@import './editor-variables';

.k-editor {
  background-color: $editor-background-color;
}

.k-expanded-editor .k-editor {
  background-color: $editor-expanded-background-color;
}

.k-editor-content {
  .ProseMirror {
    border-color: $editor-content-border-color;
    color: $editor-text-color;

    &-focused {
      border-color: $form-input-bc-focus;
    }
  }
}

.editor-clean-formatting-button {
  border-radius: $border-radius-1;

  &.k-disabled {
    background-color: $editor-clean-button-bg;

    .k-svg-i-clear-css {
      color: $editor-clear-icon-color;
    }
  }
}

.k-editor-validation {
  background-color: $editor-validation-bg;

  .ProseMirror {
    border-color: $editor-validation-bc;
  }

  .ProseMirror-focused {
    background-color: $editor-validation-bg-focused;
  }
}

.k-editor-validation-message {
  color: $editor-validation-message-color;
}

.editor-resize-button {
  &:active:focus {
    background-color: $btn-secondary-bg;
    box-shadow: none;
  }
}
