
/**
* @tokens Assets
*/
$assets-path: "./../../../../../assets/general/";

/**
* @tokens Borders
* @presenter Border
*/
$border-style-solid: solid;
$border-style-dashed: dashed;
$border-width-1: 1px;
$border-width-2: 2px;

/**
* @tokens Border-radii
*/
$border-radius-1: 2px;
$border-radius-2: 3px;
$border-radius-3: 10px;
$border-radius-4: 5px;
$border-radius-100: 50%;

/**
* @tokens Grid-breakpoints
*/
$grid-breakpoint-xs: 360px;
$grid-breakpoint-sm: 600px;
$grid-breakpoint-md: 876px;
$grid-breakpoint-lg: 1024px;
$grid-breakpoint-xl: 1280px;
$grid-breakpoint-xxl: 1536px;
$grid-breakpoint-xxxl: 1920px;

/**
* @tokens Container-max-widths
*/
$container-max-width-xs: 328px;
$container-max-width-sm: 552px;
$container-max-width-md: 828px;
$container-max-width-lg: 976px;
$container-max-width-xl: 1232px;
$container-max-width-xxl: 1488px;
$container-max-width-xxxl: 1872px;

/**
* @tokens Fonts
*/
$font-base-size: 0.875rem;
$font-base-line-height: 1.25rem;
$font-heading-70-size: 2rem;
$font-heading-70-line-height: 3rem;
$font-heading-50-size: 1.5rem;
$font-heading-50-line-height: 2rem;
$font-heading-40-size: 1.375rem;
$font-heading-40-line-height: 2rem;
$font-heading-30-size: 1.125rem;
$font-heading-30-line-height: 1.75rem;
$font-heading-20-size: 0.75rem;
$font-heading-20-line-height: 1rem;
$font-heading-70-size-mobile: 1.375rem;
$font-heading-70-line-height-mobile: 1.65rem;
$font-heading-50-size-mobile: 1.125rem;
$font-heading-50-line-height-mobile: 1.5rem;
$font-heading-40-size-mobile: 1.125rem;
$font-heading-40-line-height-mobile: 1.5rem;
$font-heading-30-size-mobile: 1rem;
$font-heading-30-line-height-mobile: 1.26315rem;
$font-subtitle-50-size: 1rem;
$font-subtitle-50-line-height: 1.5rem;
$font-subtitle-50-size-mobile: 1rem;
$font-subtitle-50-line-height-mobile: 1.26315rem;
$font-body-70-size: 0.875rem;
$font-body-70-line-height: 1.25rem;
$font-body-50-size: 0.75rem;
$font-body-50-line-height: 1rem;
$font-body-40-size: 0.6875rem;
$font-body-40-line-height: 0.875rem;
$font-body-30-size: 0.625rem;
$font-body-30-line-height: 0.75rem;
$font-link-70-size: 0.875rem;
$font-link-70-line-height: 1.25rem;
$font-link-50-size: 0.75rem;
$font-link-50-line-height: 1rem;
$font-icon-xxs: 0.75rem;
$font-icon-xs: 1rem;
$font-icon-sm: 1.25rem;
$font-icon-md: 1.5rem;
$font-icon-lg: 1.875rem;
$font-icon-xl: 2.1875rem;
$font-icon-xxl: 2.5rem;
$font-weight-normal: 400;
$font-weight-bold: 600;

/**
* @tokens Zindices
*/
$zindex-toast: 1090;
$zindex-confirmation-modal: 1065;
$zindex-onesearch: 1055;
$zindex-top-navigation: 990;
$zindex-onesearch-backdrop: 980;
$zindex-left-navigation: 970;
$zindex-drawer: 960;
$zindex-suggester: 955;
$zindex-drawer-collapsed: 950;
$zindex-sub-navigation: 940;