/**
 * Do not edit directly
 * Generated on Sun, 05 Jan 2025 18:05:02 GMT
 */

:root {
  --border-radius-1: 2px;
  --border-radius-2: 3px;
  --border-radius-3: 10px;
  --border-radius-4: 5px;
  --border-radius-100: 50%;
  --color-primary-50: #f6fdff;
  --color-primary-100: #ebf9fc;
  --color-primary-200: #d3eef5;
  --color-primary-300: #aedae5;
  --color-primary-400: #66a7b8;
  --color-primary-500: #338ba1;
  --color-primary-600: #006d89;
  --color-primary-700: #005d75;
  --color-primary-800: #005063;
  --color-primary-900: #004759;
  --color-primary-950: #004152;
  --color-neutral-50: #f5f5f5;
  --color-neutral-100: #e6e6e6;
  --color-neutral-200: #cececf;
  --color-neutral-300: #adadad;
  --color-neutral-400: #999999;
  --color-neutral-500: #6d6d6d;
  --color-neutral-600: #383838;
  --color-neutral-700: #333333;
  --color-neutral-800: #292929;
  --color-neutral-900: #1a1a1a;
  --color-success-50: #f7faf7;
  --color-success-100: #eef5f0;
  --color-success-200: #deefe1;
  --color-success-300: #bedec4;
  --color-success-400: #9dcea6;
  --color-success-500: #7dbe88;
  --color-success-600: #5cae6a;
  --color-success-700: #3c9d4d;
  --color-success-800: #167527;
  --color-success-900: #125e1f;
  --color-warning-50: #fffaeb;
  --color-warning-100: #fff7e3;
  --color-warning-200: #fff3d4;
  --color-warning-300: #fcecc0;
  --color-warning-400: #fadf96;
  --color-warning-500: #f2cb6f;
  --color-warning-600: #f0c15d;
  --color-warning-700: #e9b350;
  --color-warning-800: #d59f3c;
  --color-warning-900: #c18b28;
  --color-warning-950: #9d5700;
  --color-danger-50: #fff6f9;
  --color-danger-100: #ffeff3;
  --color-danger-200: #fadde6;
  --color-danger-300: #f3a7c3;
  --color-danger-400: #df6b95;
  --color-danger-500: #d83159;
  --color-danger-600: #d10f49;
  --color-danger-700: #b20040;
  --color-danger-800: #960036;
  --color-danger-900: #880129;
  --color-default-50: #f8f8f8;
  --color-default-100: #f5f5f5;
  --color-default-200: #eeeeee;
  --color-default-300: #e2e2e2;
  --color-default-400: #c2c2c2;
  --color-default-500: #888888;
  --color-default-600: #6b6b6b;
  --color-default-700: #5c5c5c;
  --color-default-800: #494844;
  --color-default-900: #1f1f1f;
  --color-accent-1-50: #fcf4f6;
  --color-accent-1-100: #fbeff2;
  --color-accent-1-200: #f5dfe6;
  --color-accent-1-300: #ebbfcd;
  --color-accent-1-400: #e19fb4;
  --color-accent-1-500: #cd6083;
  --color-accent-1-600: #c3406a;
  --color-accent-1-700: #a31a46;
  --color-accent-1-800: #8a183c;
  --color-accent-1-900: #7b1536;
  --color-accent-2-50: #fbf6fc;
  --color-accent-2-100: #f7eef8;
  --color-accent-2-200: #f1e4f2;
  --color-accent-2-300: #e6d2e9;
  --color-accent-2-400: #d7b8da;
  --color-accent-2-500: #b280b6;
  --color-accent-2-600: #8b4092;
  --color-accent-2-700: #73287a;
  --color-accent-2-800: #641b6b;
  --color-accent-2-900: #501555;
  --color-accent-3-50: #f2fbfe;
  --color-accent-3-100: #e9f8fd;
  --color-accent-3-200: #dceff9;
  --color-accent-3-300: #b4d5e9;
  --color-accent-3-400: #85b7d4;
  --color-accent-3-500: #6598b5;
  --color-accent-3-600: #507e99;
  --color-accent-3-700: #416a82;
  --color-accent-3-800: #345569;
  --color-accent-3-900: #284454;
  --color-accent-4-50: #fef7ef;
  --color-accent-4-100: #fdefde;
  --color-accent-4-200: #fceddb;
  --color-accent-4-300: #fadbb6;
  --color-accent-4-400: #f7c992;
  --color-accent-4-500: #f4b86d;
  --color-accent-4-600: #ec8200;
  --color-accent-4-700: #c56c00;
  --color-accent-4-800: #9d5700;
  --color-accent-4-900: #764100;
  --color-accent-5-50: #f2fdfb;
  --color-accent-5-100: #f0fcf9;
  --color-accent-5-200: #e9f5f4;
  --color-accent-5-300: #c6eae1;
  --color-accent-5-400: #31ac8d;
  --color-accent-5-500: #0f9e7a;
  --color-accent-5-600: #0c8466;
  --color-accent-5-700: #13725a;
  --color-accent-5-800: #0a6951;
  --color-accent-5-900: #084f3d;
  --color-accent-6-50: #eef0f8;
  --color-accent-6-100: #e3e6f4;
  --color-accent-6-200: #c0c8e7;
  --color-accent-6-300: #aab5df;
  --color-accent-6-400: #7c8fce;
  --color-accent-6-500: #566cbf;
  --color-accent-6-600: #495cb2;
  --color-accent-6-700: #3e51ad;
  --color-accent-6-800: #2c3991;
  --color-accent-6-900: #01145f;
  --color-base: #ffffff;
  --color-brand: #d6002a;
}
