@import './layout-width-variables';

f-layout-width .f-layout-width {
  border-color: $f-layout-width-border-color !important;

  &-item {
    & + .f-layout-width-item {
      border-left: $f-layout-width-item-border;
    }

    //styles to reset to `not-selected` color all columns after hovered item
    &,
    &:hover ~ .f-layout-width-item:not(.disabled) {
      background-color: $f-layout-width-item-bg-color;
    }

    &:hover:not(.disabled) {
      background-color: $f-layout-width-item-bg-color-hover;
    }

    &:hover:active:not(.disabled) {
      background-color: $f-layout-width-item-bg-color-pressed;
    }

    //styles to fill with `selected` color all columns before hovered item
    &.selected,
    &:has(~ .f-layout-width-item:hover):not(.disabled) {
      background-color: $f-layout-width-item-bg-color-selected;
    }

    &.selected.disabled {
      background: $f-layout-width-item-bg-color-selected-disabled;
    }

    &.disabled {
      background: $f-layout-width-item-bg-color-disabled;
    }
  }
}
