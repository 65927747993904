@charset "UTF-8";
/**
 * Do not edit directly
 * Generated on Sun, 05 Jan 2025 18:05:02 GMT
 */
:root {
  --border-radius-1: 2px;
  --border-radius-2: 3px;
  --border-radius-3: 10px;
  --border-radius-4: 5px;
  --border-radius-100: 50%;
  --color-primary-50: #f6fdff;
  --color-primary-100: #ebf9fc;
  --color-primary-200: #d3eef5;
  --color-primary-300: #aedae5;
  --color-primary-400: #66a7b8;
  --color-primary-500: #338ba1;
  --color-primary-600: #006d89;
  --color-primary-700: #005d75;
  --color-primary-800: #005063;
  --color-primary-900: #004759;
  --color-primary-950: #004152;
  --color-neutral-50: #f5f5f5;
  --color-neutral-100: #e6e6e6;
  --color-neutral-200: #cececf;
  --color-neutral-300: #adadad;
  --color-neutral-400: #999999;
  --color-neutral-500: #6d6d6d;
  --color-neutral-600: #383838;
  --color-neutral-700: #333333;
  --color-neutral-800: #292929;
  --color-neutral-900: #1a1a1a;
  --color-success-50: #f7faf7;
  --color-success-100: #eef5f0;
  --color-success-200: #deefe1;
  --color-success-300: #bedec4;
  --color-success-400: #9dcea6;
  --color-success-500: #7dbe88;
  --color-success-600: #5cae6a;
  --color-success-700: #3c9d4d;
  --color-success-800: #167527;
  --color-success-900: #125e1f;
  --color-warning-50: #fffaeb;
  --color-warning-100: #fff7e3;
  --color-warning-200: #fff3d4;
  --color-warning-300: #fcecc0;
  --color-warning-400: #fadf96;
  --color-warning-500: #f2cb6f;
  --color-warning-600: #f0c15d;
  --color-warning-700: #e9b350;
  --color-warning-800: #d59f3c;
  --color-warning-900: #c18b28;
  --color-warning-950: #9d5700;
  --color-danger-50: #fff6f9;
  --color-danger-100: #ffeff3;
  --color-danger-200: #fadde6;
  --color-danger-300: #f3a7c3;
  --color-danger-400: #df6b95;
  --color-danger-500: #d83159;
  --color-danger-600: #d10f49;
  --color-danger-700: #b20040;
  --color-danger-800: #960036;
  --color-danger-900: #880129;
  --color-default-50: #f8f8f8;
  --color-default-100: #f5f5f5;
  --color-default-200: #eeeeee;
  --color-default-300: #e2e2e2;
  --color-default-400: #c2c2c2;
  --color-default-500: #888888;
  --color-default-600: #6b6b6b;
  --color-default-700: #5c5c5c;
  --color-default-800: #494844;
  --color-default-900: #1f1f1f;
  --color-accent-1-50: #fcf4f6;
  --color-accent-1-100: #fbeff2;
  --color-accent-1-200: #f5dfe6;
  --color-accent-1-300: #ebbfcd;
  --color-accent-1-400: #e19fb4;
  --color-accent-1-500: #cd6083;
  --color-accent-1-600: #c3406a;
  --color-accent-1-700: #a31a46;
  --color-accent-1-800: #8a183c;
  --color-accent-1-900: #7b1536;
  --color-accent-2-50: #fbf6fc;
  --color-accent-2-100: #f7eef8;
  --color-accent-2-200: #f1e4f2;
  --color-accent-2-300: #e6d2e9;
  --color-accent-2-400: #d7b8da;
  --color-accent-2-500: #b280b6;
  --color-accent-2-600: #8b4092;
  --color-accent-2-700: #73287a;
  --color-accent-2-800: #641b6b;
  --color-accent-2-900: #501555;
  --color-accent-3-50: #f2fbfe;
  --color-accent-3-100: #e9f8fd;
  --color-accent-3-200: #dceff9;
  --color-accent-3-300: #b4d5e9;
  --color-accent-3-400: #85b7d4;
  --color-accent-3-500: #6598b5;
  --color-accent-3-600: #507e99;
  --color-accent-3-700: #416a82;
  --color-accent-3-800: #345569;
  --color-accent-3-900: #284454;
  --color-accent-4-50: #fef7ef;
  --color-accent-4-100: #fdefde;
  --color-accent-4-200: #fceddb;
  --color-accent-4-300: #fadbb6;
  --color-accent-4-400: #f7c992;
  --color-accent-4-500: #f4b86d;
  --color-accent-4-600: #ec8200;
  --color-accent-4-700: #c56c00;
  --color-accent-4-800: #9d5700;
  --color-accent-4-900: #764100;
  --color-accent-5-50: #f2fdfb;
  --color-accent-5-100: #f0fcf9;
  --color-accent-5-200: #e9f5f4;
  --color-accent-5-300: #c6eae1;
  --color-accent-5-400: #31ac8d;
  --color-accent-5-500: #0f9e7a;
  --color-accent-5-600: #0c8466;
  --color-accent-5-700: #13725a;
  --color-accent-5-800: #0a6951;
  --color-accent-5-900: #084f3d;
  --color-accent-6-50: #eef0f8;
  --color-accent-6-100: #e3e6f4;
  --color-accent-6-200: #c0c8e7;
  --color-accent-6-300: #aab5df;
  --color-accent-6-400: #7c8fce;
  --color-accent-6-500: #566cbf;
  --color-accent-6-600: #495cb2;
  --color-accent-6-700: #3e51ad;
  --color-accent-6-800: #2c3991;
  --color-accent-6-900: #01145f;
  --color-base: #ffffff;
  --color-brand: #d6002a;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
/**
* @tokens Brand-assets-paths
*/
/**
* @tokens Brand-assets-common-paths
*/
/**
* @tokens Brand-icon-assets-paths
*/
/**
* @tokens Brand-icon-layouts-assets-paths
*/
/**
* @tokens Colors
* @presenter Color
*/
/**
* @tokens Shadows
* @presenter Shadow
*/
.shadow-neutral-0 {
  box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12) !important;
}

:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #005d75;
  --bs-neutral: #333333;
  --bs-success: #3c9d4d;
  --bs-warning: #e9b350;
  --bs-danger: #b20040;
  --bs-default: #5c5c5c;
  --bs-accent-1: #a31a46;
  --bs-accent-2: #73287a;
  --bs-accent-3: #416a82;
  --bs-accent-4: #c56c00;
  --bs-accent-5: #13725a;
  --bs-accent-6: #3e51ad;
  --bs-base: #ffffff;
  --bs-primary-50: #f6fdff;
  --bs-primary-400: #66a7b8;
  --bs-neutral-200: #cececf;
  --bs-neutral-300: #adadad;
  --bs-neutral-700: #333333;
  --bs-neutral-900: #1a1a1a;
  --bs-warning-800: #d59f3c;
  --bs-primary-700: #005d75;
  --bs-success-300: #bedec4;
  --bs-success-400: #9dcea6;
  --bs-success-700: #3c9d4d;
  --bs-accent-1-600: #c3406a;
  --bs-accent-2-700: #73287a;
  --bs-accent-3-100: #e9f8fd;
  --bs-accent-3-700: #416a82;
  --bs-accent-4-700: #c56c00;
  --bs-accent-5-600: #0c8466;
  --bs-accent-3-300: #b4d5e9;
  --bs-accent-3-500: #6598b5;
  --bs-accent-4-900: #764100;
  --bs-default-50: #f8f8f8;
  --bs-primary-rgb: 0, 93, 117;
  --bs-neutral-rgb: 51, 51, 51;
  --bs-success-rgb: 60, 157, 77;
  --bs-warning-rgb: 233, 179, 80;
  --bs-danger-rgb: 178, 0, 64;
  --bs-default-rgb: 92, 92, 92;
  --bs-accent-1-rgb: 163, 26, 70;
  --bs-accent-2-rgb: 115, 40, 122;
  --bs-accent-3-rgb: 65, 106, 130;
  --bs-accent-4-rgb: 197, 108, 0;
  --bs-accent-5-rgb: 19, 114, 90;
  --bs-accent-6-rgb: 62, 81, 173;
  --bs-base-rgb: 255, 255, 255;
  --bs-primary-50-rgb: 246, 253, 255;
  --bs-primary-400-rgb: 102, 167, 184;
  --bs-neutral-200-rgb: 206, 206, 207;
  --bs-neutral-300-rgb: 173, 173, 173;
  --bs-neutral-700-rgb: 51, 51, 51;
  --bs-neutral-900-rgb: 26, 26, 26;
  --bs-warning-800-rgb: 213, 159, 60;
  --bs-primary-700-rgb: 0, 93, 117;
  --bs-success-300-rgb: 190, 222, 196;
  --bs-success-400-rgb: 157, 206, 166;
  --bs-success-700-rgb: 60, 157, 77;
  --bs-accent-1-600-rgb: 195, 64, 106;
  --bs-accent-2-700-rgb: 115, 40, 122;
  --bs-accent-3-100-rgb: 233, 248, 253;
  --bs-accent-3-700-rgb: 65, 106, 130;
  --bs-accent-4-700-rgb: 197, 108, 0;
  --bs-accent-5-600-rgb: 12, 132, 102;
  --bs-accent-3-300-rgb: 180, 213, 233;
  --bs-accent-3-500-rgb: 101, 152, 181;
  --bs-accent-4-900-rgb: 118, 65, 0;
  --bs-default-50-rgb: 248, 248, 248;
  --bs-primary-text-emphasis: #00252f;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #183f1f;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #5d4820;
  --bs-danger-text-emphasis: #47001a;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #ccdfe3;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d8ebdb;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fbf0dc;
  --bs-danger-bg-subtle: #f0ccd9;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #99bec8;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #b1d8b8;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #f6e1b9;
  --bs-danger-border-subtle: #e099b3;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Akkurat", sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.4285714286;
  --bs-body-color: #333333;
  --bs-body-color-rgb: 51, 51, 51;
  --bs-body-bg: #f8f8f8;
  --bs-body-bg-rgb: 248, 248, 248;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(51, 51, 51, 0.75);
  --bs-secondary-color-rgb: 51, 51, 51;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(51, 51, 51, 0.5);
  --bs-tertiary-color-rgb: 51, 51, 51;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #005d75;
  --bs-link-color-rgb: 0, 93, 117;
  --bs-link-decoration: none;
  --bs-link-hover-color: #004a5e;
  --bs-link-hover-color-rgb: 0, 74, 94;
  --bs-link-hover-decoration: underline;
  --bs-code-color: #d63384;
  --bs-highlight-color: #333333;
  --bs-highlight-bg: transparent;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e6e6e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 3px;
  --bs-border-radius-sm: 2px;
  --bs-border-radius-lg: 10px;
  --bs-border-radius-xl: 5px;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: none;
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(0, 93, 117, 0.25);
  --bs-form-valid-color: #3c9d4d;
  --bs-form-valid-border-color: #3c9d4d;
  --bs-form-invalid-color: #b20040;
  --bs-form-invalid-border-color: #b20040;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #669eac;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #8ac494;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #f2d196;
  --bs-danger-text-emphasis: #d1668c;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #001317;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #0c1f0f;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #2f2410;
  --bs-danger-bg-subtle: #24000d;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #003846;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #245e2e;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #8c6b30;
  --bs-danger-border-subtle: #6b0026;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #669eac;
  --bs-link-hover-color: #85b1bd;
  --bs-link-color-rgb: 102, 158, 172;
  --bs-link-hover-color-rgb: 133, 177, 189;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4285714286;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #333333;
  appearance: none;
  background-color: #eeeeee;
  background-clip: padding-box;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #333333;
  background-color: var(--bs-body-bg);
  border-color: #80aeba;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 93, 117, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.4285714286em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: none;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.4285714286;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: 1.5rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 2px;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.75rem;
  margin: -0.25rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: 2.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border-radius: 2px;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 0.75rem;
  margin: -0.5rem -0.75rem;
  margin-inline-end: 0.75rem;
}

textarea.form-control {
  min-height: 2rem;
}
textarea.form-control-sm {
  min-height: 1.5rem;
}
textarea.form-control-lg {
  min-height: 2.5rem;
}

.form-control-color {
  width: 3rem;
  height: 2rem;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 2px;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 2px;
}
.form-control-color.form-control-sm {
  height: 1.5rem;
}
.form-control-color.form-control-lg {
  height: 2.5rem;
}

.form-select {
  --bs-form-select-bg-img: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1000pt" height="1000pt" viewBox="0 0 1000 1000" version="1.1"%3e%3cpath style=" stroke:none;fill-rule:evenodd;fill:%23999999;fill-opacity:1;" d="M 514.730469 610.269531 L 672.769531 452.230469 C 680.90625 444.09375 680.90625 430.90625 672.769531 422.769531 C 668.863281 418.863281 663.5625 416.667969 658.039062 416.667969 L 341.960938 416.667969 C 330.457031 416.667969 321.128906 425.992188 321.128906 437.5 C 321.128906 443.023438 323.324219 448.324219 327.230469 452.230469 L 485.269531 610.269531 C 493.40625 618.40625 506.59375 618.40625 514.730469 610.269531 Z M 514.730469 610.269531 "/%3e%3c/svg%3e');
  display: block;
  width: 100%;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #333333;
  appearance: none;
  background-color: #eeeeee;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.5625rem center;
  background-size: 1.25rem 1.125rem;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: transparent;
  outline: 0;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: rgba(51, 51, 51, 0.5);
  background-color: rgba(238, 238, 238, 0.5);
  border-color: rgba(238, 238, 238, 0.5);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #333333;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: auto;
  padding-left: 0;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: 0;
}

.form-check-reverse {
  padding-right: 0;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: 0;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: transparent;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-top: 0.2142857143rem;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #888888;
  print-color-adjust: exact;
  transition: none;
}
.form-check-input[type=checkbox] {
  border-radius: 2px;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: none;
}
.form-check-input:focus {
  border-color: 1px solid #888888;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: none;
  border-color: #005d75;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: ;
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: none;
  border-color: none;
  --bs-form-check-bg-image: none;
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-label {
  cursor: pointer;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: none;
  width: 2rem;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: ;
}
.form-switch .form-check-input:checked {
  background-position: unset;
  --bs-form-switch-bg: ;
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8f8f8, 0 0 0 0.25rem rgba(0, 93, 117, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8f8f8, 0 0 0 0.25rem rgba(0, 93, 117, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #005d75;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3ced6;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #005d75;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3ced6;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #eeeeee;
  border-radius: 2px;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: 1px solid var(--bs-border-color);
  border-radius: 2px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border-radius: 2px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 2px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.warning-tooltip):not(.warning-feedback):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.warning-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 0.75rem;
  color: #e9b350;
}

.warning-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #000;
  background-color: rgba(233, 179, 80, 0.9);
  border-radius: var(--bs-border-radius);
}

.is-warning ~ .warning-feedback, .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput ~ .warning-feedback, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-dateinput .k-dateinput ~ .warning-feedback,
.is-warning ~ .warning-tooltip,
.k-daterangepicker.f-is-collapsed.is-warning .k-dateinput ~ .warning-tooltip,
.k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-dateinput .k-dateinput ~ .warning-tooltip {
  display: block;
}

.form-control.is-warning, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput {
  border-color: #e9b350;
}
.form-control.is-warning:focus, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput:focus {
  border-color: #e9b350;
  box-shadow: 0 0 0 0.25rem rgba(233, 179, 80, 0.25);
}

.form-select.is-warning, .k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput {
  border-color: #e9b350;
}
.form-select.is-warning:focus, .k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput:focus {
  border-color: #e9b350;
  box-shadow: 0 0 0 0.25rem rgba(233, 179, 80, 0.25);
}

.form-check-input.is-warning, .k-daterangepicker.f-is-collapsed.is-warning .form-check-input.k-dateinput {
  border-color: #e9b350;
}
.form-check-input.is-warning:checked, .k-daterangepicker.f-is-collapsed.is-warning .form-check-input.k-dateinput:checked {
  background-color: #e9b350;
}
.form-check-input.is-warning:focus, .k-daterangepicker.f-is-collapsed.is-warning .form-check-input.k-dateinput:focus {
  box-shadow: 0 0 0 0.25rem rgba(233, 179, 80, 0.25);
}
.form-check-input.is-warning ~ .form-check-label, .k-daterangepicker.f-is-collapsed.is-warning .form-check-input.k-dateinput ~ .form-check-label {
  color: #e9b350;
}

.form-check-inline .form-check-input ~ .warning-feedback {
  margin-left: 0.5em;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 0.75rem;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 0.75rem;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput ~ .invalid-feedback,
.k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-dateinput .k-dateinput ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput ~ .invalid-tooltip,
.k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-dateinput .k-dateinput ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus, .k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .form-check-input.k-dateinput {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked, .k-daterangepicker.f-is-collapsed.is-invalid .form-check-input.k-dateinput:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus, .k-daterangepicker.f-is-collapsed.is-invalid .form-check-input.k-dateinput:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .k-daterangepicker.f-is-collapsed.is-invalid .form-check-input.k-dateinput ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .input-group > .form-control.k-dateinput:not(:focus),
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.k-daterangepicker.f-is-collapsed.is-invalid .input-group > .form-select.k-dateinput:not(:focus),
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.k-daterangepicker.f-is-collapsed.is-invalid .input-group > .form-floating.k-dateinput:not(:focus-within) {
  z-index: 4;
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ccdfe3;
  --bs-table-border-color: #a3b2b6;
  --bs-table-striped-bg: #c2d4d8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c9cc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdced2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d8ebdb;
  --bs-table-border-color: #adbcaf;
  --bs-table-striped-bg: #cddfd0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d4c5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8d9cb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fbf0dc;
  --bs-table-border-color: #c9c0b0;
  --bs-table-striped-bg: #eee4d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2d8c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8decc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f0ccd9;
  --bs-table-border-color: #c0a3ae;
  --bs-table-striped-bg: #e4c2ce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d8b8c3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #debdc9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.4285714286;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 2px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: ;
  --bs-btn-disabled-opacity: 1;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: none;
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #005d75;
  --bs-btn-border-color: #005d75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004f63;
  --bs-btn-hover-border-color: #004a5e;
  --bs-btn-focus-shadow-rgb: 38, 117, 138;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004a5e;
  --bs-btn-active-border-color: #004658;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005d75;
  --bs-btn-disabled-border-color: #005d75;
}

.btn-neutral {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2b2b;
  --bs-btn-hover-border-color: #292929;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #292929;
  --bs-btn-active-border-color: #262626;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #333333;
  --bs-btn-disabled-border-color: #333333;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #3c9d4d;
  --bs-btn-border-color: #3c9d4d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #59ac68;
  --bs-btn-hover-border-color: #50a75f;
  --bs-btn-focus-shadow-rgb: 51, 133, 65;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #63b171;
  --bs-btn-active-border-color: #50a75f;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3c9d4d;
  --bs-btn-disabled-border-color: #3c9d4d;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #e9b350;
  --bs-btn-border-color: #e9b350;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ecbe6a;
  --bs-btn-hover-border-color: #ebbb62;
  --bs-btn-focus-shadow-rgb: 198, 152, 68;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #edc273;
  --bs-btn-active-border-color: #ebbb62;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e9b350;
  --bs-btn-disabled-border-color: #e9b350;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #b20040;
  --bs-btn-border-color: #b20040;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #970036;
  --bs-btn-hover-border-color: #8e0033;
  --bs-btn-focus-shadow-rgb: 190, 38, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8e0033;
  --bs-btn-active-border-color: #860030;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #b20040;
  --bs-btn-disabled-border-color: #b20040;
}

.btn-default {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5c5c5c;
  --bs-btn-border-color: #5c5c5c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4e4e4e;
  --bs-btn-hover-border-color: #4a4a4a;
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4a4a4a;
  --bs-btn-active-border-color: #454545;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5c5c5c;
  --bs-btn-disabled-border-color: #5c5c5c;
}

.btn-accent-1 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #a31a46;
  --bs-btn-border-color: #a31a46;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8b163c;
  --bs-btn-hover-border-color: #821538;
  --bs-btn-focus-shadow-rgb: 177, 60, 98;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #821538;
  --bs-btn-active-border-color: #7a1435;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #a31a46;
  --bs-btn-disabled-border-color: #a31a46;
}

.btn-accent-2 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #73287a;
  --bs-btn-border-color: #73287a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #622268;
  --bs-btn-hover-border-color: #5c2062;
  --bs-btn-focus-shadow-rgb: 136, 72, 142;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c2062;
  --bs-btn-active-border-color: #561e5c;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #73287a;
  --bs-btn-disabled-border-color: #73287a;
}

.btn-accent-3 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #416a82;
  --bs-btn-border-color: #416a82;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #375a6f;
  --bs-btn-hover-border-color: #345568;
  --bs-btn-focus-shadow-rgb: 94, 128, 149;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #345568;
  --bs-btn-active-border-color: #315062;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #416a82;
  --bs-btn-disabled-border-color: #416a82;
}

.btn-accent-4 {
  --bs-btn-color: #000;
  --bs-btn-bg: #c56c00;
  --bs-btn-border-color: #c56c00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ce8226;
  --bs-btn-hover-border-color: #cb7b1a;
  --bs-btn-focus-shadow-rgb: 167, 92, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d18933;
  --bs-btn-active-border-color: #cb7b1a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c56c00;
  --bs-btn-disabled-border-color: #c56c00;
}

.btn-accent-5 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #13725a;
  --bs-btn-border-color: #13725a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #10614d;
  --bs-btn-hover-border-color: #0f5b48;
  --bs-btn-focus-shadow-rgb: 54, 135, 115;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0f5b48;
  --bs-btn-active-border-color: #0e5644;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #13725a;
  --bs-btn-disabled-border-color: #13725a;
}

.btn-accent-6 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3e51ad;
  --bs-btn-border-color: #3e51ad;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #354593;
  --bs-btn-hover-border-color: #32418a;
  --bs-btn-focus-shadow-rgb: 91, 107, 185;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #32418a;
  --bs-btn-active-border-color: #2f3d82;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3e51ad;
  --bs-btn-disabled-border-color: #3e51ad;
}

.btn-base {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-primary-50 {
  --bs-btn-color: #000;
  --bs-btn-bg: #f6fdff;
  --bs-btn-border-color: #f6fdff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7fdff;
  --bs-btn-hover-border-color: #f7fdff;
  --bs-btn-focus-shadow-rgb: 209, 215, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8fdff;
  --bs-btn-active-border-color: #f7fdff;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f6fdff;
  --bs-btn-disabled-border-color: #f6fdff;
}

.btn-primary-400 {
  --bs-btn-color: #000;
  --bs-btn-bg: #66a7b8;
  --bs-btn-border-color: #66a7b8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7db4c3;
  --bs-btn-hover-border-color: #75b0bf;
  --bs-btn-focus-shadow-rgb: 87, 142, 156;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #85b9c6;
  --bs-btn-active-border-color: #75b0bf;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #66a7b8;
  --bs-btn-disabled-border-color: #66a7b8;
}

.btn-neutral-200 {
  --bs-btn-color: #000;
  --bs-btn-bg: #cececf;
  --bs-btn-border-color: #cececf;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d5d5d6;
  --bs-btn-hover-border-color: #d3d3d4;
  --bs-btn-focus-shadow-rgb: 175, 175, 176;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d8d8d9;
  --bs-btn-active-border-color: #d3d3d4;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #cececf;
  --bs-btn-disabled-border-color: #cececf;
}

.btn-neutral-300 {
  --bs-btn-color: #000;
  --bs-btn-bg: #adadad;
  --bs-btn-border-color: #adadad;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #b9b9b9;
  --bs-btn-hover-border-color: #b5b5b5;
  --bs-btn-focus-shadow-rgb: 147, 147, 147;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #bdbdbd;
  --bs-btn-active-border-color: #b5b5b5;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #adadad;
  --bs-btn-disabled-border-color: #adadad;
}

.btn-neutral-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2b2b;
  --bs-btn-hover-border-color: #292929;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #292929;
  --bs-btn-active-border-color: #262626;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #333333;
  --bs-btn-disabled-border-color: #333333;
}

.btn-neutral-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1a1a1a;
  --bs-btn-border-color: #1a1a1a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #161616;
  --bs-btn-hover-border-color: #151515;
  --bs-btn-focus-shadow-rgb: 60, 60, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #151515;
  --bs-btn-active-border-color: #141414;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1a1a1a;
  --bs-btn-disabled-border-color: #1a1a1a;
}

.btn-warning-800 {
  --bs-btn-color: #000;
  --bs-btn-bg: #d59f3c;
  --bs-btn-border-color: #d59f3c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dbad59;
  --bs-btn-hover-border-color: #d9a950;
  --bs-btn-focus-shadow-rgb: 181, 135, 51;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ddb263;
  --bs-btn-active-border-color: #d9a950;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d59f3c;
  --bs-btn-disabled-border-color: #d59f3c;
}

.btn-primary-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #005d75;
  --bs-btn-border-color: #005d75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004f63;
  --bs-btn-hover-border-color: #004a5e;
  --bs-btn-focus-shadow-rgb: 38, 117, 138;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004a5e;
  --bs-btn-active-border-color: #004658;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005d75;
  --bs-btn-disabled-border-color: #005d75;
}

.btn-success-300 {
  --bs-btn-color: #000;
  --bs-btn-bg: #bedec4;
  --bs-btn-border-color: #bedec4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c8e3cd;
  --bs-btn-hover-border-color: #c5e1ca;
  --bs-btn-focus-shadow-rgb: 162, 189, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cbe5d0;
  --bs-btn-active-border-color: #c5e1ca;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #bedec4;
  --bs-btn-disabled-border-color: #bedec4;
}

.btn-success-400 {
  --bs-btn-color: #000;
  --bs-btn-bg: #9dcea6;
  --bs-btn-border-color: #9dcea6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #acd5b3;
  --bs-btn-hover-border-color: #a7d3af;
  --bs-btn-focus-shadow-rgb: 133, 175, 141;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b1d8b8;
  --bs-btn-active-border-color: #a7d3af;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #9dcea6;
  --bs-btn-disabled-border-color: #9dcea6;
}

.btn-success-700 {
  --bs-btn-color: #000;
  --bs-btn-bg: #3c9d4d;
  --bs-btn-border-color: #3c9d4d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #59ac68;
  --bs-btn-hover-border-color: #50a75f;
  --bs-btn-focus-shadow-rgb: 51, 133, 65;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #63b171;
  --bs-btn-active-border-color: #50a75f;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3c9d4d;
  --bs-btn-disabled-border-color: #3c9d4d;
}

.btn-accent-1-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #c3406a;
  --bs-btn-border-color: #c3406a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a6365a;
  --bs-btn-hover-border-color: #9c3355;
  --bs-btn-focus-shadow-rgb: 204, 93, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #9c3355;
  --bs-btn-active-border-color: #923050;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #c3406a;
  --bs-btn-disabled-border-color: #c3406a;
}

.btn-accent-2-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #73287a;
  --bs-btn-border-color: #73287a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #622268;
  --bs-btn-hover-border-color: #5c2062;
  --bs-btn-focus-shadow-rgb: 136, 72, 142;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c2062;
  --bs-btn-active-border-color: #561e5c;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #73287a;
  --bs-btn-disabled-border-color: #73287a;
}

.btn-accent-3-100 {
  --bs-btn-color: #000;
  --bs-btn-bg: #e9f8fd;
  --bs-btn-border-color: #e9f8fd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ecf9fd;
  --bs-btn-hover-border-color: #ebf9fd;
  --bs-btn-focus-shadow-rgb: 198, 211, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #edf9fd;
  --bs-btn-active-border-color: #ebf9fd;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e9f8fd;
  --bs-btn-disabled-border-color: #e9f8fd;
}

.btn-accent-3-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #416a82;
  --bs-btn-border-color: #416a82;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #375a6f;
  --bs-btn-hover-border-color: #345568;
  --bs-btn-focus-shadow-rgb: 94, 128, 149;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #345568;
  --bs-btn-active-border-color: #315062;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #416a82;
  --bs-btn-disabled-border-color: #416a82;
}

.btn-accent-4-700 {
  --bs-btn-color: #000;
  --bs-btn-bg: #c56c00;
  --bs-btn-border-color: #c56c00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ce8226;
  --bs-btn-hover-border-color: #cb7b1a;
  --bs-btn-focus-shadow-rgb: 167, 92, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d18933;
  --bs-btn-active-border-color: #cb7b1a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c56c00;
  --bs-btn-disabled-border-color: #c56c00;
}

.btn-accent-5-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0c8466;
  --bs-btn-border-color: #0c8466;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0a7057;
  --bs-btn-hover-border-color: #0a6a52;
  --bs-btn-focus-shadow-rgb: 48, 150, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a6a52;
  --bs-btn-active-border-color: #09634d;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0c8466;
  --bs-btn-disabled-border-color: #0c8466;
}

.btn-accent-3-300 {
  --bs-btn-color: #000;
  --bs-btn-bg: #b4d5e9;
  --bs-btn-border-color: #b4d5e9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bfdbec;
  --bs-btn-hover-border-color: #bcd9eb;
  --bs-btn-focus-shadow-rgb: 153, 181, 198;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c3dded;
  --bs-btn-active-border-color: #bcd9eb;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #b4d5e9;
  --bs-btn-disabled-border-color: #b4d5e9;
}

.btn-accent-3-500 {
  --bs-btn-color: #000;
  --bs-btn-bg: #6598b5;
  --bs-btn-border-color: #6598b5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7ca7c0;
  --bs-btn-hover-border-color: #74a2bc;
  --bs-btn-focus-shadow-rgb: 86, 129, 154;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #84adc4;
  --bs-btn-active-border-color: #74a2bc;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #6598b5;
  --bs-btn-disabled-border-color: #6598b5;
}

.btn-accent-4-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #764100;
  --bs-btn-border-color: #764100;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #643700;
  --bs-btn-hover-border-color: #5e3400;
  --bs-btn-focus-shadow-rgb: 139, 94, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5e3400;
  --bs-btn-active-border-color: #593100;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #764100;
  --bs-btn-disabled-border-color: #764100;
}

.btn-default-50 {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f8f8;
  --bs-btn-border-color: #f8f8f8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f9f9f9;
  --bs-btn-hover-border-color: #f9f9f9;
  --bs-btn-focus-shadow-rgb: 211, 211, 211;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f9f9f9;
  --bs-btn-active-border-color: #f9f9f9;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f8f8;
  --bs-btn-disabled-border-color: #f8f8f8;
}

.btn-outline-primary {
  --bs-btn-color: #005d75;
  --bs-btn-border-color: #005d75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #005d75;
  --bs-btn-hover-border-color: #005d75;
  --bs-btn-focus-shadow-rgb: 0, 93, 117;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005d75;
  --bs-btn-active-border-color: #005d75;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #005d75;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #005d75;
  --bs-gradient: none;
}

.btn-outline-neutral {
  --bs-btn-color: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 51, 51, 51;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #333333;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #333333;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333333;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #3c9d4d;
  --bs-btn-border-color: #3c9d4d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3c9d4d;
  --bs-btn-hover-border-color: #3c9d4d;
  --bs-btn-focus-shadow-rgb: 60, 157, 77;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3c9d4d;
  --bs-btn-active-border-color: #3c9d4d;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #3c9d4d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3c9d4d;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #e9b350;
  --bs-btn-border-color: #e9b350;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e9b350;
  --bs-btn-hover-border-color: #e9b350;
  --bs-btn-focus-shadow-rgb: 233, 179, 80;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9b350;
  --bs-btn-active-border-color: #e9b350;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #e9b350;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e9b350;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #b20040;
  --bs-btn-border-color: #b20040;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b20040;
  --bs-btn-hover-border-color: #b20040;
  --bs-btn-focus-shadow-rgb: 178, 0, 64;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b20040;
  --bs-btn-active-border-color: #b20040;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #b20040;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b20040;
  --bs-gradient: none;
}

.btn-outline-default {
  --bs-btn-color: #5c5c5c;
  --bs-btn-border-color: #5c5c5c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c5c5c;
  --bs-btn-hover-border-color: #5c5c5c;
  --bs-btn-focus-shadow-rgb: 92, 92, 92;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c5c5c;
  --bs-btn-active-border-color: #5c5c5c;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #5c5c5c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5c5c5c;
  --bs-gradient: none;
}

.btn-outline-accent-1 {
  --bs-btn-color: #a31a46;
  --bs-btn-border-color: #a31a46;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a31a46;
  --bs-btn-hover-border-color: #a31a46;
  --bs-btn-focus-shadow-rgb: 163, 26, 70;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a31a46;
  --bs-btn-active-border-color: #a31a46;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #a31a46;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a31a46;
  --bs-gradient: none;
}

.btn-outline-accent-2 {
  --bs-btn-color: #73287a;
  --bs-btn-border-color: #73287a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #73287a;
  --bs-btn-hover-border-color: #73287a;
  --bs-btn-focus-shadow-rgb: 115, 40, 122;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #73287a;
  --bs-btn-active-border-color: #73287a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #73287a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #73287a;
  --bs-gradient: none;
}

.btn-outline-accent-3 {
  --bs-btn-color: #416a82;
  --bs-btn-border-color: #416a82;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #416a82;
  --bs-btn-hover-border-color: #416a82;
  --bs-btn-focus-shadow-rgb: 65, 106, 130;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #416a82;
  --bs-btn-active-border-color: #416a82;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #416a82;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #416a82;
  --bs-gradient: none;
}

.btn-outline-accent-4 {
  --bs-btn-color: #c56c00;
  --bs-btn-border-color: #c56c00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c56c00;
  --bs-btn-hover-border-color: #c56c00;
  --bs-btn-focus-shadow-rgb: 197, 108, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c56c00;
  --bs-btn-active-border-color: #c56c00;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #c56c00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c56c00;
  --bs-gradient: none;
}

.btn-outline-accent-5 {
  --bs-btn-color: #13725a;
  --bs-btn-border-color: #13725a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #13725a;
  --bs-btn-hover-border-color: #13725a;
  --bs-btn-focus-shadow-rgb: 19, 114, 90;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #13725a;
  --bs-btn-active-border-color: #13725a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #13725a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #13725a;
  --bs-gradient: none;
}

.btn-outline-accent-6 {
  --bs-btn-color: #3e51ad;
  --bs-btn-border-color: #3e51ad;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3e51ad;
  --bs-btn-hover-border-color: #3e51ad;
  --bs-btn-focus-shadow-rgb: 62, 81, 173;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3e51ad;
  --bs-btn-active-border-color: #3e51ad;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #3e51ad;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3e51ad;
  --bs-gradient: none;
}

.btn-outline-base {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-primary-50 {
  --bs-btn-color: #f6fdff;
  --bs-btn-border-color: #f6fdff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f6fdff;
  --bs-btn-hover-border-color: #f6fdff;
  --bs-btn-focus-shadow-rgb: 246, 253, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6fdff;
  --bs-btn-active-border-color: #f6fdff;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #f6fdff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f6fdff;
  --bs-gradient: none;
}

.btn-outline-primary-400 {
  --bs-btn-color: #66a7b8;
  --bs-btn-border-color: #66a7b8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #66a7b8;
  --bs-btn-hover-border-color: #66a7b8;
  --bs-btn-focus-shadow-rgb: 102, 167, 184;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #66a7b8;
  --bs-btn-active-border-color: #66a7b8;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #66a7b8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #66a7b8;
  --bs-gradient: none;
}

.btn-outline-neutral-200 {
  --bs-btn-color: #cececf;
  --bs-btn-border-color: #cececf;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cececf;
  --bs-btn-hover-border-color: #cececf;
  --bs-btn-focus-shadow-rgb: 206, 206, 207;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cececf;
  --bs-btn-active-border-color: #cececf;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #cececf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cececf;
  --bs-gradient: none;
}

.btn-outline-neutral-300 {
  --bs-btn-color: #adadad;
  --bs-btn-border-color: #adadad;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #adadad;
  --bs-btn-hover-border-color: #adadad;
  --bs-btn-focus-shadow-rgb: 173, 173, 173;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #adadad;
  --bs-btn-active-border-color: #adadad;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #adadad;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #adadad;
  --bs-gradient: none;
}

.btn-outline-neutral-700 {
  --bs-btn-color: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 51, 51, 51;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #333333;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #333333;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333333;
  --bs-gradient: none;
}

.btn-outline-neutral-900 {
  --bs-btn-color: #1a1a1a;
  --bs-btn-border-color: #1a1a1a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1a1a1a;
  --bs-btn-hover-border-color: #1a1a1a;
  --bs-btn-focus-shadow-rgb: 26, 26, 26;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a1a1a;
  --bs-btn-active-border-color: #1a1a1a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #1a1a1a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1a1a1a;
  --bs-gradient: none;
}

.btn-outline-warning-800 {
  --bs-btn-color: #d59f3c;
  --bs-btn-border-color: #d59f3c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d59f3c;
  --bs-btn-hover-border-color: #d59f3c;
  --bs-btn-focus-shadow-rgb: 213, 159, 60;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d59f3c;
  --bs-btn-active-border-color: #d59f3c;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #d59f3c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d59f3c;
  --bs-gradient: none;
}

.btn-outline-primary-700 {
  --bs-btn-color: #005d75;
  --bs-btn-border-color: #005d75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #005d75;
  --bs-btn-hover-border-color: #005d75;
  --bs-btn-focus-shadow-rgb: 0, 93, 117;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005d75;
  --bs-btn-active-border-color: #005d75;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #005d75;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #005d75;
  --bs-gradient: none;
}

.btn-outline-success-300 {
  --bs-btn-color: #bedec4;
  --bs-btn-border-color: #bedec4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bedec4;
  --bs-btn-hover-border-color: #bedec4;
  --bs-btn-focus-shadow-rgb: 190, 222, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #bedec4;
  --bs-btn-active-border-color: #bedec4;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #bedec4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #bedec4;
  --bs-gradient: none;
}

.btn-outline-success-400 {
  --bs-btn-color: #9dcea6;
  --bs-btn-border-color: #9dcea6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #9dcea6;
  --bs-btn-hover-border-color: #9dcea6;
  --bs-btn-focus-shadow-rgb: 157, 206, 166;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #9dcea6;
  --bs-btn-active-border-color: #9dcea6;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #9dcea6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9dcea6;
  --bs-gradient: none;
}

.btn-outline-success-700 {
  --bs-btn-color: #3c9d4d;
  --bs-btn-border-color: #3c9d4d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3c9d4d;
  --bs-btn-hover-border-color: #3c9d4d;
  --bs-btn-focus-shadow-rgb: 60, 157, 77;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3c9d4d;
  --bs-btn-active-border-color: #3c9d4d;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #3c9d4d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3c9d4d;
  --bs-gradient: none;
}

.btn-outline-accent-1-600 {
  --bs-btn-color: #c3406a;
  --bs-btn-border-color: #c3406a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c3406a;
  --bs-btn-hover-border-color: #c3406a;
  --bs-btn-focus-shadow-rgb: 195, 64, 106;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c3406a;
  --bs-btn-active-border-color: #c3406a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #c3406a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c3406a;
  --bs-gradient: none;
}

.btn-outline-accent-2-700 {
  --bs-btn-color: #73287a;
  --bs-btn-border-color: #73287a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #73287a;
  --bs-btn-hover-border-color: #73287a;
  --bs-btn-focus-shadow-rgb: 115, 40, 122;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #73287a;
  --bs-btn-active-border-color: #73287a;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #73287a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #73287a;
  --bs-gradient: none;
}

.btn-outline-accent-3-100 {
  --bs-btn-color: #e9f8fd;
  --bs-btn-border-color: #e9f8fd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e9f8fd;
  --bs-btn-hover-border-color: #e9f8fd;
  --bs-btn-focus-shadow-rgb: 233, 248, 253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9f8fd;
  --bs-btn-active-border-color: #e9f8fd;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #e9f8fd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e9f8fd;
  --bs-gradient: none;
}

.btn-outline-accent-3-700 {
  --bs-btn-color: #416a82;
  --bs-btn-border-color: #416a82;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #416a82;
  --bs-btn-hover-border-color: #416a82;
  --bs-btn-focus-shadow-rgb: 65, 106, 130;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #416a82;
  --bs-btn-active-border-color: #416a82;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #416a82;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #416a82;
  --bs-gradient: none;
}

.btn-outline-accent-4-700 {
  --bs-btn-color: #c56c00;
  --bs-btn-border-color: #c56c00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c56c00;
  --bs-btn-hover-border-color: #c56c00;
  --bs-btn-focus-shadow-rgb: 197, 108, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c56c00;
  --bs-btn-active-border-color: #c56c00;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #c56c00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c56c00;
  --bs-gradient: none;
}

.btn-outline-accent-5-600 {
  --bs-btn-color: #0c8466;
  --bs-btn-border-color: #0c8466;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0c8466;
  --bs-btn-hover-border-color: #0c8466;
  --bs-btn-focus-shadow-rgb: 12, 132, 102;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0c8466;
  --bs-btn-active-border-color: #0c8466;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #0c8466;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0c8466;
  --bs-gradient: none;
}

.btn-outline-accent-3-300 {
  --bs-btn-color: #b4d5e9;
  --bs-btn-border-color: #b4d5e9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #b4d5e9;
  --bs-btn-hover-border-color: #b4d5e9;
  --bs-btn-focus-shadow-rgb: 180, 213, 233;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b4d5e9;
  --bs-btn-active-border-color: #b4d5e9;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #b4d5e9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b4d5e9;
  --bs-gradient: none;
}

.btn-outline-accent-3-500 {
  --bs-btn-color: #6598b5;
  --bs-btn-border-color: #6598b5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #6598b5;
  --bs-btn-hover-border-color: #6598b5;
  --bs-btn-focus-shadow-rgb: 101, 152, 181;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #6598b5;
  --bs-btn-active-border-color: #6598b5;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #6598b5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6598b5;
  --bs-gradient: none;
}

.btn-outline-accent-4-900 {
  --bs-btn-color: #764100;
  --bs-btn-border-color: #764100;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #764100;
  --bs-btn-hover-border-color: #764100;
  --bs-btn-focus-shadow-rgb: 118, 65, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #764100;
  --bs-btn-active-border-color: #764100;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #764100;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #764100;
  --bs-gradient: none;
}

.btn-outline-default-50 {
  --bs-btn-color: #f8f8f8;
  --bs-btn-border-color: #f8f8f8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f8f8;
  --bs-btn-hover-border-color: #f8f8f8;
  --bs-btn-focus-shadow-rgb: 248, 248, 248;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f8f8;
  --bs-btn-active-border-color: #f8f8f8;
  --bs-btn-active-shadow: ;
  --bs-btn-disabled-color: #f8f8f8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f8f8;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #005063;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #005063;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #005063;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #d3eef5;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 38, 117, 138;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.625rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 2px;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 2px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.1rem;
  content: "";
  border-top: 0.25rem solid;
  border-right: 0.25rem solid transparent;
  border-bottom: 0;
  border-left: 0.25rem solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 140px;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-spacer: 0.25rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #333333;
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: #e6e6e6;
  --bs-dropdown-border-radius: 2px;
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14);
  --bs-dropdown-link-color: #333333;
  --bs-dropdown-link-hover-color: #333333;
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #333333;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 0.75rem;
  --bs-dropdown-item-padding-y: 0.375rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.dropdown-menu > .dropdown-item:first-child,
.dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--bs-dropdown-inner-border-radius);
  border-top-right-radius: var(--bs-dropdown-inner-border-radius);
}
.dropdown-menu > .dropdown-item:last-child,
.dropdown-menu > li:last-child .dropdown-item {
  border-bottom-right-radius: var(--bs-dropdown-inner-border-radius);
  border-bottom-left-radius: var(--bs-dropdown-inner-border-radius);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.1rem;
  content: "";
  border-top: 0;
  border-right: 0.25rem solid transparent;
  border-bottom: 0.25rem solid;
  border-left: 0.25rem solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.1rem;
  content: "";
  border-top: 0.25rem solid transparent;
  border-right: 0;
  border-bottom: 0.25rem solid transparent;
  border-left: 0.25rem solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.1rem;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.1rem;
  content: "";
  border-top: 0.25rem solid transparent;
  border-right: 0.25rem solid;
  border-bottom: 0.25rem solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #005d75;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 2px;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(0 * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(0 * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 93, 117, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0;
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: #333333;
  --bs-nav-tabs-link-active-bg: #e2e2e2;
  --bs-nav-tabs-link-active-border-color: transparent;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #005d75;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.34375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2851, 51, 51, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container-xxl, .navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 1rem;
  --bs-card-title-color: #333333;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 2px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0;
  --bs-card-cap-padding-y: 0.75rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #ffffff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: #333333;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 1rem;
  --bs-breadcrumb-padding-y: 0.75rem;
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-font-size: 0.75rem;
  --bs-breadcrumb-bg: #f8f8f8;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #005d75;
  --bs-breadcrumb-item-padding-x: 0.25rem;
  --bs-breadcrumb-item-active-color: #999999;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 93, 117, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #005d75;
  --bs-pagination-active-border-color: #005d75;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.765625rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 1rem;
  --bs-badge-padding-y: 0.375rem;
  --bs-badge-font-size: 0.875rem;
  --bs-badge-font-weight: normal;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 2px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 0.5rem;
  --bs-alert-padding-y: 0.25rem;
  --bs-alert-margin-bottom: 0;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0 solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 2px;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.3125rem 0.5rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-neutral {
  --bs-alert-color: var(--bs-neutral-text-emphasis);
  --bs-alert-bg: var(--bs-neutral-bg-subtle);
  --bs-alert-border-color: var(--bs-neutral-border-subtle);
  --bs-alert-link-color: var(--bs-neutral-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-default {
  --bs-alert-color: var(--bs-default-text-emphasis);
  --bs-alert-bg: var(--bs-default-bg-subtle);
  --bs-alert-border-color: var(--bs-default-border-subtle);
  --bs-alert-link-color: var(--bs-default-text-emphasis);
}

.alert-accent-1 {
  --bs-alert-color: var(--bs-accent-1-text-emphasis);
  --bs-alert-bg: var(--bs-accent-1-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-1-border-subtle);
  --bs-alert-link-color: var(--bs-accent-1-text-emphasis);
}

.alert-accent-2 {
  --bs-alert-color: var(--bs-accent-2-text-emphasis);
  --bs-alert-bg: var(--bs-accent-2-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-2-border-subtle);
  --bs-alert-link-color: var(--bs-accent-2-text-emphasis);
}

.alert-accent-3 {
  --bs-alert-color: var(--bs-accent-3-text-emphasis);
  --bs-alert-bg: var(--bs-accent-3-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-3-border-subtle);
  --bs-alert-link-color: var(--bs-accent-3-text-emphasis);
}

.alert-accent-4 {
  --bs-alert-color: var(--bs-accent-4-text-emphasis);
  --bs-alert-bg: var(--bs-accent-4-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-4-border-subtle);
  --bs-alert-link-color: var(--bs-accent-4-text-emphasis);
}

.alert-accent-5 {
  --bs-alert-color: var(--bs-accent-5-text-emphasis);
  --bs-alert-bg: var(--bs-accent-5-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-5-border-subtle);
  --bs-alert-link-color: var(--bs-accent-5-text-emphasis);
}

.alert-accent-6 {
  --bs-alert-color: var(--bs-accent-6-text-emphasis);
  --bs-alert-bg: var(--bs-accent-6-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-6-border-subtle);
  --bs-alert-link-color: var(--bs-accent-6-text-emphasis);
}

.alert-base {
  --bs-alert-color: var(--bs-base-text-emphasis);
  --bs-alert-bg: var(--bs-base-bg-subtle);
  --bs-alert-border-color: var(--bs-base-border-subtle);
  --bs-alert-link-color: var(--bs-base-text-emphasis);
}

.alert-primary-50 {
  --bs-alert-color: var(--bs-primary-50-text-emphasis);
  --bs-alert-bg: var(--bs-primary-50-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-50-border-subtle);
  --bs-alert-link-color: var(--bs-primary-50-text-emphasis);
}

.alert-primary-400 {
  --bs-alert-color: var(--bs-primary-400-text-emphasis);
  --bs-alert-bg: var(--bs-primary-400-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-400-border-subtle);
  --bs-alert-link-color: var(--bs-primary-400-text-emphasis);
}

.alert-neutral-200 {
  --bs-alert-color: var(--bs-neutral-200-text-emphasis);
  --bs-alert-bg: var(--bs-neutral-200-bg-subtle);
  --bs-alert-border-color: var(--bs-neutral-200-border-subtle);
  --bs-alert-link-color: var(--bs-neutral-200-text-emphasis);
}

.alert-neutral-300 {
  --bs-alert-color: var(--bs-neutral-300-text-emphasis);
  --bs-alert-bg: var(--bs-neutral-300-bg-subtle);
  --bs-alert-border-color: var(--bs-neutral-300-border-subtle);
  --bs-alert-link-color: var(--bs-neutral-300-text-emphasis);
}

.alert-neutral-700 {
  --bs-alert-color: var(--bs-neutral-700-text-emphasis);
  --bs-alert-bg: var(--bs-neutral-700-bg-subtle);
  --bs-alert-border-color: var(--bs-neutral-700-border-subtle);
  --bs-alert-link-color: var(--bs-neutral-700-text-emphasis);
}

.alert-neutral-900 {
  --bs-alert-color: var(--bs-neutral-900-text-emphasis);
  --bs-alert-bg: var(--bs-neutral-900-bg-subtle);
  --bs-alert-border-color: var(--bs-neutral-900-border-subtle);
  --bs-alert-link-color: var(--bs-neutral-900-text-emphasis);
}

.alert-warning-800 {
  --bs-alert-color: var(--bs-warning-800-text-emphasis);
  --bs-alert-bg: var(--bs-warning-800-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-800-border-subtle);
  --bs-alert-link-color: var(--bs-warning-800-text-emphasis);
}

.alert-primary-700 {
  --bs-alert-color: var(--bs-primary-700-text-emphasis);
  --bs-alert-bg: var(--bs-primary-700-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-700-border-subtle);
  --bs-alert-link-color: var(--bs-primary-700-text-emphasis);
}

.alert-success-300 {
  --bs-alert-color: var(--bs-success-300-text-emphasis);
  --bs-alert-bg: var(--bs-success-300-bg-subtle);
  --bs-alert-border-color: var(--bs-success-300-border-subtle);
  --bs-alert-link-color: var(--bs-success-300-text-emphasis);
}

.alert-success-400 {
  --bs-alert-color: var(--bs-success-400-text-emphasis);
  --bs-alert-bg: var(--bs-success-400-bg-subtle);
  --bs-alert-border-color: var(--bs-success-400-border-subtle);
  --bs-alert-link-color: var(--bs-success-400-text-emphasis);
}

.alert-success-700 {
  --bs-alert-color: var(--bs-success-700-text-emphasis);
  --bs-alert-bg: var(--bs-success-700-bg-subtle);
  --bs-alert-border-color: var(--bs-success-700-border-subtle);
  --bs-alert-link-color: var(--bs-success-700-text-emphasis);
}

.alert-accent-1-600 {
  --bs-alert-color: var(--bs-accent-1-600-text-emphasis);
  --bs-alert-bg: var(--bs-accent-1-600-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-1-600-border-subtle);
  --bs-alert-link-color: var(--bs-accent-1-600-text-emphasis);
}

.alert-accent-2-700 {
  --bs-alert-color: var(--bs-accent-2-700-text-emphasis);
  --bs-alert-bg: var(--bs-accent-2-700-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-2-700-border-subtle);
  --bs-alert-link-color: var(--bs-accent-2-700-text-emphasis);
}

.alert-accent-3-100 {
  --bs-alert-color: var(--bs-accent-3-100-text-emphasis);
  --bs-alert-bg: var(--bs-accent-3-100-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-3-100-border-subtle);
  --bs-alert-link-color: var(--bs-accent-3-100-text-emphasis);
}

.alert-accent-3-700 {
  --bs-alert-color: var(--bs-accent-3-700-text-emphasis);
  --bs-alert-bg: var(--bs-accent-3-700-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-3-700-border-subtle);
  --bs-alert-link-color: var(--bs-accent-3-700-text-emphasis);
}

.alert-accent-4-700 {
  --bs-alert-color: var(--bs-accent-4-700-text-emphasis);
  --bs-alert-bg: var(--bs-accent-4-700-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-4-700-border-subtle);
  --bs-alert-link-color: var(--bs-accent-4-700-text-emphasis);
}

.alert-accent-5-600 {
  --bs-alert-color: var(--bs-accent-5-600-text-emphasis);
  --bs-alert-bg: var(--bs-accent-5-600-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-5-600-border-subtle);
  --bs-alert-link-color: var(--bs-accent-5-600-text-emphasis);
}

.alert-accent-3-300 {
  --bs-alert-color: var(--bs-accent-3-300-text-emphasis);
  --bs-alert-bg: var(--bs-accent-3-300-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-3-300-border-subtle);
  --bs-alert-link-color: var(--bs-accent-3-300-text-emphasis);
}

.alert-accent-3-500 {
  --bs-alert-color: var(--bs-accent-3-500-text-emphasis);
  --bs-alert-bg: var(--bs-accent-3-500-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-3-500-border-subtle);
  --bs-alert-link-color: var(--bs-accent-3-500-text-emphasis);
}

.alert-accent-4-900 {
  --bs-alert-color: var(--bs-accent-4-900-text-emphasis);
  --bs-alert-bg: var(--bs-accent-4-900-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-4-900-border-subtle);
  --bs-alert-link-color: var(--bs-accent-4-900-text-emphasis);
}

.alert-default-50 {
  --bs-alert-color: var(--bs-default-50-text-emphasis);
  --bs-alert-bg: var(--bs-default-50-bg-subtle);
  --bs-alert-border-color: var(--bs-default-50-border-subtle);
  --bs-alert-link-color: var(--bs-default-50-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.25rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 0.25rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: 2px;
  --bs-progress-box-shadow: none;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #66a7b8;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #333333;
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: #e6e6e6;
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: 0;
  --bs-list-group-item-padding-x: 0.75rem;
  --bs-list-group-item-padding-y: 0.375rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: #adadad;
  --bs-list-group-disabled-bg: transparent;
  --bs-list-group-active-color: inherit;
  --bs-list-group-active-bg: transparent;
  --bs-list-group-active-border-color: #005d75;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-neutral {
  --bs-list-group-color: var(--bs-neutral-text-emphasis);
  --bs-list-group-bg: var(--bs-neutral-bg-subtle);
  --bs-list-group-border-color: var(--bs-neutral-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-neutral-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-neutral-border-subtle);
  --bs-list-group-active-color: var(--bs-neutral-bg-subtle);
  --bs-list-group-active-bg: var(--bs-neutral-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-neutral-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-default {
  --bs-list-group-color: var(--bs-default-text-emphasis);
  --bs-list-group-bg: var(--bs-default-bg-subtle);
  --bs-list-group-border-color: var(--bs-default-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-default-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-default-border-subtle);
  --bs-list-group-active-color: var(--bs-default-bg-subtle);
  --bs-list-group-active-bg: var(--bs-default-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-default-text-emphasis);
}

.list-group-item-accent-1 {
  --bs-list-group-color: var(--bs-accent-1-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-1-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-1-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-1-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-1-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-1-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-1-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-1-text-emphasis);
}

.list-group-item-accent-2 {
  --bs-list-group-color: var(--bs-accent-2-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-2-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-2-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-2-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-2-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-2-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-2-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-2-text-emphasis);
}

.list-group-item-accent-3 {
  --bs-list-group-color: var(--bs-accent-3-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-3-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-3-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-3-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-3-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-3-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-3-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-3-text-emphasis);
}

.list-group-item-accent-4 {
  --bs-list-group-color: var(--bs-accent-4-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-4-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-4-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-4-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-4-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-4-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-4-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-4-text-emphasis);
}

.list-group-item-accent-5 {
  --bs-list-group-color: var(--bs-accent-5-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-5-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-5-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-5-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-5-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-5-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-5-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-5-text-emphasis);
}

.list-group-item-accent-6 {
  --bs-list-group-color: var(--bs-accent-6-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-6-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-6-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-6-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-6-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-6-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-6-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-6-text-emphasis);
}

.list-group-item-base {
  --bs-list-group-color: var(--bs-base-text-emphasis);
  --bs-list-group-bg: var(--bs-base-bg-subtle);
  --bs-list-group-border-color: var(--bs-base-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-base-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-base-border-subtle);
  --bs-list-group-active-color: var(--bs-base-bg-subtle);
  --bs-list-group-active-bg: var(--bs-base-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-base-text-emphasis);
}

.list-group-item-primary-50 {
  --bs-list-group-color: var(--bs-primary-50-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-50-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-50-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-50-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-50-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-50-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-50-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-50-text-emphasis);
}

.list-group-item-primary-400 {
  --bs-list-group-color: var(--bs-primary-400-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-400-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-400-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-400-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-400-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-400-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-400-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-400-text-emphasis);
}

.list-group-item-neutral-200 {
  --bs-list-group-color: var(--bs-neutral-200-text-emphasis);
  --bs-list-group-bg: var(--bs-neutral-200-bg-subtle);
  --bs-list-group-border-color: var(--bs-neutral-200-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-neutral-200-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-neutral-200-border-subtle);
  --bs-list-group-active-color: var(--bs-neutral-200-bg-subtle);
  --bs-list-group-active-bg: var(--bs-neutral-200-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-neutral-200-text-emphasis);
}

.list-group-item-neutral-300 {
  --bs-list-group-color: var(--bs-neutral-300-text-emphasis);
  --bs-list-group-bg: var(--bs-neutral-300-bg-subtle);
  --bs-list-group-border-color: var(--bs-neutral-300-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-neutral-300-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-neutral-300-border-subtle);
  --bs-list-group-active-color: var(--bs-neutral-300-bg-subtle);
  --bs-list-group-active-bg: var(--bs-neutral-300-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-neutral-300-text-emphasis);
}

.list-group-item-neutral-700 {
  --bs-list-group-color: var(--bs-neutral-700-text-emphasis);
  --bs-list-group-bg: var(--bs-neutral-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-neutral-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-neutral-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-neutral-700-border-subtle);
  --bs-list-group-active-color: var(--bs-neutral-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-neutral-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-neutral-700-text-emphasis);
}

.list-group-item-neutral-900 {
  --bs-list-group-color: var(--bs-neutral-900-text-emphasis);
  --bs-list-group-bg: var(--bs-neutral-900-bg-subtle);
  --bs-list-group-border-color: var(--bs-neutral-900-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-neutral-900-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-neutral-900-border-subtle);
  --bs-list-group-active-color: var(--bs-neutral-900-bg-subtle);
  --bs-list-group-active-bg: var(--bs-neutral-900-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-neutral-900-text-emphasis);
}

.list-group-item-warning-800 {
  --bs-list-group-color: var(--bs-warning-800-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-800-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-800-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-800-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-800-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-800-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-800-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-800-text-emphasis);
}

.list-group-item-primary-700 {
  --bs-list-group-color: var(--bs-primary-700-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-700-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-700-text-emphasis);
}

.list-group-item-success-300 {
  --bs-list-group-color: var(--bs-success-300-text-emphasis);
  --bs-list-group-bg: var(--bs-success-300-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-300-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-300-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-300-border-subtle);
  --bs-list-group-active-color: var(--bs-success-300-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-300-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-300-text-emphasis);
}

.list-group-item-success-400 {
  --bs-list-group-color: var(--bs-success-400-text-emphasis);
  --bs-list-group-bg: var(--bs-success-400-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-400-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-400-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-400-border-subtle);
  --bs-list-group-active-color: var(--bs-success-400-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-400-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-400-text-emphasis);
}

.list-group-item-success-700 {
  --bs-list-group-color: var(--bs-success-700-text-emphasis);
  --bs-list-group-bg: var(--bs-success-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-700-border-subtle);
  --bs-list-group-active-color: var(--bs-success-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-700-text-emphasis);
}

.list-group-item-accent-1-600 {
  --bs-list-group-color: var(--bs-accent-1-600-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-1-600-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-1-600-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-1-600-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-1-600-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-1-600-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-1-600-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-1-600-text-emphasis);
}

.list-group-item-accent-2-700 {
  --bs-list-group-color: var(--bs-accent-2-700-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-2-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-2-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-2-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-2-700-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-2-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-2-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-2-700-text-emphasis);
}

.list-group-item-accent-3-100 {
  --bs-list-group-color: var(--bs-accent-3-100-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-3-100-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-3-100-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-3-100-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-3-100-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-3-100-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-3-100-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-3-100-text-emphasis);
}

.list-group-item-accent-3-700 {
  --bs-list-group-color: var(--bs-accent-3-700-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-3-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-3-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-3-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-3-700-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-3-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-3-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-3-700-text-emphasis);
}

.list-group-item-accent-4-700 {
  --bs-list-group-color: var(--bs-accent-4-700-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-4-700-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-4-700-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-4-700-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-4-700-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-4-700-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-4-700-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-4-700-text-emphasis);
}

.list-group-item-accent-5-600 {
  --bs-list-group-color: var(--bs-accent-5-600-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-5-600-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-5-600-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-5-600-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-5-600-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-5-600-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-5-600-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-5-600-text-emphasis);
}

.list-group-item-accent-3-300 {
  --bs-list-group-color: var(--bs-accent-3-300-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-3-300-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-3-300-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-3-300-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-3-300-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-3-300-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-3-300-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-3-300-text-emphasis);
}

.list-group-item-accent-3-500 {
  --bs-list-group-color: var(--bs-accent-3-500-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-3-500-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-3-500-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-3-500-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-3-500-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-3-500-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-3-500-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-3-500-text-emphasis);
}

.list-group-item-accent-4-900 {
  --bs-list-group-color: var(--bs-accent-4-900-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-4-900-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-4-900-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-4-900-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-4-900-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-4-900-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-4-900-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-4-900-text-emphasis);
}

.list-group-item-default-50 {
  --bs-list-group-color: var(--bs-default-50-text-emphasis);
  --bs-list-group-bg: var(--bs-default-50-bg-subtle);
  --bs-list-group-border-color: var(--bs-default-50-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-default-50-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-default-50-border-subtle);
  --bs-list-group-active-color: var(--bs-default-50-bg-subtle);
  --bs-list-group-active-bg: var(--bs-default-50-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-default-50-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 1;
  --bs-btn-close-hover-opacity: 1;
  --bs-btn-close-focus-shadow: none;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/16px auto no-repeat;
  border: 0;
  border-radius: 3px;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0;
  --bs-toast-padding-y: 0;
  --bs-toast-spacing: 0;
  --bs-toast-max-width: none;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 2px;
  --bs-toast-box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14);
  --bs-toast-header-color: #333333;
  --bs-toast-header-bg: transparent;
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.5rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: transparent;
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 5px;
  --bs-modal-box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12);
  --bs-modal-inner-border-radius: 5px;
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1.5rem 1.5rem 0;
  --bs-modal-header-border-color: transparent;
  --bs-modal-header-border-width: 0;
  --bs-modal-title-line-height: 1.4285714286;
  --bs-modal-footer-gap: 0;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: transparent;
  --bs-modal-footer-border-width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #333333;
  --bs-backdrop-opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 8px 50px 0 rgba(0, 93, 117, 0.08);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 460px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.765625rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: #494844;
  --bs-tooltip-border-radius: 2px;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Akkurat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4285714286;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 300px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: #e6e6e6;
  --bs-popover-border-radius: 3px;
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12);
  --bs-popover-header-padding-x: 0.75rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #333333;
  --bs-popover-header-bg: #ffffff;
  --bs-popover-body-padding-x: 0.75rem;
  --bs-popover-body-padding-y: 0.75rem;
  --bs-popover-body-color: #333333;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Akkurat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4285714286;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 1.25rem;
  --bs-spinner-height: 1.25rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 2px;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 2px;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 1.25rem;
  --bs-spinner-height: 1.25rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  padding: 0;
  color: #999999;
  text-align: center;
  background: none;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #999999;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23999999'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23999999'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23999999'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23999999'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 24px;
  margin-bottom: 1rem;
  margin-left: 24px;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

:root {
  --kendo-color-app-surface: #ffffff;
  --kendo-color-on-app-surface: #212529;
  --kendo-color-subtle: #596169;
  --kendo-color-surface: #f8f9fa;
  --kendo-color-surface-alt: #ffffff;
  --kendo-color-border: rgba(33, 37, 41, 0.13);
  --kendo-color-border-alt: rgba(33, 37, 41, 0.2);
  --kendo-color-base-subtle: #e9ecef;
  --kendo-color-base-subtle-hover: #dee2e6;
  --kendo-color-base-subtle-active: #ced4da;
  --kendo-color-base: #dee2e6;
  --kendo-color-base-hover: #ced4da;
  --kendo-color-base-active: #bdc4cb;
  --kendo-color-base-emphasis: rgba(33, 37, 41, 0.34);
  --kendo-color-base-on-subtle: #212529;
  --kendo-color-on-base: #212529;
  --kendo-color-base-on-surface: #212529;
  --kendo-color-primary-subtle: #cfe2ff;
  --kendo-color-primary-subtle-hover: #b6d4fe;
  --kendo-color-primary-subtle-active: #9ec5fe;
  --kendo-color-primary: #0d6efd;
  --kendo-color-primary-hover: #0c64e4;
  --kendo-color-primary-active: #0a58ca;
  --kendo-color-primary-emphasis: rgba(13, 110, 253, 0.4);
  --kendo-color-primary-on-subtle: #052c65;
  --kendo-color-on-primary: #ffffff;
  --kendo-color-primary-on-surface: #0d6efd;
  --kendo-color-secondary-subtle: #dee2e6;
  --kendo-color-secondary-subtle-hover: #ced4da;
  --kendo-color-secondary-subtle-active: #bdc4cb;
  --kendo-color-secondary: #6c757d;
  --kendo-color-secondary-hover: #596169;
  --kendo-color-secondary-active: #495057;
  --kendo-color-secondary-emphasis: rgba(108, 117, 125, 0.4);
  --kendo-color-secondary-on-subtle: #2B2F32;
  --kendo-color-on-secondary: #ffffff;
  --kendo-color-secondary-on-surface: #6c757d;
  --kendo-color-tertiary-subtle: #e2d9f3;
  --kendo-color-tertiary-subtle-hover: #d3c5ec;
  --kendo-color-tertiary-subtle-active: #c5b3e6;
  --kendo-color-tertiary: #6f42c1;
  --kendo-color-tertiary-hover: #59359a;
  --kendo-color-tertiary-active: #4e2f89;
  --kendo-color-tertiary-emphasis: rgba(111, 66, 193, 0.4);
  --kendo-color-tertiary-on-subtle: #432874;
  --kendo-color-on-tertiary: #ffffff;
  --kendo-color-tertiary-on-surface: #6f42c1;
  --kendo-color-info-subtle: #cff4fc;
  --kendo-color-info-subtle-hover: #b7eefa;
  --kendo-color-info-subtle-active: #9eeaf9;
  --kendo-color-info: #0dcaf0;
  --kendo-color-info-hover: #3dd5f3;
  --kendo-color-info-active: #6edff6;
  --kendo-color-info-emphasis: rgba(13, 202, 240, 0.4);
  --kendo-color-info-on-subtle: #055160;
  --kendo-color-on-info: #000000;
  --kendo-color-info-on-surface: #0dcaf0;
  --kendo-color-success-subtle: #d1e7dd;
  --kendo-color-success-subtle-hover: #badbcc;
  --kendo-color-success-subtle-active: #a3cfbb;
  --kendo-color-success: #198754;
  --kendo-color-success-hover: #146C43;
  --kendo-color-success-active: #0F5132;
  --kendo-color-success-emphasis: rgba(25, 135, 84, 0.4);
  --kendo-color-success-on-subtle: #0A3622;
  --kendo-color-on-success: #ffffff;
  --kendo-color-success-on-surface: #198754;
  --kendo-color-warning-subtle: #fff3cd;
  --kendo-color-warning-subtle-hover: #ffecb5;
  --kendo-color-warning-subtle-active: #ffe69c;
  --kendo-color-warning: #ffc107;
  --kendo-color-warning-hover: #ffcd39;
  --kendo-color-warning-active: #ffda6a;
  --kendo-color-warning-emphasis: rgba(255, 193, 7, 0.4);
  --kendo-color-warning-on-subtle: #664d03;
  --kendo-color-on-warning: #000000;
  --kendo-color-warning-on-surface: #ffc107;
  --kendo-color-error-subtle: #f8d7da;
  --kendo-color-error-subtle-hover: #f5c2c7;
  --kendo-color-error-subtle-active: #f1aeb5;
  --kendo-color-error: #dc3545;
  --kendo-color-error-hover: #b02a37;
  --kendo-color-error-active: #9a2530;
  --kendo-color-error-emphasis: rgba(220, 53, 69, 0.4);
  --kendo-color-error-on-subtle: #58151c;
  --kendo-color-on-error: #ffffff;
  --kendo-color-error-on-surface: #dc3545;
  --kendo-color-light-subtle: #ffffff;
  --kendo-color-light-subtle-hover: #f8f9fa;
  --kendo-color-light-subtle-active: #e9ecef;
  --kendo-color-light: #f8f9fa;
  --kendo-color-light-hover: #e9ecef;
  --kendo-color-light-active: #dee2e6;
  --kendo-color-light-emphasis: rgba(33, 37, 41, 0.1);
  --kendo-color-light-on-subtle: #495057;
  --kendo-color-on-light: #000000;
  --kendo-color-light-on-surface: #f8f9fa;
  --kendo-color-dark-subtle: #495057;
  --kendo-color-dark-subtle-hover: #596169;
  --kendo-color-dark-subtle-active: #6c757d;
  --kendo-color-dark: #212529;
  --kendo-color-dark-hover: #343a40;
  --kendo-color-dark-active: #495057;
  --kendo-color-dark-emphasis: rgba(33, 37, 41, 0.4);
  --kendo-color-dark-on-subtle: #ffffff;
  --kendo-color-on-dark: #ffffff;
  --kendo-color-dark-on-surface: #212529;
  --kendo-color-inverse-subtle: #495057;
  --kendo-color-inverse-subtle-hover: #596169;
  --kendo-color-inverse-subtle-active: #6c757d;
  --kendo-color-inverse: #212529;
  --kendo-color-inverse-hover: #343a40;
  --kendo-color-inverse-active: #495057;
  --kendo-color-inverse-emphasis: rgba(33, 37, 41, 0.4);
  --kendo-color-inverse-on-subtle: #ffffff;
  --kendo-color-on-inverse: #ffffff;
  --kendo-color-inverse-on-surface: #212529;
  --kendo-color-series-a: #0c64e4;
  --kendo-color-series-a-bold: #084298;
  --kendo-color-series-a-bolder: #052c65;
  --kendo-color-series-a-subtle: #3d8bfd;
  --kendo-color-series-a-subtler: #86b6fe;
  --kendo-color-series-b: #6f42c1;
  --kendo-color-series-b-bold: #4e2f89;
  --kendo-color-series-b-bolder: #36215f;
  --kendo-color-series-b-subtle: #8c68cd;
  --kendo-color-series-b-subtler: #b9a3e1;
  --kendo-color-series-c: #20c997;
  --kendo-color-series-c-bold: #13795b;
  --kendo-color-series-c-bolder: #0d503c;
  --kendo-color-series-c-subtle: #79dfc1;
  --kendo-color-series-c-subtler: #c1f0e2;
  --kendo-color-series-d: #198754;
  --kendo-color-series-d-bold: #0F5132;
  --kendo-color-series-d-bolder: #0A3622;
  --kendo-color-series-d-subtle: #479F76;
  --kendo-color-series-d-subtler: #8cc3aa;
  --kendo-color-series-e: #dc3545;
  --kendo-color-series-e-bold: #9a2530;
  --kendo-color-series-e-bolder: #6e1b23;
  --kendo-color-series-e-subtle: #e35d6a;
  --kendo-color-series-e-subtler: #f1aeb5;
  --kendo-color-series-f: #ffc107;
  --kendo-color-series-f-bold: #b38705;
  --kendo-color-series-f-bolder: #806104;
  --kendo-color-series-f-subtle: #ffcd39;
  --kendo-color-series-f-subtler: #ffe083;
}

.k-sr-only {
  position: absolute;
  left: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.k-push-right-enter, .k-push-right-appear {
  transform: translate(-100%, 0);
}
.k-push-right-enter-active, .k-push-right-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-right-exit {
  transform: translate(0, 0);
}
.k-push-right-exit-active {
  transform: translate(100%, 0);
  transition: transform 300ms ease-in-out;
}

.k-push-left-enter, .k-push-left-appear {
  transform: translate(100%, 0);
}
.k-push-left-enter-active, .k-push-left-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-left-exit {
  transform: translate(0, 0);
}
.k-push-left-exit-active {
  transform: translate(-100%, 0);
  transition: transform 300ms ease-in-out;
}

.k-push-down-enter, .k-push-down-appear {
  transform: translate(0, -100%);
}
.k-push-down-enter-active, .k-push-down-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-down-exit {
  transform: translate(0, 0);
}
.k-push-down-exit-active {
  transform: translate(0, 100%);
  transition: transform 300ms ease-in-out;
}

.k-push-up-enter, .k-push-up-appear {
  transform: translate(0, 100%);
}
.k-push-up-enter-active, .k-push-up-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-up-exit {
  transform: translate(0, 0);
}
.k-push-up-exit-active {
  transform: translate(0, -100%);
  transition: transform 300ms ease-in-out;
}

.k-expand-vertical-enter, .k-expand-vertical-appear {
  transform: scaleY(0);
}
.k-expand-vertical-enter-active, .k-expand-vertical-appear-active {
  transform: scaleY(1);
  transition: transform 300ms ease-in-out;
}
.k-expand-vertical-exit {
  transform: scaleY(1);
}
.k-expand-vertical-exit-active {
  transform: scaleY(0);
  transition: transform 300ms ease-in-out;
}

.k-expand-horizontal-enter, .k-expand-horizontal-appear {
  transform: scaleX(0);
}
.k-expand-horizontal-enter-active, .k-expand-horizontal-appear-active {
  transform: scaleX(1);
  transition: transform 300ms ease-in-out;
}
.k-expand-horizontal-exit {
  transform: scaleX(1);
}
.k-expand-horizontal-exit-active {
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
}

.k-fade-enter, .k-fade-appear {
  opacity: 0;
}
.k-fade-enter-active, .k-fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
.k-fade-exit {
  opacity: 1;
}
.k-fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.k-fade-exit-active + .k-fade-exit-active, .k-fade-enter-active + .k-fade-enter-active {
  display: none;
}

.k-zoom-in-enter, .k-zoom-in-appear {
  opacity: 0;
  transform: scale(0);
}
.k-zoom-in-enter-active, .k-zoom-in-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: transform, opacity 300ms ease-in-out;
}
.k-zoom-in-exit {
  opacity: 1;
  transform: scale(1);
}
.k-zoom-in-exit-active {
  opacity: 0;
  transform: scale(2);
  transition: transform, opacity 300ms ease-in-out;
}

.k-zoom-out-enter, .k-zoom-out-appear {
  opacity: 0;
  transform: scale(2);
}
.k-zoom-out-enter-active, .k-zoom-out-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: transform, opacity 300ms ease-in-out;
}
.k-zoom-out-exit {
  opacity: 1;
  transform: scale(1);
}
.k-zoom-out-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: transform, opacity 300ms ease-in-out;
}

.k-slide-in-appear {
  opacity: 0.1;
  transform: translate(0, -3em);
}
.k-slide-in-appear .k-centered {
  transform: translate(-50%, -60%);
}
.k-slide-in-appear-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 0.3s cubic-bezier(0.2, 0.6, 0.4, 1), opacity 0.3s cubic-bezier(0.2, 1, 0.2, 1);
}
.k-slide-in-appear-active .k-centered {
  transform: translate(-50%, -50%);
}

.k-slide-down-enter, .k-slide-down-appear {
  transform: translateY(-100%);
}
.k-slide-down-enter-active, .k-slide-down-appear-active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-down-exit {
  transform: translateY(0);
}
.k-slide-down-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-up-enter, .k-slide-up-appear {
  transform: translateY(100%);
}
.k-slide-up-enter-active, .k-slide-up-appear-active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-up-exit {
  transform: translateY(0);
}
.k-slide-up-exit-active {
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-right-enter, .k-slide-right-appear {
  transform: translateX(-100%);
}
.k-slide-right-enter-active, .k-slide-right-appear-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-right-exit {
  transform: translateX(0);
}
.k-slide-right-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-left-enter, .k-slide-left-appear {
  transform: translateX(100%);
}
.k-slide-left-enter-active, .k-slide-left-appear-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-left-exit {
  transform: translateX(0);
}
.k-slide-left-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.k-reveal-vertical-enter, .k-reveal-vertical-appear {
  max-height: 0;
}
.k-reveal-vertical-enter-active, .k-reveal-vertical-appear-active {
  transition: max-height 300ms ease-in-out;
}
.k-reveal-vertical-exit-active {
  max-height: 0 !important;
  transition: max-height 300ms ease-in-out;
}

.k-reveal-horizontal-enter, .k-reveal-horizontal-appear {
  max-width: 0;
}
.k-reveal-horizontal-enter-active, .k-reveal-horizontal-appear-active {
  transition: max-width 300ms ease-in-out;
}
.k-reveal-horizontal-exit-active {
  max-width: 0 !important;
  transition: max-width 300ms ease-in-out;
}

.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  transition: all 350ms ease-out;
}

.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}

.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}

.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}

.k-fx-zoom.k-fx-start .k-fx-next {
  transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next, .k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  transform: scale(0) !important;
}

.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}

.k-fx-slide.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-content, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer, .k-fx-slide.k-fx-end .k-fx-current .km-content, .k-fx-slide.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-end .k-fx-current .km-footer {
  transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  transform: translateX(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0%);
}

.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: translateX(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0%);
}

.k-fx.k-fx-overlay.k-fx-start .k-fx-next, .k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  transform: translateY(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current, .k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  transform: translateY(-100%);
}

.k-theme-test-class,
.k-common-test-class {
  opacity: 0;
}

.k-hstack, .k-hbox {
  display: flex;
  flex-flow: row nowrap;
}

.k-vstack, .k-vbox {
  display: flex;
  flex-flow: column nowrap;
}

.k-spacer, .k-flex {
  flex: 1 1 auto;
}

.k-spacer-sized {
  flex: none;
}

.k-float-wrap::after, .k-floatwrap::after {
  content: "";
  display: block;
  clear: both;
}

.k-flex-layout {
  display: flex;
}

.k-grid-layout {
  display: grid;
}

.k-separator {
  width: auto;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  display: block;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  align-self: stretch;
}

.k-separator-horizontal,
.k-vstack > .k-separator,
.k-vbox > .k-separator {
  width: auto;
  height: 0;
  border-width: 1px 0 0;
}

.k-separator-vertical,
.k-hstack > .k-separator,
.k-hbox > .k-separator {
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
}

hr.k-separator {
  margin: 0;
  padding: 0;
}

.k-hidden {
  display: none !important;
}

.k-rtl {
  direction: rtl;
}

[hidden] {
  display: none !important;
}

script {
  display: none !important;
}

.k-disabled,
.k-widget[disabled],
.k-disabled {
  outline: none;
  cursor: default;
  color: var(--kendo-disabled-text, );
  background-color: var(--kendo-disabled-bg, );
  border-color: var(--kendo-disabled-border, );
  opacity: var(--kendo-disabled-opacity, 0.65);
  filter: var(--kendo-disabled-filter, grayscale(0.1));
  pointer-events: none;
  box-shadow: none;
}
.k-disabled .k-link,
.k-widget[disabled] .k-link,
.k-disabled .k-link {
  cursor: default;
  outline: 0;
}
.k-disabled [disabled],
.k-disabled .k-disabled,
.k-widget[disabled] [disabled],
.k-widget[disabled] .k-disabled,
.k-disabled [disabled],
.k-disabled .k-disabled {
  --kendo-disabled-opacity: 1;
  --kendo-disabled-filter: grayscale(0);
}

.k-hr {
  margin-block: var(--kendo-spacing-4, 1rem);
  padding: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--kendo-component-border, var(--kendo-color-border, rgba(33, 37, 41, 0.13)));
  display: block;
  float: none;
  clear: both;
}

.k-d-flex-row > .k-hr {
  margin: 0;
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
  flex: 0 0 auto;
}

.k-d-flex-col > .k-hr {
  margin: 0;
  flex: 0 0 auto;
}

.k-sprite {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.k-image {
  display: inline-block;
}

.k-reset {
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;
  text-decoration: none;
  font: inherit;
  list-style: none;
}

kendo-sortable {
  display: block;
}

.k-link,
.k-link:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

.k-content {
  outline: 0;
}

.k-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-no-click {
  pointer-events: none;
}

.k-pdf-export-shadow {
  position: absolute;
  overflow: hidden;
  left: -15000px;
  width: 14400px;
}

.kendo-pdf-hide-pseudo-elements::before,
.kendo-pdf-hide-pseudo-elements::after {
  display: none !important;
}

.k-dirty {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  border-block-start-color: currentColor;
  border-block-end-color: transparent;
  border-inline-start-color: transparent;
  border-inline-end-color: currentColor;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
}

.k-marquee {
  position: absolute;
  z-index: 100000;
}

.k-marquee-color,
.k-marquee-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.k-marquee-color {
  color: var(--kendo-neutral-160, var(--kendo-color-on-primary, #ffffff));
  background-color: var(--kendo-primary-60, var(--kendo-color-primary, #0d6efd));
  border-color: var(--kendo-primary-100, var(--kendo-color-primary, #0d6efd));
  opacity: 0.6;
}

.k-marquee-text {
  color: var(--kendo-neutral-160, var(--kendo-color-on-primary, #ffffff));
}

.k-text-selection ::selection {
  background-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
}

.k-scrollbar {
  position: absolute;
  overflow: scroll;
}

.k-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 17px;
  height: 100%;
  overflow-x: hidden;
}

.k-touch-scrollbar {
  display: none;
  position: absolute;
  z-index: 200000;
  height: 8px;
  width: 8px;
  border: 1px solid #8a8a8a;
  background-color: #858585;
}

.k-resize-handle,
.k-resize-hint {
  position: absolute;
  border-color: inherit;
  z-index: 200;
}

.k-resize-handle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-resize-handle::before {
  content: "";
  border: 0 solid;
  border-color: inherit;
}

.k-resize-n {
  width: 100%;
  height: 6px;
  flex-direction: row;
  left: 0;
  top: -3px;
  cursor: n-resize;
}

.k-resize-s {
  width: 100%;
  height: 6px;
  flex-direction: row;
  left: 0;
  bottom: -3px;
  cursor: s-resize;
}

.k-resize-w {
  width: 6px;
  height: 100%;
  flex-direction: column;
  top: 0;
  left: -3px;
  cursor: w-resize;
}

.k-resize-e {
  width: 6px;
  height: 100%;
  flex-direction: column;
  top: 0;
  right: -3px;
  cursor: e-resize;
}

.k-resize-sw,
.k-resize-se,
.k-resize-nw,
.k-resize-ne {
  width: 5px;
  height: 5px;
}

.k-resize-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
}

.k-resize-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.k-resize-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
}

.k-resize-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
}

.k-vertical-resize {
  cursor: row-resize;
}

.k-horizontal-resize {
  cursor: col-resize;
}

.k-resize-hint {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.k-resize-hint .k-resize-hint-handle {
  width: auto;
  height: 20px;
  align-self: stretch;
}
.k-resize-hint .k-resize-hint-marker {
  width: 2px;
  height: auto;
  flex: 1 1 auto;
}

.k-resize-hint-vertical {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.k-resize-hint-vertical .k-resize-hint-handle {
  width: 20px;
  height: auto;
  align-self: stretch;
}
.k-resize-hint-vertical .k-resize-hint-marker {
  width: auto;
  height: 2px;
  flex: 1 1 auto;
}

:root {
  --kendo-elevation-1: 0px 1px 2px rgba(0, 0, 0, 0.038);
  --kendo-elevation-2: 0px 2px 7px rgba(0, 0, 0, 0.075);
  --kendo-elevation-3: 0px 4px 10px rgba(0, 0, 0, 0.1);
  --kendo-elevation-4: 0px 6px 13px rgba(0, 0, 0, 0.125);
  --kendo-elevation-5: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  --kendo-elevation-6: 0px 11px 24px 0px rgba(0, 0, 0, 0.159);
  --kendo-elevation-7: 0px 14px 36px 0px rgba(0, 0, 0, 0.168);
  --kendo-elevation-8: 0px 16px 48px 0px rgba(0, 0, 0, 0.176);
  --kendo-elevation-9: 0px 18px 60px 0px rgba(0, 0, 0, 0.185);
}

:root {
  --kendo-font-family: "Akkurat", sans-serif;
  --kendo-font-size: 0.875rem;
  --kendo-line-height: 1.4285714286;
  --kendo-font-weight: 400;
  --kendo-letter-spacing: ;
  --kendo-font-size-xs: 0.65625rem;
  --kendo-font-size-sm: 0.765625rem;
  --kendo-font-size-md: 0.875rem;
  --kendo-font-size-lg: 1.09375rem;
  --kendo-font-size-xl: 1.3125rem;
  --kendo-line-height-xs: 1;
  --kendo-line-height-sm: 1.25;
  --kendo-line-height-md: 1.4285714286;
  --kendo-line-height-lg: 2;
  --kendo-font-weight-light: 300;
  --kendo-font-weight-normal: 400;
  --kendo-font-weight-medium: 500;
  --kendo-font-weight-semibold: 600;
  --kendo-font-weight-bold: 600;
  --kendo-letter-spacing-tightest: -0.15px;
  --kendo-letter-spacing-tighter: -0.1px;
  --kendo-letter-spacing-tight: -0.5px;
  --kendo-letter-spacing-normal: 0px;
  --kendo-letter-spacing-wide: 0.5px;
  --kendo-letter-spacing-wider: 0.1px;
  --kendo-letter-spacing-widest: 0.15px;
  --kendo-font-family-sans-serif: Akkurat, sans-serif;
  --kendo-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

:root {
  --kendo-spacing-0: 0px;
  --kendo-spacing-1px: 1px;
  --kendo-spacing-0\.5: 0.125rem;
  --kendo-spacing-1: 0.25rem;
  --kendo-spacing-1\.5: 0.375rem;
  --kendo-spacing-2: 0.5rem;
  --kendo-spacing-2\.5: 0.625rem;
  --kendo-spacing-3: 0.75rem;
  --kendo-spacing-3\.5: 0.875rem;
  --kendo-spacing-4: 1rem;
  --kendo-spacing-4\.5: 1.125rem;
  --kendo-spacing-5: 1.25rem;
  --kendo-spacing-5\.5: 1.375rem;
  --kendo-spacing-6: 1.5rem;
  --kendo-spacing-6\.5: 1.625rem;
  --kendo-spacing-7: 1.75rem;
  --kendo-spacing-7\.5: 1.875rem;
  --kendo-spacing-8: 2rem;
  --kendo-spacing-9: 2.25rem;
  --kendo-spacing-10: 2.5rem;
  --kendo-spacing-11: 2.75rem;
  --kendo-spacing-12: 3rem;
  --kendo-spacing-13: 3.25rem;
  --kendo-spacing-14: 3.5rem;
  --kendo-spacing-15: 3.75rem;
  --kendo-spacing-16: 4rem;
  --kendo-spacing-17: 4.25rem;
  --kendo-spacing-18: 4.5rem;
  --kendo-spacing-19: 4.75rem;
  --kendo-spacing-20: 5rem;
  --kendo-spacing-21: 5.25rem;
  --kendo-spacing-22: 5.5rem;
  --kendo-spacing-23: 5.75rem;
  --kendo-spacing-24: 6rem;
  --kendo-spacing-25: 7rem;
  --kendo-spacing-26: 8rem;
  --kendo-spacing-27: 9rem;
  --kendo-spacing-28: 10rem;
  --kendo-spacing-29: 11rem;
  --kendo-spacing-30: 12rem;
}

:root {
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-xs: 0.125rem;
  --kendo-border-radius-sm: 2px;
  --kendo-border-radius-md: 3px;
  --kendo-border-radius-lg: 10px;
  --kendo-border-radius-xl: 0.75rem;
  --kendo-border-radius-xxl: 1rem;
  --kendo-border-radius-xxxl: 2rem;
  --kendo-border-radius-full: 50rem;
}

.k-ratio-auto {
  aspect-ratio: auto;
}

.\!k-ratio-auto {
  aspect-ratio: auto !important;
}

.k-ratio-1 {
  aspect-ratio: 1;
}

.\!k-ratio-1 {
  aspect-ratio: 1 !important;
}

.k-ratio-square {
  aspect-ratio: 1 / 1;
}

.\!k-ratio-square {
  aspect-ratio: 1 / 1 !important;
}

.k-ratio-video {
  aspect-ratio: 16 / 9;
}

.\!k-ratio-video {
  aspect-ratio: 16 / 9 !important;
}

.k-aspect-ratio-auto {
  aspect-ratio: auto;
}

.\!k-aspect-ratio-auto {
  aspect-ratio: auto !important;
}

.k-aspect-ratio-1 {
  aspect-ratio: 1;
}

.\!k-aspect-ratio-1 {
  aspect-ratio: 1 !important;
}

.k-aspect-ratio-square {
  aspect-ratio: 1 / 1;
}

.\!k-aspect-ratio-square {
  aspect-ratio: 1 / 1 !important;
}

.k-aspect-ratio-video {
  aspect-ratio: 16 / 9;
}

.\!k-aspect-ratio-video {
  aspect-ratio: 16 / 9 !important;
}

.k-box-sizing-border {
  box-sizing: border-box;
}

.\!k-box-sizing-border {
  box-sizing: border-box !important;
}

.k-box-sizing-content {
  box-sizing: content-box;
}

.\!k-box-sizing-content {
  box-sizing: content-box !important;
}

.k-clear-left {
  clear: left;
}

.\!k-clear-left {
  clear: left !important;
}

.k-clear-right {
  clear: right;
}

.\!k-clear-right {
  clear: right !important;
}

.k-clear-both {
  clear: both;
}

.\!k-clear-both {
  clear: both !important;
}

.k-clear-none {
  clear: none;
}

.\!k-clear-none {
  clear: none !important;
}

.k-columns-1 {
  columns: 1;
}

.\!k-columns-1 {
  columns: 1 !important;
}

.k-columns-2 {
  columns: 2;
}

.\!k-columns-2 {
  columns: 2 !important;
}

.k-columns-3 {
  columns: 3;
}

.\!k-columns-3 {
  columns: 3 !important;
}

.k-columns-4 {
  columns: 4;
}

.\!k-columns-4 {
  columns: 4 !important;
}

.k-columns-5 {
  columns: 5;
}

.\!k-columns-5 {
  columns: 5 !important;
}

.k-columns-6 {
  columns: 6;
}

.\!k-columns-6 {
  columns: 6 !important;
}

.k-columns-7 {
  columns: 7;
}

.\!k-columns-7 {
  columns: 7 !important;
}

.k-columns-8 {
  columns: 8;
}

.\!k-columns-8 {
  columns: 8 !important;
}

.k-columns-9 {
  columns: 9;
}

.\!k-columns-9 {
  columns: 9 !important;
}

.k-columns-10 {
  columns: 10;
}

.\!k-columns-10 {
  columns: 10 !important;
}

.k-columns-11 {
  columns: 11;
}

.\!k-columns-11 {
  columns: 11 !important;
}

.k-columns-12 {
  columns: 12;
}

.\!k-columns-12 {
  columns: 12 !important;
}

.k-columns-auto {
  columns: auto;
}

.\!k-columns-auto {
  columns: auto !important;
}

.k-d-none {
  display: none;
}

.\!k-d-none {
  display: none !important;
}

.k-d-contents {
  display: contents;
}

.\!k-d-contents {
  display: contents !important;
}

.k-d-block {
  display: block;
}

.\!k-d-block {
  display: block !important;
}

.k-d-inline {
  display: inline;
}

.\!k-d-inline {
  display: inline !important;
}

.k-d-inline-block {
  display: inline-block;
}

.\!k-d-inline-block {
  display: inline-block !important;
}

.k-d-flex, .k-d-flex-col, .k-d-flex-row {
  display: flex;
}

.\!k-d-flex, .\!k-d-flex-col, .\!k-d-flex-row {
  display: flex !important;
}

.k-d-inline-flex {
  display: inline-flex;
}

.\!k-d-inline-flex {
  display: inline-flex !important;
}

.k-d-grid {
  display: grid;
}

.\!k-d-grid {
  display: grid !important;
}

.k-d-inline-grid {
  display: inline-grid;
}

.\!k-d-inline-grid {
  display: inline-grid !important;
}

.k-d-table {
  display: table;
}

.\!k-d-table {
  display: table !important;
}

.k-d-inline-table {
  display: inline-table;
}

.\!k-d-inline-table {
  display: inline-table !important;
}

.k-d-table-row {
  display: table-row;
}

.\!k-d-table-row {
  display: table-row !important;
}

.k-d-table-cell {
  display: table-cell;
}

.\!k-d-table-cell {
  display: table-cell !important;
}

.k-d-list-item {
  display: list-item;
}

.\!k-d-list-item {
  display: list-item !important;
}

.k-display-none {
  display: none;
}

.\!k-display-none {
  display: none !important;
}

.k-display-contents {
  display: contents;
}

.\!k-display-contents {
  display: contents !important;
}

.k-display-block {
  display: block;
}

.\!k-display-block {
  display: block !important;
}

.k-display-inline {
  display: inline;
}

.\!k-display-inline {
  display: inline !important;
}

.k-display-inline-block {
  display: inline-block;
}

.\!k-display-inline-block {
  display: inline-block !important;
}

.k-display-flex {
  display: flex;
}

.\!k-display-flex {
  display: flex !important;
}

.k-display-inline-flex {
  display: inline-flex;
}

.\!k-display-inline-flex {
  display: inline-flex !important;
}

.k-display-grid {
  display: grid;
}

.\!k-display-grid {
  display: grid !important;
}

.k-display-inline-grid {
  display: inline-grid;
}

.\!k-display-inline-grid {
  display: inline-grid !important;
}

.k-display-table {
  display: table;
}

.\!k-display-table {
  display: table !important;
}

.k-display-inline-table {
  display: inline-table;
}

.\!k-display-inline-table {
  display: inline-table !important;
}

.k-display-table-row {
  display: table-row;
}

.\!k-display-table-row {
  display: table-row !important;
}

.k-display-table-cell {
  display: table-cell;
}

.\!k-display-table-cell {
  display: table-cell !important;
}

.k-display-list-item {
  display: list-item;
}

.\!k-display-list-item {
  display: list-item !important;
}

.k-float-left {
  float: left;
}

.\!k-float-left {
  float: left !important;
}

.k-float-right {
  float: right;
}

.\!k-float-right {
  float: right !important;
}

.k-float-none {
  float: none;
}

.\!k-float-none {
  float: none !important;
}

.k-object-fit-contain {
  object-fit: contain;
}

.\!k-object-fit-contain {
  object-fit: contain !important;
}

.k-object-fit-cover {
  object-fit: cover;
}

.\!k-object-fit-cover {
  object-fit: cover !important;
}

.k-object-fit-fill {
  object-fit: fill;
}

.\!k-object-fit-fill {
  object-fit: fill !important;
}

.k-object-fit-scale-down {
  object-fit: scale-down;
}

.\!k-object-fit-scale-down {
  object-fit: scale-down !important;
}

.k-object-fit-initial {
  object-fit: initial;
}

.\!k-object-fit-initial {
  object-fit: initial !important;
}

.k-object-fit-none {
  object-fit: none;
}

.\!k-object-fit-none {
  object-fit: none !important;
}

.k-object-position-center {
  object-position: center;
}

.\!k-object-position-center {
  object-position: center !important;
}

.k-object-position-top {
  object-position: top;
}

.\!k-object-position-top {
  object-position: top !important;
}

.k-object-position-right {
  object-position: right;
}

.\!k-object-position-right {
  object-position: right !important;
}

.k-object-position-bottom {
  object-position: bottom;
}

.\!k-object-position-bottom {
  object-position: bottom !important;
}

.k-object-position-left {
  object-position: left;
}

.\!k-object-position-left {
  object-position: left !important;
}

.k-object-position-top-left {
  object-position: top left;
}

.\!k-object-position-top-left {
  object-position: top left !important;
}

.k-object-position-top-right {
  object-position: top right;
}

.\!k-object-position-top-right {
  object-position: top right !important;
}

.k-object-position-bottom-left {
  object-position: bottom left;
}

.\!k-object-position-bottom-left {
  object-position: bottom left !important;
}

.k-object-position-bottom-right {
  object-position: bottom right;
}

.\!k-object-position-bottom-right {
  object-position: bottom right !important;
}

.k-overflow-auto {
  overflow: auto;
}

.\!k-overflow-auto {
  overflow: auto !important;
}

.k-overflow-hidden {
  overflow: hidden;
}

.\!k-overflow-hidden {
  overflow: hidden !important;
}

.k-overflow-visible {
  overflow: visible;
}

.\!k-overflow-visible {
  overflow: visible !important;
}

.k-overflow-scroll {
  overflow: scroll;
}

.\!k-overflow-scroll {
  overflow: scroll !important;
}

.k-overflow-clip {
  overflow: clip;
}

.\!k-overflow-clip {
  overflow: clip !important;
}

.k-overflow-x-auto {
  overflow-x: auto;
}

.\!k-overflow-x-auto {
  overflow-x: auto !important;
}

.k-overflow-x-hidden {
  overflow-x: hidden;
}

.\!k-overflow-x-hidden {
  overflow-x: hidden !important;
}

.k-overflow-x-visible {
  overflow-x: visible;
}

.\!k-overflow-x-visible {
  overflow-x: visible !important;
}

.k-overflow-x-scroll {
  overflow-x: scroll;
}

.\!k-overflow-x-scroll {
  overflow-x: scroll !important;
}

.k-overflow-x-clip {
  overflow-x: clip;
}

.\!k-overflow-x-clip {
  overflow-x: clip !important;
}

.k-overflow-y-auto {
  overflow-y: auto;
}

.\!k-overflow-y-auto {
  overflow-y: auto !important;
}

.k-overflow-y-hidden {
  overflow-y: hidden;
}

.\!k-overflow-y-hidden {
  overflow-y: hidden !important;
}

.k-overflow-y-visible {
  overflow-y: visible;
}

.\!k-overflow-y-visible {
  overflow-y: visible !important;
}

.k-overflow-y-scroll {
  overflow-y: scroll;
}

.\!k-overflow-y-scroll {
  overflow-y: scroll !important;
}

.k-overflow-y-clip {
  overflow-y: clip;
}

.\!k-overflow-y-clip {
  overflow-y: clip !important;
}

.k-top-0 {
  top: 0px;
}

.\!k-top-0 {
  top: 0px !important;
}

.k-top-1px {
  top: 1px;
}

.\!k-top-1px {
  top: 1px !important;
}

.k-top-0\.5 {
  top: 0.125rem;
}

.\!k-top-0\.5 {
  top: 0.125rem !important;
}

.k-top-1 {
  top: 0.25rem;
}

.\!k-top-1 {
  top: 0.25rem !important;
}

.k-top-1\.5 {
  top: 0.375rem;
}

.\!k-top-1\.5 {
  top: 0.375rem !important;
}

.k-top-2 {
  top: 0.5rem;
}

.\!k-top-2 {
  top: 0.5rem !important;
}

.k-top-2\.5 {
  top: 0.625rem;
}

.\!k-top-2\.5 {
  top: 0.625rem !important;
}

.k-top-3 {
  top: 0.75rem;
}

.\!k-top-3 {
  top: 0.75rem !important;
}

.k-top-3\.5 {
  top: 0.875rem;
}

.\!k-top-3\.5 {
  top: 0.875rem !important;
}

.k-top-4 {
  top: 1rem;
}

.\!k-top-4 {
  top: 1rem !important;
}

.k-top-4\.5 {
  top: 1.125rem;
}

.\!k-top-4\.5 {
  top: 1.125rem !important;
}

.k-top-5 {
  top: 1.25rem;
}

.\!k-top-5 {
  top: 1.25rem !important;
}

.k-top-5\.5 {
  top: 1.375rem;
}

.\!k-top-5\.5 {
  top: 1.375rem !important;
}

.k-top-6 {
  top: 1.5rem;
}

.\!k-top-6 {
  top: 1.5rem !important;
}

.k-top-6\.5 {
  top: 1.625rem;
}

.\!k-top-6\.5 {
  top: 1.625rem !important;
}

.k-top-7 {
  top: 1.75rem;
}

.\!k-top-7 {
  top: 1.75rem !important;
}

.k-top-7\.5 {
  top: 1.875rem;
}

.\!k-top-7\.5 {
  top: 1.875rem !important;
}

.k-top-8 {
  top: 2rem;
}

.\!k-top-8 {
  top: 2rem !important;
}

.k-top-9 {
  top: 2.25rem;
}

.\!k-top-9 {
  top: 2.25rem !important;
}

.k-top-10 {
  top: 2.5rem;
}

.\!k-top-10 {
  top: 2.5rem !important;
}

.k-top-11 {
  top: 2.75rem;
}

.\!k-top-11 {
  top: 2.75rem !important;
}

.k-top-12 {
  top: 3rem;
}

.\!k-top-12 {
  top: 3rem !important;
}

.k-top-13 {
  top: 3.25rem;
}

.\!k-top-13 {
  top: 3.25rem !important;
}

.k-top-14 {
  top: 3.5rem;
}

.\!k-top-14 {
  top: 3.5rem !important;
}

.k-top-15 {
  top: 3.75rem;
}

.\!k-top-15 {
  top: 3.75rem !important;
}

.k-top-16 {
  top: 4rem;
}

.\!k-top-16 {
  top: 4rem !important;
}

.k-top-17 {
  top: 4.25rem;
}

.\!k-top-17 {
  top: 4.25rem !important;
}

.k-top-18 {
  top: 4.5rem;
}

.\!k-top-18 {
  top: 4.5rem !important;
}

.k-top-19 {
  top: 4.75rem;
}

.\!k-top-19 {
  top: 4.75rem !important;
}

.k-top-20 {
  top: 5rem;
}

.\!k-top-20 {
  top: 5rem !important;
}

.k-top-21 {
  top: 5.25rem;
}

.\!k-top-21 {
  top: 5.25rem !important;
}

.k-top-22 {
  top: 5.5rem;
}

.\!k-top-22 {
  top: 5.5rem !important;
}

.k-top-23 {
  top: 5.75rem;
}

.\!k-top-23 {
  top: 5.75rem !important;
}

.k-top-24 {
  top: 6rem;
}

.\!k-top-24 {
  top: 6rem !important;
}

.k-top-25 {
  top: 7rem;
}

.\!k-top-25 {
  top: 7rem !important;
}

.k-top-26 {
  top: 8rem;
}

.\!k-top-26 {
  top: 8rem !important;
}

.k-top-27 {
  top: 9rem;
}

.\!k-top-27 {
  top: 9rem !important;
}

.k-top-28 {
  top: 10rem;
}

.\!k-top-28 {
  top: 10rem !important;
}

.k-top-29 {
  top: 11rem;
}

.\!k-top-29 {
  top: 11rem !important;
}

.k-top-30 {
  top: 12rem;
}

.\!k-top-30 {
  top: 12rem !important;
}

.k-top--1 {
  top: -1px;
}

.\!k-top--1 {
  top: -1px !important;
}

.k-right-0 {
  right: 0px;
}

.\!k-right-0 {
  right: 0px !important;
}

.k-right-1px {
  right: 1px;
}

.\!k-right-1px {
  right: 1px !important;
}

.k-right-0\.5 {
  right: 0.125rem;
}

.\!k-right-0\.5 {
  right: 0.125rem !important;
}

.k-right-1 {
  right: 0.25rem;
}

.\!k-right-1 {
  right: 0.25rem !important;
}

.k-right-1\.5 {
  right: 0.375rem;
}

.\!k-right-1\.5 {
  right: 0.375rem !important;
}

.k-right-2 {
  right: 0.5rem;
}

.\!k-right-2 {
  right: 0.5rem !important;
}

.k-right-2\.5 {
  right: 0.625rem;
}

.\!k-right-2\.5 {
  right: 0.625rem !important;
}

.k-right-3 {
  right: 0.75rem;
}

.\!k-right-3 {
  right: 0.75rem !important;
}

.k-right-3\.5 {
  right: 0.875rem;
}

.\!k-right-3\.5 {
  right: 0.875rem !important;
}

.k-right-4 {
  right: 1rem;
}

.\!k-right-4 {
  right: 1rem !important;
}

.k-right-4\.5 {
  right: 1.125rem;
}

.\!k-right-4\.5 {
  right: 1.125rem !important;
}

.k-right-5 {
  right: 1.25rem;
}

.\!k-right-5 {
  right: 1.25rem !important;
}

.k-right-5\.5 {
  right: 1.375rem;
}

.\!k-right-5\.5 {
  right: 1.375rem !important;
}

.k-right-6 {
  right: 1.5rem;
}

.\!k-right-6 {
  right: 1.5rem !important;
}

.k-right-6\.5 {
  right: 1.625rem;
}

.\!k-right-6\.5 {
  right: 1.625rem !important;
}

.k-right-7 {
  right: 1.75rem;
}

.\!k-right-7 {
  right: 1.75rem !important;
}

.k-right-7\.5 {
  right: 1.875rem;
}

.\!k-right-7\.5 {
  right: 1.875rem !important;
}

.k-right-8 {
  right: 2rem;
}

.\!k-right-8 {
  right: 2rem !important;
}

.k-right-9 {
  right: 2.25rem;
}

.\!k-right-9 {
  right: 2.25rem !important;
}

.k-right-10 {
  right: 2.5rem;
}

.\!k-right-10 {
  right: 2.5rem !important;
}

.k-right-11 {
  right: 2.75rem;
}

.\!k-right-11 {
  right: 2.75rem !important;
}

.k-right-12 {
  right: 3rem;
}

.\!k-right-12 {
  right: 3rem !important;
}

.k-right-13 {
  right: 3.25rem;
}

.\!k-right-13 {
  right: 3.25rem !important;
}

.k-right-14 {
  right: 3.5rem;
}

.\!k-right-14 {
  right: 3.5rem !important;
}

.k-right-15 {
  right: 3.75rem;
}

.\!k-right-15 {
  right: 3.75rem !important;
}

.k-right-16 {
  right: 4rem;
}

.\!k-right-16 {
  right: 4rem !important;
}

.k-right-17 {
  right: 4.25rem;
}

.\!k-right-17 {
  right: 4.25rem !important;
}

.k-right-18 {
  right: 4.5rem;
}

.\!k-right-18 {
  right: 4.5rem !important;
}

.k-right-19 {
  right: 4.75rem;
}

.\!k-right-19 {
  right: 4.75rem !important;
}

.k-right-20 {
  right: 5rem;
}

.\!k-right-20 {
  right: 5rem !important;
}

.k-right-21 {
  right: 5.25rem;
}

.\!k-right-21 {
  right: 5.25rem !important;
}

.k-right-22 {
  right: 5.5rem;
}

.\!k-right-22 {
  right: 5.5rem !important;
}

.k-right-23 {
  right: 5.75rem;
}

.\!k-right-23 {
  right: 5.75rem !important;
}

.k-right-24 {
  right: 6rem;
}

.\!k-right-24 {
  right: 6rem !important;
}

.k-right-25 {
  right: 7rem;
}

.\!k-right-25 {
  right: 7rem !important;
}

.k-right-26 {
  right: 8rem;
}

.\!k-right-26 {
  right: 8rem !important;
}

.k-right-27 {
  right: 9rem;
}

.\!k-right-27 {
  right: 9rem !important;
}

.k-right-28 {
  right: 10rem;
}

.\!k-right-28 {
  right: 10rem !important;
}

.k-right-29 {
  right: 11rem;
}

.\!k-right-29 {
  right: 11rem !important;
}

.k-right-30 {
  right: 12rem;
}

.\!k-right-30 {
  right: 12rem !important;
}

.k-right--1 {
  right: -1px;
}

.\!k-right--1 {
  right: -1px !important;
}

.k-bottom-0 {
  bottom: 0px;
}

.\!k-bottom-0 {
  bottom: 0px !important;
}

.k-bottom-1px {
  bottom: 1px;
}

.\!k-bottom-1px {
  bottom: 1px !important;
}

.k-bottom-0\.5 {
  bottom: 0.125rem;
}

.\!k-bottom-0\.5 {
  bottom: 0.125rem !important;
}

.k-bottom-1 {
  bottom: 0.25rem;
}

.\!k-bottom-1 {
  bottom: 0.25rem !important;
}

.k-bottom-1\.5 {
  bottom: 0.375rem;
}

.\!k-bottom-1\.5 {
  bottom: 0.375rem !important;
}

.k-bottom-2 {
  bottom: 0.5rem;
}

.\!k-bottom-2 {
  bottom: 0.5rem !important;
}

.k-bottom-2\.5 {
  bottom: 0.625rem;
}

.\!k-bottom-2\.5 {
  bottom: 0.625rem !important;
}

.k-bottom-3 {
  bottom: 0.75rem;
}

.\!k-bottom-3 {
  bottom: 0.75rem !important;
}

.k-bottom-3\.5 {
  bottom: 0.875rem;
}

.\!k-bottom-3\.5 {
  bottom: 0.875rem !important;
}

.k-bottom-4 {
  bottom: 1rem;
}

.\!k-bottom-4 {
  bottom: 1rem !important;
}

.k-bottom-4\.5 {
  bottom: 1.125rem;
}

.\!k-bottom-4\.5 {
  bottom: 1.125rem !important;
}

.k-bottom-5 {
  bottom: 1.25rem;
}

.\!k-bottom-5 {
  bottom: 1.25rem !important;
}

.k-bottom-5\.5 {
  bottom: 1.375rem;
}

.\!k-bottom-5\.5 {
  bottom: 1.375rem !important;
}

.k-bottom-6 {
  bottom: 1.5rem;
}

.\!k-bottom-6 {
  bottom: 1.5rem !important;
}

.k-bottom-6\.5 {
  bottom: 1.625rem;
}

.\!k-bottom-6\.5 {
  bottom: 1.625rem !important;
}

.k-bottom-7 {
  bottom: 1.75rem;
}

.\!k-bottom-7 {
  bottom: 1.75rem !important;
}

.k-bottom-7\.5 {
  bottom: 1.875rem;
}

.\!k-bottom-7\.5 {
  bottom: 1.875rem !important;
}

.k-bottom-8 {
  bottom: 2rem;
}

.\!k-bottom-8 {
  bottom: 2rem !important;
}

.k-bottom-9 {
  bottom: 2.25rem;
}

.\!k-bottom-9 {
  bottom: 2.25rem !important;
}

.k-bottom-10 {
  bottom: 2.5rem;
}

.\!k-bottom-10 {
  bottom: 2.5rem !important;
}

.k-bottom-11 {
  bottom: 2.75rem;
}

.\!k-bottom-11 {
  bottom: 2.75rem !important;
}

.k-bottom-12 {
  bottom: 3rem;
}

.\!k-bottom-12 {
  bottom: 3rem !important;
}

.k-bottom-13 {
  bottom: 3.25rem;
}

.\!k-bottom-13 {
  bottom: 3.25rem !important;
}

.k-bottom-14 {
  bottom: 3.5rem;
}

.\!k-bottom-14 {
  bottom: 3.5rem !important;
}

.k-bottom-15 {
  bottom: 3.75rem;
}

.\!k-bottom-15 {
  bottom: 3.75rem !important;
}

.k-bottom-16 {
  bottom: 4rem;
}

.\!k-bottom-16 {
  bottom: 4rem !important;
}

.k-bottom-17 {
  bottom: 4.25rem;
}

.\!k-bottom-17 {
  bottom: 4.25rem !important;
}

.k-bottom-18 {
  bottom: 4.5rem;
}

.\!k-bottom-18 {
  bottom: 4.5rem !important;
}

.k-bottom-19 {
  bottom: 4.75rem;
}

.\!k-bottom-19 {
  bottom: 4.75rem !important;
}

.k-bottom-20 {
  bottom: 5rem;
}

.\!k-bottom-20 {
  bottom: 5rem !important;
}

.k-bottom-21 {
  bottom: 5.25rem;
}

.\!k-bottom-21 {
  bottom: 5.25rem !important;
}

.k-bottom-22 {
  bottom: 5.5rem;
}

.\!k-bottom-22 {
  bottom: 5.5rem !important;
}

.k-bottom-23 {
  bottom: 5.75rem;
}

.\!k-bottom-23 {
  bottom: 5.75rem !important;
}

.k-bottom-24 {
  bottom: 6rem;
}

.\!k-bottom-24 {
  bottom: 6rem !important;
}

.k-bottom-25 {
  bottom: 7rem;
}

.\!k-bottom-25 {
  bottom: 7rem !important;
}

.k-bottom-26 {
  bottom: 8rem;
}

.\!k-bottom-26 {
  bottom: 8rem !important;
}

.k-bottom-27 {
  bottom: 9rem;
}

.\!k-bottom-27 {
  bottom: 9rem !important;
}

.k-bottom-28 {
  bottom: 10rem;
}

.\!k-bottom-28 {
  bottom: 10rem !important;
}

.k-bottom-29 {
  bottom: 11rem;
}

.\!k-bottom-29 {
  bottom: 11rem !important;
}

.k-bottom-30 {
  bottom: 12rem;
}

.\!k-bottom-30 {
  bottom: 12rem !important;
}

.k-bottom--1 {
  bottom: -1px;
}

.\!k-bottom--1 {
  bottom: -1px !important;
}

.k-left-0 {
  left: 0px;
}

.\!k-left-0 {
  left: 0px !important;
}

.k-left-1px {
  left: 1px;
}

.\!k-left-1px {
  left: 1px !important;
}

.k-left-0\.5 {
  left: 0.125rem;
}

.\!k-left-0\.5 {
  left: 0.125rem !important;
}

.k-left-1 {
  left: 0.25rem;
}

.\!k-left-1 {
  left: 0.25rem !important;
}

.k-left-1\.5 {
  left: 0.375rem;
}

.\!k-left-1\.5 {
  left: 0.375rem !important;
}

.k-left-2 {
  left: 0.5rem;
}

.\!k-left-2 {
  left: 0.5rem !important;
}

.k-left-2\.5 {
  left: 0.625rem;
}

.\!k-left-2\.5 {
  left: 0.625rem !important;
}

.k-left-3 {
  left: 0.75rem;
}

.\!k-left-3 {
  left: 0.75rem !important;
}

.k-left-3\.5 {
  left: 0.875rem;
}

.\!k-left-3\.5 {
  left: 0.875rem !important;
}

.k-left-4 {
  left: 1rem;
}

.\!k-left-4 {
  left: 1rem !important;
}

.k-left-4\.5 {
  left: 1.125rem;
}

.\!k-left-4\.5 {
  left: 1.125rem !important;
}

.k-left-5 {
  left: 1.25rem;
}

.\!k-left-5 {
  left: 1.25rem !important;
}

.k-left-5\.5 {
  left: 1.375rem;
}

.\!k-left-5\.5 {
  left: 1.375rem !important;
}

.k-left-6 {
  left: 1.5rem;
}

.\!k-left-6 {
  left: 1.5rem !important;
}

.k-left-6\.5 {
  left: 1.625rem;
}

.\!k-left-6\.5 {
  left: 1.625rem !important;
}

.k-left-7 {
  left: 1.75rem;
}

.\!k-left-7 {
  left: 1.75rem !important;
}

.k-left-7\.5 {
  left: 1.875rem;
}

.\!k-left-7\.5 {
  left: 1.875rem !important;
}

.k-left-8 {
  left: 2rem;
}

.\!k-left-8 {
  left: 2rem !important;
}

.k-left-9 {
  left: 2.25rem;
}

.\!k-left-9 {
  left: 2.25rem !important;
}

.k-left-10 {
  left: 2.5rem;
}

.\!k-left-10 {
  left: 2.5rem !important;
}

.k-left-11 {
  left: 2.75rem;
}

.\!k-left-11 {
  left: 2.75rem !important;
}

.k-left-12 {
  left: 3rem;
}

.\!k-left-12 {
  left: 3rem !important;
}

.k-left-13 {
  left: 3.25rem;
}

.\!k-left-13 {
  left: 3.25rem !important;
}

.k-left-14 {
  left: 3.5rem;
}

.\!k-left-14 {
  left: 3.5rem !important;
}

.k-left-15 {
  left: 3.75rem;
}

.\!k-left-15 {
  left: 3.75rem !important;
}

.k-left-16 {
  left: 4rem;
}

.\!k-left-16 {
  left: 4rem !important;
}

.k-left-17 {
  left: 4.25rem;
}

.\!k-left-17 {
  left: 4.25rem !important;
}

.k-left-18 {
  left: 4.5rem;
}

.\!k-left-18 {
  left: 4.5rem !important;
}

.k-left-19 {
  left: 4.75rem;
}

.\!k-left-19 {
  left: 4.75rem !important;
}

.k-left-20 {
  left: 5rem;
}

.\!k-left-20 {
  left: 5rem !important;
}

.k-left-21 {
  left: 5.25rem;
}

.\!k-left-21 {
  left: 5.25rem !important;
}

.k-left-22 {
  left: 5.5rem;
}

.\!k-left-22 {
  left: 5.5rem !important;
}

.k-left-23 {
  left: 5.75rem;
}

.\!k-left-23 {
  left: 5.75rem !important;
}

.k-left-24 {
  left: 6rem;
}

.\!k-left-24 {
  left: 6rem !important;
}

.k-left-25 {
  left: 7rem;
}

.\!k-left-25 {
  left: 7rem !important;
}

.k-left-26 {
  left: 8rem;
}

.\!k-left-26 {
  left: 8rem !important;
}

.k-left-27 {
  left: 9rem;
}

.\!k-left-27 {
  left: 9rem !important;
}

.k-left-28 {
  left: 10rem;
}

.\!k-left-28 {
  left: 10rem !important;
}

.k-left-29 {
  left: 11rem;
}

.\!k-left-29 {
  left: 11rem !important;
}

.k-left-30 {
  left: 12rem;
}

.\!k-left-30 {
  left: 12rem !important;
}

.k-left--1 {
  left: -1px;
}

.\!k-left--1 {
  left: -1px !important;
}

.k-top,
.k-pos-top {
  top: 0;
}

.k-right,
.k-pos-right {
  right: 0;
}

.k-bottom,
.k-pos-bottom {
  bottom: 0;
}

.k-left,
.k-pos-left {
  left: 0;
}

.k-inset-0 {
  inset: 0;
}

.\!k-inset-0 {
  inset: 0 !important;
}

.k-inset-1 {
  inset: 1px;
}

.\!k-inset-1 {
  inset: 1px !important;
}

.k-inset--1 {
  inset: -1px;
}

.\!k-inset--1 {
  inset: -1px !important;
}

.k-inset-x-0 {
  inset-inline: 0;
}

.\!k-inset-x-0 {
  inset-inline: 0 !important;
}

.k-inset-x-1 {
  inset-inline: 1px;
}

.\!k-inset-x-1 {
  inset-inline: 1px !important;
}

.k-inset-x--1 {
  inset-inline: -1px;
}

.\!k-inset-x--1 {
  inset-inline: -1px !important;
}

.k-inset-y-0 {
  inset-block: 0;
}

.\!k-inset-y-0 {
  inset-block: 0 !important;
}

.k-inset-y-1 {
  inset-block: 1px;
}

.\!k-inset-y-1 {
  inset-block: 1px !important;
}

.k-inset-y--1 {
  inset-block: -1px;
}

.\!k-inset-y--1 {
  inset-block: -1px !important;
}

.k-top-left-0 {
  top: 0;
  left: 0;
}

.\!k-top-left-0 {
  top: 0 !important;
  left: 0 !important;
}

.k-top-left-1 {
  top: 1px;
  left: 1px;
}

.\!k-top-left-1 {
  top: 1px !important;
  left: 1px !important;
}

.k-top-left--1 {
  top: -1px;
  left: -1px;
}

.\!k-top-left--1 {
  top: -1px !important;
  left: -1px !important;
}

.k-top-right-0 {
  top: 0;
  right: 0;
}

.\!k-top-right-0 {
  top: 0 !important;
  right: 0 !important;
}

.k-top-right-1 {
  top: 1px;
  right: 1px;
}

.\!k-top-right-1 {
  top: 1px !important;
  right: 1px !important;
}

.k-top-right--1 {
  top: -1px;
  right: -1px;
}

.\!k-top-right--1 {
  top: -1px !important;
  right: -1px !important;
}

.k-bottom-left-0 {
  bottom: 0;
  left: 0;
}

.\!k-bottom-left-0 {
  bottom: 0 !important;
  left: 0 !important;
}

.k-bottom-left-1 {
  bottom: 1px;
  left: 1px;
}

.\!k-bottom-left-1 {
  bottom: 1px !important;
  left: 1px !important;
}

.k-bottom-left--1 {
  bottom: -1px;
  left: -1px;
}

.\!k-bottom-left--1 {
  bottom: -1px !important;
  left: -1px !important;
}

.k-bottom-right-0 {
  bottom: 0;
  right: 0;
}

.\!k-bottom-right-0 {
  bottom: 0 !important;
  right: 0 !important;
}

.k-bottom-right-1 {
  bottom: 1px;
  right: 1px;
}

.\!k-bottom-right-1 {
  bottom: 1px !important;
  right: 1px !important;
}

.k-bottom-right--1 {
  bottom: -1px;
  right: -1px;
}

.\!k-bottom-right--1 {
  bottom: -1px !important;
  right: -1px !important;
}

.k-top-start,
.k-pos-top-start {
  top: 0;
  inset-inline-start: 0;
}

.k-top-end,
.k-pos-top-end {
  top: 0;
  inset-inline-end: 0;
}

.k-bottom-start,
.k-pos-bottom-start {
  bottom: 0;
  inset-inline-start: 0;
}

.k-bottom-end,
.k-pos-bottom-end {
  bottom: 0;
  inset-inline-end: 0;
}

.k-top-center,
.k-pos-top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-middle-left,
.k-middle-start,
.k-pos-middle-start {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.k-middle-center {
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.k-middle-right,
.k-middle-end,
.k-pos-middle-end {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-bottom-center,
.k-pos-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-pos-static, .k-static {
  position: static;
}

.\!k-pos-static, .\!k-static {
  position: static !important;
}

.k-pos-relative, .k-relative {
  position: relative;
}

.\!k-pos-relative, .\!k-relative {
  position: relative !important;
}

.k-pos-absolute, .k-absolute {
  position: absolute;
}

.\!k-pos-absolute, .\!k-absolute {
  position: absolute !important;
}

.k-pos-fixed, .k-fixed {
  position: fixed;
}

.\!k-pos-fixed, .\!k-fixed {
  position: fixed !important;
}

.k-pos-sticky, .k-sticky {
  position: sticky;
}

.\!k-pos-sticky, .\!k-sticky {
  position: sticky !important;
}

.k-visibility-visible, .k-visible {
  visibility: visible;
}

.\!k-visibility-visible, .\!k-visible {
  visibility: visible !important;
}

.k-visibility-invisible {
  visibility: hidden;
}

.\!k-visibility-invisible {
  visibility: hidden !important;
}

.k-visibility-collapse {
  visibility: collapse;
}

.\!k-visibility-collapse {
  visibility: collapse !important;
}

.k-z-0 {
  z-index: 0;
}

.\!k-z-0 {
  z-index: 0 !important;
}

.k-z-10 {
  z-index: 10;
}

.\!k-z-10 {
  z-index: 10 !important;
}

.k-z-20 {
  z-index: 20;
}

.\!k-z-20 {
  z-index: 20 !important;
}

.k-z-30 {
  z-index: 30;
}

.\!k-z-30 {
  z-index: 30 !important;
}

.k-z-40 {
  z-index: 40;
}

.\!k-z-40 {
  z-index: 40 !important;
}

.k-z-50 {
  z-index: 50;
}

.\!k-z-50 {
  z-index: 50 !important;
}

.k-z-auto {
  z-index: auto;
}

.\!k-z-auto {
  z-index: auto !important;
}

.k-align-content-normal {
  align-content: normal;
}

.\!k-align-content-normal {
  align-content: normal !important;
}

.k-align-content-stretch {
  align-content: stretch;
}

.\!k-align-content-stretch {
  align-content: stretch !important;
}

.k-align-content-center {
  align-content: center;
}

.\!k-align-content-center {
  align-content: center !important;
}

.k-align-content-start {
  align-content: start;
}

.\!k-align-content-start {
  align-content: start !important;
}

.k-align-content-end {
  align-content: end;
}

.\!k-align-content-end {
  align-content: end !important;
}

.k-align-content-flex-start {
  align-content: flex-start;
}

.\!k-align-content-flex-start {
  align-content: flex-start !important;
}

.k-align-content-flex-end {
  align-content: flex-end;
}

.\!k-align-content-flex-end {
  align-content: flex-end !important;
}

.k-align-content-baseline {
  align-content: baseline;
}

.\!k-align-content-baseline {
  align-content: baseline !important;
}

.k-align-content-first-baseline {
  align-content: first baseline;
}

.\!k-align-content-first-baseline {
  align-content: first baseline !important;
}

.k-align-content-last-baseline {
  align-content: last baseline;
}

.\!k-align-content-last-baseline {
  align-content: last baseline !important;
}

.k-align-content-between {
  align-content: space-between;
}

.\!k-align-content-between {
  align-content: space-between !important;
}

.k-align-content-around {
  align-content: space-around;
}

.\!k-align-content-around {
  align-content: space-around !important;
}

.k-align-content-evenly {
  align-content: space-evenly;
}

.\!k-align-content-evenly {
  align-content: space-evenly !important;
}

.k-align-items-normal {
  align-items: normal;
}

.\!k-align-items-normal {
  align-items: normal !important;
}

.k-align-items-stretch {
  align-items: stretch;
}

.\!k-align-items-stretch {
  align-items: stretch !important;
}

.k-align-items-center {
  align-items: center;
}

.\!k-align-items-center {
  align-items: center !important;
}

.k-align-items-start {
  align-items: start;
}

.\!k-align-items-start {
  align-items: start !important;
}

.k-align-items-end {
  align-items: end;
}

.\!k-align-items-end {
  align-items: end !important;
}

.k-align-items-flex-start {
  align-items: flex-start;
}

.\!k-align-items-flex-start {
  align-items: flex-start !important;
}

.k-align-items-flex-end {
  align-items: flex-end;
}

.\!k-align-items-flex-end {
  align-items: flex-end !important;
}

.k-align-items-baseline {
  align-items: baseline;
}

.\!k-align-items-baseline {
  align-items: baseline !important;
}

.k-align-items-first-baseline {
  align-items: first baseline;
}

.\!k-align-items-first-baseline {
  align-items: first baseline !important;
}

.k-align-items-last-baseline {
  align-items: last baseline;
}

.\!k-align-items-last-baseline {
  align-items: last baseline !important;
}

.k-align-items-self-start {
  align-items: self-start;
}

.\!k-align-items-self-start {
  align-items: self-start !important;
}

.k-align-items-self-end {
  align-items: self-end;
}

.\!k-align-items-self-end {
  align-items: self-end !important;
}

.k-align-self-auto {
  align-self: auto;
}

.\!k-align-self-auto {
  align-self: auto !important;
}

.k-align-self-normal {
  align-self: normal;
}

.\!k-align-self-normal {
  align-self: normal !important;
}

.k-align-self-stretch {
  align-self: stretch;
}

.\!k-align-self-stretch {
  align-self: stretch !important;
}

.k-align-self-center {
  align-self: center;
}

.\!k-align-self-center {
  align-self: center !important;
}

.k-align-self-start {
  align-self: start;
}

.\!k-align-self-start {
  align-self: start !important;
}

.k-align-self-end {
  align-self: end;
}

.\!k-align-self-end {
  align-self: end !important;
}

.k-align-self-flex-start {
  align-self: flex-start;
}

.\!k-align-self-flex-start {
  align-self: flex-start !important;
}

.k-align-self-flex-end {
  align-self: flex-end;
}

.\!k-align-self-flex-end {
  align-self: flex-end !important;
}

.k-align-self-baseline {
  align-self: baseline;
}

.\!k-align-self-baseline {
  align-self: baseline !important;
}

.k-align-self-first-baseline {
  align-self: first baseline;
}

.\!k-align-self-first-baseline {
  align-self: first baseline !important;
}

.k-align-self-last-baseline {
  align-self: last baseline;
}

.\!k-align-self-last-baseline {
  align-self: last baseline !important;
}

.k-align-self-self-start {
  align-self: self-start;
}

.\!k-align-self-self-start {
  align-self: self-start !important;
}

.k-align-self-self-end {
  align-self: self-end;
}

.\!k-align-self-self-end {
  align-self: self-end !important;
}

.k-basis-0 {
  flex-basis: 0px;
}

.\!k-basis-0 {
  flex-basis: 0px !important;
}

.k-basis-1px {
  flex-basis: 1px;
}

.\!k-basis-1px {
  flex-basis: 1px !important;
}

.k-basis-0\.5 {
  flex-basis: 0.125rem;
}

.\!k-basis-0\.5 {
  flex-basis: 0.125rem !important;
}

.k-basis-1 {
  flex-basis: 0.25rem;
}

.\!k-basis-1 {
  flex-basis: 0.25rem !important;
}

.k-basis-1\.5 {
  flex-basis: 0.375rem;
}

.\!k-basis-1\.5 {
  flex-basis: 0.375rem !important;
}

.k-basis-2 {
  flex-basis: 0.5rem;
}

.\!k-basis-2 {
  flex-basis: 0.5rem !important;
}

.k-basis-2\.5 {
  flex-basis: 0.625rem;
}

.\!k-basis-2\.5 {
  flex-basis: 0.625rem !important;
}

.k-basis-3 {
  flex-basis: 0.75rem;
}

.\!k-basis-3 {
  flex-basis: 0.75rem !important;
}

.k-basis-3\.5 {
  flex-basis: 0.875rem;
}

.\!k-basis-3\.5 {
  flex-basis: 0.875rem !important;
}

.k-basis-4 {
  flex-basis: 1rem;
}

.\!k-basis-4 {
  flex-basis: 1rem !important;
}

.k-basis-4\.5 {
  flex-basis: 1.125rem;
}

.\!k-basis-4\.5 {
  flex-basis: 1.125rem !important;
}

.k-basis-5 {
  flex-basis: 1.25rem;
}

.\!k-basis-5 {
  flex-basis: 1.25rem !important;
}

.k-basis-5\.5 {
  flex-basis: 1.375rem;
}

.\!k-basis-5\.5 {
  flex-basis: 1.375rem !important;
}

.k-basis-6 {
  flex-basis: 1.5rem;
}

.\!k-basis-6 {
  flex-basis: 1.5rem !important;
}

.k-basis-6\.5 {
  flex-basis: 1.625rem;
}

.\!k-basis-6\.5 {
  flex-basis: 1.625rem !important;
}

.k-basis-7 {
  flex-basis: 1.75rem;
}

.\!k-basis-7 {
  flex-basis: 1.75rem !important;
}

.k-basis-7\.5 {
  flex-basis: 1.875rem;
}

.\!k-basis-7\.5 {
  flex-basis: 1.875rem !important;
}

.k-basis-8 {
  flex-basis: 2rem;
}

.\!k-basis-8 {
  flex-basis: 2rem !important;
}

.k-basis-9 {
  flex-basis: 2.25rem;
}

.\!k-basis-9 {
  flex-basis: 2.25rem !important;
}

.k-basis-10 {
  flex-basis: 2.5rem;
}

.\!k-basis-10 {
  flex-basis: 2.5rem !important;
}

.k-basis-11 {
  flex-basis: 2.75rem;
}

.\!k-basis-11 {
  flex-basis: 2.75rem !important;
}

.k-basis-12 {
  flex-basis: 3rem;
}

.\!k-basis-12 {
  flex-basis: 3rem !important;
}

.k-basis-13 {
  flex-basis: 3.25rem;
}

.\!k-basis-13 {
  flex-basis: 3.25rem !important;
}

.k-basis-14 {
  flex-basis: 3.5rem;
}

.\!k-basis-14 {
  flex-basis: 3.5rem !important;
}

.k-basis-15 {
  flex-basis: 3.75rem;
}

.\!k-basis-15 {
  flex-basis: 3.75rem !important;
}

.k-basis-16 {
  flex-basis: 4rem;
}

.\!k-basis-16 {
  flex-basis: 4rem !important;
}

.k-basis-17 {
  flex-basis: 4.25rem;
}

.\!k-basis-17 {
  flex-basis: 4.25rem !important;
}

.k-basis-18 {
  flex-basis: 4.5rem;
}

.\!k-basis-18 {
  flex-basis: 4.5rem !important;
}

.k-basis-19 {
  flex-basis: 4.75rem;
}

.\!k-basis-19 {
  flex-basis: 4.75rem !important;
}

.k-basis-20 {
  flex-basis: 5rem;
}

.\!k-basis-20 {
  flex-basis: 5rem !important;
}

.k-basis-21 {
  flex-basis: 5.25rem;
}

.\!k-basis-21 {
  flex-basis: 5.25rem !important;
}

.k-basis-22 {
  flex-basis: 5.5rem;
}

.\!k-basis-22 {
  flex-basis: 5.5rem !important;
}

.k-basis-23 {
  flex-basis: 5.75rem;
}

.\!k-basis-23 {
  flex-basis: 5.75rem !important;
}

.k-basis-24 {
  flex-basis: 6rem;
}

.\!k-basis-24 {
  flex-basis: 6rem !important;
}

.k-basis-25 {
  flex-basis: 7rem;
}

.\!k-basis-25 {
  flex-basis: 7rem !important;
}

.k-basis-26 {
  flex-basis: 8rem;
}

.\!k-basis-26 {
  flex-basis: 8rem !important;
}

.k-basis-27 {
  flex-basis: 9rem;
}

.\!k-basis-27 {
  flex-basis: 9rem !important;
}

.k-basis-28 {
  flex-basis: 10rem;
}

.\!k-basis-28 {
  flex-basis: 10rem !important;
}

.k-basis-29 {
  flex-basis: 11rem;
}

.\!k-basis-29 {
  flex-basis: 11rem !important;
}

.k-basis-30 {
  flex-basis: 12rem;
}

.\!k-basis-30 {
  flex-basis: 12rem !important;
}

.k-basis-auto {
  flex-basis: auto;
}

.\!k-basis-auto {
  flex-basis: auto !important;
}

.k-basis-1\/2 {
  flex-basis: 50%;
}

.\!k-basis-1\/2 {
  flex-basis: 50% !important;
}

.k-basis-1\/3 {
  flex-basis: 33.333333%;
}

.\!k-basis-1\/3 {
  flex-basis: 33.333333% !important;
}

.k-basis-2\/3 {
  flex-basis: 66.666667%;
}

.\!k-basis-2\/3 {
  flex-basis: 66.666667% !important;
}

.k-basis-1\/4 {
  flex-basis: 25%;
}

.\!k-basis-1\/4 {
  flex-basis: 25% !important;
}

.k-basis-2\/4 {
  flex-basis: 50%;
}

.\!k-basis-2\/4 {
  flex-basis: 50% !important;
}

.k-basis-3\/4 {
  flex-basis: 75%;
}

.\!k-basis-3\/4 {
  flex-basis: 75% !important;
}

.k-basis-1\/5 {
  flex-basis: 20%;
}

.\!k-basis-1\/5 {
  flex-basis: 20% !important;
}

.k-basis-2\/5 {
  flex-basis: 40%;
}

.\!k-basis-2\/5 {
  flex-basis: 40% !important;
}

.k-basis-3\/5 {
  flex-basis: 60%;
}

.\!k-basis-3\/5 {
  flex-basis: 60% !important;
}

.k-basis-4\/5 {
  flex-basis: 80%;
}

.\!k-basis-4\/5 {
  flex-basis: 80% !important;
}

.k-basis-1\/6 {
  flex-basis: 16.666667%;
}

.\!k-basis-1\/6 {
  flex-basis: 16.666667% !important;
}

.k-basis-2\/6 {
  flex-basis: 33.333333%;
}

.\!k-basis-2\/6 {
  flex-basis: 33.333333% !important;
}

.k-basis-3\/6 {
  flex-basis: 50%;
}

.\!k-basis-3\/6 {
  flex-basis: 50% !important;
}

.k-basis-4\/6 {
  flex-basis: 66.666667%;
}

.\!k-basis-4\/6 {
  flex-basis: 66.666667% !important;
}

.k-basis-5\/6 {
  flex-basis: 83.333333%;
}

.\!k-basis-5\/6 {
  flex-basis: 83.333333% !important;
}

.k-basis-1\/12 {
  flex-basis: 8.333333%;
}

.\!k-basis-1\/12 {
  flex-basis: 8.333333% !important;
}

.k-basis-2\/12 {
  flex-basis: 16.666667%;
}

.\!k-basis-2\/12 {
  flex-basis: 16.666667% !important;
}

.k-basis-3\/12 {
  flex-basis: 25%;
}

.\!k-basis-3\/12 {
  flex-basis: 25% !important;
}

.k-basis-4\/12 {
  flex-basis: 33.333333%;
}

.\!k-basis-4\/12 {
  flex-basis: 33.333333% !important;
}

.k-basis-5\/12 {
  flex-basis: 41.666667%;
}

.\!k-basis-5\/12 {
  flex-basis: 41.666667% !important;
}

.k-basis-6\/12 {
  flex-basis: 50%;
}

.\!k-basis-6\/12 {
  flex-basis: 50% !important;
}

.k-basis-7\/12 {
  flex-basis: 58.333333%;
}

.\!k-basis-7\/12 {
  flex-basis: 58.333333% !important;
}

.k-basis-8\/12 {
  flex-basis: 66.666667%;
}

.\!k-basis-8\/12 {
  flex-basis: 66.666667% !important;
}

.k-basis-9\/12 {
  flex-basis: 75%;
}

.\!k-basis-9\/12 {
  flex-basis: 75% !important;
}

.k-basis-10\/12 {
  flex-basis: 83.333333%;
}

.\!k-basis-10\/12 {
  flex-basis: 83.333333% !important;
}

.k-basis-11\/12 {
  flex-basis: 91.666667%;
}

.\!k-basis-11\/12 {
  flex-basis: 91.666667% !important;
}

.k-basis-full {
  flex-basis: 100%;
}

.\!k-basis-full {
  flex-basis: 100% !important;
}

.k-basis-min {
  flex-basis: min-content;
}

.\!k-basis-min {
  flex-basis: min-content !important;
}

.k-basis-max {
  flex-basis: max-content;
}

.\!k-basis-max {
  flex-basis: max-content !important;
}

.k-basis-fit {
  flex-basis: fit-content;
}

.\!k-basis-fit {
  flex-basis: fit-content !important;
}

.k-flex-basis-0 {
  flex-basis: 0px;
}

.\!k-flex-basis-0 {
  flex-basis: 0px !important;
}

.k-flex-basis-1px {
  flex-basis: 1px;
}

.\!k-flex-basis-1px {
  flex-basis: 1px !important;
}

.k-flex-basis-0\.5 {
  flex-basis: 0.125rem;
}

.\!k-flex-basis-0\.5 {
  flex-basis: 0.125rem !important;
}

.k-flex-basis-1 {
  flex-basis: 0.25rem;
}

.\!k-flex-basis-1 {
  flex-basis: 0.25rem !important;
}

.k-flex-basis-1\.5 {
  flex-basis: 0.375rem;
}

.\!k-flex-basis-1\.5 {
  flex-basis: 0.375rem !important;
}

.k-flex-basis-2 {
  flex-basis: 0.5rem;
}

.\!k-flex-basis-2 {
  flex-basis: 0.5rem !important;
}

.k-flex-basis-2\.5 {
  flex-basis: 0.625rem;
}

.\!k-flex-basis-2\.5 {
  flex-basis: 0.625rem !important;
}

.k-flex-basis-3 {
  flex-basis: 0.75rem;
}

.\!k-flex-basis-3 {
  flex-basis: 0.75rem !important;
}

.k-flex-basis-3\.5 {
  flex-basis: 0.875rem;
}

.\!k-flex-basis-3\.5 {
  flex-basis: 0.875rem !important;
}

.k-flex-basis-4 {
  flex-basis: 1rem;
}

.\!k-flex-basis-4 {
  flex-basis: 1rem !important;
}

.k-flex-basis-4\.5 {
  flex-basis: 1.125rem;
}

.\!k-flex-basis-4\.5 {
  flex-basis: 1.125rem !important;
}

.k-flex-basis-5 {
  flex-basis: 1.25rem;
}

.\!k-flex-basis-5 {
  flex-basis: 1.25rem !important;
}

.k-flex-basis-5\.5 {
  flex-basis: 1.375rem;
}

.\!k-flex-basis-5\.5 {
  flex-basis: 1.375rem !important;
}

.k-flex-basis-6 {
  flex-basis: 1.5rem;
}

.\!k-flex-basis-6 {
  flex-basis: 1.5rem !important;
}

.k-flex-basis-6\.5 {
  flex-basis: 1.625rem;
}

.\!k-flex-basis-6\.5 {
  flex-basis: 1.625rem !important;
}

.k-flex-basis-7 {
  flex-basis: 1.75rem;
}

.\!k-flex-basis-7 {
  flex-basis: 1.75rem !important;
}

.k-flex-basis-7\.5 {
  flex-basis: 1.875rem;
}

.\!k-flex-basis-7\.5 {
  flex-basis: 1.875rem !important;
}

.k-flex-basis-8 {
  flex-basis: 2rem;
}

.\!k-flex-basis-8 {
  flex-basis: 2rem !important;
}

.k-flex-basis-9 {
  flex-basis: 2.25rem;
}

.\!k-flex-basis-9 {
  flex-basis: 2.25rem !important;
}

.k-flex-basis-10 {
  flex-basis: 2.5rem;
}

.\!k-flex-basis-10 {
  flex-basis: 2.5rem !important;
}

.k-flex-basis-11 {
  flex-basis: 2.75rem;
}

.\!k-flex-basis-11 {
  flex-basis: 2.75rem !important;
}

.k-flex-basis-12 {
  flex-basis: 3rem;
}

.\!k-flex-basis-12 {
  flex-basis: 3rem !important;
}

.k-flex-basis-13 {
  flex-basis: 3.25rem;
}

.\!k-flex-basis-13 {
  flex-basis: 3.25rem !important;
}

.k-flex-basis-14 {
  flex-basis: 3.5rem;
}

.\!k-flex-basis-14 {
  flex-basis: 3.5rem !important;
}

.k-flex-basis-15 {
  flex-basis: 3.75rem;
}

.\!k-flex-basis-15 {
  flex-basis: 3.75rem !important;
}

.k-flex-basis-16 {
  flex-basis: 4rem;
}

.\!k-flex-basis-16 {
  flex-basis: 4rem !important;
}

.k-flex-basis-17 {
  flex-basis: 4.25rem;
}

.\!k-flex-basis-17 {
  flex-basis: 4.25rem !important;
}

.k-flex-basis-18 {
  flex-basis: 4.5rem;
}

.\!k-flex-basis-18 {
  flex-basis: 4.5rem !important;
}

.k-flex-basis-19 {
  flex-basis: 4.75rem;
}

.\!k-flex-basis-19 {
  flex-basis: 4.75rem !important;
}

.k-flex-basis-20 {
  flex-basis: 5rem;
}

.\!k-flex-basis-20 {
  flex-basis: 5rem !important;
}

.k-flex-basis-21 {
  flex-basis: 5.25rem;
}

.\!k-flex-basis-21 {
  flex-basis: 5.25rem !important;
}

.k-flex-basis-22 {
  flex-basis: 5.5rem;
}

.\!k-flex-basis-22 {
  flex-basis: 5.5rem !important;
}

.k-flex-basis-23 {
  flex-basis: 5.75rem;
}

.\!k-flex-basis-23 {
  flex-basis: 5.75rem !important;
}

.k-flex-basis-24 {
  flex-basis: 6rem;
}

.\!k-flex-basis-24 {
  flex-basis: 6rem !important;
}

.k-flex-basis-25 {
  flex-basis: 7rem;
}

.\!k-flex-basis-25 {
  flex-basis: 7rem !important;
}

.k-flex-basis-26 {
  flex-basis: 8rem;
}

.\!k-flex-basis-26 {
  flex-basis: 8rem !important;
}

.k-flex-basis-27 {
  flex-basis: 9rem;
}

.\!k-flex-basis-27 {
  flex-basis: 9rem !important;
}

.k-flex-basis-28 {
  flex-basis: 10rem;
}

.\!k-flex-basis-28 {
  flex-basis: 10rem !important;
}

.k-flex-basis-29 {
  flex-basis: 11rem;
}

.\!k-flex-basis-29 {
  flex-basis: 11rem !important;
}

.k-flex-basis-30 {
  flex-basis: 12rem;
}

.\!k-flex-basis-30 {
  flex-basis: 12rem !important;
}

.k-flex-basis-auto {
  flex-basis: auto;
}

.\!k-flex-basis-auto {
  flex-basis: auto !important;
}

.k-flex-basis-1\/2 {
  flex-basis: 50%;
}

.\!k-flex-basis-1\/2 {
  flex-basis: 50% !important;
}

.k-flex-basis-1\/3 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-1\/3 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-2\/3 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-2\/3 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-1\/4 {
  flex-basis: 25%;
}

.\!k-flex-basis-1\/4 {
  flex-basis: 25% !important;
}

.k-flex-basis-2\/4 {
  flex-basis: 50%;
}

.\!k-flex-basis-2\/4 {
  flex-basis: 50% !important;
}

.k-flex-basis-3\/4 {
  flex-basis: 75%;
}

.\!k-flex-basis-3\/4 {
  flex-basis: 75% !important;
}

.k-flex-basis-1\/5 {
  flex-basis: 20%;
}

.\!k-flex-basis-1\/5 {
  flex-basis: 20% !important;
}

.k-flex-basis-2\/5 {
  flex-basis: 40%;
}

.\!k-flex-basis-2\/5 {
  flex-basis: 40% !important;
}

.k-flex-basis-3\/5 {
  flex-basis: 60%;
}

.\!k-flex-basis-3\/5 {
  flex-basis: 60% !important;
}

.k-flex-basis-4\/5 {
  flex-basis: 80%;
}

.\!k-flex-basis-4\/5 {
  flex-basis: 80% !important;
}

.k-flex-basis-1\/6 {
  flex-basis: 16.666667%;
}

.\!k-flex-basis-1\/6 {
  flex-basis: 16.666667% !important;
}

.k-flex-basis-2\/6 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-2\/6 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-3\/6 {
  flex-basis: 50%;
}

.\!k-flex-basis-3\/6 {
  flex-basis: 50% !important;
}

.k-flex-basis-4\/6 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-4\/6 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-5\/6 {
  flex-basis: 83.333333%;
}

.\!k-flex-basis-5\/6 {
  flex-basis: 83.333333% !important;
}

.k-flex-basis-1\/12 {
  flex-basis: 8.333333%;
}

.\!k-flex-basis-1\/12 {
  flex-basis: 8.333333% !important;
}

.k-flex-basis-2\/12 {
  flex-basis: 16.666667%;
}

.\!k-flex-basis-2\/12 {
  flex-basis: 16.666667% !important;
}

.k-flex-basis-3\/12 {
  flex-basis: 25%;
}

.\!k-flex-basis-3\/12 {
  flex-basis: 25% !important;
}

.k-flex-basis-4\/12 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-4\/12 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-5\/12 {
  flex-basis: 41.666667%;
}

.\!k-flex-basis-5\/12 {
  flex-basis: 41.666667% !important;
}

.k-flex-basis-6\/12 {
  flex-basis: 50%;
}

.\!k-flex-basis-6\/12 {
  flex-basis: 50% !important;
}

.k-flex-basis-7\/12 {
  flex-basis: 58.333333%;
}

.\!k-flex-basis-7\/12 {
  flex-basis: 58.333333% !important;
}

.k-flex-basis-8\/12 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-8\/12 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-9\/12 {
  flex-basis: 75%;
}

.\!k-flex-basis-9\/12 {
  flex-basis: 75% !important;
}

.k-flex-basis-10\/12 {
  flex-basis: 83.333333%;
}

.\!k-flex-basis-10\/12 {
  flex-basis: 83.333333% !important;
}

.k-flex-basis-11\/12 {
  flex-basis: 91.666667%;
}

.\!k-flex-basis-11\/12 {
  flex-basis: 91.666667% !important;
}

.k-flex-basis-full {
  flex-basis: 100%;
}

.\!k-flex-basis-full {
  flex-basis: 100% !important;
}

.k-flex-basis-min {
  flex-basis: min-content;
}

.\!k-flex-basis-min {
  flex-basis: min-content !important;
}

.k-flex-basis-max {
  flex-basis: max-content;
}

.\!k-flex-basis-max {
  flex-basis: max-content !important;
}

.k-flex-basis-fit {
  flex-basis: fit-content;
}

.\!k-flex-basis-fit {
  flex-basis: fit-content !important;
}

.k-flex-row, .k-d-flex-row {
  flex-direction: row;
}

.\!k-flex-row, .\!k-d-flex-row, .\!k-d-flex-col {
  flex-direction: row !important;
}

.k-flex-row-reverse {
  flex-direction: row-reverse;
}

.\!k-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.k-flex-col, .k-flex-column, .k-d-flex-col {
  flex-direction: column;
}

.\!k-flex-col {
  flex-direction: column !important;
}

.k-flex-col-reverse, .k-flex-column-reverse {
  flex-direction: column-reverse;
}

.\!k-flex-col-reverse {
  flex-direction: column-reverse !important;
}

.k-grow {
  flex-grow: 1;
}

.\!k-grow {
  flex-grow: 1 !important;
}

.k-grow-0 {
  flex-grow: 0;
}

.\!k-grow-0 {
  flex-grow: 0 !important;
}

.k-flex-grow {
  flex-grow: 1;
}

.\!k-flex-grow {
  flex-grow: 1 !important;
}

.k-flex-grow-0 {
  flex-grow: 0;
}

.\!k-flex-grow-0 {
  flex-grow: 0 !important;
}

.k-shrink {
  flex-shrink: 1;
}

.\!k-shrink {
  flex-shrink: 1 !important;
}

.k-shrink-0 {
  flex-shrink: 0;
}

.\!k-shrink-0 {
  flex-shrink: 0 !important;
}

.k-flex-shrink {
  flex-shrink: 1;
}

.\!k-flex-shrink {
  flex-shrink: 1 !important;
}

.k-flex-shrink-0 {
  flex-shrink: 0;
}

.\!k-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.k-flex-wrap {
  flex-wrap: wrap;
}

.\!k-flex-wrap {
  flex-wrap: wrap !important;
}

.k-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.\!k-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.k-flex-nowrap {
  flex-wrap: nowrap;
}

.\!k-flex-nowrap {
  flex-wrap: nowrap !important;
}

.k-flex-1 {
  flex: 1 1 0%;
}

.\!k-flex-1 {
  flex: 1 1 0% !important;
}

.k-flex-auto {
  flex: 1 1 auto;
}

.\!k-flex-auto {
  flex: 1 1 auto !important;
}

.k-flex-initial {
  flex: 0 1 auto;
}

.\!k-flex-initial {
  flex: 0 1 auto !important;
}

.k-flex-none {
  flex: none;
}

.\!k-flex-none {
  flex: none !important;
}

.k-gap-0 {
  gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-0 {
  gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-1px {
  gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-1px {
  gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-0\.5 {
  gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-0\.5 {
  gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-1 {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-1 {
  gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-1\.5 {
  gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-1\.5 {
  gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-2 {
  gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-2 {
  gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-2\.5 {
  gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-2\.5 {
  gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-3 {
  gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-3 {
  gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-3\.5 {
  gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-3\.5 {
  gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-4 {
  gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-4 {
  gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-4\.5 {
  gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-4\.5 {
  gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-5 {
  gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-5 {
  gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-5\.5 {
  gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-5\.5 {
  gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-6 {
  gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-6 {
  gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-6\.5 {
  gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-6\.5 {
  gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-7 {
  gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-7 {
  gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-7\.5 {
  gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-7\.5 {
  gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-8 {
  gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-8 {
  gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-9 {
  gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-9 {
  gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-10 {
  gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-10 {
  gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-11 {
  gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-11 {
  gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-12 {
  gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-12 {
  gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-13 {
  gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-13 {
  gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-14 {
  gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-14 {
  gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-15 {
  gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-15 {
  gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-16 {
  gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-16 {
  gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-17 {
  gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-17 {
  gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-18 {
  gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-18 {
  gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-19 {
  gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-19 {
  gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-20 {
  gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-20 {
  gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-21 {
  gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-21 {
  gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-22 {
  gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-22 {
  gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-23 {
  gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-23 {
  gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-24 {
  gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-24 {
  gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-25 {
  gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-25 {
  gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-26 {
  gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-26 {
  gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-27 {
  gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-27 {
  gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-28 {
  gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-28 {
  gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-29 {
  gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-29 {
  gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-30 {
  gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-30 {
  gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-xs {
  gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-xs {
  gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-sm {
  gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-sm {
  gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-md {
  gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-md {
  gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-lg {
  gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-lg {
  gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-xl {
  gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-xl {
  gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-thin {
  gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-thin {
  gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-hair {
  gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-hair {
  gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-gap-x-0 {
  column-gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-x-0 {
  column-gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-x-1px {
  column-gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-x-1px {
  column-gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-x-0\.5 {
  column-gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-x-0\.5 {
  column-gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-x-1 {
  column-gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-x-1 {
  column-gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-x-1\.5 {
  column-gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-x-1\.5 {
  column-gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-x-2 {
  column-gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-x-2 {
  column-gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-x-2\.5 {
  column-gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-x-2\.5 {
  column-gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-x-3 {
  column-gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-x-3 {
  column-gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-x-3\.5 {
  column-gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-x-3\.5 {
  column-gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-x-4 {
  column-gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-x-4 {
  column-gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-x-4\.5 {
  column-gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-x-4\.5 {
  column-gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-x-5 {
  column-gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-x-5 {
  column-gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-x-5\.5 {
  column-gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-x-5\.5 {
  column-gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-x-6 {
  column-gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-x-6 {
  column-gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-x-6\.5 {
  column-gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-x-6\.5 {
  column-gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-x-7 {
  column-gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-x-7 {
  column-gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-x-7\.5 {
  column-gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-x-7\.5 {
  column-gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-x-8 {
  column-gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-x-8 {
  column-gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-x-9 {
  column-gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-x-9 {
  column-gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-x-10 {
  column-gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-x-10 {
  column-gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-x-11 {
  column-gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-x-11 {
  column-gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-x-12 {
  column-gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-x-12 {
  column-gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-x-13 {
  column-gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-x-13 {
  column-gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-x-14 {
  column-gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-x-14 {
  column-gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-x-15 {
  column-gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-x-15 {
  column-gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-x-16 {
  column-gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-x-16 {
  column-gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-x-17 {
  column-gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-x-17 {
  column-gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-x-18 {
  column-gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-x-18 {
  column-gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-x-19 {
  column-gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-x-19 {
  column-gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-x-20 {
  column-gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-x-20 {
  column-gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-x-21 {
  column-gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-x-21 {
  column-gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-x-22 {
  column-gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-x-22 {
  column-gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-x-23 {
  column-gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-x-23 {
  column-gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-x-24 {
  column-gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-x-24 {
  column-gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-x-25 {
  column-gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-x-25 {
  column-gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-x-26 {
  column-gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-x-26 {
  column-gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-x-27 {
  column-gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-x-27 {
  column-gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-x-28 {
  column-gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-x-28 {
  column-gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-x-29 {
  column-gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-x-29 {
  column-gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-x-30 {
  column-gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-x-30 {
  column-gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-x-xs {
  column-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-x-xs {
  column-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-x-sm {
  column-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-x-sm {
  column-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-x-md {
  column-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-x-md {
  column-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-x-lg {
  column-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-x-lg {
  column-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-x-xl {
  column-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-x-xl {
  column-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-x-thin {
  column-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-x-thin {
  column-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-x-hair {
  column-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-x-hair {
  column-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-gap-y-0 {
  row-gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-y-0 {
  row-gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-y-1px {
  row-gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-y-1px {
  row-gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-y-0\.5 {
  row-gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-y-0\.5 {
  row-gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-y-1 {
  row-gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-y-1 {
  row-gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-y-1\.5 {
  row-gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-y-1\.5 {
  row-gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-y-2 {
  row-gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-y-2 {
  row-gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-y-2\.5 {
  row-gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-y-2\.5 {
  row-gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-y-3 {
  row-gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-y-3 {
  row-gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-y-3\.5 {
  row-gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-y-3\.5 {
  row-gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-y-4 {
  row-gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-y-4 {
  row-gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-y-4\.5 {
  row-gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-y-4\.5 {
  row-gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-y-5 {
  row-gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-y-5 {
  row-gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-y-5\.5 {
  row-gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-y-5\.5 {
  row-gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-y-6 {
  row-gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-y-6 {
  row-gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-y-6\.5 {
  row-gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-y-6\.5 {
  row-gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-y-7 {
  row-gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-y-7 {
  row-gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-y-7\.5 {
  row-gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-y-7\.5 {
  row-gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-y-8 {
  row-gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-y-8 {
  row-gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-y-9 {
  row-gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-y-9 {
  row-gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-y-10 {
  row-gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-y-10 {
  row-gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-y-11 {
  row-gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-y-11 {
  row-gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-y-12 {
  row-gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-y-12 {
  row-gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-y-13 {
  row-gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-y-13 {
  row-gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-y-14 {
  row-gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-y-14 {
  row-gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-y-15 {
  row-gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-y-15 {
  row-gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-y-16 {
  row-gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-y-16 {
  row-gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-y-17 {
  row-gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-y-17 {
  row-gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-y-18 {
  row-gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-y-18 {
  row-gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-y-19 {
  row-gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-y-19 {
  row-gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-y-20 {
  row-gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-y-20 {
  row-gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-y-21 {
  row-gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-y-21 {
  row-gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-y-22 {
  row-gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-y-22 {
  row-gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-y-23 {
  row-gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-y-23 {
  row-gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-y-24 {
  row-gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-y-24 {
  row-gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-y-25 {
  row-gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-y-25 {
  row-gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-y-26 {
  row-gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-y-26 {
  row-gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-y-27 {
  row-gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-y-27 {
  row-gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-y-28 {
  row-gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-y-28 {
  row-gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-y-29 {
  row-gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-y-29 {
  row-gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-y-30 {
  row-gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-y-30 {
  row-gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-y-xs {
  row-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-y-xs {
  row-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-y-sm {
  row-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-y-sm {
  row-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-y-md {
  row-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-y-md {
  row-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-y-lg {
  row-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-y-lg {
  row-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-y-xl {
  row-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-y-xl {
  row-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-y-thin {
  row-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-y-thin {
  row-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-y-hair {
  row-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-y-hair {
  row-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-grid-auto-cols-auto {
  grid-auto-columns: auto;
}

.\!k-grid-auto-cols-auto {
  grid-auto-columns: auto !important;
}

.k-grid-auto-cols-min {
  grid-auto-columns: min-content;
}

.\!k-grid-auto-cols-min {
  grid-auto-columns: min-content !important;
}

.k-grid-auto-cols-max {
  grid-auto-columns: max-content;
}

.\!k-grid-auto-cols-max {
  grid-auto-columns: max-content !important;
}

.k-grid-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.\!k-grid-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr) !important;
}

.k-grid-flow-row {
  grid-auto-flow: row;
}

.\!k-grid-flow-row {
  grid-auto-flow: row !important;
}

.k-grid-flow-col {
  grid-auto-flow: column;
}

.\!k-grid-flow-col {
  grid-auto-flow: column !important;
}

.k-grid-flow-dense {
  grid-auto-flow: dense;
}

.\!k-grid-flow-dense {
  grid-auto-flow: dense !important;
}

.k-grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.\!k-grid-flow-row-dense {
  grid-auto-flow: row dense !important;
}

.k-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.\!k-grid-flow-col-dense {
  grid-auto-flow: column dense !important;
}

.k-grid-flow-unset {
  grid-auto-flow: unset;
}

.\!k-grid-flow-unset {
  grid-auto-flow: unset !important;
}

.k-grid-auto-rows-auto {
  grid-auto-rows: auto;
}

.\!k-grid-auto-rows-auto {
  grid-auto-rows: auto !important;
}

.k-grid-auto-rows-min {
  grid-auto-rows: min-content;
}

.\!k-grid-auto-rows-min {
  grid-auto-rows: min-content !important;
}

.k-grid-auto-rows-max {
  grid-auto-rows: max-content;
}

.\!k-grid-auto-rows-max {
  grid-auto-rows: max-content !important;
}

.k-grid-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.\!k-grid-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr) !important;
}

.k-col-start-1 {
  grid-column-start: 1;
}

.\!k-col-start-1 {
  grid-column-start: 1 !important;
}

.k-col-start-2 {
  grid-column-start: 2;
}

.\!k-col-start-2 {
  grid-column-start: 2 !important;
}

.k-col-start-3 {
  grid-column-start: 3;
}

.\!k-col-start-3 {
  grid-column-start: 3 !important;
}

.k-col-start-4 {
  grid-column-start: 4;
}

.\!k-col-start-4 {
  grid-column-start: 4 !important;
}

.k-col-start-5 {
  grid-column-start: 5;
}

.\!k-col-start-5 {
  grid-column-start: 5 !important;
}

.k-col-start-6 {
  grid-column-start: 6;
}

.\!k-col-start-6 {
  grid-column-start: 6 !important;
}

.k-col-start-7 {
  grid-column-start: 7;
}

.\!k-col-start-7 {
  grid-column-start: 7 !important;
}

.k-col-start-8 {
  grid-column-start: 8;
}

.\!k-col-start-8 {
  grid-column-start: 8 !important;
}

.k-col-start-9 {
  grid-column-start: 9;
}

.\!k-col-start-9 {
  grid-column-start: 9 !important;
}

.k-col-start-10 {
  grid-column-start: 10;
}

.\!k-col-start-10 {
  grid-column-start: 10 !important;
}

.k-col-start-11 {
  grid-column-start: 11;
}

.\!k-col-start-11 {
  grid-column-start: 11 !important;
}

.k-col-start-12 {
  grid-column-start: 12;
}

.\!k-col-start-12 {
  grid-column-start: 12 !important;
}

.k-col-start-13 {
  grid-column-start: 13;
}

.\!k-col-start-13 {
  grid-column-start: 13 !important;
}

.k-col-start--1 {
  grid-column-start: -1;
}

.\!k-col-start--1 {
  grid-column-start: -1 !important;
}

.k-col-start--2 {
  grid-column-start: -2;
}

.\!k-col-start--2 {
  grid-column-start: -2 !important;
}

.k-col-start--3 {
  grid-column-start: -3;
}

.\!k-col-start--3 {
  grid-column-start: -3 !important;
}

.k-col-start--4 {
  grid-column-start: -4;
}

.\!k-col-start--4 {
  grid-column-start: -4 !important;
}

.k-col-start--5 {
  grid-column-start: -5;
}

.\!k-col-start--5 {
  grid-column-start: -5 !important;
}

.k-col-start--6 {
  grid-column-start: -6;
}

.\!k-col-start--6 {
  grid-column-start: -6 !important;
}

.k-col-start--7 {
  grid-column-start: -7;
}

.\!k-col-start--7 {
  grid-column-start: -7 !important;
}

.k-col-start--8 {
  grid-column-start: -8;
}

.\!k-col-start--8 {
  grid-column-start: -8 !important;
}

.k-col-start--9 {
  grid-column-start: -9;
}

.\!k-col-start--9 {
  grid-column-start: -9 !important;
}

.k-col-start--10 {
  grid-column-start: -10;
}

.\!k-col-start--10 {
  grid-column-start: -10 !important;
}

.k-col-start--11 {
  grid-column-start: -11;
}

.\!k-col-start--11 {
  grid-column-start: -11 !important;
}

.k-col-start--12 {
  grid-column-start: -12;
}

.\!k-col-start--12 {
  grid-column-start: -12 !important;
}

.k-col-start--13 {
  grid-column-start: -13;
}

.\!k-col-start--13 {
  grid-column-start: -13 !important;
}

.k-col-start-auto {
  grid-column-start: auto;
}

.\!k-col-start-auto {
  grid-column-start: auto !important;
}

.k-col-end-1 {
  grid-column-end: 1;
}

.\!k-col-end-1 {
  grid-column-end: 1 !important;
}

.k-col-end-2 {
  grid-column-end: 2;
}

.\!k-col-end-2 {
  grid-column-end: 2 !important;
}

.k-col-end-3 {
  grid-column-end: 3;
}

.\!k-col-end-3 {
  grid-column-end: 3 !important;
}

.k-col-end-4 {
  grid-column-end: 4;
}

.\!k-col-end-4 {
  grid-column-end: 4 !important;
}

.k-col-end-5 {
  grid-column-end: 5;
}

.\!k-col-end-5 {
  grid-column-end: 5 !important;
}

.k-col-end-6 {
  grid-column-end: 6;
}

.\!k-col-end-6 {
  grid-column-end: 6 !important;
}

.k-col-end-7 {
  grid-column-end: 7;
}

.\!k-col-end-7 {
  grid-column-end: 7 !important;
}

.k-col-end-8 {
  grid-column-end: 8;
}

.\!k-col-end-8 {
  grid-column-end: 8 !important;
}

.k-col-end-9 {
  grid-column-end: 9;
}

.\!k-col-end-9 {
  grid-column-end: 9 !important;
}

.k-col-end-10 {
  grid-column-end: 10;
}

.\!k-col-end-10 {
  grid-column-end: 10 !important;
}

.k-col-end-11 {
  grid-column-end: 11;
}

.\!k-col-end-11 {
  grid-column-end: 11 !important;
}

.k-col-end-12 {
  grid-column-end: 12;
}

.\!k-col-end-12 {
  grid-column-end: 12 !important;
}

.k-col-end-13 {
  grid-column-end: 13;
}

.\!k-col-end-13 {
  grid-column-end: 13 !important;
}

.k-col-end--1 {
  grid-column-end: -1;
}

.\!k-col-end--1 {
  grid-column-end: -1 !important;
}

.k-col-end--2 {
  grid-column-end: -2;
}

.\!k-col-end--2 {
  grid-column-end: -2 !important;
}

.k-col-end--3 {
  grid-column-end: -3;
}

.\!k-col-end--3 {
  grid-column-end: -3 !important;
}

.k-col-end--4 {
  grid-column-end: -4;
}

.\!k-col-end--4 {
  grid-column-end: -4 !important;
}

.k-col-end--5 {
  grid-column-end: -5;
}

.\!k-col-end--5 {
  grid-column-end: -5 !important;
}

.k-col-end--6 {
  grid-column-end: -6;
}

.\!k-col-end--6 {
  grid-column-end: -6 !important;
}

.k-col-end--7 {
  grid-column-end: -7;
}

.\!k-col-end--7 {
  grid-column-end: -7 !important;
}

.k-col-end--8 {
  grid-column-end: -8;
}

.\!k-col-end--8 {
  grid-column-end: -8 !important;
}

.k-col-end--9 {
  grid-column-end: -9;
}

.\!k-col-end--9 {
  grid-column-end: -9 !important;
}

.k-col-end--10 {
  grid-column-end: -10;
}

.\!k-col-end--10 {
  grid-column-end: -10 !important;
}

.k-col-end--11 {
  grid-column-end: -11;
}

.\!k-col-end--11 {
  grid-column-end: -11 !important;
}

.k-col-end--12 {
  grid-column-end: -12;
}

.\!k-col-end--12 {
  grid-column-end: -12 !important;
}

.k-col-end--13 {
  grid-column-end: -13;
}

.\!k-col-end--13 {
  grid-column-end: -13 !important;
}

.k-col-end-auto {
  grid-column-end: auto;
}

.\!k-col-end-auto {
  grid-column-end: auto !important;
}

.k-col-span-1 {
  grid-column: span 1/span 1;
}

.\!k-col-span-1 {
  grid-column: span 1/span 1 !important;
}

.k-col-span-2 {
  grid-column: span 2/span 2;
}

.\!k-col-span-2 {
  grid-column: span 2/span 2 !important;
}

.k-col-span-3 {
  grid-column: span 3/span 3;
}

.\!k-col-span-3 {
  grid-column: span 3/span 3 !important;
}

.k-col-span-4 {
  grid-column: span 4/span 4;
}

.\!k-col-span-4 {
  grid-column: span 4/span 4 !important;
}

.k-col-span-5 {
  grid-column: span 5/span 5;
}

.\!k-col-span-5 {
  grid-column: span 5/span 5 !important;
}

.k-col-span-6 {
  grid-column: span 6/span 6;
}

.\!k-col-span-6 {
  grid-column: span 6/span 6 !important;
}

.k-col-span-7 {
  grid-column: span 7/span 7;
}

.\!k-col-span-7 {
  grid-column: span 7/span 7 !important;
}

.k-col-span-8 {
  grid-column: span 8/span 8;
}

.\!k-col-span-8 {
  grid-column: span 8/span 8 !important;
}

.k-col-span-9 {
  grid-column: span 9/span 9;
}

.\!k-col-span-9 {
  grid-column: span 9/span 9 !important;
}

.k-col-span-10 {
  grid-column: span 10/span 10;
}

.\!k-col-span-10 {
  grid-column: span 10/span 10 !important;
}

.k-col-span-11 {
  grid-column: span 11/span 11;
}

.\!k-col-span-11 {
  grid-column: span 11/span 11 !important;
}

.k-col-span-12 {
  grid-column: span 12/span 12;
}

.\!k-col-span-12 {
  grid-column: span 12/span 12 !important;
}

.k-col-span-full {
  grid-column: 1 / -1;
}

.\!k-col-span-full {
  grid-column: 1 / -1 !important;
}

.k-col-span-auto {
  grid-column: auto;
}

.\!k-col-span-auto {
  grid-column: auto !important;
}

.k-colspan-1 {
  grid-column: span 1/span 1;
}

.\!k-colspan-1 {
  grid-column: span 1/span 1 !important;
}

.k-colspan-2 {
  grid-column: span 2/span 2;
}

.\!k-colspan-2 {
  grid-column: span 2/span 2 !important;
}

.k-colspan-3 {
  grid-column: span 3/span 3;
}

.\!k-colspan-3 {
  grid-column: span 3/span 3 !important;
}

.k-colspan-4 {
  grid-column: span 4/span 4;
}

.\!k-colspan-4 {
  grid-column: span 4/span 4 !important;
}

.k-colspan-5 {
  grid-column: span 5/span 5;
}

.\!k-colspan-5 {
  grid-column: span 5/span 5 !important;
}

.k-colspan-6 {
  grid-column: span 6/span 6;
}

.\!k-colspan-6 {
  grid-column: span 6/span 6 !important;
}

.k-colspan-7 {
  grid-column: span 7/span 7;
}

.\!k-colspan-7 {
  grid-column: span 7/span 7 !important;
}

.k-colspan-8 {
  grid-column: span 8/span 8;
}

.\!k-colspan-8 {
  grid-column: span 8/span 8 !important;
}

.k-colspan-9 {
  grid-column: span 9/span 9;
}

.\!k-colspan-9 {
  grid-column: span 9/span 9 !important;
}

.k-colspan-10 {
  grid-column: span 10/span 10;
}

.\!k-colspan-10 {
  grid-column: span 10/span 10 !important;
}

.k-colspan-11 {
  grid-column: span 11/span 11;
}

.\!k-colspan-11 {
  grid-column: span 11/span 11 !important;
}

.k-colspan-12 {
  grid-column: span 12/span 12;
}

.\!k-colspan-12 {
  grid-column: span 12/span 12 !important;
}

.k-colspan-full, .k-colspan-all {
  grid-column: 1 / -1;
}

.\!k-colspan-full, .\!k-colspan-all {
  grid-column: 1 / -1 !important;
}

.k-colspan-auto {
  grid-column: auto;
}

.\!k-colspan-auto {
  grid-column: auto !important;
}

.k-row-start-1 {
  grid-row-start: 1;
}

.\!k-row-start-1 {
  grid-row-start: 1 !important;
}

.k-row-start-2 {
  grid-row-start: 2;
}

.\!k-row-start-2 {
  grid-row-start: 2 !important;
}

.k-row-start-3 {
  grid-row-start: 3;
}

.\!k-row-start-3 {
  grid-row-start: 3 !important;
}

.k-row-start-4 {
  grid-row-start: 4;
}

.\!k-row-start-4 {
  grid-row-start: 4 !important;
}

.k-row-start-5 {
  grid-row-start: 5;
}

.\!k-row-start-5 {
  grid-row-start: 5 !important;
}

.k-row-start-6 {
  grid-row-start: 6;
}

.\!k-row-start-6 {
  grid-row-start: 6 !important;
}

.k-row-start-7 {
  grid-row-start: 7;
}

.\!k-row-start-7 {
  grid-row-start: 7 !important;
}

.k-row-start-8 {
  grid-row-start: 8;
}

.\!k-row-start-8 {
  grid-row-start: 8 !important;
}

.k-row-start-9 {
  grid-row-start: 9;
}

.\!k-row-start-9 {
  grid-row-start: 9 !important;
}

.k-row-start-10 {
  grid-row-start: 10;
}

.\!k-row-start-10 {
  grid-row-start: 10 !important;
}

.k-row-start-11 {
  grid-row-start: 11;
}

.\!k-row-start-11 {
  grid-row-start: 11 !important;
}

.k-row-start-12 {
  grid-row-start: 12;
}

.\!k-row-start-12 {
  grid-row-start: 12 !important;
}

.k-row-start-13 {
  grid-row-start: 13;
}

.\!k-row-start-13 {
  grid-row-start: 13 !important;
}

.k-row-start--1 {
  grid-row-start: -1;
}

.\!k-row-start--1 {
  grid-row-start: -1 !important;
}

.k-row-start--2 {
  grid-row-start: -2;
}

.\!k-row-start--2 {
  grid-row-start: -2 !important;
}

.k-row-start--3 {
  grid-row-start: -3;
}

.\!k-row-start--3 {
  grid-row-start: -3 !important;
}

.k-row-start--4 {
  grid-row-start: -4;
}

.\!k-row-start--4 {
  grid-row-start: -4 !important;
}

.k-row-start--5 {
  grid-row-start: -5;
}

.\!k-row-start--5 {
  grid-row-start: -5 !important;
}

.k-row-start--6 {
  grid-row-start: -6;
}

.\!k-row-start--6 {
  grid-row-start: -6 !important;
}

.k-row-start--7 {
  grid-row-start: -7;
}

.\!k-row-start--7 {
  grid-row-start: -7 !important;
}

.k-row-start--8 {
  grid-row-start: -8;
}

.\!k-row-start--8 {
  grid-row-start: -8 !important;
}

.k-row-start--9 {
  grid-row-start: -9;
}

.\!k-row-start--9 {
  grid-row-start: -9 !important;
}

.k-row-start--10 {
  grid-row-start: -10;
}

.\!k-row-start--10 {
  grid-row-start: -10 !important;
}

.k-row-start--11 {
  grid-row-start: -11;
}

.\!k-row-start--11 {
  grid-row-start: -11 !important;
}

.k-row-start--12 {
  grid-row-start: -12;
}

.\!k-row-start--12 {
  grid-row-start: -12 !important;
}

.k-row-start--13 {
  grid-row-start: -13;
}

.\!k-row-start--13 {
  grid-row-start: -13 !important;
}

.k-row-start-auto {
  grid-row-start: auto;
}

.\!k-row-start-auto {
  grid-row-start: auto !important;
}

.k-row-end-1 {
  grid-row-end: 1;
}

.\!k-row-end-1 {
  grid-row-end: 1 !important;
}

.k-row-end-2 {
  grid-row-end: 2;
}

.\!k-row-end-2 {
  grid-row-end: 2 !important;
}

.k-row-end-3 {
  grid-row-end: 3;
}

.\!k-row-end-3 {
  grid-row-end: 3 !important;
}

.k-row-end-4 {
  grid-row-end: 4;
}

.\!k-row-end-4 {
  grid-row-end: 4 !important;
}

.k-row-end-5 {
  grid-row-end: 5;
}

.\!k-row-end-5 {
  grid-row-end: 5 !important;
}

.k-row-end-6 {
  grid-row-end: 6;
}

.\!k-row-end-6 {
  grid-row-end: 6 !important;
}

.k-row-end-7 {
  grid-row-end: 7;
}

.\!k-row-end-7 {
  grid-row-end: 7 !important;
}

.k-row-end-8 {
  grid-row-end: 8;
}

.\!k-row-end-8 {
  grid-row-end: 8 !important;
}

.k-row-end-9 {
  grid-row-end: 9;
}

.\!k-row-end-9 {
  grid-row-end: 9 !important;
}

.k-row-end-10 {
  grid-row-end: 10;
}

.\!k-row-end-10 {
  grid-row-end: 10 !important;
}

.k-row-end-11 {
  grid-row-end: 11;
}

.\!k-row-end-11 {
  grid-row-end: 11 !important;
}

.k-row-end-12 {
  grid-row-end: 12;
}

.\!k-row-end-12 {
  grid-row-end: 12 !important;
}

.k-row-end-13 {
  grid-row-end: 13;
}

.\!k-row-end-13 {
  grid-row-end: 13 !important;
}

.k-row-end--1 {
  grid-row-end: -1;
}

.\!k-row-end--1 {
  grid-row-end: -1 !important;
}

.k-row-end--2 {
  grid-row-end: -2;
}

.\!k-row-end--2 {
  grid-row-end: -2 !important;
}

.k-row-end--3 {
  grid-row-end: -3;
}

.\!k-row-end--3 {
  grid-row-end: -3 !important;
}

.k-row-end--4 {
  grid-row-end: -4;
}

.\!k-row-end--4 {
  grid-row-end: -4 !important;
}

.k-row-end--5 {
  grid-row-end: -5;
}

.\!k-row-end--5 {
  grid-row-end: -5 !important;
}

.k-row-end--6 {
  grid-row-end: -6;
}

.\!k-row-end--6 {
  grid-row-end: -6 !important;
}

.k-row-end--7 {
  grid-row-end: -7;
}

.\!k-row-end--7 {
  grid-row-end: -7 !important;
}

.k-row-end--8 {
  grid-row-end: -8;
}

.\!k-row-end--8 {
  grid-row-end: -8 !important;
}

.k-row-end--9 {
  grid-row-end: -9;
}

.\!k-row-end--9 {
  grid-row-end: -9 !important;
}

.k-row-end--10 {
  grid-row-end: -10;
}

.\!k-row-end--10 {
  grid-row-end: -10 !important;
}

.k-row-end--11 {
  grid-row-end: -11;
}

.\!k-row-end--11 {
  grid-row-end: -11 !important;
}

.k-row-end--12 {
  grid-row-end: -12;
}

.\!k-row-end--12 {
  grid-row-end: -12 !important;
}

.k-row-end--13 {
  grid-row-end: -13;
}

.\!k-row-end--13 {
  grid-row-end: -13 !important;
}

.k-row-end-auto {
  grid-row-end: auto;
}

.\!k-row-end-auto {
  grid-row-end: auto !important;
}

.k-row-span-1 {
  grid-row: span 1/span 1;
}

.\!k-row-span-1 {
  grid-row: span 1/span 1 !important;
}

.k-row-span-2 {
  grid-row: span 2/span 2;
}

.\!k-row-span-2 {
  grid-row: span 2/span 2 !important;
}

.k-row-span-3 {
  grid-row: span 3/span 3;
}

.\!k-row-span-3 {
  grid-row: span 3/span 3 !important;
}

.k-row-span-4 {
  grid-row: span 4/span 4;
}

.\!k-row-span-4 {
  grid-row: span 4/span 4 !important;
}

.k-row-span-5 {
  grid-row: span 5/span 5;
}

.\!k-row-span-5 {
  grid-row: span 5/span 5 !important;
}

.k-row-span-6 {
  grid-row: span 6/span 6;
}

.\!k-row-span-6 {
  grid-row: span 6/span 6 !important;
}

.k-row-span-7 {
  grid-row: span 7/span 7;
}

.\!k-row-span-7 {
  grid-row: span 7/span 7 !important;
}

.k-row-span-8 {
  grid-row: span 8/span 8;
}

.\!k-row-span-8 {
  grid-row: span 8/span 8 !important;
}

.k-row-span-9 {
  grid-row: span 9/span 9;
}

.\!k-row-span-9 {
  grid-row: span 9/span 9 !important;
}

.k-row-span-10 {
  grid-row: span 10/span 10;
}

.\!k-row-span-10 {
  grid-row: span 10/span 10 !important;
}

.k-row-span-11 {
  grid-row: span 11/span 11;
}

.\!k-row-span-11 {
  grid-row: span 11/span 11 !important;
}

.k-row-span-12 {
  grid-row: span 12/span 12;
}

.\!k-row-span-12 {
  grid-row: span 12/span 12 !important;
}

.k-row-span-full {
  grid-row: span 1/span -1;
}

.\!k-row-span-full {
  grid-row: span 1/span -1 !important;
}

.k-row-span-auto {
  grid-row: auto;
}

.\!k-row-span-auto {
  grid-row: auto !important;
}

.k-rowspan-1 {
  grid-row: span 1/span 1;
}

.\!k-rowspan-1 {
  grid-row: span 1/span 1 !important;
}

.k-rowspan-2 {
  grid-row: span 2/span 2;
}

.\!k-rowspan-2 {
  grid-row: span 2/span 2 !important;
}

.k-rowspan-3 {
  grid-row: span 3/span 3;
}

.\!k-rowspan-3 {
  grid-row: span 3/span 3 !important;
}

.k-rowspan-4 {
  grid-row: span 4/span 4;
}

.\!k-rowspan-4 {
  grid-row: span 4/span 4 !important;
}

.k-rowspan-5 {
  grid-row: span 5/span 5;
}

.\!k-rowspan-5 {
  grid-row: span 5/span 5 !important;
}

.k-rowspan-6 {
  grid-row: span 6/span 6;
}

.\!k-rowspan-6 {
  grid-row: span 6/span 6 !important;
}

.k-rowspan-7 {
  grid-row: span 7/span 7;
}

.\!k-rowspan-7 {
  grid-row: span 7/span 7 !important;
}

.k-rowspan-8 {
  grid-row: span 8/span 8;
}

.\!k-rowspan-8 {
  grid-row: span 8/span 8 !important;
}

.k-rowspan-9 {
  grid-row: span 9/span 9;
}

.\!k-rowspan-9 {
  grid-row: span 9/span 9 !important;
}

.k-rowspan-10 {
  grid-row: span 10/span 10;
}

.\!k-rowspan-10 {
  grid-row: span 10/span 10 !important;
}

.k-rowspan-11 {
  grid-row: span 11/span 11;
}

.\!k-rowspan-11 {
  grid-row: span 11/span 11 !important;
}

.k-rowspan-12 {
  grid-row: span 12/span 12;
}

.\!k-rowspan-12 {
  grid-row: span 12/span 12 !important;
}

.k-rowspan-full, .k-rowspan-all {
  grid-row: span 1/span -1;
}

.\!k-rowspan-full, .\!k-rowspan-all {
  grid-row: span 1/span -1 !important;
}

.k-rowspan-auto {
  grid-row: auto;
}

.\!k-rowspan-auto {
  grid-row: auto !important;
}

.k-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.\!k-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.\!k-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.\!k-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.\!k-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.\!k-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.\!k-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.\!k-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.\!k-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.\!k-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.\!k-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.\!k-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.\!k-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-cols-none {
  grid-template-columns: none;
}

.\!k-grid-cols-none {
  grid-template-columns: none !important;
}

.k-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.\!k-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.\!k-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.\!k-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.\!k-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.\!k-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.\!k-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.\!k-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.\!k-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

.\!k-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.\!k-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr));
}

.\!k-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr));
}

.\!k-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-rows-none {
  grid-template-rows: none;
}

.\!k-grid-rows-none {
  grid-template-rows: none !important;
}

.k-justify-content-normal {
  justify-content: normal;
}

.\!k-justify-content-normal {
  justify-content: normal !important;
}

.k-justify-content-stretch {
  justify-content: stretch;
}

.\!k-justify-content-stretch {
  justify-content: stretch !important;
}

.k-justify-content-center {
  justify-content: center;
}

.\!k-justify-content-center {
  justify-content: center !important;
}

.k-justify-content-start {
  justify-content: start;
}

.\!k-justify-content-start {
  justify-content: start !important;
}

.k-justify-content-end {
  justify-content: end;
}

.\!k-justify-content-end {
  justify-content: end !important;
}

.k-justify-content-flex-start {
  justify-content: flex-start;
}

.\!k-justify-content-flex-start {
  justify-content: flex-start !important;
}

.k-justify-content-flex-end {
  justify-content: flex-end;
}

.\!k-justify-content-flex-end {
  justify-content: flex-end !important;
}

.k-justify-content-left {
  justify-content: left;
}

.\!k-justify-content-left {
  justify-content: left !important;
}

.k-justify-content-right {
  justify-content: right;
}

.\!k-justify-content-right {
  justify-content: right !important;
}

.k-justify-content-baseline {
  justify-content: baseline;
}

.\!k-justify-content-baseline {
  justify-content: baseline !important;
}

.k-justify-content-first-baseline {
  justify-content: first baseline;
}

.\!k-justify-content-first-baseline {
  justify-content: first baseline !important;
}

.k-justify-content-last-baseline {
  justify-content: last baseline;
}

.\!k-justify-content-last-baseline {
  justify-content: last baseline !important;
}

.k-justify-content-between {
  justify-content: space-between;
}

.\!k-justify-content-between {
  justify-content: space-between !important;
}

.k-justify-content-around {
  justify-content: space-around;
}

.\!k-justify-content-around {
  justify-content: space-around !important;
}

.k-justify-content-evenly {
  justify-content: space-evenly;
}

.\!k-justify-content-evenly {
  justify-content: space-evenly !important;
}

.k-justify-content-stretch > * {
  flex: 1 0 0%;
}

.\!.k-justify-content-stretch > * {
  flex: 1 0 0% !important;
}

.k-justify-items-normal {
  justify-items: normal;
}

.\!k-justify-items-normal {
  justify-items: normal !important;
}

.k-justify-items-stretch {
  justify-items: stretch;
}

.\!k-justify-items-stretch {
  justify-items: stretch !important;
}

.k-justify-items-center {
  justify-items: center;
}

.\!k-justify-items-center {
  justify-items: center !important;
}

.k-justify-items-start {
  justify-items: start;
}

.\!k-justify-items-start {
  justify-items: start !important;
}

.k-justify-items-end {
  justify-items: end;
}

.\!k-justify-items-end {
  justify-items: end !important;
}

.k-justify-items-flex-start {
  justify-items: flex-start;
}

.\!k-justify-items-flex-start {
  justify-items: flex-start !important;
}

.k-justify-items-flex-end {
  justify-items: flex-end;
}

.\!k-justify-items-flex-end {
  justify-items: flex-end !important;
}

.k-justify-items-self-start {
  justify-items: self-start;
}

.\!k-justify-items-self-start {
  justify-items: self-start !important;
}

.k-justify-items-self-end {
  justify-items: self-end;
}

.\!k-justify-items-self-end {
  justify-items: self-end !important;
}

.k-justify-items-left {
  justify-items: left;
}

.\!k-justify-items-left {
  justify-items: left !important;
}

.k-justify-items-right {
  justify-items: right;
}

.\!k-justify-items-right {
  justify-items: right !important;
}

.k-justify-items-baseline {
  justify-items: baseline;
}

.\!k-justify-items-baseline {
  justify-items: baseline !important;
}

.k-justify-items-first-baseline {
  justify-items: first baseline;
}

.\!k-justify-items-first-baseline {
  justify-items: first baseline !important;
}

.k-justify-items-last-baseline {
  justify-items: last baseline;
}

.\!k-justify-items-last-baseline {
  justify-items: last baseline !important;
}

.k-justify-self-auto {
  justify-self: auto;
}

.\!k-justify-self-auto {
  justify-self: auto !important;
}

.k-justify-self-normal {
  justify-self: normal;
}

.\!k-justify-self-normal {
  justify-self: normal !important;
}

.k-justify-self-stretch {
  justify-self: stretch;
}

.\!k-justify-self-stretch {
  justify-self: stretch !important;
}

.k-justify-self-center {
  justify-self: center;
}

.\!k-justify-self-center {
  justify-self: center !important;
}

.k-justify-self-start {
  justify-self: start;
}

.\!k-justify-self-start {
  justify-self: start !important;
}

.k-justify-self-end {
  justify-self: end;
}

.\!k-justify-self-end {
  justify-self: end !important;
}

.k-justify-self-flex-start {
  justify-self: flex-start;
}

.\!k-justify-self-flex-start {
  justify-self: flex-start !important;
}

.k-justify-self-flex-end {
  justify-self: flex-end;
}

.\!k-justify-self-flex-end {
  justify-self: flex-end !important;
}

.k-justify-self-self-start {
  justify-self: self-start;
}

.\!k-justify-self-self-start {
  justify-self: self-start !important;
}

.k-justify-self-self-end {
  justify-self: self-end;
}

.\!k-justify-self-self-end {
  justify-self: self-end !important;
}

.k-justify-self-baseline {
  justify-self: baseline;
}

.\!k-justify-self-baseline {
  justify-self: baseline !important;
}

.k-justify-self-first-baseline {
  justify-self: first baseline;
}

.\!k-justify-self-first-baseline {
  justify-self: first baseline !important;
}

.k-justify-self-last-baseline {
  justify-self: last baseline;
}

.\!k-justify-self-last-baseline {
  justify-self: last baseline !important;
}

.k-order-1 {
  order: 1;
}

.\!k-order-1 {
  order: 1 !important;
}

.k-order-2 {
  order: 2;
}

.\!k-order-2 {
  order: 2 !important;
}

.k-order-3 {
  order: 3;
}

.\!k-order-3 {
  order: 3 !important;
}

.k-order-4 {
  order: 4;
}

.\!k-order-4 {
  order: 4 !important;
}

.k-order-5 {
  order: 5;
}

.\!k-order-5 {
  order: 5 !important;
}

.k-order-6 {
  order: 6;
}

.\!k-order-6 {
  order: 6 !important;
}

.k-order-7 {
  order: 7;
}

.\!k-order-7 {
  order: 7 !important;
}

.k-order-8 {
  order: 8;
}

.\!k-order-8 {
  order: 8 !important;
}

.k-order-9 {
  order: 9;
}

.\!k-order-9 {
  order: 9 !important;
}

.k-order-10 {
  order: 10;
}

.\!k-order-10 {
  order: 10 !important;
}

.k-order-11 {
  order: 11;
}

.\!k-order-11 {
  order: 11 !important;
}

.k-order-12 {
  order: 12;
}

.\!k-order-12 {
  order: 12 !important;
}

.k-order-first {
  order: -9999;
}

.\!k-order-first {
  order: -9999 !important;
}

.k-order-last {
  order: 9999;
}

.\!k-order-last {
  order: 9999 !important;
}

.k-order-none {
  order: 0;
}

.\!k-order-none {
  order: 0 !important;
}

.k-place-content-normal {
  place-content: normal;
}

.\!k-place-content-normal {
  place-content: normal !important;
}

.k-place-content-stretch {
  place-content: stretch;
}

.\!k-place-content-stretch {
  place-content: stretch !important;
}

.k-place-content-center {
  place-content: center;
}

.\!k-place-content-center {
  place-content: center !important;
}

.k-place-content-start {
  place-content: start;
}

.\!k-place-content-start {
  place-content: start !important;
}

.k-place-content-end {
  place-content: end;
}

.\!k-place-content-end {
  place-content: end !important;
}

.k-place-content-flex-start {
  place-content: flex-start;
}

.\!k-place-content-flex-start {
  place-content: flex-start !important;
}

.k-place-content-flex-end {
  place-content: flex-end;
}

.\!k-place-content-flex-end {
  place-content: flex-end !important;
}

.k-place-content-baseline {
  place-content: baseline;
}

.\!k-place-content-baseline {
  place-content: baseline !important;
}

.k-place-content-between {
  place-content: space-between;
}

.\!k-place-content-between {
  place-content: space-between !important;
}

.k-place-content-around {
  place-content: space-around;
}

.\!k-place-content-around {
  place-content: space-around !important;
}

.k-place-content-evenly {
  place-content: space-evenly;
}

.\!k-place-content-evenly {
  place-content: space-evenly !important;
}

.k-place-items-normal {
  place-items: normal;
}

.\!k-place-items-normal {
  place-items: normal !important;
}

.k-place-items-stretch {
  place-items: stretch;
}

.\!k-place-items-stretch {
  place-items: stretch !important;
}

.k-place-items-center {
  place-items: center;
}

.\!k-place-items-center {
  place-items: center !important;
}

.k-place-items-start {
  place-items: start;
}

.\!k-place-items-start {
  place-items: start !important;
}

.k-place-items-end {
  place-items: end;
}

.\!k-place-items-end {
  place-items: end !important;
}

.k-place-items-flex-start {
  place-items: flex-start;
}

.\!k-place-items-flex-start {
  place-items: flex-start !important;
}

.k-place-items-flex-end {
  place-items: flex-end;
}

.\!k-place-items-flex-end {
  place-items: flex-end !important;
}

.k-place-items-self-start {
  place-items: self-start;
}

.\!k-place-items-self-start {
  place-items: self-start !important;
}

.k-place-items-self-end {
  place-items: self-end;
}

.\!k-place-items-self-end {
  place-items: self-end !important;
}

.k-place-items-baseline {
  place-items: baseline;
}

.\!k-place-items-baseline {
  place-items: baseline !important;
}

.k-place-self-auto {
  place-self: auto;
}

.\!k-place-self-auto {
  place-self: auto !important;
}

.k-place-self-normal {
  place-self: normal;
}

.\!k-place-self-normal {
  place-self: normal !important;
}

.k-place-self-stretch {
  place-self: stretch;
}

.\!k-place-self-stretch {
  place-self: stretch !important;
}

.k-place-self-center {
  place-self: center;
}

.\!k-place-self-center {
  place-self: center !important;
}

.k-place-self-start {
  place-self: start;
}

.\!k-place-self-start {
  place-self: start !important;
}

.k-place-self-end {
  place-self: end;
}

.\!k-place-self-end {
  place-self: end !important;
}

.k-place-self-flex-start {
  place-self: flex-start;
}

.\!k-place-self-flex-start {
  place-self: flex-start !important;
}

.k-place-self-flex-end {
  place-self: flex-end;
}

.\!k-place-self-flex-end {
  place-self: flex-end !important;
}

.k-place-self-self-start {
  place-self: self-start;
}

.\!k-place-self-self-start {
  place-self: self-start !important;
}

.k-place-self-self-end {
  place-self: self-end;
}

.\!k-place-self-self-end {
  place-self: self-end !important;
}

.k-place-self-baseline {
  place-self: baseline;
}

.\!k-place-self-baseline {
  place-self: baseline !important;
}

.k-m-0 {
  margin: var(--kendo-spacing-0, 0px);
}

.\!k-m-0 {
  margin: var(--kendo-spacing-0, 0px) !important;
}

.k-m-1px {
  margin: var(--kendo-spacing-1px, 1px);
}

.\!k-m-1px {
  margin: var(--kendo-spacing-1px, 1px) !important;
}

.k-m-0\.5 {
  margin: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-m-0\.5 {
  margin: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-m-1 {
  margin: var(--kendo-spacing-1, 0.25rem);
}

.\!k-m-1 {
  margin: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-m-1\.5 {
  margin: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-m-1\.5 {
  margin: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-m-2 {
  margin: var(--kendo-spacing-2, 0.5rem);
}

.\!k-m-2 {
  margin: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-m-2\.5 {
  margin: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-m-2\.5 {
  margin: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-m-3 {
  margin: var(--kendo-spacing-3, 0.75rem);
}

.\!k-m-3 {
  margin: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-m-3\.5 {
  margin: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-m-3\.5 {
  margin: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-m-4 {
  margin: var(--kendo-spacing-4, 1rem);
}

.\!k-m-4 {
  margin: var(--kendo-spacing-4, 1rem) !important;
}

.k-m-4\.5 {
  margin: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-m-4\.5 {
  margin: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-m-5 {
  margin: var(--kendo-spacing-5, 1.25rem);
}

.\!k-m-5 {
  margin: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-m-5\.5 {
  margin: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-m-5\.5 {
  margin: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-m-6 {
  margin: var(--kendo-spacing-6, 1.5rem);
}

.\!k-m-6 {
  margin: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-m-6\.5 {
  margin: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-m-6\.5 {
  margin: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-m-7 {
  margin: var(--kendo-spacing-7, 1.75rem);
}

.\!k-m-7 {
  margin: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-m-7\.5 {
  margin: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-m-7\.5 {
  margin: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-m-8 {
  margin: var(--kendo-spacing-8, 2rem);
}

.\!k-m-8 {
  margin: var(--kendo-spacing-8, 2rem) !important;
}

.k-m-9 {
  margin: var(--kendo-spacing-9, 2.25rem);
}

.\!k-m-9 {
  margin: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-m-10 {
  margin: var(--kendo-spacing-10, 2.5rem);
}

.\!k-m-10 {
  margin: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-m-11 {
  margin: var(--kendo-spacing-11, 2.75rem);
}

.\!k-m-11 {
  margin: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-m-12 {
  margin: var(--kendo-spacing-12, 3rem);
}

.\!k-m-12 {
  margin: var(--kendo-spacing-12, 3rem) !important;
}

.k-m-13 {
  margin: var(--kendo-spacing-13, 3.25rem);
}

.\!k-m-13 {
  margin: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-m-14 {
  margin: var(--kendo-spacing-14, 3.5rem);
}

.\!k-m-14 {
  margin: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-m-15 {
  margin: var(--kendo-spacing-15, 3.75rem);
}

.\!k-m-15 {
  margin: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-m-16 {
  margin: var(--kendo-spacing-16, 4rem);
}

.\!k-m-16 {
  margin: var(--kendo-spacing-16, 4rem) !important;
}

.k-m-17 {
  margin: var(--kendo-spacing-17, 4.25rem);
}

.\!k-m-17 {
  margin: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-m-18 {
  margin: var(--kendo-spacing-18, 4.5rem);
}

.\!k-m-18 {
  margin: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-m-19 {
  margin: var(--kendo-spacing-19, 4.75rem);
}

.\!k-m-19 {
  margin: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-m-20 {
  margin: var(--kendo-spacing-20, 5rem);
}

.\!k-m-20 {
  margin: var(--kendo-spacing-20, 5rem) !important;
}

.k-m-21 {
  margin: var(--kendo-spacing-21, 5.25rem);
}

.\!k-m-21 {
  margin: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-m-22 {
  margin: var(--kendo-spacing-22, 5.5rem);
}

.\!k-m-22 {
  margin: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-m-23 {
  margin: var(--kendo-spacing-23, 5.75rem);
}

.\!k-m-23 {
  margin: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-m-24 {
  margin: var(--kendo-spacing-24, 6rem);
}

.\!k-m-24 {
  margin: var(--kendo-spacing-24, 6rem) !important;
}

.k-m-25 {
  margin: var(--kendo-spacing-25, 7rem);
}

.\!k-m-25 {
  margin: var(--kendo-spacing-25, 7rem) !important;
}

.k-m-26 {
  margin: var(--kendo-spacing-26, 8rem);
}

.\!k-m-26 {
  margin: var(--kendo-spacing-26, 8rem) !important;
}

.k-m-27 {
  margin: var(--kendo-spacing-27, 9rem);
}

.\!k-m-27 {
  margin: var(--kendo-spacing-27, 9rem) !important;
}

.k-m-28 {
  margin: var(--kendo-spacing-28, 10rem);
}

.\!k-m-28 {
  margin: var(--kendo-spacing-28, 10rem) !important;
}

.k-m-29 {
  margin: var(--kendo-spacing-29, 11rem);
}

.\!k-m-29 {
  margin: var(--kendo-spacing-29, 11rem) !important;
}

.k-m-30 {
  margin: var(--kendo-spacing-30, 12rem);
}

.\!k-m-30 {
  margin: var(--kendo-spacing-30, 12rem) !important;
}

.k-m--0 {
  margin: var(--kendo-spacing--0, 0px);
}

.\!k-m--0 {
  margin: var(--kendo-spacing--0, 0px) !important;
}

.k-m--1px {
  margin: var(--kendo-spacing--1px, -1px);
}

.\!k-m--1px {
  margin: var(--kendo-spacing--1px, -1px) !important;
}

.k-m--0\.5 {
  margin: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-m--0\.5 {
  margin: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-m--1 {
  margin: var(--kendo-spacing--1, -0.25rem);
}

.\!k-m--1 {
  margin: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-m--1\.5 {
  margin: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-m--1\.5 {
  margin: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-m--2 {
  margin: var(--kendo-spacing--2, -0.5rem);
}

.\!k-m--2 {
  margin: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-m--2\.5 {
  margin: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-m--2\.5 {
  margin: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-m--3 {
  margin: var(--kendo-spacing--3, -0.75rem);
}

.\!k-m--3 {
  margin: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-m--3\.5 {
  margin: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-m--3\.5 {
  margin: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-m--4 {
  margin: var(--kendo-spacing--4, -1rem);
}

.\!k-m--4 {
  margin: var(--kendo-spacing--4, -1rem) !important;
}

.k-m--4\.5 {
  margin: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-m--4\.5 {
  margin: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-m--5 {
  margin: var(--kendo-spacing--5, -1.25rem);
}

.\!k-m--5 {
  margin: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-m--5\.5 {
  margin: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-m--5\.5 {
  margin: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-m--6 {
  margin: var(--kendo-spacing--6, -1.5rem);
}

.\!k-m--6 {
  margin: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-m--6\.5 {
  margin: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-m--6\.5 {
  margin: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-m--7 {
  margin: var(--kendo-spacing--7, -1.75rem);
}

.\!k-m--7 {
  margin: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-m--7\.5 {
  margin: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-m--7\.5 {
  margin: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-m--8 {
  margin: var(--kendo-spacing--8, -2rem);
}

.\!k-m--8 {
  margin: var(--kendo-spacing--8, -2rem) !important;
}

.k-m--9 {
  margin: var(--kendo-spacing--9, -2.25rem);
}

.\!k-m--9 {
  margin: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-m--10 {
  margin: var(--kendo-spacing--10, -2.5rem);
}

.\!k-m--10 {
  margin: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-m--11 {
  margin: var(--kendo-spacing--11, -2.75rem);
}

.\!k-m--11 {
  margin: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-m--12 {
  margin: var(--kendo-spacing--12, -3rem);
}

.\!k-m--12 {
  margin: var(--kendo-spacing--12, -3rem) !important;
}

.k-m--13 {
  margin: var(--kendo-spacing--13, -3.25rem);
}

.\!k-m--13 {
  margin: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-m--14 {
  margin: var(--kendo-spacing--14, -3.5rem);
}

.\!k-m--14 {
  margin: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-m--15 {
  margin: var(--kendo-spacing--15, -3.75rem);
}

.\!k-m--15 {
  margin: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-m--16 {
  margin: var(--kendo-spacing--16, -4rem);
}

.\!k-m--16 {
  margin: var(--kendo-spacing--16, -4rem) !important;
}

.k-m--17 {
  margin: var(--kendo-spacing--17, -4.25rem);
}

.\!k-m--17 {
  margin: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-m--18 {
  margin: var(--kendo-spacing--18, -4.5rem);
}

.\!k-m--18 {
  margin: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-m--19 {
  margin: var(--kendo-spacing--19, -4.75rem);
}

.\!k-m--19 {
  margin: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-m--20 {
  margin: var(--kendo-spacing--20, -5rem);
}

.\!k-m--20 {
  margin: var(--kendo-spacing--20, -5rem) !important;
}

.k-m--21 {
  margin: var(--kendo-spacing--21, -5.25rem);
}

.\!k-m--21 {
  margin: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-m--22 {
  margin: var(--kendo-spacing--22, -5.5rem);
}

.\!k-m--22 {
  margin: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-m--23 {
  margin: var(--kendo-spacing--23, -5.75rem);
}

.\!k-m--23 {
  margin: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-m--24 {
  margin: var(--kendo-spacing--24, -6rem);
}

.\!k-m--24 {
  margin: var(--kendo-spacing--24, -6rem) !important;
}

.k-m--25 {
  margin: var(--kendo-spacing--25, -7rem);
}

.\!k-m--25 {
  margin: var(--kendo-spacing--25, -7rem) !important;
}

.k-m--26 {
  margin: var(--kendo-spacing--26, -8rem);
}

.\!k-m--26 {
  margin: var(--kendo-spacing--26, -8rem) !important;
}

.k-m--27 {
  margin: var(--kendo-spacing--27, -9rem);
}

.\!k-m--27 {
  margin: var(--kendo-spacing--27, -9rem) !important;
}

.k-m--28 {
  margin: var(--kendo-spacing--28, -10rem);
}

.\!k-m--28 {
  margin: var(--kendo-spacing--28, -10rem) !important;
}

.k-m--29 {
  margin: var(--kendo-spacing--29, -11rem);
}

.\!k-m--29 {
  margin: var(--kendo-spacing--29, -11rem) !important;
}

.k-m--30 {
  margin: var(--kendo-spacing--30, -12rem);
}

.\!k-m--30 {
  margin: var(--kendo-spacing--30, -12rem) !important;
}

.k-m-xs {
  margin: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-m-xs {
  margin: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-m-sm {
  margin: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-m-sm {
  margin: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-m-md {
  margin: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-m-md {
  margin: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-m-lg {
  margin: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-m-lg {
  margin: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-m-xl {
  margin: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-m-xl {
  margin: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-m-thin {
  margin: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-m-thin {
  margin: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-m-hair {
  margin: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-m-hair {
  margin: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-m-auto {
  margin: var(--kendo-spacing-auto, auto);
}

.\!k-m-auto {
  margin: var(--kendo-spacing-auto, auto) !important;
}

.k-mt-0 {
  margin-top: var(--kendo-spacing-0, 0px);
}

.\!k-mt-0 {
  margin-top: var(--kendo-spacing-0, 0px) !important;
}

.k-mt-1px {
  margin-top: var(--kendo-spacing-1px, 1px);
}

.\!k-mt-1px {
  margin-top: var(--kendo-spacing-1px, 1px) !important;
}

.k-mt-0\.5 {
  margin-top: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mt-0\.5 {
  margin-top: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mt-1 {
  margin-top: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mt-1 {
  margin-top: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mt-1\.5 {
  margin-top: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mt-1\.5 {
  margin-top: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mt-2 {
  margin-top: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mt-2 {
  margin-top: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mt-2\.5 {
  margin-top: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mt-2\.5 {
  margin-top: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mt-3 {
  margin-top: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mt-3 {
  margin-top: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mt-3\.5 {
  margin-top: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mt-3\.5 {
  margin-top: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mt-4 {
  margin-top: var(--kendo-spacing-4, 1rem);
}

.\!k-mt-4 {
  margin-top: var(--kendo-spacing-4, 1rem) !important;
}

.k-mt-4\.5 {
  margin-top: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mt-4\.5 {
  margin-top: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mt-5 {
  margin-top: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mt-5 {
  margin-top: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mt-5\.5 {
  margin-top: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mt-5\.5 {
  margin-top: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mt-6 {
  margin-top: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mt-6 {
  margin-top: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mt-6\.5 {
  margin-top: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mt-6\.5 {
  margin-top: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mt-7 {
  margin-top: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mt-7 {
  margin-top: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mt-7\.5 {
  margin-top: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mt-7\.5 {
  margin-top: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mt-8 {
  margin-top: var(--kendo-spacing-8, 2rem);
}

.\!k-mt-8 {
  margin-top: var(--kendo-spacing-8, 2rem) !important;
}

.k-mt-9 {
  margin-top: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mt-9 {
  margin-top: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mt-10 {
  margin-top: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mt-10 {
  margin-top: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mt-11 {
  margin-top: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mt-11 {
  margin-top: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mt-12 {
  margin-top: var(--kendo-spacing-12, 3rem);
}

.\!k-mt-12 {
  margin-top: var(--kendo-spacing-12, 3rem) !important;
}

.k-mt-13 {
  margin-top: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mt-13 {
  margin-top: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mt-14 {
  margin-top: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mt-14 {
  margin-top: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mt-15 {
  margin-top: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mt-15 {
  margin-top: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mt-16 {
  margin-top: var(--kendo-spacing-16, 4rem);
}

.\!k-mt-16 {
  margin-top: var(--kendo-spacing-16, 4rem) !important;
}

.k-mt-17 {
  margin-top: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mt-17 {
  margin-top: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mt-18 {
  margin-top: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mt-18 {
  margin-top: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mt-19 {
  margin-top: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mt-19 {
  margin-top: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mt-20 {
  margin-top: var(--kendo-spacing-20, 5rem);
}

.\!k-mt-20 {
  margin-top: var(--kendo-spacing-20, 5rem) !important;
}

.k-mt-21 {
  margin-top: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mt-21 {
  margin-top: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mt-22 {
  margin-top: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mt-22 {
  margin-top: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mt-23 {
  margin-top: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mt-23 {
  margin-top: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mt-24 {
  margin-top: var(--kendo-spacing-24, 6rem);
}

.\!k-mt-24 {
  margin-top: var(--kendo-spacing-24, 6rem) !important;
}

.k-mt-25 {
  margin-top: var(--kendo-spacing-25, 7rem);
}

.\!k-mt-25 {
  margin-top: var(--kendo-spacing-25, 7rem) !important;
}

.k-mt-26 {
  margin-top: var(--kendo-spacing-26, 8rem);
}

.\!k-mt-26 {
  margin-top: var(--kendo-spacing-26, 8rem) !important;
}

.k-mt-27 {
  margin-top: var(--kendo-spacing-27, 9rem);
}

.\!k-mt-27 {
  margin-top: var(--kendo-spacing-27, 9rem) !important;
}

.k-mt-28 {
  margin-top: var(--kendo-spacing-28, 10rem);
}

.\!k-mt-28 {
  margin-top: var(--kendo-spacing-28, 10rem) !important;
}

.k-mt-29 {
  margin-top: var(--kendo-spacing-29, 11rem);
}

.\!k-mt-29 {
  margin-top: var(--kendo-spacing-29, 11rem) !important;
}

.k-mt-30 {
  margin-top: var(--kendo-spacing-30, 12rem);
}

.\!k-mt-30 {
  margin-top: var(--kendo-spacing-30, 12rem) !important;
}

.k-mt--0 {
  margin-top: var(--kendo-spacing--0, 0px);
}

.\!k-mt--0 {
  margin-top: var(--kendo-spacing--0, 0px) !important;
}

.k-mt--1px {
  margin-top: var(--kendo-spacing--1px, -1px);
}

.\!k-mt--1px {
  margin-top: var(--kendo-spacing--1px, -1px) !important;
}

.k-mt--0\.5 {
  margin-top: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mt--0\.5 {
  margin-top: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mt--1 {
  margin-top: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mt--1 {
  margin-top: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mt--1\.5 {
  margin-top: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mt--1\.5 {
  margin-top: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mt--2 {
  margin-top: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mt--2 {
  margin-top: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mt--2\.5 {
  margin-top: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mt--2\.5 {
  margin-top: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mt--3 {
  margin-top: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mt--3 {
  margin-top: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mt--3\.5 {
  margin-top: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mt--3\.5 {
  margin-top: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mt--4 {
  margin-top: var(--kendo-spacing--4, -1rem);
}

.\!k-mt--4 {
  margin-top: var(--kendo-spacing--4, -1rem) !important;
}

.k-mt--4\.5 {
  margin-top: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mt--4\.5 {
  margin-top: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mt--5 {
  margin-top: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mt--5 {
  margin-top: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mt--5\.5 {
  margin-top: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mt--5\.5 {
  margin-top: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mt--6 {
  margin-top: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mt--6 {
  margin-top: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mt--6\.5 {
  margin-top: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mt--6\.5 {
  margin-top: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mt--7 {
  margin-top: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mt--7 {
  margin-top: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mt--7\.5 {
  margin-top: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mt--7\.5 {
  margin-top: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mt--8 {
  margin-top: var(--kendo-spacing--8, -2rem);
}

.\!k-mt--8 {
  margin-top: var(--kendo-spacing--8, -2rem) !important;
}

.k-mt--9 {
  margin-top: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mt--9 {
  margin-top: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mt--10 {
  margin-top: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mt--10 {
  margin-top: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mt--11 {
  margin-top: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mt--11 {
  margin-top: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mt--12 {
  margin-top: var(--kendo-spacing--12, -3rem);
}

.\!k-mt--12 {
  margin-top: var(--kendo-spacing--12, -3rem) !important;
}

.k-mt--13 {
  margin-top: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mt--13 {
  margin-top: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mt--14 {
  margin-top: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mt--14 {
  margin-top: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mt--15 {
  margin-top: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mt--15 {
  margin-top: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mt--16 {
  margin-top: var(--kendo-spacing--16, -4rem);
}

.\!k-mt--16 {
  margin-top: var(--kendo-spacing--16, -4rem) !important;
}

.k-mt--17 {
  margin-top: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mt--17 {
  margin-top: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mt--18 {
  margin-top: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mt--18 {
  margin-top: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mt--19 {
  margin-top: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mt--19 {
  margin-top: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mt--20 {
  margin-top: var(--kendo-spacing--20, -5rem);
}

.\!k-mt--20 {
  margin-top: var(--kendo-spacing--20, -5rem) !important;
}

.k-mt--21 {
  margin-top: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mt--21 {
  margin-top: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mt--22 {
  margin-top: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mt--22 {
  margin-top: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mt--23 {
  margin-top: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mt--23 {
  margin-top: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mt--24 {
  margin-top: var(--kendo-spacing--24, -6rem);
}

.\!k-mt--24 {
  margin-top: var(--kendo-spacing--24, -6rem) !important;
}

.k-mt--25 {
  margin-top: var(--kendo-spacing--25, -7rem);
}

.\!k-mt--25 {
  margin-top: var(--kendo-spacing--25, -7rem) !important;
}

.k-mt--26 {
  margin-top: var(--kendo-spacing--26, -8rem);
}

.\!k-mt--26 {
  margin-top: var(--kendo-spacing--26, -8rem) !important;
}

.k-mt--27 {
  margin-top: var(--kendo-spacing--27, -9rem);
}

.\!k-mt--27 {
  margin-top: var(--kendo-spacing--27, -9rem) !important;
}

.k-mt--28 {
  margin-top: var(--kendo-spacing--28, -10rem);
}

.\!k-mt--28 {
  margin-top: var(--kendo-spacing--28, -10rem) !important;
}

.k-mt--29 {
  margin-top: var(--kendo-spacing--29, -11rem);
}

.\!k-mt--29 {
  margin-top: var(--kendo-spacing--29, -11rem) !important;
}

.k-mt--30 {
  margin-top: var(--kendo-spacing--30, -12rem);
}

.\!k-mt--30 {
  margin-top: var(--kendo-spacing--30, -12rem) !important;
}

.k-mt-xs {
  margin-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mt-xs {
  margin-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mt-sm {
  margin-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mt-sm {
  margin-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mt-md {
  margin-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mt-md {
  margin-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mt-lg {
  margin-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mt-lg {
  margin-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mt-xl {
  margin-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mt-xl {
  margin-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mt-thin {
  margin-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mt-thin {
  margin-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mt-hair {
  margin-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mt-hair {
  margin-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mt-auto {
  margin-top: var(--kendo-spacing-auto, auto);
}

.\!k-mt-auto {
  margin-top: var(--kendo-spacing-auto, auto) !important;
}

.k-mr-0 {
  margin-right: var(--kendo-spacing-0, 0px);
}

.\!k-mr-0 {
  margin-right: var(--kendo-spacing-0, 0px) !important;
}

.k-mr-1px {
  margin-right: var(--kendo-spacing-1px, 1px);
}

.\!k-mr-1px {
  margin-right: var(--kendo-spacing-1px, 1px) !important;
}

.k-mr-0\.5 {
  margin-right: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mr-0\.5 {
  margin-right: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mr-1 {
  margin-right: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mr-1 {
  margin-right: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mr-1\.5 {
  margin-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mr-1\.5 {
  margin-right: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mr-2 {
  margin-right: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mr-2 {
  margin-right: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mr-2\.5 {
  margin-right: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mr-2\.5 {
  margin-right: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mr-3 {
  margin-right: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mr-3 {
  margin-right: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mr-3\.5 {
  margin-right: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mr-3\.5 {
  margin-right: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mr-4 {
  margin-right: var(--kendo-spacing-4, 1rem);
}

.\!k-mr-4 {
  margin-right: var(--kendo-spacing-4, 1rem) !important;
}

.k-mr-4\.5 {
  margin-right: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mr-4\.5 {
  margin-right: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mr-5 {
  margin-right: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mr-5 {
  margin-right: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mr-5\.5 {
  margin-right: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mr-5\.5 {
  margin-right: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mr-6 {
  margin-right: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mr-6 {
  margin-right: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mr-6\.5 {
  margin-right: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mr-6\.5 {
  margin-right: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mr-7 {
  margin-right: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mr-7 {
  margin-right: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mr-7\.5 {
  margin-right: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mr-7\.5 {
  margin-right: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mr-8 {
  margin-right: var(--kendo-spacing-8, 2rem);
}

.\!k-mr-8 {
  margin-right: var(--kendo-spacing-8, 2rem) !important;
}

.k-mr-9 {
  margin-right: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mr-9 {
  margin-right: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mr-10 {
  margin-right: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mr-10 {
  margin-right: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mr-11 {
  margin-right: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mr-11 {
  margin-right: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mr-12 {
  margin-right: var(--kendo-spacing-12, 3rem);
}

.\!k-mr-12 {
  margin-right: var(--kendo-spacing-12, 3rem) !important;
}

.k-mr-13 {
  margin-right: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mr-13 {
  margin-right: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mr-14 {
  margin-right: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mr-14 {
  margin-right: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mr-15 {
  margin-right: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mr-15 {
  margin-right: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mr-16 {
  margin-right: var(--kendo-spacing-16, 4rem);
}

.\!k-mr-16 {
  margin-right: var(--kendo-spacing-16, 4rem) !important;
}

.k-mr-17 {
  margin-right: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mr-17 {
  margin-right: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mr-18 {
  margin-right: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mr-18 {
  margin-right: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mr-19 {
  margin-right: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mr-19 {
  margin-right: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mr-20 {
  margin-right: var(--kendo-spacing-20, 5rem);
}

.\!k-mr-20 {
  margin-right: var(--kendo-spacing-20, 5rem) !important;
}

.k-mr-21 {
  margin-right: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mr-21 {
  margin-right: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mr-22 {
  margin-right: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mr-22 {
  margin-right: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mr-23 {
  margin-right: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mr-23 {
  margin-right: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mr-24 {
  margin-right: var(--kendo-spacing-24, 6rem);
}

.\!k-mr-24 {
  margin-right: var(--kendo-spacing-24, 6rem) !important;
}

.k-mr-25 {
  margin-right: var(--kendo-spacing-25, 7rem);
}

.\!k-mr-25 {
  margin-right: var(--kendo-spacing-25, 7rem) !important;
}

.k-mr-26 {
  margin-right: var(--kendo-spacing-26, 8rem);
}

.\!k-mr-26 {
  margin-right: var(--kendo-spacing-26, 8rem) !important;
}

.k-mr-27 {
  margin-right: var(--kendo-spacing-27, 9rem);
}

.\!k-mr-27 {
  margin-right: var(--kendo-spacing-27, 9rem) !important;
}

.k-mr-28 {
  margin-right: var(--kendo-spacing-28, 10rem);
}

.\!k-mr-28 {
  margin-right: var(--kendo-spacing-28, 10rem) !important;
}

.k-mr-29 {
  margin-right: var(--kendo-spacing-29, 11rem);
}

.\!k-mr-29 {
  margin-right: var(--kendo-spacing-29, 11rem) !important;
}

.k-mr-30 {
  margin-right: var(--kendo-spacing-30, 12rem);
}

.\!k-mr-30 {
  margin-right: var(--kendo-spacing-30, 12rem) !important;
}

.k-mr--0 {
  margin-right: var(--kendo-spacing--0, 0px);
}

.\!k-mr--0 {
  margin-right: var(--kendo-spacing--0, 0px) !important;
}

.k-mr--1px {
  margin-right: var(--kendo-spacing--1px, -1px);
}

.\!k-mr--1px {
  margin-right: var(--kendo-spacing--1px, -1px) !important;
}

.k-mr--0\.5 {
  margin-right: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mr--0\.5 {
  margin-right: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mr--1 {
  margin-right: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mr--1 {
  margin-right: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mr--1\.5 {
  margin-right: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mr--1\.5 {
  margin-right: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mr--2 {
  margin-right: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mr--2 {
  margin-right: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mr--2\.5 {
  margin-right: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mr--2\.5 {
  margin-right: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mr--3 {
  margin-right: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mr--3 {
  margin-right: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mr--3\.5 {
  margin-right: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mr--3\.5 {
  margin-right: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mr--4 {
  margin-right: var(--kendo-spacing--4, -1rem);
}

.\!k-mr--4 {
  margin-right: var(--kendo-spacing--4, -1rem) !important;
}

.k-mr--4\.5 {
  margin-right: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mr--4\.5 {
  margin-right: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mr--5 {
  margin-right: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mr--5 {
  margin-right: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mr--5\.5 {
  margin-right: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mr--5\.5 {
  margin-right: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mr--6 {
  margin-right: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mr--6 {
  margin-right: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mr--6\.5 {
  margin-right: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mr--6\.5 {
  margin-right: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mr--7 {
  margin-right: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mr--7 {
  margin-right: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mr--7\.5 {
  margin-right: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mr--7\.5 {
  margin-right: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mr--8 {
  margin-right: var(--kendo-spacing--8, -2rem);
}

.\!k-mr--8 {
  margin-right: var(--kendo-spacing--8, -2rem) !important;
}

.k-mr--9 {
  margin-right: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mr--9 {
  margin-right: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mr--10 {
  margin-right: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mr--10 {
  margin-right: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mr--11 {
  margin-right: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mr--11 {
  margin-right: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mr--12 {
  margin-right: var(--kendo-spacing--12, -3rem);
}

.\!k-mr--12 {
  margin-right: var(--kendo-spacing--12, -3rem) !important;
}

.k-mr--13 {
  margin-right: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mr--13 {
  margin-right: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mr--14 {
  margin-right: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mr--14 {
  margin-right: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mr--15 {
  margin-right: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mr--15 {
  margin-right: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mr--16 {
  margin-right: var(--kendo-spacing--16, -4rem);
}

.\!k-mr--16 {
  margin-right: var(--kendo-spacing--16, -4rem) !important;
}

.k-mr--17 {
  margin-right: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mr--17 {
  margin-right: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mr--18 {
  margin-right: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mr--18 {
  margin-right: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mr--19 {
  margin-right: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mr--19 {
  margin-right: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mr--20 {
  margin-right: var(--kendo-spacing--20, -5rem);
}

.\!k-mr--20 {
  margin-right: var(--kendo-spacing--20, -5rem) !important;
}

.k-mr--21 {
  margin-right: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mr--21 {
  margin-right: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mr--22 {
  margin-right: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mr--22 {
  margin-right: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mr--23 {
  margin-right: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mr--23 {
  margin-right: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mr--24 {
  margin-right: var(--kendo-spacing--24, -6rem);
}

.\!k-mr--24 {
  margin-right: var(--kendo-spacing--24, -6rem) !important;
}

.k-mr--25 {
  margin-right: var(--kendo-spacing--25, -7rem);
}

.\!k-mr--25 {
  margin-right: var(--kendo-spacing--25, -7rem) !important;
}

.k-mr--26 {
  margin-right: var(--kendo-spacing--26, -8rem);
}

.\!k-mr--26 {
  margin-right: var(--kendo-spacing--26, -8rem) !important;
}

.k-mr--27 {
  margin-right: var(--kendo-spacing--27, -9rem);
}

.\!k-mr--27 {
  margin-right: var(--kendo-spacing--27, -9rem) !important;
}

.k-mr--28 {
  margin-right: var(--kendo-spacing--28, -10rem);
}

.\!k-mr--28 {
  margin-right: var(--kendo-spacing--28, -10rem) !important;
}

.k-mr--29 {
  margin-right: var(--kendo-spacing--29, -11rem);
}

.\!k-mr--29 {
  margin-right: var(--kendo-spacing--29, -11rem) !important;
}

.k-mr--30 {
  margin-right: var(--kendo-spacing--30, -12rem);
}

.\!k-mr--30 {
  margin-right: var(--kendo-spacing--30, -12rem) !important;
}

.k-mr-xs {
  margin-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mr-xs {
  margin-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mr-sm {
  margin-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mr-sm {
  margin-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mr-md {
  margin-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mr-md {
  margin-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mr-lg {
  margin-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mr-lg {
  margin-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mr-xl {
  margin-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mr-xl {
  margin-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mr-thin {
  margin-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mr-thin {
  margin-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mr-hair {
  margin-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mr-hair {
  margin-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mr-auto {
  margin-right: var(--kendo-spacing-auto, auto);
}

.\!k-mr-auto {
  margin-right: var(--kendo-spacing-auto, auto) !important;
}

.k-mb-0 {
  margin-bottom: var(--kendo-spacing-0, 0px);
}

.\!k-mb-0 {
  margin-bottom: var(--kendo-spacing-0, 0px) !important;
}

.k-mb-1px {
  margin-bottom: var(--kendo-spacing-1px, 1px);
}

.\!k-mb-1px {
  margin-bottom: var(--kendo-spacing-1px, 1px) !important;
}

.k-mb-0\.5 {
  margin-bottom: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mb-0\.5 {
  margin-bottom: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mb-1 {
  margin-bottom: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mb-1 {
  margin-bottom: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mb-1\.5 {
  margin-bottom: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mb-1\.5 {
  margin-bottom: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mb-2 {
  margin-bottom: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mb-2 {
  margin-bottom: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mb-2\.5 {
  margin-bottom: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mb-2\.5 {
  margin-bottom: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mb-3 {
  margin-bottom: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mb-3 {
  margin-bottom: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mb-3\.5 {
  margin-bottom: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mb-3\.5 {
  margin-bottom: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mb-4 {
  margin-bottom: var(--kendo-spacing-4, 1rem);
}

.\!k-mb-4 {
  margin-bottom: var(--kendo-spacing-4, 1rem) !important;
}

.k-mb-4\.5 {
  margin-bottom: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mb-4\.5 {
  margin-bottom: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mb-5 {
  margin-bottom: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mb-5 {
  margin-bottom: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mb-5\.5 {
  margin-bottom: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mb-5\.5 {
  margin-bottom: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mb-6 {
  margin-bottom: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mb-6 {
  margin-bottom: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mb-6\.5 {
  margin-bottom: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mb-6\.5 {
  margin-bottom: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mb-7 {
  margin-bottom: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mb-7 {
  margin-bottom: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mb-7\.5 {
  margin-bottom: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mb-7\.5 {
  margin-bottom: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mb-8 {
  margin-bottom: var(--kendo-spacing-8, 2rem);
}

.\!k-mb-8 {
  margin-bottom: var(--kendo-spacing-8, 2rem) !important;
}

.k-mb-9 {
  margin-bottom: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mb-9 {
  margin-bottom: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mb-10 {
  margin-bottom: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mb-10 {
  margin-bottom: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mb-11 {
  margin-bottom: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mb-11 {
  margin-bottom: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mb-12 {
  margin-bottom: var(--kendo-spacing-12, 3rem);
}

.\!k-mb-12 {
  margin-bottom: var(--kendo-spacing-12, 3rem) !important;
}

.k-mb-13 {
  margin-bottom: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mb-13 {
  margin-bottom: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mb-14 {
  margin-bottom: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mb-14 {
  margin-bottom: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mb-15 {
  margin-bottom: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mb-15 {
  margin-bottom: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mb-16 {
  margin-bottom: var(--kendo-spacing-16, 4rem);
}

.\!k-mb-16 {
  margin-bottom: var(--kendo-spacing-16, 4rem) !important;
}

.k-mb-17 {
  margin-bottom: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mb-17 {
  margin-bottom: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mb-18 {
  margin-bottom: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mb-18 {
  margin-bottom: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mb-19 {
  margin-bottom: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mb-19 {
  margin-bottom: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mb-20 {
  margin-bottom: var(--kendo-spacing-20, 5rem);
}

.\!k-mb-20 {
  margin-bottom: var(--kendo-spacing-20, 5rem) !important;
}

.k-mb-21 {
  margin-bottom: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mb-21 {
  margin-bottom: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mb-22 {
  margin-bottom: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mb-22 {
  margin-bottom: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mb-23 {
  margin-bottom: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mb-23 {
  margin-bottom: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mb-24 {
  margin-bottom: var(--kendo-spacing-24, 6rem);
}

.\!k-mb-24 {
  margin-bottom: var(--kendo-spacing-24, 6rem) !important;
}

.k-mb-25 {
  margin-bottom: var(--kendo-spacing-25, 7rem);
}

.\!k-mb-25 {
  margin-bottom: var(--kendo-spacing-25, 7rem) !important;
}

.k-mb-26 {
  margin-bottom: var(--kendo-spacing-26, 8rem);
}

.\!k-mb-26 {
  margin-bottom: var(--kendo-spacing-26, 8rem) !important;
}

.k-mb-27 {
  margin-bottom: var(--kendo-spacing-27, 9rem);
}

.\!k-mb-27 {
  margin-bottom: var(--kendo-spacing-27, 9rem) !important;
}

.k-mb-28 {
  margin-bottom: var(--kendo-spacing-28, 10rem);
}

.\!k-mb-28 {
  margin-bottom: var(--kendo-spacing-28, 10rem) !important;
}

.k-mb-29 {
  margin-bottom: var(--kendo-spacing-29, 11rem);
}

.\!k-mb-29 {
  margin-bottom: var(--kendo-spacing-29, 11rem) !important;
}

.k-mb-30 {
  margin-bottom: var(--kendo-spacing-30, 12rem);
}

.\!k-mb-30 {
  margin-bottom: var(--kendo-spacing-30, 12rem) !important;
}

.k-mb--0 {
  margin-bottom: var(--kendo-spacing--0, 0px);
}

.\!k-mb--0 {
  margin-bottom: var(--kendo-spacing--0, 0px) !important;
}

.k-mb--1px {
  margin-bottom: var(--kendo-spacing--1px, -1px);
}

.\!k-mb--1px {
  margin-bottom: var(--kendo-spacing--1px, -1px) !important;
}

.k-mb--0\.5 {
  margin-bottom: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mb--0\.5 {
  margin-bottom: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mb--1 {
  margin-bottom: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mb--1 {
  margin-bottom: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mb--1\.5 {
  margin-bottom: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mb--1\.5 {
  margin-bottom: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mb--2 {
  margin-bottom: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mb--2 {
  margin-bottom: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mb--2\.5 {
  margin-bottom: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mb--2\.5 {
  margin-bottom: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mb--3 {
  margin-bottom: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mb--3 {
  margin-bottom: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mb--3\.5 {
  margin-bottom: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mb--3\.5 {
  margin-bottom: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mb--4 {
  margin-bottom: var(--kendo-spacing--4, -1rem);
}

.\!k-mb--4 {
  margin-bottom: var(--kendo-spacing--4, -1rem) !important;
}

.k-mb--4\.5 {
  margin-bottom: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mb--4\.5 {
  margin-bottom: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mb--5 {
  margin-bottom: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mb--5 {
  margin-bottom: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mb--5\.5 {
  margin-bottom: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mb--5\.5 {
  margin-bottom: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mb--6 {
  margin-bottom: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mb--6 {
  margin-bottom: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mb--6\.5 {
  margin-bottom: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mb--6\.5 {
  margin-bottom: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mb--7 {
  margin-bottom: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mb--7 {
  margin-bottom: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mb--7\.5 {
  margin-bottom: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mb--7\.5 {
  margin-bottom: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mb--8 {
  margin-bottom: var(--kendo-spacing--8, -2rem);
}

.\!k-mb--8 {
  margin-bottom: var(--kendo-spacing--8, -2rem) !important;
}

.k-mb--9 {
  margin-bottom: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mb--9 {
  margin-bottom: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mb--10 {
  margin-bottom: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mb--10 {
  margin-bottom: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mb--11 {
  margin-bottom: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mb--11 {
  margin-bottom: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mb--12 {
  margin-bottom: var(--kendo-spacing--12, -3rem);
}

.\!k-mb--12 {
  margin-bottom: var(--kendo-spacing--12, -3rem) !important;
}

.k-mb--13 {
  margin-bottom: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mb--13 {
  margin-bottom: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mb--14 {
  margin-bottom: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mb--14 {
  margin-bottom: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mb--15 {
  margin-bottom: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mb--15 {
  margin-bottom: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mb--16 {
  margin-bottom: var(--kendo-spacing--16, -4rem);
}

.\!k-mb--16 {
  margin-bottom: var(--kendo-spacing--16, -4rem) !important;
}

.k-mb--17 {
  margin-bottom: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mb--17 {
  margin-bottom: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mb--18 {
  margin-bottom: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mb--18 {
  margin-bottom: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mb--19 {
  margin-bottom: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mb--19 {
  margin-bottom: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mb--20 {
  margin-bottom: var(--kendo-spacing--20, -5rem);
}

.\!k-mb--20 {
  margin-bottom: var(--kendo-spacing--20, -5rem) !important;
}

.k-mb--21 {
  margin-bottom: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mb--21 {
  margin-bottom: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mb--22 {
  margin-bottom: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mb--22 {
  margin-bottom: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mb--23 {
  margin-bottom: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mb--23 {
  margin-bottom: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mb--24 {
  margin-bottom: var(--kendo-spacing--24, -6rem);
}

.\!k-mb--24 {
  margin-bottom: var(--kendo-spacing--24, -6rem) !important;
}

.k-mb--25 {
  margin-bottom: var(--kendo-spacing--25, -7rem);
}

.\!k-mb--25 {
  margin-bottom: var(--kendo-spacing--25, -7rem) !important;
}

.k-mb--26 {
  margin-bottom: var(--kendo-spacing--26, -8rem);
}

.\!k-mb--26 {
  margin-bottom: var(--kendo-spacing--26, -8rem) !important;
}

.k-mb--27 {
  margin-bottom: var(--kendo-spacing--27, -9rem);
}

.\!k-mb--27 {
  margin-bottom: var(--kendo-spacing--27, -9rem) !important;
}

.k-mb--28 {
  margin-bottom: var(--kendo-spacing--28, -10rem);
}

.\!k-mb--28 {
  margin-bottom: var(--kendo-spacing--28, -10rem) !important;
}

.k-mb--29 {
  margin-bottom: var(--kendo-spacing--29, -11rem);
}

.\!k-mb--29 {
  margin-bottom: var(--kendo-spacing--29, -11rem) !important;
}

.k-mb--30 {
  margin-bottom: var(--kendo-spacing--30, -12rem);
}

.\!k-mb--30 {
  margin-bottom: var(--kendo-spacing--30, -12rem) !important;
}

.k-mb-xs {
  margin-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mb-xs {
  margin-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mb-sm {
  margin-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mb-sm {
  margin-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mb-md {
  margin-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mb-md {
  margin-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mb-lg {
  margin-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mb-lg {
  margin-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mb-xl {
  margin-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mb-xl {
  margin-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mb-thin {
  margin-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mb-thin {
  margin-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mb-hair {
  margin-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mb-hair {
  margin-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mb-auto {
  margin-bottom: var(--kendo-spacing-auto, auto);
}

.\!k-mb-auto {
  margin-bottom: var(--kendo-spacing-auto, auto) !important;
}

.k-ml-0 {
  margin-left: var(--kendo-spacing-0, 0px);
}

.\!k-ml-0 {
  margin-left: var(--kendo-spacing-0, 0px) !important;
}

.k-ml-1px {
  margin-left: var(--kendo-spacing-1px, 1px);
}

.\!k-ml-1px {
  margin-left: var(--kendo-spacing-1px, 1px) !important;
}

.k-ml-0\.5 {
  margin-left: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-ml-0\.5 {
  margin-left: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-ml-1 {
  margin-left: var(--kendo-spacing-1, 0.25rem);
}

.\!k-ml-1 {
  margin-left: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-ml-1\.5 {
  margin-left: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-ml-1\.5 {
  margin-left: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-ml-2 {
  margin-left: var(--kendo-spacing-2, 0.5rem);
}

.\!k-ml-2 {
  margin-left: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-ml-2\.5 {
  margin-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-ml-2\.5 {
  margin-left: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-ml-3 {
  margin-left: var(--kendo-spacing-3, 0.75rem);
}

.\!k-ml-3 {
  margin-left: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-ml-3\.5 {
  margin-left: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-ml-3\.5 {
  margin-left: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-ml-4 {
  margin-left: var(--kendo-spacing-4, 1rem);
}

.\!k-ml-4 {
  margin-left: var(--kendo-spacing-4, 1rem) !important;
}

.k-ml-4\.5 {
  margin-left: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-ml-4\.5 {
  margin-left: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-ml-5 {
  margin-left: var(--kendo-spacing-5, 1.25rem);
}

.\!k-ml-5 {
  margin-left: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-ml-5\.5 {
  margin-left: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-ml-5\.5 {
  margin-left: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-ml-6 {
  margin-left: var(--kendo-spacing-6, 1.5rem);
}

.\!k-ml-6 {
  margin-left: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-ml-6\.5 {
  margin-left: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-ml-6\.5 {
  margin-left: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-ml-7 {
  margin-left: var(--kendo-spacing-7, 1.75rem);
}

.\!k-ml-7 {
  margin-left: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-ml-7\.5 {
  margin-left: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-ml-7\.5 {
  margin-left: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-ml-8 {
  margin-left: var(--kendo-spacing-8, 2rem);
}

.\!k-ml-8 {
  margin-left: var(--kendo-spacing-8, 2rem) !important;
}

.k-ml-9 {
  margin-left: var(--kendo-spacing-9, 2.25rem);
}

.\!k-ml-9 {
  margin-left: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-ml-10 {
  margin-left: var(--kendo-spacing-10, 2.5rem);
}

.\!k-ml-10 {
  margin-left: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-ml-11 {
  margin-left: var(--kendo-spacing-11, 2.75rem);
}

.\!k-ml-11 {
  margin-left: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-ml-12 {
  margin-left: var(--kendo-spacing-12, 3rem);
}

.\!k-ml-12 {
  margin-left: var(--kendo-spacing-12, 3rem) !important;
}

.k-ml-13 {
  margin-left: var(--kendo-spacing-13, 3.25rem);
}

.\!k-ml-13 {
  margin-left: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-ml-14 {
  margin-left: var(--kendo-spacing-14, 3.5rem);
}

.\!k-ml-14 {
  margin-left: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-ml-15 {
  margin-left: var(--kendo-spacing-15, 3.75rem);
}

.\!k-ml-15 {
  margin-left: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-ml-16 {
  margin-left: var(--kendo-spacing-16, 4rem);
}

.\!k-ml-16 {
  margin-left: var(--kendo-spacing-16, 4rem) !important;
}

.k-ml-17 {
  margin-left: var(--kendo-spacing-17, 4.25rem);
}

.\!k-ml-17 {
  margin-left: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-ml-18 {
  margin-left: var(--kendo-spacing-18, 4.5rem);
}

.\!k-ml-18 {
  margin-left: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-ml-19 {
  margin-left: var(--kendo-spacing-19, 4.75rem);
}

.\!k-ml-19 {
  margin-left: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-ml-20 {
  margin-left: var(--kendo-spacing-20, 5rem);
}

.\!k-ml-20 {
  margin-left: var(--kendo-spacing-20, 5rem) !important;
}

.k-ml-21 {
  margin-left: var(--kendo-spacing-21, 5.25rem);
}

.\!k-ml-21 {
  margin-left: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-ml-22 {
  margin-left: var(--kendo-spacing-22, 5.5rem);
}

.\!k-ml-22 {
  margin-left: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-ml-23 {
  margin-left: var(--kendo-spacing-23, 5.75rem);
}

.\!k-ml-23 {
  margin-left: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-ml-24 {
  margin-left: var(--kendo-spacing-24, 6rem);
}

.\!k-ml-24 {
  margin-left: var(--kendo-spacing-24, 6rem) !important;
}

.k-ml-25 {
  margin-left: var(--kendo-spacing-25, 7rem);
}

.\!k-ml-25 {
  margin-left: var(--kendo-spacing-25, 7rem) !important;
}

.k-ml-26 {
  margin-left: var(--kendo-spacing-26, 8rem);
}

.\!k-ml-26 {
  margin-left: var(--kendo-spacing-26, 8rem) !important;
}

.k-ml-27 {
  margin-left: var(--kendo-spacing-27, 9rem);
}

.\!k-ml-27 {
  margin-left: var(--kendo-spacing-27, 9rem) !important;
}

.k-ml-28 {
  margin-left: var(--kendo-spacing-28, 10rem);
}

.\!k-ml-28 {
  margin-left: var(--kendo-spacing-28, 10rem) !important;
}

.k-ml-29 {
  margin-left: var(--kendo-spacing-29, 11rem);
}

.\!k-ml-29 {
  margin-left: var(--kendo-spacing-29, 11rem) !important;
}

.k-ml-30 {
  margin-left: var(--kendo-spacing-30, 12rem);
}

.\!k-ml-30 {
  margin-left: var(--kendo-spacing-30, 12rem) !important;
}

.k-ml--0 {
  margin-left: var(--kendo-spacing--0, 0px);
}

.\!k-ml--0 {
  margin-left: var(--kendo-spacing--0, 0px) !important;
}

.k-ml--1px {
  margin-left: var(--kendo-spacing--1px, -1px);
}

.\!k-ml--1px {
  margin-left: var(--kendo-spacing--1px, -1px) !important;
}

.k-ml--0\.5 {
  margin-left: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-ml--0\.5 {
  margin-left: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-ml--1 {
  margin-left: var(--kendo-spacing--1, -0.25rem);
}

.\!k-ml--1 {
  margin-left: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-ml--1\.5 {
  margin-left: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-ml--1\.5 {
  margin-left: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-ml--2 {
  margin-left: var(--kendo-spacing--2, -0.5rem);
}

.\!k-ml--2 {
  margin-left: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-ml--2\.5 {
  margin-left: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-ml--2\.5 {
  margin-left: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-ml--3 {
  margin-left: var(--kendo-spacing--3, -0.75rem);
}

.\!k-ml--3 {
  margin-left: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-ml--3\.5 {
  margin-left: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-ml--3\.5 {
  margin-left: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-ml--4 {
  margin-left: var(--kendo-spacing--4, -1rem);
}

.\!k-ml--4 {
  margin-left: var(--kendo-spacing--4, -1rem) !important;
}

.k-ml--4\.5 {
  margin-left: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-ml--4\.5 {
  margin-left: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-ml--5 {
  margin-left: var(--kendo-spacing--5, -1.25rem);
}

.\!k-ml--5 {
  margin-left: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-ml--5\.5 {
  margin-left: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-ml--5\.5 {
  margin-left: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-ml--6 {
  margin-left: var(--kendo-spacing--6, -1.5rem);
}

.\!k-ml--6 {
  margin-left: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-ml--6\.5 {
  margin-left: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-ml--6\.5 {
  margin-left: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-ml--7 {
  margin-left: var(--kendo-spacing--7, -1.75rem);
}

.\!k-ml--7 {
  margin-left: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-ml--7\.5 {
  margin-left: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-ml--7\.5 {
  margin-left: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-ml--8 {
  margin-left: var(--kendo-spacing--8, -2rem);
}

.\!k-ml--8 {
  margin-left: var(--kendo-spacing--8, -2rem) !important;
}

.k-ml--9 {
  margin-left: var(--kendo-spacing--9, -2.25rem);
}

.\!k-ml--9 {
  margin-left: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-ml--10 {
  margin-left: var(--kendo-spacing--10, -2.5rem);
}

.\!k-ml--10 {
  margin-left: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-ml--11 {
  margin-left: var(--kendo-spacing--11, -2.75rem);
}

.\!k-ml--11 {
  margin-left: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-ml--12 {
  margin-left: var(--kendo-spacing--12, -3rem);
}

.\!k-ml--12 {
  margin-left: var(--kendo-spacing--12, -3rem) !important;
}

.k-ml--13 {
  margin-left: var(--kendo-spacing--13, -3.25rem);
}

.\!k-ml--13 {
  margin-left: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-ml--14 {
  margin-left: var(--kendo-spacing--14, -3.5rem);
}

.\!k-ml--14 {
  margin-left: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-ml--15 {
  margin-left: var(--kendo-spacing--15, -3.75rem);
}

.\!k-ml--15 {
  margin-left: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-ml--16 {
  margin-left: var(--kendo-spacing--16, -4rem);
}

.\!k-ml--16 {
  margin-left: var(--kendo-spacing--16, -4rem) !important;
}

.k-ml--17 {
  margin-left: var(--kendo-spacing--17, -4.25rem);
}

.\!k-ml--17 {
  margin-left: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-ml--18 {
  margin-left: var(--kendo-spacing--18, -4.5rem);
}

.\!k-ml--18 {
  margin-left: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-ml--19 {
  margin-left: var(--kendo-spacing--19, -4.75rem);
}

.\!k-ml--19 {
  margin-left: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-ml--20 {
  margin-left: var(--kendo-spacing--20, -5rem);
}

.\!k-ml--20 {
  margin-left: var(--kendo-spacing--20, -5rem) !important;
}

.k-ml--21 {
  margin-left: var(--kendo-spacing--21, -5.25rem);
}

.\!k-ml--21 {
  margin-left: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-ml--22 {
  margin-left: var(--kendo-spacing--22, -5.5rem);
}

.\!k-ml--22 {
  margin-left: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-ml--23 {
  margin-left: var(--kendo-spacing--23, -5.75rem);
}

.\!k-ml--23 {
  margin-left: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-ml--24 {
  margin-left: var(--kendo-spacing--24, -6rem);
}

.\!k-ml--24 {
  margin-left: var(--kendo-spacing--24, -6rem) !important;
}

.k-ml--25 {
  margin-left: var(--kendo-spacing--25, -7rem);
}

.\!k-ml--25 {
  margin-left: var(--kendo-spacing--25, -7rem) !important;
}

.k-ml--26 {
  margin-left: var(--kendo-spacing--26, -8rem);
}

.\!k-ml--26 {
  margin-left: var(--kendo-spacing--26, -8rem) !important;
}

.k-ml--27 {
  margin-left: var(--kendo-spacing--27, -9rem);
}

.\!k-ml--27 {
  margin-left: var(--kendo-spacing--27, -9rem) !important;
}

.k-ml--28 {
  margin-left: var(--kendo-spacing--28, -10rem);
}

.\!k-ml--28 {
  margin-left: var(--kendo-spacing--28, -10rem) !important;
}

.k-ml--29 {
  margin-left: var(--kendo-spacing--29, -11rem);
}

.\!k-ml--29 {
  margin-left: var(--kendo-spacing--29, -11rem) !important;
}

.k-ml--30 {
  margin-left: var(--kendo-spacing--30, -12rem);
}

.\!k-ml--30 {
  margin-left: var(--kendo-spacing--30, -12rem) !important;
}

.k-ml-xs {
  margin-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-ml-xs {
  margin-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-ml-sm {
  margin-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-ml-sm {
  margin-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-ml-md {
  margin-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-ml-md {
  margin-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-ml-lg {
  margin-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-ml-lg {
  margin-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-ml-xl {
  margin-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-ml-xl {
  margin-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-ml-thin {
  margin-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-ml-thin {
  margin-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-ml-hair {
  margin-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-ml-hair {
  margin-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-ml-auto {
  margin-left: var(--kendo-spacing-auto, auto);
}

.\!k-ml-auto {
  margin-left: var(--kendo-spacing-auto, auto) !important;
}

.k-mx-0 {
  margin-inline: var(--kendo-spacing-0, 0px);
}

.\!k-mx-0 {
  margin-inline: var(--kendo-spacing-0, 0px) !important;
}

.k-mx-1px {
  margin-inline: var(--kendo-spacing-1px, 1px);
}

.\!k-mx-1px {
  margin-inline: var(--kendo-spacing-1px, 1px) !important;
}

.k-mx-0\.5 {
  margin-inline: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mx-0\.5 {
  margin-inline: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mx-1 {
  margin-inline: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mx-1 {
  margin-inline: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mx-1\.5 {
  margin-inline: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mx-1\.5 {
  margin-inline: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mx-2 {
  margin-inline: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mx-2 {
  margin-inline: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mx-2\.5 {
  margin-inline: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mx-2\.5 {
  margin-inline: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mx-3 {
  margin-inline: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mx-3 {
  margin-inline: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mx-3\.5 {
  margin-inline: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mx-3\.5 {
  margin-inline: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mx-4 {
  margin-inline: var(--kendo-spacing-4, 1rem);
}

.\!k-mx-4 {
  margin-inline: var(--kendo-spacing-4, 1rem) !important;
}

.k-mx-4\.5 {
  margin-inline: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mx-4\.5 {
  margin-inline: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mx-5 {
  margin-inline: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mx-5 {
  margin-inline: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mx-5\.5 {
  margin-inline: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mx-5\.5 {
  margin-inline: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mx-6 {
  margin-inline: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mx-6 {
  margin-inline: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mx-6\.5 {
  margin-inline: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mx-6\.5 {
  margin-inline: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mx-7 {
  margin-inline: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mx-7 {
  margin-inline: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mx-7\.5 {
  margin-inline: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mx-7\.5 {
  margin-inline: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mx-8 {
  margin-inline: var(--kendo-spacing-8, 2rem);
}

.\!k-mx-8 {
  margin-inline: var(--kendo-spacing-8, 2rem) !important;
}

.k-mx-9 {
  margin-inline: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mx-9 {
  margin-inline: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mx-10 {
  margin-inline: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mx-10 {
  margin-inline: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mx-11 {
  margin-inline: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mx-11 {
  margin-inline: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mx-12 {
  margin-inline: var(--kendo-spacing-12, 3rem);
}

.\!k-mx-12 {
  margin-inline: var(--kendo-spacing-12, 3rem) !important;
}

.k-mx-13 {
  margin-inline: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mx-13 {
  margin-inline: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mx-14 {
  margin-inline: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mx-14 {
  margin-inline: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mx-15 {
  margin-inline: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mx-15 {
  margin-inline: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mx-16 {
  margin-inline: var(--kendo-spacing-16, 4rem);
}

.\!k-mx-16 {
  margin-inline: var(--kendo-spacing-16, 4rem) !important;
}

.k-mx-17 {
  margin-inline: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mx-17 {
  margin-inline: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mx-18 {
  margin-inline: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mx-18 {
  margin-inline: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mx-19 {
  margin-inline: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mx-19 {
  margin-inline: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mx-20 {
  margin-inline: var(--kendo-spacing-20, 5rem);
}

.\!k-mx-20 {
  margin-inline: var(--kendo-spacing-20, 5rem) !important;
}

.k-mx-21 {
  margin-inline: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mx-21 {
  margin-inline: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mx-22 {
  margin-inline: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mx-22 {
  margin-inline: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mx-23 {
  margin-inline: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mx-23 {
  margin-inline: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mx-24 {
  margin-inline: var(--kendo-spacing-24, 6rem);
}

.\!k-mx-24 {
  margin-inline: var(--kendo-spacing-24, 6rem) !important;
}

.k-mx-25 {
  margin-inline: var(--kendo-spacing-25, 7rem);
}

.\!k-mx-25 {
  margin-inline: var(--kendo-spacing-25, 7rem) !important;
}

.k-mx-26 {
  margin-inline: var(--kendo-spacing-26, 8rem);
}

.\!k-mx-26 {
  margin-inline: var(--kendo-spacing-26, 8rem) !important;
}

.k-mx-27 {
  margin-inline: var(--kendo-spacing-27, 9rem);
}

.\!k-mx-27 {
  margin-inline: var(--kendo-spacing-27, 9rem) !important;
}

.k-mx-28 {
  margin-inline: var(--kendo-spacing-28, 10rem);
}

.\!k-mx-28 {
  margin-inline: var(--kendo-spacing-28, 10rem) !important;
}

.k-mx-29 {
  margin-inline: var(--kendo-spacing-29, 11rem);
}

.\!k-mx-29 {
  margin-inline: var(--kendo-spacing-29, 11rem) !important;
}

.k-mx-30 {
  margin-inline: var(--kendo-spacing-30, 12rem);
}

.\!k-mx-30 {
  margin-inline: var(--kendo-spacing-30, 12rem) !important;
}

.k-mx--0 {
  margin-inline: var(--kendo-spacing--0, 0px);
}

.\!k-mx--0 {
  margin-inline: var(--kendo-spacing--0, 0px) !important;
}

.k-mx--1px {
  margin-inline: var(--kendo-spacing--1px, -1px);
}

.\!k-mx--1px {
  margin-inline: var(--kendo-spacing--1px, -1px) !important;
}

.k-mx--0\.5 {
  margin-inline: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mx--0\.5 {
  margin-inline: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mx--1 {
  margin-inline: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mx--1 {
  margin-inline: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mx--1\.5 {
  margin-inline: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mx--1\.5 {
  margin-inline: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mx--2 {
  margin-inline: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mx--2 {
  margin-inline: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mx--2\.5 {
  margin-inline: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mx--2\.5 {
  margin-inline: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mx--3 {
  margin-inline: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mx--3 {
  margin-inline: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mx--3\.5 {
  margin-inline: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mx--3\.5 {
  margin-inline: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mx--4 {
  margin-inline: var(--kendo-spacing--4, -1rem);
}

.\!k-mx--4 {
  margin-inline: var(--kendo-spacing--4, -1rem) !important;
}

.k-mx--4\.5 {
  margin-inline: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mx--4\.5 {
  margin-inline: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mx--5 {
  margin-inline: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mx--5 {
  margin-inline: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mx--5\.5 {
  margin-inline: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mx--5\.5 {
  margin-inline: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mx--6 {
  margin-inline: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mx--6 {
  margin-inline: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mx--6\.5 {
  margin-inline: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mx--6\.5 {
  margin-inline: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mx--7 {
  margin-inline: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mx--7 {
  margin-inline: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mx--7\.5 {
  margin-inline: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mx--7\.5 {
  margin-inline: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mx--8 {
  margin-inline: var(--kendo-spacing--8, -2rem);
}

.\!k-mx--8 {
  margin-inline: var(--kendo-spacing--8, -2rem) !important;
}

.k-mx--9 {
  margin-inline: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mx--9 {
  margin-inline: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mx--10 {
  margin-inline: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mx--10 {
  margin-inline: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mx--11 {
  margin-inline: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mx--11 {
  margin-inline: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mx--12 {
  margin-inline: var(--kendo-spacing--12, -3rem);
}

.\!k-mx--12 {
  margin-inline: var(--kendo-spacing--12, -3rem) !important;
}

.k-mx--13 {
  margin-inline: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mx--13 {
  margin-inline: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mx--14 {
  margin-inline: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mx--14 {
  margin-inline: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mx--15 {
  margin-inline: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mx--15 {
  margin-inline: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mx--16 {
  margin-inline: var(--kendo-spacing--16, -4rem);
}

.\!k-mx--16 {
  margin-inline: var(--kendo-spacing--16, -4rem) !important;
}

.k-mx--17 {
  margin-inline: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mx--17 {
  margin-inline: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mx--18 {
  margin-inline: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mx--18 {
  margin-inline: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mx--19 {
  margin-inline: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mx--19 {
  margin-inline: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mx--20 {
  margin-inline: var(--kendo-spacing--20, -5rem);
}

.\!k-mx--20 {
  margin-inline: var(--kendo-spacing--20, -5rem) !important;
}

.k-mx--21 {
  margin-inline: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mx--21 {
  margin-inline: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mx--22 {
  margin-inline: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mx--22 {
  margin-inline: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mx--23 {
  margin-inline: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mx--23 {
  margin-inline: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mx--24 {
  margin-inline: var(--kendo-spacing--24, -6rem);
}

.\!k-mx--24 {
  margin-inline: var(--kendo-spacing--24, -6rem) !important;
}

.k-mx--25 {
  margin-inline: var(--kendo-spacing--25, -7rem);
}

.\!k-mx--25 {
  margin-inline: var(--kendo-spacing--25, -7rem) !important;
}

.k-mx--26 {
  margin-inline: var(--kendo-spacing--26, -8rem);
}

.\!k-mx--26 {
  margin-inline: var(--kendo-spacing--26, -8rem) !important;
}

.k-mx--27 {
  margin-inline: var(--kendo-spacing--27, -9rem);
}

.\!k-mx--27 {
  margin-inline: var(--kendo-spacing--27, -9rem) !important;
}

.k-mx--28 {
  margin-inline: var(--kendo-spacing--28, -10rem);
}

.\!k-mx--28 {
  margin-inline: var(--kendo-spacing--28, -10rem) !important;
}

.k-mx--29 {
  margin-inline: var(--kendo-spacing--29, -11rem);
}

.\!k-mx--29 {
  margin-inline: var(--kendo-spacing--29, -11rem) !important;
}

.k-mx--30 {
  margin-inline: var(--kendo-spacing--30, -12rem);
}

.\!k-mx--30 {
  margin-inline: var(--kendo-spacing--30, -12rem) !important;
}

.k-mx-xs {
  margin-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mx-xs {
  margin-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mx-sm {
  margin-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mx-sm {
  margin-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mx-md {
  margin-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mx-md {
  margin-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mx-lg {
  margin-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mx-lg {
  margin-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mx-xl {
  margin-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mx-xl {
  margin-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mx-thin {
  margin-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mx-thin {
  margin-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mx-hair {
  margin-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mx-hair {
  margin-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mx-auto {
  margin-inline: var(--kendo-spacing-auto, auto);
}

.\!k-mx-auto {
  margin-inline: var(--kendo-spacing-auto, auto) !important;
}

.k-my-0 {
  margin-block: var(--kendo-spacing-0, 0px);
}

.\!k-my-0 {
  margin-block: var(--kendo-spacing-0, 0px) !important;
}

.k-my-1px {
  margin-block: var(--kendo-spacing-1px, 1px);
}

.\!k-my-1px {
  margin-block: var(--kendo-spacing-1px, 1px) !important;
}

.k-my-0\.5 {
  margin-block: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-my-0\.5 {
  margin-block: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-my-1 {
  margin-block: var(--kendo-spacing-1, 0.25rem);
}

.\!k-my-1 {
  margin-block: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-my-1\.5 {
  margin-block: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-my-1\.5 {
  margin-block: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-my-2 {
  margin-block: var(--kendo-spacing-2, 0.5rem);
}

.\!k-my-2 {
  margin-block: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-my-2\.5 {
  margin-block: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-my-2\.5 {
  margin-block: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-my-3 {
  margin-block: var(--kendo-spacing-3, 0.75rem);
}

.\!k-my-3 {
  margin-block: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-my-3\.5 {
  margin-block: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-my-3\.5 {
  margin-block: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-my-4 {
  margin-block: var(--kendo-spacing-4, 1rem);
}

.\!k-my-4 {
  margin-block: var(--kendo-spacing-4, 1rem) !important;
}

.k-my-4\.5 {
  margin-block: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-my-4\.5 {
  margin-block: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-my-5 {
  margin-block: var(--kendo-spacing-5, 1.25rem);
}

.\!k-my-5 {
  margin-block: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-my-5\.5 {
  margin-block: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-my-5\.5 {
  margin-block: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-my-6 {
  margin-block: var(--kendo-spacing-6, 1.5rem);
}

.\!k-my-6 {
  margin-block: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-my-6\.5 {
  margin-block: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-my-6\.5 {
  margin-block: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-my-7 {
  margin-block: var(--kendo-spacing-7, 1.75rem);
}

.\!k-my-7 {
  margin-block: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-my-7\.5 {
  margin-block: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-my-7\.5 {
  margin-block: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-my-8 {
  margin-block: var(--kendo-spacing-8, 2rem);
}

.\!k-my-8 {
  margin-block: var(--kendo-spacing-8, 2rem) !important;
}

.k-my-9 {
  margin-block: var(--kendo-spacing-9, 2.25rem);
}

.\!k-my-9 {
  margin-block: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-my-10 {
  margin-block: var(--kendo-spacing-10, 2.5rem);
}

.\!k-my-10 {
  margin-block: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-my-11 {
  margin-block: var(--kendo-spacing-11, 2.75rem);
}

.\!k-my-11 {
  margin-block: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-my-12 {
  margin-block: var(--kendo-spacing-12, 3rem);
}

.\!k-my-12 {
  margin-block: var(--kendo-spacing-12, 3rem) !important;
}

.k-my-13 {
  margin-block: var(--kendo-spacing-13, 3.25rem);
}

.\!k-my-13 {
  margin-block: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-my-14 {
  margin-block: var(--kendo-spacing-14, 3.5rem);
}

.\!k-my-14 {
  margin-block: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-my-15 {
  margin-block: var(--kendo-spacing-15, 3.75rem);
}

.\!k-my-15 {
  margin-block: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-my-16 {
  margin-block: var(--kendo-spacing-16, 4rem);
}

.\!k-my-16 {
  margin-block: var(--kendo-spacing-16, 4rem) !important;
}

.k-my-17 {
  margin-block: var(--kendo-spacing-17, 4.25rem);
}

.\!k-my-17 {
  margin-block: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-my-18 {
  margin-block: var(--kendo-spacing-18, 4.5rem);
}

.\!k-my-18 {
  margin-block: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-my-19 {
  margin-block: var(--kendo-spacing-19, 4.75rem);
}

.\!k-my-19 {
  margin-block: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-my-20 {
  margin-block: var(--kendo-spacing-20, 5rem);
}

.\!k-my-20 {
  margin-block: var(--kendo-spacing-20, 5rem) !important;
}

.k-my-21 {
  margin-block: var(--kendo-spacing-21, 5.25rem);
}

.\!k-my-21 {
  margin-block: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-my-22 {
  margin-block: var(--kendo-spacing-22, 5.5rem);
}

.\!k-my-22 {
  margin-block: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-my-23 {
  margin-block: var(--kendo-spacing-23, 5.75rem);
}

.\!k-my-23 {
  margin-block: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-my-24 {
  margin-block: var(--kendo-spacing-24, 6rem);
}

.\!k-my-24 {
  margin-block: var(--kendo-spacing-24, 6rem) !important;
}

.k-my-25 {
  margin-block: var(--kendo-spacing-25, 7rem);
}

.\!k-my-25 {
  margin-block: var(--kendo-spacing-25, 7rem) !important;
}

.k-my-26 {
  margin-block: var(--kendo-spacing-26, 8rem);
}

.\!k-my-26 {
  margin-block: var(--kendo-spacing-26, 8rem) !important;
}

.k-my-27 {
  margin-block: var(--kendo-spacing-27, 9rem);
}

.\!k-my-27 {
  margin-block: var(--kendo-spacing-27, 9rem) !important;
}

.k-my-28 {
  margin-block: var(--kendo-spacing-28, 10rem);
}

.\!k-my-28 {
  margin-block: var(--kendo-spacing-28, 10rem) !important;
}

.k-my-29 {
  margin-block: var(--kendo-spacing-29, 11rem);
}

.\!k-my-29 {
  margin-block: var(--kendo-spacing-29, 11rem) !important;
}

.k-my-30 {
  margin-block: var(--kendo-spacing-30, 12rem);
}

.\!k-my-30 {
  margin-block: var(--kendo-spacing-30, 12rem) !important;
}

.k-my--0 {
  margin-block: var(--kendo-spacing--0, 0px);
}

.\!k-my--0 {
  margin-block: var(--kendo-spacing--0, 0px) !important;
}

.k-my--1px {
  margin-block: var(--kendo-spacing--1px, -1px);
}

.\!k-my--1px {
  margin-block: var(--kendo-spacing--1px, -1px) !important;
}

.k-my--0\.5 {
  margin-block: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-my--0\.5 {
  margin-block: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-my--1 {
  margin-block: var(--kendo-spacing--1, -0.25rem);
}

.\!k-my--1 {
  margin-block: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-my--1\.5 {
  margin-block: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-my--1\.5 {
  margin-block: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-my--2 {
  margin-block: var(--kendo-spacing--2, -0.5rem);
}

.\!k-my--2 {
  margin-block: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-my--2\.5 {
  margin-block: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-my--2\.5 {
  margin-block: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-my--3 {
  margin-block: var(--kendo-spacing--3, -0.75rem);
}

.\!k-my--3 {
  margin-block: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-my--3\.5 {
  margin-block: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-my--3\.5 {
  margin-block: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-my--4 {
  margin-block: var(--kendo-spacing--4, -1rem);
}

.\!k-my--4 {
  margin-block: var(--kendo-spacing--4, -1rem) !important;
}

.k-my--4\.5 {
  margin-block: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-my--4\.5 {
  margin-block: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-my--5 {
  margin-block: var(--kendo-spacing--5, -1.25rem);
}

.\!k-my--5 {
  margin-block: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-my--5\.5 {
  margin-block: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-my--5\.5 {
  margin-block: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-my--6 {
  margin-block: var(--kendo-spacing--6, -1.5rem);
}

.\!k-my--6 {
  margin-block: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-my--6\.5 {
  margin-block: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-my--6\.5 {
  margin-block: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-my--7 {
  margin-block: var(--kendo-spacing--7, -1.75rem);
}

.\!k-my--7 {
  margin-block: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-my--7\.5 {
  margin-block: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-my--7\.5 {
  margin-block: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-my--8 {
  margin-block: var(--kendo-spacing--8, -2rem);
}

.\!k-my--8 {
  margin-block: var(--kendo-spacing--8, -2rem) !important;
}

.k-my--9 {
  margin-block: var(--kendo-spacing--9, -2.25rem);
}

.\!k-my--9 {
  margin-block: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-my--10 {
  margin-block: var(--kendo-spacing--10, -2.5rem);
}

.\!k-my--10 {
  margin-block: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-my--11 {
  margin-block: var(--kendo-spacing--11, -2.75rem);
}

.\!k-my--11 {
  margin-block: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-my--12 {
  margin-block: var(--kendo-spacing--12, -3rem);
}

.\!k-my--12 {
  margin-block: var(--kendo-spacing--12, -3rem) !important;
}

.k-my--13 {
  margin-block: var(--kendo-spacing--13, -3.25rem);
}

.\!k-my--13 {
  margin-block: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-my--14 {
  margin-block: var(--kendo-spacing--14, -3.5rem);
}

.\!k-my--14 {
  margin-block: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-my--15 {
  margin-block: var(--kendo-spacing--15, -3.75rem);
}

.\!k-my--15 {
  margin-block: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-my--16 {
  margin-block: var(--kendo-spacing--16, -4rem);
}

.\!k-my--16 {
  margin-block: var(--kendo-spacing--16, -4rem) !important;
}

.k-my--17 {
  margin-block: var(--kendo-spacing--17, -4.25rem);
}

.\!k-my--17 {
  margin-block: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-my--18 {
  margin-block: var(--kendo-spacing--18, -4.5rem);
}

.\!k-my--18 {
  margin-block: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-my--19 {
  margin-block: var(--kendo-spacing--19, -4.75rem);
}

.\!k-my--19 {
  margin-block: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-my--20 {
  margin-block: var(--kendo-spacing--20, -5rem);
}

.\!k-my--20 {
  margin-block: var(--kendo-spacing--20, -5rem) !important;
}

.k-my--21 {
  margin-block: var(--kendo-spacing--21, -5.25rem);
}

.\!k-my--21 {
  margin-block: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-my--22 {
  margin-block: var(--kendo-spacing--22, -5.5rem);
}

.\!k-my--22 {
  margin-block: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-my--23 {
  margin-block: var(--kendo-spacing--23, -5.75rem);
}

.\!k-my--23 {
  margin-block: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-my--24 {
  margin-block: var(--kendo-spacing--24, -6rem);
}

.\!k-my--24 {
  margin-block: var(--kendo-spacing--24, -6rem) !important;
}

.k-my--25 {
  margin-block: var(--kendo-spacing--25, -7rem);
}

.\!k-my--25 {
  margin-block: var(--kendo-spacing--25, -7rem) !important;
}

.k-my--26 {
  margin-block: var(--kendo-spacing--26, -8rem);
}

.\!k-my--26 {
  margin-block: var(--kendo-spacing--26, -8rem) !important;
}

.k-my--27 {
  margin-block: var(--kendo-spacing--27, -9rem);
}

.\!k-my--27 {
  margin-block: var(--kendo-spacing--27, -9rem) !important;
}

.k-my--28 {
  margin-block: var(--kendo-spacing--28, -10rem);
}

.\!k-my--28 {
  margin-block: var(--kendo-spacing--28, -10rem) !important;
}

.k-my--29 {
  margin-block: var(--kendo-spacing--29, -11rem);
}

.\!k-my--29 {
  margin-block: var(--kendo-spacing--29, -11rem) !important;
}

.k-my--30 {
  margin-block: var(--kendo-spacing--30, -12rem);
}

.\!k-my--30 {
  margin-block: var(--kendo-spacing--30, -12rem) !important;
}

.k-my-xs {
  margin-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-my-xs {
  margin-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-my-sm {
  margin-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-my-sm {
  margin-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-my-md {
  margin-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-my-md {
  margin-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-my-lg {
  margin-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-my-lg {
  margin-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-my-xl {
  margin-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-my-xl {
  margin-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-my-thin {
  margin-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-my-thin {
  margin-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-my-hair {
  margin-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-my-hair {
  margin-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-my-auto {
  margin-block: var(--kendo-spacing-auto, auto);
}

.\!k-my-auto {
  margin-block: var(--kendo-spacing-auto, auto) !important;
}

.k-p-0 {
  padding: var(--kendo-spacing-0, 0px);
}

.\!k-p-0 {
  padding: var(--kendo-spacing-0, 0px) !important;
}

.k-p-1px {
  padding: var(--kendo-spacing-1px, 1px);
}

.\!k-p-1px {
  padding: var(--kendo-spacing-1px, 1px) !important;
}

.k-p-0\.5 {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-p-0\.5 {
  padding: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-p-1 {
  padding: var(--kendo-spacing-1, 0.25rem);
}

.\!k-p-1 {
  padding: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-p-1\.5 {
  padding: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-p-1\.5 {
  padding: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-p-2 {
  padding: var(--kendo-spacing-2, 0.5rem);
}

.\!k-p-2 {
  padding: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-p-2\.5 {
  padding: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-p-2\.5 {
  padding: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-p-3 {
  padding: var(--kendo-spacing-3, 0.75rem);
}

.\!k-p-3 {
  padding: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-p-3\.5 {
  padding: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-p-3\.5 {
  padding: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-p-4 {
  padding: var(--kendo-spacing-4, 1rem);
}

.\!k-p-4 {
  padding: var(--kendo-spacing-4, 1rem) !important;
}

.k-p-4\.5 {
  padding: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-p-4\.5 {
  padding: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-p-5 {
  padding: var(--kendo-spacing-5, 1.25rem);
}

.\!k-p-5 {
  padding: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-p-5\.5 {
  padding: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-p-5\.5 {
  padding: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-p-6 {
  padding: var(--kendo-spacing-6, 1.5rem);
}

.\!k-p-6 {
  padding: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-p-6\.5 {
  padding: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-p-6\.5 {
  padding: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-p-7 {
  padding: var(--kendo-spacing-7, 1.75rem);
}

.\!k-p-7 {
  padding: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-p-7\.5 {
  padding: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-p-7\.5 {
  padding: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-p-8 {
  padding: var(--kendo-spacing-8, 2rem);
}

.\!k-p-8 {
  padding: var(--kendo-spacing-8, 2rem) !important;
}

.k-p-9 {
  padding: var(--kendo-spacing-9, 2.25rem);
}

.\!k-p-9 {
  padding: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-p-10 {
  padding: var(--kendo-spacing-10, 2.5rem);
}

.\!k-p-10 {
  padding: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-p-11 {
  padding: var(--kendo-spacing-11, 2.75rem);
}

.\!k-p-11 {
  padding: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-p-12 {
  padding: var(--kendo-spacing-12, 3rem);
}

.\!k-p-12 {
  padding: var(--kendo-spacing-12, 3rem) !important;
}

.k-p-13 {
  padding: var(--kendo-spacing-13, 3.25rem);
}

.\!k-p-13 {
  padding: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-p-14 {
  padding: var(--kendo-spacing-14, 3.5rem);
}

.\!k-p-14 {
  padding: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-p-15 {
  padding: var(--kendo-spacing-15, 3.75rem);
}

.\!k-p-15 {
  padding: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-p-16 {
  padding: var(--kendo-spacing-16, 4rem);
}

.\!k-p-16 {
  padding: var(--kendo-spacing-16, 4rem) !important;
}

.k-p-17 {
  padding: var(--kendo-spacing-17, 4.25rem);
}

.\!k-p-17 {
  padding: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-p-18 {
  padding: var(--kendo-spacing-18, 4.5rem);
}

.\!k-p-18 {
  padding: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-p-19 {
  padding: var(--kendo-spacing-19, 4.75rem);
}

.\!k-p-19 {
  padding: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-p-20 {
  padding: var(--kendo-spacing-20, 5rem);
}

.\!k-p-20 {
  padding: var(--kendo-spacing-20, 5rem) !important;
}

.k-p-21 {
  padding: var(--kendo-spacing-21, 5.25rem);
}

.\!k-p-21 {
  padding: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-p-22 {
  padding: var(--kendo-spacing-22, 5.5rem);
}

.\!k-p-22 {
  padding: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-p-23 {
  padding: var(--kendo-spacing-23, 5.75rem);
}

.\!k-p-23 {
  padding: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-p-24 {
  padding: var(--kendo-spacing-24, 6rem);
}

.\!k-p-24 {
  padding: var(--kendo-spacing-24, 6rem) !important;
}

.k-p-25 {
  padding: var(--kendo-spacing-25, 7rem);
}

.\!k-p-25 {
  padding: var(--kendo-spacing-25, 7rem) !important;
}

.k-p-26 {
  padding: var(--kendo-spacing-26, 8rem);
}

.\!k-p-26 {
  padding: var(--kendo-spacing-26, 8rem) !important;
}

.k-p-27 {
  padding: var(--kendo-spacing-27, 9rem);
}

.\!k-p-27 {
  padding: var(--kendo-spacing-27, 9rem) !important;
}

.k-p-28 {
  padding: var(--kendo-spacing-28, 10rem);
}

.\!k-p-28 {
  padding: var(--kendo-spacing-28, 10rem) !important;
}

.k-p-29 {
  padding: var(--kendo-spacing-29, 11rem);
}

.\!k-p-29 {
  padding: var(--kendo-spacing-29, 11rem) !important;
}

.k-p-30 {
  padding: var(--kendo-spacing-30, 12rem);
}

.\!k-p-30 {
  padding: var(--kendo-spacing-30, 12rem) !important;
}

.k-p-xs {
  padding: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-p-xs {
  padding: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-p-sm {
  padding: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-p-sm {
  padding: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-p-md {
  padding: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-p-md {
  padding: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-p-lg {
  padding: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-p-lg {
  padding: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-p-xl {
  padding: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-p-xl {
  padding: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-p-thin {
  padding: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-p-thin {
  padding: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-p-hair {
  padding: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-p-hair {
  padding: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pt-0 {
  padding-top: var(--kendo-spacing-0, 0px);
}

.\!k-pt-0 {
  padding-top: var(--kendo-spacing-0, 0px) !important;
}

.k-pt-1px {
  padding-top: var(--kendo-spacing-1px, 1px);
}

.\!k-pt-1px {
  padding-top: var(--kendo-spacing-1px, 1px) !important;
}

.k-pt-0\.5 {
  padding-top: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pt-0\.5 {
  padding-top: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pt-1 {
  padding-top: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pt-1 {
  padding-top: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pt-1\.5 {
  padding-top: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pt-1\.5 {
  padding-top: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pt-2 {
  padding-top: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pt-2 {
  padding-top: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pt-2\.5 {
  padding-top: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pt-2\.5 {
  padding-top: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pt-3 {
  padding-top: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pt-3 {
  padding-top: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pt-3\.5 {
  padding-top: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pt-3\.5 {
  padding-top: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pt-4 {
  padding-top: var(--kendo-spacing-4, 1rem);
}

.\!k-pt-4 {
  padding-top: var(--kendo-spacing-4, 1rem) !important;
}

.k-pt-4\.5 {
  padding-top: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pt-4\.5 {
  padding-top: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pt-5 {
  padding-top: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pt-5 {
  padding-top: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pt-5\.5 {
  padding-top: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pt-5\.5 {
  padding-top: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pt-6 {
  padding-top: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pt-6 {
  padding-top: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pt-6\.5 {
  padding-top: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pt-6\.5 {
  padding-top: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pt-7 {
  padding-top: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pt-7 {
  padding-top: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pt-7\.5 {
  padding-top: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pt-7\.5 {
  padding-top: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pt-8 {
  padding-top: var(--kendo-spacing-8, 2rem);
}

.\!k-pt-8 {
  padding-top: var(--kendo-spacing-8, 2rem) !important;
}

.k-pt-9 {
  padding-top: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pt-9 {
  padding-top: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pt-10 {
  padding-top: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pt-10 {
  padding-top: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pt-11 {
  padding-top: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pt-11 {
  padding-top: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pt-12 {
  padding-top: var(--kendo-spacing-12, 3rem);
}

.\!k-pt-12 {
  padding-top: var(--kendo-spacing-12, 3rem) !important;
}

.k-pt-13 {
  padding-top: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pt-13 {
  padding-top: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pt-14 {
  padding-top: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pt-14 {
  padding-top: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pt-15 {
  padding-top: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pt-15 {
  padding-top: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pt-16 {
  padding-top: var(--kendo-spacing-16, 4rem);
}

.\!k-pt-16 {
  padding-top: var(--kendo-spacing-16, 4rem) !important;
}

.k-pt-17 {
  padding-top: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pt-17 {
  padding-top: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pt-18 {
  padding-top: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pt-18 {
  padding-top: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pt-19 {
  padding-top: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pt-19 {
  padding-top: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pt-20 {
  padding-top: var(--kendo-spacing-20, 5rem);
}

.\!k-pt-20 {
  padding-top: var(--kendo-spacing-20, 5rem) !important;
}

.k-pt-21 {
  padding-top: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pt-21 {
  padding-top: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pt-22 {
  padding-top: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pt-22 {
  padding-top: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pt-23 {
  padding-top: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pt-23 {
  padding-top: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pt-24 {
  padding-top: var(--kendo-spacing-24, 6rem);
}

.\!k-pt-24 {
  padding-top: var(--kendo-spacing-24, 6rem) !important;
}

.k-pt-25 {
  padding-top: var(--kendo-spacing-25, 7rem);
}

.\!k-pt-25 {
  padding-top: var(--kendo-spacing-25, 7rem) !important;
}

.k-pt-26 {
  padding-top: var(--kendo-spacing-26, 8rem);
}

.\!k-pt-26 {
  padding-top: var(--kendo-spacing-26, 8rem) !important;
}

.k-pt-27 {
  padding-top: var(--kendo-spacing-27, 9rem);
}

.\!k-pt-27 {
  padding-top: var(--kendo-spacing-27, 9rem) !important;
}

.k-pt-28 {
  padding-top: var(--kendo-spacing-28, 10rem);
}

.\!k-pt-28 {
  padding-top: var(--kendo-spacing-28, 10rem) !important;
}

.k-pt-29 {
  padding-top: var(--kendo-spacing-29, 11rem);
}

.\!k-pt-29 {
  padding-top: var(--kendo-spacing-29, 11rem) !important;
}

.k-pt-30 {
  padding-top: var(--kendo-spacing-30, 12rem);
}

.\!k-pt-30 {
  padding-top: var(--kendo-spacing-30, 12rem) !important;
}

.k-pt-xs {
  padding-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pt-xs {
  padding-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pt-sm {
  padding-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pt-sm {
  padding-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pt-md {
  padding-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pt-md {
  padding-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pt-lg {
  padding-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pt-lg {
  padding-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pt-xl {
  padding-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pt-xl {
  padding-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pt-thin {
  padding-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pt-thin {
  padding-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pt-hair {
  padding-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pt-hair {
  padding-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pr-0 {
  padding-right: var(--kendo-spacing-0, 0px);
}

.\!k-pr-0 {
  padding-right: var(--kendo-spacing-0, 0px) !important;
}

.k-pr-1px {
  padding-right: var(--kendo-spacing-1px, 1px);
}

.\!k-pr-1px {
  padding-right: var(--kendo-spacing-1px, 1px) !important;
}

.k-pr-0\.5 {
  padding-right: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pr-0\.5 {
  padding-right: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pr-1 {
  padding-right: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pr-1 {
  padding-right: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pr-1\.5 {
  padding-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pr-1\.5 {
  padding-right: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pr-2 {
  padding-right: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pr-2 {
  padding-right: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pr-2\.5 {
  padding-right: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pr-2\.5 {
  padding-right: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pr-3 {
  padding-right: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pr-3 {
  padding-right: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pr-3\.5 {
  padding-right: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pr-3\.5 {
  padding-right: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pr-4 {
  padding-right: var(--kendo-spacing-4, 1rem);
}

.\!k-pr-4 {
  padding-right: var(--kendo-spacing-4, 1rem) !important;
}

.k-pr-4\.5 {
  padding-right: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pr-4\.5 {
  padding-right: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pr-5 {
  padding-right: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pr-5 {
  padding-right: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pr-5\.5 {
  padding-right: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pr-5\.5 {
  padding-right: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pr-6 {
  padding-right: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pr-6 {
  padding-right: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pr-6\.5 {
  padding-right: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pr-6\.5 {
  padding-right: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pr-7 {
  padding-right: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pr-7 {
  padding-right: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pr-7\.5 {
  padding-right: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pr-7\.5 {
  padding-right: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pr-8 {
  padding-right: var(--kendo-spacing-8, 2rem);
}

.\!k-pr-8 {
  padding-right: var(--kendo-spacing-8, 2rem) !important;
}

.k-pr-9 {
  padding-right: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pr-9 {
  padding-right: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pr-10 {
  padding-right: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pr-10 {
  padding-right: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pr-11 {
  padding-right: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pr-11 {
  padding-right: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pr-12 {
  padding-right: var(--kendo-spacing-12, 3rem);
}

.\!k-pr-12 {
  padding-right: var(--kendo-spacing-12, 3rem) !important;
}

.k-pr-13 {
  padding-right: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pr-13 {
  padding-right: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pr-14 {
  padding-right: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pr-14 {
  padding-right: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pr-15 {
  padding-right: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pr-15 {
  padding-right: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pr-16 {
  padding-right: var(--kendo-spacing-16, 4rem);
}

.\!k-pr-16 {
  padding-right: var(--kendo-spacing-16, 4rem) !important;
}

.k-pr-17 {
  padding-right: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pr-17 {
  padding-right: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pr-18 {
  padding-right: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pr-18 {
  padding-right: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pr-19 {
  padding-right: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pr-19 {
  padding-right: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pr-20 {
  padding-right: var(--kendo-spacing-20, 5rem);
}

.\!k-pr-20 {
  padding-right: var(--kendo-spacing-20, 5rem) !important;
}

.k-pr-21 {
  padding-right: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pr-21 {
  padding-right: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pr-22 {
  padding-right: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pr-22 {
  padding-right: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pr-23 {
  padding-right: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pr-23 {
  padding-right: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pr-24 {
  padding-right: var(--kendo-spacing-24, 6rem);
}

.\!k-pr-24 {
  padding-right: var(--kendo-spacing-24, 6rem) !important;
}

.k-pr-25 {
  padding-right: var(--kendo-spacing-25, 7rem);
}

.\!k-pr-25 {
  padding-right: var(--kendo-spacing-25, 7rem) !important;
}

.k-pr-26 {
  padding-right: var(--kendo-spacing-26, 8rem);
}

.\!k-pr-26 {
  padding-right: var(--kendo-spacing-26, 8rem) !important;
}

.k-pr-27 {
  padding-right: var(--kendo-spacing-27, 9rem);
}

.\!k-pr-27 {
  padding-right: var(--kendo-spacing-27, 9rem) !important;
}

.k-pr-28 {
  padding-right: var(--kendo-spacing-28, 10rem);
}

.\!k-pr-28 {
  padding-right: var(--kendo-spacing-28, 10rem) !important;
}

.k-pr-29 {
  padding-right: var(--kendo-spacing-29, 11rem);
}

.\!k-pr-29 {
  padding-right: var(--kendo-spacing-29, 11rem) !important;
}

.k-pr-30 {
  padding-right: var(--kendo-spacing-30, 12rem);
}

.\!k-pr-30 {
  padding-right: var(--kendo-spacing-30, 12rem) !important;
}

.k-pr-xs {
  padding-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pr-xs {
  padding-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pr-sm {
  padding-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pr-sm {
  padding-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pr-md {
  padding-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pr-md {
  padding-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pr-lg {
  padding-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pr-lg {
  padding-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pr-xl {
  padding-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pr-xl {
  padding-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pr-thin {
  padding-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pr-thin {
  padding-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pr-hair {
  padding-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pr-hair {
  padding-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pb-0 {
  padding-bottom: var(--kendo-spacing-0, 0px);
}

.\!k-pb-0 {
  padding-bottom: var(--kendo-spacing-0, 0px) !important;
}

.k-pb-1px {
  padding-bottom: var(--kendo-spacing-1px, 1px);
}

.\!k-pb-1px {
  padding-bottom: var(--kendo-spacing-1px, 1px) !important;
}

.k-pb-0\.5 {
  padding-bottom: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pb-0\.5 {
  padding-bottom: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pb-1 {
  padding-bottom: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pb-1 {
  padding-bottom: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pb-1\.5 {
  padding-bottom: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pb-1\.5 {
  padding-bottom: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pb-2 {
  padding-bottom: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pb-2 {
  padding-bottom: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pb-2\.5 {
  padding-bottom: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pb-2\.5 {
  padding-bottom: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pb-3 {
  padding-bottom: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pb-3 {
  padding-bottom: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pb-3\.5 {
  padding-bottom: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pb-3\.5 {
  padding-bottom: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pb-4 {
  padding-bottom: var(--kendo-spacing-4, 1rem);
}

.\!k-pb-4 {
  padding-bottom: var(--kendo-spacing-4, 1rem) !important;
}

.k-pb-4\.5 {
  padding-bottom: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pb-4\.5 {
  padding-bottom: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pb-5 {
  padding-bottom: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pb-5 {
  padding-bottom: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pb-5\.5 {
  padding-bottom: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pb-5\.5 {
  padding-bottom: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pb-6 {
  padding-bottom: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pb-6 {
  padding-bottom: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pb-6\.5 {
  padding-bottom: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pb-6\.5 {
  padding-bottom: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pb-7 {
  padding-bottom: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pb-7 {
  padding-bottom: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pb-7\.5 {
  padding-bottom: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pb-7\.5 {
  padding-bottom: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pb-8 {
  padding-bottom: var(--kendo-spacing-8, 2rem);
}

.\!k-pb-8 {
  padding-bottom: var(--kendo-spacing-8, 2rem) !important;
}

.k-pb-9 {
  padding-bottom: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pb-9 {
  padding-bottom: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pb-10 {
  padding-bottom: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pb-10 {
  padding-bottom: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pb-11 {
  padding-bottom: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pb-11 {
  padding-bottom: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pb-12 {
  padding-bottom: var(--kendo-spacing-12, 3rem);
}

.\!k-pb-12 {
  padding-bottom: var(--kendo-spacing-12, 3rem) !important;
}

.k-pb-13 {
  padding-bottom: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pb-13 {
  padding-bottom: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pb-14 {
  padding-bottom: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pb-14 {
  padding-bottom: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pb-15 {
  padding-bottom: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pb-15 {
  padding-bottom: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pb-16 {
  padding-bottom: var(--kendo-spacing-16, 4rem);
}

.\!k-pb-16 {
  padding-bottom: var(--kendo-spacing-16, 4rem) !important;
}

.k-pb-17 {
  padding-bottom: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pb-17 {
  padding-bottom: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pb-18 {
  padding-bottom: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pb-18 {
  padding-bottom: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pb-19 {
  padding-bottom: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pb-19 {
  padding-bottom: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pb-20 {
  padding-bottom: var(--kendo-spacing-20, 5rem);
}

.\!k-pb-20 {
  padding-bottom: var(--kendo-spacing-20, 5rem) !important;
}

.k-pb-21 {
  padding-bottom: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pb-21 {
  padding-bottom: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pb-22 {
  padding-bottom: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pb-22 {
  padding-bottom: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pb-23 {
  padding-bottom: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pb-23 {
  padding-bottom: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pb-24 {
  padding-bottom: var(--kendo-spacing-24, 6rem);
}

.\!k-pb-24 {
  padding-bottom: var(--kendo-spacing-24, 6rem) !important;
}

.k-pb-25 {
  padding-bottom: var(--kendo-spacing-25, 7rem);
}

.\!k-pb-25 {
  padding-bottom: var(--kendo-spacing-25, 7rem) !important;
}

.k-pb-26 {
  padding-bottom: var(--kendo-spacing-26, 8rem);
}

.\!k-pb-26 {
  padding-bottom: var(--kendo-spacing-26, 8rem) !important;
}

.k-pb-27 {
  padding-bottom: var(--kendo-spacing-27, 9rem);
}

.\!k-pb-27 {
  padding-bottom: var(--kendo-spacing-27, 9rem) !important;
}

.k-pb-28 {
  padding-bottom: var(--kendo-spacing-28, 10rem);
}

.\!k-pb-28 {
  padding-bottom: var(--kendo-spacing-28, 10rem) !important;
}

.k-pb-29 {
  padding-bottom: var(--kendo-spacing-29, 11rem);
}

.\!k-pb-29 {
  padding-bottom: var(--kendo-spacing-29, 11rem) !important;
}

.k-pb-30 {
  padding-bottom: var(--kendo-spacing-30, 12rem);
}

.\!k-pb-30 {
  padding-bottom: var(--kendo-spacing-30, 12rem) !important;
}

.k-pb-xs {
  padding-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pb-xs {
  padding-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pb-sm {
  padding-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pb-sm {
  padding-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pb-md {
  padding-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pb-md {
  padding-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pb-lg {
  padding-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pb-lg {
  padding-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pb-xl {
  padding-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pb-xl {
  padding-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pb-thin {
  padding-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pb-thin {
  padding-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pb-hair {
  padding-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pb-hair {
  padding-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pl-0 {
  padding-left: var(--kendo-spacing-0, 0px);
}

.\!k-pl-0 {
  padding-left: var(--kendo-spacing-0, 0px) !important;
}

.k-pl-1px {
  padding-left: var(--kendo-spacing-1px, 1px);
}

.\!k-pl-1px {
  padding-left: var(--kendo-spacing-1px, 1px) !important;
}

.k-pl-0\.5 {
  padding-left: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pl-0\.5 {
  padding-left: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pl-1 {
  padding-left: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pl-1 {
  padding-left: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pl-1\.5 {
  padding-left: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pl-1\.5 {
  padding-left: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pl-2 {
  padding-left: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pl-2 {
  padding-left: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pl-2\.5 {
  padding-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pl-2\.5 {
  padding-left: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pl-3 {
  padding-left: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pl-3 {
  padding-left: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pl-3\.5 {
  padding-left: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pl-3\.5 {
  padding-left: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pl-4 {
  padding-left: var(--kendo-spacing-4, 1rem);
}

.\!k-pl-4 {
  padding-left: var(--kendo-spacing-4, 1rem) !important;
}

.k-pl-4\.5 {
  padding-left: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pl-4\.5 {
  padding-left: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pl-5 {
  padding-left: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pl-5 {
  padding-left: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pl-5\.5 {
  padding-left: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pl-5\.5 {
  padding-left: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pl-6 {
  padding-left: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pl-6 {
  padding-left: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pl-6\.5 {
  padding-left: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pl-6\.5 {
  padding-left: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pl-7 {
  padding-left: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pl-7 {
  padding-left: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pl-7\.5 {
  padding-left: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pl-7\.5 {
  padding-left: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pl-8 {
  padding-left: var(--kendo-spacing-8, 2rem);
}

.\!k-pl-8 {
  padding-left: var(--kendo-spacing-8, 2rem) !important;
}

.k-pl-9 {
  padding-left: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pl-9 {
  padding-left: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pl-10 {
  padding-left: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pl-10 {
  padding-left: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pl-11 {
  padding-left: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pl-11 {
  padding-left: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pl-12 {
  padding-left: var(--kendo-spacing-12, 3rem);
}

.\!k-pl-12 {
  padding-left: var(--kendo-spacing-12, 3rem) !important;
}

.k-pl-13 {
  padding-left: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pl-13 {
  padding-left: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pl-14 {
  padding-left: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pl-14 {
  padding-left: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pl-15 {
  padding-left: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pl-15 {
  padding-left: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pl-16 {
  padding-left: var(--kendo-spacing-16, 4rem);
}

.\!k-pl-16 {
  padding-left: var(--kendo-spacing-16, 4rem) !important;
}

.k-pl-17 {
  padding-left: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pl-17 {
  padding-left: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pl-18 {
  padding-left: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pl-18 {
  padding-left: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pl-19 {
  padding-left: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pl-19 {
  padding-left: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pl-20 {
  padding-left: var(--kendo-spacing-20, 5rem);
}

.\!k-pl-20 {
  padding-left: var(--kendo-spacing-20, 5rem) !important;
}

.k-pl-21 {
  padding-left: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pl-21 {
  padding-left: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pl-22 {
  padding-left: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pl-22 {
  padding-left: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pl-23 {
  padding-left: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pl-23 {
  padding-left: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pl-24 {
  padding-left: var(--kendo-spacing-24, 6rem);
}

.\!k-pl-24 {
  padding-left: var(--kendo-spacing-24, 6rem) !important;
}

.k-pl-25 {
  padding-left: var(--kendo-spacing-25, 7rem);
}

.\!k-pl-25 {
  padding-left: var(--kendo-spacing-25, 7rem) !important;
}

.k-pl-26 {
  padding-left: var(--kendo-spacing-26, 8rem);
}

.\!k-pl-26 {
  padding-left: var(--kendo-spacing-26, 8rem) !important;
}

.k-pl-27 {
  padding-left: var(--kendo-spacing-27, 9rem);
}

.\!k-pl-27 {
  padding-left: var(--kendo-spacing-27, 9rem) !important;
}

.k-pl-28 {
  padding-left: var(--kendo-spacing-28, 10rem);
}

.\!k-pl-28 {
  padding-left: var(--kendo-spacing-28, 10rem) !important;
}

.k-pl-29 {
  padding-left: var(--kendo-spacing-29, 11rem);
}

.\!k-pl-29 {
  padding-left: var(--kendo-spacing-29, 11rem) !important;
}

.k-pl-30 {
  padding-left: var(--kendo-spacing-30, 12rem);
}

.\!k-pl-30 {
  padding-left: var(--kendo-spacing-30, 12rem) !important;
}

.k-pl-xs {
  padding-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pl-xs {
  padding-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pl-sm {
  padding-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pl-sm {
  padding-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pl-md {
  padding-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pl-md {
  padding-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pl-lg {
  padding-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pl-lg {
  padding-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pl-xl {
  padding-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pl-xl {
  padding-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pl-thin {
  padding-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pl-thin {
  padding-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pl-hair {
  padding-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pl-hair {
  padding-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-px-0 {
  padding-inline: var(--kendo-spacing-0, 0px);
}

.\!k-px-0 {
  padding-inline: var(--kendo-spacing-0, 0px) !important;
}

.k-px-1px {
  padding-inline: var(--kendo-spacing-1px, 1px);
}

.\!k-px-1px {
  padding-inline: var(--kendo-spacing-1px, 1px) !important;
}

.k-px-0\.5 {
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-px-0\.5 {
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-px-1 {
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.\!k-px-1 {
  padding-inline: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-px-1\.5 {
  padding-inline: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-px-1\.5 {
  padding-inline: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-px-2 {
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.\!k-px-2 {
  padding-inline: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-px-2\.5 {
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-px-2\.5 {
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-px-3 {
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}

.\!k-px-3 {
  padding-inline: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-px-3\.5 {
  padding-inline: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-px-3\.5 {
  padding-inline: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-px-4 {
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.\!k-px-4 {
  padding-inline: var(--kendo-spacing-4, 1rem) !important;
}

.k-px-4\.5 {
  padding-inline: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-px-4\.5 {
  padding-inline: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-px-5 {
  padding-inline: var(--kendo-spacing-5, 1.25rem);
}

.\!k-px-5 {
  padding-inline: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-px-5\.5 {
  padding-inline: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-px-5\.5 {
  padding-inline: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-px-6 {
  padding-inline: var(--kendo-spacing-6, 1.5rem);
}

.\!k-px-6 {
  padding-inline: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-px-6\.5 {
  padding-inline: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-px-6\.5 {
  padding-inline: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-px-7 {
  padding-inline: var(--kendo-spacing-7, 1.75rem);
}

.\!k-px-7 {
  padding-inline: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-px-7\.5 {
  padding-inline: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-px-7\.5 {
  padding-inline: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-px-8 {
  padding-inline: var(--kendo-spacing-8, 2rem);
}

.\!k-px-8 {
  padding-inline: var(--kendo-spacing-8, 2rem) !important;
}

.k-px-9 {
  padding-inline: var(--kendo-spacing-9, 2.25rem);
}

.\!k-px-9 {
  padding-inline: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-px-10 {
  padding-inline: var(--kendo-spacing-10, 2.5rem);
}

.\!k-px-10 {
  padding-inline: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-px-11 {
  padding-inline: var(--kendo-spacing-11, 2.75rem);
}

.\!k-px-11 {
  padding-inline: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-px-12 {
  padding-inline: var(--kendo-spacing-12, 3rem);
}

.\!k-px-12 {
  padding-inline: var(--kendo-spacing-12, 3rem) !important;
}

.k-px-13 {
  padding-inline: var(--kendo-spacing-13, 3.25rem);
}

.\!k-px-13 {
  padding-inline: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-px-14 {
  padding-inline: var(--kendo-spacing-14, 3.5rem);
}

.\!k-px-14 {
  padding-inline: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-px-15 {
  padding-inline: var(--kendo-spacing-15, 3.75rem);
}

.\!k-px-15 {
  padding-inline: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-px-16 {
  padding-inline: var(--kendo-spacing-16, 4rem);
}

.\!k-px-16 {
  padding-inline: var(--kendo-spacing-16, 4rem) !important;
}

.k-px-17 {
  padding-inline: var(--kendo-spacing-17, 4.25rem);
}

.\!k-px-17 {
  padding-inline: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-px-18 {
  padding-inline: var(--kendo-spacing-18, 4.5rem);
}

.\!k-px-18 {
  padding-inline: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-px-19 {
  padding-inline: var(--kendo-spacing-19, 4.75rem);
}

.\!k-px-19 {
  padding-inline: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-px-20 {
  padding-inline: var(--kendo-spacing-20, 5rem);
}

.\!k-px-20 {
  padding-inline: var(--kendo-spacing-20, 5rem) !important;
}

.k-px-21 {
  padding-inline: var(--kendo-spacing-21, 5.25rem);
}

.\!k-px-21 {
  padding-inline: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-px-22 {
  padding-inline: var(--kendo-spacing-22, 5.5rem);
}

.\!k-px-22 {
  padding-inline: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-px-23 {
  padding-inline: var(--kendo-spacing-23, 5.75rem);
}

.\!k-px-23 {
  padding-inline: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-px-24 {
  padding-inline: var(--kendo-spacing-24, 6rem);
}

.\!k-px-24 {
  padding-inline: var(--kendo-spacing-24, 6rem) !important;
}

.k-px-25 {
  padding-inline: var(--kendo-spacing-25, 7rem);
}

.\!k-px-25 {
  padding-inline: var(--kendo-spacing-25, 7rem) !important;
}

.k-px-26 {
  padding-inline: var(--kendo-spacing-26, 8rem);
}

.\!k-px-26 {
  padding-inline: var(--kendo-spacing-26, 8rem) !important;
}

.k-px-27 {
  padding-inline: var(--kendo-spacing-27, 9rem);
}

.\!k-px-27 {
  padding-inline: var(--kendo-spacing-27, 9rem) !important;
}

.k-px-28 {
  padding-inline: var(--kendo-spacing-28, 10rem);
}

.\!k-px-28 {
  padding-inline: var(--kendo-spacing-28, 10rem) !important;
}

.k-px-29 {
  padding-inline: var(--kendo-spacing-29, 11rem);
}

.\!k-px-29 {
  padding-inline: var(--kendo-spacing-29, 11rem) !important;
}

.k-px-30 {
  padding-inline: var(--kendo-spacing-30, 12rem);
}

.\!k-px-30 {
  padding-inline: var(--kendo-spacing-30, 12rem) !important;
}

.k-px-xs {
  padding-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-px-xs {
  padding-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-px-sm {
  padding-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-px-sm {
  padding-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-px-md {
  padding-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-px-md {
  padding-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-px-lg {
  padding-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-px-lg {
  padding-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-px-xl {
  padding-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-px-xl {
  padding-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-px-thin {
  padding-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-px-thin {
  padding-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-px-hair {
  padding-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-px-hair {
  padding-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-py-0 {
  padding-block: var(--kendo-spacing-0, 0px);
}

.\!k-py-0 {
  padding-block: var(--kendo-spacing-0, 0px) !important;
}

.k-py-1px {
  padding-block: var(--kendo-spacing-1px, 1px);
}

.\!k-py-1px {
  padding-block: var(--kendo-spacing-1px, 1px) !important;
}

.k-py-0\.5 {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-py-0\.5 {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-py-1 {
  padding-block: var(--kendo-spacing-1, 0.25rem);
}

.\!k-py-1 {
  padding-block: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-py-1\.5 {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-py-1\.5 {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-py-2 {
  padding-block: var(--kendo-spacing-2, 0.5rem);
}

.\!k-py-2 {
  padding-block: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-py-2\.5 {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-py-2\.5 {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-py-3 {
  padding-block: var(--kendo-spacing-3, 0.75rem);
}

.\!k-py-3 {
  padding-block: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-py-3\.5 {
  padding-block: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-py-3\.5 {
  padding-block: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-py-4 {
  padding-block: var(--kendo-spacing-4, 1rem);
}

.\!k-py-4 {
  padding-block: var(--kendo-spacing-4, 1rem) !important;
}

.k-py-4\.5 {
  padding-block: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-py-4\.5 {
  padding-block: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-py-5 {
  padding-block: var(--kendo-spacing-5, 1.25rem);
}

.\!k-py-5 {
  padding-block: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-py-5\.5 {
  padding-block: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-py-5\.5 {
  padding-block: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-py-6 {
  padding-block: var(--kendo-spacing-6, 1.5rem);
}

.\!k-py-6 {
  padding-block: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-py-6\.5 {
  padding-block: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-py-6\.5 {
  padding-block: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-py-7 {
  padding-block: var(--kendo-spacing-7, 1.75rem);
}

.\!k-py-7 {
  padding-block: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-py-7\.5 {
  padding-block: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-py-7\.5 {
  padding-block: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-py-8 {
  padding-block: var(--kendo-spacing-8, 2rem);
}

.\!k-py-8 {
  padding-block: var(--kendo-spacing-8, 2rem) !important;
}

.k-py-9 {
  padding-block: var(--kendo-spacing-9, 2.25rem);
}

.\!k-py-9 {
  padding-block: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-py-10 {
  padding-block: var(--kendo-spacing-10, 2.5rem);
}

.\!k-py-10 {
  padding-block: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-py-11 {
  padding-block: var(--kendo-spacing-11, 2.75rem);
}

.\!k-py-11 {
  padding-block: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-py-12 {
  padding-block: var(--kendo-spacing-12, 3rem);
}

.\!k-py-12 {
  padding-block: var(--kendo-spacing-12, 3rem) !important;
}

.k-py-13 {
  padding-block: var(--kendo-spacing-13, 3.25rem);
}

.\!k-py-13 {
  padding-block: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-py-14 {
  padding-block: var(--kendo-spacing-14, 3.5rem);
}

.\!k-py-14 {
  padding-block: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-py-15 {
  padding-block: var(--kendo-spacing-15, 3.75rem);
}

.\!k-py-15 {
  padding-block: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-py-16 {
  padding-block: var(--kendo-spacing-16, 4rem);
}

.\!k-py-16 {
  padding-block: var(--kendo-spacing-16, 4rem) !important;
}

.k-py-17 {
  padding-block: var(--kendo-spacing-17, 4.25rem);
}

.\!k-py-17 {
  padding-block: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-py-18 {
  padding-block: var(--kendo-spacing-18, 4.5rem);
}

.\!k-py-18 {
  padding-block: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-py-19 {
  padding-block: var(--kendo-spacing-19, 4.75rem);
}

.\!k-py-19 {
  padding-block: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-py-20 {
  padding-block: var(--kendo-spacing-20, 5rem);
}

.\!k-py-20 {
  padding-block: var(--kendo-spacing-20, 5rem) !important;
}

.k-py-21 {
  padding-block: var(--kendo-spacing-21, 5.25rem);
}

.\!k-py-21 {
  padding-block: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-py-22 {
  padding-block: var(--kendo-spacing-22, 5.5rem);
}

.\!k-py-22 {
  padding-block: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-py-23 {
  padding-block: var(--kendo-spacing-23, 5.75rem);
}

.\!k-py-23 {
  padding-block: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-py-24 {
  padding-block: var(--kendo-spacing-24, 6rem);
}

.\!k-py-24 {
  padding-block: var(--kendo-spacing-24, 6rem) !important;
}

.k-py-25 {
  padding-block: var(--kendo-spacing-25, 7rem);
}

.\!k-py-25 {
  padding-block: var(--kendo-spacing-25, 7rem) !important;
}

.k-py-26 {
  padding-block: var(--kendo-spacing-26, 8rem);
}

.\!k-py-26 {
  padding-block: var(--kendo-spacing-26, 8rem) !important;
}

.k-py-27 {
  padding-block: var(--kendo-spacing-27, 9rem);
}

.\!k-py-27 {
  padding-block: var(--kendo-spacing-27, 9rem) !important;
}

.k-py-28 {
  padding-block: var(--kendo-spacing-28, 10rem);
}

.\!k-py-28 {
  padding-block: var(--kendo-spacing-28, 10rem) !important;
}

.k-py-29 {
  padding-block: var(--kendo-spacing-29, 11rem);
}

.\!k-py-29 {
  padding-block: var(--kendo-spacing-29, 11rem) !important;
}

.k-py-30 {
  padding-block: var(--kendo-spacing-30, 12rem);
}

.\!k-py-30 {
  padding-block: var(--kendo-spacing-30, 12rem) !important;
}

.k-py-xs {
  padding-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-py-xs {
  padding-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-py-sm {
  padding-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-py-sm {
  padding-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-py-md {
  padding-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-py-md {
  padding-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-py-lg {
  padding-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-py-lg {
  padding-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-py-xl {
  padding-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-py-xl {
  padding-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-py-thin {
  padding-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-py-thin {
  padding-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-py-hair {
  padding-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-py-hair {
  padding-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-h-0 {
  height: 0px;
}

.\!k-h-0 {
  height: 0px !important;
}

.k-h-1px {
  height: 1px;
}

.\!k-h-1px {
  height: 1px !important;
}

.k-h-0\.5 {
  height: 0.125rem;
}

.\!k-h-0\.5 {
  height: 0.125rem !important;
}

.k-h-1 {
  height: 0.25rem;
}

.\!k-h-1 {
  height: 0.25rem !important;
}

.k-h-1\.5 {
  height: 0.375rem;
}

.\!k-h-1\.5 {
  height: 0.375rem !important;
}

.k-h-2 {
  height: 0.5rem;
}

.\!k-h-2 {
  height: 0.5rem !important;
}

.k-h-2\.5 {
  height: 0.625rem;
}

.\!k-h-2\.5 {
  height: 0.625rem !important;
}

.k-h-3 {
  height: 0.75rem;
}

.\!k-h-3 {
  height: 0.75rem !important;
}

.k-h-3\.5 {
  height: 0.875rem;
}

.\!k-h-3\.5 {
  height: 0.875rem !important;
}

.k-h-4 {
  height: 1rem;
}

.\!k-h-4 {
  height: 1rem !important;
}

.k-h-4\.5 {
  height: 1.125rem;
}

.\!k-h-4\.5 {
  height: 1.125rem !important;
}

.k-h-5 {
  height: 1.25rem;
}

.\!k-h-5 {
  height: 1.25rem !important;
}

.k-h-5\.5 {
  height: 1.375rem;
}

.\!k-h-5\.5 {
  height: 1.375rem !important;
}

.k-h-6 {
  height: 1.5rem;
}

.\!k-h-6 {
  height: 1.5rem !important;
}

.k-h-6\.5 {
  height: 1.625rem;
}

.\!k-h-6\.5 {
  height: 1.625rem !important;
}

.k-h-7 {
  height: 1.75rem;
}

.\!k-h-7 {
  height: 1.75rem !important;
}

.k-h-7\.5 {
  height: 1.875rem;
}

.\!k-h-7\.5 {
  height: 1.875rem !important;
}

.k-h-8 {
  height: 2rem;
}

.\!k-h-8 {
  height: 2rem !important;
}

.k-h-9 {
  height: 2.25rem;
}

.\!k-h-9 {
  height: 2.25rem !important;
}

.k-h-10 {
  height: 2.5rem;
}

.\!k-h-10 {
  height: 2.5rem !important;
}

.k-h-11 {
  height: 2.75rem;
}

.\!k-h-11 {
  height: 2.75rem !important;
}

.k-h-12 {
  height: 3rem;
}

.\!k-h-12 {
  height: 3rem !important;
}

.k-h-13 {
  height: 3.25rem;
}

.\!k-h-13 {
  height: 3.25rem !important;
}

.k-h-14 {
  height: 3.5rem;
}

.\!k-h-14 {
  height: 3.5rem !important;
}

.k-h-15 {
  height: 3.75rem;
}

.\!k-h-15 {
  height: 3.75rem !important;
}

.k-h-16 {
  height: 4rem;
}

.\!k-h-16 {
  height: 4rem !important;
}

.k-h-17 {
  height: 4.25rem;
}

.\!k-h-17 {
  height: 4.25rem !important;
}

.k-h-18 {
  height: 4.5rem;
}

.\!k-h-18 {
  height: 4.5rem !important;
}

.k-h-19 {
  height: 4.75rem;
}

.\!k-h-19 {
  height: 4.75rem !important;
}

.k-h-20 {
  height: 5rem;
}

.\!k-h-20 {
  height: 5rem !important;
}

.k-h-21 {
  height: 5.25rem;
}

.\!k-h-21 {
  height: 5.25rem !important;
}

.k-h-22 {
  height: 5.5rem;
}

.\!k-h-22 {
  height: 5.5rem !important;
}

.k-h-23 {
  height: 5.75rem;
}

.\!k-h-23 {
  height: 5.75rem !important;
}

.k-h-24 {
  height: 6rem;
}

.\!k-h-24 {
  height: 6rem !important;
}

.k-h-25 {
  height: 7rem;
}

.\!k-h-25 {
  height: 7rem !important;
}

.k-h-26 {
  height: 8rem;
}

.\!k-h-26 {
  height: 8rem !important;
}

.k-h-27 {
  height: 9rem;
}

.\!k-h-27 {
  height: 9rem !important;
}

.k-h-28 {
  height: 10rem;
}

.\!k-h-28 {
  height: 10rem !important;
}

.k-h-29 {
  height: 11rem;
}

.\!k-h-29 {
  height: 11rem !important;
}

.k-h-30 {
  height: 12rem;
}

.\!k-h-30 {
  height: 12rem !important;
}

.k-h-auto {
  height: auto;
}

.\!k-h-auto {
  height: auto !important;
}

.k-h-1\/2 {
  height: 50%;
}

.\!k-h-1\/2 {
  height: 50% !important;
}

.k-h-1\/3 {
  height: 33.333333%;
}

.\!k-h-1\/3 {
  height: 33.333333% !important;
}

.k-h-2\/3 {
  height: 66.666667%;
}

.\!k-h-2\/3 {
  height: 66.666667% !important;
}

.k-h-1\/4 {
  height: 25%;
}

.\!k-h-1\/4 {
  height: 25% !important;
}

.k-h-2\/4 {
  height: 50%;
}

.\!k-h-2\/4 {
  height: 50% !important;
}

.k-h-3\/4 {
  height: 75%;
}

.\!k-h-3\/4 {
  height: 75% !important;
}

.k-h-1\/5 {
  height: 20%;
}

.\!k-h-1\/5 {
  height: 20% !important;
}

.k-h-2\/5 {
  height: 40%;
}

.\!k-h-2\/5 {
  height: 40% !important;
}

.k-h-3\/5 {
  height: 60%;
}

.\!k-h-3\/5 {
  height: 60% !important;
}

.k-h-4\/5 {
  height: 80%;
}

.\!k-h-4\/5 {
  height: 80% !important;
}

.k-h-1\/6 {
  height: 16.666667%;
}

.\!k-h-1\/6 {
  height: 16.666667% !important;
}

.k-h-2\/6 {
  height: 33.333333%;
}

.\!k-h-2\/6 {
  height: 33.333333% !important;
}

.k-h-3\/6 {
  height: 50%;
}

.\!k-h-3\/6 {
  height: 50% !important;
}

.k-h-4\/6 {
  height: 66.666667%;
}

.\!k-h-4\/6 {
  height: 66.666667% !important;
}

.k-h-5\/6 {
  height: 83.333333%;
}

.\!k-h-5\/6 {
  height: 83.333333% !important;
}

.k-h-1\/12 {
  height: 8.333333%;
}

.\!k-h-1\/12 {
  height: 8.333333% !important;
}

.k-h-2\/12 {
  height: 16.666667%;
}

.\!k-h-2\/12 {
  height: 16.666667% !important;
}

.k-h-3\/12 {
  height: 25%;
}

.\!k-h-3\/12 {
  height: 25% !important;
}

.k-h-4\/12 {
  height: 33.333333%;
}

.\!k-h-4\/12 {
  height: 33.333333% !important;
}

.k-h-5\/12 {
  height: 41.666667%;
}

.\!k-h-5\/12 {
  height: 41.666667% !important;
}

.k-h-6\/12 {
  height: 50%;
}

.\!k-h-6\/12 {
  height: 50% !important;
}

.k-h-7\/12 {
  height: 58.333333%;
}

.\!k-h-7\/12 {
  height: 58.333333% !important;
}

.k-h-8\/12 {
  height: 66.666667%;
}

.\!k-h-8\/12 {
  height: 66.666667% !important;
}

.k-h-9\/12 {
  height: 75%;
}

.\!k-h-9\/12 {
  height: 75% !important;
}

.k-h-10\/12 {
  height: 83.333333%;
}

.\!k-h-10\/12 {
  height: 83.333333% !important;
}

.k-h-11\/12 {
  height: 91.666667%;
}

.\!k-h-11\/12 {
  height: 91.666667% !important;
}

.k-h-full {
  height: 100%;
}

.\!k-h-full {
  height: 100% !important;
}

.k-h-min {
  height: min-content;
}

.\!k-h-min {
  height: min-content !important;
}

.k-h-max {
  height: max-content;
}

.\!k-h-max {
  height: max-content !important;
}

.k-h-fit {
  height: fit-content;
}

.\!k-h-fit {
  height: fit-content !important;
}

.k-h-screen {
  height: 100vh;
}

.\!k-h-screen {
  height: 100vh !important;
}

.k-min-h-0 {
  min-height: 0;
}

.\!k-min-h-0 {
  min-height: 0 !important;
}

.k-min-h-full {
  min-height: 100%;
}

.\!k-min-h-full {
  min-height: 100% !important;
}

.k-min-h-screen {
  min-height: 100vh;
}

.\!k-min-h-screen {
  min-height: 100vh !important;
}

.k-min-h-min {
  min-height: min-content;
}

.\!k-min-h-min {
  min-height: min-content !important;
}

.k-min-h-max {
  min-height: max-content;
}

.\!k-min-h-max {
  min-height: max-content !important;
}

.k-min-h-fit {
  min-height: fit-content;
}

.\!k-min-h-fit {
  min-height: fit-content !important;
}

.k-max-h-none {
  max-height: none;
}

.\!k-max-h-none {
  max-height: none !important;
}

.k-max-h-0 {
  max-height: 0;
}

.\!k-max-h-0 {
  max-height: 0 !important;
}

.k-max-h-full {
  max-height: 100%;
}

.\!k-max-h-full {
  max-height: 100% !important;
}

.k-max-h-screen {
  max-height: 100vh;
}

.\!k-max-h-screen {
  max-height: 100vh !important;
}

.k-max-h-min {
  max-height: min-content;
}

.\!k-max-h-min {
  max-height: min-content !important;
}

.k-max-h-max {
  max-height: max-content;
}

.\!k-max-h-max {
  max-height: max-content !important;
}

.k-max-h-fit {
  max-height: fit-content;
}

.\!k-max-h-fit {
  max-height: fit-content !important;
}

.k-w-0 {
  width: 0px;
}

.\!k-w-0 {
  width: 0px !important;
}

.k-w-1px {
  width: 1px;
}

.\!k-w-1px {
  width: 1px !important;
}

.k-w-0\.5 {
  width: 0.125rem;
}

.\!k-w-0\.5 {
  width: 0.125rem !important;
}

.k-w-1 {
  width: 0.25rem;
}

.\!k-w-1 {
  width: 0.25rem !important;
}

.k-w-1\.5 {
  width: 0.375rem;
}

.\!k-w-1\.5 {
  width: 0.375rem !important;
}

.k-w-2 {
  width: 0.5rem;
}

.\!k-w-2 {
  width: 0.5rem !important;
}

.k-w-2\.5 {
  width: 0.625rem;
}

.\!k-w-2\.5 {
  width: 0.625rem !important;
}

.k-w-3 {
  width: 0.75rem;
}

.\!k-w-3 {
  width: 0.75rem !important;
}

.k-w-3\.5 {
  width: 0.875rem;
}

.\!k-w-3\.5 {
  width: 0.875rem !important;
}

.k-w-4 {
  width: 1rem;
}

.\!k-w-4 {
  width: 1rem !important;
}

.k-w-4\.5 {
  width: 1.125rem;
}

.\!k-w-4\.5 {
  width: 1.125rem !important;
}

.k-w-5 {
  width: 1.25rem;
}

.\!k-w-5 {
  width: 1.25rem !important;
}

.k-w-5\.5 {
  width: 1.375rem;
}

.\!k-w-5\.5 {
  width: 1.375rem !important;
}

.k-w-6 {
  width: 1.5rem;
}

.\!k-w-6 {
  width: 1.5rem !important;
}

.k-w-6\.5 {
  width: 1.625rem;
}

.\!k-w-6\.5 {
  width: 1.625rem !important;
}

.k-w-7 {
  width: 1.75rem;
}

.\!k-w-7 {
  width: 1.75rem !important;
}

.k-w-7\.5 {
  width: 1.875rem;
}

.\!k-w-7\.5 {
  width: 1.875rem !important;
}

.k-w-8 {
  width: 2rem;
}

.\!k-w-8 {
  width: 2rem !important;
}

.k-w-9 {
  width: 2.25rem;
}

.\!k-w-9 {
  width: 2.25rem !important;
}

.k-w-10 {
  width: 2.5rem;
}

.\!k-w-10 {
  width: 2.5rem !important;
}

.k-w-11 {
  width: 2.75rem;
}

.\!k-w-11 {
  width: 2.75rem !important;
}

.k-w-12 {
  width: 3rem;
}

.\!k-w-12 {
  width: 3rem !important;
}

.k-w-13 {
  width: 3.25rem;
}

.\!k-w-13 {
  width: 3.25rem !important;
}

.k-w-14 {
  width: 3.5rem;
}

.\!k-w-14 {
  width: 3.5rem !important;
}

.k-w-15 {
  width: 3.75rem;
}

.\!k-w-15 {
  width: 3.75rem !important;
}

.k-w-16 {
  width: 4rem;
}

.\!k-w-16 {
  width: 4rem !important;
}

.k-w-17 {
  width: 4.25rem;
}

.\!k-w-17 {
  width: 4.25rem !important;
}

.k-w-18 {
  width: 4.5rem;
}

.\!k-w-18 {
  width: 4.5rem !important;
}

.k-w-19 {
  width: 4.75rem;
}

.\!k-w-19 {
  width: 4.75rem !important;
}

.k-w-20 {
  width: 5rem;
}

.\!k-w-20 {
  width: 5rem !important;
}

.k-w-21 {
  width: 5.25rem;
}

.\!k-w-21 {
  width: 5.25rem !important;
}

.k-w-22 {
  width: 5.5rem;
}

.\!k-w-22 {
  width: 5.5rem !important;
}

.k-w-23 {
  width: 5.75rem;
}

.\!k-w-23 {
  width: 5.75rem !important;
}

.k-w-24 {
  width: 6rem;
}

.\!k-w-24 {
  width: 6rem !important;
}

.k-w-25 {
  width: 7rem;
}

.\!k-w-25 {
  width: 7rem !important;
}

.k-w-26 {
  width: 8rem;
}

.\!k-w-26 {
  width: 8rem !important;
}

.k-w-27 {
  width: 9rem;
}

.\!k-w-27 {
  width: 9rem !important;
}

.k-w-28 {
  width: 10rem;
}

.\!k-w-28 {
  width: 10rem !important;
}

.k-w-29 {
  width: 11rem;
}

.\!k-w-29 {
  width: 11rem !important;
}

.k-w-30 {
  width: 12rem;
}

.\!k-w-30 {
  width: 12rem !important;
}

.k-w-auto {
  width: auto;
}

.\!k-w-auto {
  width: auto !important;
}

.k-w-1\/2 {
  width: 50%;
}

.\!k-w-1\/2 {
  width: 50% !important;
}

.k-w-1\/3 {
  width: 33.333333%;
}

.\!k-w-1\/3 {
  width: 33.333333% !important;
}

.k-w-2\/3 {
  width: 66.666667%;
}

.\!k-w-2\/3 {
  width: 66.666667% !important;
}

.k-w-1\/4 {
  width: 25%;
}

.\!k-w-1\/4 {
  width: 25% !important;
}

.k-w-2\/4 {
  width: 50%;
}

.\!k-w-2\/4 {
  width: 50% !important;
}

.k-w-3\/4 {
  width: 75%;
}

.\!k-w-3\/4 {
  width: 75% !important;
}

.k-w-1\/5 {
  width: 20%;
}

.\!k-w-1\/5 {
  width: 20% !important;
}

.k-w-2\/5 {
  width: 40%;
}

.\!k-w-2\/5 {
  width: 40% !important;
}

.k-w-3\/5 {
  width: 60%;
}

.\!k-w-3\/5 {
  width: 60% !important;
}

.k-w-4\/5 {
  width: 80%;
}

.\!k-w-4\/5 {
  width: 80% !important;
}

.k-w-1\/6 {
  width: 16.666667%;
}

.\!k-w-1\/6 {
  width: 16.666667% !important;
}

.k-w-2\/6 {
  width: 33.333333%;
}

.\!k-w-2\/6 {
  width: 33.333333% !important;
}

.k-w-3\/6 {
  width: 50%;
}

.\!k-w-3\/6 {
  width: 50% !important;
}

.k-w-4\/6 {
  width: 66.666667%;
}

.\!k-w-4\/6 {
  width: 66.666667% !important;
}

.k-w-5\/6 {
  width: 83.333333%;
}

.\!k-w-5\/6 {
  width: 83.333333% !important;
}

.k-w-1\/12 {
  width: 8.333333%;
}

.\!k-w-1\/12 {
  width: 8.333333% !important;
}

.k-w-2\/12 {
  width: 16.666667%;
}

.\!k-w-2\/12 {
  width: 16.666667% !important;
}

.k-w-3\/12 {
  width: 25%;
}

.\!k-w-3\/12 {
  width: 25% !important;
}

.k-w-4\/12 {
  width: 33.333333%;
}

.\!k-w-4\/12 {
  width: 33.333333% !important;
}

.k-w-5\/12 {
  width: 41.666667%;
}

.\!k-w-5\/12 {
  width: 41.666667% !important;
}

.k-w-6\/12 {
  width: 50%;
}

.\!k-w-6\/12 {
  width: 50% !important;
}

.k-w-7\/12 {
  width: 58.333333%;
}

.\!k-w-7\/12 {
  width: 58.333333% !important;
}

.k-w-8\/12 {
  width: 66.666667%;
}

.\!k-w-8\/12 {
  width: 66.666667% !important;
}

.k-w-9\/12 {
  width: 75%;
}

.\!k-w-9\/12 {
  width: 75% !important;
}

.k-w-10\/12 {
  width: 83.333333%;
}

.\!k-w-10\/12 {
  width: 83.333333% !important;
}

.k-w-11\/12 {
  width: 91.666667%;
}

.\!k-w-11\/12 {
  width: 91.666667% !important;
}

.k-w-full {
  width: 100%;
}

.\!k-w-full {
  width: 100% !important;
}

.k-w-min {
  width: min-content;
}

.\!k-w-min {
  width: min-content !important;
}

.k-w-max {
  width: max-content;
}

.\!k-w-max {
  width: max-content !important;
}

.k-w-fit {
  width: fit-content;
}

.\!k-w-fit {
  width: fit-content !important;
}

.k-w-screen {
  width: 100vw;
}

.\!k-w-screen {
  width: 100vw !important;
}

.k-min-w-0 {
  min-width: 0;
}

.\!k-min-w-0 {
  min-width: 0 !important;
}

.k-min-w-full {
  min-width: 100%;
}

.\!k-min-w-full {
  min-width: 100% !important;
}

.k-min-w-screen {
  min-width: 100vw;
}

.\!k-min-w-screen {
  min-width: 100vw !important;
}

.k-min-w-min {
  min-width: min-content;
}

.\!k-min-w-min {
  min-width: min-content !important;
}

.k-min-w-max {
  min-width: max-content;
}

.\!k-min-w-max {
  min-width: max-content !important;
}

.k-min-w-fit {
  min-width: fit-content;
}

.\!k-min-w-fit {
  min-width: fit-content !important;
}

.k-max-w-none {
  max-width: none;
}

.\!k-max-w-none {
  max-width: none !important;
}

.k-max-w-0 {
  max-width: 0;
}

.\!k-max-w-0 {
  max-width: 0 !important;
}

.k-max-w-full {
  max-width: 100%;
}

.\!k-max-w-full {
  max-width: 100% !important;
}

.k-max-w-screen {
  max-width: 100vw;
}

.\!k-max-w-screen {
  max-width: 100vw !important;
}

.k-max-w-min {
  max-width: min-content;
}

.\!k-max-w-min {
  max-width: min-content !important;
}

.k-max-w-max {
  max-width: max-content;
}

.\!k-max-w-max {
  max-width: max-content !important;
}

.k-max-w-fit {
  max-width: fit-content;
}

.\!k-max-w-fit {
  max-width: fit-content !important;
}

.k-font-family-sans-serif {
  font-family: var(--kendo-font-family-sans-serif, Akkurat, sans-serif);
}

.\!k-font-family-sans-serif {
  font-family: var(--kendo-font-family-sans-serif, Akkurat, sans-serif) !important;
}

.k-font-family-monospace {
  font-family: var(--kendo-font-family-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace);
}

.\!k-font-family-monospace {
  font-family: var(--kendo-font-family-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace) !important;
}

.k-font-size-xs {
  font-size: var(--kendo-font-size-xs, 0.65625rem);
}

.\!k-font-size-xs {
  font-size: var(--kendo-font-size-xs, 0.65625rem) !important;
}

.k-font-size-sm {
  font-size: var(--kendo-font-size-sm, 0.765625rem);
}

.\!k-font-size-sm {
  font-size: var(--kendo-font-size-sm, 0.765625rem) !important;
}

.k-font-size-md {
  font-size: var(--kendo-font-size-md, 0.875rem);
}

.\!k-font-size-md {
  font-size: var(--kendo-font-size-md, 0.875rem) !important;
}

.k-font-size-lg {
  font-size: var(--kendo-font-size-lg, 1.09375rem);
}

.\!k-font-size-lg {
  font-size: var(--kendo-font-size-lg, 1.09375rem) !important;
}

.k-font-size-xl {
  font-size: var(--kendo-font-size-xl, 1.3125rem);
}

.\!k-font-size-xl {
  font-size: var(--kendo-font-size-xl, 1.3125rem) !important;
}

.k-fs-xs {
  font-size: var(--kendo-fs-xs, 0.65625rem);
}

.\!k-fs-xs {
  font-size: var(--kendo-fs-xs, 0.65625rem) !important;
}

.k-fs-sm {
  font-size: var(--kendo-fs-sm, 0.765625rem);
}

.\!k-fs-sm {
  font-size: var(--kendo-fs-sm, 0.765625rem) !important;
}

.k-fs-md {
  font-size: var(--kendo-fs-md, 0.875rem);
}

.\!k-fs-md {
  font-size: var(--kendo-fs-md, 0.875rem) !important;
}

.k-fs-lg {
  font-size: var(--kendo-fs-lg, 1.09375rem);
}

.\!k-fs-lg {
  font-size: var(--kendo-fs-lg, 1.09375rem) !important;
}

.k-fs-xl {
  font-size: var(--kendo-fs-xl, 1.3125rem);
}

.\!k-fs-xl {
  font-size: var(--kendo-fs-xl, 1.3125rem) !important;
}

.k-font-style-italic {
  font-style: var(--kendo-font-style-italic, italic);
}

.\!k-font-style-italic {
  font-style: var(--kendo-font-style-italic, italic) !important;
}

.k-font-style-non-italic {
  font-style: var(--kendo-font-style-non-italic, normal);
}

.\!k-font-style-non-italic {
  font-style: var(--kendo-font-style-non-italic, normal) !important;
}

.k-font-style-oblique {
  font-style: var(--kendo-font-style-oblique, oblique);
}

.\!k-font-style-oblique {
  font-style: var(--kendo-font-style-oblique, oblique) !important;
}

.k-font-light {
  font-weight: var(--kendo-font-weight-light, 300);
}

.\!k-font-light {
  font-weight: var(--kendo-font-weight-light, 300) !important;
}

.k-font-normal {
  font-weight: var(--kendo-font-weight-normal, 400);
}

.\!k-font-normal {
  font-weight: var(--kendo-font-weight-normal, 400) !important;
}

.k-font-medium {
  font-weight: var(--kendo-font-weight-medium, 500);
}

.\!k-font-medium {
  font-weight: var(--kendo-font-weight-medium, 500) !important;
}

.k-font-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600);
}

.\!k-font-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600) !important;
}

.k-font-bold {
  font-weight: var(--kendo-font-weight-bold, 600);
}

.\!k-font-bold {
  font-weight: var(--kendo-font-weight-bold, 600) !important;
}

.k-font-weight-light {
  font-weight: var(--kendo-font-weight-light, 300);
}

.\!k-font-weight-light {
  font-weight: var(--kendo-font-weight-light, 300) !important;
}

.k-font-weight-normal {
  font-weight: var(--kendo-font-weight-normal, 400);
}

.\!k-font-weight-normal {
  font-weight: var(--kendo-font-weight-normal, 400) !important;
}

.k-font-weight-medium {
  font-weight: var(--kendo-font-weight-medium, 500);
}

.\!k-font-weight-medium {
  font-weight: var(--kendo-font-weight-medium, 500) !important;
}

.k-font-weight-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600);
}

.\!k-font-weight-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600) !important;
}

.k-font-weight-bold {
  font-weight: var(--kendo-font-weight-bold, 600);
}

.\!k-font-weight-bold {
  font-weight: var(--kendo-font-weight-bold, 600) !important;
}

.k-letter-spacing-tightest {
  letter-spacing: var(--kendo-letter-spacing-tightest, -0.15px);
}

.\!k-letter-spacing-tightest {
  letter-spacing: var(--kendo-letter-spacing-tightest, -0.15px) !important;
}

.k-letter-spacing-tighter {
  letter-spacing: var(--kendo-letter-spacing-tighter, -0.1px);
}

.\!k-letter-spacing-tighter {
  letter-spacing: var(--kendo-letter-spacing-tighter, -0.1px) !important;
}

.k-letter-spacing-tight {
  letter-spacing: var(--kendo-letter-spacing-tight, -0.5px);
}

.\!k-letter-spacing-tight {
  letter-spacing: var(--kendo-letter-spacing-tight, -0.5px) !important;
}

.k-letter-spacing-normal {
  letter-spacing: var(--kendo-letter-spacing-normal, 0px);
}

.\!k-letter-spacing-normal {
  letter-spacing: var(--kendo-letter-spacing-normal, 0px) !important;
}

.k-letter-spacing-wide {
  letter-spacing: var(--kendo-letter-spacing-wide, 0.5px);
}

.\!k-letter-spacing-wide {
  letter-spacing: var(--kendo-letter-spacing-wide, 0.5px) !important;
}

.k-letter-spacing-wider {
  letter-spacing: var(--kendo-letter-spacing-wider, 0.1px);
}

.\!k-letter-spacing-wider {
  letter-spacing: var(--kendo-letter-spacing-wider, 0.1px) !important;
}

.k-letter-spacing-widest {
  letter-spacing: var(--kendo-letter-spacing-widest, 0.15px);
}

.\!k-letter-spacing-widest {
  letter-spacing: var(--kendo-letter-spacing-widest, 0.15px) !important;
}

.k-line-height-xs {
  line-height: var(--kendo-line-height-xs, 1);
}

.\!k-line-height-xs {
  line-height: var(--kendo-line-height-xs, 1) !important;
}

.k-line-height-sm {
  line-height: var(--kendo-line-height-sm, 1.25);
}

.\!k-line-height-sm {
  line-height: var(--kendo-line-height-sm, 1.25) !important;
}

.k-line-height-md {
  line-height: var(--kendo-line-height-md, 1.4285714286);
}

.\!k-line-height-md {
  line-height: var(--kendo-line-height-md, 1.4285714286) !important;
}

.k-line-height-lg {
  line-height: var(--kendo-line-height-lg, 2);
}

.\!k-line-height-lg {
  line-height: var(--kendo-line-height-lg, 2) !important;
}

.k-list-none {
  list-style-type: none;
}

.\!k-list-none {
  list-style-type: none !important;
}

.k-list-disc {
  list-style-type: disc;
}

.\!k-list-disc {
  list-style-type: disc !important;
}

.k-list-decimal {
  list-style-type: decimal;
}

.\!k-list-decimal {
  list-style-type: decimal !important;
}

.k-text-left {
  text-align: left;
}

.\!k-text-left {
  text-align: left !important;
}

.k-text-right {
  text-align: right;
}

.\!k-text-right {
  text-align: right !important;
}

.k-text-center {
  text-align: center;
}

.\!k-text-center {
  text-align: center !important;
}

.k-text-justify {
  text-align: justify;
}

.\!k-text-justify {
  text-align: justify !important;
}

.k-text-start {
  text-align: start;
}

.\!k-text-start {
  text-align: start !important;
}

.k-text-end {
  text-align: end;
}

.\!k-text-end {
  text-align: end !important;
}

.k-text-app-surface {
  color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-text-app-surface {
  color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-text-on-app-surface {
  color: var(--kendo-color-on-app-surface, #212529);
}

.\!k-text-on-app-surface {
  color: var(--kendo-color-on-app-surface, #212529) !important;
}

.k-text-subtle {
  color: var(--kendo-color-subtle, #596169);
}

.\!k-text-subtle {
  color: var(--kendo-color-subtle, #596169) !important;
}

.k-text-surface {
  color: var(--kendo-color-surface, #f8f9fa);
}

.\!k-text-surface {
  color: var(--kendo-color-surface, #f8f9fa) !important;
}

.k-text-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-text-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-text-border {
  color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.\!k-text-border {
  color: var(--kendo-color-border, rgba(33, 37, 41, 0.13)) !important;
}

.k-text-border-alt {
  color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2));
}

.\!k-text-border-alt {
  color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2)) !important;
}

.k-text-base-subtle {
  color: var(--kendo-color-base-subtle, #e9ecef);
}

.\!k-text-base-subtle {
  color: var(--kendo-color-base-subtle, #e9ecef) !important;
}

.k-text-base-emphasis {
  color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
}

.\!k-text-base-emphasis {
  color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) !important;
}

.k-text-primary-subtle {
  color: var(--kendo-color-primary-subtle, #cfe2ff);
}

.\!k-text-primary-subtle {
  color: var(--kendo-color-primary-subtle, #cfe2ff) !important;
}

.k-text-primary {
  color: var(--kendo-color-primary, #0d6efd);
}

.\!k-text-primary {
  color: var(--kendo-color-primary, #0d6efd) !important;
}

.k-text-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
}

.\!k-text-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4)) !important;
}

.k-text-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #dee2e6);
}

.\!k-text-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #dee2e6) !important;
}

.k-text-secondary {
  color: var(--kendo-color-secondary, #6c757d);
}

.\!k-text-secondary {
  color: var(--kendo-color-secondary, #6c757d) !important;
}

.k-text-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4));
}

.\!k-text-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4)) !important;
}

.k-text-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #e2d9f3);
}

.\!k-text-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #e2d9f3) !important;
}

.k-text-tertiary {
  color: var(--kendo-color-tertiary, #6f42c1);
}

.\!k-text-tertiary {
  color: var(--kendo-color-tertiary, #6f42c1) !important;
}

.k-text-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4));
}

.\!k-text-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4)) !important;
}

.k-text-info-subtle {
  color: var(--kendo-color-info-subtle, #cff4fc);
}

.\!k-text-info-subtle {
  color: var(--kendo-color-info-subtle, #cff4fc) !important;
}

.k-text-info {
  color: var(--kendo-color-info, #0dcaf0);
}

.\!k-text-info {
  color: var(--kendo-color-info, #0dcaf0) !important;
}

.k-text-info-emphasis {
  color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4));
}

.\!k-text-info-emphasis {
  color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4)) !important;
}

.k-text-success-subtle {
  color: var(--kendo-color-success-subtle, #d1e7dd);
}

.\!k-text-success-subtle {
  color: var(--kendo-color-success-subtle, #d1e7dd) !important;
}

.k-text-success {
  color: var(--kendo-color-success, #198754);
}

.\!k-text-success {
  color: var(--kendo-color-success, #198754) !important;
}

.k-text-success-emphasis {
  color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4));
}

.\!k-text-success-emphasis {
  color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4)) !important;
}

.k-text-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff3cd);
}

.\!k-text-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff3cd) !important;
}

.k-text-warning {
  color: var(--kendo-color-warning, #ffc107);
}

.\!k-text-warning {
  color: var(--kendo-color-warning, #ffc107) !important;
}

.k-text-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4));
}

.\!k-text-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4)) !important;
}

.k-text-error-subtle {
  color: var(--kendo-color-error-subtle, #f8d7da);
}

.\!k-text-error-subtle {
  color: var(--kendo-color-error-subtle, #f8d7da) !important;
}

.k-text-error {
  color: var(--kendo-color-error, #dc3545);
}

.\!k-text-error {
  color: var(--kendo-color-error, #dc3545) !important;
}

.k-text-error-emphasis {
  color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4));
}

.\!k-text-error-emphasis {
  color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4)) !important;
}

.k-text-light-subtle {
  color: var(--kendo-color-light-subtle, #ffffff);
}

.\!k-text-light-subtle {
  color: var(--kendo-color-light-subtle, #ffffff) !important;
}

.k-text-light {
  color: var(--kendo-color-light, #f8f9fa);
}

.\!k-text-light {
  color: var(--kendo-color-light, #f8f9fa) !important;
}

.k-text-light-emphasis {
  color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1));
}

.\!k-text-light-emphasis {
  color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1)) !important;
}

.k-text-dark-subtle {
  color: var(--kendo-color-dark-subtle, #495057);
}

.\!k-text-dark-subtle {
  color: var(--kendo-color-dark-subtle, #495057) !important;
}

.k-text-dark {
  color: var(--kendo-color-dark, #212529);
}

.\!k-text-dark {
  color: var(--kendo-color-dark, #212529) !important;
}

.k-text-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4));
}

.\!k-text-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4)) !important;
}

.k-text-inherit {
  color: var(--kendo-color-inherit, inherit);
}

.\!k-text-inherit {
  color: var(--kendo-color-inherit, inherit) !important;
}

.k-text-current {
  color: var(--kendo-color-current, currentColor);
}

.\!k-text-current {
  color: var(--kendo-color-current, currentColor) !important;
}

.k-text-transparent {
  color: var(--kendo-color-transparent, transparent);
}

.\!k-text-transparent {
  color: var(--kendo-color-transparent, transparent) !important;
}

.k-text-black {
  color: var(--kendo-color-black, black);
}

.\!k-text-black {
  color: var(--kendo-color-black, black) !important;
}

.k-text-white {
  color: var(--kendo-color-white, white);
}

.\!k-text-white {
  color: var(--kendo-color-white, white) !important;
}

.k-color-app-surface {
  color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-color-app-surface {
  color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-color-on-app-surface {
  color: var(--kendo-color-on-app-surface, #212529);
}

.\!k-color-on-app-surface {
  color: var(--kendo-color-on-app-surface, #212529) !important;
}

.k-color-subtle {
  color: var(--kendo-color-subtle, #596169);
}

.\!k-color-subtle {
  color: var(--kendo-color-subtle, #596169) !important;
}

.k-color-surface {
  color: var(--kendo-color-surface, #f8f9fa);
}

.\!k-color-surface {
  color: var(--kendo-color-surface, #f8f9fa) !important;
}

.k-color-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-color-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-color-border {
  color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.\!k-color-border {
  color: var(--kendo-color-border, rgba(33, 37, 41, 0.13)) !important;
}

.k-color-border-alt {
  color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2));
}

.\!k-color-border-alt {
  color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2)) !important;
}

.k-color-base-subtle {
  color: var(--kendo-color-base-subtle, #e9ecef);
}

.\!k-color-base-subtle {
  color: var(--kendo-color-base-subtle, #e9ecef) !important;
}

.k-color-base-emphasis {
  color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
}

.\!k-color-base-emphasis {
  color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) !important;
}

.k-color-primary-subtle {
  color: var(--kendo-color-primary-subtle, #cfe2ff);
}

.\!k-color-primary-subtle {
  color: var(--kendo-color-primary-subtle, #cfe2ff) !important;
}

.k-color-primary {
  color: var(--kendo-color-primary, #0d6efd);
}

.\!k-color-primary {
  color: var(--kendo-color-primary, #0d6efd) !important;
}

.k-color-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
}

.\!k-color-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4)) !important;
}

.k-color-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #dee2e6);
}

.\!k-color-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #dee2e6) !important;
}

.k-color-secondary {
  color: var(--kendo-color-secondary, #6c757d);
}

.\!k-color-secondary {
  color: var(--kendo-color-secondary, #6c757d) !important;
}

.k-color-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4));
}

.\!k-color-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4)) !important;
}

.k-color-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #e2d9f3);
}

.\!k-color-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #e2d9f3) !important;
}

.k-color-tertiary {
  color: var(--kendo-color-tertiary, #6f42c1);
}

.\!k-color-tertiary {
  color: var(--kendo-color-tertiary, #6f42c1) !important;
}

.k-color-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4));
}

.\!k-color-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4)) !important;
}

.k-color-info-subtle {
  color: var(--kendo-color-info-subtle, #cff4fc);
}

.\!k-color-info-subtle {
  color: var(--kendo-color-info-subtle, #cff4fc) !important;
}

.k-color-info {
  color: var(--kendo-color-info, #0dcaf0);
}

.\!k-color-info {
  color: var(--kendo-color-info, #0dcaf0) !important;
}

.k-color-info-emphasis {
  color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4));
}

.\!k-color-info-emphasis {
  color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4)) !important;
}

.k-color-success-subtle {
  color: var(--kendo-color-success-subtle, #d1e7dd);
}

.\!k-color-success-subtle {
  color: var(--kendo-color-success-subtle, #d1e7dd) !important;
}

.k-color-success {
  color: var(--kendo-color-success, #198754);
}

.\!k-color-success {
  color: var(--kendo-color-success, #198754) !important;
}

.k-color-success-emphasis {
  color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4));
}

.\!k-color-success-emphasis {
  color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4)) !important;
}

.k-color-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff3cd);
}

.\!k-color-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff3cd) !important;
}

.k-color-warning {
  color: var(--kendo-color-warning, #ffc107);
}

.\!k-color-warning {
  color: var(--kendo-color-warning, #ffc107) !important;
}

.k-color-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4));
}

.\!k-color-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4)) !important;
}

.k-color-error-subtle {
  color: var(--kendo-color-error-subtle, #f8d7da);
}

.\!k-color-error-subtle {
  color: var(--kendo-color-error-subtle, #f8d7da) !important;
}

.k-color-error {
  color: var(--kendo-color-error, #dc3545);
}

.\!k-color-error {
  color: var(--kendo-color-error, #dc3545) !important;
}

.k-color-error-emphasis {
  color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4));
}

.\!k-color-error-emphasis {
  color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4)) !important;
}

.k-color-light-subtle {
  color: var(--kendo-color-light-subtle, #ffffff);
}

.\!k-color-light-subtle {
  color: var(--kendo-color-light-subtle, #ffffff) !important;
}

.k-color-light {
  color: var(--kendo-color-light, #f8f9fa);
}

.\!k-color-light {
  color: var(--kendo-color-light, #f8f9fa) !important;
}

.k-color-light-emphasis {
  color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1));
}

.\!k-color-light-emphasis {
  color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1)) !important;
}

.k-color-dark-subtle {
  color: var(--kendo-color-dark-subtle, #495057);
}

.\!k-color-dark-subtle {
  color: var(--kendo-color-dark-subtle, #495057) !important;
}

.k-color-dark {
  color: var(--kendo-color-dark, #212529);
}

.\!k-color-dark {
  color: var(--kendo-color-dark, #212529) !important;
}

.k-color-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4));
}

.\!k-color-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4)) !important;
}

.k-color-inherit {
  color: var(--kendo-color-inherit, inherit);
}

.\!k-color-inherit {
  color: var(--kendo-color-inherit, inherit) !important;
}

.k-color-current {
  color: var(--kendo-color-current, currentColor);
}

.\!k-color-current {
  color: var(--kendo-color-current, currentColor) !important;
}

.k-color-transparent {
  color: var(--kendo-color-transparent, transparent);
}

.\!k-color-transparent {
  color: var(--kendo-color-transparent, transparent) !important;
}

.k-color-black {
  color: var(--kendo-color-black, black);
}

.\!k-color-black {
  color: var(--kendo-color-black, black) !important;
}

.k-color-white {
  color: var(--kendo-color-white, white);
}

.\!k-color-white {
  color: var(--kendo-color-white, white) !important;
}

.k-text-underline {
  text-decoration: underline;
}

.\!k-text-underline {
  text-decoration: underline !important;
}

.k-text-overline {
  text-decoration: overline;
}

.\!k-text-overline {
  text-decoration: overline !important;
}

.k-text-line-through {
  text-decoration: line-through;
}

.\!k-text-line-through {
  text-decoration: line-through !important;
}

.k-text-no-underline {
  text-decoration: none;
}

.\!k-text-no-underline {
  text-decoration: none !important;
}

.k-text-clip {
  text-overflow: clip;
}

.\!k-text-clip {
  text-overflow: clip !important;
}

.k-text-ellipsis {
  text-overflow: ellipsis;
}

.\!k-text-ellipsis {
  text-overflow: ellipsis !important;
}

.k-text-truncate, .k-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.\!k-text-truncate, .\!k-text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.k-text-uppercase {
  text-transform: uppercase;
}

.\!k-text-uppercase {
  text-transform: uppercase !important;
}

.k-text-lowercase {
  text-transform: lowercase;
}

.\!k-text-lowercase {
  text-transform: lowercase !important;
}

.k-text-capitalize {
  text-transform: capitalize;
}

.\!k-text-capitalize {
  text-transform: capitalize !important;
}

.k-text-normal-case {
  text-transform: none;
}

.\!k-text-normal-case {
  text-transform: none !important;
}

.k-align-baseline {
  vertical-align: baseline;
}

.\!k-align-baseline {
  vertical-align: baseline !important;
}

.k-align-top {
  vertical-align: top;
}

.\!k-align-top {
  vertical-align: top !important;
}

.k-align-middle {
  vertical-align: middle;
}

.\!k-align-middle {
  vertical-align: middle !important;
}

.k-align-bottom {
  vertical-align: bottom;
}

.\!k-align-bottom {
  vertical-align: bottom !important;
}

.k-align-text-top {
  vertical-align: text-top;
}

.\!k-align-text-top {
  vertical-align: text-top !important;
}

.k-align-text-bottom {
  vertical-align: text-bottom;
}

.\!k-align-text-bottom {
  vertical-align: text-bottom !important;
}

.k-align-sub {
  vertical-align: sub;
}

.\!k-align-sub {
  vertical-align: sub !important;
}

.k-align-super {
  vertical-align: super;
}

.\!k-align-super {
  vertical-align: super !important;
}

.k-white-space-normal {
  white-space: normal;
}

.\!k-white-space-normal {
  white-space: normal !important;
}

.k-white-space-nowrap, .k-text-nowrap {
  white-space: nowrap;
}

.\!k-white-space-nowrap {
  white-space: nowrap !important;
}

.k-white-space-pre {
  white-space: pre;
}

.\!k-white-space-pre {
  white-space: pre !important;
}

.k-white-space-pre-line {
  white-space: pre-line;
}

.\!k-white-space-pre-line {
  white-space: pre-line !important;
}

.k-white-space-pre-wrap {
  white-space: pre-wrap;
}

.\!k-white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.k-white-space-break-spaces {
  white-space: break-spaces;
}

.\!k-white-space-break-spaces {
  white-space: break-spaces !important;
}

.k-whitespace-normal {
  white-space: normal;
}

.\!k-whitespace-normal {
  white-space: normal !important;
}

.k-whitespace-nowrap {
  white-space: nowrap;
}

.\!k-whitespace-nowrap {
  white-space: nowrap !important;
}

.k-whitespace-pre {
  white-space: pre;
}

.\!k-whitespace-pre {
  white-space: pre !important;
}

.k-whitespace-pre-line {
  white-space: pre-line;
}

.\!k-whitespace-pre-line {
  white-space: pre-line !important;
}

.k-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.\!k-whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.k-whitespace-break-spaces {
  white-space: break-spaces;
}

.\!k-whitespace-break-spaces {
  white-space: break-spaces !important;
}

.k-bg-clip-border {
  background-clip: border-box;
}

.\!k-bg-clip-border {
  background-clip: border-box !important;
}

.k-bg-clip-padding {
  background-clip: padding-box;
}

.\!k-bg-clip-padding {
  background-clip: padding-box !important;
}

.k-bg-clip-content {
  background-clip: content-box;
}

.\!k-bg-clip-content {
  background-clip: content-box !important;
}

.k-bg-clip-text {
  background-clip: text;
}

.\!k-bg-clip-text {
  background-clip: text !important;
}

.k-bg-app-surface {
  background-color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-bg-app-surface {
  background-color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-bg-on-app-surface {
  background-color: var(--kendo-color-on-app-surface, #212529);
}

.\!k-bg-on-app-surface {
  background-color: var(--kendo-color-on-app-surface, #212529) !important;
}

.k-bg-subtle {
  background-color: var(--kendo-color-subtle, #596169);
}

.\!k-bg-subtle {
  background-color: var(--kendo-color-subtle, #596169) !important;
}

.k-bg-surface {
  background-color: var(--kendo-color-surface, #f8f9fa);
}

.\!k-bg-surface {
  background-color: var(--kendo-color-surface, #f8f9fa) !important;
}

.k-bg-surface-alt {
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-bg-surface-alt {
  background-color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-bg-border {
  background-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.\!k-bg-border {
  background-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13)) !important;
}

.k-bg-border-alt {
  background-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2));
}

.\!k-bg-border-alt {
  background-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2)) !important;
}

.k-bg-base-subtle {
  background-color: var(--kendo-color-base-subtle, #e9ecef);
}

.\!k-bg-base-subtle {
  background-color: var(--kendo-color-base-subtle, #e9ecef) !important;
}

.k-bg-base-emphasis {
  background-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
}

.\!k-bg-base-emphasis {
  background-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) !important;
}

.k-bg-primary-subtle {
  background-color: var(--kendo-color-primary-subtle, #cfe2ff);
}

.\!k-bg-primary-subtle {
  background-color: var(--kendo-color-primary-subtle, #cfe2ff) !important;
}

.k-bg-primary {
  background-color: var(--kendo-color-primary, #0d6efd);
}

.\!k-bg-primary {
  background-color: var(--kendo-color-primary, #0d6efd) !important;
}

.k-bg-primary-emphasis {
  background-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
}

.\!k-bg-primary-emphasis {
  background-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4)) !important;
}

.k-bg-secondary-subtle {
  background-color: var(--kendo-color-secondary-subtle, #dee2e6);
}

.\!k-bg-secondary-subtle {
  background-color: var(--kendo-color-secondary-subtle, #dee2e6) !important;
}

.k-bg-secondary {
  background-color: var(--kendo-color-secondary, #6c757d);
}

.\!k-bg-secondary {
  background-color: var(--kendo-color-secondary, #6c757d) !important;
}

.k-bg-secondary-emphasis {
  background-color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4));
}

.\!k-bg-secondary-emphasis {
  background-color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4)) !important;
}

.k-bg-tertiary-subtle {
  background-color: var(--kendo-color-tertiary-subtle, #e2d9f3);
}

.\!k-bg-tertiary-subtle {
  background-color: var(--kendo-color-tertiary-subtle, #e2d9f3) !important;
}

.k-bg-tertiary {
  background-color: var(--kendo-color-tertiary, #6f42c1);
}

.\!k-bg-tertiary {
  background-color: var(--kendo-color-tertiary, #6f42c1) !important;
}

.k-bg-tertiary-emphasis {
  background-color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4));
}

.\!k-bg-tertiary-emphasis {
  background-color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4)) !important;
}

.k-bg-info-subtle {
  background-color: var(--kendo-color-info-subtle, #cff4fc);
}

.\!k-bg-info-subtle {
  background-color: var(--kendo-color-info-subtle, #cff4fc) !important;
}

.k-bg-info {
  background-color: var(--kendo-color-info, #0dcaf0);
}

.\!k-bg-info {
  background-color: var(--kendo-color-info, #0dcaf0) !important;
}

.k-bg-info-emphasis {
  background-color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4));
}

.\!k-bg-info-emphasis {
  background-color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4)) !important;
}

.k-bg-success-subtle {
  background-color: var(--kendo-color-success-subtle, #d1e7dd);
}

.\!k-bg-success-subtle {
  background-color: var(--kendo-color-success-subtle, #d1e7dd) !important;
}

.k-bg-success {
  background-color: var(--kendo-color-success, #198754);
}

.\!k-bg-success {
  background-color: var(--kendo-color-success, #198754) !important;
}

.k-bg-success-emphasis {
  background-color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4));
}

.\!k-bg-success-emphasis {
  background-color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4)) !important;
}

.k-bg-warning-subtle {
  background-color: var(--kendo-color-warning-subtle, #fff3cd);
}

.\!k-bg-warning-subtle {
  background-color: var(--kendo-color-warning-subtle, #fff3cd) !important;
}

.k-bg-warning {
  background-color: var(--kendo-color-warning, #ffc107);
}

.\!k-bg-warning {
  background-color: var(--kendo-color-warning, #ffc107) !important;
}

.k-bg-warning-emphasis {
  background-color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4));
}

.\!k-bg-warning-emphasis {
  background-color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4)) !important;
}

.k-bg-error-subtle {
  background-color: var(--kendo-color-error-subtle, #f8d7da);
}

.\!k-bg-error-subtle {
  background-color: var(--kendo-color-error-subtle, #f8d7da) !important;
}

.k-bg-error {
  background-color: var(--kendo-color-error, #dc3545);
}

.\!k-bg-error {
  background-color: var(--kendo-color-error, #dc3545) !important;
}

.k-bg-error-emphasis {
  background-color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4));
}

.\!k-bg-error-emphasis {
  background-color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4)) !important;
}

.k-bg-light-subtle {
  background-color: var(--kendo-color-light-subtle, #ffffff);
}

.\!k-bg-light-subtle {
  background-color: var(--kendo-color-light-subtle, #ffffff) !important;
}

.k-bg-light {
  background-color: var(--kendo-color-light, #f8f9fa);
}

.\!k-bg-light {
  background-color: var(--kendo-color-light, #f8f9fa) !important;
}

.k-bg-light-emphasis {
  background-color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1));
}

.\!k-bg-light-emphasis {
  background-color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1)) !important;
}

.k-bg-dark-subtle {
  background-color: var(--kendo-color-dark-subtle, #495057);
}

.\!k-bg-dark-subtle {
  background-color: var(--kendo-color-dark-subtle, #495057) !important;
}

.k-bg-dark {
  background-color: var(--kendo-color-dark, #212529);
}

.\!k-bg-dark {
  background-color: var(--kendo-color-dark, #212529) !important;
}

.k-bg-dark-emphasis {
  background-color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4));
}

.\!k-bg-dark-emphasis {
  background-color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4)) !important;
}

.k-bg-inherit {
  background-color: var(--kendo-color-inherit, inherit);
}

.\!k-bg-inherit {
  background-color: var(--kendo-color-inherit, inherit) !important;
}

.k-bg-transparent {
  background-color: var(--kendo-color-transparent, transparent);
}

.\!k-bg-transparent {
  background-color: var(--kendo-color-transparent, transparent) !important;
}

.k-bg-black {
  background-color: var(--kendo-color-black, black);
}

.\!k-bg-black {
  background-color: var(--kendo-color-black, black) !important;
}

.k-bg-white {
  background-color: var(--kendo-color-white, white);
}

.\!k-bg-white {
  background-color: var(--kendo-color-white, white) !important;
}

.k-bg-center {
  background-position: center;
}

.\!k-bg-center {
  background-position: center !important;
}

.k-bg-top {
  background-position: top;
}

.\!k-bg-top {
  background-position: top !important;
}

.k-bg-right {
  background-position: right;
}

.\!k-bg-right {
  background-position: right !important;
}

.k-bg-bottom {
  background-position: bottom;
}

.\!k-bg-bottom {
  background-position: bottom !important;
}

.k-bg-left {
  background-position: left;
}

.\!k-bg-left {
  background-position: left !important;
}

.k-bg-top-left {
  background-position: top left;
}

.\!k-bg-top-left {
  background-position: top left !important;
}

.k-bg-top-right {
  background-position: top right;
}

.\!k-bg-top-right {
  background-position: top right !important;
}

.k-bg-bottom-left {
  background-position: bottom left;
}

.\!k-bg-bottom-left {
  background-position: bottom left !important;
}

.k-bg-bottom-right {
  background-position: bottom right;
}

.\!k-bg-bottom-right {
  background-position: bottom right !important;
}

.k-bg-auto {
  background-size: auto;
}

.\!k-bg-auto {
  background-size: auto !important;
}

.k-bg-cover {
  background-size: cover;
}

.\!k-bg-cover {
  background-size: cover !important;
}

.k-bg-contain {
  background-size: contain;
}

.\!k-bg-contain {
  background-size: contain !important;
}

.k-border-app-surface {
  border-color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-border-app-surface {
  border-color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-border-on-app-surface {
  border-color: var(--kendo-color-on-app-surface, #212529);
}

.\!k-border-on-app-surface {
  border-color: var(--kendo-color-on-app-surface, #212529) !important;
}

.k-border-subtle {
  border-color: var(--kendo-color-subtle, #596169);
}

.\!k-border-subtle {
  border-color: var(--kendo-color-subtle, #596169) !important;
}

.k-border-surface {
  border-color: var(--kendo-color-surface, #f8f9fa);
}

.\!k-border-surface {
  border-color: var(--kendo-color-surface, #f8f9fa) !important;
}

.k-border-surface-alt {
  border-color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-border-surface-alt {
  border-color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-border-border {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.\!k-border-border {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13)) !important;
}

.k-border-border-alt {
  border-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2));
}

.\!k-border-border-alt {
  border-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2)) !important;
}

.k-border-base-subtle {
  border-color: var(--kendo-color-base-subtle, #e9ecef);
}

.\!k-border-base-subtle {
  border-color: var(--kendo-color-base-subtle, #e9ecef) !important;
}

.k-border-base-emphasis {
  border-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
}

.\!k-border-base-emphasis {
  border-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) !important;
}

.k-border-primary-subtle {
  border-color: var(--kendo-color-primary-subtle, #cfe2ff);
}

.\!k-border-primary-subtle {
  border-color: var(--kendo-color-primary-subtle, #cfe2ff) !important;
}

.k-border-primary {
  border-color: var(--kendo-color-primary, #0d6efd);
}

.\!k-border-primary {
  border-color: var(--kendo-color-primary, #0d6efd) !important;
}

.k-border-primary-emphasis {
  border-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
}

.\!k-border-primary-emphasis {
  border-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4)) !important;
}

.k-border-secondary-subtle {
  border-color: var(--kendo-color-secondary-subtle, #dee2e6);
}

.\!k-border-secondary-subtle {
  border-color: var(--kendo-color-secondary-subtle, #dee2e6) !important;
}

.k-border-secondary {
  border-color: var(--kendo-color-secondary, #6c757d);
}

.\!k-border-secondary {
  border-color: var(--kendo-color-secondary, #6c757d) !important;
}

.k-border-secondary-emphasis {
  border-color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4));
}

.\!k-border-secondary-emphasis {
  border-color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4)) !important;
}

.k-border-tertiary-subtle {
  border-color: var(--kendo-color-tertiary-subtle, #e2d9f3);
}

.\!k-border-tertiary-subtle {
  border-color: var(--kendo-color-tertiary-subtle, #e2d9f3) !important;
}

.k-border-tertiary {
  border-color: var(--kendo-color-tertiary, #6f42c1);
}

.\!k-border-tertiary {
  border-color: var(--kendo-color-tertiary, #6f42c1) !important;
}

.k-border-tertiary-emphasis {
  border-color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4));
}

.\!k-border-tertiary-emphasis {
  border-color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4)) !important;
}

.k-border-info-subtle {
  border-color: var(--kendo-color-info-subtle, #cff4fc);
}

.\!k-border-info-subtle {
  border-color: var(--kendo-color-info-subtle, #cff4fc) !important;
}

.k-border-info {
  border-color: var(--kendo-color-info, #0dcaf0);
}

.\!k-border-info {
  border-color: var(--kendo-color-info, #0dcaf0) !important;
}

.k-border-info-emphasis {
  border-color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4));
}

.\!k-border-info-emphasis {
  border-color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4)) !important;
}

.k-border-success-subtle {
  border-color: var(--kendo-color-success-subtle, #d1e7dd);
}

.\!k-border-success-subtle {
  border-color: var(--kendo-color-success-subtle, #d1e7dd) !important;
}

.k-border-success {
  border-color: var(--kendo-color-success, #198754);
}

.\!k-border-success {
  border-color: var(--kendo-color-success, #198754) !important;
}

.k-border-success-emphasis {
  border-color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4));
}

.\!k-border-success-emphasis {
  border-color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4)) !important;
}

.k-border-warning-subtle {
  border-color: var(--kendo-color-warning-subtle, #fff3cd);
}

.\!k-border-warning-subtle {
  border-color: var(--kendo-color-warning-subtle, #fff3cd) !important;
}

.k-border-warning {
  border-color: var(--kendo-color-warning, #ffc107);
}

.\!k-border-warning {
  border-color: var(--kendo-color-warning, #ffc107) !important;
}

.k-border-warning-emphasis {
  border-color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4));
}

.\!k-border-warning-emphasis {
  border-color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4)) !important;
}

.k-border-error-subtle {
  border-color: var(--kendo-color-error-subtle, #f8d7da);
}

.\!k-border-error-subtle {
  border-color: var(--kendo-color-error-subtle, #f8d7da) !important;
}

.k-border-error {
  border-color: var(--kendo-color-error, #dc3545);
}

.\!k-border-error {
  border-color: var(--kendo-color-error, #dc3545) !important;
}

.k-border-error-emphasis {
  border-color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4));
}

.\!k-border-error-emphasis {
  border-color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4)) !important;
}

.k-border-light-subtle {
  border-color: var(--kendo-color-light-subtle, #ffffff);
}

.\!k-border-light-subtle {
  border-color: var(--kendo-color-light-subtle, #ffffff) !important;
}

.k-border-light {
  border-color: var(--kendo-color-light, #f8f9fa);
}

.\!k-border-light {
  border-color: var(--kendo-color-light, #f8f9fa) !important;
}

.k-border-light-emphasis {
  border-color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1));
}

.\!k-border-light-emphasis {
  border-color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1)) !important;
}

.k-border-dark-subtle {
  border-color: var(--kendo-color-dark-subtle, #495057);
}

.\!k-border-dark-subtle {
  border-color: var(--kendo-color-dark-subtle, #495057) !important;
}

.k-border-dark {
  border-color: var(--kendo-color-dark, #212529);
}

.\!k-border-dark {
  border-color: var(--kendo-color-dark, #212529) !important;
}

.k-border-dark-emphasis {
  border-color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4));
}

.\!k-border-dark-emphasis {
  border-color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4)) !important;
}

.k-border-inherit {
  border-color: var(--kendo-color-inherit, inherit);
}

.\!k-border-inherit {
  border-color: var(--kendo-color-inherit, inherit) !important;
}

.k-border-current {
  border-color: var(--kendo-color-current, currentColor);
}

.\!k-border-current {
  border-color: var(--kendo-color-current, currentColor) !important;
}

.k-border-transparent {
  border-color: var(--kendo-color-transparent, transparent);
}

.\!k-border-transparent {
  border-color: var(--kendo-color-transparent, transparent) !important;
}

.k-border-black {
  border-color: var(--kendo-color-black, black);
}

.\!k-border-black {
  border-color: var(--kendo-color-black, black) !important;
}

.k-border-white {
  border-color: var(--kendo-color-white, white);
}

.\!k-border-white {
  border-color: var(--kendo-color-white, white) !important;
}

.k-rounded-none {
  border-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-none {
  border-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-xs {
  border-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-xs {
  border-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-sm {
  border-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-sm {
  border-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-md {
  border-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-md {
  border-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-lg {
  border-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-lg {
  border-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-xl {
  border-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-xl {
  border-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-xxl {
  border-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-xxl {
  border-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-xxxl {
  border-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-xxxl {
  border-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-full {
  border-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-full {
  border-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-tl-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-tl-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-tl-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-tl-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-tl-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-tl-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-tl-md {
  border-top-left-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-tl-md {
  border-top-left-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-tl-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-tl-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-tl-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-tl-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-tl-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-tl-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-tl-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-tl-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-tl-full {
  border-top-left-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-tl-full {
  border-top-left-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-tr-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-tr-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-tr-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-tr-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-tr-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-tr-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-tr-md {
  border-top-right-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-tr-md {
  border-top-right-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-tr-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-tr-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-tr-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-tr-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-tr-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-tr-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-tr-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-tr-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-tr-full {
  border-top-right-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-tr-full {
  border-top-right-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-br-none {
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-br-none {
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-br-xs {
  border-bottom-right-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-br-xs {
  border-bottom-right-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-br-sm {
  border-bottom-right-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-br-sm {
  border-bottom-right-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-br-md {
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-br-md {
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-br-lg {
  border-bottom-right-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-br-lg {
  border-bottom-right-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-br-xl {
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-br-xl {
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-br-xxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-br-xxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-br-xxxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-br-xxxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-br-full {
  border-bottom-right-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-br-full {
  border-bottom-right-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-bl-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-bl-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-bl-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-bl-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-bl-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-bl-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-bl-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-bl-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-bl-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-bl-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-bl-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-bl-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-bl-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-bl-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-bl-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-bl-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-bl-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-bl-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-t-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px);
  border-top-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-t-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px) !important;
  border-top-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-t-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 0.125rem);
  border-top-right-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-t-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
  border-top-right-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-t-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 2px);
  border-top-right-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-t-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 2px) !important;
  border-top-right-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-t-md {
  border-top-left-radius: var(--kendo-border-radius-md, 3px);
  border-top-right-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-t-md {
  border-top-left-radius: var(--kendo-border-radius-md, 3px) !important;
  border-top-right-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-t-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 10px);
  border-top-right-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-t-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 10px) !important;
  border-top-right-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-t-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.75rem);
  border-top-right-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-t-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
  border-top-right-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-t-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 1rem);
  border-top-right-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-t-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 1rem) !important;
  border-top-right-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-t-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 2rem);
  border-top-right-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-t-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
  border-top-right-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-t-full {
  border-top-left-radius: var(--kendo-border-radius-full, 50rem);
  border-top-right-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-t-full {
  border-top-left-radius: var(--kendo-border-radius-full, 50rem) !important;
  border-top-right-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-r-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px);
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-r-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-r-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 0.125rem);
  border-bottom-right-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-r-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-r-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 2px);
  border-bottom-right-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-r-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 2px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-r-md {
  border-top-right-radius: var(--kendo-border-radius-md, 3px);
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-r-md {
  border-top-right-radius: var(--kendo-border-radius-md, 3px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-r-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 10px);
  border-bottom-right-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-r-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 10px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-r-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.75rem);
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-r-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-r-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 1rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-r-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 1rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-r-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 2rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-r-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-r-full {
  border-top-right-radius: var(--kendo-border-radius-full, 50rem);
  border-bottom-right-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-r-full {
  border-top-right-radius: var(--kendo-border-radius-full, 50rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-b-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px);
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-b-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-b-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 0.125rem);
  border-bottom-right-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-b-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-b-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 2px);
  border-bottom-right-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-b-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 2px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-b-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px);
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-b-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-b-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 10px);
  border-bottom-right-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-b-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 10px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-b-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.75rem);
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-b-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-b-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 1rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-b-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 1rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-b-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 2rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-b-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-b-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 50rem);
  border-bottom-right-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-b-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 50rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-rounded-l-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px);
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-l-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px) !important;
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-l-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 0.125rem);
  border-bottom-left-radius: var(--kendo-border-radius-xs, 0.125rem);
}

.\!k-rounded-l-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xs, 0.125rem) !important;
}

.k-rounded-l-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 2px);
  border-bottom-left-radius: var(--kendo-border-radius-sm, 2px);
}

.\!k-rounded-l-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 2px) !important;
  border-bottom-left-radius: var(--kendo-border-radius-sm, 2px) !important;
}

.k-rounded-l-md {
  border-top-left-radius: var(--kendo-border-radius-md, 3px);
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px);
}

.\!k-rounded-l-md {
  border-top-left-radius: var(--kendo-border-radius-md, 3px) !important;
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px) !important;
}

.k-rounded-l-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 10px);
  border-bottom-left-radius: var(--kendo-border-radius-lg, 10px);
}

.\!k-rounded-l-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 10px) !important;
  border-bottom-left-radius: var(--kendo-border-radius-lg, 10px) !important;
}

.k-rounded-l-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.75rem);
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.75rem);
}

.\!k-rounded-l-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.75rem) !important;
}

.k-rounded-l-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 1rem);
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 1rem);
}

.\!k-rounded-l-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 1rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 1rem) !important;
}

.k-rounded-l-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 2rem);
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 2rem);
}

.\!k-rounded-l-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 2rem) !important;
}

.k-rounded-l-full {
  border-top-left-radius: var(--kendo-border-radius-full, 50rem);
  border-bottom-left-radius: var(--kendo-border-radius-full, 50rem);
}

.\!k-rounded-l-full {
  border-top-left-radius: var(--kendo-border-radius-full, 50rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-full, 50rem) !important;
}

.k-border-solid {
  border-style: solid;
}

.\!k-border-solid {
  border-style: solid !important;
}

.k-border-dashed {
  border-style: dashed;
}

.\!k-border-dashed {
  border-style: dashed !important;
}

.k-border-dotted {
  border-style: dotted;
}

.\!k-border-dotted {
  border-style: dotted !important;
}

.k-border-double {
  border-style: double;
}

.\!k-border-double {
  border-style: double !important;
}

.k-border-inset {
  border-style: inset;
}

.\!k-border-inset {
  border-style: inset !important;
}

.k-border-outset {
  border-style: outset;
}

.\!k-border-outset {
  border-style: outset !important;
}

.k-border-hidden {
  border-style: hidden;
}

.\!k-border-hidden {
  border-style: hidden !important;
}

.k-border-none {
  border-style: none;
}

.\!k-border-none {
  border-style: none !important;
}

.k-border-t-solid {
  border-top-style: solid;
}

.\!k-border-t-solid {
  border-top-style: solid !important;
}

.k-border-t-dashed {
  border-top-style: dashed;
}

.\!k-border-t-dashed {
  border-top-style: dashed !important;
}

.k-border-t-dotted {
  border-top-style: dotted;
}

.\!k-border-t-dotted {
  border-top-style: dotted !important;
}

.k-border-t-double {
  border-top-style: double;
}

.\!k-border-t-double {
  border-top-style: double !important;
}

.k-border-t-inset {
  border-top-style: inset;
}

.\!k-border-t-inset {
  border-top-style: inset !important;
}

.k-border-t-outset {
  border-top-style: outset;
}

.\!k-border-t-outset {
  border-top-style: outset !important;
}

.k-border-t-hidden {
  border-top-style: hidden;
}

.\!k-border-t-hidden {
  border-top-style: hidden !important;
}

.k-border-t-none {
  border-top-style: none;
}

.\!k-border-t-none {
  border-top-style: none !important;
}

.k-border-r-solid {
  border-right-style: solid;
}

.\!k-border-r-solid {
  border-right-style: solid !important;
}

.k-border-r-dashed {
  border-right-style: dashed;
}

.\!k-border-r-dashed {
  border-right-style: dashed !important;
}

.k-border-r-dotted {
  border-right-style: dotted;
}

.\!k-border-r-dotted {
  border-right-style: dotted !important;
}

.k-border-r-double {
  border-right-style: double;
}

.\!k-border-r-double {
  border-right-style: double !important;
}

.k-border-r-inset {
  border-right-style: inset;
}

.\!k-border-r-inset {
  border-right-style: inset !important;
}

.k-border-r-outset {
  border-right-style: outset;
}

.\!k-border-r-outset {
  border-right-style: outset !important;
}

.k-border-r-hidden {
  border-right-style: hidden;
}

.\!k-border-r-hidden {
  border-right-style: hidden !important;
}

.k-border-r-none {
  border-right-style: none;
}

.\!k-border-r-none {
  border-right-style: none !important;
}

.k-border-b-solid {
  border-bottom-style: solid;
}

.\!k-border-b-solid {
  border-bottom-style: solid !important;
}

.k-border-b-dashed {
  border-bottom-style: dashed;
}

.\!k-border-b-dashed {
  border-bottom-style: dashed !important;
}

.k-border-b-dotted {
  border-bottom-style: dotted;
}

.\!k-border-b-dotted {
  border-bottom-style: dotted !important;
}

.k-border-b-double {
  border-bottom-style: double;
}

.\!k-border-b-double {
  border-bottom-style: double !important;
}

.k-border-b-inset {
  border-bottom-style: inset;
}

.\!k-border-b-inset {
  border-bottom-style: inset !important;
}

.k-border-b-outset {
  border-bottom-style: outset;
}

.\!k-border-b-outset {
  border-bottom-style: outset !important;
}

.k-border-b-hidden {
  border-bottom-style: hidden;
}

.\!k-border-b-hidden {
  border-bottom-style: hidden !important;
}

.k-border-b-none {
  border-bottom-style: none;
}

.\!k-border-b-none {
  border-bottom-style: none !important;
}

.k-border-l-solid {
  border-left-style: solid;
}

.\!k-border-l-solid {
  border-left-style: solid !important;
}

.k-border-l-dashed {
  border-left-style: dashed;
}

.\!k-border-l-dashed {
  border-left-style: dashed !important;
}

.k-border-l-dotted {
  border-left-style: dotted;
}

.\!k-border-l-dotted {
  border-left-style: dotted !important;
}

.k-border-l-double {
  border-left-style: double;
}

.\!k-border-l-double {
  border-left-style: double !important;
}

.k-border-l-inset {
  border-left-style: inset;
}

.\!k-border-l-inset {
  border-left-style: inset !important;
}

.k-border-l-outset {
  border-left-style: outset;
}

.\!k-border-l-outset {
  border-left-style: outset !important;
}

.k-border-l-hidden {
  border-left-style: hidden;
}

.\!k-border-l-hidden {
  border-left-style: hidden !important;
}

.k-border-l-none {
  border-left-style: none;
}

.\!k-border-l-none {
  border-left-style: none !important;
}

.k-border-x-solid {
  border-inline-style: solid;
}

.\!k-border-x-solid {
  border-inline-style: solid !important;
}

.k-border-x-dashed {
  border-inline-style: dashed;
}

.\!k-border-x-dashed {
  border-inline-style: dashed !important;
}

.k-border-x-dotted {
  border-inline-style: dotted;
}

.\!k-border-x-dotted {
  border-inline-style: dotted !important;
}

.k-border-x-double {
  border-inline-style: double;
}

.\!k-border-x-double {
  border-inline-style: double !important;
}

.k-border-x-inset {
  border-inline-style: inset;
}

.\!k-border-x-inset {
  border-inline-style: inset !important;
}

.k-border-x-outset {
  border-inline-style: outset;
}

.\!k-border-x-outset {
  border-inline-style: outset !important;
}

.k-border-x-hidden {
  border-inline-style: hidden;
}

.\!k-border-x-hidden {
  border-inline-style: hidden !important;
}

.k-border-x-none {
  border-inline-style: none;
}

.\!k-border-x-none {
  border-inline-style: none !important;
}

.k-border-y-solid {
  border-block-style: solid;
}

.\!k-border-y-solid {
  border-block-style: solid !important;
}

.k-border-y-dashed {
  border-block-style: dashed;
}

.\!k-border-y-dashed {
  border-block-style: dashed !important;
}

.k-border-y-dotted {
  border-block-style: dotted;
}

.\!k-border-y-dotted {
  border-block-style: dotted !important;
}

.k-border-y-double {
  border-block-style: double;
}

.\!k-border-y-double {
  border-block-style: double !important;
}

.k-border-y-inset {
  border-block-style: inset;
}

.\!k-border-y-inset {
  border-block-style: inset !important;
}

.k-border-y-outset {
  border-block-style: outset;
}

.\!k-border-y-outset {
  border-block-style: outset !important;
}

.k-border-y-hidden {
  border-block-style: hidden;
}

.\!k-border-y-hidden {
  border-block-style: hidden !important;
}

.k-border-y-none {
  border-block-style: none;
}

.\!k-border-y-none {
  border-block-style: none !important;
}

.k-border {
  border-width: 1px;
}

.\!k-border {
  border-width: 1px !important;
}

.k-border-0 {
  border-width: 0;
}

.\!k-border-0 {
  border-width: 0 !important;
}

.k-border-2 {
  border-width: 2px;
}

.\!k-border-2 {
  border-width: 2px !important;
}

.k-border-4 {
  border-width: 4px;
}

.\!k-border-4 {
  border-width: 4px !important;
}

.k-border-8 {
  border-width: 8px;
}

.\!k-border-8 {
  border-width: 8px !important;
}

.k-border-t {
  border-top-width: 1px;
}

.\!k-border-t {
  border-top-width: 1px !important;
}

.k-border-t-0 {
  border-top-width: 0;
}

.\!k-border-t-0 {
  border-top-width: 0 !important;
}

.k-border-t-2 {
  border-top-width: 2px;
}

.\!k-border-t-2 {
  border-top-width: 2px !important;
}

.k-border-t-4 {
  border-top-width: 4px;
}

.\!k-border-t-4 {
  border-top-width: 4px !important;
}

.k-border-t-8 {
  border-top-width: 8px;
}

.\!k-border-t-8 {
  border-top-width: 8px !important;
}

.k-border-r {
  border-right-width: 1px;
}

.\!k-border-r {
  border-right-width: 1px !important;
}

.k-border-r-0 {
  border-right-width: 0;
}

.\!k-border-r-0 {
  border-right-width: 0 !important;
}

.k-border-r-2 {
  border-right-width: 2px;
}

.\!k-border-r-2 {
  border-right-width: 2px !important;
}

.k-border-r-4 {
  border-right-width: 4px;
}

.\!k-border-r-4 {
  border-right-width: 4px !important;
}

.k-border-r-8 {
  border-right-width: 8px;
}

.\!k-border-r-8 {
  border-right-width: 8px !important;
}

.k-border-b {
  border-bottom-width: 1px;
}

.\!k-border-b {
  border-bottom-width: 1px !important;
}

.k-border-b-0 {
  border-bottom-width: 0;
}

.\!k-border-b-0 {
  border-bottom-width: 0 !important;
}

.k-border-b-2 {
  border-bottom-width: 2px;
}

.\!k-border-b-2 {
  border-bottom-width: 2px !important;
}

.k-border-b-4 {
  border-bottom-width: 4px;
}

.\!k-border-b-4 {
  border-bottom-width: 4px !important;
}

.k-border-b-8 {
  border-bottom-width: 8px;
}

.\!k-border-b-8 {
  border-bottom-width: 8px !important;
}

.k-border-l {
  border-left-width: 1px;
}

.\!k-border-l {
  border-left-width: 1px !important;
}

.k-border-l-0 {
  border-left-width: 0;
}

.\!k-border-l-0 {
  border-left-width: 0 !important;
}

.k-border-l-2 {
  border-left-width: 2px;
}

.\!k-border-l-2 {
  border-left-width: 2px !important;
}

.k-border-l-4 {
  border-left-width: 4px;
}

.\!k-border-l-4 {
  border-left-width: 4px !important;
}

.k-border-l-8 {
  border-left-width: 8px;
}

.\!k-border-l-8 {
  border-left-width: 8px !important;
}

.k-border-x {
  border-inline-width: 1px;
}

.\!k-border-x {
  border-inline-width: 1px !important;
}

.k-border-x-0 {
  border-inline-width: 0;
}

.\!k-border-x-0 {
  border-inline-width: 0 !important;
}

.k-border-x-2 {
  border-inline-width: 2px;
}

.\!k-border-x-2 {
  border-inline-width: 2px !important;
}

.k-border-x-4 {
  border-inline-width: 4px;
}

.\!k-border-x-4 {
  border-inline-width: 4px !important;
}

.k-border-x-8 {
  border-inline-width: 8px;
}

.\!k-border-x-8 {
  border-inline-width: 8px !important;
}

.k-border-y {
  border-block-width: 1px;
}

.\!k-border-y {
  border-block-width: 1px !important;
}

.k-border-y-0 {
  border-block-width: 0;
}

.\!k-border-y-0 {
  border-block-width: 0 !important;
}

.k-border-y-2 {
  border-block-width: 2px;
}

.\!k-border-y-2 {
  border-block-width: 2px !important;
}

.k-border-y-4 {
  border-block-width: 4px;
}

.\!k-border-y-4 {
  border-block-width: 4px !important;
}

.k-border-y-8 {
  border-block-width: 8px;
}

.\!k-border-y-8 {
  border-block-width: 8px !important;
}

.k-border-top {
  border-top-width: 1px;
}

.\!k-border-top {
  border-top-width: 1px !important;
}

.k-border-top-0 {
  border-top-width: 0;
}

.\!k-border-top-0 {
  border-top-width: 0 !important;
}

.k-border-top-2 {
  border-top-width: 2px;
}

.\!k-border-top-2 {
  border-top-width: 2px !important;
}

.k-border-top-4 {
  border-top-width: 4px;
}

.\!k-border-top-4 {
  border-top-width: 4px !important;
}

.k-border-top-8 {
  border-top-width: 8px;
}

.\!k-border-top-8 {
  border-top-width: 8px !important;
}

.k-border-right {
  border-right-width: 1px;
}

.\!k-border-right {
  border-right-width: 1px !important;
}

.k-border-right-0 {
  border-right-width: 0;
}

.\!k-border-right-0 {
  border-right-width: 0 !important;
}

.k-border-right-2 {
  border-right-width: 2px;
}

.\!k-border-right-2 {
  border-right-width: 2px !important;
}

.k-border-right-4 {
  border-right-width: 4px;
}

.\!k-border-right-4 {
  border-right-width: 4px !important;
}

.k-border-right-8 {
  border-right-width: 8px;
}

.\!k-border-right-8 {
  border-right-width: 8px !important;
}

.k-border-bottom {
  border-bottom-width: 1px;
}

.\!k-border-bottom {
  border-bottom-width: 1px !important;
}

.k-border-bottom-0 {
  border-bottom-width: 0;
}

.\!k-border-bottom-0 {
  border-bottom-width: 0 !important;
}

.k-border-bottom-2 {
  border-bottom-width: 2px;
}

.\!k-border-bottom-2 {
  border-bottom-width: 2px !important;
}

.k-border-bottom-4 {
  border-bottom-width: 4px;
}

.\!k-border-bottom-4 {
  border-bottom-width: 4px !important;
}

.k-border-bottom-8 {
  border-bottom-width: 8px;
}

.\!k-border-bottom-8 {
  border-bottom-width: 8px !important;
}

.k-border-left {
  border-left-width: 1px;
}

.\!k-border-left {
  border-left-width: 1px !important;
}

.k-border-left-0 {
  border-left-width: 0;
}

.\!k-border-left-0 {
  border-left-width: 0 !important;
}

.k-border-left-2 {
  border-left-width: 2px;
}

.\!k-border-left-2 {
  border-left-width: 2px !important;
}

.k-border-left-4 {
  border-left-width: 4px;
}

.\!k-border-left-4 {
  border-left-width: 4px !important;
}

.k-border-left-8 {
  border-left-width: 8px;
}

.\!k-border-left-8 {
  border-left-width: 8px !important;
}

.k-outline-app-surface {
  outline-color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-outline-app-surface {
  outline-color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-outline-on-app-surface {
  outline-color: var(--kendo-color-on-app-surface, #212529);
}

.\!k-outline-on-app-surface {
  outline-color: var(--kendo-color-on-app-surface, #212529) !important;
}

.k-outline-subtle {
  outline-color: var(--kendo-color-subtle, #596169);
}

.\!k-outline-subtle {
  outline-color: var(--kendo-color-subtle, #596169) !important;
}

.k-outline-surface {
  outline-color: var(--kendo-color-surface, #f8f9fa);
}

.\!k-outline-surface {
  outline-color: var(--kendo-color-surface, #f8f9fa) !important;
}

.k-outline-surface-alt {
  outline-color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-outline-surface-alt {
  outline-color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-outline-border {
  outline-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.\!k-outline-border {
  outline-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13)) !important;
}

.k-outline-border-alt {
  outline-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2));
}

.\!k-outline-border-alt {
  outline-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2)) !important;
}

.k-outline-base-subtle {
  outline-color: var(--kendo-color-base-subtle, #e9ecef);
}

.\!k-outline-base-subtle {
  outline-color: var(--kendo-color-base-subtle, #e9ecef) !important;
}

.k-outline-base-emphasis {
  outline-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
}

.\!k-outline-base-emphasis {
  outline-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) !important;
}

.k-outline-primary-subtle {
  outline-color: var(--kendo-color-primary-subtle, #cfe2ff);
}

.\!k-outline-primary-subtle {
  outline-color: var(--kendo-color-primary-subtle, #cfe2ff) !important;
}

.k-outline-primary {
  outline-color: var(--kendo-color-primary, #0d6efd);
}

.\!k-outline-primary {
  outline-color: var(--kendo-color-primary, #0d6efd) !important;
}

.k-outline-primary-emphasis {
  outline-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
}

.\!k-outline-primary-emphasis {
  outline-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4)) !important;
}

.k-outline-secondary-subtle {
  outline-color: var(--kendo-color-secondary-subtle, #dee2e6);
}

.\!k-outline-secondary-subtle {
  outline-color: var(--kendo-color-secondary-subtle, #dee2e6) !important;
}

.k-outline-secondary {
  outline-color: var(--kendo-color-secondary, #6c757d);
}

.\!k-outline-secondary {
  outline-color: var(--kendo-color-secondary, #6c757d) !important;
}

.k-outline-secondary-emphasis {
  outline-color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4));
}

.\!k-outline-secondary-emphasis {
  outline-color: var(--kendo-color-secondary-emphasis, rgba(108, 117, 125, 0.4)) !important;
}

.k-outline-tertiary-subtle {
  outline-color: var(--kendo-color-tertiary-subtle, #e2d9f3);
}

.\!k-outline-tertiary-subtle {
  outline-color: var(--kendo-color-tertiary-subtle, #e2d9f3) !important;
}

.k-outline-tertiary {
  outline-color: var(--kendo-color-tertiary, #6f42c1);
}

.\!k-outline-tertiary {
  outline-color: var(--kendo-color-tertiary, #6f42c1) !important;
}

.k-outline-tertiary-emphasis {
  outline-color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4));
}

.\!k-outline-tertiary-emphasis {
  outline-color: var(--kendo-color-tertiary-emphasis, rgba(111, 66, 193, 0.4)) !important;
}

.k-outline-info-subtle {
  outline-color: var(--kendo-color-info-subtle, #cff4fc);
}

.\!k-outline-info-subtle {
  outline-color: var(--kendo-color-info-subtle, #cff4fc) !important;
}

.k-outline-info {
  outline-color: var(--kendo-color-info, #0dcaf0);
}

.\!k-outline-info {
  outline-color: var(--kendo-color-info, #0dcaf0) !important;
}

.k-outline-info-emphasis {
  outline-color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4));
}

.\!k-outline-info-emphasis {
  outline-color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4)) !important;
}

.k-outline-success-subtle {
  outline-color: var(--kendo-color-success-subtle, #d1e7dd);
}

.\!k-outline-success-subtle {
  outline-color: var(--kendo-color-success-subtle, #d1e7dd) !important;
}

.k-outline-success {
  outline-color: var(--kendo-color-success, #198754);
}

.\!k-outline-success {
  outline-color: var(--kendo-color-success, #198754) !important;
}

.k-outline-success-emphasis {
  outline-color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4));
}

.\!k-outline-success-emphasis {
  outline-color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4)) !important;
}

.k-outline-warning-subtle {
  outline-color: var(--kendo-color-warning-subtle, #fff3cd);
}

.\!k-outline-warning-subtle {
  outline-color: var(--kendo-color-warning-subtle, #fff3cd) !important;
}

.k-outline-warning {
  outline-color: var(--kendo-color-warning, #ffc107);
}

.\!k-outline-warning {
  outline-color: var(--kendo-color-warning, #ffc107) !important;
}

.k-outline-warning-emphasis {
  outline-color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4));
}

.\!k-outline-warning-emphasis {
  outline-color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4)) !important;
}

.k-outline-error-subtle {
  outline-color: var(--kendo-color-error-subtle, #f8d7da);
}

.\!k-outline-error-subtle {
  outline-color: var(--kendo-color-error-subtle, #f8d7da) !important;
}

.k-outline-error {
  outline-color: var(--kendo-color-error, #dc3545);
}

.\!k-outline-error {
  outline-color: var(--kendo-color-error, #dc3545) !important;
}

.k-outline-error-emphasis {
  outline-color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4));
}

.\!k-outline-error-emphasis {
  outline-color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4)) !important;
}

.k-outline-light-subtle {
  outline-color: var(--kendo-color-light-subtle, #ffffff);
}

.\!k-outline-light-subtle {
  outline-color: var(--kendo-color-light-subtle, #ffffff) !important;
}

.k-outline-light {
  outline-color: var(--kendo-color-light, #f8f9fa);
}

.\!k-outline-light {
  outline-color: var(--kendo-color-light, #f8f9fa) !important;
}

.k-outline-light-emphasis {
  outline-color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1));
}

.\!k-outline-light-emphasis {
  outline-color: var(--kendo-color-light-emphasis, rgba(33, 37, 41, 0.1)) !important;
}

.k-outline-dark-subtle {
  outline-color: var(--kendo-color-dark-subtle, #495057);
}

.\!k-outline-dark-subtle {
  outline-color: var(--kendo-color-dark-subtle, #495057) !important;
}

.k-outline-dark {
  outline-color: var(--kendo-color-dark, #212529);
}

.\!k-outline-dark {
  outline-color: var(--kendo-color-dark, #212529) !important;
}

.k-outline-dark-emphasis {
  outline-color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4));
}

.\!k-outline-dark-emphasis {
  outline-color: var(--kendo-color-dark-emphasis, rgba(33, 37, 41, 0.4)) !important;
}

.k-outline-inherit {
  outline-color: var(--kendo-color-inherit, inherit);
}

.\!k-outline-inherit {
  outline-color: var(--kendo-color-inherit, inherit) !important;
}

.k-outline-current {
  outline-color: var(--kendo-color-current, currentColor);
}

.\!k-outline-current {
  outline-color: var(--kendo-color-current, currentColor) !important;
}

.k-outline-transparent {
  outline-color: var(--kendo-color-transparent, transparent);
}

.\!k-outline-transparent {
  outline-color: var(--kendo-color-transparent, transparent) !important;
}

.k-outline-black {
  outline-color: var(--kendo-color-black, black);
}

.\!k-outline-black {
  outline-color: var(--kendo-color-black, black) !important;
}

.k-outline-white {
  outline-color: var(--kendo-color-white, white);
}

.\!k-outline-white {
  outline-color: var(--kendo-color-white, white) !important;
}

.k-outline-offset-0 {
  outline-offset: 0;
}

.\!k-outline-offset-0 {
  outline-offset: 0 !important;
}

.k-outline-offset-1 {
  outline-offset: 1px;
}

.\!k-outline-offset-1 {
  outline-offset: 1px !important;
}

.k-outline-offset-2 {
  outline-offset: 2px;
}

.\!k-outline-offset-2 {
  outline-offset: 2px !important;
}

.k-outline-offset-4 {
  outline-offset: 4px;
}

.\!k-outline-offset-4 {
  outline-offset: 4px !important;
}

.k-outline-offset-8 {
  outline-offset: 8px;
}

.\!k-outline-offset-8 {
  outline-offset: 8px !important;
}

.k-outline-solid {
  outline-style: solid;
}

.\!k-outline-solid {
  outline-style: solid !important;
}

.k-outline-dashed {
  outline-style: dashed;
}

.\!k-outline-dashed {
  outline-style: dashed !important;
}

.k-outline-dotted {
  outline-style: dotted;
}

.\!k-outline-dotted {
  outline-style: dotted !important;
}

.k-outline-double {
  outline-style: double;
}

.\!k-outline-double {
  outline-style: double !important;
}

.k-outline-inset {
  outline-style: inset;
}

.\!k-outline-inset {
  outline-style: inset !important;
}

.k-outline-outset {
  outline-style: outset;
}

.\!k-outline-outset {
  outline-style: outset !important;
}

.k-outline-none {
  outline-style: none;
}

.\!k-outline-none {
  outline-style: none !important;
}

.k-outline {
  outline-width: 1px;
}

.\!k-outline {
  outline-width: 1px !important;
}

.k-outline-0 {
  outline-width: 0;
}

.\!k-outline-0 {
  outline-width: 0 !important;
}

.k-outline-2 {
  outline-width: 2px;
}

.\!k-outline-2 {
  outline-width: 2px !important;
}

.k-outline-4 {
  outline-width: 4px;
}

.\!k-outline-4 {
  outline-width: 4px !important;
}

.k-outline-8 {
  outline-width: 8px;
}

.\!k-outline-8 {
  outline-width: 8px !important;
}

.k-opacity-0 {
  opacity: 0;
}

.\!k-opacity-0 {
  opacity: 0 !important;
}

.k-opacity-5 {
  opacity: 0.05;
}

.\!k-opacity-5 {
  opacity: 0.05 !important;
}

.k-opacity-10 {
  opacity: 0.1;
}

.\!k-opacity-10 {
  opacity: 0.1 !important;
}

.k-opacity-20 {
  opacity: 0.2;
}

.\!k-opacity-20 {
  opacity: 0.2 !important;
}

.k-opacity-30 {
  opacity: 0.3;
}

.\!k-opacity-30 {
  opacity: 0.3 !important;
}

.k-opacity-40 {
  opacity: 0.4;
}

.\!k-opacity-40 {
  opacity: 0.4 !important;
}

.k-opacity-50 {
  opacity: 0.5;
}

.\!k-opacity-50 {
  opacity: 0.5 !important;
}

.k-opacity-60 {
  opacity: 0.6;
}

.\!k-opacity-60 {
  opacity: 0.6 !important;
}

.k-opacity-70 {
  opacity: 0.7;
}

.\!k-opacity-70 {
  opacity: 0.7 !important;
}

.k-opacity-80 {
  opacity: 0.8;
}

.\!k-opacity-80 {
  opacity: 0.8 !important;
}

.k-opacity-90 {
  opacity: 0.9;
}

.\!k-opacity-90 {
  opacity: 0.9 !important;
}

.k-opacity-100 {
  opacity: 1;
}

.\!k-opacity-100 {
  opacity: 1 !important;
}

.k-backdrop-blur {
  backdrop-filter: blur(8px);
}

.\!k-backdrop-blur {
  backdrop-filter: blur(8px) !important;
}

.k-backdrop-blur-none {
  backdrop-filter: blur(0);
}

.\!k-backdrop-blur-none {
  backdrop-filter: blur(0) !important;
}

.k-backdrop-blur-sm {
  backdrop-filter: blur(4px);
}

.\!k-backdrop-blur-sm {
  backdrop-filter: blur(4px) !important;
}

.k-backdrop-blur-md {
  backdrop-filter: blur(6px);
}

.\!k-backdrop-blur-md {
  backdrop-filter: blur(6px) !important;
}

.k-backdrop-blur-lg {
  backdrop-filter: blur(12px);
}

.\!k-backdrop-blur-lg {
  backdrop-filter: blur(12px) !important;
}

.k-border-collapse {
  border-collapse: collapse;
}

.\!k-border-collapse {
  border-collapse: collapse !important;
}

.k-border-separate {
  border-collapse: separate;
}

.\!k-border-separate {
  border-collapse: separate !important;
}

.k-table-auto {
  table-layout: auto;
}

.\!k-table-auto {
  table-layout: auto !important;
}

.k-table-fixed {
  table-layout: fixed;
}

.\!k-table-fixed {
  table-layout: fixed !important;
}

.k-table-layout-auto {
  table-layout: auto;
}

.\!k-table-layout-auto {
  table-layout: auto !important;
}

.k-table-layout-fixed {
  table-layout: fixed;
}

.\!k-table-layout-fixed {
  table-layout: fixed !important;
}

.k-flip-h, .k-flip-x {
  transform: scaleX(-1);
}

.\!k-flip-h, .\!k-flip-x {
  transform: scaleX(-1) !important;
}

.k-flip-v, .k-flip-y {
  transform: scaleY(-1);
}

.\!k-flip-v, .\!k-flip-y {
  transform: scaleY(-1) !important;
}

.k-flip-h.k-flip-v, .k-flip-h.k-flip-y, .k-flip-v.k-flip-x, .k-flip-x.k-flip-y {
  transform: scale(-1, -1);
}

.\!k-flip-h.\!k-flip-v, .\!k-flip-h.\!k-flip-y, .\!k-flip-v.\!k-flip-x, .\!k-flip-x.\!k-flip-y {
  transform: scale(-1, -1) !important;
}

.k-origin-center {
  transform-origin: center;
}

.\!k-origin-center {
  transform-origin: center !important;
}

.k-origin-top {
  transform-origin: top;
}

.\!k-origin-top {
  transform-origin: top !important;
}

.k-origin-right {
  transform-origin: right;
}

.\!k-origin-right {
  transform-origin: right !important;
}

.k-origin-bottom {
  transform-origin: bottom;
}

.\!k-origin-bottom {
  transform-origin: bottom !important;
}

.k-origin-left {
  transform-origin: left;
}

.\!k-origin-left {
  transform-origin: left !important;
}

.k-origin-top-left {
  transform-origin: top left;
}

.\!k-origin-top-left {
  transform-origin: top left !important;
}

.k-origin-top-right {
  transform-origin: top right;
}

.\!k-origin-top-right {
  transform-origin: top right !important;
}

.k-origin-bottom-left {
  transform-origin: bottom left;
}

.\!k-origin-bottom-left {
  transform-origin: bottom left !important;
}

.k-origin-bottom-right {
  transform-origin: bottom right;
}

.\!k-origin-bottom-right {
  transform-origin: bottom right !important;
}

.k-rotate-0 {
  transform: rotate(0deg);
}

.\!k-rotate-0 {
  transform: rotate(0deg) !important;
}

.k-rotate-45 {
  transform: rotate(45deg);
}

.\!k-rotate-45 {
  transform: rotate(45deg) !important;
}

.k-rotate-90 {
  transform: rotate(90deg);
}

.\!k-rotate-90 {
  transform: rotate(90deg) !important;
}

.k-rotate-135 {
  transform: rotate(135deg);
}

.\!k-rotate-135 {
  transform: rotate(135deg) !important;
}

.k-rotate-180 {
  transform: rotate(180deg);
}

.\!k-rotate-180 {
  transform: rotate(180deg) !important;
}

.k-rotate-225 {
  transform: rotate(225deg);
}

.\!k-rotate-225 {
  transform: rotate(225deg) !important;
}

.k-rotate-270 {
  transform: rotate(270deg);
}

.\!k-rotate-270 {
  transform: rotate(270deg) !important;
}

.k-rotate-315 {
  transform: rotate(315deg);
}

.\!k-rotate-315 {
  transform: rotate(315deg) !important;
}

.k-scale-0 {
  transform: scale(0);
}

.\!k-scale-0 {
  transform: scale(0) !important;
}

.k-scale-0\.25 {
  transform: scale(0.25);
}

.\!k-scale-0\.25 {
  transform: scale(0.25) !important;
}

.k-scale-0\.5 {
  transform: scale(0.5);
}

.\!k-scale-0\.5 {
  transform: scale(0.5) !important;
}

.k-scale-0\.75 {
  transform: scale(0.75);
}

.\!k-scale-0\.75 {
  transform: scale(0.75) !important;
}

.k-scale-1 {
  transform: scale(1);
}

.\!k-scale-1 {
  transform: scale(1) !important;
}

.k-scale-1\.25 {
  transform: scale(1.25);
}

.\!k-scale-1\.25 {
  transform: scale(1.25) !important;
}

.k-scale-1\.5 {
  transform: scale(1.5);
}

.\!k-scale-1\.5 {
  transform: scale(1.5) !important;
}

.k-scale-2 {
  transform: scale(2);
}

.\!k-scale-2 {
  transform: scale(2) !important;
}

.k-scale-x-0 {
  transform: scaleX(0);
}

.\!k-scale-x-0 {
  transform: scaleX(0) !important;
}

.k-scale-x-0\.25 {
  transform: scaleX(0.25);
}

.\!k-scale-x-0\.25 {
  transform: scaleX(0.25) !important;
}

.k-scale-x-0\.5 {
  transform: scaleX(0.5);
}

.\!k-scale-x-0\.5 {
  transform: scaleX(0.5) !important;
}

.k-scale-x-0\.75 {
  transform: scaleX(0.75);
}

.\!k-scale-x-0\.75 {
  transform: scaleX(0.75) !important;
}

.k-scale-x-1 {
  transform: scaleX(1);
}

.\!k-scale-x-1 {
  transform: scaleX(1) !important;
}

.k-scale-x-1\.25 {
  transform: scaleX(1.25);
}

.\!k-scale-x-1\.25 {
  transform: scaleX(1.25) !important;
}

.k-scale-x-1\.5 {
  transform: scaleX(1.5);
}

.\!k-scale-x-1\.5 {
  transform: scaleX(1.5) !important;
}

.k-scale-x-2 {
  transform: scaleX(2);
}

.\!k-scale-x-2 {
  transform: scaleX(2) !important;
}

.k-scale-y-0 {
  transform: scaleY(0);
}

.\!k-scale-y-0 {
  transform: scaleY(0) !important;
}

.k-scale-y-0\.25 {
  transform: scaleY(0.25);
}

.\!k-scale-y-0\.25 {
  transform: scaleY(0.25) !important;
}

.k-scale-y-0\.5 {
  transform: scaleY(0.5);
}

.\!k-scale-y-0\.5 {
  transform: scaleY(0.5) !important;
}

.k-scale-y-0\.75 {
  transform: scaleY(0.75);
}

.\!k-scale-y-0\.75 {
  transform: scaleY(0.75) !important;
}

.k-scale-y-1 {
  transform: scaleY(1);
}

.\!k-scale-y-1 {
  transform: scaleY(1) !important;
}

.k-scale-y-1\.25 {
  transform: scaleY(1.25);
}

.\!k-scale-y-1\.25 {
  transform: scaleY(1.25) !important;
}

.k-scale-y-1\.5 {
  transform: scaleY(1.5);
}

.\!k-scale-y-1\.5 {
  transform: scaleY(1.5) !important;
}

.k-scale-y-2 {
  transform: scaleY(2);
}

.\!k-scale-y-2 {
  transform: scaleY(2) !important;
}

.k-skew-x-0 {
  transform: skewX(0deg);
}

.\!k-skew-x-0 {
  transform: skewX(0deg) !important;
}

.k-skew-x-1 {
  transform: skewX(1deg);
}

.\!k-skew-x-1 {
  transform: skewX(1deg) !important;
}

.k-skew-x-2 {
  transform: skewX(2deg);
}

.\!k-skew-x-2 {
  transform: skewX(2deg) !important;
}

.k-skew-x-3 {
  transform: skewX(3deg);
}

.\!k-skew-x-3 {
  transform: skewX(3deg) !important;
}

.k-skew-x-4 {
  transform: skewX(4deg);
}

.\!k-skew-x-4 {
  transform: skewX(4deg) !important;
}

.k-skew-x-5 {
  transform: skewX(5deg);
}

.\!k-skew-x-5 {
  transform: skewX(5deg) !important;
}

.k-skew-x-6 {
  transform: skewX(6deg);
}

.\!k-skew-x-6 {
  transform: skewX(6deg) !important;
}

.k-skew-x-7 {
  transform: skewX(7deg);
}

.\!k-skew-x-7 {
  transform: skewX(7deg) !important;
}

.k-skew-x-8 {
  transform: skewX(8deg);
}

.\!k-skew-x-8 {
  transform: skewX(8deg) !important;
}

.k-skew-x-9 {
  transform: skewX(9deg);
}

.\!k-skew-x-9 {
  transform: skewX(9deg) !important;
}

.k-skew-x-10 {
  transform: skewX(10deg);
}

.\!k-skew-x-10 {
  transform: skewX(10deg) !important;
}

.k-skew-x-11 {
  transform: skewX(11deg);
}

.\!k-skew-x-11 {
  transform: skewX(11deg) !important;
}

.k-skew-x-12 {
  transform: skewX(12deg);
}

.\!k-skew-x-12 {
  transform: skewX(12deg) !important;
}

.k-skew-y-0 {
  transform: skewY(0deg);
}

.\!k-skew-y-0 {
  transform: skewY(0deg) !important;
}

.k-skew-y-1 {
  transform: skewY(1deg);
}

.\!k-skew-y-1 {
  transform: skewY(1deg) !important;
}

.k-skew-y-2 {
  transform: skewY(2deg);
}

.\!k-skew-y-2 {
  transform: skewY(2deg) !important;
}

.k-skew-y-3 {
  transform: skewY(3deg);
}

.\!k-skew-y-3 {
  transform: skewY(3deg) !important;
}

.k-skew-y-4 {
  transform: skewY(4deg);
}

.\!k-skew-y-4 {
  transform: skewY(4deg) !important;
}

.k-skew-y-5 {
  transform: skewY(5deg);
}

.\!k-skew-y-5 {
  transform: skewY(5deg) !important;
}

.k-skew-y-6 {
  transform: skewY(6deg);
}

.\!k-skew-y-6 {
  transform: skewY(6deg) !important;
}

.k-skew-y-7 {
  transform: skewY(7deg);
}

.\!k-skew-y-7 {
  transform: skewY(7deg) !important;
}

.k-skew-y-8 {
  transform: skewY(8deg);
}

.\!k-skew-y-8 {
  transform: skewY(8deg) !important;
}

.k-skew-y-9 {
  transform: skewY(9deg);
}

.\!k-skew-y-9 {
  transform: skewY(9deg) !important;
}

.k-skew-y-10 {
  transform: skewY(10deg);
}

.\!k-skew-y-10 {
  transform: skewY(10deg) !important;
}

.k-skew-y-11 {
  transform: skewY(11deg);
}

.\!k-skew-y-11 {
  transform: skewY(11deg) !important;
}

.k-skew-y-12 {
  transform: skewY(12deg);
}

.\!k-skew-y-12 {
  transform: skewY(12deg) !important;
}

.k-translate-0 {
  transform: translate(0, 0);
}

.\!k-translate-0 {
  transform: translate(0, 0) !important;
}

.k-translate-0-50 {
  transform: translate(0, 50%);
}

.\!k-translate-0-50 {
  transform: translate(0, 50%) !important;
}

.k-translate-0-100 {
  transform: translate(0, 100%);
}

.\!k-translate-0-100 {
  transform: translate(0, 100%) !important;
}

.k-translate-50-0 {
  transform: translate(50%, 0);
}

.\!k-translate-50-0 {
  transform: translate(50%, 0) !important;
}

.k-translate-50-50 {
  transform: translate(50%, 50%);
}

.\!k-translate-50-50 {
  transform: translate(50%, 50%) !important;
}

.k-translate-50-100 {
  transform: translate(50%, 100%);
}

.\!k-translate-50-100 {
  transform: translate(50%, 100%) !important;
}

.k-translate-100-0 {
  transform: translate(100%, 0);
}

.\!k-translate-100-0 {
  transform: translate(100%, 0) !important;
}

.k-translate-100-50 {
  transform: translate(100%, 50%);
}

.\!k-translate-100-50 {
  transform: translate(100%, 50%) !important;
}

.k-translate-100-100 {
  transform: translate(100%, 100%);
}

.\!k-translate-100-100 {
  transform: translate(100%, 100%) !important;
}

.k-translate-x-0 {
  transform: translateX(0px);
}

.\!k-translate-x-0 {
  transform: translateX(0px) !important;
}

.k-translate-x-1px {
  transform: translateX(1px);
}

.\!k-translate-x-1px {
  transform: translateX(1px) !important;
}

.k-translate-x-0\.5 {
  transform: translateX(0.125rem);
}

.\!k-translate-x-0\.5 {
  transform: translateX(0.125rem) !important;
}

.k-translate-x-1 {
  transform: translateX(0.25rem);
}

.\!k-translate-x-1 {
  transform: translateX(0.25rem) !important;
}

.k-translate-x-1\.5 {
  transform: translateX(0.375rem);
}

.\!k-translate-x-1\.5 {
  transform: translateX(0.375rem) !important;
}

.k-translate-x-2 {
  transform: translateX(0.5rem);
}

.\!k-translate-x-2 {
  transform: translateX(0.5rem) !important;
}

.k-translate-x-2\.5 {
  transform: translateX(0.625rem);
}

.\!k-translate-x-2\.5 {
  transform: translateX(0.625rem) !important;
}

.k-translate-x-3 {
  transform: translateX(0.75rem);
}

.\!k-translate-x-3 {
  transform: translateX(0.75rem) !important;
}

.k-translate-x-3\.5 {
  transform: translateX(0.875rem);
}

.\!k-translate-x-3\.5 {
  transform: translateX(0.875rem) !important;
}

.k-translate-x-4 {
  transform: translateX(1rem);
}

.\!k-translate-x-4 {
  transform: translateX(1rem) !important;
}

.k-translate-x-4\.5 {
  transform: translateX(1.125rem);
}

.\!k-translate-x-4\.5 {
  transform: translateX(1.125rem) !important;
}

.k-translate-x-5 {
  transform: translateX(1.25rem);
}

.\!k-translate-x-5 {
  transform: translateX(1.25rem) !important;
}

.k-translate-x-5\.5 {
  transform: translateX(1.375rem);
}

.\!k-translate-x-5\.5 {
  transform: translateX(1.375rem) !important;
}

.k-translate-x-6 {
  transform: translateX(1.5rem);
}

.\!k-translate-x-6 {
  transform: translateX(1.5rem) !important;
}

.k-translate-x-6\.5 {
  transform: translateX(1.625rem);
}

.\!k-translate-x-6\.5 {
  transform: translateX(1.625rem) !important;
}

.k-translate-x-7 {
  transform: translateX(1.75rem);
}

.\!k-translate-x-7 {
  transform: translateX(1.75rem) !important;
}

.k-translate-x-7\.5 {
  transform: translateX(1.875rem);
}

.\!k-translate-x-7\.5 {
  transform: translateX(1.875rem) !important;
}

.k-translate-x-8 {
  transform: translateX(2rem);
}

.\!k-translate-x-8 {
  transform: translateX(2rem) !important;
}

.k-translate-x-9 {
  transform: translateX(2.25rem);
}

.\!k-translate-x-9 {
  transform: translateX(2.25rem) !important;
}

.k-translate-x-10 {
  transform: translateX(2.5rem);
}

.\!k-translate-x-10 {
  transform: translateX(2.5rem) !important;
}

.k-translate-x-11 {
  transform: translateX(2.75rem);
}

.\!k-translate-x-11 {
  transform: translateX(2.75rem) !important;
}

.k-translate-x-12 {
  transform: translateX(3rem);
}

.\!k-translate-x-12 {
  transform: translateX(3rem) !important;
}

.k-translate-x-13 {
  transform: translateX(3.25rem);
}

.\!k-translate-x-13 {
  transform: translateX(3.25rem) !important;
}

.k-translate-x-14 {
  transform: translateX(3.5rem);
}

.\!k-translate-x-14 {
  transform: translateX(3.5rem) !important;
}

.k-translate-x-15 {
  transform: translateX(3.75rem);
}

.\!k-translate-x-15 {
  transform: translateX(3.75rem) !important;
}

.k-translate-x-16 {
  transform: translateX(4rem);
}

.\!k-translate-x-16 {
  transform: translateX(4rem) !important;
}

.k-translate-x-17 {
  transform: translateX(4.25rem);
}

.\!k-translate-x-17 {
  transform: translateX(4.25rem) !important;
}

.k-translate-x-18 {
  transform: translateX(4.5rem);
}

.\!k-translate-x-18 {
  transform: translateX(4.5rem) !important;
}

.k-translate-x-19 {
  transform: translateX(4.75rem);
}

.\!k-translate-x-19 {
  transform: translateX(4.75rem) !important;
}

.k-translate-x-20 {
  transform: translateX(5rem);
}

.\!k-translate-x-20 {
  transform: translateX(5rem) !important;
}

.k-translate-x-21 {
  transform: translateX(5.25rem);
}

.\!k-translate-x-21 {
  transform: translateX(5.25rem) !important;
}

.k-translate-x-22 {
  transform: translateX(5.5rem);
}

.\!k-translate-x-22 {
  transform: translateX(5.5rem) !important;
}

.k-translate-x-23 {
  transform: translateX(5.75rem);
}

.\!k-translate-x-23 {
  transform: translateX(5.75rem) !important;
}

.k-translate-x-24 {
  transform: translateX(6rem);
}

.\!k-translate-x-24 {
  transform: translateX(6rem) !important;
}

.k-translate-x-25 {
  transform: translateX(7rem);
}

.\!k-translate-x-25 {
  transform: translateX(7rem) !important;
}

.k-translate-x-26 {
  transform: translateX(8rem);
}

.\!k-translate-x-26 {
  transform: translateX(8rem) !important;
}

.k-translate-x-27 {
  transform: translateX(9rem);
}

.\!k-translate-x-27 {
  transform: translateX(9rem) !important;
}

.k-translate-x-28 {
  transform: translateX(10rem);
}

.\!k-translate-x-28 {
  transform: translateX(10rem) !important;
}

.k-translate-x-29 {
  transform: translateX(11rem);
}

.\!k-translate-x-29 {
  transform: translateX(11rem) !important;
}

.k-translate-x-30 {
  transform: translateX(12rem);
}

.\!k-translate-x-30 {
  transform: translateX(12rem) !important;
}

.k-translate-x-1\/2 {
  transform: translateX(50%);
}

.\!k-translate-x-1\/2 {
  transform: translateX(50%) !important;
}

.k-translate-x-1\/3 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-1\/3 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-2\/3 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-2\/3 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-1\/4 {
  transform: translateX(25%);
}

.\!k-translate-x-1\/4 {
  transform: translateX(25%) !important;
}

.k-translate-x-2\/4 {
  transform: translateX(50%);
}

.\!k-translate-x-2\/4 {
  transform: translateX(50%) !important;
}

.k-translate-x-3\/4 {
  transform: translateX(75%);
}

.\!k-translate-x-3\/4 {
  transform: translateX(75%) !important;
}

.k-translate-x-1\/5 {
  transform: translateX(20%);
}

.\!k-translate-x-1\/5 {
  transform: translateX(20%) !important;
}

.k-translate-x-2\/5 {
  transform: translateX(40%);
}

.\!k-translate-x-2\/5 {
  transform: translateX(40%) !important;
}

.k-translate-x-3\/5 {
  transform: translateX(60%);
}

.\!k-translate-x-3\/5 {
  transform: translateX(60%) !important;
}

.k-translate-x-4\/5 {
  transform: translateX(80%);
}

.\!k-translate-x-4\/5 {
  transform: translateX(80%) !important;
}

.k-translate-x-1\/6 {
  transform: translateX(16.666667%);
}

.\!k-translate-x-1\/6 {
  transform: translateX(16.666667%) !important;
}

.k-translate-x-2\/6 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-2\/6 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-3\/6 {
  transform: translateX(50%);
}

.\!k-translate-x-3\/6 {
  transform: translateX(50%) !important;
}

.k-translate-x-4\/6 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-4\/6 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-5\/6 {
  transform: translateX(83.333333%);
}

.\!k-translate-x-5\/6 {
  transform: translateX(83.333333%) !important;
}

.k-translate-x-1\/12 {
  transform: translateX(8.333333%);
}

.\!k-translate-x-1\/12 {
  transform: translateX(8.333333%) !important;
}

.k-translate-x-2\/12 {
  transform: translateX(16.666667%);
}

.\!k-translate-x-2\/12 {
  transform: translateX(16.666667%) !important;
}

.k-translate-x-3\/12 {
  transform: translateX(25%);
}

.\!k-translate-x-3\/12 {
  transform: translateX(25%) !important;
}

.k-translate-x-4\/12 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-4\/12 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-5\/12 {
  transform: translateX(41.666667%);
}

.\!k-translate-x-5\/12 {
  transform: translateX(41.666667%) !important;
}

.k-translate-x-6\/12 {
  transform: translateX(50%);
}

.\!k-translate-x-6\/12 {
  transform: translateX(50%) !important;
}

.k-translate-x-7\/12 {
  transform: translateX(58.333333%);
}

.\!k-translate-x-7\/12 {
  transform: translateX(58.333333%) !important;
}

.k-translate-x-8\/12 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-8\/12 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-9\/12 {
  transform: translateX(75%);
}

.\!k-translate-x-9\/12 {
  transform: translateX(75%) !important;
}

.k-translate-x-10\/12 {
  transform: translateX(83.333333%);
}

.\!k-translate-x-10\/12 {
  transform: translateX(83.333333%) !important;
}

.k-translate-x-11\/12 {
  transform: translateX(91.666667%);
}

.\!k-translate-x-11\/12 {
  transform: translateX(91.666667%) !important;
}

.k-translate-x-full {
  transform: translateX(100%);
}

.\!k-translate-x-full {
  transform: translateX(100%) !important;
}

.k-translate-y-0 {
  transform: translateY(0px);
}

.\!k-translate-y-0 {
  transform: translateY(0px) !important;
}

.k-translate-y-1px {
  transform: translateY(1px);
}

.\!k-translate-y-1px {
  transform: translateY(1px) !important;
}

.k-translate-y-0\.5 {
  transform: translateY(0.125rem);
}

.\!k-translate-y-0\.5 {
  transform: translateY(0.125rem) !important;
}

.k-translate-y-1 {
  transform: translateY(0.25rem);
}

.\!k-translate-y-1 {
  transform: translateY(0.25rem) !important;
}

.k-translate-y-1\.5 {
  transform: translateY(0.375rem);
}

.\!k-translate-y-1\.5 {
  transform: translateY(0.375rem) !important;
}

.k-translate-y-2 {
  transform: translateY(0.5rem);
}

.\!k-translate-y-2 {
  transform: translateY(0.5rem) !important;
}

.k-translate-y-2\.5 {
  transform: translateY(0.625rem);
}

.\!k-translate-y-2\.5 {
  transform: translateY(0.625rem) !important;
}

.k-translate-y-3 {
  transform: translateY(0.75rem);
}

.\!k-translate-y-3 {
  transform: translateY(0.75rem) !important;
}

.k-translate-y-3\.5 {
  transform: translateY(0.875rem);
}

.\!k-translate-y-3\.5 {
  transform: translateY(0.875rem) !important;
}

.k-translate-y-4 {
  transform: translateY(1rem);
}

.\!k-translate-y-4 {
  transform: translateY(1rem) !important;
}

.k-translate-y-4\.5 {
  transform: translateY(1.125rem);
}

.\!k-translate-y-4\.5 {
  transform: translateY(1.125rem) !important;
}

.k-translate-y-5 {
  transform: translateY(1.25rem);
}

.\!k-translate-y-5 {
  transform: translateY(1.25rem) !important;
}

.k-translate-y-5\.5 {
  transform: translateY(1.375rem);
}

.\!k-translate-y-5\.5 {
  transform: translateY(1.375rem) !important;
}

.k-translate-y-6 {
  transform: translateY(1.5rem);
}

.\!k-translate-y-6 {
  transform: translateY(1.5rem) !important;
}

.k-translate-y-6\.5 {
  transform: translateY(1.625rem);
}

.\!k-translate-y-6\.5 {
  transform: translateY(1.625rem) !important;
}

.k-translate-y-7 {
  transform: translateY(1.75rem);
}

.\!k-translate-y-7 {
  transform: translateY(1.75rem) !important;
}

.k-translate-y-7\.5 {
  transform: translateY(1.875rem);
}

.\!k-translate-y-7\.5 {
  transform: translateY(1.875rem) !important;
}

.k-translate-y-8 {
  transform: translateY(2rem);
}

.\!k-translate-y-8 {
  transform: translateY(2rem) !important;
}

.k-translate-y-9 {
  transform: translateY(2.25rem);
}

.\!k-translate-y-9 {
  transform: translateY(2.25rem) !important;
}

.k-translate-y-10 {
  transform: translateY(2.5rem);
}

.\!k-translate-y-10 {
  transform: translateY(2.5rem) !important;
}

.k-translate-y-11 {
  transform: translateY(2.75rem);
}

.\!k-translate-y-11 {
  transform: translateY(2.75rem) !important;
}

.k-translate-y-12 {
  transform: translateY(3rem);
}

.\!k-translate-y-12 {
  transform: translateY(3rem) !important;
}

.k-translate-y-13 {
  transform: translateY(3.25rem);
}

.\!k-translate-y-13 {
  transform: translateY(3.25rem) !important;
}

.k-translate-y-14 {
  transform: translateY(3.5rem);
}

.\!k-translate-y-14 {
  transform: translateY(3.5rem) !important;
}

.k-translate-y-15 {
  transform: translateY(3.75rem);
}

.\!k-translate-y-15 {
  transform: translateY(3.75rem) !important;
}

.k-translate-y-16 {
  transform: translateY(4rem);
}

.\!k-translate-y-16 {
  transform: translateY(4rem) !important;
}

.k-translate-y-17 {
  transform: translateY(4.25rem);
}

.\!k-translate-y-17 {
  transform: translateY(4.25rem) !important;
}

.k-translate-y-18 {
  transform: translateY(4.5rem);
}

.\!k-translate-y-18 {
  transform: translateY(4.5rem) !important;
}

.k-translate-y-19 {
  transform: translateY(4.75rem);
}

.\!k-translate-y-19 {
  transform: translateY(4.75rem) !important;
}

.k-translate-y-20 {
  transform: translateY(5rem);
}

.\!k-translate-y-20 {
  transform: translateY(5rem) !important;
}

.k-translate-y-21 {
  transform: translateY(5.25rem);
}

.\!k-translate-y-21 {
  transform: translateY(5.25rem) !important;
}

.k-translate-y-22 {
  transform: translateY(5.5rem);
}

.\!k-translate-y-22 {
  transform: translateY(5.5rem) !important;
}

.k-translate-y-23 {
  transform: translateY(5.75rem);
}

.\!k-translate-y-23 {
  transform: translateY(5.75rem) !important;
}

.k-translate-y-24 {
  transform: translateY(6rem);
}

.\!k-translate-y-24 {
  transform: translateY(6rem) !important;
}

.k-translate-y-25 {
  transform: translateY(7rem);
}

.\!k-translate-y-25 {
  transform: translateY(7rem) !important;
}

.k-translate-y-26 {
  transform: translateY(8rem);
}

.\!k-translate-y-26 {
  transform: translateY(8rem) !important;
}

.k-translate-y-27 {
  transform: translateY(9rem);
}

.\!k-translate-y-27 {
  transform: translateY(9rem) !important;
}

.k-translate-y-28 {
  transform: translateY(10rem);
}

.\!k-translate-y-28 {
  transform: translateY(10rem) !important;
}

.k-translate-y-29 {
  transform: translateY(11rem);
}

.\!k-translate-y-29 {
  transform: translateY(11rem) !important;
}

.k-translate-y-30 {
  transform: translateY(12rem);
}

.\!k-translate-y-30 {
  transform: translateY(12rem) !important;
}

.k-translate-y-1\/2 {
  transform: translateY(50%);
}

.\!k-translate-y-1\/2 {
  transform: translateY(50%) !important;
}

.k-translate-y-1\/3 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-1\/3 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-2\/3 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-2\/3 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-1\/4 {
  transform: translateY(25%);
}

.\!k-translate-y-1\/4 {
  transform: translateY(25%) !important;
}

.k-translate-y-2\/4 {
  transform: translateY(50%);
}

.\!k-translate-y-2\/4 {
  transform: translateY(50%) !important;
}

.k-translate-y-3\/4 {
  transform: translateY(75%);
}

.\!k-translate-y-3\/4 {
  transform: translateY(75%) !important;
}

.k-translate-y-1\/5 {
  transform: translateY(20%);
}

.\!k-translate-y-1\/5 {
  transform: translateY(20%) !important;
}

.k-translate-y-2\/5 {
  transform: translateY(40%);
}

.\!k-translate-y-2\/5 {
  transform: translateY(40%) !important;
}

.k-translate-y-3\/5 {
  transform: translateY(60%);
}

.\!k-translate-y-3\/5 {
  transform: translateY(60%) !important;
}

.k-translate-y-4\/5 {
  transform: translateY(80%);
}

.\!k-translate-y-4\/5 {
  transform: translateY(80%) !important;
}

.k-translate-y-1\/6 {
  transform: translateY(16.666667%);
}

.\!k-translate-y-1\/6 {
  transform: translateY(16.666667%) !important;
}

.k-translate-y-2\/6 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-2\/6 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-3\/6 {
  transform: translateY(50%);
}

.\!k-translate-y-3\/6 {
  transform: translateY(50%) !important;
}

.k-translate-y-4\/6 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-4\/6 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-5\/6 {
  transform: translateY(83.333333%);
}

.\!k-translate-y-5\/6 {
  transform: translateY(83.333333%) !important;
}

.k-translate-y-1\/12 {
  transform: translateY(8.333333%);
}

.\!k-translate-y-1\/12 {
  transform: translateY(8.333333%) !important;
}

.k-translate-y-2\/12 {
  transform: translateY(16.666667%);
}

.\!k-translate-y-2\/12 {
  transform: translateY(16.666667%) !important;
}

.k-translate-y-3\/12 {
  transform: translateY(25%);
}

.\!k-translate-y-3\/12 {
  transform: translateY(25%) !important;
}

.k-translate-y-4\/12 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-4\/12 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-5\/12 {
  transform: translateY(41.666667%);
}

.\!k-translate-y-5\/12 {
  transform: translateY(41.666667%) !important;
}

.k-translate-y-6\/12 {
  transform: translateY(50%);
}

.\!k-translate-y-6\/12 {
  transform: translateY(50%) !important;
}

.k-translate-y-7\/12 {
  transform: translateY(58.333333%);
}

.\!k-translate-y-7\/12 {
  transform: translateY(58.333333%) !important;
}

.k-translate-y-8\/12 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-8\/12 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-9\/12 {
  transform: translateY(75%);
}

.\!k-translate-y-9\/12 {
  transform: translateY(75%) !important;
}

.k-translate-y-10\/12 {
  transform: translateY(83.333333%);
}

.\!k-translate-y-10\/12 {
  transform: translateY(83.333333%) !important;
}

.k-translate-y-11\/12 {
  transform: translateY(91.666667%);
}

.\!k-translate-y-11\/12 {
  transform: translateY(91.666667%) !important;
}

.k-translate-y-full {
  transform: translateY(100%);
}

.\!k-translate-y-full {
  transform: translateY(100%) !important;
}

.k-appearance-none {
  appearance: none;
}

.\!k-appearance-none {
  appearance: none !important;
}

.k-appearance-auto {
  appearance: auto;
}

.\!k-appearance-auto {
  appearance: auto !important;
}

.k-cursor-none {
  cursor: none;
}

.\!k-cursor-none {
  cursor: none !important;
}

.k-cursor-auto {
  cursor: auto;
}

.\!k-cursor-auto {
  cursor: auto !important;
}

.k-cursor-default {
  cursor: default;
}

.\!k-cursor-default {
  cursor: default !important;
}

.k-cursor-context-menu {
  cursor: context-menu;
}

.\!k-cursor-context-menu {
  cursor: context-menu !important;
}

.k-cursor-help {
  cursor: help;
}

.\!k-cursor-help {
  cursor: help !important;
}

.k-cursor-pointer {
  cursor: pointer;
}

.\!k-cursor-pointer {
  cursor: pointer !important;
}

.k-cursor-progress {
  cursor: progress;
}

.\!k-cursor-progress {
  cursor: progress !important;
}

.k-cursor-wait {
  cursor: wait;
}

.\!k-cursor-wait {
  cursor: wait !important;
}

.k-cursor-cell {
  cursor: cell;
}

.\!k-cursor-cell {
  cursor: cell !important;
}

.k-cursor-crosshair {
  cursor: crosshair;
}

.\!k-cursor-crosshair {
  cursor: crosshair !important;
}

.k-cursor-text {
  cursor: text;
}

.\!k-cursor-text {
  cursor: text !important;
}

.k-cursor-vertical-text {
  cursor: vertical-text;
}

.\!k-cursor-vertical-text {
  cursor: vertical-text !important;
}

.k-cursor-alias {
  cursor: alias;
}

.\!k-cursor-alias {
  cursor: alias !important;
}

.k-cursor-copy {
  cursor: copy;
}

.\!k-cursor-copy {
  cursor: copy !important;
}

.k-cursor-move {
  cursor: move;
}

.\!k-cursor-move {
  cursor: move !important;
}

.k-cursor-no-drop {
  cursor: no-drop;
}

.\!k-cursor-no-drop {
  cursor: no-drop !important;
}

.k-cursor-not-allowed {
  cursor: not-allowed;
}

.\!k-cursor-not-allowed {
  cursor: not-allowed !important;
}

.k-cursor-e-resize {
  cursor: e-resize;
}

.\!k-cursor-e-resize {
  cursor: e-resize !important;
}

.k-cursor-n-resize {
  cursor: n-resize;
}

.\!k-cursor-n-resize {
  cursor: n-resize !important;
}

.k-cursor-ne-resize {
  cursor: ne-resize;
}

.\!k-cursor-ne-resize {
  cursor: ne-resize !important;
}

.k-cursor-nw-resize {
  cursor: nw-resize;
}

.\!k-cursor-nw-resize {
  cursor: nw-resize !important;
}

.k-cursor-s-resize {
  cursor: s-resize;
}

.\!k-cursor-s-resize {
  cursor: s-resize !important;
}

.k-cursor-se-resize {
  cursor: se-resize;
}

.\!k-cursor-se-resize {
  cursor: se-resize !important;
}

.k-cursor-sw-resize {
  cursor: sw-resize;
}

.\!k-cursor-sw-resize {
  cursor: sw-resize !important;
}

.k-cursor-w-resize {
  cursor: w-resize;
}

.\!k-cursor-w-resize {
  cursor: w-resize !important;
}

.k-cursor-ew-resize {
  cursor: ew-resize;
}

.\!k-cursor-ew-resize {
  cursor: ew-resize !important;
}

.k-cursor-ns-resize {
  cursor: ns-resize;
}

.\!k-cursor-ns-resize {
  cursor: ns-resize !important;
}

.k-cursor-nesw-resize {
  cursor: nesw-resize;
}

.\!k-cursor-nesw-resize {
  cursor: nesw-resize !important;
}

.k-cursor-nwse-resize {
  cursor: nwse-resize;
}

.\!k-cursor-nwse-resize {
  cursor: nwse-resize !important;
}

.k-cursor-col-resize {
  cursor: col-resize;
}

.\!k-cursor-col-resize {
  cursor: col-resize !important;
}

.k-cursor-row-resize {
  cursor: row-resize;
}

.\!k-cursor-row-resize {
  cursor: row-resize !important;
}

.k-cursor-all-scroll {
  cursor: all-scroll;
}

.\!k-cursor-all-scroll {
  cursor: all-scroll !important;
}

.k-cursor-zoom-in {
  cursor: zoom-in;
}

.\!k-cursor-zoom-in {
  cursor: zoom-in !important;
}

.k-cursor-zoom-out {
  cursor: zoom-out;
}

.\!k-cursor-zoom-out {
  cursor: zoom-out !important;
}

.k-cursor-grab {
  cursor: grab;
}

.\!k-cursor-grab {
  cursor: grab !important;
}

.k-cursor-grabbing {
  cursor: grabbing;
}

.\!k-cursor-grabbing {
  cursor: grabbing !important;
}

.k-pointer-events-none {
  pointer-events: none;
}

.\!k-pointer-events-none {
  pointer-events: none !important;
}

.k-pointer-events-auto {
  pointer-events: auto;
}

.\!k-pointer-events-auto {
  pointer-events: auto !important;
}

.k-resize {
  resize: both;
}

.\!k-resize {
  resize: both !important;
}

.k-resize-none {
  resize: none;
}

.\!k-resize-none {
  resize: none !important;
}

.k-resize-x {
  resize: horizontal;
}

.\!k-resize-x {
  resize: horizontal !important;
}

.k-resize-y {
  resize: vertical;
}

.\!k-resize-y {
  resize: vertical !important;
}

.k-scroll-auto {
  scroll-behavior: auto;
}

.\!k-scroll-auto {
  scroll-behavior: auto !important;
}

.k-scroll-smooth {
  scroll-behavior: smooth;
}

.\!k-scroll-smooth {
  scroll-behavior: smooth !important;
}

.k-scroll-snap-none {
  scroll-snap-type: none;
}

.\!k-scroll-snap-none {
  scroll-snap-type: none !important;
}

.k-scroll-snap-snap-x {
  scroll-snap-type: x var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-x {
  scroll-snap-type: x var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-y {
  scroll-snap-type: y var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-y {
  scroll-snap-type: y var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-both {
  scroll-snap-type: both var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-both {
  scroll-snap-type: both var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-mandatory {
  --k-scroll-snap-strictness: mandatory;
}

.k-scroll-snap-proximity {
  --k-scroll-snap-strictness: proximity;
}

.k-scroll-snap-stop-normal {
  scroll-snap-stop: normal;
}

.\!k-scroll-snap-stop-normal {
  scroll-snap-stop: normal !important;
}

.k-scroll-snap-stop-always {
  scroll-snap-stop: always;
}

.\!k-scroll-snap-stop-always {
  scroll-snap-stop: always !important;
}

.k-scroll-snap-align-start {
  scroll-snap-align: start;
}

.\!k-scroll-snap-align-start {
  scroll-snap-align: start !important;
}

.k-scroll-snap-align-end {
  scroll-snap-align: end;
}

.\!k-scroll-snap-align-end {
  scroll-snap-align: end !important;
}

.k-scroll-snap-align-center {
  scroll-snap-align: center;
}

.\!k-scroll-snap-align-center {
  scroll-snap-align: center !important;
}

.k-scroll-snap-align-none {
  scroll-snap-align: none;
}

.\!k-scroll-snap-align-none {
  scroll-snap-align: none !important;
}

.k-scroll-m-0 {
  scroll-margin: 0px;
}

.\!k-scroll-m-0 {
  scroll-margin: 0px !important;
}

.k-scroll-m-1px {
  scroll-margin: 1px;
}

.\!k-scroll-m-1px {
  scroll-margin: 1px !important;
}

.k-scroll-m-0\.5 {
  scroll-margin: 0.125rem;
}

.\!k-scroll-m-0\.5 {
  scroll-margin: 0.125rem !important;
}

.k-scroll-m-1 {
  scroll-margin: 0.25rem;
}

.\!k-scroll-m-1 {
  scroll-margin: 0.25rem !important;
}

.k-scroll-m-1\.5 {
  scroll-margin: 0.375rem;
}

.\!k-scroll-m-1\.5 {
  scroll-margin: 0.375rem !important;
}

.k-scroll-m-2 {
  scroll-margin: 0.5rem;
}

.\!k-scroll-m-2 {
  scroll-margin: 0.5rem !important;
}

.k-scroll-m-2\.5 {
  scroll-margin: 0.625rem;
}

.\!k-scroll-m-2\.5 {
  scroll-margin: 0.625rem !important;
}

.k-scroll-m-3 {
  scroll-margin: 0.75rem;
}

.\!k-scroll-m-3 {
  scroll-margin: 0.75rem !important;
}

.k-scroll-m-3\.5 {
  scroll-margin: 0.875rem;
}

.\!k-scroll-m-3\.5 {
  scroll-margin: 0.875rem !important;
}

.k-scroll-m-4 {
  scroll-margin: 1rem;
}

.\!k-scroll-m-4 {
  scroll-margin: 1rem !important;
}

.k-scroll-m-4\.5 {
  scroll-margin: 1.125rem;
}

.\!k-scroll-m-4\.5 {
  scroll-margin: 1.125rem !important;
}

.k-scroll-m-5 {
  scroll-margin: 1.25rem;
}

.\!k-scroll-m-5 {
  scroll-margin: 1.25rem !important;
}

.k-scroll-m-5\.5 {
  scroll-margin: 1.375rem;
}

.\!k-scroll-m-5\.5 {
  scroll-margin: 1.375rem !important;
}

.k-scroll-m-6 {
  scroll-margin: 1.5rem;
}

.\!k-scroll-m-6 {
  scroll-margin: 1.5rem !important;
}

.k-scroll-m-6\.5 {
  scroll-margin: 1.625rem;
}

.\!k-scroll-m-6\.5 {
  scroll-margin: 1.625rem !important;
}

.k-scroll-m-7 {
  scroll-margin: 1.75rem;
}

.\!k-scroll-m-7 {
  scroll-margin: 1.75rem !important;
}

.k-scroll-m-7\.5 {
  scroll-margin: 1.875rem;
}

.\!k-scroll-m-7\.5 {
  scroll-margin: 1.875rem !important;
}

.k-scroll-m-8 {
  scroll-margin: 2rem;
}

.\!k-scroll-m-8 {
  scroll-margin: 2rem !important;
}

.k-scroll-m-9 {
  scroll-margin: 2.25rem;
}

.\!k-scroll-m-9 {
  scroll-margin: 2.25rem !important;
}

.k-scroll-m-10 {
  scroll-margin: 2.5rem;
}

.\!k-scroll-m-10 {
  scroll-margin: 2.5rem !important;
}

.k-scroll-m-11 {
  scroll-margin: 2.75rem;
}

.\!k-scroll-m-11 {
  scroll-margin: 2.75rem !important;
}

.k-scroll-m-12 {
  scroll-margin: 3rem;
}

.\!k-scroll-m-12 {
  scroll-margin: 3rem !important;
}

.k-scroll-m-13 {
  scroll-margin: 3.25rem;
}

.\!k-scroll-m-13 {
  scroll-margin: 3.25rem !important;
}

.k-scroll-m-14 {
  scroll-margin: 3.5rem;
}

.\!k-scroll-m-14 {
  scroll-margin: 3.5rem !important;
}

.k-scroll-m-15 {
  scroll-margin: 3.75rem;
}

.\!k-scroll-m-15 {
  scroll-margin: 3.75rem !important;
}

.k-scroll-m-16 {
  scroll-margin: 4rem;
}

.\!k-scroll-m-16 {
  scroll-margin: 4rem !important;
}

.k-scroll-m-17 {
  scroll-margin: 4.25rem;
}

.\!k-scroll-m-17 {
  scroll-margin: 4.25rem !important;
}

.k-scroll-m-18 {
  scroll-margin: 4.5rem;
}

.\!k-scroll-m-18 {
  scroll-margin: 4.5rem !important;
}

.k-scroll-m-19 {
  scroll-margin: 4.75rem;
}

.\!k-scroll-m-19 {
  scroll-margin: 4.75rem !important;
}

.k-scroll-m-20 {
  scroll-margin: 5rem;
}

.\!k-scroll-m-20 {
  scroll-margin: 5rem !important;
}

.k-scroll-m-21 {
  scroll-margin: 5.25rem;
}

.\!k-scroll-m-21 {
  scroll-margin: 5.25rem !important;
}

.k-scroll-m-22 {
  scroll-margin: 5.5rem;
}

.\!k-scroll-m-22 {
  scroll-margin: 5.5rem !important;
}

.k-scroll-m-23 {
  scroll-margin: 5.75rem;
}

.\!k-scroll-m-23 {
  scroll-margin: 5.75rem !important;
}

.k-scroll-m-24 {
  scroll-margin: 6rem;
}

.\!k-scroll-m-24 {
  scroll-margin: 6rem !important;
}

.k-scroll-m-25 {
  scroll-margin: 7rem;
}

.\!k-scroll-m-25 {
  scroll-margin: 7rem !important;
}

.k-scroll-m-26 {
  scroll-margin: 8rem;
}

.\!k-scroll-m-26 {
  scroll-margin: 8rem !important;
}

.k-scroll-m-27 {
  scroll-margin: 9rem;
}

.\!k-scroll-m-27 {
  scroll-margin: 9rem !important;
}

.k-scroll-m-28 {
  scroll-margin: 10rem;
}

.\!k-scroll-m-28 {
  scroll-margin: 10rem !important;
}

.k-scroll-m-29 {
  scroll-margin: 11rem;
}

.\!k-scroll-m-29 {
  scroll-margin: 11rem !important;
}

.k-scroll-m-30 {
  scroll-margin: 12rem;
}

.\!k-scroll-m-30 {
  scroll-margin: 12rem !important;
}

.k-scroll-mt-0 {
  scroll-margin-top: 0px;
}

.\!k-scroll-mt-0 {
  scroll-margin-top: 0px !important;
}

.k-scroll-mt-1px {
  scroll-margin-top: 1px;
}

.\!k-scroll-mt-1px {
  scroll-margin-top: 1px !important;
}

.k-scroll-mt-0\.5 {
  scroll-margin-top: 0.125rem;
}

.\!k-scroll-mt-0\.5 {
  scroll-margin-top: 0.125rem !important;
}

.k-scroll-mt-1 {
  scroll-margin-top: 0.25rem;
}

.\!k-scroll-mt-1 {
  scroll-margin-top: 0.25rem !important;
}

.k-scroll-mt-1\.5 {
  scroll-margin-top: 0.375rem;
}

.\!k-scroll-mt-1\.5 {
  scroll-margin-top: 0.375rem !important;
}

.k-scroll-mt-2 {
  scroll-margin-top: 0.5rem;
}

.\!k-scroll-mt-2 {
  scroll-margin-top: 0.5rem !important;
}

.k-scroll-mt-2\.5 {
  scroll-margin-top: 0.625rem;
}

.\!k-scroll-mt-2\.5 {
  scroll-margin-top: 0.625rem !important;
}

.k-scroll-mt-3 {
  scroll-margin-top: 0.75rem;
}

.\!k-scroll-mt-3 {
  scroll-margin-top: 0.75rem !important;
}

.k-scroll-mt-3\.5 {
  scroll-margin-top: 0.875rem;
}

.\!k-scroll-mt-3\.5 {
  scroll-margin-top: 0.875rem !important;
}

.k-scroll-mt-4 {
  scroll-margin-top: 1rem;
}

.\!k-scroll-mt-4 {
  scroll-margin-top: 1rem !important;
}

.k-scroll-mt-4\.5 {
  scroll-margin-top: 1.125rem;
}

.\!k-scroll-mt-4\.5 {
  scroll-margin-top: 1.125rem !important;
}

.k-scroll-mt-5 {
  scroll-margin-top: 1.25rem;
}

.\!k-scroll-mt-5 {
  scroll-margin-top: 1.25rem !important;
}

.k-scroll-mt-5\.5 {
  scroll-margin-top: 1.375rem;
}

.\!k-scroll-mt-5\.5 {
  scroll-margin-top: 1.375rem !important;
}

.k-scroll-mt-6 {
  scroll-margin-top: 1.5rem;
}

.\!k-scroll-mt-6 {
  scroll-margin-top: 1.5rem !important;
}

.k-scroll-mt-6\.5 {
  scroll-margin-top: 1.625rem;
}

.\!k-scroll-mt-6\.5 {
  scroll-margin-top: 1.625rem !important;
}

.k-scroll-mt-7 {
  scroll-margin-top: 1.75rem;
}

.\!k-scroll-mt-7 {
  scroll-margin-top: 1.75rem !important;
}

.k-scroll-mt-7\.5 {
  scroll-margin-top: 1.875rem;
}

.\!k-scroll-mt-7\.5 {
  scroll-margin-top: 1.875rem !important;
}

.k-scroll-mt-8 {
  scroll-margin-top: 2rem;
}

.\!k-scroll-mt-8 {
  scroll-margin-top: 2rem !important;
}

.k-scroll-mt-9 {
  scroll-margin-top: 2.25rem;
}

.\!k-scroll-mt-9 {
  scroll-margin-top: 2.25rem !important;
}

.k-scroll-mt-10 {
  scroll-margin-top: 2.5rem;
}

.\!k-scroll-mt-10 {
  scroll-margin-top: 2.5rem !important;
}

.k-scroll-mt-11 {
  scroll-margin-top: 2.75rem;
}

.\!k-scroll-mt-11 {
  scroll-margin-top: 2.75rem !important;
}

.k-scroll-mt-12 {
  scroll-margin-top: 3rem;
}

.\!k-scroll-mt-12 {
  scroll-margin-top: 3rem !important;
}

.k-scroll-mt-13 {
  scroll-margin-top: 3.25rem;
}

.\!k-scroll-mt-13 {
  scroll-margin-top: 3.25rem !important;
}

.k-scroll-mt-14 {
  scroll-margin-top: 3.5rem;
}

.\!k-scroll-mt-14 {
  scroll-margin-top: 3.5rem !important;
}

.k-scroll-mt-15 {
  scroll-margin-top: 3.75rem;
}

.\!k-scroll-mt-15 {
  scroll-margin-top: 3.75rem !important;
}

.k-scroll-mt-16 {
  scroll-margin-top: 4rem;
}

.\!k-scroll-mt-16 {
  scroll-margin-top: 4rem !important;
}

.k-scroll-mt-17 {
  scroll-margin-top: 4.25rem;
}

.\!k-scroll-mt-17 {
  scroll-margin-top: 4.25rem !important;
}

.k-scroll-mt-18 {
  scroll-margin-top: 4.5rem;
}

.\!k-scroll-mt-18 {
  scroll-margin-top: 4.5rem !important;
}

.k-scroll-mt-19 {
  scroll-margin-top: 4.75rem;
}

.\!k-scroll-mt-19 {
  scroll-margin-top: 4.75rem !important;
}

.k-scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.\!k-scroll-mt-20 {
  scroll-margin-top: 5rem !important;
}

.k-scroll-mt-21 {
  scroll-margin-top: 5.25rem;
}

.\!k-scroll-mt-21 {
  scroll-margin-top: 5.25rem !important;
}

.k-scroll-mt-22 {
  scroll-margin-top: 5.5rem;
}

.\!k-scroll-mt-22 {
  scroll-margin-top: 5.5rem !important;
}

.k-scroll-mt-23 {
  scroll-margin-top: 5.75rem;
}

.\!k-scroll-mt-23 {
  scroll-margin-top: 5.75rem !important;
}

.k-scroll-mt-24 {
  scroll-margin-top: 6rem;
}

.\!k-scroll-mt-24 {
  scroll-margin-top: 6rem !important;
}

.k-scroll-mt-25 {
  scroll-margin-top: 7rem;
}

.\!k-scroll-mt-25 {
  scroll-margin-top: 7rem !important;
}

.k-scroll-mt-26 {
  scroll-margin-top: 8rem;
}

.\!k-scroll-mt-26 {
  scroll-margin-top: 8rem !important;
}

.k-scroll-mt-27 {
  scroll-margin-top: 9rem;
}

.\!k-scroll-mt-27 {
  scroll-margin-top: 9rem !important;
}

.k-scroll-mt-28 {
  scroll-margin-top: 10rem;
}

.\!k-scroll-mt-28 {
  scroll-margin-top: 10rem !important;
}

.k-scroll-mt-29 {
  scroll-margin-top: 11rem;
}

.\!k-scroll-mt-29 {
  scroll-margin-top: 11rem !important;
}

.k-scroll-mt-30 {
  scroll-margin-top: 12rem;
}

.\!k-scroll-mt-30 {
  scroll-margin-top: 12rem !important;
}

.k-scroll-mr-0 {
  scroll-margin-right: 0px;
}

.\!k-scroll-mr-0 {
  scroll-margin-right: 0px !important;
}

.k-scroll-mr-1px {
  scroll-margin-right: 1px;
}

.\!k-scroll-mr-1px {
  scroll-margin-right: 1px !important;
}

.k-scroll-mr-0\.5 {
  scroll-margin-right: 0.125rem;
}

.\!k-scroll-mr-0\.5 {
  scroll-margin-right: 0.125rem !important;
}

.k-scroll-mr-1 {
  scroll-margin-right: 0.25rem;
}

.\!k-scroll-mr-1 {
  scroll-margin-right: 0.25rem !important;
}

.k-scroll-mr-1\.5 {
  scroll-margin-right: 0.375rem;
}

.\!k-scroll-mr-1\.5 {
  scroll-margin-right: 0.375rem !important;
}

.k-scroll-mr-2 {
  scroll-margin-right: 0.5rem;
}

.\!k-scroll-mr-2 {
  scroll-margin-right: 0.5rem !important;
}

.k-scroll-mr-2\.5 {
  scroll-margin-right: 0.625rem;
}

.\!k-scroll-mr-2\.5 {
  scroll-margin-right: 0.625rem !important;
}

.k-scroll-mr-3 {
  scroll-margin-right: 0.75rem;
}

.\!k-scroll-mr-3 {
  scroll-margin-right: 0.75rem !important;
}

.k-scroll-mr-3\.5 {
  scroll-margin-right: 0.875rem;
}

.\!k-scroll-mr-3\.5 {
  scroll-margin-right: 0.875rem !important;
}

.k-scroll-mr-4 {
  scroll-margin-right: 1rem;
}

.\!k-scroll-mr-4 {
  scroll-margin-right: 1rem !important;
}

.k-scroll-mr-4\.5 {
  scroll-margin-right: 1.125rem;
}

.\!k-scroll-mr-4\.5 {
  scroll-margin-right: 1.125rem !important;
}

.k-scroll-mr-5 {
  scroll-margin-right: 1.25rem;
}

.\!k-scroll-mr-5 {
  scroll-margin-right: 1.25rem !important;
}

.k-scroll-mr-5\.5 {
  scroll-margin-right: 1.375rem;
}

.\!k-scroll-mr-5\.5 {
  scroll-margin-right: 1.375rem !important;
}

.k-scroll-mr-6 {
  scroll-margin-right: 1.5rem;
}

.\!k-scroll-mr-6 {
  scroll-margin-right: 1.5rem !important;
}

.k-scroll-mr-6\.5 {
  scroll-margin-right: 1.625rem;
}

.\!k-scroll-mr-6\.5 {
  scroll-margin-right: 1.625rem !important;
}

.k-scroll-mr-7 {
  scroll-margin-right: 1.75rem;
}

.\!k-scroll-mr-7 {
  scroll-margin-right: 1.75rem !important;
}

.k-scroll-mr-7\.5 {
  scroll-margin-right: 1.875rem;
}

.\!k-scroll-mr-7\.5 {
  scroll-margin-right: 1.875rem !important;
}

.k-scroll-mr-8 {
  scroll-margin-right: 2rem;
}

.\!k-scroll-mr-8 {
  scroll-margin-right: 2rem !important;
}

.k-scroll-mr-9 {
  scroll-margin-right: 2.25rem;
}

.\!k-scroll-mr-9 {
  scroll-margin-right: 2.25rem !important;
}

.k-scroll-mr-10 {
  scroll-margin-right: 2.5rem;
}

.\!k-scroll-mr-10 {
  scroll-margin-right: 2.5rem !important;
}

.k-scroll-mr-11 {
  scroll-margin-right: 2.75rem;
}

.\!k-scroll-mr-11 {
  scroll-margin-right: 2.75rem !important;
}

.k-scroll-mr-12 {
  scroll-margin-right: 3rem;
}

.\!k-scroll-mr-12 {
  scroll-margin-right: 3rem !important;
}

.k-scroll-mr-13 {
  scroll-margin-right: 3.25rem;
}

.\!k-scroll-mr-13 {
  scroll-margin-right: 3.25rem !important;
}

.k-scroll-mr-14 {
  scroll-margin-right: 3.5rem;
}

.\!k-scroll-mr-14 {
  scroll-margin-right: 3.5rem !important;
}

.k-scroll-mr-15 {
  scroll-margin-right: 3.75rem;
}

.\!k-scroll-mr-15 {
  scroll-margin-right: 3.75rem !important;
}

.k-scroll-mr-16 {
  scroll-margin-right: 4rem;
}

.\!k-scroll-mr-16 {
  scroll-margin-right: 4rem !important;
}

.k-scroll-mr-17 {
  scroll-margin-right: 4.25rem;
}

.\!k-scroll-mr-17 {
  scroll-margin-right: 4.25rem !important;
}

.k-scroll-mr-18 {
  scroll-margin-right: 4.5rem;
}

.\!k-scroll-mr-18 {
  scroll-margin-right: 4.5rem !important;
}

.k-scroll-mr-19 {
  scroll-margin-right: 4.75rem;
}

.\!k-scroll-mr-19 {
  scroll-margin-right: 4.75rem !important;
}

.k-scroll-mr-20 {
  scroll-margin-right: 5rem;
}

.\!k-scroll-mr-20 {
  scroll-margin-right: 5rem !important;
}

.k-scroll-mr-21 {
  scroll-margin-right: 5.25rem;
}

.\!k-scroll-mr-21 {
  scroll-margin-right: 5.25rem !important;
}

.k-scroll-mr-22 {
  scroll-margin-right: 5.5rem;
}

.\!k-scroll-mr-22 {
  scroll-margin-right: 5.5rem !important;
}

.k-scroll-mr-23 {
  scroll-margin-right: 5.75rem;
}

.\!k-scroll-mr-23 {
  scroll-margin-right: 5.75rem !important;
}

.k-scroll-mr-24 {
  scroll-margin-right: 6rem;
}

.\!k-scroll-mr-24 {
  scroll-margin-right: 6rem !important;
}

.k-scroll-mr-25 {
  scroll-margin-right: 7rem;
}

.\!k-scroll-mr-25 {
  scroll-margin-right: 7rem !important;
}

.k-scroll-mr-26 {
  scroll-margin-right: 8rem;
}

.\!k-scroll-mr-26 {
  scroll-margin-right: 8rem !important;
}

.k-scroll-mr-27 {
  scroll-margin-right: 9rem;
}

.\!k-scroll-mr-27 {
  scroll-margin-right: 9rem !important;
}

.k-scroll-mr-28 {
  scroll-margin-right: 10rem;
}

.\!k-scroll-mr-28 {
  scroll-margin-right: 10rem !important;
}

.k-scroll-mr-29 {
  scroll-margin-right: 11rem;
}

.\!k-scroll-mr-29 {
  scroll-margin-right: 11rem !important;
}

.k-scroll-mr-30 {
  scroll-margin-right: 12rem;
}

.\!k-scroll-mr-30 {
  scroll-margin-right: 12rem !important;
}

.k-scroll-mb-0 {
  scroll-margin-bottom: 0px;
}

.\!k-scroll-mb-0 {
  scroll-margin-bottom: 0px !important;
}

.k-scroll-mb-1px {
  scroll-margin-bottom: 1px;
}

.\!k-scroll-mb-1px {
  scroll-margin-bottom: 1px !important;
}

.k-scroll-mb-0\.5 {
  scroll-margin-bottom: 0.125rem;
}

.\!k-scroll-mb-0\.5 {
  scroll-margin-bottom: 0.125rem !important;
}

.k-scroll-mb-1 {
  scroll-margin-bottom: 0.25rem;
}

.\!k-scroll-mb-1 {
  scroll-margin-bottom: 0.25rem !important;
}

.k-scroll-mb-1\.5 {
  scroll-margin-bottom: 0.375rem;
}

.\!k-scroll-mb-1\.5 {
  scroll-margin-bottom: 0.375rem !important;
}

.k-scroll-mb-2 {
  scroll-margin-bottom: 0.5rem;
}

.\!k-scroll-mb-2 {
  scroll-margin-bottom: 0.5rem !important;
}

.k-scroll-mb-2\.5 {
  scroll-margin-bottom: 0.625rem;
}

.\!k-scroll-mb-2\.5 {
  scroll-margin-bottom: 0.625rem !important;
}

.k-scroll-mb-3 {
  scroll-margin-bottom: 0.75rem;
}

.\!k-scroll-mb-3 {
  scroll-margin-bottom: 0.75rem !important;
}

.k-scroll-mb-3\.5 {
  scroll-margin-bottom: 0.875rem;
}

.\!k-scroll-mb-3\.5 {
  scroll-margin-bottom: 0.875rem !important;
}

.k-scroll-mb-4 {
  scroll-margin-bottom: 1rem;
}

.\!k-scroll-mb-4 {
  scroll-margin-bottom: 1rem !important;
}

.k-scroll-mb-4\.5 {
  scroll-margin-bottom: 1.125rem;
}

.\!k-scroll-mb-4\.5 {
  scroll-margin-bottom: 1.125rem !important;
}

.k-scroll-mb-5 {
  scroll-margin-bottom: 1.25rem;
}

.\!k-scroll-mb-5 {
  scroll-margin-bottom: 1.25rem !important;
}

.k-scroll-mb-5\.5 {
  scroll-margin-bottom: 1.375rem;
}

.\!k-scroll-mb-5\.5 {
  scroll-margin-bottom: 1.375rem !important;
}

.k-scroll-mb-6 {
  scroll-margin-bottom: 1.5rem;
}

.\!k-scroll-mb-6 {
  scroll-margin-bottom: 1.5rem !important;
}

.k-scroll-mb-6\.5 {
  scroll-margin-bottom: 1.625rem;
}

.\!k-scroll-mb-6\.5 {
  scroll-margin-bottom: 1.625rem !important;
}

.k-scroll-mb-7 {
  scroll-margin-bottom: 1.75rem;
}

.\!k-scroll-mb-7 {
  scroll-margin-bottom: 1.75rem !important;
}

.k-scroll-mb-7\.5 {
  scroll-margin-bottom: 1.875rem;
}

.\!k-scroll-mb-7\.5 {
  scroll-margin-bottom: 1.875rem !important;
}

.k-scroll-mb-8 {
  scroll-margin-bottom: 2rem;
}

.\!k-scroll-mb-8 {
  scroll-margin-bottom: 2rem !important;
}

.k-scroll-mb-9 {
  scroll-margin-bottom: 2.25rem;
}

.\!k-scroll-mb-9 {
  scroll-margin-bottom: 2.25rem !important;
}

.k-scroll-mb-10 {
  scroll-margin-bottom: 2.5rem;
}

.\!k-scroll-mb-10 {
  scroll-margin-bottom: 2.5rem !important;
}

.k-scroll-mb-11 {
  scroll-margin-bottom: 2.75rem;
}

.\!k-scroll-mb-11 {
  scroll-margin-bottom: 2.75rem !important;
}

.k-scroll-mb-12 {
  scroll-margin-bottom: 3rem;
}

.\!k-scroll-mb-12 {
  scroll-margin-bottom: 3rem !important;
}

.k-scroll-mb-13 {
  scroll-margin-bottom: 3.25rem;
}

.\!k-scroll-mb-13 {
  scroll-margin-bottom: 3.25rem !important;
}

.k-scroll-mb-14 {
  scroll-margin-bottom: 3.5rem;
}

.\!k-scroll-mb-14 {
  scroll-margin-bottom: 3.5rem !important;
}

.k-scroll-mb-15 {
  scroll-margin-bottom: 3.75rem;
}

.\!k-scroll-mb-15 {
  scroll-margin-bottom: 3.75rem !important;
}

.k-scroll-mb-16 {
  scroll-margin-bottom: 4rem;
}

.\!k-scroll-mb-16 {
  scroll-margin-bottom: 4rem !important;
}

.k-scroll-mb-17 {
  scroll-margin-bottom: 4.25rem;
}

.\!k-scroll-mb-17 {
  scroll-margin-bottom: 4.25rem !important;
}

.k-scroll-mb-18 {
  scroll-margin-bottom: 4.5rem;
}

.\!k-scroll-mb-18 {
  scroll-margin-bottom: 4.5rem !important;
}

.k-scroll-mb-19 {
  scroll-margin-bottom: 4.75rem;
}

.\!k-scroll-mb-19 {
  scroll-margin-bottom: 4.75rem !important;
}

.k-scroll-mb-20 {
  scroll-margin-bottom: 5rem;
}

.\!k-scroll-mb-20 {
  scroll-margin-bottom: 5rem !important;
}

.k-scroll-mb-21 {
  scroll-margin-bottom: 5.25rem;
}

.\!k-scroll-mb-21 {
  scroll-margin-bottom: 5.25rem !important;
}

.k-scroll-mb-22 {
  scroll-margin-bottom: 5.5rem;
}

.\!k-scroll-mb-22 {
  scroll-margin-bottom: 5.5rem !important;
}

.k-scroll-mb-23 {
  scroll-margin-bottom: 5.75rem;
}

.\!k-scroll-mb-23 {
  scroll-margin-bottom: 5.75rem !important;
}

.k-scroll-mb-24 {
  scroll-margin-bottom: 6rem;
}

.\!k-scroll-mb-24 {
  scroll-margin-bottom: 6rem !important;
}

.k-scroll-mb-25 {
  scroll-margin-bottom: 7rem;
}

.\!k-scroll-mb-25 {
  scroll-margin-bottom: 7rem !important;
}

.k-scroll-mb-26 {
  scroll-margin-bottom: 8rem;
}

.\!k-scroll-mb-26 {
  scroll-margin-bottom: 8rem !important;
}

.k-scroll-mb-27 {
  scroll-margin-bottom: 9rem;
}

.\!k-scroll-mb-27 {
  scroll-margin-bottom: 9rem !important;
}

.k-scroll-mb-28 {
  scroll-margin-bottom: 10rem;
}

.\!k-scroll-mb-28 {
  scroll-margin-bottom: 10rem !important;
}

.k-scroll-mb-29 {
  scroll-margin-bottom: 11rem;
}

.\!k-scroll-mb-29 {
  scroll-margin-bottom: 11rem !important;
}

.k-scroll-mb-30 {
  scroll-margin-bottom: 12rem;
}

.\!k-scroll-mb-30 {
  scroll-margin-bottom: 12rem !important;
}

.k-scroll-ml-0 {
  scroll-margin-left: 0px;
}

.\!k-scroll-ml-0 {
  scroll-margin-left: 0px !important;
}

.k-scroll-ml-1px {
  scroll-margin-left: 1px;
}

.\!k-scroll-ml-1px {
  scroll-margin-left: 1px !important;
}

.k-scroll-ml-0\.5 {
  scroll-margin-left: 0.125rem;
}

.\!k-scroll-ml-0\.5 {
  scroll-margin-left: 0.125rem !important;
}

.k-scroll-ml-1 {
  scroll-margin-left: 0.25rem;
}

.\!k-scroll-ml-1 {
  scroll-margin-left: 0.25rem !important;
}

.k-scroll-ml-1\.5 {
  scroll-margin-left: 0.375rem;
}

.\!k-scroll-ml-1\.5 {
  scroll-margin-left: 0.375rem !important;
}

.k-scroll-ml-2 {
  scroll-margin-left: 0.5rem;
}

.\!k-scroll-ml-2 {
  scroll-margin-left: 0.5rem !important;
}

.k-scroll-ml-2\.5 {
  scroll-margin-left: 0.625rem;
}

.\!k-scroll-ml-2\.5 {
  scroll-margin-left: 0.625rem !important;
}

.k-scroll-ml-3 {
  scroll-margin-left: 0.75rem;
}

.\!k-scroll-ml-3 {
  scroll-margin-left: 0.75rem !important;
}

.k-scroll-ml-3\.5 {
  scroll-margin-left: 0.875rem;
}

.\!k-scroll-ml-3\.5 {
  scroll-margin-left: 0.875rem !important;
}

.k-scroll-ml-4 {
  scroll-margin-left: 1rem;
}

.\!k-scroll-ml-4 {
  scroll-margin-left: 1rem !important;
}

.k-scroll-ml-4\.5 {
  scroll-margin-left: 1.125rem;
}

.\!k-scroll-ml-4\.5 {
  scroll-margin-left: 1.125rem !important;
}

.k-scroll-ml-5 {
  scroll-margin-left: 1.25rem;
}

.\!k-scroll-ml-5 {
  scroll-margin-left: 1.25rem !important;
}

.k-scroll-ml-5\.5 {
  scroll-margin-left: 1.375rem;
}

.\!k-scroll-ml-5\.5 {
  scroll-margin-left: 1.375rem !important;
}

.k-scroll-ml-6 {
  scroll-margin-left: 1.5rem;
}

.\!k-scroll-ml-6 {
  scroll-margin-left: 1.5rem !important;
}

.k-scroll-ml-6\.5 {
  scroll-margin-left: 1.625rem;
}

.\!k-scroll-ml-6\.5 {
  scroll-margin-left: 1.625rem !important;
}

.k-scroll-ml-7 {
  scroll-margin-left: 1.75rem;
}

.\!k-scroll-ml-7 {
  scroll-margin-left: 1.75rem !important;
}

.k-scroll-ml-7\.5 {
  scroll-margin-left: 1.875rem;
}

.\!k-scroll-ml-7\.5 {
  scroll-margin-left: 1.875rem !important;
}

.k-scroll-ml-8 {
  scroll-margin-left: 2rem;
}

.\!k-scroll-ml-8 {
  scroll-margin-left: 2rem !important;
}

.k-scroll-ml-9 {
  scroll-margin-left: 2.25rem;
}

.\!k-scroll-ml-9 {
  scroll-margin-left: 2.25rem !important;
}

.k-scroll-ml-10 {
  scroll-margin-left: 2.5rem;
}

.\!k-scroll-ml-10 {
  scroll-margin-left: 2.5rem !important;
}

.k-scroll-ml-11 {
  scroll-margin-left: 2.75rem;
}

.\!k-scroll-ml-11 {
  scroll-margin-left: 2.75rem !important;
}

.k-scroll-ml-12 {
  scroll-margin-left: 3rem;
}

.\!k-scroll-ml-12 {
  scroll-margin-left: 3rem !important;
}

.k-scroll-ml-13 {
  scroll-margin-left: 3.25rem;
}

.\!k-scroll-ml-13 {
  scroll-margin-left: 3.25rem !important;
}

.k-scroll-ml-14 {
  scroll-margin-left: 3.5rem;
}

.\!k-scroll-ml-14 {
  scroll-margin-left: 3.5rem !important;
}

.k-scroll-ml-15 {
  scroll-margin-left: 3.75rem;
}

.\!k-scroll-ml-15 {
  scroll-margin-left: 3.75rem !important;
}

.k-scroll-ml-16 {
  scroll-margin-left: 4rem;
}

.\!k-scroll-ml-16 {
  scroll-margin-left: 4rem !important;
}

.k-scroll-ml-17 {
  scroll-margin-left: 4.25rem;
}

.\!k-scroll-ml-17 {
  scroll-margin-left: 4.25rem !important;
}

.k-scroll-ml-18 {
  scroll-margin-left: 4.5rem;
}

.\!k-scroll-ml-18 {
  scroll-margin-left: 4.5rem !important;
}

.k-scroll-ml-19 {
  scroll-margin-left: 4.75rem;
}

.\!k-scroll-ml-19 {
  scroll-margin-left: 4.75rem !important;
}

.k-scroll-ml-20 {
  scroll-margin-left: 5rem;
}

.\!k-scroll-ml-20 {
  scroll-margin-left: 5rem !important;
}

.k-scroll-ml-21 {
  scroll-margin-left: 5.25rem;
}

.\!k-scroll-ml-21 {
  scroll-margin-left: 5.25rem !important;
}

.k-scroll-ml-22 {
  scroll-margin-left: 5.5rem;
}

.\!k-scroll-ml-22 {
  scroll-margin-left: 5.5rem !important;
}

.k-scroll-ml-23 {
  scroll-margin-left: 5.75rem;
}

.\!k-scroll-ml-23 {
  scroll-margin-left: 5.75rem !important;
}

.k-scroll-ml-24 {
  scroll-margin-left: 6rem;
}

.\!k-scroll-ml-24 {
  scroll-margin-left: 6rem !important;
}

.k-scroll-ml-25 {
  scroll-margin-left: 7rem;
}

.\!k-scroll-ml-25 {
  scroll-margin-left: 7rem !important;
}

.k-scroll-ml-26 {
  scroll-margin-left: 8rem;
}

.\!k-scroll-ml-26 {
  scroll-margin-left: 8rem !important;
}

.k-scroll-ml-27 {
  scroll-margin-left: 9rem;
}

.\!k-scroll-ml-27 {
  scroll-margin-left: 9rem !important;
}

.k-scroll-ml-28 {
  scroll-margin-left: 10rem;
}

.\!k-scroll-ml-28 {
  scroll-margin-left: 10rem !important;
}

.k-scroll-ml-29 {
  scroll-margin-left: 11rem;
}

.\!k-scroll-ml-29 {
  scroll-margin-left: 11rem !important;
}

.k-scroll-ml-30 {
  scroll-margin-left: 12rem;
}

.\!k-scroll-ml-30 {
  scroll-margin-left: 12rem !important;
}

.k-scroll-mx-0 {
  scroll-margin-inline: 0px;
}

.\!k-scroll-mx-0 {
  scroll-margin-inline: 0px !important;
}

.k-scroll-mx-1px {
  scroll-margin-inline: 1px;
}

.\!k-scroll-mx-1px {
  scroll-margin-inline: 1px !important;
}

.k-scroll-mx-0\.5 {
  scroll-margin-inline: 0.125rem;
}

.\!k-scroll-mx-0\.5 {
  scroll-margin-inline: 0.125rem !important;
}

.k-scroll-mx-1 {
  scroll-margin-inline: 0.25rem;
}

.\!k-scroll-mx-1 {
  scroll-margin-inline: 0.25rem !important;
}

.k-scroll-mx-1\.5 {
  scroll-margin-inline: 0.375rem;
}

.\!k-scroll-mx-1\.5 {
  scroll-margin-inline: 0.375rem !important;
}

.k-scroll-mx-2 {
  scroll-margin-inline: 0.5rem;
}

.\!k-scroll-mx-2 {
  scroll-margin-inline: 0.5rem !important;
}

.k-scroll-mx-2\.5 {
  scroll-margin-inline: 0.625rem;
}

.\!k-scroll-mx-2\.5 {
  scroll-margin-inline: 0.625rem !important;
}

.k-scroll-mx-3 {
  scroll-margin-inline: 0.75rem;
}

.\!k-scroll-mx-3 {
  scroll-margin-inline: 0.75rem !important;
}

.k-scroll-mx-3\.5 {
  scroll-margin-inline: 0.875rem;
}

.\!k-scroll-mx-3\.5 {
  scroll-margin-inline: 0.875rem !important;
}

.k-scroll-mx-4 {
  scroll-margin-inline: 1rem;
}

.\!k-scroll-mx-4 {
  scroll-margin-inline: 1rem !important;
}

.k-scroll-mx-4\.5 {
  scroll-margin-inline: 1.125rem;
}

.\!k-scroll-mx-4\.5 {
  scroll-margin-inline: 1.125rem !important;
}

.k-scroll-mx-5 {
  scroll-margin-inline: 1.25rem;
}

.\!k-scroll-mx-5 {
  scroll-margin-inline: 1.25rem !important;
}

.k-scroll-mx-5\.5 {
  scroll-margin-inline: 1.375rem;
}

.\!k-scroll-mx-5\.5 {
  scroll-margin-inline: 1.375rem !important;
}

.k-scroll-mx-6 {
  scroll-margin-inline: 1.5rem;
}

.\!k-scroll-mx-6 {
  scroll-margin-inline: 1.5rem !important;
}

.k-scroll-mx-6\.5 {
  scroll-margin-inline: 1.625rem;
}

.\!k-scroll-mx-6\.5 {
  scroll-margin-inline: 1.625rem !important;
}

.k-scroll-mx-7 {
  scroll-margin-inline: 1.75rem;
}

.\!k-scroll-mx-7 {
  scroll-margin-inline: 1.75rem !important;
}

.k-scroll-mx-7\.5 {
  scroll-margin-inline: 1.875rem;
}

.\!k-scroll-mx-7\.5 {
  scroll-margin-inline: 1.875rem !important;
}

.k-scroll-mx-8 {
  scroll-margin-inline: 2rem;
}

.\!k-scroll-mx-8 {
  scroll-margin-inline: 2rem !important;
}

.k-scroll-mx-9 {
  scroll-margin-inline: 2.25rem;
}

.\!k-scroll-mx-9 {
  scroll-margin-inline: 2.25rem !important;
}

.k-scroll-mx-10 {
  scroll-margin-inline: 2.5rem;
}

.\!k-scroll-mx-10 {
  scroll-margin-inline: 2.5rem !important;
}

.k-scroll-mx-11 {
  scroll-margin-inline: 2.75rem;
}

.\!k-scroll-mx-11 {
  scroll-margin-inline: 2.75rem !important;
}

.k-scroll-mx-12 {
  scroll-margin-inline: 3rem;
}

.\!k-scroll-mx-12 {
  scroll-margin-inline: 3rem !important;
}

.k-scroll-mx-13 {
  scroll-margin-inline: 3.25rem;
}

.\!k-scroll-mx-13 {
  scroll-margin-inline: 3.25rem !important;
}

.k-scroll-mx-14 {
  scroll-margin-inline: 3.5rem;
}

.\!k-scroll-mx-14 {
  scroll-margin-inline: 3.5rem !important;
}

.k-scroll-mx-15 {
  scroll-margin-inline: 3.75rem;
}

.\!k-scroll-mx-15 {
  scroll-margin-inline: 3.75rem !important;
}

.k-scroll-mx-16 {
  scroll-margin-inline: 4rem;
}

.\!k-scroll-mx-16 {
  scroll-margin-inline: 4rem !important;
}

.k-scroll-mx-17 {
  scroll-margin-inline: 4.25rem;
}

.\!k-scroll-mx-17 {
  scroll-margin-inline: 4.25rem !important;
}

.k-scroll-mx-18 {
  scroll-margin-inline: 4.5rem;
}

.\!k-scroll-mx-18 {
  scroll-margin-inline: 4.5rem !important;
}

.k-scroll-mx-19 {
  scroll-margin-inline: 4.75rem;
}

.\!k-scroll-mx-19 {
  scroll-margin-inline: 4.75rem !important;
}

.k-scroll-mx-20 {
  scroll-margin-inline: 5rem;
}

.\!k-scroll-mx-20 {
  scroll-margin-inline: 5rem !important;
}

.k-scroll-mx-21 {
  scroll-margin-inline: 5.25rem;
}

.\!k-scroll-mx-21 {
  scroll-margin-inline: 5.25rem !important;
}

.k-scroll-mx-22 {
  scroll-margin-inline: 5.5rem;
}

.\!k-scroll-mx-22 {
  scroll-margin-inline: 5.5rem !important;
}

.k-scroll-mx-23 {
  scroll-margin-inline: 5.75rem;
}

.\!k-scroll-mx-23 {
  scroll-margin-inline: 5.75rem !important;
}

.k-scroll-mx-24 {
  scroll-margin-inline: 6rem;
}

.\!k-scroll-mx-24 {
  scroll-margin-inline: 6rem !important;
}

.k-scroll-mx-25 {
  scroll-margin-inline: 7rem;
}

.\!k-scroll-mx-25 {
  scroll-margin-inline: 7rem !important;
}

.k-scroll-mx-26 {
  scroll-margin-inline: 8rem;
}

.\!k-scroll-mx-26 {
  scroll-margin-inline: 8rem !important;
}

.k-scroll-mx-27 {
  scroll-margin-inline: 9rem;
}

.\!k-scroll-mx-27 {
  scroll-margin-inline: 9rem !important;
}

.k-scroll-mx-28 {
  scroll-margin-inline: 10rem;
}

.\!k-scroll-mx-28 {
  scroll-margin-inline: 10rem !important;
}

.k-scroll-mx-29 {
  scroll-margin-inline: 11rem;
}

.\!k-scroll-mx-29 {
  scroll-margin-inline: 11rem !important;
}

.k-scroll-mx-30 {
  scroll-margin-inline: 12rem;
}

.\!k-scroll-mx-30 {
  scroll-margin-inline: 12rem !important;
}

.k-scroll-my-0 {
  scroll-margin-block: 0px;
}

.\!k-scroll-my-0 {
  scroll-margin-block: 0px !important;
}

.k-scroll-my-1px {
  scroll-margin-block: 1px;
}

.\!k-scroll-my-1px {
  scroll-margin-block: 1px !important;
}

.k-scroll-my-0\.5 {
  scroll-margin-block: 0.125rem;
}

.\!k-scroll-my-0\.5 {
  scroll-margin-block: 0.125rem !important;
}

.k-scroll-my-1 {
  scroll-margin-block: 0.25rem;
}

.\!k-scroll-my-1 {
  scroll-margin-block: 0.25rem !important;
}

.k-scroll-my-1\.5 {
  scroll-margin-block: 0.375rem;
}

.\!k-scroll-my-1\.5 {
  scroll-margin-block: 0.375rem !important;
}

.k-scroll-my-2 {
  scroll-margin-block: 0.5rem;
}

.\!k-scroll-my-2 {
  scroll-margin-block: 0.5rem !important;
}

.k-scroll-my-2\.5 {
  scroll-margin-block: 0.625rem;
}

.\!k-scroll-my-2\.5 {
  scroll-margin-block: 0.625rem !important;
}

.k-scroll-my-3 {
  scroll-margin-block: 0.75rem;
}

.\!k-scroll-my-3 {
  scroll-margin-block: 0.75rem !important;
}

.k-scroll-my-3\.5 {
  scroll-margin-block: 0.875rem;
}

.\!k-scroll-my-3\.5 {
  scroll-margin-block: 0.875rem !important;
}

.k-scroll-my-4 {
  scroll-margin-block: 1rem;
}

.\!k-scroll-my-4 {
  scroll-margin-block: 1rem !important;
}

.k-scroll-my-4\.5 {
  scroll-margin-block: 1.125rem;
}

.\!k-scroll-my-4\.5 {
  scroll-margin-block: 1.125rem !important;
}

.k-scroll-my-5 {
  scroll-margin-block: 1.25rem;
}

.\!k-scroll-my-5 {
  scroll-margin-block: 1.25rem !important;
}

.k-scroll-my-5\.5 {
  scroll-margin-block: 1.375rem;
}

.\!k-scroll-my-5\.5 {
  scroll-margin-block: 1.375rem !important;
}

.k-scroll-my-6 {
  scroll-margin-block: 1.5rem;
}

.\!k-scroll-my-6 {
  scroll-margin-block: 1.5rem !important;
}

.k-scroll-my-6\.5 {
  scroll-margin-block: 1.625rem;
}

.\!k-scroll-my-6\.5 {
  scroll-margin-block: 1.625rem !important;
}

.k-scroll-my-7 {
  scroll-margin-block: 1.75rem;
}

.\!k-scroll-my-7 {
  scroll-margin-block: 1.75rem !important;
}

.k-scroll-my-7\.5 {
  scroll-margin-block: 1.875rem;
}

.\!k-scroll-my-7\.5 {
  scroll-margin-block: 1.875rem !important;
}

.k-scroll-my-8 {
  scroll-margin-block: 2rem;
}

.\!k-scroll-my-8 {
  scroll-margin-block: 2rem !important;
}

.k-scroll-my-9 {
  scroll-margin-block: 2.25rem;
}

.\!k-scroll-my-9 {
  scroll-margin-block: 2.25rem !important;
}

.k-scroll-my-10 {
  scroll-margin-block: 2.5rem;
}

.\!k-scroll-my-10 {
  scroll-margin-block: 2.5rem !important;
}

.k-scroll-my-11 {
  scroll-margin-block: 2.75rem;
}

.\!k-scroll-my-11 {
  scroll-margin-block: 2.75rem !important;
}

.k-scroll-my-12 {
  scroll-margin-block: 3rem;
}

.\!k-scroll-my-12 {
  scroll-margin-block: 3rem !important;
}

.k-scroll-my-13 {
  scroll-margin-block: 3.25rem;
}

.\!k-scroll-my-13 {
  scroll-margin-block: 3.25rem !important;
}

.k-scroll-my-14 {
  scroll-margin-block: 3.5rem;
}

.\!k-scroll-my-14 {
  scroll-margin-block: 3.5rem !important;
}

.k-scroll-my-15 {
  scroll-margin-block: 3.75rem;
}

.\!k-scroll-my-15 {
  scroll-margin-block: 3.75rem !important;
}

.k-scroll-my-16 {
  scroll-margin-block: 4rem;
}

.\!k-scroll-my-16 {
  scroll-margin-block: 4rem !important;
}

.k-scroll-my-17 {
  scroll-margin-block: 4.25rem;
}

.\!k-scroll-my-17 {
  scroll-margin-block: 4.25rem !important;
}

.k-scroll-my-18 {
  scroll-margin-block: 4.5rem;
}

.\!k-scroll-my-18 {
  scroll-margin-block: 4.5rem !important;
}

.k-scroll-my-19 {
  scroll-margin-block: 4.75rem;
}

.\!k-scroll-my-19 {
  scroll-margin-block: 4.75rem !important;
}

.k-scroll-my-20 {
  scroll-margin-block: 5rem;
}

.\!k-scroll-my-20 {
  scroll-margin-block: 5rem !important;
}

.k-scroll-my-21 {
  scroll-margin-block: 5.25rem;
}

.\!k-scroll-my-21 {
  scroll-margin-block: 5.25rem !important;
}

.k-scroll-my-22 {
  scroll-margin-block: 5.5rem;
}

.\!k-scroll-my-22 {
  scroll-margin-block: 5.5rem !important;
}

.k-scroll-my-23 {
  scroll-margin-block: 5.75rem;
}

.\!k-scroll-my-23 {
  scroll-margin-block: 5.75rem !important;
}

.k-scroll-my-24 {
  scroll-margin-block: 6rem;
}

.\!k-scroll-my-24 {
  scroll-margin-block: 6rem !important;
}

.k-scroll-my-25 {
  scroll-margin-block: 7rem;
}

.\!k-scroll-my-25 {
  scroll-margin-block: 7rem !important;
}

.k-scroll-my-26 {
  scroll-margin-block: 8rem;
}

.\!k-scroll-my-26 {
  scroll-margin-block: 8rem !important;
}

.k-scroll-my-27 {
  scroll-margin-block: 9rem;
}

.\!k-scroll-my-27 {
  scroll-margin-block: 9rem !important;
}

.k-scroll-my-28 {
  scroll-margin-block: 10rem;
}

.\!k-scroll-my-28 {
  scroll-margin-block: 10rem !important;
}

.k-scroll-my-29 {
  scroll-margin-block: 11rem;
}

.\!k-scroll-my-29 {
  scroll-margin-block: 11rem !important;
}

.k-scroll-my-30 {
  scroll-margin-block: 12rem;
}

.\!k-scroll-my-30 {
  scroll-margin-block: 12rem !important;
}

.k-scroll-p-0 {
  scroll-padding: 0px;
}

.\!k-scroll-p-0 {
  scroll-padding: 0px !important;
}

.k-scroll-p-1px {
  scroll-padding: 1px;
}

.\!k-scroll-p-1px {
  scroll-padding: 1px !important;
}

.k-scroll-p-0\.5 {
  scroll-padding: 0.125rem;
}

.\!k-scroll-p-0\.5 {
  scroll-padding: 0.125rem !important;
}

.k-scroll-p-1 {
  scroll-padding: 0.25rem;
}

.\!k-scroll-p-1 {
  scroll-padding: 0.25rem !important;
}

.k-scroll-p-1\.5 {
  scroll-padding: 0.375rem;
}

.\!k-scroll-p-1\.5 {
  scroll-padding: 0.375rem !important;
}

.k-scroll-p-2 {
  scroll-padding: 0.5rem;
}

.\!k-scroll-p-2 {
  scroll-padding: 0.5rem !important;
}

.k-scroll-p-2\.5 {
  scroll-padding: 0.625rem;
}

.\!k-scroll-p-2\.5 {
  scroll-padding: 0.625rem !important;
}

.k-scroll-p-3 {
  scroll-padding: 0.75rem;
}

.\!k-scroll-p-3 {
  scroll-padding: 0.75rem !important;
}

.k-scroll-p-3\.5 {
  scroll-padding: 0.875rem;
}

.\!k-scroll-p-3\.5 {
  scroll-padding: 0.875rem !important;
}

.k-scroll-p-4 {
  scroll-padding: 1rem;
}

.\!k-scroll-p-4 {
  scroll-padding: 1rem !important;
}

.k-scroll-p-4\.5 {
  scroll-padding: 1.125rem;
}

.\!k-scroll-p-4\.5 {
  scroll-padding: 1.125rem !important;
}

.k-scroll-p-5 {
  scroll-padding: 1.25rem;
}

.\!k-scroll-p-5 {
  scroll-padding: 1.25rem !important;
}

.k-scroll-p-5\.5 {
  scroll-padding: 1.375rem;
}

.\!k-scroll-p-5\.5 {
  scroll-padding: 1.375rem !important;
}

.k-scroll-p-6 {
  scroll-padding: 1.5rem;
}

.\!k-scroll-p-6 {
  scroll-padding: 1.5rem !important;
}

.k-scroll-p-6\.5 {
  scroll-padding: 1.625rem;
}

.\!k-scroll-p-6\.5 {
  scroll-padding: 1.625rem !important;
}

.k-scroll-p-7 {
  scroll-padding: 1.75rem;
}

.\!k-scroll-p-7 {
  scroll-padding: 1.75rem !important;
}

.k-scroll-p-7\.5 {
  scroll-padding: 1.875rem;
}

.\!k-scroll-p-7\.5 {
  scroll-padding: 1.875rem !important;
}

.k-scroll-p-8 {
  scroll-padding: 2rem;
}

.\!k-scroll-p-8 {
  scroll-padding: 2rem !important;
}

.k-scroll-p-9 {
  scroll-padding: 2.25rem;
}

.\!k-scroll-p-9 {
  scroll-padding: 2.25rem !important;
}

.k-scroll-p-10 {
  scroll-padding: 2.5rem;
}

.\!k-scroll-p-10 {
  scroll-padding: 2.5rem !important;
}

.k-scroll-p-11 {
  scroll-padding: 2.75rem;
}

.\!k-scroll-p-11 {
  scroll-padding: 2.75rem !important;
}

.k-scroll-p-12 {
  scroll-padding: 3rem;
}

.\!k-scroll-p-12 {
  scroll-padding: 3rem !important;
}

.k-scroll-p-13 {
  scroll-padding: 3.25rem;
}

.\!k-scroll-p-13 {
  scroll-padding: 3.25rem !important;
}

.k-scroll-p-14 {
  scroll-padding: 3.5rem;
}

.\!k-scroll-p-14 {
  scroll-padding: 3.5rem !important;
}

.k-scroll-p-15 {
  scroll-padding: 3.75rem;
}

.\!k-scroll-p-15 {
  scroll-padding: 3.75rem !important;
}

.k-scroll-p-16 {
  scroll-padding: 4rem;
}

.\!k-scroll-p-16 {
  scroll-padding: 4rem !important;
}

.k-scroll-p-17 {
  scroll-padding: 4.25rem;
}

.\!k-scroll-p-17 {
  scroll-padding: 4.25rem !important;
}

.k-scroll-p-18 {
  scroll-padding: 4.5rem;
}

.\!k-scroll-p-18 {
  scroll-padding: 4.5rem !important;
}

.k-scroll-p-19 {
  scroll-padding: 4.75rem;
}

.\!k-scroll-p-19 {
  scroll-padding: 4.75rem !important;
}

.k-scroll-p-20 {
  scroll-padding: 5rem;
}

.\!k-scroll-p-20 {
  scroll-padding: 5rem !important;
}

.k-scroll-p-21 {
  scroll-padding: 5.25rem;
}

.\!k-scroll-p-21 {
  scroll-padding: 5.25rem !important;
}

.k-scroll-p-22 {
  scroll-padding: 5.5rem;
}

.\!k-scroll-p-22 {
  scroll-padding: 5.5rem !important;
}

.k-scroll-p-23 {
  scroll-padding: 5.75rem;
}

.\!k-scroll-p-23 {
  scroll-padding: 5.75rem !important;
}

.k-scroll-p-24 {
  scroll-padding: 6rem;
}

.\!k-scroll-p-24 {
  scroll-padding: 6rem !important;
}

.k-scroll-p-25 {
  scroll-padding: 7rem;
}

.\!k-scroll-p-25 {
  scroll-padding: 7rem !important;
}

.k-scroll-p-26 {
  scroll-padding: 8rem;
}

.\!k-scroll-p-26 {
  scroll-padding: 8rem !important;
}

.k-scroll-p-27 {
  scroll-padding: 9rem;
}

.\!k-scroll-p-27 {
  scroll-padding: 9rem !important;
}

.k-scroll-p-28 {
  scroll-padding: 10rem;
}

.\!k-scroll-p-28 {
  scroll-padding: 10rem !important;
}

.k-scroll-p-29 {
  scroll-padding: 11rem;
}

.\!k-scroll-p-29 {
  scroll-padding: 11rem !important;
}

.k-scroll-p-30 {
  scroll-padding: 12rem;
}

.\!k-scroll-p-30 {
  scroll-padding: 12rem !important;
}

.k-scroll-pt-0 {
  scroll-padding-top: 0px;
}

.\!k-scroll-pt-0 {
  scroll-padding-top: 0px !important;
}

.k-scroll-pt-1px {
  scroll-padding-top: 1px;
}

.\!k-scroll-pt-1px {
  scroll-padding-top: 1px !important;
}

.k-scroll-pt-0\.5 {
  scroll-padding-top: 0.125rem;
}

.\!k-scroll-pt-0\.5 {
  scroll-padding-top: 0.125rem !important;
}

.k-scroll-pt-1 {
  scroll-padding-top: 0.25rem;
}

.\!k-scroll-pt-1 {
  scroll-padding-top: 0.25rem !important;
}

.k-scroll-pt-1\.5 {
  scroll-padding-top: 0.375rem;
}

.\!k-scroll-pt-1\.5 {
  scroll-padding-top: 0.375rem !important;
}

.k-scroll-pt-2 {
  scroll-padding-top: 0.5rem;
}

.\!k-scroll-pt-2 {
  scroll-padding-top: 0.5rem !important;
}

.k-scroll-pt-2\.5 {
  scroll-padding-top: 0.625rem;
}

.\!k-scroll-pt-2\.5 {
  scroll-padding-top: 0.625rem !important;
}

.k-scroll-pt-3 {
  scroll-padding-top: 0.75rem;
}

.\!k-scroll-pt-3 {
  scroll-padding-top: 0.75rem !important;
}

.k-scroll-pt-3\.5 {
  scroll-padding-top: 0.875rem;
}

.\!k-scroll-pt-3\.5 {
  scroll-padding-top: 0.875rem !important;
}

.k-scroll-pt-4 {
  scroll-padding-top: 1rem;
}

.\!k-scroll-pt-4 {
  scroll-padding-top: 1rem !important;
}

.k-scroll-pt-4\.5 {
  scroll-padding-top: 1.125rem;
}

.\!k-scroll-pt-4\.5 {
  scroll-padding-top: 1.125rem !important;
}

.k-scroll-pt-5 {
  scroll-padding-top: 1.25rem;
}

.\!k-scroll-pt-5 {
  scroll-padding-top: 1.25rem !important;
}

.k-scroll-pt-5\.5 {
  scroll-padding-top: 1.375rem;
}

.\!k-scroll-pt-5\.5 {
  scroll-padding-top: 1.375rem !important;
}

.k-scroll-pt-6 {
  scroll-padding-top: 1.5rem;
}

.\!k-scroll-pt-6 {
  scroll-padding-top: 1.5rem !important;
}

.k-scroll-pt-6\.5 {
  scroll-padding-top: 1.625rem;
}

.\!k-scroll-pt-6\.5 {
  scroll-padding-top: 1.625rem !important;
}

.k-scroll-pt-7 {
  scroll-padding-top: 1.75rem;
}

.\!k-scroll-pt-7 {
  scroll-padding-top: 1.75rem !important;
}

.k-scroll-pt-7\.5 {
  scroll-padding-top: 1.875rem;
}

.\!k-scroll-pt-7\.5 {
  scroll-padding-top: 1.875rem !important;
}

.k-scroll-pt-8 {
  scroll-padding-top: 2rem;
}

.\!k-scroll-pt-8 {
  scroll-padding-top: 2rem !important;
}

.k-scroll-pt-9 {
  scroll-padding-top: 2.25rem;
}

.\!k-scroll-pt-9 {
  scroll-padding-top: 2.25rem !important;
}

.k-scroll-pt-10 {
  scroll-padding-top: 2.5rem;
}

.\!k-scroll-pt-10 {
  scroll-padding-top: 2.5rem !important;
}

.k-scroll-pt-11 {
  scroll-padding-top: 2.75rem;
}

.\!k-scroll-pt-11 {
  scroll-padding-top: 2.75rem !important;
}

.k-scroll-pt-12 {
  scroll-padding-top: 3rem;
}

.\!k-scroll-pt-12 {
  scroll-padding-top: 3rem !important;
}

.k-scroll-pt-13 {
  scroll-padding-top: 3.25rem;
}

.\!k-scroll-pt-13 {
  scroll-padding-top: 3.25rem !important;
}

.k-scroll-pt-14 {
  scroll-padding-top: 3.5rem;
}

.\!k-scroll-pt-14 {
  scroll-padding-top: 3.5rem !important;
}

.k-scroll-pt-15 {
  scroll-padding-top: 3.75rem;
}

.\!k-scroll-pt-15 {
  scroll-padding-top: 3.75rem !important;
}

.k-scroll-pt-16 {
  scroll-padding-top: 4rem;
}

.\!k-scroll-pt-16 {
  scroll-padding-top: 4rem !important;
}

.k-scroll-pt-17 {
  scroll-padding-top: 4.25rem;
}

.\!k-scroll-pt-17 {
  scroll-padding-top: 4.25rem !important;
}

.k-scroll-pt-18 {
  scroll-padding-top: 4.5rem;
}

.\!k-scroll-pt-18 {
  scroll-padding-top: 4.5rem !important;
}

.k-scroll-pt-19 {
  scroll-padding-top: 4.75rem;
}

.\!k-scroll-pt-19 {
  scroll-padding-top: 4.75rem !important;
}

.k-scroll-pt-20 {
  scroll-padding-top: 5rem;
}

.\!k-scroll-pt-20 {
  scroll-padding-top: 5rem !important;
}

.k-scroll-pt-21 {
  scroll-padding-top: 5.25rem;
}

.\!k-scroll-pt-21 {
  scroll-padding-top: 5.25rem !important;
}

.k-scroll-pt-22 {
  scroll-padding-top: 5.5rem;
}

.\!k-scroll-pt-22 {
  scroll-padding-top: 5.5rem !important;
}

.k-scroll-pt-23 {
  scroll-padding-top: 5.75rem;
}

.\!k-scroll-pt-23 {
  scroll-padding-top: 5.75rem !important;
}

.k-scroll-pt-24 {
  scroll-padding-top: 6rem;
}

.\!k-scroll-pt-24 {
  scroll-padding-top: 6rem !important;
}

.k-scroll-pt-25 {
  scroll-padding-top: 7rem;
}

.\!k-scroll-pt-25 {
  scroll-padding-top: 7rem !important;
}

.k-scroll-pt-26 {
  scroll-padding-top: 8rem;
}

.\!k-scroll-pt-26 {
  scroll-padding-top: 8rem !important;
}

.k-scroll-pt-27 {
  scroll-padding-top: 9rem;
}

.\!k-scroll-pt-27 {
  scroll-padding-top: 9rem !important;
}

.k-scroll-pt-28 {
  scroll-padding-top: 10rem;
}

.\!k-scroll-pt-28 {
  scroll-padding-top: 10rem !important;
}

.k-scroll-pt-29 {
  scroll-padding-top: 11rem;
}

.\!k-scroll-pt-29 {
  scroll-padding-top: 11rem !important;
}

.k-scroll-pt-30 {
  scroll-padding-top: 12rem;
}

.\!k-scroll-pt-30 {
  scroll-padding-top: 12rem !important;
}

.k-scroll-pr-0 {
  scroll-padding-right: 0px;
}

.\!k-scroll-pr-0 {
  scroll-padding-right: 0px !important;
}

.k-scroll-pr-1px {
  scroll-padding-right: 1px;
}

.\!k-scroll-pr-1px {
  scroll-padding-right: 1px !important;
}

.k-scroll-pr-0\.5 {
  scroll-padding-right: 0.125rem;
}

.\!k-scroll-pr-0\.5 {
  scroll-padding-right: 0.125rem !important;
}

.k-scroll-pr-1 {
  scroll-padding-right: 0.25rem;
}

.\!k-scroll-pr-1 {
  scroll-padding-right: 0.25rem !important;
}

.k-scroll-pr-1\.5 {
  scroll-padding-right: 0.375rem;
}

.\!k-scroll-pr-1\.5 {
  scroll-padding-right: 0.375rem !important;
}

.k-scroll-pr-2 {
  scroll-padding-right: 0.5rem;
}

.\!k-scroll-pr-2 {
  scroll-padding-right: 0.5rem !important;
}

.k-scroll-pr-2\.5 {
  scroll-padding-right: 0.625rem;
}

.\!k-scroll-pr-2\.5 {
  scroll-padding-right: 0.625rem !important;
}

.k-scroll-pr-3 {
  scroll-padding-right: 0.75rem;
}

.\!k-scroll-pr-3 {
  scroll-padding-right: 0.75rem !important;
}

.k-scroll-pr-3\.5 {
  scroll-padding-right: 0.875rem;
}

.\!k-scroll-pr-3\.5 {
  scroll-padding-right: 0.875rem !important;
}

.k-scroll-pr-4 {
  scroll-padding-right: 1rem;
}

.\!k-scroll-pr-4 {
  scroll-padding-right: 1rem !important;
}

.k-scroll-pr-4\.5 {
  scroll-padding-right: 1.125rem;
}

.\!k-scroll-pr-4\.5 {
  scroll-padding-right: 1.125rem !important;
}

.k-scroll-pr-5 {
  scroll-padding-right: 1.25rem;
}

.\!k-scroll-pr-5 {
  scroll-padding-right: 1.25rem !important;
}

.k-scroll-pr-5\.5 {
  scroll-padding-right: 1.375rem;
}

.\!k-scroll-pr-5\.5 {
  scroll-padding-right: 1.375rem !important;
}

.k-scroll-pr-6 {
  scroll-padding-right: 1.5rem;
}

.\!k-scroll-pr-6 {
  scroll-padding-right: 1.5rem !important;
}

.k-scroll-pr-6\.5 {
  scroll-padding-right: 1.625rem;
}

.\!k-scroll-pr-6\.5 {
  scroll-padding-right: 1.625rem !important;
}

.k-scroll-pr-7 {
  scroll-padding-right: 1.75rem;
}

.\!k-scroll-pr-7 {
  scroll-padding-right: 1.75rem !important;
}

.k-scroll-pr-7\.5 {
  scroll-padding-right: 1.875rem;
}

.\!k-scroll-pr-7\.5 {
  scroll-padding-right: 1.875rem !important;
}

.k-scroll-pr-8 {
  scroll-padding-right: 2rem;
}

.\!k-scroll-pr-8 {
  scroll-padding-right: 2rem !important;
}

.k-scroll-pr-9 {
  scroll-padding-right: 2.25rem;
}

.\!k-scroll-pr-9 {
  scroll-padding-right: 2.25rem !important;
}

.k-scroll-pr-10 {
  scroll-padding-right: 2.5rem;
}

.\!k-scroll-pr-10 {
  scroll-padding-right: 2.5rem !important;
}

.k-scroll-pr-11 {
  scroll-padding-right: 2.75rem;
}

.\!k-scroll-pr-11 {
  scroll-padding-right: 2.75rem !important;
}

.k-scroll-pr-12 {
  scroll-padding-right: 3rem;
}

.\!k-scroll-pr-12 {
  scroll-padding-right: 3rem !important;
}

.k-scroll-pr-13 {
  scroll-padding-right: 3.25rem;
}

.\!k-scroll-pr-13 {
  scroll-padding-right: 3.25rem !important;
}

.k-scroll-pr-14 {
  scroll-padding-right: 3.5rem;
}

.\!k-scroll-pr-14 {
  scroll-padding-right: 3.5rem !important;
}

.k-scroll-pr-15 {
  scroll-padding-right: 3.75rem;
}

.\!k-scroll-pr-15 {
  scroll-padding-right: 3.75rem !important;
}

.k-scroll-pr-16 {
  scroll-padding-right: 4rem;
}

.\!k-scroll-pr-16 {
  scroll-padding-right: 4rem !important;
}

.k-scroll-pr-17 {
  scroll-padding-right: 4.25rem;
}

.\!k-scroll-pr-17 {
  scroll-padding-right: 4.25rem !important;
}

.k-scroll-pr-18 {
  scroll-padding-right: 4.5rem;
}

.\!k-scroll-pr-18 {
  scroll-padding-right: 4.5rem !important;
}

.k-scroll-pr-19 {
  scroll-padding-right: 4.75rem;
}

.\!k-scroll-pr-19 {
  scroll-padding-right: 4.75rem !important;
}

.k-scroll-pr-20 {
  scroll-padding-right: 5rem;
}

.\!k-scroll-pr-20 {
  scroll-padding-right: 5rem !important;
}

.k-scroll-pr-21 {
  scroll-padding-right: 5.25rem;
}

.\!k-scroll-pr-21 {
  scroll-padding-right: 5.25rem !important;
}

.k-scroll-pr-22 {
  scroll-padding-right: 5.5rem;
}

.\!k-scroll-pr-22 {
  scroll-padding-right: 5.5rem !important;
}

.k-scroll-pr-23 {
  scroll-padding-right: 5.75rem;
}

.\!k-scroll-pr-23 {
  scroll-padding-right: 5.75rem !important;
}

.k-scroll-pr-24 {
  scroll-padding-right: 6rem;
}

.\!k-scroll-pr-24 {
  scroll-padding-right: 6rem !important;
}

.k-scroll-pr-25 {
  scroll-padding-right: 7rem;
}

.\!k-scroll-pr-25 {
  scroll-padding-right: 7rem !important;
}

.k-scroll-pr-26 {
  scroll-padding-right: 8rem;
}

.\!k-scroll-pr-26 {
  scroll-padding-right: 8rem !important;
}

.k-scroll-pr-27 {
  scroll-padding-right: 9rem;
}

.\!k-scroll-pr-27 {
  scroll-padding-right: 9rem !important;
}

.k-scroll-pr-28 {
  scroll-padding-right: 10rem;
}

.\!k-scroll-pr-28 {
  scroll-padding-right: 10rem !important;
}

.k-scroll-pr-29 {
  scroll-padding-right: 11rem;
}

.\!k-scroll-pr-29 {
  scroll-padding-right: 11rem !important;
}

.k-scroll-pr-30 {
  scroll-padding-right: 12rem;
}

.\!k-scroll-pr-30 {
  scroll-padding-right: 12rem !important;
}

.k-scroll-pb-0 {
  scroll-padding-bottom: 0px;
}

.\!k-scroll-pb-0 {
  scroll-padding-bottom: 0px !important;
}

.k-scroll-pb-1px {
  scroll-padding-bottom: 1px;
}

.\!k-scroll-pb-1px {
  scroll-padding-bottom: 1px !important;
}

.k-scroll-pb-0\.5 {
  scroll-padding-bottom: 0.125rem;
}

.\!k-scroll-pb-0\.5 {
  scroll-padding-bottom: 0.125rem !important;
}

.k-scroll-pb-1 {
  scroll-padding-bottom: 0.25rem;
}

.\!k-scroll-pb-1 {
  scroll-padding-bottom: 0.25rem !important;
}

.k-scroll-pb-1\.5 {
  scroll-padding-bottom: 0.375rem;
}

.\!k-scroll-pb-1\.5 {
  scroll-padding-bottom: 0.375rem !important;
}

.k-scroll-pb-2 {
  scroll-padding-bottom: 0.5rem;
}

.\!k-scroll-pb-2 {
  scroll-padding-bottom: 0.5rem !important;
}

.k-scroll-pb-2\.5 {
  scroll-padding-bottom: 0.625rem;
}

.\!k-scroll-pb-2\.5 {
  scroll-padding-bottom: 0.625rem !important;
}

.k-scroll-pb-3 {
  scroll-padding-bottom: 0.75rem;
}

.\!k-scroll-pb-3 {
  scroll-padding-bottom: 0.75rem !important;
}

.k-scroll-pb-3\.5 {
  scroll-padding-bottom: 0.875rem;
}

.\!k-scroll-pb-3\.5 {
  scroll-padding-bottom: 0.875rem !important;
}

.k-scroll-pb-4 {
  scroll-padding-bottom: 1rem;
}

.\!k-scroll-pb-4 {
  scroll-padding-bottom: 1rem !important;
}

.k-scroll-pb-4\.5 {
  scroll-padding-bottom: 1.125rem;
}

.\!k-scroll-pb-4\.5 {
  scroll-padding-bottom: 1.125rem !important;
}

.k-scroll-pb-5 {
  scroll-padding-bottom: 1.25rem;
}

.\!k-scroll-pb-5 {
  scroll-padding-bottom: 1.25rem !important;
}

.k-scroll-pb-5\.5 {
  scroll-padding-bottom: 1.375rem;
}

.\!k-scroll-pb-5\.5 {
  scroll-padding-bottom: 1.375rem !important;
}

.k-scroll-pb-6 {
  scroll-padding-bottom: 1.5rem;
}

.\!k-scroll-pb-6 {
  scroll-padding-bottom: 1.5rem !important;
}

.k-scroll-pb-6\.5 {
  scroll-padding-bottom: 1.625rem;
}

.\!k-scroll-pb-6\.5 {
  scroll-padding-bottom: 1.625rem !important;
}

.k-scroll-pb-7 {
  scroll-padding-bottom: 1.75rem;
}

.\!k-scroll-pb-7 {
  scroll-padding-bottom: 1.75rem !important;
}

.k-scroll-pb-7\.5 {
  scroll-padding-bottom: 1.875rem;
}

.\!k-scroll-pb-7\.5 {
  scroll-padding-bottom: 1.875rem !important;
}

.k-scroll-pb-8 {
  scroll-padding-bottom: 2rem;
}

.\!k-scroll-pb-8 {
  scroll-padding-bottom: 2rem !important;
}

.k-scroll-pb-9 {
  scroll-padding-bottom: 2.25rem;
}

.\!k-scroll-pb-9 {
  scroll-padding-bottom: 2.25rem !important;
}

.k-scroll-pb-10 {
  scroll-padding-bottom: 2.5rem;
}

.\!k-scroll-pb-10 {
  scroll-padding-bottom: 2.5rem !important;
}

.k-scroll-pb-11 {
  scroll-padding-bottom: 2.75rem;
}

.\!k-scroll-pb-11 {
  scroll-padding-bottom: 2.75rem !important;
}

.k-scroll-pb-12 {
  scroll-padding-bottom: 3rem;
}

.\!k-scroll-pb-12 {
  scroll-padding-bottom: 3rem !important;
}

.k-scroll-pb-13 {
  scroll-padding-bottom: 3.25rem;
}

.\!k-scroll-pb-13 {
  scroll-padding-bottom: 3.25rem !important;
}

.k-scroll-pb-14 {
  scroll-padding-bottom: 3.5rem;
}

.\!k-scroll-pb-14 {
  scroll-padding-bottom: 3.5rem !important;
}

.k-scroll-pb-15 {
  scroll-padding-bottom: 3.75rem;
}

.\!k-scroll-pb-15 {
  scroll-padding-bottom: 3.75rem !important;
}

.k-scroll-pb-16 {
  scroll-padding-bottom: 4rem;
}

.\!k-scroll-pb-16 {
  scroll-padding-bottom: 4rem !important;
}

.k-scroll-pb-17 {
  scroll-padding-bottom: 4.25rem;
}

.\!k-scroll-pb-17 {
  scroll-padding-bottom: 4.25rem !important;
}

.k-scroll-pb-18 {
  scroll-padding-bottom: 4.5rem;
}

.\!k-scroll-pb-18 {
  scroll-padding-bottom: 4.5rem !important;
}

.k-scroll-pb-19 {
  scroll-padding-bottom: 4.75rem;
}

.\!k-scroll-pb-19 {
  scroll-padding-bottom: 4.75rem !important;
}

.k-scroll-pb-20 {
  scroll-padding-bottom: 5rem;
}

.\!k-scroll-pb-20 {
  scroll-padding-bottom: 5rem !important;
}

.k-scroll-pb-21 {
  scroll-padding-bottom: 5.25rem;
}

.\!k-scroll-pb-21 {
  scroll-padding-bottom: 5.25rem !important;
}

.k-scroll-pb-22 {
  scroll-padding-bottom: 5.5rem;
}

.\!k-scroll-pb-22 {
  scroll-padding-bottom: 5.5rem !important;
}

.k-scroll-pb-23 {
  scroll-padding-bottom: 5.75rem;
}

.\!k-scroll-pb-23 {
  scroll-padding-bottom: 5.75rem !important;
}

.k-scroll-pb-24 {
  scroll-padding-bottom: 6rem;
}

.\!k-scroll-pb-24 {
  scroll-padding-bottom: 6rem !important;
}

.k-scroll-pb-25 {
  scroll-padding-bottom: 7rem;
}

.\!k-scroll-pb-25 {
  scroll-padding-bottom: 7rem !important;
}

.k-scroll-pb-26 {
  scroll-padding-bottom: 8rem;
}

.\!k-scroll-pb-26 {
  scroll-padding-bottom: 8rem !important;
}

.k-scroll-pb-27 {
  scroll-padding-bottom: 9rem;
}

.\!k-scroll-pb-27 {
  scroll-padding-bottom: 9rem !important;
}

.k-scroll-pb-28 {
  scroll-padding-bottom: 10rem;
}

.\!k-scroll-pb-28 {
  scroll-padding-bottom: 10rem !important;
}

.k-scroll-pb-29 {
  scroll-padding-bottom: 11rem;
}

.\!k-scroll-pb-29 {
  scroll-padding-bottom: 11rem !important;
}

.k-scroll-pb-30 {
  scroll-padding-bottom: 12rem;
}

.\!k-scroll-pb-30 {
  scroll-padding-bottom: 12rem !important;
}

.k-scroll-pl-0 {
  scroll-padding-left: 0px;
}

.\!k-scroll-pl-0 {
  scroll-padding-left: 0px !important;
}

.k-scroll-pl-1px {
  scroll-padding-left: 1px;
}

.\!k-scroll-pl-1px {
  scroll-padding-left: 1px !important;
}

.k-scroll-pl-0\.5 {
  scroll-padding-left: 0.125rem;
}

.\!k-scroll-pl-0\.5 {
  scroll-padding-left: 0.125rem !important;
}

.k-scroll-pl-1 {
  scroll-padding-left: 0.25rem;
}

.\!k-scroll-pl-1 {
  scroll-padding-left: 0.25rem !important;
}

.k-scroll-pl-1\.5 {
  scroll-padding-left: 0.375rem;
}

.\!k-scroll-pl-1\.5 {
  scroll-padding-left: 0.375rem !important;
}

.k-scroll-pl-2 {
  scroll-padding-left: 0.5rem;
}

.\!k-scroll-pl-2 {
  scroll-padding-left: 0.5rem !important;
}

.k-scroll-pl-2\.5 {
  scroll-padding-left: 0.625rem;
}

.\!k-scroll-pl-2\.5 {
  scroll-padding-left: 0.625rem !important;
}

.k-scroll-pl-3 {
  scroll-padding-left: 0.75rem;
}

.\!k-scroll-pl-3 {
  scroll-padding-left: 0.75rem !important;
}

.k-scroll-pl-3\.5 {
  scroll-padding-left: 0.875rem;
}

.\!k-scroll-pl-3\.5 {
  scroll-padding-left: 0.875rem !important;
}

.k-scroll-pl-4 {
  scroll-padding-left: 1rem;
}

.\!k-scroll-pl-4 {
  scroll-padding-left: 1rem !important;
}

.k-scroll-pl-4\.5 {
  scroll-padding-left: 1.125rem;
}

.\!k-scroll-pl-4\.5 {
  scroll-padding-left: 1.125rem !important;
}

.k-scroll-pl-5 {
  scroll-padding-left: 1.25rem;
}

.\!k-scroll-pl-5 {
  scroll-padding-left: 1.25rem !important;
}

.k-scroll-pl-5\.5 {
  scroll-padding-left: 1.375rem;
}

.\!k-scroll-pl-5\.5 {
  scroll-padding-left: 1.375rem !important;
}

.k-scroll-pl-6 {
  scroll-padding-left: 1.5rem;
}

.\!k-scroll-pl-6 {
  scroll-padding-left: 1.5rem !important;
}

.k-scroll-pl-6\.5 {
  scroll-padding-left: 1.625rem;
}

.\!k-scroll-pl-6\.5 {
  scroll-padding-left: 1.625rem !important;
}

.k-scroll-pl-7 {
  scroll-padding-left: 1.75rem;
}

.\!k-scroll-pl-7 {
  scroll-padding-left: 1.75rem !important;
}

.k-scroll-pl-7\.5 {
  scroll-padding-left: 1.875rem;
}

.\!k-scroll-pl-7\.5 {
  scroll-padding-left: 1.875rem !important;
}

.k-scroll-pl-8 {
  scroll-padding-left: 2rem;
}

.\!k-scroll-pl-8 {
  scroll-padding-left: 2rem !important;
}

.k-scroll-pl-9 {
  scroll-padding-left: 2.25rem;
}

.\!k-scroll-pl-9 {
  scroll-padding-left: 2.25rem !important;
}

.k-scroll-pl-10 {
  scroll-padding-left: 2.5rem;
}

.\!k-scroll-pl-10 {
  scroll-padding-left: 2.5rem !important;
}

.k-scroll-pl-11 {
  scroll-padding-left: 2.75rem;
}

.\!k-scroll-pl-11 {
  scroll-padding-left: 2.75rem !important;
}

.k-scroll-pl-12 {
  scroll-padding-left: 3rem;
}

.\!k-scroll-pl-12 {
  scroll-padding-left: 3rem !important;
}

.k-scroll-pl-13 {
  scroll-padding-left: 3.25rem;
}

.\!k-scroll-pl-13 {
  scroll-padding-left: 3.25rem !important;
}

.k-scroll-pl-14 {
  scroll-padding-left: 3.5rem;
}

.\!k-scroll-pl-14 {
  scroll-padding-left: 3.5rem !important;
}

.k-scroll-pl-15 {
  scroll-padding-left: 3.75rem;
}

.\!k-scroll-pl-15 {
  scroll-padding-left: 3.75rem !important;
}

.k-scroll-pl-16 {
  scroll-padding-left: 4rem;
}

.\!k-scroll-pl-16 {
  scroll-padding-left: 4rem !important;
}

.k-scroll-pl-17 {
  scroll-padding-left: 4.25rem;
}

.\!k-scroll-pl-17 {
  scroll-padding-left: 4.25rem !important;
}

.k-scroll-pl-18 {
  scroll-padding-left: 4.5rem;
}

.\!k-scroll-pl-18 {
  scroll-padding-left: 4.5rem !important;
}

.k-scroll-pl-19 {
  scroll-padding-left: 4.75rem;
}

.\!k-scroll-pl-19 {
  scroll-padding-left: 4.75rem !important;
}

.k-scroll-pl-20 {
  scroll-padding-left: 5rem;
}

.\!k-scroll-pl-20 {
  scroll-padding-left: 5rem !important;
}

.k-scroll-pl-21 {
  scroll-padding-left: 5.25rem;
}

.\!k-scroll-pl-21 {
  scroll-padding-left: 5.25rem !important;
}

.k-scroll-pl-22 {
  scroll-padding-left: 5.5rem;
}

.\!k-scroll-pl-22 {
  scroll-padding-left: 5.5rem !important;
}

.k-scroll-pl-23 {
  scroll-padding-left: 5.75rem;
}

.\!k-scroll-pl-23 {
  scroll-padding-left: 5.75rem !important;
}

.k-scroll-pl-24 {
  scroll-padding-left: 6rem;
}

.\!k-scroll-pl-24 {
  scroll-padding-left: 6rem !important;
}

.k-scroll-pl-25 {
  scroll-padding-left: 7rem;
}

.\!k-scroll-pl-25 {
  scroll-padding-left: 7rem !important;
}

.k-scroll-pl-26 {
  scroll-padding-left: 8rem;
}

.\!k-scroll-pl-26 {
  scroll-padding-left: 8rem !important;
}

.k-scroll-pl-27 {
  scroll-padding-left: 9rem;
}

.\!k-scroll-pl-27 {
  scroll-padding-left: 9rem !important;
}

.k-scroll-pl-28 {
  scroll-padding-left: 10rem;
}

.\!k-scroll-pl-28 {
  scroll-padding-left: 10rem !important;
}

.k-scroll-pl-29 {
  scroll-padding-left: 11rem;
}

.\!k-scroll-pl-29 {
  scroll-padding-left: 11rem !important;
}

.k-scroll-pl-30 {
  scroll-padding-left: 12rem;
}

.\!k-scroll-pl-30 {
  scroll-padding-left: 12rem !important;
}

.k-scroll-px-0 {
  scroll-padding-inline: 0px;
}

.\!k-scroll-px-0 {
  scroll-padding-inline: 0px !important;
}

.k-scroll-px-1px {
  scroll-padding-inline: 1px;
}

.\!k-scroll-px-1px {
  scroll-padding-inline: 1px !important;
}

.k-scroll-px-0\.5 {
  scroll-padding-inline: 0.125rem;
}

.\!k-scroll-px-0\.5 {
  scroll-padding-inline: 0.125rem !important;
}

.k-scroll-px-1 {
  scroll-padding-inline: 0.25rem;
}

.\!k-scroll-px-1 {
  scroll-padding-inline: 0.25rem !important;
}

.k-scroll-px-1\.5 {
  scroll-padding-inline: 0.375rem;
}

.\!k-scroll-px-1\.5 {
  scroll-padding-inline: 0.375rem !important;
}

.k-scroll-px-2 {
  scroll-padding-inline: 0.5rem;
}

.\!k-scroll-px-2 {
  scroll-padding-inline: 0.5rem !important;
}

.k-scroll-px-2\.5 {
  scroll-padding-inline: 0.625rem;
}

.\!k-scroll-px-2\.5 {
  scroll-padding-inline: 0.625rem !important;
}

.k-scroll-px-3 {
  scroll-padding-inline: 0.75rem;
}

.\!k-scroll-px-3 {
  scroll-padding-inline: 0.75rem !important;
}

.k-scroll-px-3\.5 {
  scroll-padding-inline: 0.875rem;
}

.\!k-scroll-px-3\.5 {
  scroll-padding-inline: 0.875rem !important;
}

.k-scroll-px-4 {
  scroll-padding-inline: 1rem;
}

.\!k-scroll-px-4 {
  scroll-padding-inline: 1rem !important;
}

.k-scroll-px-4\.5 {
  scroll-padding-inline: 1.125rem;
}

.\!k-scroll-px-4\.5 {
  scroll-padding-inline: 1.125rem !important;
}

.k-scroll-px-5 {
  scroll-padding-inline: 1.25rem;
}

.\!k-scroll-px-5 {
  scroll-padding-inline: 1.25rem !important;
}

.k-scroll-px-5\.5 {
  scroll-padding-inline: 1.375rem;
}

.\!k-scroll-px-5\.5 {
  scroll-padding-inline: 1.375rem !important;
}

.k-scroll-px-6 {
  scroll-padding-inline: 1.5rem;
}

.\!k-scroll-px-6 {
  scroll-padding-inline: 1.5rem !important;
}

.k-scroll-px-6\.5 {
  scroll-padding-inline: 1.625rem;
}

.\!k-scroll-px-6\.5 {
  scroll-padding-inline: 1.625rem !important;
}

.k-scroll-px-7 {
  scroll-padding-inline: 1.75rem;
}

.\!k-scroll-px-7 {
  scroll-padding-inline: 1.75rem !important;
}

.k-scroll-px-7\.5 {
  scroll-padding-inline: 1.875rem;
}

.\!k-scroll-px-7\.5 {
  scroll-padding-inline: 1.875rem !important;
}

.k-scroll-px-8 {
  scroll-padding-inline: 2rem;
}

.\!k-scroll-px-8 {
  scroll-padding-inline: 2rem !important;
}

.k-scroll-px-9 {
  scroll-padding-inline: 2.25rem;
}

.\!k-scroll-px-9 {
  scroll-padding-inline: 2.25rem !important;
}

.k-scroll-px-10 {
  scroll-padding-inline: 2.5rem;
}

.\!k-scroll-px-10 {
  scroll-padding-inline: 2.5rem !important;
}

.k-scroll-px-11 {
  scroll-padding-inline: 2.75rem;
}

.\!k-scroll-px-11 {
  scroll-padding-inline: 2.75rem !important;
}

.k-scroll-px-12 {
  scroll-padding-inline: 3rem;
}

.\!k-scroll-px-12 {
  scroll-padding-inline: 3rem !important;
}

.k-scroll-px-13 {
  scroll-padding-inline: 3.25rem;
}

.\!k-scroll-px-13 {
  scroll-padding-inline: 3.25rem !important;
}

.k-scroll-px-14 {
  scroll-padding-inline: 3.5rem;
}

.\!k-scroll-px-14 {
  scroll-padding-inline: 3.5rem !important;
}

.k-scroll-px-15 {
  scroll-padding-inline: 3.75rem;
}

.\!k-scroll-px-15 {
  scroll-padding-inline: 3.75rem !important;
}

.k-scroll-px-16 {
  scroll-padding-inline: 4rem;
}

.\!k-scroll-px-16 {
  scroll-padding-inline: 4rem !important;
}

.k-scroll-px-17 {
  scroll-padding-inline: 4.25rem;
}

.\!k-scroll-px-17 {
  scroll-padding-inline: 4.25rem !important;
}

.k-scroll-px-18 {
  scroll-padding-inline: 4.5rem;
}

.\!k-scroll-px-18 {
  scroll-padding-inline: 4.5rem !important;
}

.k-scroll-px-19 {
  scroll-padding-inline: 4.75rem;
}

.\!k-scroll-px-19 {
  scroll-padding-inline: 4.75rem !important;
}

.k-scroll-px-20 {
  scroll-padding-inline: 5rem;
}

.\!k-scroll-px-20 {
  scroll-padding-inline: 5rem !important;
}

.k-scroll-px-21 {
  scroll-padding-inline: 5.25rem;
}

.\!k-scroll-px-21 {
  scroll-padding-inline: 5.25rem !important;
}

.k-scroll-px-22 {
  scroll-padding-inline: 5.5rem;
}

.\!k-scroll-px-22 {
  scroll-padding-inline: 5.5rem !important;
}

.k-scroll-px-23 {
  scroll-padding-inline: 5.75rem;
}

.\!k-scroll-px-23 {
  scroll-padding-inline: 5.75rem !important;
}

.k-scroll-px-24 {
  scroll-padding-inline: 6rem;
}

.\!k-scroll-px-24 {
  scroll-padding-inline: 6rem !important;
}

.k-scroll-px-25 {
  scroll-padding-inline: 7rem;
}

.\!k-scroll-px-25 {
  scroll-padding-inline: 7rem !important;
}

.k-scroll-px-26 {
  scroll-padding-inline: 8rem;
}

.\!k-scroll-px-26 {
  scroll-padding-inline: 8rem !important;
}

.k-scroll-px-27 {
  scroll-padding-inline: 9rem;
}

.\!k-scroll-px-27 {
  scroll-padding-inline: 9rem !important;
}

.k-scroll-px-28 {
  scroll-padding-inline: 10rem;
}

.\!k-scroll-px-28 {
  scroll-padding-inline: 10rem !important;
}

.k-scroll-px-29 {
  scroll-padding-inline: 11rem;
}

.\!k-scroll-px-29 {
  scroll-padding-inline: 11rem !important;
}

.k-scroll-px-30 {
  scroll-padding-inline: 12rem;
}

.\!k-scroll-px-30 {
  scroll-padding-inline: 12rem !important;
}

.k-scroll-py-0 {
  scroll-padding-block: 0px;
}

.\!k-scroll-py-0 {
  scroll-padding-block: 0px !important;
}

.k-scroll-py-1px {
  scroll-padding-block: 1px;
}

.\!k-scroll-py-1px {
  scroll-padding-block: 1px !important;
}

.k-scroll-py-0\.5 {
  scroll-padding-block: 0.125rem;
}

.\!k-scroll-py-0\.5 {
  scroll-padding-block: 0.125rem !important;
}

.k-scroll-py-1 {
  scroll-padding-block: 0.25rem;
}

.\!k-scroll-py-1 {
  scroll-padding-block: 0.25rem !important;
}

.k-scroll-py-1\.5 {
  scroll-padding-block: 0.375rem;
}

.\!k-scroll-py-1\.5 {
  scroll-padding-block: 0.375rem !important;
}

.k-scroll-py-2 {
  scroll-padding-block: 0.5rem;
}

.\!k-scroll-py-2 {
  scroll-padding-block: 0.5rem !important;
}

.k-scroll-py-2\.5 {
  scroll-padding-block: 0.625rem;
}

.\!k-scroll-py-2\.5 {
  scroll-padding-block: 0.625rem !important;
}

.k-scroll-py-3 {
  scroll-padding-block: 0.75rem;
}

.\!k-scroll-py-3 {
  scroll-padding-block: 0.75rem !important;
}

.k-scroll-py-3\.5 {
  scroll-padding-block: 0.875rem;
}

.\!k-scroll-py-3\.5 {
  scroll-padding-block: 0.875rem !important;
}

.k-scroll-py-4 {
  scroll-padding-block: 1rem;
}

.\!k-scroll-py-4 {
  scroll-padding-block: 1rem !important;
}

.k-scroll-py-4\.5 {
  scroll-padding-block: 1.125rem;
}

.\!k-scroll-py-4\.5 {
  scroll-padding-block: 1.125rem !important;
}

.k-scroll-py-5 {
  scroll-padding-block: 1.25rem;
}

.\!k-scroll-py-5 {
  scroll-padding-block: 1.25rem !important;
}

.k-scroll-py-5\.5 {
  scroll-padding-block: 1.375rem;
}

.\!k-scroll-py-5\.5 {
  scroll-padding-block: 1.375rem !important;
}

.k-scroll-py-6 {
  scroll-padding-block: 1.5rem;
}

.\!k-scroll-py-6 {
  scroll-padding-block: 1.5rem !important;
}

.k-scroll-py-6\.5 {
  scroll-padding-block: 1.625rem;
}

.\!k-scroll-py-6\.5 {
  scroll-padding-block: 1.625rem !important;
}

.k-scroll-py-7 {
  scroll-padding-block: 1.75rem;
}

.\!k-scroll-py-7 {
  scroll-padding-block: 1.75rem !important;
}

.k-scroll-py-7\.5 {
  scroll-padding-block: 1.875rem;
}

.\!k-scroll-py-7\.5 {
  scroll-padding-block: 1.875rem !important;
}

.k-scroll-py-8 {
  scroll-padding-block: 2rem;
}

.\!k-scroll-py-8 {
  scroll-padding-block: 2rem !important;
}

.k-scroll-py-9 {
  scroll-padding-block: 2.25rem;
}

.\!k-scroll-py-9 {
  scroll-padding-block: 2.25rem !important;
}

.k-scroll-py-10 {
  scroll-padding-block: 2.5rem;
}

.\!k-scroll-py-10 {
  scroll-padding-block: 2.5rem !important;
}

.k-scroll-py-11 {
  scroll-padding-block: 2.75rem;
}

.\!k-scroll-py-11 {
  scroll-padding-block: 2.75rem !important;
}

.k-scroll-py-12 {
  scroll-padding-block: 3rem;
}

.\!k-scroll-py-12 {
  scroll-padding-block: 3rem !important;
}

.k-scroll-py-13 {
  scroll-padding-block: 3.25rem;
}

.\!k-scroll-py-13 {
  scroll-padding-block: 3.25rem !important;
}

.k-scroll-py-14 {
  scroll-padding-block: 3.5rem;
}

.\!k-scroll-py-14 {
  scroll-padding-block: 3.5rem !important;
}

.k-scroll-py-15 {
  scroll-padding-block: 3.75rem;
}

.\!k-scroll-py-15 {
  scroll-padding-block: 3.75rem !important;
}

.k-scroll-py-16 {
  scroll-padding-block: 4rem;
}

.\!k-scroll-py-16 {
  scroll-padding-block: 4rem !important;
}

.k-scroll-py-17 {
  scroll-padding-block: 4.25rem;
}

.\!k-scroll-py-17 {
  scroll-padding-block: 4.25rem !important;
}

.k-scroll-py-18 {
  scroll-padding-block: 4.5rem;
}

.\!k-scroll-py-18 {
  scroll-padding-block: 4.5rem !important;
}

.k-scroll-py-19 {
  scroll-padding-block: 4.75rem;
}

.\!k-scroll-py-19 {
  scroll-padding-block: 4.75rem !important;
}

.k-scroll-py-20 {
  scroll-padding-block: 5rem;
}

.\!k-scroll-py-20 {
  scroll-padding-block: 5rem !important;
}

.k-scroll-py-21 {
  scroll-padding-block: 5.25rem;
}

.\!k-scroll-py-21 {
  scroll-padding-block: 5.25rem !important;
}

.k-scroll-py-22 {
  scroll-padding-block: 5.5rem;
}

.\!k-scroll-py-22 {
  scroll-padding-block: 5.5rem !important;
}

.k-scroll-py-23 {
  scroll-padding-block: 5.75rem;
}

.\!k-scroll-py-23 {
  scroll-padding-block: 5.75rem !important;
}

.k-scroll-py-24 {
  scroll-padding-block: 6rem;
}

.\!k-scroll-py-24 {
  scroll-padding-block: 6rem !important;
}

.k-scroll-py-25 {
  scroll-padding-block: 7rem;
}

.\!k-scroll-py-25 {
  scroll-padding-block: 7rem !important;
}

.k-scroll-py-26 {
  scroll-padding-block: 8rem;
}

.\!k-scroll-py-26 {
  scroll-padding-block: 8rem !important;
}

.k-scroll-py-27 {
  scroll-padding-block: 9rem;
}

.\!k-scroll-py-27 {
  scroll-padding-block: 9rem !important;
}

.k-scroll-py-28 {
  scroll-padding-block: 10rem;
}

.\!k-scroll-py-28 {
  scroll-padding-block: 10rem !important;
}

.k-scroll-py-29 {
  scroll-padding-block: 11rem;
}

.\!k-scroll-py-29 {
  scroll-padding-block: 11rem !important;
}

.k-scroll-py-30 {
  scroll-padding-block: 12rem;
}

.\!k-scroll-py-30 {
  scroll-padding-block: 12rem !important;
}

.k-touch-action-none {
  touch-action: none;
}

.\!k-touch-action-none {
  touch-action: none !important;
}

.k-touch-action-auto {
  touch-action: auto;
}

.\!k-touch-action-auto {
  touch-action: auto !important;
}

.k-touch-action-pan-x {
  touch-action: pan-x;
}

.\!k-touch-action-pan-x {
  touch-action: pan-x !important;
}

.k-touch-action-pan-left {
  touch-action: pan-left;
}

.\!k-touch-action-pan-left {
  touch-action: pan-left !important;
}

.k-touch-action-pan-right {
  touch-action: pan-right;
}

.\!k-touch-action-pan-right {
  touch-action: pan-right !important;
}

.k-touch-action-pan-y {
  touch-action: pan-y;
}

.\!k-touch-action-pan-y {
  touch-action: pan-y !important;
}

.k-touch-action-pan-up {
  touch-action: pan-up;
}

.\!k-touch-action-pan-up {
  touch-action: pan-up !important;
}

.k-touch-action-pan-down {
  touch-action: pan-down;
}

.\!k-touch-action-pan-down {
  touch-action: pan-down !important;
}

.k-touch-action-pinch-zoom {
  touch-action: pinch-zoom;
}

.\!k-touch-action-pinch-zoom {
  touch-action: pinch-zoom !important;
}

.k-touch-action-manipulation {
  touch-action: manipulation;
}

.\!k-touch-action-manipulation {
  touch-action: manipulation !important;
}

.k-user-select-none {
  user-select: none;
}

.\!k-user-select-none {
  user-select: none !important;
}

.k-user-select-auto {
  user-select: auto;
}

.\!k-user-select-auto {
  user-select: auto !important;
}

.k-user-select-text {
  user-select: text;
}

.\!k-user-select-text {
  user-select: text !important;
}

.k-user-select-all {
  user-select: all;
}

.\!k-user-select-all {
  user-select: all !important;
}

.k-user-select-contain {
  user-select: contain;
}

.\!k-user-select-contain {
  user-select: contain !important;
}

.k-will-change-auto {
  will-change: auto;
}

.\!k-will-change-auto {
  will-change: auto !important;
}

.k-will-change-scroll {
  will-change: scroll-position;
}

.\!k-will-change-scroll {
  will-change: scroll-position !important;
}

.k-will-change-contents {
  will-change: contents;
}

.\!k-will-change-contents {
  will-change: contents !important;
}

.k-will-change-transform {
  will-change: transform;
}

.\!k-will-change-transform {
  will-change: transform !important;
}

.k-fill-none {
  fill: none;
}

.\!k-fill-none {
  fill: none !important;
}

.k-fill-inherit {
  fill: inherit;
}

.\!k-fill-inherit {
  fill: inherit !important;
}

.k-fill-current {
  fill: currentColor;
}

.\!k-fill-current {
  fill: currentColor !important;
}

.k-fill-transparent {
  fill: transparent;
}

.\!k-fill-transparent {
  fill: transparent !important;
}

.k-fill-black {
  fill: black;
}

.\!k-fill-black {
  fill: black !important;
}

.k-fill-white {
  fill: white;
}

.\!k-fill-white {
  fill: white !important;
}

.k-stroke-none {
  stroke: none;
}

.\!k-stroke-none {
  stroke: none !important;
}

.k-stroke-inherit {
  stroke: inherit;
}

.\!k-stroke-inherit {
  stroke: inherit !important;
}

.k-stroke-current {
  stroke: currentColor;
}

.\!k-stroke-current {
  stroke: currentColor !important;
}

.k-stroke-transparent {
  stroke: transparent;
}

.\!k-stroke-transparent {
  stroke: transparent !important;
}

.k-stroke-black {
  stroke: black;
}

.\!k-stroke-black {
  stroke: black !important;
}

.k-stroke-white {
  stroke: white;
}

.\!k-stroke-white {
  stroke: white !important;
}

.k-elevation-1 {
  box-shadow: var(--kendo-elevation-1, 0px 1px 2px rgba(0, 0, 0, 0.038));
}

.\!k-elevation-1 {
  box-shadow: var(--kendo-elevation-1, 0px 1px 2px rgba(0, 0, 0, 0.038)) !important;
}

.k-elevation-2 {
  box-shadow: var(--kendo-elevation-2, 0px 2px 7px rgba(0, 0, 0, 0.075));
}

.\!k-elevation-2 {
  box-shadow: var(--kendo-elevation-2, 0px 2px 7px rgba(0, 0, 0, 0.075)) !important;
}

.k-elevation-3 {
  box-shadow: var(--kendo-elevation-3, 0px 4px 10px rgba(0, 0, 0, 0.1));
}

.\!k-elevation-3 {
  box-shadow: var(--kendo-elevation-3, 0px 4px 10px rgba(0, 0, 0, 0.1)) !important;
}

.k-elevation-4 {
  box-shadow: var(--kendo-elevation-4, 0px 6px 13px rgba(0, 0, 0, 0.125));
}

.\!k-elevation-4 {
  box-shadow: var(--kendo-elevation-4, 0px 6px 13px rgba(0, 0, 0, 0.125)) !important;
}

.k-elevation-5 {
  box-shadow: var(--kendo-elevation-5, 0px 8px 16px 0px rgba(0, 0, 0, 0.15));
}

.\!k-elevation-5 {
  box-shadow: var(--kendo-elevation-5, 0px 8px 16px 0px rgba(0, 0, 0, 0.15)) !important;
}

.k-elevation-6 {
  box-shadow: var(--kendo-elevation-6, 0px 11px 24px 0px rgba(0, 0, 0, 0.159));
}

.\!k-elevation-6 {
  box-shadow: var(--kendo-elevation-6, 0px 11px 24px 0px rgba(0, 0, 0, 0.159)) !important;
}

.k-elevation-7 {
  box-shadow: var(--kendo-elevation-7, 0px 14px 36px 0px rgba(0, 0, 0, 0.168));
}

.\!k-elevation-7 {
  box-shadow: var(--kendo-elevation-7, 0px 14px 36px 0px rgba(0, 0, 0, 0.168)) !important;
}

.k-elevation-8 {
  box-shadow: var(--kendo-elevation-8, 0px 16px 48px 0px rgba(0, 0, 0, 0.176));
}

.\!k-elevation-8 {
  box-shadow: var(--kendo-elevation-8, 0px 16px 48px 0px rgba(0, 0, 0, 0.176)) !important;
}

.k-elevation-9 {
  box-shadow: var(--kendo-elevation-9, 0px 18px 60px 0px rgba(0, 0, 0, 0.185));
}

.\!k-elevation-9 {
  box-shadow: var(--kendo-elevation-9, 0px 18px 60px 0px rgba(0, 0, 0, 0.185)) !important;
}

.k-svg-icon {
  width: 16px;
  height: 16px;
  outline: 0;
  line-height: 1;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
}
.k-svg-icon > svg {
  fill: currentColor;
  flex: 1 1 auto;
}

.k-svg-icon.k-icon-xs {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-svg-icon.k-icon-sm {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-svg-icon.k-icon-md {
  width: 16px;
  height: 16px;
}
.k-svg-icon.k-icon-lg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-svg-icon.k-icon-xl {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-svg-icon.k-icon-xxl {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-svg-icon.k-icon-xxxl {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-flip-h.k-svg-icon, .k-svg-icon.k-flip-x,
.k-flip-v.k-svg-icon,
.k-svg-icon.k-flip-y,
.k-flip-h.k-flip-v.k-svg-icon {
  transform: none;
}

.k-flip-h > svg, .k-flip-x > svg {
  transform: scaleX(-1);
}

.k-flip-v > svg, .k-flip-y > svg {
  transform: scaleY(-1);
}

.k-flip-h.k-flip-v > svg, .k-flip-v.k-flip-x > svg, .k-flip-h.k-flip-y > svg, .k-flip-x.k-flip-y > svg,
.k-flip-both > svg {
  transform: scale(-1, -1);
}

.k-rotate-0.k-svg-icon {
  transform: none;
}
.k-rotate-0 > svg {
  transform: rotate(0deg);
}

.k-rotate-45.k-svg-icon {
  transform: none;
}
.k-rotate-45 > svg {
  transform: rotate(45deg);
}

.k-rotate-90.k-svg-icon {
  transform: none;
}
.k-rotate-90 > svg {
  transform: rotate(90deg);
}

.k-rotate-135.k-svg-icon {
  transform: none;
}
.k-rotate-135 > svg {
  transform: rotate(135deg);
}

.k-rotate-180.k-svg-icon {
  transform: none;
}
.k-rotate-180 > svg {
  transform: rotate(180deg);
}

.k-rotate-225.k-svg-icon {
  transform: none;
}
.k-rotate-225 > svg {
  transform: rotate(225deg);
}

.k-rotate-270.k-svg-icon {
  transform: none;
}
.k-rotate-270 > svg {
  transform: rotate(270deg);
}

.k-rotate-315.k-svg-icon {
  transform: none;
}
.k-rotate-315 > svg {
  transform: rotate(315deg);
}

.k-icon-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: flex-start;
  vertical-align: middle;
  position: relative;
}
.k-icon-wrap::before {
  content: "​";
  width: 0;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-icon-wrapper-host {
  display: contents;
}

.k-icon-with-modifier {
  position: relative;
  margin: 0.25em;
}

.k-icon.k-icon-modifier {
  width: 1em;
  height: 1em;
  position: absolute;
  font-size: 0.5em;
  bottom: 0;
  right: 0;
  margin: 0 -0.5em -0.5em 0;
}

.k-i-none::before {
  content: "";
  display: none;
}

.k-icon-action {
  display: inline-flex;
  padding: var(--kendo-spacing-1, 0.25rem);
  line-height: 1;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.k-sprite {
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.k-image {
  display: inline-block;
}

.k-listgroup {
  border-radius: var(--kendo-spacing-1, 0.25rem);
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.k-listgroup > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.k-listgroup .k-listgroup-item + .k-listgroup-item {
  border-top-width: 1px;
}

.k-listgroup-flush {
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.k-listgroup-item {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  position: relative;
}
.k-listgroup-item > .k-link {
  margin-block: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  margin-inline: calc(var(--kendo-spacing-4, 1rem) * -1);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  color: inherit;
  text-decoration: none;
  outline: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  position: relative;
}
.k-listgroup-item > .k-link > .k-select {
  padding: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  transform: translateY(-50%);
}

.k-listgroup-item-segmented {
  align-items: stretch;
}
.k-listgroup-item-segmented > .k-link {
  margin-right: 0;
}
.k-listgroup-item-segmented > .k-select {
  margin: calc(var(--kendo-spacing-2, 0.5rem) * -1) calc(var(--kendo-spacing-4, 1rem) * -1) calc(var(--kendo-spacing-2, 0.5rem) * -1) 0;
  padding: var(--kendo-spacing-2, 0.5rem);
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: inherit;
  cursor: pointer;
}

.k-listgroup-form-row {
  margin-block: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  margin-inline: calc(var(--kendo-spacing-4, 1rem) * -1);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-label {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
  position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper select,
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=text],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=password],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=date],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=time],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=datetime-local],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=number],
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
  width: 100%;
  box-sizing: border-box;
  flex: 1 1 100%;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
  min-height: 4em;
  resize: vertical;
}

.k-listgroup-item.k-listgroup-form-row {
  margin: 0;
}

[dir=rtl] .k-listgroup-item > .k-link > .k-select {
  right: auto;
  left: 0;
}
[dir=rtl] .k-listgroup-item-segmented > .k-link {
  margin-right: calc(var(--kendo-spacing-4, 1rem) * -1);
  margin-left: 0;
}
[dir=rtl] .k-listgroup-item-segmented > .k-select {
  margin-right: 0;
  margin-left: calc(var(--kendo-spacing-4, 1rem) * -1);
  border-right-width: 1px;
  border-left-width: 0;
}

.k-noflexbox .k-listgroup,
.k-noflexbox .k-listgroup > ul .k-listgroup-item,
.k-noflexbox .k-listgroup-item > .k-link {
  display: block;
}

.k-listgroup {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-checkbox {
  margin: 0;
  padding: 0;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='var%28--kendo-color-on-primary, %23ffffff%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='var%28--kendo-color-on-primary, %23ffffff%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.k-checkbox:disabled,
.k-checkbox.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-checkbox-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: flex-start;
  vertical-align: middle;
  position: relative;
}
.k-checkbox-wrap::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-checkbox-label {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: var(--kendo-spacing-1, 0.25rem);
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.k-checkbox-label .k-ripple {
  visibility: hidden !important;
}

.k-checkbox + .k-label,
.k-checkbox-wrap + .k-label,
.k-checkbox + .k-checkbox-label,
.k-checkbox-wrap + .k-checkbox-label {
  display: inline;
  margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-checkbox-label:empty {
  display: none !important;
}

.k-checkbox-label.k-no-text {
  min-width: 1px;
}

.k-checkbox-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  list-style: none;
}

.k-checkbox-item,
.k-checkbox-list-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-0, 0px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-checkbox-item .k-checkbox-label,
.k-checkbox-list-item .k-checkbox-label {
  margin: 0;
}

.k-checkbox-list-horizontal,
.k-checkbox-list.k-list-horizontal {
  display: flex;
  flex-flow: row wrap;
  gap: var(--kendo-spacing-4, 1rem);
}

.k-ripple-container .k-checkbox::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  z-index: -1;
  transition: opacity 100ms linear, transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
}
.k-ripple-container .k-checkbox:focus,
.k-ripple-container .k-checkbox.k-focus {
  box-shadow: none !important;
}
.k-ripple-container .k-checkbox:disabled::after,
.k-ripple-container .k-checkbox.k-disabled::after {
  display: none;
}

.k-checkbox-sm {
  width: var(--kendo-spacing-3, 0.75rem);
  height: var(--kendo-spacing-3, 0.75rem);
}
.k-checkbox-sm::before {
  font-size: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-ripple-container .k-checkbox-sm::after {
  width: 300%;
  height: 300%;
}

.k-checkbox-md {
  width: var(--kendo-spacing-4, 1rem);
  height: var(--kendo-spacing-4, 1rem);
}
.k-checkbox-md::before {
  font-size: var(--kendo-spacing-3\.5, 0.875rem);
}

.k-ripple-container .k-checkbox-md::after {
  width: 300%;
  height: 300%;
}

.k-checkbox-lg {
  width: var(--kendo-spacing-5, 1.25rem);
  height: var(--kendo-spacing-5, 1.25rem);
}
.k-checkbox-lg::before {
  font-size: var(--kendo-spacing-4\.5, 1.125rem);
}

.k-ripple-container .k-checkbox-lg::after {
  width: 300%;
  height: 300%;
}

.k-checkbox.k-rounded-sm {
  border-radius: calc(var(--kendo-border-radius-sm, 2px) / 2);
}

.k-checkbox.k-rounded-md {
  border-radius: var(--kendo-border-radius-sm, 2px);
}

.k-checkbox.k-rounded-lg {
  border-radius: var(--kendo-border-radius-md, 3px);
}

.k-checkbox {
  border-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2));
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-checkbox:focus,
.k-checkbox.k-focus {
  border-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  border-color: var(--kendo-color-primary, #0d6efd);
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}

.k-checkbox.k-invalid {
  border-color: var(--kendo-color-error, #dc3545);
}

.k-checkbox.k-invalid + .k-checkbox-label {
  color: var(--kendo-color-error, #dc3545);
}

.k-checkbox-wrap .k-ripple-blob {
  color: var(--kendo-color-primary, #0d6efd);
  opacity: 0.25;
}

.k-ripple-container .k-checkbox::after {
  background: var(--kendo-color-primary, #0d6efd);
  opacity: 0.25;
}

.k-radio {
  border-radius: 50%;
  margin: 0;
  padding: 0;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}

.k-radio:checked,
.k-radio.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='var%28--kendo-color-on-primary, %23ffffff%29'/%3e%3c/svg%3e");
}

.k-radio:disabled,
.k-radio.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-radio-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: start;
  vertical-align: middle;
  position: relative;
}
.k-radio-wrap::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-radio-label {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: var(--kendo-spacing-1, 0.25rem);
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.k-radio-label .k-ripple {
  visibility: hidden !important;
}

.k-radio + .k-label,
.k-radio-wrap + .k-label,
.k-radio + .k-radio-label,
.k-radio-wrap + .k-radio-label {
  display: inline;
  margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-radio-label:empty {
  display: none !important;
}

.k-radio-label.k-no-text {
  min-width: 1px;
}

.k-radio-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  list-style: none;
}

.k-radio-item,
.k-radio-list-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-0, 0px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-radio-item .k-radio-label,
.k-radio-list-item .k-radio-label {
  margin: 0;
}

.k-radio-list-horizontal,
.k-radio-list.k-list-horizontal {
  display: flex;
  flex-flow: row wrap;
  gap: var(--kendo-spacing-4, 1rem);
}

.k-ripple-container .k-radio::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  z-index: -1;
  transition: opacity 100ms linear, transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
}
.k-ripple-container .k-radio:focus,
.k-ripple-container .k-radio.k-focus {
  box-shadow: none !important;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
  display: none;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
  display: none;
}

.k-radio-sm {
  width: var(--kendo-spacing-3, 0.75rem);
  height: var(--kendo-spacing-3, 0.75rem);
}
.k-radio-sm::before {
  font-size: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-ripple-container .k-radio-sm::after {
  width: 300%;
  height: 300%;
}

.k-radio-md {
  width: var(--kendo-spacing-4, 1rem);
  height: var(--kendo-spacing-4, 1rem);
}
.k-radio-md::before {
  font-size: var(--kendo-spacing-3\.5, 0.875rem);
}

.k-ripple-container .k-radio-md::after {
  width: 300%;
  height: 300%;
}

.k-radio-lg {
  width: var(--kendo-spacing-5, 1.25rem);
  height: var(--kendo-spacing-5, 1.25rem);
}
.k-radio-lg::before {
  font-size: var(--kendo-spacing-4\.5, 1.125rem);
}

.k-ripple-container .k-radio-lg::after {
  width: 300%;
  height: 300%;
}

.k-radio {
  border-color: var(--kendo-color-border-alt, rgba(33, 37, 41, 0.2));
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-radio:focus,
.k-radio.k-focus {
  border-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}

.k-radio.k-invalid,
.k-radio.ng-invalid.ng-touched,
.k-radio.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}

.k-radio.k-invalid + .k-radio-label,
.k-radio.ng-invalid.ng-touched + .k-radio-label,
.k-radio.ng-invalid.ng-dirty + .k-radio-label {
  color: var(--kendo-color-error, #dc3545);
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
  border-color: var(--kendo-color-primary, #0d6efd);
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}

.k-ripple-container .k-radio::after {
  background: var(--kendo-color-primary, #0d6efd);
  opacity: 0.25;
}

.k-switch {
  box-sizing: border-box;
  outline: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-switch *,
.k-switch *::before,
.k-switch *::after {
  box-sizing: border-box;
}
.k-switch [type=checkbox] {
  display: none;
}
.k-switch[aria-readonly=true] {
  pointer-events: none;
}

.k-switch.k-readonly {
  pointer-events: none;
}

.k-switch-track {
  border-width: 1px;
  border-style: solid;
  outline: 0;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  position: relative;
  transition: background-color 200ms ease-in-out;
}

.k-switch-thumb-wrap {
  width: 0;
  height: 0;
  overflow: visible;
  position: absolute;
  transition: left 200ms ease-in-out;
  top: 50%;
}

.k-switch-thumb {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
}

.k-switch-label-on,
.k-switch-label-off {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
}

.k-switch-sm {
  width: 48px;
  height: 26px;
}
.k-switch-sm .k-switch-track {
  width: 48px;
  height: 26px;
}
.k-switch-sm .k-switch-thumb {
  width: 20px;
  height: 20px;
}
.k-switch-sm.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 10px - 3px);
}
.k-switch-sm.k-switch-off .k-switch-thumb-wrap {
  left: calc(10px + 3px);
}

.k-switch-sm[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-sm .k-switch-label-on,
.k-rtl .k-switch-sm .k-switch-label-on {
  left: auto;
}
.k-switch-sm[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-sm .k-switch-label-off,
.k-rtl .k-switch-sm .k-switch-label-off {
  right: auto;
}
.k-switch-sm[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-sm.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-on .k-switch-thumb-wrap {
  left: calc(10px + 3px);
}
.k-switch-sm[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-sm.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 10px - 3px);
}

.k-switch-md {
  width: 56px;
  height: 30px;
}
.k-switch-md .k-switch-track {
  width: 56px;
  height: 30px;
}
.k-switch-md .k-switch-thumb {
  width: 24px;
  height: 24px;
}
.k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 12px - 3px);
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(12px + 3px);
}

.k-switch-md[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-md .k-switch-label-on,
.k-rtl .k-switch-md .k-switch-label-on {
  left: auto;
}
.k-switch-md[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-md .k-switch-label-off,
.k-rtl .k-switch-md .k-switch-label-off {
  right: auto;
}
.k-switch-md[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-md.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(12px + 3px);
}
.k-switch-md[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-md.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 12px - 3px);
}

.k-switch-lg {
  width: 64px;
  height: 34px;
}
.k-switch-lg .k-switch-track {
  width: 64px;
  height: 34px;
}
.k-switch-lg .k-switch-thumb {
  width: 28px;
  height: 28px;
}
.k-switch-lg.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 14px - 3px);
}
.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(14px + 3px);
}

.k-switch-lg[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-lg .k-switch-label-on,
.k-rtl .k-switch-lg .k-switch-label-on {
  left: auto;
}
.k-switch-lg[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-lg .k-switch-label-off,
.k-rtl .k-switch-lg .k-switch-label-off {
  right: auto;
}
.k-switch-lg[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-lg.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-on .k-switch-thumb-wrap {
  left: calc(14px + 3px);
}
.k-switch-lg[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-lg.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 14px - 3px);
}

.k-switch[dir=rtl] .k-switch-thumb,
[dir=rtl] .k-switch .k-switch-thumb,
.k-switch.k-rtl .k-switch-thumb,
.k-rtl .k-switch .k-switch-thumb {
  transform: translate(50%, -50%);
}

.k-switch.k-disabled {
  opacity: 1;
  filter: none;
}

.k-switch-off .k-switch-track {
  border-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-switch-off .k-switch-thumb {
  background-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
}
.k-switch-off:focus .k-switch-track, .k-switch-off.k-focus .k-switch-track {
  border-color: var(--kendo-color-primary-emphasis, rgba(13, 110, 253, 0.4));
  outline: 0.25rem solid color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}
.k-switch-off:disabled .k-switch-track, .k-switch-off.k-disabled .k-switch-track {
  background-color: var(--kendo-color-base-subtle, #e9ecef);
}
.k-switch-off .k-switch-label-on {
  color: transparent;
}

.k-switch-on .k-switch-track {
  border-color: var(--kendo-color-primary, #0d6efd);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-switch-on .k-switch-thumb {
  background-color: var(--kendo-color-on-primary, #ffffff);
}
.k-switch-on:focus .k-switch-track, .k-switch-on.k-focus .k-switch-track {
  outline: 0.25rem solid color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}
.k-switch-on:disabled .k-switch-track, .k-switch-on.k-disabled .k-switch-track {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 50%, transparent);
}
.k-switch-on .k-switch-label-off {
  color: transparent;
}

.k-badge {
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  box-sizing: border-box;
  color: inherit;
  background-color: transparent;
  font: inherit;
  text-align: center;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-badge *,
.k-badge *::before,
.k-badge *::after {
  box-sizing: border-box;
}

.k-badge > * {
  color: inherit;
}

a.k-badge,
.k-badge > a {
  text-decoration: none;
}

a.k-badge:hover,
.k-badge > a:hover {
  text-decoration: underline;
}

.k-badge-icon {
  max-width: 1em;
  max-height: 1em;
  font-size: inherit;
}

.k-badge-icon.k-svg-icon svg {
  fill: currentColor;
}

.k-badge-sm {
  padding-block: 0.1875rem;
  padding-inline: 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  min-width: calc(1em + 0.1875rem * 2 + 1px * 2);
}
.k-badge-sm:empty {
  padding: 0.1875rem;
  min-width: auto;
}

.k-badge-md {
  padding-block: 0.375rem;
  padding-inline: 1rem;
  font-size: 0.875rem;
  line-height: 1;
  min-width: calc(1em + 0.375rem * 2 + 1px * 2);
}
.k-badge-md:empty {
  padding: 0.375rem;
  min-width: auto;
}

.k-badge-lg {
  padding-block: 0.5625rem;
  padding-inline: 1.5rem;
  font-size: 0.875rem;
  line-height: 1;
  min-width: calc(1em + 0.5625rem * 2 + 1px * 2);
}
.k-badge-lg:empty {
  padding: 0.5625rem;
  min-width: auto;
}

kendo-badge-container {
  display: inline-block;
}

.k-badge-container {
  position: relative;
  overflow: visible;
}

.k-badge-inside {
  position: absolute;
  z-index: 9999;
}
.k-badge-edge {
  position: absolute;
  z-index: 9999;
}
.k-badge-edge.k-top-start {
  transform: translate(-50%, -50%);
}
.k-badge-edge.k-top-end {
  transform: translate(50%, -50%);
}
.k-badge-edge.k-bottom-start {
  transform: translate(-50%, 50%);
}
.k-badge-edge.k-bottom-end {
  transform: translate(50%, 50%);
}

.k-rtl .k-badge-edge.k-top-start,
[dir=rtl].k-badge-edge.k-top-start,
[dir=rtl] .k-badge-edge.k-top-start {
  transform: translate(50%, -50%);
}
.k-rtl .k-badge-edge.k-top-end,
[dir=rtl].k-badge-edge.k-top-end,
[dir=rtl] .k-badge-edge.k-top-end {
  transform: translate(-50%, -50%);
}
.k-rtl .k-badge-edge.k-bottom-start,
[dir=rtl].k-badge-edge.k-bottom-start,
[dir=rtl] .k-badge-edge.k-bottom-start {
  transform: translate(50%, 50%);
}
.k-rtl .k-badge-edge.k-bottom-end,
[dir=rtl].k-badge-edge.k-bottom-end,
[dir=rtl] .k-badge-edge.k-bottom-end {
  transform: translate(-50%, 50%);
}

.k-badge-outside {
  position: absolute;
  z-index: 9999;
}
.k-badge-outside.k-top-start {
  transform: translate(-100%, -100%);
}
.k-badge-outside.k-top-end {
  transform: translate(100%, -100%);
}
.k-badge-outside.k-bottom-start {
  transform: translate(-100%, 100%);
}
.k-badge-outside.k-bottom-end {
  transform: translate(100%, 100%);
}

.k-rtl .k-badge-outside.k-top-start,
[dir=rtl].k-badge-outside.k-top-start,
[dir=rtl] .k-badge-outside.k-top-start {
  transform: translate(100%, -100%);
}
.k-rtl .k-badge-outside.k-top-end,
[dir=rtl].k-badge-outside.k-top-end,
[dir=rtl] .k-badge-outside.k-top-end {
  transform: translate(-100%, -100%);
}
.k-rtl .k-badge-outside.k-bottom-start,
[dir=rtl].k-badge-outside.k-bottom-start,
[dir=rtl] .k-badge-outside.k-bottom-start {
  transform: translate(100%, 100%);
}
.k-rtl .k-badge-outside.k-bottom-end,
[dir=rtl].k-badge-outside.k-bottom-end,
[dir=rtl] .k-badge-outside.k-bottom-end {
  transform: translate(-100%, 100%);
}

.k-badge-border-cutout {
  box-shadow: 0 0 0 2px var(--kendo-color-surface-alt, #ffffff);
}

.k-badge-solid-primary {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-badge-solid-secondary {
  border-color: var(--kendo-color-secondary, #6c757d);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #6c757d);
}

.k-badge-solid-tertiary {
  border-color: var(--kendo-color-tertiary, #6f42c1);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #6f42c1);
}

.k-badge-solid-info {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info, #0dcaf0);
}

.k-badge-solid-success {
  border-color: var(--kendo-color-success, #198754);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #198754);
}

.k-badge-solid-warning {
  border-color: var(--kendo-color-warning, #ffc107);
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}

.k-badge-solid-error {
  border-color: var(--kendo-color-error, #dc3545);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #dc3545);
}

.k-badge-solid-dark {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}

.k-badge-solid-light {
  border-color: var(--kendo-color-light, #f8f9fa);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}

.k-badge-solid-inverse {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}

.k-badge-outline {
  border-color: currentColor;
  background-color: transparent;
}

.k-badge-outline-primary {
  color: var(--kendo-color-primary, #0d6efd);
}

.k-badge-outline-secondary {
  color: var(--kendo-color-secondary, #6c757d);
}

.k-badge-outline-tertiary {
  color: var(--kendo-color-tertiary, #6f42c1);
}

.k-badge-outline-info {
  color: var(--kendo-color-info, #0dcaf0);
}

.k-badge-outline-success {
  color: var(--kendo-color-success, #198754);
}

.k-badge-outline-warning {
  color: var(--kendo-color-warning, #ffc107);
}

.k-badge-outline-error {
  color: var(--kendo-color-error, #dc3545);
}

.k-badge-outline-dark {
  color: var(--kendo-color-dark, #212529);
}

.k-badge-outline-light {
  color: var(--kendo-color-light, #f8f9fa);
}

.k-badge-outline-inverse {
  color: var(--kendo-color-dark, #212529);
}

.k-button {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-radius: 2px;
  color: inherit;
  background: none;
  font-family: var(--kendo-font-family, inherit);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  transition: none;
}
.k-button *,
.k-button *::before,
.k-button *::after {
  box-sizing: border-box;
}
.k-button:disabled, .k-button.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
.k-button::-moz-focus-inner {
  padding: 0;
  border: 0;
  outline: 0;
}
.k-button:hover, .k-button:focus {
  text-decoration: none;
  outline: 0;
}

.k-icon-button {
  gap: 0;
}
.k-icon-button .k-icon {
  display: inline-flex;
}

.k-button-icon {
  color: inherit;
  align-self: center;
  position: relative;
}

.k-button-sm {
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: 1.4285714286;
}

.k-button-sm.k-icon-button {
  padding: 0.25rem;
}
.k-button-sm.k-icon-button .k-button-icon {
  min-width: calc(var(--kendo-font-size-sm, inherit) * 1.4285714286);
  min-height: calc(var(--kendo-font-size-sm, inherit) * 1.4285714286);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-sm.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-sm.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-md {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-button-md.k-icon-button {
  padding: 0.375rem;
}
.k-button-md.k-icon-button .k-button-icon {
  min-width: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  min-height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-lg {
  padding-block: 0.625rem;
  padding-inline: 1rem;
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-button-lg.k-icon-button {
  padding: 0.625rem;
}
.k-button-lg.k-icon-button .k-button-icon {
  min-width: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  min-height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-lg.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-lg.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-square {
  aspect-ratio: 1;
}

.k-button-group {
  margin: 0;
  padding: 0;
  border-width: 0;
  box-sizing: border-box;
  list-style: none;
  outline: 0;
  display: inline-flex;
  flex-flow: row nowrap;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-button-group > .k-button:hover,
.k-button-group > .k-button.k-hover,
.k-button-group > .k-button:focus,
.k-button-group > .k-button.k-focus,
.k-button-group > .k-button:active,
.k-button-group > .k-button.k-active,
.k-button-group > .k-button.k-selected {
  z-index: 2;
}
.k-button-group .k-button:not(:first-child):not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.k-button-group > .k-button:first-child:not(:only-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
.k-button-group > .k-button:last-child:not(:only-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.k-button-group:disabled, .k-button-group[disabled], .k-button-group.k-disabled {
  opacity: 1;
  filter: none;
}

.k-button-group-stretched {
  width: 100%;
}
.k-button-group-stretched > * {
  flex: 1 0 0%;
  overflow: hidden;
}

.k-button-flat {
  border-color: transparent !important;
  color: inherit;
  background: none !important;
  box-shadow: none !important;
}
.k-button-flat::before {
  display: block !important;
}
.k-button-flat::after {
  box-shadow: inset 0 0 0 2px currentColor;
  display: block !important;
}
.k-button-flat:focus::after, .k-button-flat.k-focus::after {
  opacity: 0.12;
}

.k-button-outline {
  border-color: currentColor;
  color: inherit;
  background: none;
}

.k-button-link {
  border-color: transparent;
  color: inherit;
  text-decoration: none;
  background: none;
}
.k-button-link:hover, .k-button-link.k-hover {
  text-decoration: underline;
}

.k-button-clear {
  border-color: transparent !important;
  color: inherit;
  background: none !important;
  box-shadow: none !important;
}

.k-button-overlay, .k-button::before {
  border-radius: inherit;
  content: "";
  background: currentColor;
  opacity: 0;
  display: none;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  transition: opacity 0.2s ease-in-out;
}

.k-button:hover::before, .k-button.k-hover::before {
  opacity: 0.08;
}
.k-button.k-no-focus:not(:hover)::before, .k-button.k-no-focus:not(.k-hover)::before {
  opacity: 0;
}
.k-button:active::before, .k-button.k-active::before {
  opacity: 0.16;
}
.k-button.k-selected::before {
  opacity: 0.2;
}

.k-button::after {
  border-radius: inherit;
  content: "";
  opacity: 0;
  display: none;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  transition: opacity 0.2s ease-in-out;
}

.k-button-solid-base {
  border-color: #eeeeee;
  color: #1a1a1a;
  background-color: #eeeeee;
}
.k-button-solid-base:hover, .k-button-solid-base.k-hover {
  border-color: #c2c2c2;
  background-color: #e2e2e2;
}
.k-button-solid-base:focus, .k-button-solid-base.k-focus {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-button-solid-base:active, .k-button-solid-base.k-active {
  border-color: var(--kendo-color-base-active, #bdc4cb);
  background-color: #c2c2c2;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-button-solid-base.k-selected {
  border-color: var(--kendo-color-primary-active, #0a58ca);
  color: #ffffff;
  background-color: #006d89;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-button-solid-primary {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-button-solid-primary:hover, .k-button-solid-primary.k-hover {
  border-color: var(--kendo-color-primary-hover, #0c64e4);
  background-color: var(--kendo-color-primary-hover, #0c64e4);
}
.k-button-solid-primary:focus, .k-button-solid-primary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 50%, transparent);
}
.k-button-solid-primary:active, .k-button-solid-primary.k-active {
  border-color: var(--kendo-color-primary-active, #0a58ca);
  background-color: var(--kendo-color-primary-active, #0a58ca);
}
.k-button-solid-primary.k-selected {
  border-color: var(--kendo-color-primary-active, #0a58ca);
  background-color: var(--kendo-color-primary-active, #0a58ca);
}
.k-button-solid-secondary {
  border-color: var(--kendo-color-secondary, #6c757d);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #6c757d);
}
.k-button-solid-secondary:hover, .k-button-solid-secondary.k-hover {
  border-color: var(--kendo-color-secondary-hover, #596169);
  background-color: var(--kendo-color-secondary-hover, #596169);
}
.k-button-solid-secondary:focus, .k-button-solid-secondary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-secondary, #6c757d) 50%, transparent);
}
.k-button-solid-secondary:active, .k-button-solid-secondary.k-active {
  border-color: var(--kendo-color-secondary-active, #495057);
  background-color: var(--kendo-color-secondary-active, #495057);
}
.k-button-solid-secondary.k-selected {
  border-color: var(--kendo-color-secondary-active, #495057);
  background-color: var(--kendo-color-secondary-active, #495057);
}
.k-button-solid-tertiary {
  border-color: var(--kendo-color-tertiary, #6f42c1);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #6f42c1);
}
.k-button-solid-tertiary:hover, .k-button-solid-tertiary.k-hover {
  border-color: var(--kendo-color-tertiary-hover, #59359a);
  background-color: var(--kendo-color-tertiary-hover, #59359a);
}
.k-button-solid-tertiary:focus, .k-button-solid-tertiary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-tertiary, #6f42c1) 50%, transparent);
}
.k-button-solid-tertiary:active, .k-button-solid-tertiary.k-active {
  border-color: var(--kendo-color-tertiary-active, #4e2f89);
  background-color: var(--kendo-color-tertiary-active, #4e2f89);
}
.k-button-solid-tertiary.k-selected {
  border-color: var(--kendo-color-tertiary-active, #4e2f89);
  background-color: var(--kendo-color-tertiary-active, #4e2f89);
}
.k-button-solid-info {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info, #0dcaf0);
}
.k-button-solid-info:hover, .k-button-solid-info.k-hover {
  border-color: var(--kendo-color-info-hover, #3dd5f3);
  background-color: var(--kendo-color-info-hover, #3dd5f3);
}
.k-button-solid-info:focus, .k-button-solid-info.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-info, #0dcaf0) 50%, transparent);
}
.k-button-solid-info:active, .k-button-solid-info.k-active {
  border-color: var(--kendo-color-info-active, #6edff6);
  background-color: var(--kendo-color-info-active, #6edff6);
}
.k-button-solid-info.k-selected {
  border-color: var(--kendo-color-info-active, #6edff6);
  background-color: var(--kendo-color-info-active, #6edff6);
}
.k-button-solid-success {
  border-color: var(--kendo-color-success, #198754);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #198754);
}
.k-button-solid-success:hover, .k-button-solid-success.k-hover {
  border-color: var(--kendo-color-success-hover, #146C43);
  background-color: var(--kendo-color-success-hover, #146C43);
}
.k-button-solid-success:focus, .k-button-solid-success.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-success, #198754) 50%, transparent);
}
.k-button-solid-success:active, .k-button-solid-success.k-active {
  border-color: var(--kendo-color-success-active, #0F5132);
  background-color: var(--kendo-color-success-active, #0F5132);
}
.k-button-solid-success.k-selected {
  border-color: var(--kendo-color-success-active, #0F5132);
  background-color: var(--kendo-color-success-active, #0F5132);
}
.k-button-solid-warning {
  border-color: var(--kendo-color-warning, #ffc107);
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}
.k-button-solid-warning:hover, .k-button-solid-warning.k-hover {
  border-color: var(--kendo-color-warning-hover, #ffcd39);
  background-color: var(--kendo-color-warning-hover, #ffcd39);
}
.k-button-solid-warning:focus, .k-button-solid-warning.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-warning, #ffc107) 50%, transparent);
}
.k-button-solid-warning:active, .k-button-solid-warning.k-active {
  border-color: var(--kendo-color-warning-active, #ffda6a);
  background-color: var(--kendo-color-warning-active, #ffda6a);
}
.k-button-solid-warning.k-selected {
  border-color: var(--kendo-color-warning-active, #ffda6a);
  background-color: var(--kendo-color-warning-active, #ffda6a);
}
.k-button-solid-error {
  border-color: var(--kendo-color-error, #dc3545);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #dc3545);
}
.k-button-solid-error:hover, .k-button-solid-error.k-hover {
  border-color: var(--kendo-color-error-hover, #b02a37);
  background-color: var(--kendo-color-error-hover, #b02a37);
}
.k-button-solid-error:focus, .k-button-solid-error.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 50%, transparent);
}
.k-button-solid-error:active, .k-button-solid-error.k-active {
  border-color: var(--kendo-color-error-active, #9a2530);
  background-color: var(--kendo-color-error-active, #9a2530);
}
.k-button-solid-error.k-selected {
  border-color: var(--kendo-color-error-active, #9a2530);
  background-color: var(--kendo-color-error-active, #9a2530);
}
.k-button-solid-dark {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-button-solid-dark:hover, .k-button-solid-dark.k-hover {
  border-color: var(--kendo-color-dark-hover, #343a40);
  background-color: var(--kendo-color-dark-hover, #343a40);
}
.k-button-solid-dark:focus, .k-button-solid-dark.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-dark, #212529) 50%, transparent);
}
.k-button-solid-dark:active, .k-button-solid-dark.k-active {
  border-color: var(--kendo-color-dark-active, #495057);
  background-color: var(--kendo-color-dark-active, #495057);
}
.k-button-solid-dark.k-selected {
  border-color: var(--kendo-color-dark-active, #495057);
  background-color: var(--kendo-color-dark-active, #495057);
}
.k-button-solid-light {
  border-color: var(--kendo-color-light, #f8f9fa);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}
.k-button-solid-light:hover, .k-button-solid-light.k-hover {
  border-color: var(--kendo-color-light-hover, #e9ecef);
  background-color: var(--kendo-color-light-hover, #e9ecef);
}
.k-button-solid-light:focus, .k-button-solid-light.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-light, #f8f9fa) 50%, transparent);
}
.k-button-solid-light:active, .k-button-solid-light.k-active {
  border-color: var(--kendo-color-light-active, #dee2e6);
  background-color: var(--kendo-color-light-active, #dee2e6);
}
.k-button-solid-light.k-selected {
  border-color: var(--kendo-color-light-active, #dee2e6);
  background-color: var(--kendo-color-light-active, #dee2e6);
}
.k-button-solid-inverse {
  border-color: var(--kendo-color-inverse, #212529);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-inverse, #212529);
}
.k-button-solid-inverse:hover, .k-button-solid-inverse.k-hover {
  border-color: var(--kendo-color-inverse-hover, #343a40);
  background-color: var(--kendo-color-inverse-hover, #343a40);
}
.k-button-solid-inverse:focus, .k-button-solid-inverse.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-inverse, #212529) 50%, transparent);
}
.k-button-solid-inverse:active, .k-button-solid-inverse.k-active {
  border-color: var(--kendo-color-inverse-active, #495057);
  background-color: var(--kendo-color-inverse-active, #495057);
}
.k-button-solid-inverse.k-selected {
  border-color: var(--kendo-color-inverse-active, #495057);
  background-color: var(--kendo-color-inverse-active, #495057);
}
.k-button-outline-primary {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-primary-on-surface, #0d6efd);
  background-color: transparent;
}
.k-button-outline-primary:hover, .k-button-outline-primary.k-hover {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-button-outline-primary:focus, .k-button-outline-primary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 50%, transparent);
}
.k-button-outline-primary:active, .k-button-outline-primary.k-active {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-button-outline-primary.k-selected {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-button-outline-secondary {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-secondary-on-surface, #6c757d);
  background-color: transparent;
}
.k-button-outline-secondary:hover, .k-button-outline-secondary.k-hover {
  border-color: var(--kendo-color-secondary, #6c757d);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #6c757d);
}
.k-button-outline-secondary:focus, .k-button-outline-secondary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-secondary, #6c757d) 50%, transparent);
}
.k-button-outline-secondary:active, .k-button-outline-secondary.k-active {
  border-color: var(--kendo-color-secondary, #6c757d);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #6c757d);
}
.k-button-outline-secondary.k-selected {
  border-color: var(--kendo-color-secondary, #6c757d);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #6c757d);
}
.k-button-outline-tertiary {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-tertiary-on-surface, #6f42c1);
  background-color: transparent;
}
.k-button-outline-tertiary:hover, .k-button-outline-tertiary.k-hover {
  border-color: var(--kendo-color-tertiary, #6f42c1);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #6f42c1);
}
.k-button-outline-tertiary:focus, .k-button-outline-tertiary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-tertiary, #6f42c1) 50%, transparent);
}
.k-button-outline-tertiary:active, .k-button-outline-tertiary.k-active {
  border-color: var(--kendo-color-tertiary, #6f42c1);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #6f42c1);
}
.k-button-outline-tertiary.k-selected {
  border-color: var(--kendo-color-tertiary, #6f42c1);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #6f42c1);
}
.k-button-outline-info {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-info-on-surface, #0dcaf0);
  background-color: transparent;
}
.k-button-outline-info:hover, .k-button-outline-info.k-hover {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info, #0dcaf0);
}
.k-button-outline-info:focus, .k-button-outline-info.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-info, #0dcaf0) 50%, transparent);
}
.k-button-outline-info:active, .k-button-outline-info.k-active {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info, #0dcaf0);
}
.k-button-outline-info.k-selected {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info, #0dcaf0);
}
.k-button-outline-success {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-success-on-surface, #198754);
  background-color: transparent;
}
.k-button-outline-success:hover, .k-button-outline-success.k-hover {
  border-color: var(--kendo-color-success, #198754);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #198754);
}
.k-button-outline-success:focus, .k-button-outline-success.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-success, #198754) 50%, transparent);
}
.k-button-outline-success:active, .k-button-outline-success.k-active {
  border-color: var(--kendo-color-success, #198754);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #198754);
}
.k-button-outline-success.k-selected {
  border-color: var(--kendo-color-success, #198754);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #198754);
}
.k-button-outline-warning {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-warning-on-surface, #ffc107);
  background-color: transparent;
}
.k-button-outline-warning:hover, .k-button-outline-warning.k-hover {
  border-color: var(--kendo-color-warning, #ffc107);
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}
.k-button-outline-warning:focus, .k-button-outline-warning.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-warning, #ffc107) 50%, transparent);
}
.k-button-outline-warning:active, .k-button-outline-warning.k-active {
  border-color: var(--kendo-color-warning, #ffc107);
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}
.k-button-outline-warning.k-selected {
  border-color: var(--kendo-color-warning, #ffc107);
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}
.k-button-outline-error {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-error-on-surface, #dc3545);
  background-color: transparent;
}
.k-button-outline-error:hover, .k-button-outline-error.k-hover {
  border-color: var(--kendo-color-error, #dc3545);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #dc3545);
}
.k-button-outline-error:focus, .k-button-outline-error.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 50%, transparent);
}
.k-button-outline-error:active, .k-button-outline-error.k-active {
  border-color: var(--kendo-color-error, #dc3545);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #dc3545);
}
.k-button-outline-error.k-selected {
  border-color: var(--kendo-color-error, #dc3545);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #dc3545);
}
.k-button-outline-dark {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-dark-on-surface, #212529);
  background-color: transparent;
}
.k-button-outline-dark:hover, .k-button-outline-dark.k-hover {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-button-outline-dark:focus, .k-button-outline-dark.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-dark, #212529) 50%, transparent);
}
.k-button-outline-dark:active, .k-button-outline-dark.k-active {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-button-outline-dark.k-selected {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-button-outline-light {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-light-on-surface, #f8f9fa);
  background-color: transparent;
}
.k-button-outline-light:hover, .k-button-outline-light.k-hover {
  border-color: var(--kendo-color-light, #f8f9fa);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}
.k-button-outline-light:focus, .k-button-outline-light.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-light, #f8f9fa) 50%, transparent);
}
.k-button-outline-light:active, .k-button-outline-light.k-active {
  border-color: var(--kendo-color-light, #f8f9fa);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}
.k-button-outline-light.k-selected {
  border-color: var(--kendo-color-light, #f8f9fa);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}
.k-button-outline-inverse {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-inverse-on-surface, #212529);
  background-color: transparent;
}
.k-button-outline-inverse:hover, .k-button-outline-inverse.k-hover {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-button-outline-inverse:focus, .k-button-outline-inverse.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-inverse, #212529) 50%, transparent);
}
.k-button-outline-inverse:active, .k-button-outline-inverse.k-active {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-button-outline-inverse.k-selected {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-button-outline-base {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-base-on-surface, #212529);
  background-color: transparent;
}
.k-button-outline-base:hover, .k-button-outline-base.k-hover {
  border-color: var(--kendo-color-on-base, #212529);
  color: var(--kendo-color-base, #dee2e6);
  background-color: var(--kendo-color-on-base, #212529);
}
.k-button-outline-base:focus, .k-button-outline-base.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 50%, transparent);
}
.k-button-outline-base:active, .k-button-outline-base.k-active {
  border-color: var(--kendo-color-on-base, #212529);
  color: var(--kendo-color-base, #dee2e6);
  background-color: var(--kendo-color-on-base, #212529);
}
.k-button-outline-base.k-selected {
  border-color: var(--kendo-color-on-base, #212529);
  color: var(--kendo-color-base, #dee2e6);
  background-color: var(--kendo-color-on-base, #212529);
}
.k-button-flat-primary {
  color: var(--kendo-color-primary-on-surface, #0d6efd);
}
.k-button-flat-secondary {
  color: var(--kendo-color-secondary-on-surface, #6c757d);
}
.k-button-flat-tertiary {
  color: var(--kendo-color-tertiary-on-surface, #6f42c1);
}
.k-button-flat-info {
  color: var(--kendo-color-info-on-surface, #0dcaf0);
}
.k-button-flat-success {
  color: var(--kendo-color-success-on-surface, #198754);
}
.k-button-flat-warning {
  color: var(--kendo-color-warning-on-surface, #ffc107);
}
.k-button-flat-error {
  color: var(--kendo-color-error-on-surface, #dc3545);
}
.k-button-flat-dark {
  color: var(--kendo-color-dark-on-surface, #212529);
}
.k-button-flat-light {
  color: var(--kendo-color-light-on-surface, #f8f9fa);
}
.k-button-flat-inverse {
  color: var(--kendo-color-inverse-on-surface, #212529);
}
.k-button-flat-base {
  color: inherit;
}
.k-button-link-primary {
  color: var(--kendo-color-primary, #0d6efd);
}
.k-button-link-primary:hover, .k-button-link-primary.k-hover {
  color: var(--kendo-color-primary-hover, #0c64e4);
}
.k-button-link-primary:focus, .k-button-link-primary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 50%, transparent);
}
.k-button-link-primary:active, .k-button-link-primary.k-active {
  color: var(--kendo-color-primary-active, #0a58ca);
}
.k-button-link-primary.k-selected {
  color: var(--kendo-color-primary-active, #0a58ca);
}
.k-button-link-secondary {
  color: var(--kendo-color-secondary, #6c757d);
}
.k-button-link-secondary:hover, .k-button-link-secondary.k-hover {
  color: var(--kendo-color-secondary-hover, #596169);
}
.k-button-link-secondary:focus, .k-button-link-secondary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-secondary, #6c757d) 50%, transparent);
}
.k-button-link-secondary:active, .k-button-link-secondary.k-active {
  color: var(--kendo-color-secondary-active, #495057);
}
.k-button-link-secondary.k-selected {
  color: var(--kendo-color-secondary-active, #495057);
}
.k-button-link-tertiary {
  color: var(--kendo-color-tertiary, #6f42c1);
}
.k-button-link-tertiary:hover, .k-button-link-tertiary.k-hover {
  color: var(--kendo-color-tertiary-hover, #59359a);
}
.k-button-link-tertiary:focus, .k-button-link-tertiary.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-tertiary, #6f42c1) 50%, transparent);
}
.k-button-link-tertiary:active, .k-button-link-tertiary.k-active {
  color: var(--kendo-color-tertiary-active, #4e2f89);
}
.k-button-link-tertiary.k-selected {
  color: var(--kendo-color-tertiary-active, #4e2f89);
}
.k-button-link-info {
  color: var(--kendo-color-info, #0dcaf0);
}
.k-button-link-info:hover, .k-button-link-info.k-hover {
  color: var(--kendo-color-info-hover, #3dd5f3);
}
.k-button-link-info:focus, .k-button-link-info.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-info, #0dcaf0) 50%, transparent);
}
.k-button-link-info:active, .k-button-link-info.k-active {
  color: var(--kendo-color-info-active, #6edff6);
}
.k-button-link-info.k-selected {
  color: var(--kendo-color-info-active, #6edff6);
}
.k-button-link-success {
  color: var(--kendo-color-success, #198754);
}
.k-button-link-success:hover, .k-button-link-success.k-hover {
  color: var(--kendo-color-success-hover, #146C43);
}
.k-button-link-success:focus, .k-button-link-success.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-success, #198754) 50%, transparent);
}
.k-button-link-success:active, .k-button-link-success.k-active {
  color: var(--kendo-color-success-active, #0F5132);
}
.k-button-link-success.k-selected {
  color: var(--kendo-color-success-active, #0F5132);
}
.k-button-link-warning {
  color: var(--kendo-color-warning, #ffc107);
}
.k-button-link-warning:hover, .k-button-link-warning.k-hover {
  color: var(--kendo-color-warning-hover, #ffcd39);
}
.k-button-link-warning:focus, .k-button-link-warning.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-warning, #ffc107) 50%, transparent);
}
.k-button-link-warning:active, .k-button-link-warning.k-active {
  color: var(--kendo-color-warning-active, #ffda6a);
}
.k-button-link-warning.k-selected {
  color: var(--kendo-color-warning-active, #ffda6a);
}
.k-button-link-error {
  color: var(--kendo-color-error, #dc3545);
}
.k-button-link-error:hover, .k-button-link-error.k-hover {
  color: var(--kendo-color-error-hover, #b02a37);
}
.k-button-link-error:focus, .k-button-link-error.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 50%, transparent);
}
.k-button-link-error:active, .k-button-link-error.k-active {
  color: var(--kendo-color-error-active, #9a2530);
}
.k-button-link-error.k-selected {
  color: var(--kendo-color-error-active, #9a2530);
}
.k-button-link-dark {
  color: var(--kendo-color-dark, #212529);
}
.k-button-link-dark:hover, .k-button-link-dark.k-hover {
  color: var(--kendo-color-dark-hover, #343a40);
}
.k-button-link-dark:focus, .k-button-link-dark.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-dark, #212529) 50%, transparent);
}
.k-button-link-dark:active, .k-button-link-dark.k-active {
  color: var(--kendo-color-dark-active, #495057);
}
.k-button-link-dark.k-selected {
  color: var(--kendo-color-dark-active, #495057);
}
.k-button-link-light {
  color: var(--kendo-color-light, #f8f9fa);
}
.k-button-link-light:hover, .k-button-link-light.k-hover {
  color: var(--kendo-color-light-hover, #e9ecef);
}
.k-button-link-light:focus, .k-button-link-light.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-light, #f8f9fa) 50%, transparent);
}
.k-button-link-light:active, .k-button-link-light.k-active {
  color: var(--kendo-color-light-active, #dee2e6);
}
.k-button-link-light.k-selected {
  color: var(--kendo-color-light-active, #dee2e6);
}
.k-button-link-inverse {
  color: var(--kendo-color-dark, #212529);
}
.k-button-link-inverse:hover, .k-button-link-inverse.k-hover {
  color: var(--kendo-color-inverse-hover, #343a40);
}
.k-button-link-inverse:focus, .k-button-link-inverse.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-inverse, #212529) 50%, transparent);
}
.k-button-link-inverse:active, .k-button-link-inverse.k-active {
  color: var(--kendo-color-inverse-active, #495057);
}
.k-button-link-inverse.k-selected {
  color: var(--kendo-color-inverse-active, #495057);
}
.k-button-link-base {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-button-link-base:hover, .k-button-link-base.k-hover {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-button-link-base:focus, .k-button-link-base.k-focus {
  box-shadow: 0 0 0px 0.25rem color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 50%, transparent);
}
.k-button-link-base:active, .k-button-link-base.k-active {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-button-link-base.k-selected {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-button-clear-primary {
  color: var(--kendo-color-primary-on-surface, #0d6efd);
}
.k-button-clear-primary:focus::after, .k-button-clear-primary.k-focus::after, .k-button-clear-primary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-secondary {
  color: var(--kendo-color-secondary-on-surface, #6c757d);
}
.k-button-clear-secondary:focus::after, .k-button-clear-secondary.k-focus::after, .k-button-clear-secondary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-tertiary {
  color: var(--kendo-color-tertiary-on-surface, #6f42c1);
}
.k-button-clear-tertiary:focus::after, .k-button-clear-tertiary.k-focus::after, .k-button-clear-tertiary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-info {
  color: var(--kendo-color-info-on-surface, #0dcaf0);
}
.k-button-clear-info:focus::after, .k-button-clear-info.k-focus::after, .k-button-clear-info:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-success {
  color: var(--kendo-color-success-on-surface, #198754);
}
.k-button-clear-success:focus::after, .k-button-clear-success.k-focus::after, .k-button-clear-success:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-warning {
  color: var(--kendo-color-warning-on-surface, #ffc107);
}
.k-button-clear-warning:focus::after, .k-button-clear-warning.k-focus::after, .k-button-clear-warning:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-error {
  color: var(--kendo-color-error-on-surface, #dc3545);
}
.k-button-clear-error:focus::after, .k-button-clear-error.k-focus::after, .k-button-clear-error:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-dark {
  color: var(--kendo-color-dark-on-surface, #212529);
}
.k-button-clear-dark:focus::after, .k-button-clear-dark.k-focus::after, .k-button-clear-dark:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-light {
  color: var(--kendo-color-light-on-surface, #f8f9fa);
}
.k-button-clear-light:focus::after, .k-button-clear-light.k-focus::after, .k-button-clear-light:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-inverse {
  color: var(--kendo-color-inverse-on-surface, #212529);
}
.k-button-clear-inverse:focus::after, .k-button-clear-inverse.k-focus::after, .k-button-clear-inverse:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-base {
  color: var(--kendo-color-base-on-surface, #212529);
}
.k-button-clear-base:focus::after, .k-button-clear-base.k-focus::after, .k-button-clear-base:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-animation-container {
  position: absolute;
  overflow: hidden;
  z-index: 100;
}
.k-animation-container-fixed {
  position: fixed;
}
.k-animation-container-relative {
  position: relative;
  display: inline-block;
}

.k-animation-container {
  border-radius: 0 0 3px 3px;
}

.k-animation-container-shown {
  overflow: visible;
}

.k-popup {
  border-radius: var(--kendo-border-radius-md, 3px);
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.k-popup .k-item {
  outline: none;
}

.k-popup > .k-colorpalette {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-popup.k-popup-transparent {
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
}

.k-popup.k-popup-flush {
  padding: 0;
}

.k-popup > .k-widget,
.k-popup > .k-coloreditor {
  border-width: 0;
}

.k-shadow {
  box-shadow: var(--kendo-elevation-4, 0px 6px 13px rgba(0, 0, 0, 0.125));
}

.k-popup.k-column-menu {
  padding-top: 0;
  padding-bottom: 0;
}

.k-popup {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
  box-shadow: var(--kendo-elevation-4, 0px 6px 13px rgba(0, 0, 0, 0.125));
}

.k-animation-container {
  border-radius: 0 0 var(--kendo-border-radius-md, 3px) var(--kendo-border-radius-md, 3px);
}

.k-list-container {
  display: flex;
  flex-flow: column nowrap;
}
.k-list-container > .k-list {
  flex: 1;
  height: 100%;
}

.k-list {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--kendo-font-family, inherit);
  display: flex;
  flex-flow: column nowrap;
  outline: none;
  position: relative;
  overflow: hidden;
}
.k-list *,
.k-list *::before,
.k-list *::after {
  box-sizing: border-box;
}

.k-popup > .k-list {
  height: 100%;
  border-width: 0;
}

.k-list-group-sticky-header {
  border-width: 0;
  border-width: 0 0 1px;
  border-style: solid;
  font-weight: var(--kendo-font-weight-bold, normal);
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.k-list-content {
  border-color: inherit;
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}

.k-list-ul {
  margin: 0;
  padding: 0;
  border-width: 0;
  border-color: inherit;
  height: auto;
  list-style: none;
}

.k-list-item, .k-list-optionlabel {
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  position: relative;
  transition-property: color, background-color, outline-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.k-list-item.k-first::before, .k-first.k-list-optionlabel::before {
  content: "";
  border-width: 1px 0 0;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.k-list-item-text::before,
.k-list-header-text::before,
.k-list-optionlabel::before {
  content: "​";
  width: 0px;
  overflow: hidden;
}

.k-list-optionlabel {
  column-gap: 0;
}

.k-list-group-item {
  border-width: 0;
  border-width: 1px 0 0;
  border-style: solid;
  font-weight: var(--kendo-font-weight-bold, normal);
  cursor: default;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  position: relative;
}

.k-list-item-group-label {
  padding-block: 0;
  padding-inline: 0.5em;
  font-size: 0.75em;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
}

.k-virtual-content,
.k-virtual-list .k-list-content {
  overflow-y: scroll;
}

.k-virtual-list .k-list-item, .k-virtual-list .k-list-optionlabel,
.k-virtual-list .k-list-group-item,
.k-virtual-content .k-list-item,
.k-virtual-content .k-list-optionlabel,
.k-virtual-content .k-list-group-item {
  position: absolute;
  width: 100%;
}

.k-virtual-list .k-list-item-text,
.k-virtual-list .k-list-header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-virtual-list .k-list-optionlabel {
  position: relative;
}

.k-list-filter {
  display: block;
  position: relative;
  padding: 0.5rem;
  box-sizing: border-box;
  flex: none;
}

.k-list-sm {
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: 1.4285714286;
}
.k-list-sm .k-list-group-sticky-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}
.k-list-sm .k-list-item, .k-list-sm .k-list-optionlabel {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}
.k-list-sm .k-list-group-item {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-list-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-list-md .k-list-group-sticky-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}
.k-list-md .k-list-item, .k-list-md .k-list-optionlabel {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}
.k-list-md .k-list-group-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-list-lg {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-list-lg .k-list-group-sticky-header {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}
.k-list-lg .k-list-item, .k-list-lg .k-list-optionlabel {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}
.k-list-lg .k-list-group-item {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-no-data, .k-nodata {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  text-align: center;
  white-space: normal;
}

.k-list {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #ffffff;
}

.k-list-group-sticky-header {
  border-color: inherit;
}

.k-list-item:hover, .k-list-optionlabel:hover, .k-list-item.k-hover, .k-hover.k-list-optionlabel {
  color: #333333;
  background-color: transparent;
}
.k-list-item:focus, .k-list-optionlabel:focus, .k-list-item.k-focus, .k-focus.k-list-optionlabel {
  box-shadow: inset 0 0 0 3px color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 15%, transparent);
}
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
  color: #333333;
  background-color: transparent;
}
.k-list-item.k-selected:hover, .k-selected.k-list-optionlabel:hover, .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel {
  color: #333333;
  background-color: var(--kendo-color-primary-hover, #0c64e4);
}

.k-list-optionlabel,
.k-list-optionlabel.k-hover,
.k-list-optionlabel:hover {
  color: var(--kendo-color-subtle, #596169);
}

.k-list-group-item {
  border-color: inherit;
}

.k-list-item-group-label {
  color: #ffffff;
  background-color: #333333;
}

.k-no-data, .k-nodata {
  color: var(--kendo-color-subtle, #596169);
}

.k-menu-bar, .k-menu {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-menu-bar *, .k-menu *,
.k-menu-bar *::before,
.k-menu *::before,
.k-menu-bar *::after,
.k-menu *::after {
  box-sizing: border-box;
}

.k-menu-item {
  border-width: 0;
  outline: 0;
  display: flex;
  flex-flow: column nowrap;
  flex: none;
  position: relative;
  user-select: none;
}

.k-menu-item-content {
  display: block;
  height: auto;
  overflow: visible;
}
.k-menu-item-content .k-actions, .k-menu-item-content .k-form-buttons, .k-menu-item-content .k-edit-buttons,
.k-menu-item-content .k-action-buttons,
.k-menu-item-content .k-columnmenu-actions {
  margin-top: 0;
}

.k-menu-link {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  outline: 0;
  color: inherit;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  gap: var(--kendo-spacing-2, 0.5rem);
  align-items: center;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}

.k-menu-link-text {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-menu-expand-arrow {
  margin-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  flex: none;
  position: relative;
}

.k-menu-horizontal {
  flex-direction: row;
}
.k-menu-horizontal > .k-menu-item + .k-menu-item {
  margin-inline-start: var(--kendo-spacing-0, 0px);
}
.k-menu-horizontal > .k-separator {
  margin-block: 0;
  margin-inline: var(--kendo-spacing-1, 0.25rem);
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
  border-style: solid;
}

.k-menu-vertical {
  flex-direction: column;
}
.k-menu-vertical > .k-menu-item + .k-menu-item {
  margin-top: var(--kendo-spacing-0, 0px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  padding-inline-end: calc(var(--kendo-spacing-4, 1rem) * 2 + 16px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link > .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-4, 1rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-4, 1rem) * 2 + 16px) - var(--kendo-spacing-4, 1rem)/2));
}
.k-menu-vertical > .k-separator {
  margin-block: var(--kendo-spacing-1, 0.25rem);
  margin-inline: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  display: block;
}

.k-menu-popup {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  overflow: auto;
  max-height: 80vh;
}
.k-menu-popup *,
.k-menu-popup *::before,
.k-menu-popup *::after {
  box-sizing: border-box;
}

.k-menu-group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
}
.k-menu-group .k-menu-item + .k-menu-item {
  margin-top: var(--kendo-spacing-0, 0px);
}
.k-menu-group .k-separator {
  margin-block: var(--kendo-spacing-1, 0.25rem);
  margin-inline: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  display: block;
}

.k-menu-popup .k-menu-group {
  position: relative;
}

.k-menu-group-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-sm .k-menu-item {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-sm .k-menu-link {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  padding-inline-end: calc(var(--kendo-spacing-4, 1rem) * 2 + 16px);
}
.k-menu-group-sm .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-4, 1rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-4, 1rem) * 2 + 16px) - var(--kendo-spacing-4, 1rem)/2));
}

.k-menu-group-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-md .k-menu-item {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-md .k-menu-link {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  padding-inline-end: calc(var(--kendo-spacing-4, 1rem) * 2 + 16px);
}
.k-menu-group-md .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-4, 1rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-4, 1rem) * 2 + 16px) - var(--kendo-spacing-4, 1rem)/2));
}

.k-menu-group-lg {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-lg .k-menu-item {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-lg .k-menu-link {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  padding-inline-end: calc(var(--kendo-spacing-4, 1rem) * 2 + 16px);
}
.k-menu-group-lg .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-4, 1rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-4, 1rem) * 2 + 16px) - var(--kendo-spacing-4, 1rem)/2));
}

.k-popups-wrapper {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
}

.k-context-menu {
  margin: 0;
  border-width: 1px;
  border-style: solid;
}
.k-animation-container .k-context-menu.k-menu-horizontal {
  display: flex !important;
  flex-wrap: nowrap;
}

.k-context-menu-popup {
  z-index: 12000;
}
.k-context-menu-popup .k-context-menu {
  border-width: 0;
}

.k-popup .k-context-menu,
.k-context-menu-popup .k-context-menu {
  border-width: 0;
}

.k-menu-scroll-wrapper {
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
}
.k-menu-scroll-wrapper .k-menu {
  overflow: hidden;
  flex-wrap: nowrap;
}
.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-radius: 0;
  padding: 0;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: inherit;
  position: absolute;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-left {
  top: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 16px;
  border-right-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-right {
  top: 0;
  inset-inline-end: 0;
  height: 100%;
  width: 16px;
  border-left-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-up {
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 16px;
  border-bottom-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-down {
  bottom: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 16px;
  border-top-width: 1px;
}

.k-menu.k-menu-horizontal:not(.k-context-menu) {
  padding-block: 0.5rem;
}

.k-menu.k-menu-vertical:not(.k-context-menu) {
  padding-block: 0.5rem;
  padding-inline: 0;
}

.k-menu-popup .k-menu-group,
.k-menu-popup .k-menu-vertical {
  padding: 0;
}

.k-context-menu-popup > .k-popup:not(.k-menu-popup) {
  padding: 0;
}
.k-context-menu-popup .k-context-menu {
  border-radius: var(--kendo-border-radius-md, 3px);
}
.k-context-menu-popup .k-header {
  font-size: inherit;
  font-weight: inherit;
}

.k-menu:not(.k-context-menu) {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface, #f8f9fa);
}
.k-menu:not(.k-context-menu) > .k-item {
  color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 50%, transparent);
}
.k-menu:not(.k-context-menu) > .k-item:hover, .k-menu:not(.k-context-menu) > .k-item.k-hover {
  color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 70%, transparent);
}
.k-menu:not(.k-context-menu) > .k-item:active, .k-menu:not(.k-context-menu) > .k-item.k-active {
  color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 90%, transparent);
}
.k-menu:not(.k-context-menu) > .k-item:focus, .k-menu:not(.k-context-menu) > .k-item.k-focus {
  box-shadow: inset 0 0 0 3px color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 15%, transparent);
}

.k-menu-group,
.k-menu.k-context-menu {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #ffffff;
}
.k-menu-group .k-item > .k-link:hover, .k-menu-group .k-item > .k-link.k-hover,
.k-menu.k-context-menu .k-item > .k-link:hover,
.k-menu.k-context-menu .k-item > .k-link.k-hover {
  color: #333333;
  background-color: transparent;
}
.k-menu-group .k-item > .k-link:active, .k-menu-group .k-item > .k-link.k-active, .k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected {
  color: #333333;
  background-color: transparent;
}
.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link {
  box-shadow: inset 0 0 0 3px color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 15%, transparent);
}

.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 50%, transparent);
  background-color: var(--kendo-color-surface, #f8f9fa);
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover {
  color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 70%, transparent);
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover::before {
  opacity: 0;
}

.k-toolbar {
  margin: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-toolbar::before {
  content: "";
  height: calc(1.4285714286 * 1em + 0.375rem * 2 + 0);
}
.k-toolbar.k-toolbar-resizable {
  flex-wrap: nowrap;
}
.k-toolbar > * {
  flex-shrink: 0;
  display: inline-flex;
  align-content: center;
  vertical-align: middle;
}
.k-toolbar > * > label {
  align-self: center;
}
.k-toolbar .k-input,
.k-toolbar .k-picker {
  width: 10em;
}
.k-toolbar .k-color-picker, .k-toolbar .k-colorpicker {
  width: min-content;
}
.k-toolbar .k-toolbar-overflow-button {
  margin-inline-start: auto;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
  width: 0;
  height: calc(1.4285714286 * 1em);
  border-width: 0 0 0 1px;
  border-style: solid;
  align-self: center;
}
.k-toolbar .k-toolbar-spacer {
  height: calc(1.4285714286 * 1em);
  flex: 1 0 0%;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
  text-decoration: none;
  outline: 0;
}

.k-toolbar-group {
  display: flex;
  flex-direction: inherit;
  flex-wrap: nowrap;
  gap: inherit;
}

.k-toolbar-renderer {
  display: inline-block;
  border-color: inherit;
}

.k-toolbar-sm {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  gap: var(--kendo-spacing-1\.5, 0.375rem);
}
.k-toolbar-sm::before {
  margin-inline-start: calc(var(--kendo-spacing-1\.5, 0.375rem) * -1);
}
.k-toolbar-sm > * > label {
  margin-inline-end: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-toolbar-md {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-toolbar-md::before {
  margin-inline-start: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-toolbar-md > * > label {
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
}

.k-toolbar-lg {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
  gap: var(--kendo-spacing-2\.5, 0.625rem);
}
.k-toolbar-lg::before {
  margin-inline-start: calc(var(--kendo-spacing-2\.5, 0.625rem) * -1);
}
.k-toolbar-lg > * > label {
  margin-inline-end: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-toolbar-sm::before {
  height: calc(1.4285714286 * 1em + 0.25rem * 2 + 0);
}

.k-toolbar-md::before {
  height: calc(var(--kendo-line-height, normal) * 1em + 0.375rem * 2 + 0);
}

.k-toolbar-lg::before {
  height: calc(var(--kendo-line-height, normal) * 1em + 0.625rem * 2 + 0);
}

.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
  padding-block: var(--kendo-spacing-2, 0.5rem) !important;
  padding-inline: var(--kendo-spacing-2, 0.5rem) !important;
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-flow: row nowrap;
  gap: var(--kendo-spacing-2, 0.5rem);
  align-items: center;
}
.k-floating-toolbar .k-toolbar,
.editorToolbarWindow.k-window-content .k-toolbar {
  padding: 0;
  border-width: 0;
  flex-shrink: 1;
  color: inherit;
  background: none;
}

.k-editortoolbar-dragHandle {
  cursor: move;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
}

.k-overflow-container {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-overflow-container > .k-item {
  border-color: inherit;
}
.k-overflow-container .k-separator {
  margin: var(--kendo-spacing-1, 0.25rem) 0;
}
.k-overflow-container .k-overflow-tool-group {
  display: block;
}
.k-overflow-container .k-overflow-button {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  width: 100%;
  height: auto;
  border-width: 0;
  border-radius: 0;
  aspect-ratio: auto;
  color: inherit;
  background-color: transparent;
  background-image: none;
  font: inherit;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  justify-content: flex-start;
  position: relative;
}
.k-overflow-container .k-button-group {
  box-shadow: none;
  display: flex;
  flex-flow: column nowrap;
}
.k-overflow-container .k-split-button {
  display: flex;
  flex-direction: column;
}
.k-overflow-container .k-overflow-hidden {
  display: none;
}

.k-toolbar.k-toolbar-flat {
  border-width: 1px 0;
  border-top-color: transparent !important;
  border-bottom-color: inherit;
  color: inherit;
  background: none !important;
  box-shadow: none;
}

.k-toolbar {
  border-color: #c2c2c2;
  color: #333333;
  background-color: #f8f8f8;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
  border-color: inherit;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}
.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
  border-color: #c2c2c2;
  color: #333333;
  background-color: #f8f8f8;
}

.k-overflow-container .k-button:hover, .k-overflow-container .k-button.k-hover {
  color: #333333;
  background: transparent;
}
.k-overflow-container .k-button:focus, .k-overflow-container .k-button.k-focus {
  box-shadow: inset 0 0 0 3px color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 15%, transparent);
}
.k-overflow-container .k-button:active, .k-overflow-container .k-button.k-active {
  color: #333333;
  background: transparent;
}
.k-overflow-container .k-button.k-selected {
  color: #333333;
  background: transparent;
}
.k-overflow-container .k-button:disabled, .k-overflow-container .k-button.k-disabled {
  color: inherit;
}

.k-pane-wrapper {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-pane {
  width: 100%;
  height: 100%;
  user-select: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow-x: hidden;
}
.k-pane-wrapper .k-view {
  top: 0;
  left: 0;
  position: relative;
  border: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-content {
  min-height: 1px;
  flex: 1;
  align-items: stretch;
  display: block;
  width: auto;
  overflow: hidden;
  position: relative;
}

.k-pane-wrapper > div.k-pane {
  box-shadow: none;
  font-weight: normal;
}
.k-pane-wrapper .k-popup-edit-form .k-content,
.k-pane-wrapper .k-grid-edit-form .k-content,
.k-pane-wrapper .k-grid-header-menu .k-content,
.k-pane-wrapper .k-grid-filter-menu .k-content {
  overflow-y: auto;
}
.k-pane-wrapper .k-popup-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-header-menu .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-filter-menu .k-content > .k-scroll-container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}
.k-pane-wrapper .k-grid-edit-form .k-popup-edit-form,
.k-pane-wrapper .k-grid-edit-form .k-edit-form-container {
  width: auto;
  min-width: auto;
}

.k-grid.k-grid-mobile {
  border-width: 0;
}

.k-grid-mobile .k-resize-handle-inner::before {
  content: "\e01e";
  position: absolute;
  font: 16px/1 "WebComponentsIcons";
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.2em;
}
.k-grid-mobile .k-header a {
  user-select: none;
}

.k-pane-wrapper .k-scheduler-edit-form .k-recur-view {
  padding: 0;
  flex-direction: column;
  align-items: stretch;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-view > .k-listgroup-form-row {
  margin: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-items-wrap {
  width: 100%;
  margin-block: -1px;
  margin-inline: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-scheduler-recur-end-wrap {
  white-space: nowrap;
}

.k-scheduler.k-scheduler-mobile {
  border-width: 0;
}

.k-scheduler-mobile th {
  font-weight: normal;
}
.k-scheduler-mobile .k-event:hover .k-resize-handle {
  visibility: hidden;
}
.k-scheduler-mobile .k-scheduler-toolbar {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-scheduler-mobile .k-scheduler-toolbar > * {
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar::before {
  display: none;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current {
  line-height: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-views-wrapper .k-views-dropdown {
  display: inline-block;
}
.k-scheduler-mobile .k-scheduler-footer {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  justify-content: space-between;
}
.k-scheduler-mobile .k-scheduler-footer > * {
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-footer::before {
  display: none;
}
.k-scheduler-mobile .k-scheduler-monthview .k-hidden {
  height: 40px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-scheduler-table td {
  height: 40px;
  vertical-align: top;
  text-align: center;
}
.k-scheduler-mobile .k-scheduler-monthview .k-events-container {
  position: absolute;
  text-align: center;
  height: 6px;
  line-height: 6px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-event {
  position: static;
  padding: 4px;
  border-radius: 50%;
  display: inline-block;
  width: 4px;
  height: 4px;
  min-height: 0;
  margin: 1px;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header.k-mobile-horizontal-header .k-scheduler-times table tr:first-child {
  display: none;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header .k-scheduler-header .k-scheduler-date-group {
  display: none;
}
.k-scheduler-mobile .k-scheduler-header-wrap > div {
  overflow: visible;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-mobile-header {
  display: none;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table .k-scheduler-groupcolumn {
  width: 1%;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table td {
  white-space: normal;
}
.k-scheduler-mobile .k-mobile-header .k-scheduler-table td,
.k-scheduler-mobile .k-mobile-header .k-scheduler-table th {
  height: 1.5em;
}
.k-scheduler-mobile .k-time-text,
.k-scheduler-mobile .k-time-period {
  display: block;
  line-height: 1;
}
.k-scheduler-mobile .k-time-period {
  font-size: 0.7em;
}
.k-scheduler-mobile .k-scheduler-table td,
.k-scheduler-mobile .k-scheduler-table th {
  height: 2em;
  vertical-align: middle;
}
.k-scheduler-mobile .k-scheduler-datecolumn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.k-scheduler-mobile .k-task {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.k-scheduler-mobile .k-task .k-scheduler-mark {
  border-radius: 50%;
  margin: 0;
}
.k-scheduler-mobile .k-task .k-scheduler-task-text {
  flex: 1 1 0%;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn {
  vertical-align: top;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn .k-scheduler-group-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
}
.k-scheduler-mobile .k-scrollbar-h tr + tr .k-scheduler-times {
  border-bottom-width: 0;
}

.k-pane-wrapper .k-appbar {
  padding: var(--kendo-spacing-1, 0.25rem);
}
.k-pane-wrapper .k-list-title,
.k-pane-wrapper .k-filter-help-text {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  display: block;
}
.k-pane-wrapper .k-listgroup-title {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  font-weight: bold;
  text-transform: uppercase;
}
.k-pane-wrapper .k-listgroup .k-listgroup-item {
  border-color: inherit;
}
.k-pane-wrapper .k-listgroup + .k-listgroup {
  margin-top: var(--kendo-spacing-4, 1rem);
}
.k-pane-wrapper .k-column-menu {
  padding: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-pane-wrapper .k-column-menu .k-filter-item .k-filterable * {
  pointer-events: none;
}
.k-pane-wrapper .k-column-menu .k-list-title,
.k-pane-wrapper .k-column-menu .k-listgroup-title {
  padding: 0;
}
.k-pane-wrapper .k-column-menu .k-listgroup {
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-pane-wrapper .k-filter-menu {
  padding: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-pane-wrapper .k-filter-menu .k-list-title {
  padding: 0;
}
.k-pane-wrapper .k-filter-menu .k-list-filter {
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-filter-tools {
  display: flex;
  flex-flow: row nowrap;
  gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-listgroup {
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-pane-wrapper .k-popup-edit-form .k-recur-editor-wrap {
  display: flex;
}
.k-pane-wrapper .k-popup-edit-form .k-mobiletimezoneeditor {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex: 1 1 auto;
}
.k-pane-wrapper .k-multicheck-wrap.k-listgroup {
  overflow-y: auto;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm,
.k-pane-wrapper .k-pager.k-pager-sm {
  justify-content: center;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm .k-pager-refresh,
.k-pane-wrapper .k-pager.k-pager-sm .k-pager-refresh {
  display: none;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li, .k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-scheduler-toolbar > ul > li, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li {
  border: 0;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload, .k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-task .k-i-reload, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload {
  margin-left: 0.5em;
  margin-right: 0;
}

.k-pane-wrapper,
.k-view {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-pane-wrapper .k-pane > .k-view > .k-content {
  background-position: 50% 50%;
  background-color: var(--kendo-color-surface, #f8f9fa);
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-column-active + th.k-header {
  border-left-color: var(--kendo-color-on-app-surface, #212529);
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-resize-handle-inner::before {
  background-color: var(--kendo-color-surface, #f8f9fa);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current {
  color: var(--kendo-color-primary, #0d6efd);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendadate {
  color: inherit;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-time-period,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendaweekday {
  color: var(--kendo-color-subtle, #596169);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-wrap,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-datecolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-group-cell,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-all-day {
  background-color: var(--kendo-color-surface, #f8f9fa);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools > li {
  display: inline-block;
  background-color: inherit;
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-item,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-link,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-link,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-link,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-item,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-link {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries {
  color: var(--kendo-color-primary, #0d6efd);
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected {
  color: var(--kendo-color-primary, #0d6efd);
  background: none;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected .k-link {
  color: inherit;
}

.k-progressbar {
  border-radius: var(--kendo-border-radius-md, 3px);
  --kendo-progressbar-value: 0;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: 0.65625rem;
  line-height: var(--kendo-line-height-xs, normal);
  display: inline-grid;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-progressbar *,
.k-progressbar *::before,
.k-progressbar *::after {
  box-sizing: border-box;
}
.k-progressbar > .k-progressbar-value {
  border-width: 0;
  border-style: solid;
  display: flex;
  overflow: hidden;
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.k-progressbar > .k-progressbar-chunks {
  border-width: inherit;
  border-style: inherit;
  white-space: nowrap;
  display: flex;
  align-items: stretch;
  flex: 1;
  gap: 1px;
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.k-progressbar .k-progressbar-chunk {
  display: block;
  border-width: 0;
  flex: 1;
}

.k-progress-status-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.k-progress-status-wrap.k-progress-start {
  justify-content: flex-start;
}
.k-progress-status-wrap.k-progress-center {
  justify-content: center;
}
.k-progress-status-wrap.k-progress-end {
  justify-content: flex-end;
}

.k-progress-status {
  padding-block: 0;
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  min-width: 10px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}

.k-progressbar-horizontal {
  width: 100%;
  height: var(--kendo-progressbar-height, 0.25rem);
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
}
.k-progressbar-horizontal .k-progress-status-wrap {
  flex-direction: row;
}
.k-progressbar-horizontal > .k-progressbar-value {
  width: calc(var(--kendo-progressbar-value, 0) * 1%);
  flex-direction: row;
}
.k-progressbar-horizontal > .k-progressbar-value > .k-progress-status-wrap {
  width: calc(100% * 100 / var(--kendo-progressbar-value, 1));
}
.k-progressbar-horizontal > .k-progressbar-chunks {
  flex-direction: row;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-progressbar-value {
  flex-direction: row-reverse;
  justify-self: flex-end;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-progressbar-chunks {
  flex-direction: row-reverse;
}

.k-progressbar-vertical {
  width: var(--kendo-progressbar-height, 0.25rem);
  height: 27em;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
}
.k-progressbar-vertical .k-progress-status-wrap {
  flex-direction: column-reverse;
}
.k-progressbar-vertical .k-progress-status {
  writing-mode: vertical-lr;
}
.k-progressbar-vertical > .k-progressbar-value {
  height: calc(var(--kendo-progressbar-value, 0) * 1%);
  flex-direction: column-reverse;
  align-self: flex-end;
  align-items: flex-end;
}
.k-progressbar-vertical > .k-progressbar-value > .k-progress-status-wrap {
  height: calc(100% * 100 / var(--kendo-progressbar-value, 1));
}
.k-progressbar-vertical > .k-progressbar-chunks {
  flex-direction: column-reverse;
}
.k-progressbar-vertical.k-progressbar-reverse {
  flex-direction: column;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-progressbar-value {
  flex-direction: column;
  align-self: flex-start;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-progressbar-chunks {
  flex-direction: column;
}

.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-progressbar-value,
.k-progressbar-indeterminate .k-progressbar-chunk {
  display: none;
}

.telerik-blazor.k-progressbar-horizontal > .k-progressbar-value,
.telerik-blazor.k-progressbar-horizontal > .k-progressbar-value > .k-progress-status-wrap {
  transition: width 0.1s ease-in-out;
}

.k-rtl .k-progressbar,
.k-progressbar[dir=rtl] {
  direction: rtl;
}

.k-circular-progressbar {
  display: inline-block;
  text-align: start;
  position: relative;
}

.k-circular-progressbar-surface {
  height: 100%;
}
.k-circular-progressbar-surface > div {
  width: 100%;
  height: 100%;
}
.k-circular-progressbar-surface svg {
  width: 100%;
  height: 100%;
}

.k-circular-progressbar-scale {
  fill: none;
}

.k-circular-progressbar-arc {
  transform-box: fill-box;
  transform-origin: center center;
  transform: rotate(-90deg);
  stroke-linecap: round;
  fill: none;
}

.k-circular-progressbar-label {
  position: absolute;
  text-align: center;
  padding: 0;
  margin: 0;
}

@keyframes kendo-progressbar-indeterminate-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0.25rem 0;
  }
}
.k-progressbar {
  color: var(--kendo-color-base-on-subtle, #212529);
  background-color: var(--bs-secondary-bg);
}
.k-progressbar .k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-chunk-progressbar {
  background-color: transparent;
}

.k-progressbar-chunk {
  background-color: var(--bs-secondary-bg);
}
.k-progressbar-chunk .k-selected {
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-progressbar-indeterminate {
  color: var(--kendo-color-base-on-subtle, #212529);
  background-color: var(--bs-secondary-bg);
  background-image: linear-gradient(45deg, color-mix(in srgb, var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) 55%, transparent) 25%, transparent 25%, transparent 50%, color-mix(in srgb, var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) 55%, transparent) 50%, color-mix(in srgb, var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34)) 55%, transparent) 75%, transparent 75%, transparent);
  background-size: 0.25rem 0.25rem;
  animation: kendo-progressbar-indeterminate-animation 1s linear infinite;
}

.k-circular-progressbar-scale {
  stroke: var(--bs-secondary-bg);
}

.k-circular-progressbar-arc {
  stroke: var(--kendo-color-primary, #0d6efd);
  transition: stroke 0.5s ease;
}

.k-actions, .k-form-buttons, .k-edit-buttons,
.k-action-buttons,
.k-columnmenu-actions {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  box-sizing: border-box;
  border-width: 0px 0 0 0;
  border-style: solid;
  border-color: inherit;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  overflow: hidden;
  clear: both;
}
.k-actions *, .k-form-buttons *, .k-edit-buttons *,
.k-action-buttons *,
.k-columnmenu-actions *,
.k-actions *::before,
.k-form-buttons *::before,
.k-edit-buttons *::before,
.k-action-buttons *::before,
.k-columnmenu-actions *::before,
.k-actions *::after,
.k-form-buttons *::after,
.k-edit-buttons *::after,
.k-action-buttons *::after,
.k-columnmenu-actions *::after {
  box-sizing: border-box;
}

.k-popup .k-actions, .k-popup .k-form-buttons, .k-popup .k-edit-buttons,
.k-popup .k-action-buttons,
.k-popup .k-columnmenu-actions {
  margin-top: var(--kendo-spacing-4, 1rem);
}

.k-actions-start {
  justify-content: flex-start;
}

.k-actions-center {
  justify-content: center;
}

.k-actions-end {
  justify-content: flex-end;
}

.k-actions-stretched > * {
  flex: 1 0 0%;
}

.k-actions-horizontal {
  width: 100%;
  flex-flow: row nowrap;
}

.k-actions-vertical {
  flex-flow: column nowrap;
}

.k-input,
.k-picker {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1.4285714286;
  font-weight: normal;
  text-align: start;
  box-shadow: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.k-input *,
.k-input *::before,
.k-input *::after,
.k-picker *,
.k-picker *::before,
.k-picker *::after {
  box-sizing: border-box;
}
.k-input > .k-input,
.k-input > .k-picker,
.k-picker > .k-input,
.k-picker > .k-picker {
  border-width: 0 !important;
  color: inherit;
  background: none;
  font: inherit;
  outline: 0;
  box-shadow: none;
}
.k-input > *,
.k-picker > * {
  margin: 0;
}
.k-input::placeholder,
.k-picker::placeholder {
  color: #999999;
  opacity: 1;
  user-select: none;
}

input.k-input,
textarea.k-textarea {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
}
input.k-input:disabled, input.k-input[disabled],
textarea.k-textarea:disabled,
textarea.k-textarea[disabled] {
  outline: none;
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
[disabled] input.k-input:disabled, .k-disabled input.k-input:disabled, [disabled] input.k-input[disabled], .k-disabled input.k-input[disabled],
[disabled] textarea.k-textarea:disabled,
.k-disabled textarea.k-textarea:disabled,
[disabled] textarea.k-textarea[disabled],
.k-disabled textarea.k-textarea[disabled] {
  opacity: 1;
  filter: grayscale(0);
}

textarea.k-textarea {
  overflow: auto;
}

.k-picker {
  cursor: pointer;
}

select.k-picker {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  appearance: auto;
}
select.k-picker:disabled, select.k-picker[disabled] {
  outline: none;
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
[disabled] select.k-picker:disabled, .k-disabled select.k-picker:disabled, [disabled] select.k-picker[disabled], .k-disabled select.k-picker[disabled] {
  opacity: 1;
  filter: grayscale(0);
}

.k-input-inner {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  width: 100%;
  border: 0;
  outline: 0;
  color: inherit;
  background: none;
  font: inherit;
  flex: 1;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.k-input-inner::placeholder {
  color: #999999;
  opacity: 1;
  user-select: none;
}
.k-input-inner:invalid {
  box-shadow: none;
}

span.k-input-inner {
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

textarea.k-input-inner {
  margin: 0;
}

.k-input-value-icon {
  flex: none;
}

.k-input-value-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-input-value-text::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-input-values {
  min-width: 0px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: text;
}

.k-input-values .k-chip-list {
  display: contents;
}

.k-icon-picker .k-input-inner {
  padding: 0.375rem;
  justify-content: center;
}

.k-input-prefix,
.k-input-suffix {
  border-color: inherit;
  display: flex;
  align-items: center;
  flex: none;
}
.k-input-prefix > *,
.k-input-suffix > * {
  flex-shrink: 0;
  border: none;
}
.k-input-prefix-vertical,
.k-input-suffix-vertical {
  flex-flow: column wrap;
}
.k-input-prefix-horizontal,
.k-input-suffix-horizontal {
  flex-flow: row wrap;
}

.k-input-separator {
  margin: 0;
  border-style: solid;
  border-color: inherit;
  border-width: 0 0 0 1px;
  height: 16px;
  align-self: center;
}
.k-input-separator-horizontal {
  height: auto;
  margin-inline: 0.375rem;
  align-self: stretch;
  border-width: 1px 0 0;
}
.k-input-separator-vertical {
  height: auto;
  margin-block: 0.375rem;
  align-self: stretch;
  border-width: 0 0 0 1px;
}

.k-input-icon,
.k-input-validation-icon,
.k-input-loading-icon {
  flex: none;
  align-self: center;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
}

.k-input-loading-icon {
  width: 1em;
  height: 1em;
  font-size: 16px;
}

.k-input-icon.k-svg-icon > svg, .k-input-icon.k-icon-md > svg,
.k-input-validation-icon.k-svg-icon > svg,
.k-input-validation-icon.k-icon-md > svg,
.k-input-loading-icon.k-svg-icon > svg,
.k-input-loading-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-input-icon.k-icon-xs > svg,
.k-input-validation-icon.k-icon-xs > svg,
.k-input-loading-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-input-icon.k-icon-sm > svg,
.k-input-validation-icon.k-icon-sm > svg,
.k-input-loading-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-input-icon.k-icon-lg > svg,
.k-input-validation-icon.k-icon-lg > svg,
.k-input-loading-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-input-icon.k-icon-xl > svg,
.k-input-validation-icon.k-icon-xl > svg,
.k-input-loading-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-input-icon.k-icon-xxl > svg,
.k-input-validation-icon.k-icon-xxl > svg,
.k-input-loading-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-input-icon.k-icon-xxxl > svg,
.k-input-validation-icon.k-icon-xxxl > svg,
.k-input-loading-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-clear-value {
  outline: 0;
  flex: none;
  align-self: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
}

.k-clear-value:hover,
.k-clear-value.k-hover {
  opacity: 1;
}

.k-clear-value:focus-visible {
  outline: 1px dotted;
  outline-offset: -1px;
}

.k-input-button {
  width: auto;
  border-width: 0;
  border-inline-start-width: 1px;
  flex: none;
  aspect-ratio: auto;
  box-shadow: none;
}
.k-input-button > .k-button-icon {
  min-width: auto !important;
}
.k-input-button:focus {
  box-shadow: none;
}

.k-picker .k-input-button {
  color: inherit;
  background: none;
  border-color: transparent;
}

.k-input-spinner {
  width: auto;
  flex: none;
  display: flex;
  flex-flow: column nowrap;
}
.k-input-spinner .k-spinner-increase,
.k-input-spinner .k-spinner-decrease {
  border-width: 0;
  border-inline-start-width: 1px;
  flex: 1 1 50%;
  box-shadow: none;
  position: relative;
  aspect-ratio: auto;
}
.k-input-spinner .k-spinner-increase .k-button-icon,
.k-input-spinner .k-spinner-decrease .k-button-icon {
  margin-block: -1em;
  margin-inline: 0;
  min-width: auto !important;
  min-height: auto !important;
}
.k-input-spinner .k-spinner-increase .k-icon {
  bottom: auto;
}
.k-input-spinner .k-spinner-decrease .k-icon {
  top: auto;
}

.k-searchbar {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
}

.k-picker-wrap,
.k-dropdown-wrap,
.k-dateinput-wrap,
.k-multiselect-wrap,
.k-numeric-wrap {
  width: 100%;
  border-width: 0;
  border-color: inherit;
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  position: relative;
}

.k-input-flat,
.k-picker-flat {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-width: 1px 0;
  border-top-color: transparent !important;
  background-image: none !important;
}

.k-input-outline {
  background: none !important;
}

.k-input-sm,
.k-picker-sm {
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: 1.4285714286;
}
.k-input-sm .k-input-values,
.k-picker-sm .k-input-values {
  padding: calc(0.25rem / 2);
  gap: calc(0.25rem / 2);
}
.k-input-sm .k-input-values > .k-searchbar,
.k-input-sm .k-input-values > .k-input-inner,
.k-picker-sm .k-input-values > .k-searchbar,
.k-picker-sm .k-input-values > .k-input-inner {
  margin: calc(0.25rem / 2 * -1);
}
.k-input-sm .k-input-inner,
.k-picker-sm .k-input-inner {
  padding-block: 0.25rem;
  padding-inline: 0.75rem;
}
.k-input-sm .k-input-button,
.k-input-sm .k-spinner-increase,
.k-input-sm .k-spinner-decrease,
.k-picker-sm .k-input-button,
.k-picker-sm .k-spinner-increase,
.k-picker-sm .k-spinner-decrease {
  padding-block: 0.25rem;
  padding-inline: 0.25rem;
}
.k-input-sm .k-input-icon,
.k-input-sm .k-input-validation-icon,
.k-input-sm .k-input-loading-icon,
.k-input-sm .k-clear-value,
.k-input-sm .k-input-prefix > .k-icon,
.k-input-sm .k-input-prefix > .k-input-prefix-text,
.k-input-sm .k-input-suffix > .k-icon,
.k-input-sm .k-input-suffix > .k-input-suffix-text,
.k-picker-sm .k-input-icon,
.k-picker-sm .k-input-validation-icon,
.k-picker-sm .k-input-loading-icon,
.k-picker-sm .k-clear-value,
.k-picker-sm .k-input-prefix > .k-icon,
.k-picker-sm .k-input-prefix > .k-input-prefix-text,
.k-picker-sm .k-input-suffix > .k-icon,
.k-picker-sm .k-input-suffix > .k-input-suffix-text {
  padding-block: 0.25rem;
  padding-inline: 0.25rem;
  box-sizing: content-box;
}
.k-input-sm .k-input-separator-horizontal,
.k-picker-sm .k-input-separator-horizontal {
  margin-inline: 0.25rem;
}
.k-input-sm .k-input-separator-vertical,
.k-picker-sm .k-input-separator-vertical {
  margin-block: 0.25rem;
}
.k-input-sm.k-icon-picker .k-input-inner,
.k-picker-sm.k-icon-picker .k-input-inner {
  width: calc(1.4285714286 * 1em);
  height: calc(1.4285714286 * 1em);
  padding: 0.25rem;
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-sm .k-input-button {
  padding: 0.25rem;
}

select.k-picker-sm {
  padding-block: 0.25rem;
  padding-inline: 0.75rem;
}

.k-input-md,
.k-picker-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-input-md .k-input-values,
.k-picker-md .k-input-values {
  padding: calc(0.375rem / 2);
  gap: calc(0.375rem / 2);
}
.k-input-md .k-input-values > .k-searchbar,
.k-input-md .k-input-values > .k-input-inner,
.k-picker-md .k-input-values > .k-searchbar,
.k-picker-md .k-input-values > .k-input-inner {
  margin: calc(0.375rem / 2 * -1);
}
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
}
.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
  padding-block: 0.375rem;
  padding-inline: 0.375rem;
}
.k-input-md .k-input-icon,
.k-input-md .k-input-validation-icon,
.k-input-md .k-input-loading-icon,
.k-input-md .k-clear-value,
.k-input-md .k-input-prefix > .k-icon,
.k-input-md .k-input-prefix > .k-input-prefix-text,
.k-input-md .k-input-suffix > .k-icon,
.k-input-md .k-input-suffix > .k-input-suffix-text,
.k-picker-md .k-input-icon,
.k-picker-md .k-input-validation-icon,
.k-picker-md .k-input-loading-icon,
.k-picker-md .k-clear-value,
.k-picker-md .k-input-prefix > .k-icon,
.k-picker-md .k-input-prefix > .k-input-prefix-text,
.k-picker-md .k-input-suffix > .k-icon,
.k-picker-md .k-input-suffix > .k-input-suffix-text {
  padding-block: 0.375rem;
  padding-inline: 0.375rem;
  box-sizing: content-box;
}
.k-input-md .k-input-separator-horizontal,
.k-picker-md .k-input-separator-horizontal {
  margin-inline: 0.375rem;
}
.k-input-md .k-input-separator-vertical,
.k-picker-md .k-input-separator-vertical {
  margin-block: 0.375rem;
}
.k-input-md.k-icon-picker .k-input-inner,
.k-picker-md.k-icon-picker .k-input-inner {
  width: calc(var(--kendo-line-height, normal) * 1em);
  height: calc(var(--kendo-line-height, normal) * 1em);
  padding: 0.375rem;
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-md .k-input-button {
  padding: 0.375rem;
}

select.k-picker-md {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
}

.k-input-lg,
.k-picker-lg {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-input-lg .k-input-values,
.k-picker-lg .k-input-values {
  padding: calc(0.5rem / 2);
  gap: calc(0.5rem / 2);
}
.k-input-lg .k-input-values > .k-searchbar,
.k-input-lg .k-input-values > .k-input-inner,
.k-picker-lg .k-input-values > .k-searchbar,
.k-picker-lg .k-input-values > .k-input-inner {
  margin: calc(0.5rem / 2 * -1);
}
.k-input-lg .k-input-inner,
.k-picker-lg .k-input-inner {
  padding-block: 0.5rem;
  padding-inline: 0.75rem;
}
.k-input-lg .k-input-button,
.k-input-lg .k-spinner-increase,
.k-input-lg .k-spinner-decrease,
.k-picker-lg .k-input-button,
.k-picker-lg .k-spinner-increase,
.k-picker-lg .k-spinner-decrease {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}
.k-input-lg .k-input-icon,
.k-input-lg .k-input-validation-icon,
.k-input-lg .k-input-loading-icon,
.k-input-lg .k-clear-value,
.k-input-lg .k-input-prefix > .k-icon,
.k-input-lg .k-input-prefix > .k-input-prefix-text,
.k-input-lg .k-input-suffix > .k-icon,
.k-input-lg .k-input-suffix > .k-input-suffix-text,
.k-picker-lg .k-input-icon,
.k-picker-lg .k-input-validation-icon,
.k-picker-lg .k-input-loading-icon,
.k-picker-lg .k-clear-value,
.k-picker-lg .k-input-prefix > .k-icon,
.k-picker-lg .k-input-prefix > .k-input-prefix-text,
.k-picker-lg .k-input-suffix > .k-icon,
.k-picker-lg .k-input-suffix > .k-input-suffix-text {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
  box-sizing: content-box;
}
.k-input-lg .k-input-separator-horizontal,
.k-picker-lg .k-input-separator-horizontal {
  margin-inline: 0.5rem;
}
.k-input-lg .k-input-separator-vertical,
.k-picker-lg .k-input-separator-vertical {
  margin-block: 0.5rem;
}
.k-input-lg.k-icon-picker .k-input-inner,
.k-picker-lg.k-icon-picker .k-input-inner {
  width: calc(var(--kendo-line-height, normal) * 1em);
  height: calc(var(--kendo-line-height, normal) * 1em);
  padding: 0.5rem;
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-lg .k-input-button {
  padding: 0.5rem;
}

select.k-picker-lg {
  padding-block: 0.5rem;
  padding-inline: 0.75rem;
}

.k-input > kendo-popup,
.k-picker > kendo-popup {
  position: fixed;
}

@keyframes autoFillStart {}
@keyframes autoFillEnd {}
.k-autofill:-webkit-autofill {
  animation-name: autoFillStart;
}

.k-autofill:not(:-webkit-autofill) {
  animation-name: autoFillEnd;
}

.k-input:-webkit-autofill {
  animation-name: autoFillStart;
}

.k-input:not(:-webkit-autofill) {
  animation-name: autoFillEnd;
}

.k-textbox {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.k-input-solid {
  border-color: #eeeeee;
  color: #333333;
  background-color: #eeeeee;
}
.k-input-solid:hover, .k-input-solid.k-hover {
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-input-solid:focus, .k-input-solid.k-focus {
  border-color: #338ba1;
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: #f8f8f8;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-input-solid:focus-within {
  border-color: #338ba1;
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: #f8f8f8;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-input-solid.k-invalid, .k-input-solid.ng-invalid.ng-touched, .k-input-solid.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}
.k-input-solid.k-invalid .k-input-validation-icon, .k-input-solid.ng-invalid.ng-touched .k-input-validation-icon, .k-input-solid.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #dc3545);
}
.k-input-solid.k-invalid:focus, .k-input-solid.k-invalid.k-focus, .k-input-solid.ng-invalid.ng-touched:focus, .k-input-solid.ng-invalid.ng-touched.k-focus, .k-input-solid.ng-invalid.ng-dirty:focus, .k-input-solid.ng-invalid.ng-dirty.k-focus {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-input-solid.k-invalid:focus-within, .k-input-solid.ng-invalid.ng-touched:focus-within, .k-input-solid.ng-invalid.ng-dirty:focus-within {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-input-solid .k-input-prefix {
  color: var(--kendo-color-subtle, #596169);
}
.k-input-solid .k-input-suffix {
  color: var(--kendo-color-subtle, #596169);
}
.k-input-solid .k-input-separator {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-picker-solid {
  border-color: #eeeeee;
  color: #1a1a1a;
  background-color: #eeeeee;
}
.k-picker-solid:hover, .k-picker-solid.k-hover {
  border-color: #c2c2c2;
  background-color: #e2e2e2;
}
.k-picker-solid:focus, .k-picker-solid.k-focus {
  border-color: var(--kendo-color-base-active, #bdc4cb);
  background-color: #eeeeee;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-picker-solid:focus-within {
  border-color: var(--kendo-color-base-active, #bdc4cb);
  background-color: #eeeeee;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-picker-solid.k-invalid, .k-picker-solid.ng-invalid.ng-touched, .k-picker-solid.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}
.k-picker-solid.k-invalid .k-input-validation-icon, .k-picker-solid.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-solid.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #dc3545);
}
.k-picker-solid.k-invalid:focus, .k-picker-solid.k-invalid.k-focus, .k-picker-solid.ng-invalid.ng-touched:focus, .k-picker-solid.ng-invalid.ng-touched.k-focus, .k-picker-solid.ng-invalid.ng-dirty:focus, .k-picker-solid.ng-invalid.ng-dirty.k-focus {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-picker-solid.k-invalid:focus-within, .k-picker-solid.ng-invalid.ng-touched:focus-within, .k-picker-solid.ng-invalid.ng-dirty:focus-within {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}

.k-input-outline {
  border-color: #c2c2c2;
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: transparent;
}
.k-input-outline > .k-input-button {
  border-color: inherit;
}
.k-input-outline > .k-input-spinner {
  border-color: inherit;
}
.k-input-outline > .k-input-spinner > .k-spinner-increase, .k-input-outline > .k-input-spinner > .k-spinner-decrease {
  border-color: inherit;
}
.k-input-outline:hover, .k-input-outline.k-hover {
  border-color: #888888;
  background-color: transparent;
}
.k-input-outline:focus, .k-input-outline.k-focus {
  border-color: #338ba1;
  background-color: transparent;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-input-outline:focus-within {
  border-color: #338ba1;
  background-color: transparent;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-input-outline.k-invalid, .k-input-outline.ng-invalid.ng-touched, .k-input-outline.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}
.k-input-outline.k-invalid .k-input-validation-icon, .k-input-outline.ng-invalid.ng-touched .k-input-validation-icon, .k-input-outline.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #dc3545);
}
.k-input-outline.k-invalid:focus, .k-input-outline.k-invalid.k-focus, .k-input-outline.ng-invalid.ng-touched:focus, .k-input-outline.ng-invalid.ng-touched.k-focus, .k-input-outline.ng-invalid.ng-dirty:focus, .k-input-outline.ng-invalid.ng-dirty.k-focus {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-input-outline.k-invalid:focus-within, .k-input-outline.ng-invalid.ng-touched:focus-within, .k-input-outline.ng-invalid.ng-dirty:focus-within {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-input-outline .k-input-prefix {
  color: var(--kendo-color-subtle, #596169);
}
.k-input-outline .k-input-suffix {
  color: var(--kendo-color-subtle, #596169);
}
.k-input-outline .k-input-separator {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-picker-outline {
  border-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 50%, transparent);
  color: #1a1a1a;
}
.k-picker-outline:hover, .k-picker-outline.k-hover {
  border-color: #1a1a1a;
  color: var(--kendo-color-base-subtle, #e9ecef);
  background-color: #1a1a1a;
}
.k-picker-outline:focus, .k-picker-outline.k-focus {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-picker-outline:focus-within {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-picker-outline.k-invalid, .k-picker-outline.ng-invalid.ng-touched, .k-picker-outline.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}
.k-picker-outline.k-invalid .k-input-validation-icon, .k-picker-outline.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-outline.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #dc3545);
}
.k-picker-outline.k-invalid:focus, .k-picker-outline.k-invalid.k-focus, .k-picker-outline.ng-invalid.ng-touched:focus, .k-picker-outline.ng-invalid.ng-touched.k-focus, .k-picker-outline.ng-invalid.ng-dirty:focus, .k-picker-outline.ng-invalid.ng-dirty.k-focus {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-picker-outline.k-invalid:focus-within, .k-picker-outline.ng-invalid.ng-touched:focus-within, .k-picker-outline.ng-invalid.ng-dirty:focus-within {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}

.k-input-flat {
  border-color: #eeeeee;
  color: #333333;
}
.k-input-flat:hover, .k-input-flat.k-hover {
  border-color: #f5f5f5;
}
.k-input-flat:focus, .k-input-flat.k-focus {
  border-color: #338ba1;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-input-flat:focus-within {
  border-color: #338ba1;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-input-flat.k-invalid, .k-input-flat.ng-invalid.ng-touched, .k-input-flat.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}
.k-input-flat.k-invalid .k-input-validation-icon, .k-input-flat.ng-invalid.ng-touched .k-input-validation-icon, .k-input-flat.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #dc3545);
}
.k-input-flat.k-invalid:focus, .k-input-flat.k-invalid.k-focus, .k-input-flat.ng-invalid.ng-touched:focus, .k-input-flat.ng-invalid.ng-touched.k-focus, .k-input-flat.ng-invalid.ng-dirty:focus, .k-input-flat.ng-invalid.ng-dirty.k-focus {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-input-flat.k-invalid:focus-within, .k-input-flat.ng-invalid.ng-touched:focus-within, .k-input-flat.ng-invalid.ng-dirty:focus-within {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-input-flat .k-input-prefix {
  color: var(--kendo-color-subtle, #596169);
}
.k-input-flat .k-input-suffix {
  color: var(--kendo-color-subtle, #596169);
}
.k-input-flat .k-input-separator {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-picker-flat {
  border-color: #eeeeee;
  color: #1a1a1a;
}
.k-picker-flat:hover, .k-picker-flat.k-hover {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 8%, transparent);
}
.k-picker-flat:focus, .k-picker-flat.k-focus {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-picker-flat:focus-within {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-picker-flat.k-invalid, .k-picker-flat.ng-invalid.ng-touched, .k-picker-flat.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}
.k-picker-flat.k-invalid .k-input-validation-icon, .k-picker-flat.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-flat.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #dc3545);
}
.k-picker-flat.k-invalid:focus, .k-picker-flat.k-invalid.k-focus, .k-picker-flat.ng-invalid.ng-touched:focus, .k-picker-flat.ng-invalid.ng-touched.k-focus, .k-picker-flat.ng-invalid.ng-dirty:focus, .k-picker-flat.ng-invalid.ng-dirty.k-focus {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}
.k-picker-flat.k-invalid:focus-within, .k-picker-flat.ng-invalid.ng-touched:focus-within, .k-picker-flat.ng-invalid.ng-dirty:focus-within {
  box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--kendo-color-error, #dc3545) 25%, transparent);
}

.k-floating-label-container {
  padding-top: calc(1.4285714286 * var(--kendo-font-size, inherit));
  box-sizing: border-box;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  flex-direction: column;
  justify-content: stretch;
}
.k-floating-label-container *,
.k-floating-label-container *::before,
.k-floating-label-container *::after {
  box-sizing: border-box;
}
.k-floating-label-container > .k-floating-label {
  max-width: 90%;
  font-size: var(--kendo-font-size, inherit);
  line-height: 1.4285714286;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: var(--kendo-floating-label-offset-y, calc(calc(1.4285714286 * var(--kendo-font-size, inherit)) + 1px + 0.375rem));
  left: var(--kendo-floating-label-offset-x, calc(0.75rem + 1px));
  overflow: hidden;
  cursor: text;
  transform-origin: left center;
  transition: transform 0.2s ease-out, color 0.2s ease-out, top 0.2s ease-out, left 0.2s ease-out;
}
.k-floating-label-container.k-empty > .k-floating-label {
  top: var(--kendo-floating-label-offset-y, calc(calc(1.4285714286 * var(--kendo-font-size, inherit)) + 1px + 0.375rem));
  left: var(--kendo-floating-label-offset-x, calc(0.75rem + 1px));
  transform: scale(1);
  pointer-events: none;
}
.k-floating-label-container > .k-floating-label, .k-floating-label-container.k-focus > .k-floating-label {
  top: var(--kendo-floating-label-focus-offset-y, 0);
  left: var(--kendo-floating-label-focus-offset-x, 0);
  transform: scale(1);
}
.k-floating-label-container:focus-within > .k-floating-label {
  top: var(--kendo-floating-label-focus-offset-y, 0);
  left: var(--kendo-floating-label-focus-offset-x, 0);
  transform: scale(1);
}
.k-floating-label-container.k-empty:not(.k-focus):not(:focus-within) ::placeholder {
  color: transparent;
}
[dir=rtl] .k-floating-label-container > .k-floating-label, .k-rtl .k-floating-label-container > .k-floating-label, .k-floating-label-container[dir=rtl] > .k-floating-label {
  transform-origin: right center;
  transition: transform 0.2s ease-out, color 0.2s ease-out, top 0.2s ease-out, right 0.2s ease-out;
}
[dir=rtl] .k-floating-label-container.k-empty > .k-floating-label, .k-rtl .k-floating-label-container.k-empty > .k-floating-label, .k-floating-label-container[dir=rtl].k-empty > .k-floating-label {
  left: auto;
  right: var(--kendo-floating-label-offset-x, calc(0.75rem + 1px));
}
[dir=rtl] .k-floating-label-container > .k-floating-label, [dir=rtl] .k-floating-label-container.k-focus > .k-floating-label, .k-rtl .k-floating-label-container > .k-floating-label, .k-rtl .k-floating-label-container.k-focus > .k-floating-label, .k-floating-label-container[dir=rtl] > .k-floating-label, .k-floating-label-container[dir=rtl].k-focus > .k-floating-label {
  left: auto;
  right: var(--kendo-floating-label-focus-offset-x, 0);
}
[dir=rtl] .k-floating-label-container:focus-within > .k-floating-label, .k-rtl .k-floating-label-container:focus-within > .k-floating-label, .k-floating-label-container[dir=rtl]:focus-within > .k-floating-label {
  left: auto;
  right: var(--kendo-floating-label-focus-offset-x, 0);
}

.k-floating-label-container.k-invalid > .k-floating-label, .k-floating-label-container.ng-invalid.ng-touched > .k-floating-label, .k-floating-label-container.ng-invalid.ng-dirty > .k-floating-label {
  color: var(--kendo-color-error, #dc3545);
}

.k-tooltip {
  border-radius: 2px;
  margin: 0;
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  background-repeat: repeat-x;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  position: absolute;
  z-index: 12000;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-tooltip *,
.k-tooltip *::before,
.k-tooltip *::after {
  box-sizing: border-box;
}

.k-tooltip-icon {
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
  flex-shrink: 0;
}

.k-tooltip-title {
  margin-bottom: 0.25em;
  font-size: calc(var(--kendo-font-size-sm, 0.765625rem) * 1.25);
  line-height: var(--kendo-line-height-xs, normal);
}

.k-tooltip-content {
  align-self: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-tooltip-button {
  margin-inline-start: var(--kendo-spacing-2, 0.5rem);
  flex-shrink: 0;
}
.k-tooltip-button .k-icon {
  color: inherit;
  vertical-align: top;
}

.k-callout {
  width: calc(0.4rem * 2);
  height: calc(0.4rem * 2);
  border-width: 0.4rem;
  border-style: solid;
  border-color: transparent;
  position: absolute;
  pointer-events: none;
}

.k-callout-n {
  margin-left: calc(0.4rem * -1);
  border-bottom-color: currentColor;
  top: calc(0.4rem * -1 * 2);
  left: 50%;
  pointer-events: none;
}

.k-callout-e {
  margin-top: calc(0.4rem * -1);
  border-left-color: currentColor;
  top: 50%;
  right: calc(0.4rem * -1 * 2);
  pointer-events: none;
}

.k-callout-s {
  margin-left: calc(0.4rem * -1);
  border-top-color: currentColor;
  bottom: calc(0.4rem * -1 * 2);
  left: 50%;
  pointer-events: none;
}

.k-callout-w {
  margin-top: calc(0.4rem * -1);
  border-right-color: currentColor;
  top: 50%;
  left: calc(0.4rem * -1 * 2);
  pointer-events: none;
}

.k-tooltip {
  border-color: var(--kendo-color-on-app-surface, #212529);
  color: var(--kendo-color-app-surface, #ffffff);
  background-color: var(--kendo-color-on-app-surface, #212529);
  box-shadow: var(--kendo-elevation-2, 0px 2px 7px rgba(0, 0, 0, 0.075));
}
.k-tooltip .k-callout {
  color: var(--kendo-color-on-app-surface, #212529);
}

.k-tooltip-inverse {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-tooltip-inverse .k-callout {
  color: var(--kendo-color-dark, #212529);
}

.k-tooltip-light {
  border-color: var(--kendo-color-light, #f8f9fa);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}
.k-tooltip-light .k-callout {
  color: var(--kendo-color-light, #f8f9fa);
}

.k-tooltip-dark {
  border-color: var(--kendo-color-dark, #212529);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}
.k-tooltip-dark .k-callout {
  color: var(--kendo-color-dark, #212529);
}

.k-tooltip-error {
  border-color: var(--kendo-color-error, #dc3545);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #dc3545);
}
.k-tooltip-error .k-callout {
  color: var(--kendo-color-error, #dc3545);
}

.k-tooltip-warning {
  border-color: var(--kendo-color-warning, #ffc107);
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}
.k-tooltip-warning .k-callout {
  color: var(--kendo-color-warning, #ffc107);
}

.k-tooltip-success {
  border-color: var(--kendo-color-success, #198754);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #198754);
}
.k-tooltip-success .k-callout {
  color: var(--kendo-color-success, #198754);
}

.k-tooltip-info {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info, #0dcaf0);
}
.k-tooltip-info .k-callout {
  color: var(--kendo-color-info, #0dcaf0);
}

.k-tooltip-tertiary {
  border-color: var(--kendo-color-tertiary, #6f42c1);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #6f42c1);
}
.k-tooltip-tertiary .k-callout {
  color: var(--kendo-color-tertiary, #6f42c1);
}

.k-tooltip-secondary {
  border-color: var(--kendo-color-secondary, #6c757d);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #6c757d);
}
.k-tooltip-secondary .k-callout {
  color: var(--kendo-color-secondary, #6c757d);
}

.k-tooltip-primary {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-tooltip-primary .k-callout {
  color: var(--kendo-color-primary, #0d6efd);
}

.k-validator-tooltip {
  margin-top: calc(0.4rem + 0px);
  width: auto;
  white-space: normal;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.k-grid .k-validator-tooltip {
  max-width: 300px;
}

.k-calendar {
  inline-size: var(--INTERNAL--kendo-calendar-width, min-content);
  block-size: var(--INTERNAL--kendo-calendar-height, min-content);
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  position: relative;
  overflow: hidden;
  display: inline-flex;
  flex-flow: column nowrap;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-calendar > .k-calendar {
  border: 0;
}

.k-calendar-table {
  margin-block: 0;
  margin-inline: auto;
  border-width: 0;
  border-color: inherit;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  outline: 0;
  display: table;
  position: relative;
  z-index: 1;
}

.k-calendar-caption,
.k-calendar-caption.k-calendar-th,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
  height: 32px;
  box-sizing: border-box;
  text-transform: none;
  text-align: start;
  font-weight: bold;
  cursor: default;
}

.k-calendar-th,
.k-calendar-td {
  border-width: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  border-color: inherit;
  font-weight: normal;
  cursor: default;
}

.k-calendar-th {
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 20px);
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}

.k-calendar-td {
  border-radius: 50%;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
  border-color: transparent;
}

.k-calendar-td.k-alt {
  font-size: 0.765625rem;
}

.k-calendar .k-calendar-cell-inner, .k-calendar .k-link {
  border-radius: 50%;
  padding-inline: var(--INTERNAL--kendo-calendar-cell-padding-x, var(--kendo-spacing-1, 0.25rem));
  padding-block: var(--INTERNAL--kendo-calendar-cell-padding-y, var(--kendo-spacing-1, 0.25rem));
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: normal;
  position: relative;
  overflow: hidden;
}

.k-calendar .k-header {
  padding-block: 0.5rem;
  padding-inline: 1.5rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.25rem;
  align-items: center;
  position: relative;
  z-index: 2;
}

.k-calendar-header {
  padding-block: 0.5rem;
  padding-inline: 1.5rem;
  min-width: 256px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
.k-calendar-header .k-calendar-nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.25rem;
}

.k-calendar-footer,
.k-calendar .k-footer {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  text-align: center;
  clear: both;
}

.k-calendar-view {
  margin: auto;
  padding-block: 0;
  padding-inline: 1.5rem;
  width: 256px;
  inline-size: var(--INTERNAL--kendo-calendar-view-width, 256px);
  min-height: 224px;
  box-sizing: content-box;
  gap: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.k-calendar-view .k-today {
  font-weight: bold;
}

.k-month-calendar .k-calendar-td,
.k-calendar-monthview .k-calendar-td {
  width: 32px;
  height: 32px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
}

.k-year-calendar .k-calendar-td,
.k-calendar-yearview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-decade-calendar .k-calendar-td,
.k-calendar-decadeview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-century-calendar .k-calendar-td,
.k-calendar-centuryview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}
.k-century-calendar .k-calendar-cell-inner, .k-century-calendar .k-link,
.k-calendar-centuryview .k-calendar-cell-inner,
.k-calendar-centuryview .k-link {
  text-align: start;
}

.k-popup .k-calendar {
  height: 100%;
}

.k-calendar-container,
.k-datetime-container {
  padding: 0;
}
.k-calendar-container .k-calendar,
.k-datetime-container .k-calendar {
  border-width: 0;
}

.k-calendar .k-content.k-scrollable,
.k-calendar .k-calendar-content.k-scrollable {
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  padding-right: 100px;
  padding-left: 100px;
  margin-left: -100px;
  margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}

.k-calendar .k-scrollable-placeholder {
  position: absolute;
  z-index: -1;
  width: 1px;
  top: 0;
  right: 0;
}

.k-calendar-sm {
  --INTERNAL--kendo-calendar-view-width: 272px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-sm-cell-padding-x, var(--kendo-spacing-0\.5, 0.125rem) );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-sm-cell-padding-y, var(--kendo-spacing-0\.5, 0.125rem) );
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-calendar-sm.k-month-calendar,
.k-calendar-sm .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-month-cell-size, 34px );
}
.k-calendar-sm.k-year-calendar,
.k-calendar-sm .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-year-cell-size, 68px );
}
.k-calendar-sm.k-decade-calendar,
.k-calendar-sm .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-decade-cell-size, 68px );
}
.k-calendar-sm.k-century-calendar,
.k-calendar-sm .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-century-cell-size, 68px );
}

.k-calendar-md {
  --INTERNAL--kendo-calendar-view-width: 304px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-md-cell-padding-x, var(--kendo-spacing-1, 0.25rem) );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-md-cell-padding-y, var(--kendo-spacing-1, 0.25rem) );
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-calendar-md.k-month-calendar,
.k-calendar-md .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-month-cell-size, 38px );
}
.k-calendar-md.k-year-calendar,
.k-calendar-md .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-year-cell-size, 76px );
}
.k-calendar-md.k-decade-calendar,
.k-calendar-md .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-decade-cell-size, 76px );
}
.k-calendar-md.k-century-calendar,
.k-calendar-md .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-century-cell-size, 76px );
}

.k-calendar-lg {
  --INTERNAL--kendo-calendar-view-width: 336px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-lg-cell-padding-x, var(--kendo-spacing-1, 0.25rem) );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-lg-cell-padding-y, var(--kendo-spacing-1, 0.25rem) );
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-calendar-lg.k-month-calendar,
.k-calendar-lg .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-month-cell-size, 42px );
}
.k-calendar-lg.k-year-calendar,
.k-calendar-lg .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-year-cell-size, 84px );
}
.k-calendar-lg.k-decade-calendar,
.k-calendar-lg .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-decade-cell-size, 84px );
}
.k-calendar-lg.k-century-calendar,
.k-calendar-lg .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-century-cell-size, 84px );
}

.k-calendar-infinite {
  box-sizing: content-box;
  display: inline-flex;
  flex-flow: row nowrap;
}
.k-calendar-infinite .k-calendar-view {
  padding-block: var(--kendo-spacing-0, 0px);
  padding-inline: var(--kendo-spacing-4, 1rem);
  height: 288px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  overflow: hidden;
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  position: relative;
}
.k-calendar-infinite .k-calendar-view::after {
  display: block;
  position: absolute;
  bottom: 0;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 150%;
  left: -25%;
  box-shadow: 0 0 32px 16px #ffffff;
}
.k-calendar-infinite .k-calendar-header {
  margin-left: calc(var(--kendo-spacing-4, 1rem) * -1);
  margin-right: calc(var(--kendo-spacing-4, 1rem) * -1);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  width: auto;
  min-width: 0;
}
.k-calendar-infinite .k-calendar-weekdays {
  flex: 0 0 auto;
}

.k-calendar-navigation {
  width: 5em;
  text-align: center;
  flex: 0 0 auto;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.k-calendar-navigation::before, .k-calendar-navigation::after {
  display: block;
  position: absolute;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 200%;
  left: -50%;
  box-shadow: 0 0 6em 3em var(--kendo-color-surface, #f8f9fa);
}
.k-calendar-navigation::before {
  top: 0;
}
.k-calendar-navigation::after {
  bottom: 0;
}
.k-calendar-navigation .k-content,
.k-calendar-navigation .k-calendar-content {
  background: transparent;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.k-calendar-navigation .k-content ul,
.k-calendar-navigation .k-calendar-content ul {
  width: 5em;
}
.k-calendar-navigation .k-content li,
.k-calendar-navigation .k-calendar-content li {
  height: 2em;
  line-height: 2em;
  cursor: pointer;
  padding-block: 0;
  padding-inline: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}
.k-calendar-navigation .k-calendar-navigation-marker {
  font-weight: bold;
}
.k-calendar-navigation .k-calendar-navigation-highlight {
  width: 100%;
  border-width: 1px 0;
  border-style: solid;
  height: 2em;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-calendar-range {
  width: auto;
}
.k-calendar-range .k-calendar-view {
  width: auto;
  white-space: nowrap;
}
.k-calendar-range .k-calendar-view::after {
  display: none;
}
.k-calendar-range .k-calendar-view:focus {
  outline: 0;
}

.k-range-start {
  border-radius: 50% 0 0 50%;
}
.k-range-start .k-calendar-cell-inner,
.k-range-start .k-link {
  border-color: inherit;
  border-radius: inherit;
}

.k-range-end {
  border-radius: 0 50% 50% 0;
}
.k-range-end .k-calendar-cell-inner,
.k-range-end .k-link {
  border-color: inherit;
  border-radius: inherit;
}

.k-range-mid {
  border-color: inherit;
  border-radius: 0;
}

.k-range-start.k-range-end {
  border-radius: 50%;
}

.k-rtl .k-calendar .k-content.k-scrollable,
.k-rtl .k-calendar .k-calendar-content.k-scrollable,
[dir=rtl] .k-calendar .k-content.k-scrollable,
[dir=rtl] .k-calendar .k-calendar-content.k-scrollable,
.k-calendar.k-rtl .k-content.k-scrollable,
.k-calendar.k-rtl .k-calendar-content.k-scrollable,
.k-calendar[dir=rtl] .k-content.k-scrollable,
.k-calendar[dir=rtl] .k-calendar-content.k-scrollable {
  padding-right: 100px;
  padding-left: 100px;
  margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
  margin-right: -100px;
}
.k-rtl .k-calendar .k-range-start,
[dir=rtl] .k-calendar .k-range-start,
.k-calendar.k-rtl .k-range-start,
.k-calendar[dir=rtl] .k-range-start {
  border-radius: 0 50% 50% 0;
}
.k-rtl .k-calendar .k-range-end,
[dir=rtl] .k-calendar .k-range-end,
.k-calendar.k-rtl .k-range-end,
.k-calendar[dir=rtl] .k-range-end {
  border-radius: 50% 0 0 50%;
}

.k-calendar {
  border-color: transparent;
  color: #333333;
  background-color: #ffffff;
}
.k-calendar .k-header {
  border-color: inherit;
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface, #f8f9fa);
  box-shadow: none;
}
.k-calendar .k-calendar-th,
.k-calendar .k-calendar-caption,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  color: #999999;
}
.k-calendar .k-calendar-view .k-today .k-calendar-cell-inner, .k-calendar .k-calendar-view .k-today .k-link {
  box-shadow: inset 0 0 0 1px #338ba1;
}
.k-calendar .k-calendar-view .k-today.k-focus .k-calendar-cell-inner, .k-calendar .k-calendar-view .k-today.k-focus .k-link {
  box-shadow: inset 0 0 0 1px #338ba1, inset 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-calendar .k-other-month {
  color: #999999;
}
.k-calendar .k-alt {
  background-color: transparent;
  color: #999999;
}
.k-calendar .k-calendar-td:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #eeeeee;
}
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: #ffffff;
  background-color: #338ba1;
}
.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: #005d75;
}
.k-calendar .k-calendar-td:focus .k-calendar-cell-inner, .k-calendar .k-calendar-td:focus .k-link,
.k-calendar .k-calendar-td.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-focus .k-link {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-calendar .k-calendar-td.k-selected:focus .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:focus .k-link,
.k-calendar .k-calendar-td.k-selected.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-focus .k-link {
  box-shadow: none;
}
.k-calendar .k-calendar-navigation {
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface, #f8f9fa);
  box-shadow: inset -1px 0 var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}
.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-hover {
  color: var(--kendo-color-primary-hover, #0c64e4);
}
.k-calendar .k-calendar-navigation-highlight {
  border-color: transparent;
  color: #333333;
  background-color: #ffffff;
}
.k-calendar[dir=rtl] .k-calendar-navigation, .k-rtl .k-calendar .k-calendar-navigation {
  box-shadow: inset 1px 0 var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}
.k-calendar.k-invalid, .k-calendar.k-invalid:hover, .k-calendar.ng-invalid.ng-touched, .k-calendar.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}

.k-scheduler .k-calendar .k-other-month {
  color: #999999;
  background-color: transparent;
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}
.k-calendar .k-range-start .k-calendar-cell-inner, .k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
  background-color: #338ba1;
}
.k-calendar .k-range-start.k-active .k-calendar-cell-inner, .k-calendar .k-range-start.k-active .k-link,
.k-calendar .k-range-end.k-active .k-calendar-cell-inner,
.k-calendar .k-range-end.k-active .k-link {
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.k-calendar .k-range-split-start,
.k-calendar .k-range-split-end {
  position: relative;
}
.k-calendar .k-range-split-start::after,
.k-calendar .k-range-split-end::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
}
.k-calendar .k-range-split-start::after {
  left: -5px;
  right: auto;
  background-image: linear-gradient(to left, color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent), transparent 100%);
}
.k-calendar .k-range-split-end::after {
  right: -5px;
  left: auto;
  background-image: linear-gradient(to right, color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent), transparent 100%);
}

.k-timeselector {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-popup > .k-timeselector {
  border-width: 0;
}

.k-time-header,
.k-time-selector-header {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
.k-time-header .k-title,
.k-time-header .k-time-selector-header-title,
.k-time-selector-header .k-title,
.k-time-selector-header .k-time-selector-header-title {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  font-weight: bold;
  display: inline-block;
}
.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  border-width: 0;
  line-height: inherit;
  cursor: pointer;
}

.k-actions.k-time-fast-selection, .k-time-fast-selection.k-form-buttons, .k-time-fast-selection.k-edit-buttons,
.k-time-fast-selection.k-action-buttons,
.k-time-fast-selection.k-columnmenu-actions {
  margin-top: 0;
}

.k-time-list-container {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
}

.k-time-highlight,
.k-time-list-highlight {
  width: 100%;
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
  border-width: 1px 0px;
  border-style: solid;
  box-sizing: border-box;
  position: absolute;
  top: calc(50% + calc(var(--kendo-font-size-sm, inherit) * var(--kendo-line-height, normal))/2);
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.k-time-list-wrapper {
  min-width: 4em;
  height: 240px;
  box-sizing: content-box;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  text-align: center;
  flex: 1 1 auto;
}
.k-time-list-wrapper .k-title {
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: var(--kendo-line-height, normal);
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  display: block;
}
.k-time-list-wrapper.k-focus::before, .k-time-list-wrapper.k-focus::after {
  display: block;
  content: "​";
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
  height: calc(50% - calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2)/2);
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}
.k-time-list-wrapper.k-focus::before {
  top: calc(var(--kendo-font-size-sm, inherit) * var(--kendo-line-height, normal));
}
.k-time-list-wrapper.k-focus::after {
  bottom: 0;
}

.k-time-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  flex: 1;
  position: relative;
  z-index: 1;
  outline: 0;
  overflow: hidden;
}
.k-time-list::before, .k-time-list::after {
  display: block;
  position: absolute;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 200%;
  left: -50%;
}
.k-time-list::before {
  top: 0;
}
.k-time-list::after {
  bottom: 0;
}

.k-time-container,
.k-time-list-content {
  position: relative;
  flex: 1 1 auto;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 100px;
  padding-left: 100px;
  margin-left: -100px;
  margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}
.k-time-container > ul,
.k-time-list-content > ul {
  height: auto;
  width: 4em;
  margin: auto;
}
.k-time-container .k-scrollable-placeholder,
.k-time-list-content .k-scrollable-placeholder {
  position: absolute;
  width: 1px;
  top: 0;
  right: 0;
}

.k-time-list-item,
.k-time-list .k-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-time-separator {
  width: 0;
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
  align-self: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 11;
  top: calc(calc(var(--kendo-font-size-sm, inherit) * var(--kendo-line-height, normal))/2);
}

.k-timeselector-sm {
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: 1.4285714286;
}
.k-timeselector-sm .k-time-highlight,
.k-timeselector-sm .k-time-list-highlight {
  height: calc(var(--kendo-font-size-sm, inherit) * 1.4285714286 + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-sm .k-time-separator {
  height: calc(var(--kendo-font-size-sm, inherit) * 1.4285714286 + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-sm .k-time-list-item,
.k-timeselector-sm .k-time-list .k-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-timeselector-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-timeselector-md .k-time-highlight,
.k-timeselector-md .k-time-list-highlight {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-md .k-time-separator {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-md .k-time-list-item,
.k-timeselector-md .k-time-list .k-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-timeselector-lg {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-timeselector-lg .k-time-highlight,
.k-timeselector-lg .k-time-list-highlight {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-timeselector-lg .k-time-separator {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-timeselector-lg .k-time-list-item,
.k-timeselector-lg .k-time-list .k-item {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-rtl .k-time-container,
.k-rtl .k-time-list-content,
[dir=rtl] .k-time-container,
[dir=rtl] .k-time-list-content {
  padding-right: 100px;
  padding-left: 100px;
  margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
  margin-right: -100px;
}

.k-timeselector {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  color: var(--kendo-color-primary, #0d6efd);
}
.k-time-header .k-time-now:hover,
.k-time-selector-header .k-time-now:hover {
  color: var(--kendo-color-primary-hover, #0c64e4);
}

.k-time-list-wrapper .k-title {
  color: #999999;
}
.k-time-list-wrapper.k-focus .k-title {
  color: #333333;
}
.k-time-list-wrapper.k-focus::before, .k-time-list-wrapper.k-focus::after {
  background-color: rgba(0, 0, 0, 0.02);
}

.k-time-list::before, .k-time-list::after {
  box-shadow: 0 0 3em 1.5em var(--kendo-color-surface-alt, #ffffff);
}
.k-time-list .k-item:hover {
  color: var(--kendo-color-primary, #0d6efd);
}

.k-time-container {
  background: transparent;
}

.k-time-highlight {
  border-color: #e6e6e6;
  background-color: #ffffff;
}

.k-datetime-selector {
  display: flex;
  transition: transform 0.2s;
}

.k-datetime-wrap {
  width: calc(32px * 8 + var(--kendo-spacing-4, 1rem) * 2);
  overflow: hidden;
}
.k-datetime-wrap .k-datetime-buttongroup {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-datetime-wrap .k-calendar,
.k-datetime-wrap .k-timeselector {
  width: 100%;
  border-width: 0;
}

.k-datetime-calendar-wrap,
.k-datetime-time-wrap {
  text-align: center;
  flex: 0 0 calc(32px * 8 + var(--kendo-spacing-4, 1rem) * 2);
}

.k-date-tab .k-datetime-selector {
  transform: translateX(0);
}

.k-time-tab .k-datetime-selector {
  transform: translateX(-100%);
}

.k-time-tab .k-datetime-selector.k-rtl, .k-time-tab .k-datetime-selector[dir=rtl], .k-rtl .k-time-tab .k-datetime-selector, [dir=rtl] .k-time-tab .k-datetime-selector {
  transform: translateX(100%);
}

.k-dropdown-operator {
  width: min-content;
  flex: none;
}
.k-dropdown-operator .k-input-button {
  border-width: 0;
  width: min-content;
}
.k-dropdown-operator .k-input-button .k-button-icon {
  width: auto;
  aspect-ratio: 1;
}
.k-dropdown-operator .k-input-inner {
  display: none;
}

.k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}

.k-overlay {
  background-color: #000000;
}

.k-overlay-primary {
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-overlay-secondary {
  background-color: var(--kendo-color-secondary, #6c757d);
}

.k-overlay-tertiary {
  background-color: var(--kendo-color-tertiary, #6f42c1);
}

.k-overlay-info {
  background-color: var(--kendo-color-info, #0dcaf0);
}

.k-overlay-success {
  background-color: var(--kendo-color-success, #198754);
}

.k-overlay-warning {
  background-color: var(--kendo-color-warning, #ffc107);
}

.k-overlay-error {
  background-color: var(--kendo-color-error, #dc3545);
}

.k-overlay-dark {
  background-color: var(--kendo-color-dark, #212529);
}

.k-overlay-light {
  background-color: var(--kendo-color-light, #f8f9fa);
}

.k-overlay-inverse {
  background-color: var(--kendo-color-dark, #212529);
}

.k-form,
.k-form-inline {
  font-size: var(--kendo-font-size, inherit);
  line-height: 1.4285714286;
}

.k-fieldset {
  margin: var(--kendo-spacing-7\.5, 1.875rem);
  border-width: 1px 0 0;
  border-style: solid;
  padding: 25px 0 0;
}
.k-fieldset > legend {
  margin-left: 0;
  padding: 0 0.5rem 0 0;
  text-transform: uppercase;
}

kendo-label > .k-label {
  margin: 0;
}

.k-label-optional {
  margin-left: var(--kendo-spacing-1\.5, 0.375rem);
  font-size: var(--kendo-font-size-xs, inherit);
  font-style: italic;
  align-self: center;
}

.k-label-empty::before {
  content: "";
  display: inline-block;
}

.k-form {
  border: 0;
}
.k-form .k-form-field > .k-label,
.k-form .k-form-field > kendo-label,
.k-form .k-form-label {
  margin-bottom: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex-flow: row nowrap;
}
.k-form .k-label + .k-radio {
  margin-left: 0;
}
.k-form .k-form-fieldset {
  margin: 2rem 0 0;
  padding: var(--kendo-spacing-0, 0px);
  border: 0;
}
.k-form .k-form-fieldset > *:not(.k-hidden):first-child, .k-form .k-form-fieldset > *.k-hidden + :not(.k-hidden) {
  margin-top: 0;
}
.k-form .k-form-legend {
  margin: 0 0 1rem;
  padding: var(--kendo-spacing-0, 0px);
  border-width: 0 0 2px;
  border-style: solid;
  width: 100%;
  font-size: var(--kendo-font-size-sm, inherit);
  text-transform: uppercase;
}
.k-form .k-form-group {
  padding: 0;
}
.k-form > *:not(.k-hidden):first-child, .k-form > *.k-hidden + :not(.k-hidden) {
  margin-top: 0;
}
.k-form .k-form-field,
.k-form .k-form-field-wrap {
  display: block;
}
.k-form .k-form-field-disabled .k-label,
.k-form .k-form-field-disabled .k-form-label {
  outline: none;
  cursor: default;
  opacity: 0.65;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
.k-form .k-alert-error {
  font-size: var(--kendo-font-size-sm, inherit);
  margin-top: calc(var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-form .k-field-info {
  display: inline-block;
  font-size: var(--kendo-font-size-xs, inherit);
  line-height: 1;
  margin-block: 0;
  margin-inline: 0.5rem;
}
.k-form .k-multiselect,
.k-form .k-floating-label-container,
.k-form .k-daterangepicker .k-dateinput,
.k-form .k-signature {
  display: inline-flex;
  width: 100%;
}

.k-form-buttons {
  padding: 0;
  overflow: visible;
}

.k-form-hint,
.k-form-error {
  margin-top: var(--kendo-spacing-1, 0.25rem);
  display: flex;
  font-size: var(--kendo-font-size-xs, inherit);
  font-style: normal;
}

.k-text-start,
.k-buttons-start {
  justify-content: flex-start;
}

.k-text-end,
.k-buttons-end {
  justify-content: flex-end;
}

.k-form-separator {
  margin: var(--kendo-spacing-4, 1rem) 0 0;
  border-width: 1px 0 0;
  border-style: solid;
  display: block;
  flex: 0 0 auto;
}

.k-form-horizontal .k-form-field {
  display: flex;
}
.k-form-horizontal .k-form-field > .k-label,
.k-form-horizontal .k-form-field > kendo-label,
.k-form-horizontal .k-form-field > .k-form-label {
  margin-right: var(--kendo-spacing-2\.5, 0.625rem);
  padding-top: calc(var(--kendo-spacing-2\.5, 0.625rem) / 2);
  width: 25%;
  text-align: end;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.k-form-horizontal .k-form-field .k-label-optional {
  margin: 0;
  align-self: inherit;
}
.k-form-horizontal .k-form-field-wrap {
  max-width: calc(100% - 25% - var(--kendo-spacing-2\.5, 0.625rem));
  flex: 1 1 auto;
}

.k-form [dir=rtl] .k-label-optional, .k-rtl .k-form .k-label-optional {
  margin-left: 0;
  margin-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-form-horizontal [dir=rtl].k-form-field > .k-label,
.k-form-horizontal [dir=rtl].k-form-field > kendo-label,
.k-form-horizontal [dir=rtl].k-form-field > .k-form-label,
.k-form-horizontal [dir=rtl] .k-form-field > .k-label,
.k-form-horizontal [dir=rtl] .k-form-field > kendo-label,
.k-form-horizontal [dir=rtl] .k-form-field > .k-form-label, .k-rtl .k-form-horizontal.k-form-field > .k-label,
.k-rtl .k-form-horizontal.k-form-field > kendo-label,
.k-rtl .k-form-horizontal.k-form-field > .k-form-label,
.k-rtl .k-form-horizontal .k-form-field > .k-label,
.k-rtl .k-form-horizontal .k-form-field > kendo-label,
.k-rtl .k-form-horizontal .k-form-field > .k-form-label {
  margin-right: 0;
  margin-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-form-sm .k-form-field,
.k-form-sm .k-form-buttons {
  margin-top: var(--kendo-spacing-4, 1rem);
}

.k-form-md .k-form-field,
.k-form-md .k-form-buttons {
  margin-top: var(--kendo-spacing-4, 1rem);
}

.k-form-lg .k-form-field,
.k-form-lg .k-form-buttons {
  margin-top: var(--kendo-spacing-4, 1rem);
}

.k-form-inline {
  padding: calc(0.5rem * 2);
}
.k-form-inline fieldset {
  border-width: 1px 0 0;
  border-style: solid;
  margin: calc(calc(0.5rem * 2) * 2) 0;
  padding: 0;
}
.k-form-inline fieldset:first-child:first-of-type {
  margin-top: 0;
}
.k-form-inline fieldset:last-child:last-of-type {
  margin-bottom: 0;
}
.k-form-inline legend {
  font-size: var(--kendo-font-size-sm, inherit);
  text-align: start;
  font-weight: 600;
  line-height: 1;
  margin-bottom: calc(var(--kendo-spacing-3, 0.75rem) * 2);
  text-transform: uppercase;
  padding: 0 0.5rem 0 0;
  width: auto;
}
.k-form-inline .k-form-field {
  display: flex;
  align-items: flex-start;
  text-align: start;
  margin-bottom: calc(var(--kendo-spacing-3, 0.75rem) * 2);
}
.k-form-inline .k-form-field > span:not(.k-widget) {
  width: 25%;
  text-align: end;
  line-height: var(--kendo-line-height, normal);
  padding: calc(var(--kendo-spacing-2, 0.5rem) + 1px) 0;
  padding-right: var(--kendo-spacing-6, 1.5rem);
  align-self: center;
}
.k-form-inline .k-form-field > input {
  align-self: center;
}
.k-form-inline .k-form-field > input:not(.k-checkbox):not(.k-radio) {
  flex: 1 1 auto;
}
.k-form-inline .k-form-field .k-alert-error {
  font-size: var(--kendo-font-size-sm, inherit);
  margin-top: calc(var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-form-inline .k-form-field .k-field-info {
  display: block;
  font-size: var(--kendo-font-size-xs, inherit);
  line-height: 1;
  margin: 0;
}
.k-form-inline .k-form-field:last-child {
  margin-bottom: 0;
}

.k-edit-form-container {
  width: 400px;
  min-width: 400px;
  border-color: inherit;
  position: relative;
}

.k-popup-edit-form > .k-actions, .k-popup-edit-form > .k-edit-buttons,
.k-popup-edit-form > .k-action-buttons,
.k-popup-edit-form > .k-columnmenu-actions, .k-popup-edit-form > .k-form-buttons,
.k-edit-form-container .k-actions,
.k-edit-form-container .k-edit-buttons,
.k-edit-form-container .k-action-buttons,
.k-edit-form-container .k-columnmenu-actions,
.k-edit-form-container .k-form-buttons {
  margin: var(--kendo-spacing-4, 1rem) -var(--kendo-spacing-4, 1rem) -var(--kendo-spacing-4, 1rem);
}

.k-edit-label {
  margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
  padding: calc(0.375rem + 1px) 0;
  width: 30%;
  line-height: 1.4285714286;
  text-align: end;
  float: left;
  clear: both;
}

.k-edit-field {
  margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
  width: 65%;
  float: right;
  clear: right;
  position: relative;
}
.k-edit-field.k-no-editor {
  padding: calc(0.375rem + 1px) 0;
}

.k-edit-field > .k-widget {
  width: 100%;
  box-sizing: border-box;
}
.k-edit-field input[type=radio]:not(.k-radio),
.k-edit-field input[type=checkbox]:not(.k-checkbox) {
  margin-right: 0.4ex;
}
.k-edit-field .k-radio-label,
.k-edit-field .k-checkbox-label {
  margin-right: var(--kendo-spacing-4, 1rem);
}
.k-edit-field > .k-reset > li + li {
  margin-top: var(--kendo-spacing-2, 0.5rem);
}
.k-edit-field .k-reset .k-widget {
  margin: 0 0.4ex 0 1ex;
}

.k-form legend,
.k-form-inline legend {
  font-size: calc(var(--kendo-font-size, 0.875rem) * 1.5);
  font-weight: normal;
  line-height: var(--kendo-line-height, normal);
  text-transform: none;
}
.k-form .k-field-info,
.k-form-inline .k-field-info {
  opacity: 0.7;
}

.k-form legend {
  margin-bottom: var(--kendo-spacing-2, 0.5rem);
}
.k-form .k-field-info {
  margin-block: 0;
  margin-inline: calc(0.5rem / 2);
}

.k-form-inline legend {
  margin-bottom: 0;
}
.k-form-inline .k-form-field > span:not(.k-widget) {
  padding: calc(var(--kendo-spacing-2, 0.5rem) - 1px) 0;
  padding-right: 0.5rem;
}

.k-edit-label {
  padding: calc(var(--kendo-spacing-2, 0.5rem) - 1px) 0;
}

.k-form,
.k-form-inline {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-form .k-form-legend,
.k-form-inline .k-form-legend {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}
.k-form .k-field-info,
.k-form-inline .k-field-info {
  color: var(--kendo-color-subtle, #596169);
}
.k-form .k-alert-error,
.k-form-inline .k-alert-error {
  color: var(--kendo-color-error, #dc3545);
}

.k-form-error,
.k-text-error,
.k-form-field-error .k-label {
  color: var(--kendo-color-error, #dc3545);
}

.k-form-separator {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-window {
  border-radius: var(--kendo-border-radius-md, 3px);
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  z-index: 10002;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-window.k-window-maximized {
  max-width: 100vw;
  max-height: 100vh;
  box-shadow: none;
}

.k-window-sm {
  width: 300px;
}

.k-window-md {
  width: 800px;
}

.k-window-lg {
  width: 1200px;
}

.k-window-titlebar {
  border-top-left-radius: var(--kendo-border-radius-md, 3px);
  border-top-right-radius: var(--kendo-border-radius-md, 3px);
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  border-width: 0 0 1px;
  border-style: solid;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
}
.k-window-minimized .k-window-titlebar {
  border-width: 0;
}

.k-window-title {
  padding-block: 0.5em;
  padding-inline: 0;
  margin-block: -0.5em;
  margin-inline: 0;
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height, normal);
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
}
.k-window-title::before {
  content: "​";
}

.k-window-titlebar-actions {
  margin-block: -5em;
  margin-inline: 0;
  margin-inline-end: calc(var(--kendo-spacing-4, 1rem) - var(--kendo-spacing-4, 1rem));
  line-height: 1;
  display: flex;
  gap: var(--kendo-spacing-2, 0.5rem);
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  vertical-align: top;
}

.k-window-titlebar-action {
  flex-shrink: 0;
  opacity: 0.5;
}

.k-window-titlebar-action:hover {
  opacity: 0.75;
}

.k-window-content,
.k-prompt-container {
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.k-window-content + .k-prompt-container {
  margin-top: -var(--kendo-spacing-4, 1rem)/2;
}

.k-window-content:first-child {
  padding-top: clamp(var(--kendo-spacing-4, 1rem), var(--kendo-spacing-4, 1rem), var(--kendo-spacing-4, 1rem));
}

.k-window-content:last-child {
  padding-bottom: clamp(var(--kendo-spacing-4, 1rem), var(--kendo-spacing-4, 1rem), var(--kendo-spacing-4, 1rem));
}

.k-window-iframecontent {
  padding: 0;
  overflow: visible;
}
.k-window-iframecontent .k-content-frame {
  vertical-align: top;
  border: 0;
  width: 100%;
  height: 100%;
}

.k-window-actions {
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px);
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  overflow: hidden;
}

.k-prompt-container > .k-textarea {
  width: 100%;
}

.k-window .k-resize-n {
  top: 0;
}
.k-window .k-resize-e {
  right: 0;
}
.k-window .k-resize-s {
  bottom: 0;
}
.k-window .k-resize-w {
  left: 0;
}

.k-window {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-window-titlebar {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-window-primary .k-window-titlebar {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-window-light .k-window-titlebar {
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}

.k-window-dark .k-window-titlebar {
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}

.k-dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
}
.k-dialog-wrapper .k-dialog {
  position: relative;
}

.k-dialog {
  padding: 0;
  position: fixed;
  box-sizing: border-box;
}
.k-dialog.k-dialog-centered {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.k-dialog .k-multiselect {
  width: 100%;
}

.k-dialog-actions {
  border-bottom-right-radius: var(--kendo-border-radius-md, 3px);
  border-bottom-left-radius: var(--kendo-border-radius-md, 3px);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  overflow: hidden;
}

.k-dialog-primary .k-dialog-titlebar {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-dialog-light .k-dialog-titlebar {
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #f8f9fa);
}

.k-dialog-dark .k-dialog-titlebar {
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #212529);
}

.k-pager {
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: default;
  flex: 0 0 auto;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pager *,
.k-pager *::before,
.k-pager *::after {
  box-sizing: border-box;
}

.k-pager-info, .k-pager-sizes, .k-pager-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.k-pager-nav {
  color: inherit;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.k-pager-nav:hover {
  z-index: 2;
}
.k-disabled.k-pager-nav {
  color: inherit;
}

.k-pager-numbers-wrap {
  display: flex;
  flex-direction: row;
  position: relative;
}
.k-pager-numbers-wrap select.k-dropdown-list, .k-pager-numbers-wrap select.k-dropdown,
.k-pager-numbers-wrap select.k-dropdownlist {
  width: 5em;
}

.k-pager-numbers {
  display: flex;
  flex-direction: row;
}
.k-pager-numbers .k-selected {
  cursor: inherit;
  z-index: 2;
}

.k-pager-nav + .k-pager-nav,
.k-pager-nav + .k-pager-numbers-wrap,
.k-pager-numbers-wrap + .k-pager-nav {
  margin-left: -1px;
}
.k-rtl .k-pager-nav + .k-pager-nav, [dir=rtl] .k-pager-nav + .k-pager-nav,
.k-rtl .k-pager-nav + .k-pager-numbers-wrap,
[dir=rtl] .k-pager-nav + .k-pager-numbers-wrap,
.k-rtl .k-pager-numbers-wrap + .k-pager-nav,
[dir=rtl] .k-pager-numbers-wrap + .k-pager-nav {
  margin-left: 0;
  margin-right: -1px;
}

.k-pager-input {
  gap: 1ex;
}
.k-pager-input .k-textbox,
.k-pager-input .k-numerictextbox {
  margin-block: 0;
  width: 5em;
}

.k-pager-sizes {
  gap: 1ex;
}
.k-pager-sizes .k-input-inner,
.k-pager-sizes .k-input-value-text {
  text-overflow: clip;
}

.k-pager-info {
  text-align: end;
  justify-content: flex-end;
  flex: 1 1 0%;
  order: 9;
}

.k-pager-refresh {
  margin-inline-start: auto;
  color: inherit;
  order: 10;
}

.k-pager-sm {
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  padding-block: var(--kendo-spacing-1, 0.25rem);
  gap: var(--kendo-spacing-3\.5, 0.875rem);
}
.k-pager-sm .k-pager-numbers-wrap .k-button {
  min-width: calc(1.4285714286 * 1em + 0.25rem * 2 + 0);
}
.k-pager-sm .k-pager-numbers-wrap .k-pager-input,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdownlist {
  margin-inline-start: var(--kendo-spacing-3\.5, 0.875rem);
  margin-inline-end: var(--kendo-spacing-3\.5, 0.875rem);
}
.k-pager-sm .k-pager-sizes .k-dropdown-list, .k-pager-sm .k-pager-sizes .k-dropdown,
.k-pager-sm .k-pager-sizes .k-dropdownlist,
.k-pager-sm .k-pager-sizes > select {
  width: 5em;
}

.k-pager-md {
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  gap: var(--kendo-spacing-4, 1rem);
}
.k-pager-md .k-pager-numbers-wrap .k-button {
  min-width: calc(var(--kendo-line-height, normal) * 1em + 0.375rem * 2 + 0);
}
.k-pager-md .k-pager-numbers-wrap .k-pager-input,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdownlist {
  margin-inline-start: var(--kendo-spacing-4, 1rem);
  margin-inline-end: var(--kendo-spacing-4, 1rem);
}
.k-pager-md .k-pager-sizes .k-dropdown-list, .k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist,
.k-pager-md .k-pager-sizes > select {
  width: 5em;
}

.k-pager-lg {
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  gap: var(--kendo-spacing-4\.5, 1.125rem);
}
.k-pager-lg .k-pager-numbers-wrap .k-button {
  min-width: calc(var(--kendo-line-height, normal) * 1em + 0.625rem * 2 + 0);
}
.k-pager-lg .k-pager-numbers-wrap .k-pager-input,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdownlist {
  margin-inline-start: var(--kendo-spacing-4\.5, 1.125rem);
  margin-inline-end: var(--kendo-spacing-4\.5, 1.125rem);
}
.k-pager-lg .k-pager-sizes .k-dropdown-list, .k-pager-lg .k-pager-sizes .k-dropdown,
.k-pager-lg .k-pager-sizes .k-dropdownlist,
.k-pager-lg .k-pager-sizes > select {
  width: 6em;
}

.k-pager-nav {
  border-radius: 0;
}

.k-pager-first {
  border-radius: var(--kendo-spacing-1, 0.25rem) 0 0 var(--kendo-spacing-1, 0.25rem);
}

.k-pager-last {
  border-radius: 0 var(--kendo-spacing-1, 0.25rem) var(--kendo-spacing-1, 0.25rem) 0;
}

.k-rtl .k-pager-first,
[dir=rtl] .k-pager-first {
  border-radius: 0 var(--kendo-spacing-1, 0.25rem) var(--kendo-spacing-1, 0.25rem) 0;
}
.k-rtl .k-pager-last,
[dir=rtl] .k-pager-last {
  border-radius: var(--kendo-spacing-1, 0.25rem) 0 0 var(--kendo-spacing-1, 0.25rem);
}

.k-pager {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface, #f8f9fa);
}
.k-pager:focus, .k-pager.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}

.k-chip {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1.25;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}
.k-chip *,
.k-chip *::before,
.k-chip *::after {
  box-sizing: border-box;
}
.k-chip .k-selected-icon-wrapper {
  display: none !important;
}

.k-chip-content {
  padding-block: 0.5em;
  padding-inline: 0;
  margin-block: -0.5em;
  margin-inline: 0;
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  flex: 1 1 auto;
}
.k-chip-content .k-icon-wrapper-host {
  display: initial;
}

.k-chip-content:first-child {
  margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-content:last-child {
  margin-inline-end: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-text,
.k-chip-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
}

.k-chip-avatar {
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  flex: none;
}

.k-chip-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.k-chip-actions {
  margin-block: -5em;
  margin-inline: 0;
  flex: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: center;
}

.k-chip-action {
  flex: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: center;
}

.k-remove-icon {
  font-size: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.k-chip-list {
  min-width: 0px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.k-chip-sm {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: 1.25;
}
.k-chip-sm .k-chip-action {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
}

.k-chip-avatar {
  width: 1em;
  height: 1em;
  flex-basis: 1em;
}

.k-chip-md {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1.25;
}
.k-chip-md .k-chip-action {
  padding: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-avatar {
  width: 1em;
  height: 1em;
  flex-basis: 1em;
}

.k-chip-lg {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1.25;
}
.k-chip-lg .k-chip-action {
  padding: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-chip-avatar {
  width: 1em;
  height: 1em;
  flex-basis: 1em;
}

.k-chip-list-sm {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-list-md {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-list-lg {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-solid-base {
  border-color: #eeeeee;
  color: #1a1a1a;
  background-color: #eeeeee;
}
.k-chip-solid-base:focus, .k-chip-solid-base.k-focus {
  box-shadow: 0 0 0 2px rgba(238, 238, 238, 0.16);
}
.k-chip-solid-base:hover, .k-chip-solid-base.k-hover {
  background-color: #e2e2e2;
}
.k-chip-solid-base.k-selected {
  background-color: #c2c2c2;
}

.k-chip-solid-error {
  border-color: var(--kendo-color-error-emphasis, rgba(220, 53, 69, 0.4));
  color: var(--kendo-color-error-on-subtle, #58151c);
  background-color: var(--kendo-color-error-subtle, #f8d7da);
}
.k-chip-solid-error:focus, .k-chip-solid-error.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-error, #dc3545) 16%, transparent);
}
.k-chip-solid-error:hover, .k-chip-solid-error.k-hover {
  background-color: var(--kendo-color-error-subtle-hover, #f5c2c7);
}
.k-chip-solid-error.k-selected {
  background-color: var(--kendo-color-error-subtle-active, #f1aeb5);
}

.k-chip-solid-info {
  border-color: var(--kendo-color-info-emphasis, rgba(13, 202, 240, 0.4));
  color: var(--kendo-color-info-on-subtle, #055160);
  background-color: var(--kendo-color-info-subtle, #cff4fc);
}
.k-chip-solid-info:focus, .k-chip-solid-info.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-info, #0dcaf0) 16%, transparent);
}
.k-chip-solid-info:hover, .k-chip-solid-info.k-hover {
  background-color: var(--kendo-color-info-subtle-hover, #b7eefa);
}
.k-chip-solid-info.k-selected {
  background-color: var(--kendo-color-info-subtle-active, #9eeaf9);
}

.k-chip-solid-warning {
  border-color: var(--kendo-color-warning-emphasis, rgba(255, 193, 7, 0.4));
  color: var(--kendo-color-warning-on-subtle, #664d03);
  background-color: var(--kendo-color-warning-subtle, #fff3cd);
}
.k-chip-solid-warning:focus, .k-chip-solid-warning.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-warning, #ffc107) 16%, transparent);
}
.k-chip-solid-warning:hover, .k-chip-solid-warning.k-hover {
  background-color: var(--kendo-color-warning-subtle-hover, #ffecb5);
}
.k-chip-solid-warning.k-selected {
  background-color: var(--kendo-color-warning-subtle-active, #ffe69c);
}

.k-chip-solid-success {
  border-color: var(--kendo-color-success-emphasis, rgba(25, 135, 84, 0.4));
  color: var(--kendo-color-success-on-subtle, #0A3622);
  background-color: var(--kendo-color-success-subtle, #d1e7dd);
}
.k-chip-solid-success:focus, .k-chip-solid-success.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-success, #198754) 16%, transparent);
}
.k-chip-solid-success:hover, .k-chip-solid-success.k-hover {
  background-color: var(--kendo-color-success-subtle-hover, #badbcc);
}
.k-chip-solid-success.k-selected {
  background-color: var(--kendo-color-success-subtle-active, #a3cfbb);
}

.k-chip-outline-base {
  border-color: var(--kendo-color-base-on-surface, #212529);
  color: var(--kendo-color-base-on-surface, #212529);
  background-color: transparent;
}
.k-chip-outline-base:focus, .k-chip-outline-base.k-focus {
  box-shadow: 0 0 0 2px rgba(238, 238, 238, 0.16);
}
.k-chip-outline-base:hover, .k-chip-outline-base.k-hover {
  color: var(--kendo-color-base-subtle, #e9ecef);
  background-color: var(--kendo-color-base-on-surface, #212529);
}
.k-chip-outline-base.k-selected {
  color: var(--kendo-color-base-subtle, #e9ecef);
  background-color: var(--kendo-color-base-on-surface, #212529);
}

.k-chip-outline-error {
  border-color: var(--kendo-color-error-on-surface, #dc3545);
  color: var(--kendo-color-error-on-surface, #dc3545);
  background-color: transparent;
}
.k-chip-outline-error:focus, .k-chip-outline-error.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-error, #dc3545) 16%, transparent);
}
.k-chip-outline-error:hover, .k-chip-outline-error.k-hover {
  border-color: var(--kendo-color-error-hover, #b02a37);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error-hover, #b02a37);
}
.k-chip-outline-error.k-selected {
  border-color: var(--kendo-color-error-active, #9a2530);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error-active, #9a2530);
}

.k-chip-outline-info {
  border-color: var(--kendo-color-info-on-surface, #0dcaf0);
  color: var(--kendo-color-info-on-surface, #0dcaf0);
  background-color: transparent;
}
.k-chip-outline-info:focus, .k-chip-outline-info.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-info, #0dcaf0) 16%, transparent);
}
.k-chip-outline-info:hover, .k-chip-outline-info.k-hover {
  border-color: var(--kendo-color-info-hover, #3dd5f3);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info-hover, #3dd5f3);
}
.k-chip-outline-info.k-selected {
  border-color: var(--kendo-color-info-active, #6edff6);
  color: var(--kendo-color-on-info, #000000);
  background-color: var(--kendo-color-info-active, #6edff6);
}

.k-chip-outline-warning {
  border-color: var(--kendo-color-warning, #ffc107);
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: transparent;
}
.k-chip-outline-warning:focus, .k-chip-outline-warning.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-warning, #ffc107) 16%, transparent);
}
.k-chip-outline-warning:hover, .k-chip-outline-warning.k-hover {
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}
.k-chip-outline-warning.k-selected {
  color: var(--kendo-color-on-warning, #000000);
  background-color: var(--kendo-color-warning, #ffc107);
}

.k-chip-outline-success {
  border-color: var(--kendo-color-success-on-surface, #198754);
  color: var(--kendo-color-success-on-surface, #198754);
  background-color: transparent;
}
.k-chip-outline-success:focus, .k-chip-outline-success.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-success, #198754) 16%, transparent);
}
.k-chip-outline-success:hover, .k-chip-outline-success.k-hover {
  border-color: var(--kendo-color-success-hover, #146C43);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success-hover, #146C43);
}
.k-chip-outline-success.k-selected {
  border-color: var(--kendo-color-success-active, #0F5132);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success-active, #0F5132);
}

.k-chip-outline-info {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-chip-outline-info:hover, .k-chip-outline-info.k-hover, .k-chip-outline-info.k-selected {
  border-color: var(--kendo-color-info, #0dcaf0);
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-info, #0dcaf0);
}

.k-skeleton, .k-placeholder-line {
  display: block;
}

.k-skeleton-text, .k-placeholder-line {
  border-radius: 2px;
  transform: scale(1, 0.6);
}
.k-skeleton-text:empty::before, .k-placeholder-line:empty::before {
  content: "​";
}

.k-skeleton-rect {
  border-radius: 2px;
}

.k-skeleton-circle {
  border-radius: 9999px;
}

@keyframes k-skeleton-wave {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.k-skeleton-wave .k-skeleton, .k-skeleton-wave .k-placeholder-line, .k-skeleton-wave.k-skeleton, .k-skeleton-wave.k-placeholder-line {
  position: relative;
  overflow: hidden;
}
.k-skeleton-wave .k-skeleton::after, .k-skeleton-wave .k-placeholder-line::after, .k-skeleton-wave.k-skeleton::after, .k-skeleton-wave.k-placeholder-line::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  animation: k-skeleton-wave 1.6s linear 0.5s infinite;
}

@keyframes k-skeleton-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.k-skeleton-pulse .k-skeleton, .k-skeleton-pulse .k-placeholder-line, .k-skeleton-pulse.k-skeleton, .k-skeleton-pulse.k-placeholder-line {
  animation: k-skeleton-pulse 1.5s ease-in-out 0.5s infinite;
}

.k-skeleton, .k-placeholder-line {
  background-color: #f5f5f5;
}

.k-skeleton-wave .k-skeleton::after, .k-skeleton-wave .k-placeholder-line::after, .k-skeleton-wave.k-skeleton::after, .k-skeleton-wave.k-placeholder-line::after {
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.04), transparent);
}

.k-table {
  width: 100%;
  max-width: none;
  border-width: 1px;
  border-style: solid;
  text-align: start;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  outline: none;
}

.k-data-table {
  border-width: 1px;
  border-style: solid;
}
.k-data-table .k-table {
  table-layout: fixed;
}

.k-table-thead,
.k-table-tbody,
.k-table-tfoot,
.k-table-row,
.k-table-alt-row {
  border-color: inherit;
  text-align: inherit;
}

.k-table-th,
.k-table-td {
  border-width: 0 0 0px 1px;
  border-style: solid;
  border-color: inherit;
  box-sizing: border-box;
  font-weight: normal;
  text-align: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: table-cell;
  overflow: hidden;
  position: relative;
}
.k-table-th:first-child,
.k-table-td:first-child {
  border-left-width: 0;
}

.k-table-th {
  border-bottom-width: 1px;
}

.k-table-header {
  padding-inline-end: var(--kendo-scrollbar-width);
  border-width: 0 0 1px;
  border-style: solid;
  box-sizing: border-box;
}
.k-table-header .k-table {
  border-width: 0;
}

.k-table-header-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: inherit;
  overflow: hidden;
}

.k-table-header > .k-table,
.k-table-header-wrap > .k-table {
  margin-bottom: -1px;
}

.k-table-group-sticky-header {
  flex: none;
}
.k-table-group-sticky-header .k-table-th {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
}

.k-table-list {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;
  border-width: 0;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  empty-cells: show;
  list-style: none;
  outline: none;
}
.k-table-list .k-table-row {
  position: relative;
}
.k-table-list .k-table-row,
.k-table-list .k-table-group-row {
  width: 100%;
  box-sizing: border-box;
  display: table-row;
}
.k-table-list .k-table-row.k-first {
  border-top: 1px solid currentColor;
}
.k-table-list .k-table-th,
.k-table-list .k-table-td {
  vertical-align: middle;
}
.k-table-list .k-table-group-row {
  position: sticky;
}
.k-table-list .k-table-group-row::before {
  content: "​";
  padding-inline: 0;
  width: 0;
  display: block;
  overflow: hidden;
}
.k-table-list .k-table-group-row .k-table-th {
  width: 100%;
  border-color: inherit;
  color: inherit;
  background-color: inherit;
  position: absolute;
  top: 0;
}
.k-table-list .k-table-spacer-td {
  padding: 0 !important;
  width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.k-table-list .k-table-group-td {
  padding: 0 !important;
  width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  overflow: visible;
}
.k-table-list .k-table-group-td > span {
  font-size: 0.75em;
  position: absolute;
  top: 0;
  right: 0;
}

.k-virtual-table .k-table-row,
.k-virtual-table .k-table-group-row {
  position: absolute;
  width: 100%;
}

.k-table-scroller {
  position: relative;
  overflow: auto;
}
.k-table-scroller > .k-table {
  border-width: 0;
}

.k-table-footer {
  padding-inline-end: var(--kendo-scrollbar-width);
  border-width: 1px 0 0;
  border-style: solid;
  box-sizing: border-box;
}
.k-table-footer .k-table {
  border-width: 0;
}

.k-table-footer-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: inherit;
  overflow: hidden;
}

.k-table-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-table-sm .k-table-th,
.k-table-sm .k-table-td {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-table-sm .k-table-list .k-table-group-td > span {
  padding-block: 0;
  padding-inline: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}

.k-table-sm .k-table-list .k-table-group-row::before {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: 0;
}

.k-table-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-table-md .k-table-th,
.k-table-md .k-table-td {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}

.k-table-md .k-table-list .k-table-group-td > span {
  padding-block: 0;
  padding-inline: calc(0.5rem / 2);
}

.k-table-md .k-table-list .k-table-group-row::before {
  padding-block: 0.5rem;
  padding-inline: 0;
}

.k-table-lg {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-table-lg .k-table-th,
.k-table-lg .k-table-td {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-table-lg .k-table-list .k-table-group-td > span {
  padding-block: 0;
  padding-inline: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}

.k-table-lg .k-table-list .k-table-group-row::before {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  padding-inline: 0;
}

.k-rtl.k-table .k-table-th,
.k-rtl.k-table .k-table-td,
.k-rtl .k-table .k-table-th,
.k-rtl .k-table .k-table-td,
[dir=rtl].k-table .k-table-th,
[dir=rtl].k-table .k-table-td,
[dir=rtl] .k-table .k-table-th,
[dir=rtl] .k-table .k-table-td {
  border-left-width: 0;
  border-right-width: 1px;
}
.k-rtl.k-table .k-table-th:first-child,
.k-rtl.k-table .k-table-td:first-child,
.k-rtl .k-table .k-table-th:first-child,
.k-rtl .k-table .k-table-td:first-child,
[dir=rtl].k-table .k-table-th:first-child,
[dir=rtl].k-table .k-table-td:first-child,
[dir=rtl] .k-table .k-table-th:first-child,
[dir=rtl] .k-table .k-table-td:first-child {
  border-right-width: 0;
}
.k-rtl.k-table .k-table-header-wrap,
.k-rtl.k-table .k-table-footer-wrap,
.k-rtl .k-table .k-table-header-wrap,
.k-rtl .k-table .k-table-footer-wrap,
[dir=rtl].k-table .k-table-header-wrap,
[dir=rtl].k-table .k-table-footer-wrap,
[dir=rtl] .k-table .k-table-header-wrap,
[dir=rtl] .k-table .k-table-footer-wrap {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
  border-right-width: 0;
}
.k-rtl .k-table-list .k-table-group-td > span,
[dir=rtl] .k-table-list .k-table-group-td > span {
  left: 0;
  right: auto;
}

.k-table,
.k-data-table {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #f5f5f5;
}

.k-table-tfoot,
.k-table-footer {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #f8f8f8;
}

.k-table-group-row {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #f5f5f5;
}

.k-table-group-td > span {
  color: var(--kendo-color-surface-alt, #ffffff);
  background-color: #333333;
}

.k-table-alt-row {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 5%, transparent);
}

.k-table-tbody .k-table-row:hover,
.k-table-list .k-table-row:hover,
.k-table-tbody .k-table-row.k-hover,
.k-table-list .k-table-row.k-hover {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 9%, transparent);
}

.k-table-tbody .k-table-row.k-focus, .k-table-tbody .k-table-row:focus,
.k-table-list .k-table-row.k-focus,
.k-table-list .k-table-row:focus,
.k-table-tbody .k-table-td.k-focus,
.k-table-tbody .k-table-td:focus,
.k-table-list .k-table-td.k-focus,
.k-table-list .k-table-td:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}

.k-table-tbody .k-table-row.k-selected > .k-table-td,
.k-table-list .k-table-row.k-selected {
  color: #333333;
  background-color: color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}

.k-tabstrip-wrapper {
  padding-block: var(--kendo-spacing-0, 0px);
  padding-inline: var(--kendo-spacing-0, 0px);
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  position: relative;
}

.k-tabstrip {
  border-width: 0;
  border-color: transparent;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  color: inherit;
  background-color: transparent;
  display: flex;
  flex-flow: column nowrap;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-tabstrip-wrapper > .k-tabstrip {
  flex: 1 1 auto;
}

.k-tabstrip-items-wrapper {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  position: relative;
}

.k-tabstrip-items {
  box-sizing: border-box;
  outline: 0;
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
  flex: 1 1 auto;
  gap: var(--kendo-spacing-0, 0px);
}
.k-tabstrip-items *,
.k-tabstrip-items *::before,
.k-tabstrip-items *::after {
  box-sizing: border-box;
}
.k-tabstrip-items .k-item {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: stretch;
  outline: 0;
}
.k-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.k-tabstrip-items .k-link {
  padding-block: 0.5rem;
  padding-inline: 1rem;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  flex: 1 1 auto;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.k-tabstrip-content,
.k-tabstrip > .k-content {
  margin: 0 !important;
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  display: none;
  overflow: auto;
  flex: 1 1 auto;
  position: relative;
}
.k-tabstrip-content.k-active,
.k-tabstrip > .k-content.k-active {
  display: block;
}
.k-tabstrip-content:focus, .k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
  outline-width: 1px;
  outline-style: dotted;
  outline-offset: -1px;
}

.k-tabstrip-items .k-loading {
  width: 20%;
  height: 0;
  border: 0;
  border-top: 1px solid transparent;
  border-color: inherit;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.2s linear;
  display: none;
}
.k-tabstrip-items .k-loading.k-complete {
  width: 100%;
  border-top-width: 0;
}

.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
}
.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  width: auto;
  height: auto;
  flex: none;
  align-self: stretch;
  aspect-ratio: auto;
}
.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button .k-button-icon {
  min-width: auto;
  min-height: auto;
}

.k-tabstrip-horizontal, .k-tabstrip-bottom > .k-tabstrip-items-wrapper, .k-tabstrip-top > .k-tabstrip-items-wrapper {
  flex-direction: row;
}

.k-tabstrip-vertical, .k-tabstrip-right > .k-tabstrip-items-wrapper, .k-tabstrip-left > .k-tabstrip-items-wrapper {
  flex-direction: column;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom-width: 1px;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  border-top-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-top-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-width: 0;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-bottom: -1px;
  border-bottom-width: 1px;
  border-bottom-color: transparent !important;
}
.k-tabstrip-top > .k-content,
.k-tabstrip-top > .k-tabstrip-content {
  border-bottom-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-top-width: 0 !important;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper {
  border-top-width: 1px;
}
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item {
  border-bottom-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-top-width: 0;
}
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-top: -1px;
  border-top-width: 1px;
  border-top-color: transparent !important;
}
.k-tabstrip-bottom > .k-content,
.k-tabstrip-bottom > .k-tabstrip-content {
  border-top-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-top-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-width: 0 !important;
}

.k-tabstrip-left {
  flex-direction: row;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper {
  border-right-width: 1px;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item {
  border-top-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-right-width: 0;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-right: -1px;
  border-right-width: 1px;
  border-right-color: transparent !important;
}
.k-tabstrip-left > .k-content,
.k-tabstrip-left > .k-tabstrip-content {
  border-top-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-left-width: 0 !important;
}

.k-tabstrip-right {
  flex-direction: row-reverse;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper {
  border-left-width: 1px;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item {
  border-top-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-right-radius: var(--kendo-spacing-1, 0.25rem);
  border-left-width: 0;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-left: -1px;
  border-left-width: 1px;
  border-left-color: transparent !important;
}
.k-tabstrip-right > .k-content,
.k-tabstrip-right > .k-tabstrip-content {
  border-top-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-bottom-left-radius: var(--kendo-spacing-1, 0.25rem);
  border-right-width: 0;
}

.k-tabstrip-items-start {
  justify-content: flex-start;
}

.k-tabstrip-items-center {
  justify-content: center;
}

.k-tabstrip-items-end {
  justify-content: flex-end;
}

.k-tabstrip-items-justify {
  justify-content: space-between;
}

.k-tabstrip-items-stretched > * {
  flex: 1 0 0;
}

.k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-items-wrapper, .k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-items-wrapper,
.k-tabstrip[dir=rtl].k-tabstrip-left > .k-tabstrip-items-wrapper,
.k-tabstrip[dir=rtl].k-tabstrip-right > .k-tabstrip-items-wrapper {
  order: 1;
}
.k-rtl .k-tabstrip.k-tabstrip-left > .k-content, .k-rtl .k-tabstrip.k-tabstrip-right > .k-content,
.k-tabstrip[dir=rtl].k-tabstrip-left > .k-content,
.k-tabstrip[dir=rtl].k-tabstrip-right > .k-content {
  order: 0;
}
.k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-content, .k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-content,
.k-tabstrip[dir=rtl].k-tabstrip-left > .k-tabstrip-content,
.k-tabstrip[dir=rtl].k-tabstrip-right > .k-tabstrip-content {
  order: 0;
}

.k-tabstrip-items-wrapper {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-tabstrip-items-wrapper .k-item {
  color: var(--kendo-color-primary, #0d6efd);
}
.k-tabstrip-items-wrapper .k-item:hover, .k-tabstrip-items-wrapper .k-item.k-hover {
  border-color: var(--kendo-color-base-subtle, #e9ecef);
  color: var(--kendo-color-primary-hover, #0c64e4);
}
.k-tabstrip-items-wrapper .k-item:active, .k-tabstrip-items-wrapper .k-item.k-active, .k-tabstrip-items-wrapper .k-item.k-selected {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-tabstrip-items-wrapper .k-item:focus, .k-tabstrip-items-wrapper .k-item.k-focus {
  box-shadow: inset 0 0 0 3px color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 15%, transparent);
}
.k-tabstrip-items-wrapper .k-item.k-tabstrip-dragging {
  box-shadow: var(--kendo-elevation-3, 0px 4px 10px rgba(0, 0, 0, 0.1));
}
.k-tabstrip-content,
.k-tabstrip > .k-content {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-tabstrip-content:focus, .k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
  outline-color: var(--kendo-color-on-app-surface, #212529);
}

.k-grid {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-grid.k-grid-display-block {
  display: block;
}
.k-grid .k-grid-container {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}
.k-grid .k-grid-aria-root {
  border-color: inherit;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}
.k-grid .k-table {
  margin: 0;
  width: 100%;
  max-width: none;
  border-width: 0;
  border-color: inherit;
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: content-box;
  empty-cells: show;
  outline: 0;
}
.k-grid .k-grid-header .k-table,
.k-grid .k-grid-content .k-table,
.k-grid .k-grid-content-locked .k-table,
.k-grid .k-grid-footer .k-table {
  table-layout: fixed;
}
.k-grid .k-table-thead,
.k-grid .k-table-tbody,
.k-grid .k-table-tfoot {
  text-align: start;
  border-color: inherit;
}
.k-grid .k-table-row {
  border-color: inherit;
}
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
  border-width: 0;
  border-inline-start-width: 0;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  position: static;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: content-box;
}
.k-grid .k-table-th:first-child,
.k-grid td:first-child,
.k-grid .k-table-td:first-child {
  border-inline-start-width: 0;
}
.k-grid .k-table-th:focus,
.k-grid td:focus,
.k-grid .k-table-td:focus {
  outline: none;
}
.k-grid .k-table-th > .k-radio,
.k-grid .k-table-th > .k-radio-wrap,
.k-grid .k-table-th > .k-checkbox,
.k-grid .k-table-th > .k-checkbox-wrap,
.k-grid td > .k-radio,
.k-grid td > .k-radio-wrap,
.k-grid td > .k-checkbox,
.k-grid td > .k-checkbox-wrap,
.k-grid .k-table-td > .k-radio,
.k-grid .k-table-td > .k-radio-wrap,
.k-grid .k-table-td > .k-checkbox,
.k-grid .k-table-td > .k-checkbox-wrap {
  vertical-align: top;
}
.k-grid .k-table-th {
  border-block-end-width: 1px;
  white-space: nowrap;
}
.k-grid td,
.k-grid .k-table-td {
  border-block-end-width: 0px;
  white-space: unset;
  vertical-align: middle;
}
.k-grid a {
  color: inherit;
  text-decoration: none;
}
.k-grid a:hover {
  text-decoration: none;
}
.k-grid .k-grid-header-wrap {
  box-sizing: content-box;
}
.k-grid .k-grid-header {
  border-bottom-width: 1px;
}
.k-grid .k-grid-header .k-table {
  margin-bottom: -1px;
}
.k-grid .k-grid-header.k-grid-draggable-header {
  user-select: none;
  touch-action: none;
}
.k-grid .k-grid-header .k-table-th {
  position: relative;
  vertical-align: bottom;
  cursor: default;
}
.k-grid .k-grid-header .k-table-th:first-child {
  border-inline-start-width: 0;
}
.k-grid .k-grid-header .k-table-th.k-first {
  border-inline-start-width: 1px;
}
.k-grid .k-grid-header .k-table-th > .k-link {
  line-height: inherit;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 0;
}
.k-grid .k-grid-header .k-table-th > .k-link:focus {
  text-decoration: none;
}
.k-grid .k-grid-header .k-grid-filter,
.k-grid .k-grid-header .k-header-column-menu,
.k-grid .k-grid-header .k-grid-header-menu {
  box-sizing: border-box;
  outline: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  inset-inline-end: var(--kendo-spacing-2, 0.5rem);
  z-index: 1;
}
.k-grid .k-grid-header .k-grid-filter:hover,
.k-grid .k-grid-header .k-header-column-menu:hover,
.k-grid .k-grid-header .k-grid-header-menu:hover {
  cursor: pointer;
}
.k-grid .k-grid-header .k-cell-inner > .k-link > .k-sort-icon {
  vertical-align: text-top;
  margin-inline-start: calc(0.5rem - 1px);
}
.k-grid .k-grid-header .k-cell-inner > .k-link > .k-sort-icon .k-icon {
  display: flex;
}
.k-grid .k-grid-header .k-sort-order {
  display: inline-block;
  vertical-align: top;
  height: 16px;
  font-size: 12px;
  margin-top: var(--kendo-spacing-0\.5, 0.125rem);
  margin-inline-start: calc(var(--kendo-spacing-0\.5, 0.125rem) * -1);
}
.k-grid .k-grid-header .k-filterable > .k-cell-inner .k-link {
  padding-inline-end: 0;
}
.k-grid .k-cell-inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: inherit;
  overflow: hidden;
}
.k-grid .k-cell-inner > .k-link {
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: inherit;
  flex: 1;
  overflow: hidden;
}
.k-grid .k-cell-inner > .k-link > .k-sort-icon {
  margin-inline-start: 0;
  display: inline-block;
  flex-shrink: 0;
}
.k-grid .k-cell-inner .k-sort-order {
  flex-shrink: 0;
  line-height: normal;
}
.k-grid .k-cell-inner .k-grid-filter,
.k-grid .k-cell-inner .k-header-column-menu,
.k-grid .k-cell-inner .k-grid-header-menu {
  position: static;
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-group-col,
.k-grid .k-hierarchy-col {
  padding: 0;
  width: 32px;
}
.k-grid .k-grouping-row p {
  margin: 0;
  display: flex;
  align-items: center;
  align-content: center;
}
.k-grid .k-grouping-row td,
.k-grid .k-grouping-row .k-table-td {
  overflow: visible;
}
.k-grid .k-grouping-row + .k-table-row td,
.k-grid .k-grouping-row + .k-table-row .k-table-td {
  border-top-width: 1px;
}
.k-grid .k-grouping-row .k-group-cell,
.k-grid .k-grouping-row + .k-table-row .k-group-cell {
  border-top-width: 0;
  text-overflow: clip;
}
.k-grid .k-grouping-row .k-icon {
  margin-inline-start: calc(8px - 0.5rem);
  margin-inline-end: 8px;
  text-decoration: none;
}
.k-grid .k-table .k-group-footer td,
.k-grid .k-table .k-group-footer .k-table-td {
  border-style: solid;
  border-width: 1px 0;
}
.k-grid .k-group-footer .k-group-cell + td,
.k-grid .k-group-footer .k-group-cell + .k-table-td {
  border-inline-start-width: 0;
}
.k-grid .k-hierarchy-cell,
.k-grid .k-drag-cell {
  text-align: center;
  overflow: visible;
}
.k-grid .k-hierarchy-cell > .k-font-icon,
.k-grid .k-drag-cell > .k-font-icon {
  padding-block: 0.5rem;
  padding-inline: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  outline: 0;
}
.k-grid .k-hierarchy-cell + .k-grid-content-sticky {
  border-inline-start-width: 0;
}
.k-grid .k-detail-row .k-detail-cell {
  border-inline-start-width: 0;
}
.k-grid .k-dirty-cell {
  position: relative;
}
.k-grid .k-dirty-cell.k-edit-cell {
  position: static;
}
.k-grid .k-dirty-cell.k-grid-content-sticky {
  position: sticky;
}
.k-grid .k-dirty {
  border-width: 5px;
  border-color: transparent;
  border-block-start-color: currentColor;
  border-inline-start-color: currentColor;
  inset-inline-start: 0;
  inset-inline-end: auto;
}
.k-grid .k-grid-content-locked + .k-grid-content {
  box-sizing: content-box;
}
.k-grid .k-grid-content-expander {
  position: absolute;
  visibility: hidden;
  height: 1px;
  bottom: 1px;
}
.k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-grid-header-menu,
.k-grid .k-hierarchy-cell .k-icon {
  border-width: 0;
}
.k-grid .k-drag-col {
  padding: 0;
  width: 48px;
}
.k-grid .k-drag-cell {
  cursor: move;
}

.k-grid-toolbar {
  border-width: 0 0 1px;
  flex-shrink: 0;
}

.k-grid-toolbar-bottom {
  border-width: 1px 0 0;
}

.k-grouping-header {
  border-width: 0 0 1px;
  border-style: solid;
  border-color: inherit;
  white-space: normal;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
.k-grouping-header::before {
  content: "";
  height: calc(1.25em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
  display: inline-block;
  vertical-align: middle;
}
.k-grouping-header .k-chip-list {
  flex-grow: 0;
  display: contents;
}
.k-grouping-header > .k-grouping-drop-container {
  flex-grow: 1;
  padding-block: 0.5rem;
  margin-block: calc(0.5rem * -1);
  display: inline-flex;
  align-self: stretch;
  align-items: center;
}

.k-grouping-dropclue {
  width: 12px;
  position: absolute;
  top: 3px;
  box-sizing: content-box;
}
.k-grouping-dropclue::before, .k-grouping-dropclue::after {
  display: inline-block;
  content: "";
  position: absolute;
}
.k-grouping-dropclue::before {
  border-width: 6px;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  top: 0;
}
.k-grouping-dropclue::after {
  width: 2px;
  height: calc(100% - 6px);
  top: 6px;
  inset-inline-start: calc(6px - 1px);
}

.k-grid-header-wrap,
.k-grid-footer-wrap {
  margin-inline-end: -0;
  width: 100%;
  border-width: 0;
  border-inline-end-width: 0;
  border-style: solid;
  border-color: inherit;
  position: relative;
  overflow: hidden;
}

.k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
  margin-inline-end: 0;
}

.k-grid-header,
.k-grid-footer {
  padding-inline-start: 0;
  padding-inline-end: var(--kendo-scrollbar-width, 17px);
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
}

div.k-grid-header,
div.k-grid-footer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.k-column-resizer {
  width: 0.5em;
  height: 1000%;
  display: block;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  cursor: col-resize;
}

.k-row-resizer {
  display: block;
  height: var(--kendo-spacing-0\.5, 0.125rem);
  width: 100%;
  background: none;
}

.k-grid-footer {
  border-top-width: 1px;
}
.k-grid-footer td,
.k-grid-footer .k-table-td {
  height: calc(1.4285714286 * 1em);
}

.k-grid-footer td,
.k-group-footer td,
.k-grouping-row td,
.k-grid-footer .k-table-td,
.k-group-footer .k-table-td,
.k-grouping-row .k-table-td {
  font-weight: bold;
}

.k-grid-filter-popup {
  min-width: 250px;
  max-width: 320px;
}

.k-grid-column-menu-standalone a.k-grid-filter {
  position: initial;
  display: inline-block;
  margin-block: -4px;
  margin-inline: 0;
  padding-block: 4px;
  padding-inline: 8px;
}

.k-filter-row {
  line-height: 1.4285714286;
}
.k-filter-row td,
.k-filter-row .k-table-td,
.k-filter-row .k-table-th {
  border-width: 0;
  border-block-end-width: 1px;
  border-inline-start-width: 0;
  white-space: nowrap;
}
.k-filter-row td:first-child,
.k-filter-row .k-table-td:first-child {
  border-inline-start-width: 0;
}
.k-filter-row .k-multiselect {
  height: auto;
}

.k-filtercell {
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.k-filtercell > span,
.k-filtercell .k-filtercell-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 auto;
}
.k-filtercell > span > .k-button,
.k-filtercell .k-filtercell-wrapper > .k-button {
  flex: none;
}

.k-grid-content,
.k-grid-content-locked {
  border-color: inherit;
  outline: 0;
}
.k-grid-content .k-table-row:last-child > td,
.k-grid-content .k-table-row:last-child > .k-table-td,
.k-grid-content-locked .k-table-row:last-child > td,
.k-grid-content-locked .k-table-row:last-child > .k-table-td {
  border-bottom-width: 0;
}

.k-grid-content {
  width: 100%;
  min-height: 0;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  position: relative;
  flex: 1;
}

.k-virtual-scrollable-wrap {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.k-grid .k-edit-cell,
.k-grid .k-command-cell,
.k-grid .k-grid-edit-row td,
.k-grid .k-grid-edit-row .k-table-td {
  text-overflow: clip;
}

.k-grid .k-edit-cell > input:not([type=checkbox]):not([type=radio]),
.k-grid .k-edit-cell > select,
.k-grid .k-edit-cell > .k-widget:not(.k-switch),
.k-grid .k-grid-edit-row td > input:not([type=checkbox]):not([type=radio]),
.k-grid .k-grid-edit-row td > select,
.k-grid .k-grid-edit-row td > .k-widget:not(.k-switch),
.k-grid .k-grid-edit-row .k-table-td > input:not([type=checkbox]):not([type=radio]),
.k-grid .k-grid-edit-row .k-table-td > select,
.k-grid .k-grid-edit-row .k-table-td > .k-widget:not(.k-switch) {
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
}
.k-grid .k-edit-cell > .k-radio,
.k-grid .k-edit-cell > .k-checkbox,
.k-grid .k-edit-cell > .k-radio-wrap,
.k-grid .k-edit-cell > .k-checkbox-wrap,
.k-grid .k-grid-edit-row td > .k-radio,
.k-grid .k-grid-edit-row td > .k-checkbox,
.k-grid .k-grid-edit-row td > .k-radio-wrap,
.k-grid .k-grid-edit-row td > .k-checkbox-wrap,
.k-grid .k-grid-edit-row .k-table-td > .k-radio,
.k-grid .k-grid-edit-row .k-table-td > .k-checkbox,
.k-grid .k-grid-edit-row .k-table-td > .k-radio-wrap,
.k-grid .k-grid-edit-row .k-table-td > .k-checkbox-wrap {
  vertical-align: middle;
}

.k-grid .k-command-cell > .k-button {
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
  vertical-align: middle;
}
.k-grid .k-command-cell > .k-button:last-child {
  margin-inline-end: unset;
}

.k-grid > .k-resize-handle,
.k-grid-header .k-resize-handle {
  height: 25px;
  cursor: col-resize;
  position: absolute;
  z-index: 2;
}

.k-selection-aggregates {
  border-width: 1px 0 0;
  border-style: solid;
  line-height: var(--kendo-line-height, normal);
  display: flex;
  justify-content: flex-end;
  gap: var(--kendo-spacing-2, 0.5rem);
}

.k-selection-aggregates-item-value {
  font-weight: var(--kendo-font-weight-bold, normal);
}

.k-grid-pager {
  border-width: 1px 0 0;
  border-color: inherit;
  font-size: inherit;
}

.k-grid-pager-top {
  border-width: 0 0 1px;
}

.k-grid-virtual .k-grid-content .k-grid-table-wrap {
  float: left;
  width: 100%;
}
.k-grid-virtual .k-grid-content .k-grid-table {
  position: relative;
  float: left;
  z-index: 1;
}
.k-grid-virtual .k-grid-content > .k-height-container {
  position: relative;
  float: left;
}
.k-grid-virtual .k-grid-content::after {
  content: "";
  display: block;
  clear: both;
}

.k-width-container {
  position: absolute;
  visibility: hidden;
}

.k-width-container div {
  height: 1px;
}

.k-grid-add-row td,
.k-grid-add-row .k-table-td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.k-grid-lockedcolumns {
  white-space: nowrap;
}

.k-grid-content,
.k-grid-content-locked {
  white-space: normal;
}

.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  box-sizing: content-box;
}
.k-grid-content-locked + .k-grid-content.k-auto-scrollable,
.k-grid-footer-locked + .k-grid-content.k-auto-scrollable,
.k-grid-header-locked + .k-grid-content.k-auto-scrollable {
  display: inline-block;
}
.k-grid-content-locked .k-table,
.k-grid-footer-locked .k-table,
.k-grid-header-locked .k-table {
  border-width: 0;
}

.k-grid-content,
.k-grid-footer-wrap,
.k-grid-header-wrap {
  flex: 1 1 auto;
  display: inline-block;
  vertical-align: top;
}
.k-grid-content.k-auto-scrollable,
.k-grid-footer-wrap.k-auto-scrollable,
.k-grid-header-wrap.k-auto-scrollable {
  display: block;
}

.k-grid-header-locked > .k-grid-header-table,
.k-grid-header-wrap > .k-grid-header-table {
  margin-bottom: -1px;
}

.k-grid-header .k-table-th.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky,
.k-grid .k-grid-content-sticky,
.k-grid .k-grid-row-sticky,
.k-grid .k-grid-footer-sticky {
  position: sticky;
  z-index: 2;
}
.k-grid-header .k-table-th.k-grid-header-sticky.k-edit-cell,
.k-grid-header .k-filter-row .k-grid-header-sticky.k-edit-cell,
.k-grid .k-grid-content-sticky.k-edit-cell,
.k-grid .k-grid-row-sticky.k-edit-cell,
.k-grid .k-grid-footer-sticky.k-edit-cell {
  overflow: visible;
  z-index: 3;
}

.k-master-row .k-grid-content-sticky::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

kendo-grid .k-table-row.k-grid-row-sticky {
  border: 0;
  position: static;
  z-index: auto;
}
kendo-grid .k-grid-row-sticky td,
kendo-grid .k-grid-row-sticky .k-table-td {
  border-bottom-width: 1px;
  border-top-width: 1px;
  position: sticky;
  top: inherit;
  bottom: inherit;
  z-index: 2;
}
kendo-grid .k-grid-row-sticky td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky.k-table-alt-row td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky .k-table-td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky.k-table-alt-row .k-table-td.k-grid-content-sticky {
  z-index: 3;
}
kendo-grid .k-grid-header-locked .k-table-th.k-grid-header-sticky:last-child,
kendo-grid .k-grid-content-locked .k-table-row .k-grid-content-sticky:last-child {
  border-inline-end: 0;
}
kendo-grid .k-grid-header .k-grid-column-menu,
kendo-grid .k-grid-header .k-grid-header-menu {
  z-index: 1;
}

.k-grid-content-sticky.k-grid-row-sticky {
  z-index: 3;
}

.k-grid .k-table .k-grid-header-sticky,
.k-grid .k-table .k-grid-content-sticky,
.k-grid .k-table .k-grid-footer-sticky {
  border-inline-end-width: 0;
}
.k-grid .k-table .k-grid-header-sticky:not([style*="display: none"]) + td, .k-grid .k-table .k-grid-header-sticky:not([style*="display: none"]) + .k-table-td, .k-grid .k-table .k-grid-header-sticky:not([style*="display: none"]) + .k-table-th,
.k-grid .k-table .k-grid-content-sticky:not([style*="display: none"]) + td,
.k-grid .k-table .k-grid-content-sticky:not([style*="display: none"]) + .k-table-td,
.k-grid .k-table .k-grid-content-sticky:not([style*="display: none"]) + .k-table-th,
.k-grid .k-table .k-grid-footer-sticky:not([style*="display: none"]) + td,
.k-grid .k-table .k-grid-footer-sticky:not([style*="display: none"]) + .k-table-td,
.k-grid .k-table .k-grid-footer-sticky:not([style*="display: none"]) + .k-table-th {
  border-inline-start-width: 0;
}

.k-grid .k-grid-row-sticky {
  border-bottom-width: 1px;
  border-top-width: 1px;
}

.k-grid-header-sticky.k-table-th.k-grid-no-left-border.k-first {
  border-inline-start-width: 0;
}

.k-grid.k-grid-no-scrollbar .k-grid-header-wrap,
.k-grid.k-grid-no-scrollbar .k-grid-footer-wrap {
  margin: 0;
  border-width: 0;
}
.k-grid.k-grid-no-scrollbar .k-grid-header,
.k-grid.k-grid-no-scrollbar .k-grid-footer {
  padding: 0;
}
.k-grid.k-grid-no-scrollbar .k-grid-content {
  overflow-y: auto;
}

.k-grid-norecords {
  text-align: center;
}

div.k-grid-norecords {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.k-grid-norecords-template {
  box-sizing: border-box;
  margin-block: 0;
  margin-inline: auto;
  width: 20em;
  height: 4em;
  border: 1px solid;
  line-height: 4em;
}

.k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
  overflow: hidden;
}

.k-grid .k-grid-sm .k-table-th,
.k-grid-sm .k-table-th {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm td,
.k-grid .k-grid-sm .k-table-td,
.k-grid-sm td,
.k-grid-sm .k-table-td {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-grouping-header,
.k-grid-sm .k-grouping-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: 0.5rem;
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-grouping-header::before,
.k-grid-sm .k-grouping-header::before {
  margin-inline-start: calc(var(--kendo-spacing-1, 0.25rem) * -1);
}
.k-grid .k-grid-sm .k-grid-header .k-table-th > .k-link,
.k-grid-sm .k-grid-header .k-table-th > .k-link {
  margin-block: calc(var(--kendo-spacing-1, 0.25rem) * -1);
  margin-inline: calc(var(--kendo-spacing-1, 0.25rem) * -1);
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-grid-header .k-grid-filter,
.k-grid .k-grid-sm .k-grid-header .k-header-column-menu,
.k-grid .k-grid-sm .k-grid-header .k-grid-header-menu,
.k-grid-sm .k-grid-header .k-grid-filter,
.k-grid-sm .k-grid-header .k-header-column-menu,
.k-grid-sm .k-grid-header .k-grid-header-menu {
  padding: 0.25rem;
  width: calc(1.4285714286 * 1em + 0.25rem * 2 + 0);
  height: calc(1.4285714286 * 1em + 0.25rem * 2 + 0);
  bottom: calc(var(--kendo-spacing-1, 0.25rem) + calc(1.4285714286 * 1em)/2 - calc(1.4285714286 * 1em + 0.375rem * 2)/2);
}
.k-grid .k-grid-sm .k-table-th > .k-cell-inner,
.k-grid-sm .k-table-th > .k-cell-inner {
  margin-block: calc(var(--kendo-spacing-1, 0.25rem) * -1);
  margin-inline: calc(var(--kendo-spacing-1, 0.25rem) * -1);
}
.k-grid .k-grid-sm .k-table-th > .k-cell-inner > .k-link,
.k-grid-sm .k-table-th > .k-cell-inner > .k-link {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-grouping-row .k-icon,
.k-grid-sm .k-grouping-row .k-icon {
  margin-inline-start: calc(8px - var(--kendo-spacing-1, 0.25rem));
}
.k-grid .k-grid-sm .k-grouping-dropclue,
.k-grid-sm .k-grouping-dropclue {
  height: calc(calc(1.25em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px) + 6px);
}
.k-grid .k-grid-sm .k-hierarchy-cell,
.k-grid .k-grid-sm .k-drag-cell,
.k-grid-sm .k-hierarchy-cell,
.k-grid-sm .k-drag-cell {
  padding: 0;
}
.k-grid .k-grid-sm .k-hierarchy-cell > .k-icon,
.k-grid .k-grid-sm .k-drag-cell > .k-icon,
.k-grid-sm .k-hierarchy-cell > .k-icon,
.k-grid-sm .k-drag-cell > .k-icon {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: 0;
}
.k-grid .k-grid-sm .k-edit-cell,
.k-grid .k-grid-sm .k-command-cell,
.k-grid .k-grid-sm .k-grid-edit-row td,
.k-grid .k-grid-sm .k-grid-edit-row .k-table-td,
.k-grid-sm .k-edit-cell,
.k-grid-sm .k-command-cell,
.k-grid-sm .k-grid-edit-row td,
.k-grid-sm .k-grid-edit-row .k-table-td {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-filter-row td,
.k-grid .k-grid-sm .k-filter-row .k-table-td,
.k-grid .k-grid-sm .k-filter-row .k-table-th,
.k-grid-sm .k-filter-row td,
.k-grid-sm .k-filter-row .k-table-td,
.k-grid-sm .k-filter-row .k-table-th {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-1\.5, 0.375rem);
}
.k-grid .k-grid-sm .k-filtercell > span,
.k-grid .k-grid-sm .k-filtercell .k-filtercell-wrapper,
.k-grid-sm .k-filtercell > span,
.k-grid-sm .k-filtercell .k-filtercell-wrapper {
  gap: calc(var(--kendo-spacing-1, 0.25rem) / 2);
}
.k-grid .k-grid-sm .k-selection-aggregates,
.k-grid-sm .k-selection-aggregates {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-resizer-wrap,
.k-grid-sm .k-resizer-wrap {
  display: block;
  width: 100%;
  padding-block-start: calc(var(--kendo-spacing-1, 0.25rem) - var(--kendo-spacing-0\.5, 0.125rem));
  padding-block-end: var(--kendo-spacing-1, 0.25rem);
  position: absolute;
  background: none;
  cursor: row-resize;
  z-index: 2;
}

.k-grid .k-grid-md .k-table-th,
.k-grid-md .k-table-th {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}
.k-grid .k-grid-md td,
.k-grid .k-grid-md .k-table-td,
.k-grid-md td,
.k-grid-md .k-table-td {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}
.k-grid .k-grid-md .k-grouping-header,
.k-grid-md .k-grouping-header {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
  gap: 0.5rem;
}
.k-grid .k-grid-md .k-grouping-header::before,
.k-grid-md .k-grouping-header::before {
  margin-inline-start: calc(0.5rem * -1);
}
.k-grid .k-grid-md .k-grid-header .k-table-th > .k-link,
.k-grid-md .k-grid-header .k-table-th > .k-link {
  margin-block: calc(0.5rem * -1);
  margin-inline: calc(0.5rem * -1);
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}
.k-grid .k-grid-md .k-grid-header .k-grid-filter,
.k-grid .k-grid-md .k-grid-header .k-header-column-menu,
.k-grid .k-grid-md .k-grid-header .k-grid-header-menu,
.k-grid-md .k-grid-header .k-grid-filter,
.k-grid-md .k-grid-header .k-header-column-menu,
.k-grid-md .k-grid-header .k-grid-header-menu {
  padding: 0.375rem;
  width: calc(var(--kendo-line-height, normal) * 1em + 0.375rem * 2 + 0);
  height: calc(var(--kendo-line-height, normal) * 1em + 0.375rem * 2 + 0);
  bottom: calc(0.5rem + calc(1.4285714286 * 1em)/2 - calc(1.4285714286 * 1em + 0.375rem * 2)/2);
}
.k-grid .k-grid-md .k-table-th > .k-cell-inner,
.k-grid-md .k-table-th > .k-cell-inner {
  margin-block: calc(0.5rem * -1);
  margin-inline: calc(0.5rem * -1);
}
.k-grid .k-grid-md .k-table-th > .k-cell-inner > .k-link,
.k-grid-md .k-table-th > .k-cell-inner > .k-link {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}
.k-grid .k-grid-md .k-grouping-row .k-icon,
.k-grid-md .k-grouping-row .k-icon {
  margin-inline-start: calc(8px - 0.5rem);
}
.k-grid .k-grid-md .k-grouping-dropclue,
.k-grid-md .k-grouping-dropclue {
  height: calc(calc(1.25em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px) + 6px);
}
.k-grid .k-grid-md .k-hierarchy-cell,
.k-grid .k-grid-md .k-drag-cell,
.k-grid-md .k-hierarchy-cell,
.k-grid-md .k-drag-cell {
  padding: 0;
}
.k-grid .k-grid-md .k-hierarchy-cell > .k-icon,
.k-grid .k-grid-md .k-drag-cell > .k-icon,
.k-grid-md .k-hierarchy-cell > .k-icon,
.k-grid-md .k-drag-cell > .k-icon {
  padding-block: 0.5rem;
  padding-inline: 0;
}
.k-grid .k-grid-md .k-edit-cell,
.k-grid .k-grid-md .k-command-cell,
.k-grid .k-grid-md .k-grid-edit-row td,
.k-grid .k-grid-md .k-grid-edit-row .k-table-td,
.k-grid-md .k-edit-cell,
.k-grid-md .k-command-cell,
.k-grid-md .k-grid-edit-row td,
.k-grid-md .k-grid-edit-row .k-table-td {
  padding-block: calc(var(--kendo-spacing-2\.5, 0.625rem) / 2);
  padding-inline: 0.5rem;
}
.k-grid .k-grid-md .k-filter-row td,
.k-grid .k-grid-md .k-filter-row .k-table-td,
.k-grid .k-grid-md .k-filter-row .k-table-th,
.k-grid-md .k-filter-row td,
.k-grid-md .k-filter-row .k-table-td,
.k-grid-md .k-filter-row .k-table-th {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}
.k-grid .k-grid-md .k-filtercell > span,
.k-grid .k-grid-md .k-filtercell .k-filtercell-wrapper,
.k-grid-md .k-filtercell > span,
.k-grid-md .k-filtercell .k-filtercell-wrapper {
  gap: calc(0.5rem / 2);
}
.k-grid .k-grid-md .k-selection-aggregates,
.k-grid-md .k-selection-aggregates {
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
}
.k-grid .k-grid-md .k-resizer-wrap,
.k-grid-md .k-resizer-wrap {
  display: block;
  width: 100%;
  padding-block-start: calc(calc(var(--kendo-spacing-2\.5, 0.625rem) / 2) - var(--kendo-spacing-0\.5, 0.125rem));
  padding-block-end: calc(var(--kendo-spacing-2\.5, 0.625rem) / 2);
  position: absolute;
  background: none;
  cursor: row-resize;
  z-index: 2;
}

.k-loading-pdf-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.k-loading-pdf-mask > .k-i-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 64px;
}
.k-loading-pdf-mask .k-loading-pdf-progress {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.k-pdf-export .k-loading-pdf-mask {
  display: none;
}

.k-grid-pdf-export-element {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.k-grid-pdf-export-element .k-filter-row {
  display: none;
}

.k-pdf-export-shadow .k-grid {
  float: left;
  width: auto !important;
}
.k-pdf-export-shadow .k-grid,
.k-pdf-export-shadow .k-grid-content,
.k-pdf-export-shadow .k-grid-content-locked {
  height: auto !important;
  overflow: visible;
}
.k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap, .k-pdf-export-shadow .k-grid-header-locked + .k-grid-content, .k-pdf-export-shadow .k-grid-header-locked + .k-grid-footer-wrap {
  width: auto !important;
}
.k-pdf-export-shadow .k-grid-header,
.k-pdf-export-shadow .k-grid[data-role=grid] .k-grid-footer {
  padding: 0 !important;
}

.k-filter-menu-popup {
  min-width: 250px;
  max-width: 320px;
}

.k-filter-menu {
  box-sizing: border-box;
}

.k-filter-menu-container {
  padding: var(--kendo-spacing-2, 0.5rem);
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-filter-menu-container .k-filter-and {
  width: min-content;
  align-self: start;
}
.k-filter-menu-container .k-actions, .k-filter-menu-container .k-edit-buttons,
.k-filter-menu-container .k-action-buttons,
.k-filter-menu-container .k-columnmenu-actions, .k-filter-menu-container .k-form-buttons {
  margin: 0;
  padding: 0;
}
.k-filter-menu-container kendo-numeric-filter-menu,
.k-filter-menu-container kendo-grid-string-filter-menu,
.k-filter-menu-container kendo-grid-date-filter-menu,
.k-filter-menu-container kendo-grid-numeric-filter-menu,
.k-filter-menu-container kendo-grid-filter-menu-input-wrapper,
.k-filter-menu-container kendo-treelist-string-filter-menu,
.k-filter-menu-container kendo-treelist-date-filter-menu,
.k-filter-menu-container kendo-treelist-numeric-filter-menu,
.k-filter-menu-container kendo-treelist-filter-menu-input-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: var(--kendo-spacing-2, 0.5rem);
}

.k-filter-menu.k-popup .k-filter-menu-container,
.k-grid-filter-popup.k-popup .k-filter-menu-container {
  min-width: 250px;
  max-width: 320px;
}

.k-popup .k-multicheck-wrap {
  margin: 0;
  padding: 0;
  max-height: 300px;
  white-space: nowrap;
  overflow: auto;
  list-style: none;
}
.k-popup .k-multicheck-wrap .k-item,
.k-popup .k-multicheck-wrap .k-check-all-wrap {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  display: flex;
  flex-flow: row nowrap;
}

.k-filter-selected-items {
  font-weight: bold;
  line-height: normal;
  white-space: nowrap;
}

.k-autofitting {
  width: auto !important;
  table-layout: auto !important;
}
.k-autofitting .k-table-th,
.k-autofitting td,
.k-autofitting .k-table-td {
  white-space: nowrap !important;
}
.k-autofitting .k-detail-row {
  display: none !important;
}

.k-column-menu-popup,
.k-grid-columnmenu-popup {
  min-width: 250px;
  box-sizing: border-box;
}
.k-column-menu-popup.k-popup,
.k-grid-columnmenu-popup.k-popup {
  max-width: 320px;
}
.k-column-menu-popup .k-actions, .k-column-menu-popup .k-edit-buttons,
.k-column-menu-popup .k-action-buttons,
.k-column-menu-popup .k-columnmenu-actions, .k-column-menu-popup .k-form-buttons,
.k-grid-columnmenu-popup .k-actions,
.k-grid-columnmenu-popup .k-edit-buttons,
.k-grid-columnmenu-popup .k-action-buttons,
.k-grid-columnmenu-popup .k-columnmenu-actions,
.k-grid-columnmenu-popup .k-form-buttons {
  margin: 0;
}

.k-column-menu {
  box-sizing: border-box;
}
.k-column-menu .k-menu:not(.k-context-menu) {
  font-weight: 400;
}
.k-column-menu .k-expander {
  border: 0;
  background: inherit;
}
.k-column-menu .k-expander .k-columnmenu-item {
  display: flex;
  align-items: center;
}

.k-column-menu-tabbed {
  border-radius: var(--kendo-spacing-1, 0.25rem) var(--kendo-spacing-1, 0.25rem) 0 0;
}
.k-column-menu-tabbed .k-tabstrip-items {
  margin: -1px -1px 0;
}
.k-column-menu-tabbed .k-tabstrip-items .k-item {
  flex: 1;
}
.k-column-menu-tabbed .k-tabstrip-items .k-link {
  justify-content: center;
}
.k-column-menu-tabbed .k-tabstrip-content {
  padding-inline: var(--kendo-spacing-0, 0px);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  border-width: 0;
}

.k-column-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.k-column-chooser-title,
.k-column-list-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-column-list-item {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
}
.k-column-list-item .k-checkbox-label {
  margin: 0;
}
.k-column-list-item:focus {
  outline: none;
}

.k-columns-items-wrap {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-0, 0px);
}

.k-columnmenu-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  outline: 0;
  cursor: pointer;
}
.k-columnmenu-item > .k-icon,
.k-columnmenu-item > .k-expander-indicator {
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
}

.k-columnmenu-item-wrapper + .k-columnmenu-item-wrapper {
  border-top: 1px solid;
  border-top-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-columnmenu-item-content {
  overflow: hidden;
}

.k-column-menu-group-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  display: flex;
  flex: 0 0 auto;
  position: relative;
  user-select: none;
}

.k-column-menu-group-header-text {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: var(--kendo-line-height, normal);
  font-weight: bold;
  text-transform: uppercase;
  flex: 1 1 auto;
}

.k-rtl .k-grid-virtual .k-grid-content .k-grid-table-wrap,
.k-rtl .k-grid-virtual .k-grid-content .k-grid-table,
.k-rtl .k-grid-virtual .k-grid-content > .k-height-container,
[dir=rtl] .k-grid-virtual .k-grid-content .k-grid-table-wrap,
[dir=rtl] .k-grid-virtual .k-grid-content .k-grid-table,
[dir=rtl] .k-grid-virtual .k-grid-content > .k-height-container,
.k-rtl.k-grid-virtual .k-grid-content .k-grid-table-wrap,
.k-rtl.k-grid-virtual .k-grid-content .k-grid-table,
.k-rtl.k-grid-virtual .k-grid-content > .k-height-container,
[dir=rtl].k-grid-virtual .k-grid-content .k-grid-table-wrap,
[dir=rtl].k-grid-virtual .k-grid-content .k-grid-table,
[dir=rtl].k-grid-virtual .k-grid-content > .k-height-container {
  float: right;
}

.k-grid {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #ffffff;
}
.k-grid .k-table {
  background-color: inherit;
}
.k-grid td,
.k-grid .k-table-td,
.k-grid .k-grid-content,
.k-grid .k-grid-header-locked,
.k-grid .k-grid-content-locked {
  border-color: #e6e6e6;
}
.k-grid .k-table-alt-row {
  background-color: #ffffff;
}
.k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
  background-color: #f5f5f5;
}
.k-grid .k-table-th.k-selected,
.k-grid td.k-selected,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected > td,
.k-grid .k-table-row.k-selected > .k-table-td {
  color: #333333;
  background-color: #f6fdff;
}
.k-grid .k-table-th:focus, .k-grid .k-table-th.k-focus,
.k-grid td:focus,
.k-grid td.k-focus,
.k-grid .k-table-td:focus,
.k-grid .k-table-td.k-focus,
.k-grid .k-master-row > td:focus,
.k-grid .k-master-row > td.k-focus,
.k-grid .k-grouping-row > td:focus,
.k-grid .k-grouping-row > td.k-focus,
.k-grid .k-detail-row > td:focus,
.k-grid .k-detail-row > td.k-focus,
.k-grid .k-group-footer > td:focus,
.k-grid .k-group-footer > td.k-focus,
.k-grid .k-master-row > .k-table-td:focus,
.k-grid .k-master-row > .k-table-td.k-focus,
.k-grid .k-grouping-row > .k-table-td:focus,
.k-grid .k-grouping-row > .k-table-td.k-focus,
.k-grid .k-detail-row > .k-table-td:focus,
.k-grid .k-detail-row > .k-table-td.k-focus,
.k-grid .k-group-footer > .k-table-td:focus,
.k-grid .k-group-footer > .k-table-td.k-focus,
.k-grid .k-grid-pager:focus,
.k-grid .k-grid-pager.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-grid col.k-sorted,
.k-grid .k-table-th.k-sorted {
  background-color: transparent;
}
.k-grid .k-grid-header-locked,
.k-grid .k-grid-content-locked,
.k-grid .k-grid-header-locked .k-header,
.k-grid .k-grid-content-locked td,
.k-grid .k-grid-content-locked .k-table-td {
  border-color: #e6e6e6;
}
.k-grid .k-grid-header-locked .k-header {
  border-bottom-color: #e6e6e6;
}
.k-grid .k-grid-content-locked .k-group-footer td,
.k-grid .k-grid-content-locked .k-group-footer .k-table-td,
.k-grid .k-grid-content-locked .k-group-cell {
  border-color: #e6e6e6;
}
.k-grid .k-grid-content-locked .k-grouping-row + .k-table-row td,
.k-grid .k-grid-content-locked .k-grouping-row + .k-table-row .k-table-td {
  border-top-color: #e6e6e6;
}
.k-grid .k-grid-content-locked .k-selected td,
.k-grid .k-grid-content-locked .k-selected .k-table-td {
  background-color: #f6fdff;
}
.k-grid .k-grid-content-locked .k-selected.k-table-alt-row td,
.k-grid .k-grid-content-locked .k-selected.k-table-alt-row .k-table-td {
  background-color: #f6fdff;
}
.k-grid .k-grid-content-locked .k-selected:hover td,
.k-grid .k-grid-content-locked .k-selected.k-hover td,
.k-grid .k-grid-content-locked .k-selected:hover .k-table-td,
.k-grid .k-grid-content-locked .k-selected.k-hover .k-table-td {
  background-color: #f6fdff;
}

.k-grid-toolbar {
  border-color: inherit;
}

.k-grouping-header {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #f5f5f5;
}

.k-grid-header {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #f5f5f5;
}
.k-grid-header .k-sort-icon,
.k-grid-header .k-sort-order {
  color: #333333;
}
.k-grid-header .k-grid-filter:focus, .k-grid-header .k-grid-filter.k-focus,
.k-grid-header .k-header-column-menu:focus,
.k-grid-header .k-header-column-menu.k-focus,
.k-grid-header .k-grid-header-menu:focus,
.k-grid-header .k-grid-header-menu.k-focus,
.k-grid-header .k-hierarchy-cell .k-icon:focus,
.k-grid-header .k-hierarchy-cell .k-icon.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-grid-header .k-grid-header-sticky,
.k-grid-header .k-grid-header-sticky.k-sorted {
  color: #333333;
  background-color: #f5f5f5;
  border-right-color: #e6e6e6;
  border-left-color: #e6e6e6;
}

.k-selection-aggregates {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #f5f5f5;
}

.k-master-row .k-grid-content-sticky {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #ffffff;
  border-top-color: #e6e6e6;
}
.k-master-row .k-grid-row-sticky {
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  background-color: #ffffff;
}
.k-master-row.k-table-alt-row .k-grid-content-sticky,
.k-master-row.k-table-alt-row .k-grid-row-sticky {
  background-color: #ffffff;
}
.k-master-row.k-table-row.k-selected td.k-grid-content-sticky, .k-master-row.k-table-row.k-selected .k-table-td.k-grid-row-sticky, .k-master-row.k-table-row td.k-grid-content-sticky.k-selected, .k-master-row.k-table-row .k-table-td.k-grid-content-sticky.k-selected {
  background-color: #ffffff;
}
.k-master-row.k-table-row.k-selected td.k-grid-content-sticky::before, .k-master-row.k-table-row.k-selected .k-table-td.k-grid-row-sticky::before, .k-master-row.k-table-row td.k-grid-content-sticky.k-selected::before, .k-master-row.k-table-row .k-table-td.k-grid-content-sticky.k-selected::before {
  background-color: #f6fdff;
}
.k-master-row.k-selected.k-table-alt-row td.k-grid-content-sticky, .k-master-row.k-selected.k-table-alt-row .k-table-td.k-grid-row-sticky, .k-master-row.k-table-alt-row td.k-grid-content-sticky.k-selected, .k-master-row.k-table-alt-row .k-table-td.k-grid-content-sticky.k-selected {
  background-color: #ffffff;
}
.k-master-row.k-selected.k-table-alt-row td.k-grid-content-sticky::before, .k-master-row.k-selected.k-table-alt-row .k-table-td.k-grid-row-sticky::before, .k-master-row.k-table-alt-row td.k-grid-content-sticky.k-selected::before, .k-master-row.k-table-alt-row .k-table-td.k-grid-content-sticky.k-selected::before {
  background-color: #f6fdff;
}
.k-master-row:hover .k-grid-content-sticky, .k-master-row.k-hover .k-grid-content-sticky, .k-master-row:hover .k-grid-row-sticky, .k-master-row.k-hover .k-grid-row-sticky {
  background-color: #f5f5f5;
}
.k-master-row.k-selected:hover td.k-grid-content-sticky, .k-master-row.k-selected.k-hover .k-table-td.k-grid-content-sticky, .k-master-row.k-selected:hover td.k-grid-row-sticky, .k-master-row.k-selected.k-hover .k-table-td.k-grid-row-sticky, .k-master-row:hover td.k-grid-content-sticky.k-selected, .k-master-row.k-hover td.k-grid-content-sticky.k-selected, .k-master-row:hover .k-table-td.k-grid-content-sticky.k-selected, .k-master-row.k-hover .k-table-td.k-grid-content-sticky.k-selected {
  background-color: #f5f5f5;
}
.k-master-row.k-selected:hover td.k-grid-content-sticky::before, .k-master-row.k-selected.k-hover .k-table-td.k-grid-content-sticky::before, .k-master-row.k-selected:hover td.k-grid-row-sticky::before, .k-master-row.k-selected.k-hover .k-table-td.k-grid-row-sticky::before, .k-master-row:hover td.k-grid-content-sticky.k-selected::before, .k-master-row.k-hover td.k-grid-content-sticky.k-selected::before, .k-master-row:hover .k-table-td.k-grid-content-sticky.k-selected::before, .k-master-row.k-hover .k-table-td.k-grid-content-sticky.k-selected::before {
  background-color: #f6fdff;
}

kendo-grid.k-grid .k-grid-content-sticky {
  border-top-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  background-color: #ffffff;
}
kendo-grid.k-grid .k-grid-content-sticky:hover, kendo-grid.k-grid .k-grid-content-sticky.k-hover {
  background-color: #f5f5f5;
}
kendo-grid.k-grid .k-grid-row-sticky td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td {
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  background-color: #ffffff;
}
kendo-grid.k-grid .k-grid-row-sticky td:hover td, kendo-grid.k-grid .k-grid-row-sticky td.k-hover td, kendo-grid.k-grid .k-grid-row-sticky td:hover .k-table-td, kendo-grid.k-grid .k-grid-row-sticky td.k-hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td:hover td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td.k-hover .k-table-td {
  background-color: #f5f5f5;
}
kendo-grid.k-grid .k-table-alt-row .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row .k-table-td {
  background-color: #ffffff;
}
kendo-grid.k-grid .k-table-row.k-selected .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-grid-row-sticky td,
kendo-grid.k-grid .k-grid-row-sticky td.k-selected,
kendo-grid.k-grid .k-selected.k-grid-row-sticky .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td.k-selected,
kendo-grid.k-grid .k-selected.k-grid-content-sticky {
  background-color: #ffffff;
}
kendo-grid.k-grid .k-table-row.k-selected .k-grid-content-sticky::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky td::before,
kendo-grid.k-grid .k-grid-row-sticky td.k-selected::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky .k-table-td::before,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td.k-selected::before,
kendo-grid.k-grid .k-selected.k-grid-content-sticky::before {
  background-color: #f6fdff;
}
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky .k-table-td,
kendo-grid.k-grid .k-table-alt-row .k-selected.k-grid-content-sticky {
  background-color: #ffffff;
}
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row .k-grid-content-sticky::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky .k-table-td::before,
kendo-grid.k-grid .k-table-alt-row .k-selected.k-grid-content-sticky::before {
  background-color: #f6fdff;
}
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row:hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row.k-hover .k-table-td,
kendo-grid.k-grid .k-table-alt-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-alt-row.k-hover .k-grid-content-sticky {
  background-color: #f5f5f5;
}
kendo-grid.k-grid .k-table-row.k-selected:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-selected.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky:hover td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td.k-selected,
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky.k-selected,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky.k-selected {
  background-color: #f5f5f5;
}
kendo-grid.k-grid .k-table-row.k-selected:hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-table-row.k-selected.k-hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover td::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover td::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover .k-table-td::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover .k-table-td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover .k-table-td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover .k-table-td::before,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row:hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row.k-hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-grid-row-sticky:hover td.k-selected::before,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td.k-selected::before,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td.k-selected::before,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td.k-selected::before,
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky.k-selected::before,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky.k-selected::before {
  background-color: #f6fdff;
}

.k-grouping-row .k-grid-content-sticky {
  background-color: #f5f5f5;
}
.k-grouping-row:hover .k-grid-content-sticky, .k-grouping-row.k-hover .k-grid-content-sticky {
  background-color: #f5f5f5;
}

.k-group-footer td,
.k-group-footer .k-table-td,
.k-group-footer .k-grid-footer-sticky {
  background-color: #f8f8f8;
}
.k-group-footer:hover .k-grid-footer-sticky, .k-group-footer.k-hover .k-grid-footer-sticky {
  background-color: #f8f8f8;
}

.k-grid-footer {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #ffffff;
}
.k-grid-footer .k-grid-footer-locked {
  border-color: inherit;
}
.k-grid-footer .k-grid-footer-sticky {
  border-color: #e6e6e6;
  background-color: #f8f8f8;
}

.k-grouping-row,
.k-group-cell {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #f5f5f5;
}

.k-column-list-item:hover,
.k-columnmenu-item:hover {
  color: #333333;
  background-color: transparent;
}

.k-column-list-item:focus,
.k-column-list-item.k-focus,
.k-columnmenu-item:focus,
.k-columnmenu-item.k-focus {
  box-shadow: inset 0 0 0 3px color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 15%, transparent);
}

.k-column-list-wrapper {
  border-color: inherit;
}

.k-columnmenu-item.k-selected {
  color: #333333;
  background-color: transparent;
}

.k-column-menu .k-menu:not(.k-context-menu) {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item:hover, .k-column-menu .k-menu:not(.k-context-menu) .k-item.k-hover {
  color: #333333;
  background-color: transparent;
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-selected {
  color: #333333;
  background-color: transparent;
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item:focus, .k-column-menu .k-menu:not(.k-context-menu) .k-item.k-focus {
  box-shadow: inset 0 0 0 3px color-mix(in srgb, var(--kendo-color-on-app-surface, #212529) 15%, transparent);
}

.k-column-menu-tabbed {
  background-color: var(--kendo-color-surface, #f8f9fa);
}

.k-column-menu-group-header-text {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
}

.k-check-all-wrap {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-grid-norecords-template {
  background-color: #ffffff;
  border-color: #e6e6e6;
}

.k-resizer-wrap.k-hover .k-row-resizer {
  background-color: color-mix(in srgb, var(--kendo-color-on-base, #212529) 20%, transparent);
}
.k-resizer-wrap.k-active .k-row-resizer {
  background-color: var(--kendo-color-primary, #0d6efd);
}

.k-color-preview {
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-radius: var(--kendo-border-radius-md, 3px);
  box-sizing: border-box;
  border-style: solid;
  display: inline-flex;
  flex-flow: row nowrap;
  position: relative;
  overflow: hidden;
}
.k-color-preview *,
.k-color-preview *::before,
.k-color-preview *::after {
  box-sizing: border-box;
}

.k-coloreditor-current-color {
  cursor: pointer;
}

.k-icon-color-preview {
  border-width: 0;
  border-radius: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.k-icon-color-preview .k-color-preview-mask {
  width: calc(16px - 2px);
  height: 2px;
}

.k-color-preview-mask {
  width: 100%;
  height: 100%;
  position: relative;
}

.k-color-preview-mask::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC");
  background-size: contain;
  background-position: 0 0;
}

.k-no-color .k-color-preview-mask::before {
  content: "";
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' version='1.1'%3e%3cline x1='0' x2='20' y1='0' y2='20' stroke='var%28--kendo-color-error, %23dc3545%29' stroke-width='1'/%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: 0 0;
}

.k-color-preview {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}
.k-color-preview:hover, .k-color-preview.k-hover {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-colorpalette {
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: 0;
  display: inline-flex;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-colorpalette *,
.k-colorpalette *::before,
.k-colorpalette *::after {
  box-sizing: border-box;
}

.k-colorpalette-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  position: relative;
}

.k-colorpalette-tile {
  width: var(--kendo-spacing-6, 1.5rem);
  height: var(--kendo-spacing-6, 1.5rem);
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.k-colorpalette-tile:hover, .k-colorpalette-tile.k-hover {
  position: relative;
  z-index: 101;
}
.k-colorpalette-tile:focus, .k-colorpalette-tile.k-focus, .k-colorpalette-tile.k-selected, .k-colorpalette-tile.k-selected:hover {
  position: relative;
  z-index: 100;
}

.k-colorpalette-tile:focus, .k-colorpalette-tile.k-focus {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.k-colorpalette-tile:hover, .k-colorpalette-tile.k-hover {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.8);
}
.k-colorpalette-tile.k-selected, .k-colorpalette-tile.k-selected:hover {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px white;
}

.k-slider {
  border: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  background: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-slider *,
.k-slider *::before,
.k-slider *::after {
  box-sizing: border-box;
}
.k-slider .k-label {
  width: auto;
  font-size: 0.92em;
  line-height: 1;
  white-space: nowrap;
  position: absolute;
}
.k-slider .k-tick,
.k-slider .k-slider-track {
  cursor: pointer;
}
.k-slider .k-tick {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: relative;
}
.k-slider.k-disabled .k-tick,
.k-slider.k-disabled .k-slider-track,
.k-slider.k-disabled .k-draghandle {
  cursor: default;
}

.k-slider {
  width: min-content;
  height: min-content;
  gap: calc(14px / 2);
}
.k-slider .k-button {
  flex: none;
}
.k-slider .k-slider-track-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-flow: inherit;
  position: relative;
  touch-action: none;
}
.k-slider .k-slider-track-wrap .k-slider-items {
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1 1 100%;
  display: flex;
  flex-flow: inherit;
  justify-content: space-between;
  user-select: none;
}
.k-slider .k-slider-track-wrap .k-tick {
  flex: 0 0 1px;
}
.k-slider .k-slider-track-wrap .k-tick-horizontal {
  width: 1px;
  min-height: 100%;
}
.k-slider .k-slider-track-wrap .k-tick-vertical {
  width: 100%;
  min-height: 1px;
}
.k-slider .k-slider-track-wrap .k-draghandle {
  position: absolute;
}
.k-slider-horizontal .k-slider-track-wrap {
  height: 26px;
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-track {
  width: 100%;
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle {
  top: 50%;
  transform: translate(-50%, -50%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:focus,
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:active {
  transform: translate(-50%, -50%) scale(1);
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-selection {
  width: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
  inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-start {
  inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-end {
  inset-inline-start: calc(var(--kendo-slider-end, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap {
  width: 26px;
}
.k-slider-vertical .k-slider-track-wrap .k-slider-track {
  height: 100%;
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle {
  left: 50%;
  transform: translate(-50%, 50%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle:focus,
.k-slider-vertical .k-slider-track-wrap .k-draghandle:active {
  transform: translate(-50%, 50%) scale(1);
}
.k-slider-vertical .k-slider-track-wrap .k-slider-selection {
  bottom: calc(var(--kendo-slider-start, 0) * 1%);
  height: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-start {
  bottom: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-end {
  bottom: calc(var(--kendo-slider-end, 0) * 1%);
}

.k-slider-vertical {
  height: 200px;
  flex-flow: column-reverse nowrap;
}
.k-slider-vertical .k-tick {
  text-align: end;
  margin-left: 2px;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  text-align: start;
}
.k-slider-vertical .k-tick {
  background-position: -94px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  background-position: -124px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
  background-position: -154px center;
}
.k-slider-vertical .k-tick-large {
  display: flex;
  align-items: center;
  background-position: -4px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
  background-position: -34px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
  background-position: -64px center;
}
.k-slider-vertical .k-first {
  background-position: -94px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
  background-position: -4px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
  background-position: -124px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
  background-position: -34px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
  background-position: -154px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
  background-position: -64px 100%;
}
.k-slider-vertical .k-last {
  background-position: -94px 0;
}
.k-slider-vertical .k-tick-large.k-last {
  background-position: -4px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
  background-position: -124px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
  background-position: -34px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
  background-position: -154px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
  background-position: -64px 0;
}
.k-slider-vertical .k-label {
  text-align: start;
  inset-inline-start: 120%;
  top: 50%;
  transform: translateY(-50%);
}
.k-slider-vertical .k-first .k-label {
  top: 100%;
}
.k-slider-vertical .k-last .k-label {
  top: 0;
}
.k-slider-vertical .k-slider-topleft .k-label {
  inset-inline-start: auto;
  inset-inline-end: 120%;
}

.k-slider-horizontal {
  width: 200px;
  flex-flow: row nowrap;
}
.k-slider-horizontal .k-tick {
  background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
  background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
  background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
  background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
  background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
  background-position: center -62px;
}
.k-slider-horizontal .k-first {
  background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
  background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
  background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
  background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
  background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
  background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
  background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
  background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
  background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
  background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
  background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
  background-position: 100% -62px;
}
.k-slider-horizontal .k-label {
  inset-inline-start: 50%;
  bottom: -1.2em;
  transform: translateX(-50%);
}
.k-slider-horizontal .k-first .k-label {
  inset-inline-start: 0;
}
.k-slider-horizontal .k-last .k-label {
  inset-inline-start: 100%;
}
.k-slider-horizontal .k-slider-topleft .k-label {
  top: -1.2em;
}

.k-slider-track,
.k-slider-selection {
  margin: 0;
  padding: 0;
  position: absolute;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
  height: 4px;
  left: 0;
  margin-top: -2px;
  top: 50%;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
}

.k-draghandle {
  background-color: transparent;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  outline: 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 14px;
  height: 14px;
}
.k-slider-transitions.k-slider-horizontal .k-draghandle {
  transition: inset-inline-start 0.3s ease-out, background-color 0.3s ease-out, transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-slider-transitions.k-slider-vertical .k-draghandle {
  transition: bottom 0.3s ease-out, background-color 0.3s ease-out, transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-draghandle.k-pressed {
  transition: none;
}

.k-slider-transitions.k-slider-horizontal .k-slider-selection {
  transition: width 0.3s ease-out;
}
.k-slider-transitions.k-slider-vertical .k-slider-selection {
  transition: height 0.3s ease-out;
}
.k-slider-selection.k-pressed {
  transition: none;
}

.k-slider.k-readonly .k-button,
.k-slider.k-readonly .k-slider-track,
.k-slider.k-readonly .k-tick,
.k-slider.k-readonly .k-draghandle {
  pointer-events: none;
}

.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
  margin-inline-start: -0.2rem;
}
.k-slider-tooltip .k-callout-w,
.k-slider-tooltip .k-callout-e {
  margin-top: -0.2rem;
}

.k-slider-rtl.k-slider-horizontal .k-draghandle, .k-slider-horizontal.k-slider[dir=rtl] .k-draghandle,
[dir=rtl] .k-slider-horizontal.k-slider .k-draghandle,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle {
  transform: translate(50%, -50%);
}
.k-slider-rtl.k-slider-horizontal .k-draghandle:focus, .k-slider-horizontal.k-slider[dir=rtl] .k-draghandle:focus,
[dir=rtl] .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:focus,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-rtl.k-slider-horizontal .k-draghandle:active,
.k-slider-horizontal.k-slider[dir=rtl] .k-draghandle:active,
[dir=rtl] .k-slider-horizontal.k-slider .k-draghandle:active,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:active,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:active {
  transform: translate(50%, -50%) scale(1);
}
.k-slider-rtl.k-slider-horizontal .k-label, .k-slider-horizontal.k-slider[dir=rtl] .k-label,
[dir=rtl] .k-slider-horizontal.k-slider .k-label,
.k-slider-horizontal.k-slider.k-rtl .k-label,
.k-rtl .k-slider-horizontal.k-slider .k-label {
  transform: translateX(50%);
}

.k-slider kendo-resize-sensor {
  position: absolute;
}

.k-slider {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-slider .k-slider-track,
.k-slider .k-slider-selection {
  border-radius: var(--kendo-border-radius-md, 3px);
}
.k-slider .k-slider-track {
  background-color: var(--kendo-color-base-emphasis, rgba(33, 37, 41, 0.34));
}
.k-slider .k-slider-selection {
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-slider .k-draghandle {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
  border-radius: 50%;
}
.k-slider .k-draghandle:hover {
  border-color: var(--kendo-color-primary-hover, #0c64e4);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary-hover, #0c64e4);
}
.k-slider .k-draghandle:active, .k-slider .k-draghandle.k-pressed {
  border-color: var(--kendo-color-primary-active, #0a58ca);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary-active, #0a58ca);
}
.k-slider .k-draghandle:focus, .k-slider .k-draghandle.k-focus {
  box-shadow: 0 0 0 3px color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 50%, transparent);
}
.k-slider.k-focus .k-draghandle {
  box-shadow: 0 0 0 3px color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 50%, transparent);
}
.k-slider-horizontal .k-tick {
  background-image: url(data:image/gif;base64,R0lGODlhAQC0AIABALi4uAAAACH5BAEAAAEALAAAAAABALQAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-slider-vertical .k-tick {
  background-image: url(data:image/gif;base64,R0lGODlhtAABAIABALi4uAAAACH5BAEAAAEALAAAAAC0AAEAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-colorgradient {
  border-radius: var(--kendo-border-radius-md, 3px);
  width: 328px;
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0;
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--kendo-spacing-4, 1rem);
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-colorgradient *,
.k-colorgradient *::before,
.k-colorgradient *::after {
  box-sizing: border-box;
}

.k-colorgradient-canvas {
  gap: var(--kendo-spacing-3, 0.75rem);
}
.k-colorgradient-canvas .k-hsv-rectangle {
  border-radius: var(--kendo-border-radius-md, 3px);
  position: relative;
  flex: 1 1 auto;
  user-select: none;
}
.k-colorgradient-canvas .k-hsv-gradient {
  border-radius: var(--kendo-border-radius-md, 3px);
  height: 180px;
}
.k-colorgradient-canvas .k-hsv-draghandle {
  margin-top: -7px;
  margin-left: -7px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  cursor: pointer;
}
.k-colorgradient-canvas .k-hsv-controls {
  position: relative;
  flex-shrink: 0;
  gap: var(--kendo-spacing-3, 0.75rem);
}

.k-colorgradient .k-draghandle {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  outline-width: 1px;
  outline-style: solid;
  box-sizing: border-box;
}

.k-colorgradient-slider.k-slider-vertical {
  width: 10px;
  height: 180px;
  flex: 0 0 10px;
}
.k-colorgradient-slider.k-slider-vertical .k-slider-track {
  width: 10px;
  border-radius: 10px;
  margin-left: -5px;
}
.k-colorgradient-slider.k-slider-horizontal {
  width: 100%;
  height: 10px;
  flex: 0 0 10px;
}
.k-colorgradient-slider.k-slider-horizontal .k-slider-track {
  height: 10px;
  border-radius: 10px;
  margin-top: -5px;
}
.k-colorgradient-slider.k-alpha-slider .k-slider-track::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  position: relative;
  z-index: -1;
}
.k-colorgradient-slider .k-slider-track-wrap {
  z-index: 1;
}
.k-colorgradient-slider .k-slider-selection {
  display: none;
}
.k-colorgradient-slider .k-draghandle {
  border-width: 3px;
}

.k-colorgradient-inputs {
  gap: calc(var(--kendo-spacing-4, 1rem) / 2);
}
.k-colorgradient-inputs .k-colorgradient-input-label {
  text-transform: uppercase;
}
.k-colorgradient-inputs > .k-vstack, .k-colorgradient-inputs > .k-vbox {
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-colorgradient-inputs .k-numerictextbox {
  width: 56px;
}
.k-colorgradient-inputs .k-input-inner {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  text-align: center;
  text-overflow: clip;
}

.k-colorgradient-color-contrast > div {
  display: flex;
  flex-flow: row nowrap;
  gap: calc(var(--kendo-spacing-4, 1rem) / 2);
}
.k-colorgradient-color-contrast .k-contrast-ratio {
  margin-bottom: calc(var(--kendo-spacing-4, 1rem) / 2);
}
.k-colorgradient-color-contrast .k-contrast-ratio-text {
  font-weight: var(--kendo-font-weight-bold, normal);
}
.k-colorgradient-color-contrast .k-contrast-validation {
  display: inline-flex;
  align-items: center;
  gap: calc(calc(var(--kendo-spacing-4, 1rem) / 2) / 2);
}
.k-colorgradient-color-contrast .k-contrast-validation .k-icon {
  vertical-align: middle;
}
.k-colorgradient-color-contrast .k-contrast-validation .k-icon + .k-icon,
.k-colorgradient-color-contrast .k-contrast-validation .k-icon-wrapper-host + .k-icon-wrapper-host .k-icon {
  margin-inline-start: -13px;
}

.k-color-contrast-svg {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  pointer-events: none;
}

.k-colorgradient {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-colorgradient:focus,
.k-colorgradient.k-focus {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-colorgradient-canvas .k-hsv-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), black), linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.k-colorgradient .k-draghandle {
  border-color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  outline-color: #000000;
  box-shadow: rgba(0, 0, 0, 0.5);
}
.k-colorgradient .k-draghandle:focus, .k-colorgradient .k-draghandle.k-focus {
  border-color: #ffffff;
  background-color: transparent;
  outline-color: #000000;
  box-shadow: rgba(0, 0, 0, 0.5);
}
.k-colorgradient .k-draghandle:hover, .k-colorgradient .k-draghandle.k-hover, .k-colorgradient .k-draghandle.k-active, .k-colorgradient .k-draghandle:active {
  border-color: #ffffff;
  background-color: transparent;
  outline-color: #000000;
}

.k-colorgradient-slider.k-hue-slider.k-slider-horizontal .k-slider-track {
  background: linear-gradient(to right, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}
.k-colorgradient-slider.k-hue-slider.k-slider-vertical .k-slider-track {
  background: linear-gradient(to top, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}

.k-colorgradient-slider.k-alpha-slider .k-slider-track::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC) center repeat;
}

.k-colorgradient-input-label {
  color: var(--kendo-color-subtle, #596169);
}

.k-rtl .k-colorgradient .k-hue-slider.k-slider-horizontal .k-slider-track,
[dir=rtl] .k-colorgradient .k-hue-slider.k-slider-horizontal .k-slider-track {
  background: linear-gradient(to left, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}

.k-coloreditor {
  border-radius: var(--kendo-border-radius-md, 3px);
  min-width: auto;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: var(--kendo-line-height, normal);
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}
.k-coloreditor *,
.k-coloreditor *::before,
.k-coloreditor *::after {
  box-sizing: border-box;
}

.k-coloreditor-header {
  padding: var(--kendo-spacing-4, 1rem) var(--kendo-spacing-4, 1rem) 0;
}

.k-coloreditor-header-actions {
  gap: calc(var(--kendo-spacing-4, 1rem) / 2);
}

.k-coloreditor-preview {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  position: relative;
  z-index: 1;
}

.k-coloreditor-preview .k-color-preview {
  width: 32px;
  height: 12px;
}

.k-coloreditor-views {
  min-width: calc(var(--kendo-color-preview-width, var(--kendo-spacing-6, 1.5rem)) * var(--kendo-color-preview-columns, 10));
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  gap: var(--kendo-spacing-4, 1rem);
}
.k-coloreditor-views .k-colorgradient {
  padding: 0;
  width: auto;
  border-width: 0;
}
.k-coloreditor-views .k-colorgradient:focus,
.k-coloreditor-views .k-colorgradient.k-focus {
  outline: 2px dotted;
  outline-offset: 2px;
  border-radius: 0;
}
.k-coloreditor-views .k-colorpalette {
  align-self: center;
}
.k-coloreditor-views .k-colorpalette-tile {
  width: var(--kendo-color-preview-width, var(--kendo-spacing-6, 1.5rem));
  height: var(--kendo-color-preview-height, var(--kendo-spacing-6, 1.5rem));
}

.k-coloreditor-footer.k-actions, .k-coloreditor-footer.k-edit-buttons,
.k-coloreditor-footer.k-action-buttons,
.k-coloreditor-footer.k-columnmenu-actions, .k-coloreditor-footer.k-form-buttons {
  margin: 0;
}

.k-coloreditor {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: #333333;
  background-color: #ffffff;
}

.k-coloreditor:focus,
.k-coloreditor.k-focus {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-coloreditor .k-colorgradient:focus,
.k-coloreditor .k-colorgradient.k-focus {
  box-shadow: none;
  outline-color: rgba(0, 0, 0, 0.3);
}

.k-color-picker, .k-colorpicker {
  width: min-content;
}

.k-external-dropzone {
  height: 220px;
  display: flex;
  flex-flow: row nowrap;
}

.k-dropzone-inner {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: var(--kendo-line-height, normal);
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.k-dropzone-inner .k-dropzone-icon {
  margin-bottom: var(--kendo-spacing-6, 1.5rem);
}
.k-dropzone-inner .k-dropzone-hint {
  margin-bottom: var(--kendo-spacing-2, 0.5rem);
}
.k-dropzone-inner .k-dropzone-note {
  font-size: var(--kendo-font-size-xs, inherit);
}

.k-dropzone-inner {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface, #f8f9fa);
}
.k-dropzone-inner .k-icon,
.k-dropzone-inner .k-dropzone-icon {
  color: var(--kendo-color-subtle, #596169);
}
.k-dropzone-inner .k-dropzone-note {
  color: var(--kendo-color-subtle, #596169);
}

.k-external-dropzone-hover .k-icon,
.k-external-dropzone-hover .k-dropzone-icon {
  color: var(--kendo-color-primary, #0d6efd);
}

.k-upload {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-upload *,
.k-upload *::before,
.k-upload *::after {
  box-sizing: border-box;
}
.k-upload .k-upload-button {
  min-width: 7em;
}
.k-upload .k-dropzone,
.k-upload .k-upload-dropzone {
  padding-block: 0.75rem;
  padding-inline: 0.75rem;
  border-width: 0;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: transparent;
}
.k-upload .k-dropzone .k-dropzone-hint,
.k-upload .k-upload-dropzone .k-dropzone-hint {
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  flex: 1;
}
.k-upload .k-dropzone .k-upload-status,
.k-upload .k-upload-dropzone .k-upload-status {
  font-weight: normal;
  display: flex;
  align-items: center;
  position: relative;
}
.k-upload .k-dropzone .k-upload-status > .k-icon,
.k-upload .k-upload-dropzone .k-upload-status > .k-icon {
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
}
.k-upload .k-dropzone .k-upload-status + .k-dropzone-hint,
.k-upload .k-upload-dropzone .k-upload-status + .k-dropzone-hint {
  display: none;
}
.k-upload .k-dropzone-active .k-upload-status {
  display: none;
}
.k-upload .k-dropzone-active .k-upload-status + .k-dropzone-hint {
  display: block;
}
.k-upload .k-upload-files {
  max-height: 164px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  list-style: none;
}
.k-upload .k-upload-files .k-file-multiple,
.k-upload .k-upload-files .k-file-single {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.k-upload .k-upload-files .k-file-single {
  align-items: center;
}
.k-upload .k-upload-files .k-file-multiple {
  align-items: flex-start;
}
.k-upload .k-upload-files .k-file {
  padding: 0.5rem 0.5rem calc(0.5rem + 4px);
  border-width: 0 0 1px;
  border-style: solid;
  outline: none;
  display: flex;
  align-items: flex-start;
  position: relative;
}
.k-upload .k-upload-files .k-file:last-child {
  border-width: 0;
}
.k-upload .k-upload-files .k-filename {
  margin-left: var(--kendo-spacing-4, 1rem);
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.k-upload .k-upload-files .k-upload-pct {
  margin-block: 0;
  margin-inline: var(--kendo-spacing-2, 0.5rem);
  font-weight: normal;
  vertical-align: middle;
}
.k-upload .k-upload-files ~ .k-clear-selected,
.k-upload .k-upload-files ~ .k-upload-selected {
  margin-top: -var(--kendo-spacing-2, 0.5rem);
  border-width: 0;
}
.k-upload .k-upload-files ~ .k-upload-selected {
  border-left-width: 1px;
  border-left-style: solid;
  margin-left: -1px;
}
.k-upload .k-upload-files .k-file-name,
.k-upload .k-upload-files .k-file-size,
.k-upload .k-upload-files .k-file-validation-message,
.k-upload .k-upload-files .k-file-summary {
  display: block;
}
.k-upload .k-upload-files .k-file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.k-upload .k-upload-files .k-file-size,
.k-upload .k-upload-files .k-file-summary,
.k-upload .k-upload-files .k-file-validation-message {
  font-size: 12px;
  line-height: var(--kendo-line-height-xs, normal);
}
.k-upload .k-upload-files .k-file-icon-wrapper .k-file-state {
  position: absolute;
}
.k-upload .k-upload-files .k-file-state {
  visibility: hidden;
}
.k-upload .k-upload-files .k-file-info {
  min-height: calc(16px * 2);
  display: block;
  overflow: hidden;
  flex: 1 0 0;
}
.k-upload .k-multiple-files-wrapper,
.k-upload .k-file-single > .k-file-info {
  margin-inline-start: 0.5rem;
  min-height: calc(16px * 2);
  display: block;
  overflow: hidden;
  flex: 1 0 0;
}
.k-upload .k-multiple-files-wrapper .k-file-info {
  margin-bottom: var(--kendo-spacing-3, 0.75rem);
  display: block;
}
.k-upload .k-multiple-files-wrapper .k-file-summary {
  margin-top: calc(var(--kendo-spacing-3, 0.75rem) / 3 * -1);
}
.k-upload .k-progressbar {
  --kendo-progressbar-height: 4px;
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  bottom: calc(0.5rem / 2);
  width: auto;
}
.k-upload .k-actions, .k-upload .k-edit-buttons,
.k-upload .k-action-buttons,
.k-upload .k-columnmenu-actions, .k-upload .k-form-buttons {
  border-top-width: 1px;
}

.k-upload-button-wrap {
  position: relative;
  direction: ltr;
}
.k-upload-button-wrap input {
  font: 170px monospace !important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.k-upload-button-wrap input[disabled],
.k-upload-button-wrap input.k-disabled {
  visibility: hidden;
}

.k-upload {
  border-color: #e6e6e6;
  color: #333333;
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-upload .k-dropzone,
.k-upload .k-upload-dropzone {
  border-color: #c2c2c2;
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: transparent;
}
.k-upload .k-dropzone.k-hover,
.k-upload .k-upload-dropzone.k-hover {
  background-color: var(--kendo-color-base-subtle, #e9ecef);
}
.k-upload .k-dropzone .k-dropzone-hint,
.k-upload .k-upload-dropzone .k-dropzone-hint {
  color: #333333;
}
.k-upload .k-upload-files,
.k-upload .k-file {
  border-color: #e6e6e6;
}
.k-upload .k-file.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}
.k-upload .k-file .k-upload-action.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.13);
}
.k-upload .k-upload-status {
  color: #333333;
}
.k-upload .k-file-progress .k-progressbar .k-selected {
  background-color: #66a7b8;
}
.k-upload .k-file-success .k-file-validation-message {
  color: var(--kendo-color-success, #198754);
}
.k-upload .k-file-success .k-progressbar .k-selected {
  background-color: var(--kendo-color-success, #198754);
}
.k-upload .k-file-error .k-file-validation-message {
  color: #b20040;
}
.k-upload .k-file-error .k-progressbar .k-selected {
  background-color: var(--kendo-color-error, #dc3545);
}
.k-upload .k-file-icon-wrapper {
  color: #999999;
  border-color: #999999;
}
.k-upload .k-file-size,
.k-upload .k-file-validation-message,
.k-upload .k-file-summary {
  color: var(--kendo-color-subtle, #596169);
}
.k-upload .k-multiple-files-wrapper .k-file-summary {
  color: #333333;
}
.k-upload.k-invalid, .k-upload.ng-invalid.ng-touched, .k-upload.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #dc3545);
}

.k-editor {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-editor.k-readonly .k-editor-content {
  pointer-events: auto;
}
.k-editor.k-readonly .k-editor-content.k-focus {
  outline-width: 1px;
  outline-style: dashed;
}

.k-editor-inline {
  border-radius: var(--kendo-border-radius-md, 3px);
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border: 1px solid transparent;
  word-wrap: break-word;
  overflow: auto;
  background: none;
}

.k-editor-resizable {
  resize: both;
  overflow: auto;
}

.k-editor-resize-handles-wrapper {
  position: absolute;
  visibility: hidden;
}

.k-editor-resize-handle {
  width: 8px;
  height: 8px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  position: absolute;
  visibility: visible;
  z-index: 100;
}
.k-editor-resize-handle.northwest {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: nw-resize;
}
.k-editor-resize-handle.north {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: n-resize;
}
.k-editor-resize-handle.northeast {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: ne-resize;
}
.k-editor-resize-handle.southwest {
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
  cursor: sw-resize;
}
.k-editor-resize-handle.south {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  cursor: s-resize;
}
.k-editor-resize-handle.southeast {
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  cursor: se-resize;
}
.k-editor-resize-handle.west {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: w-resize;
}
.k-editor-resize-handle.east {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  cursor: e-resize;
}

.ProseMirror-selectednode {
  outline-width: 2px;
  outline-style: solid;
}

.ProseMirror-hideselection {
  caret-color: transparent;
}
.ProseMirror-hideselection *::selection,
.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}

.k-editor-toolbar {
  border-width: 0 0 1px 0;
  flex-shrink: 0;
}
.k-editor-toolbar .k-editor-export .k-icon {
  margin-right: 0.25em;
}
.k-editor-toolbar .k-editor-export .k-icon,
.k-editor-toolbar .k-editor-export .k-export-tool-text {
  vertical-align: middle;
}

.k-editor-content {
  flex: 1 1 auto;
  overflow-y: auto;
}
.k-editor-content > .k-iframe {
  width: 100%;
  height: 100%;
  border-width: 0;
  display: block;
}
.k-editor-content > .ProseMirror {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  outline: none;
  white-space: pre-wrap;
  overflow: auto;
}
.k-editor-content > .ProseMirror table {
  white-space: pre-wrap;
}
.k-editor-content > .ProseMirror .k-placeholder::before {
  content: attr(data-placeholder);
  height: 0;
  color: #999999;
  float: left;
  opacity: 1;
  cursor: text;
  user-select: none;
}
.k-editor-content > .ProseMirror[contenteditable=false] {
  cursor: unset !important;
}
.k-editor-content > .ProseMirror[contenteditable=false] .k-editor-resize-handles-wrapper,
.k-editor-content > .ProseMirror[contenteditable=false] .k-editor-resize-handle,
.k-editor-content > .ProseMirror[contenteditable=false] .row-resize-handle,
.k-editor-content > .ProseMirror[contenteditable=false] .column-resize-handle {
  display: none;
}
.k-editor-content > .ProseMirror[contenteditable=false] img,
.k-editor-content > .ProseMirror[contenteditable=false] table {
  pointer-events: none;
}
.k-editor-content > .ProseMirror[contenteditable=false] .ProseMirror-selectednode {
  outline: none;
}
.k-editor-content p {
  margin: 0 0 1rem;
}
.k-editor-content td p,
.k-editor-content th p,
.k-editor-content li p {
  margin: 0;
}
.k-editor-content table {
  margin: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}
.k-editor-content td,
.k-editor-content th {
  min-width: 1em;
  border: 1px solid;
  padding-block: 3px;
  padding-inline: 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
.k-editor-content th {
  font-weight: bold;
  text-align: start;
}
.k-editor-content td {
  text-align: start;
}
.k-editor-content .selectedCell::after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.k-edit-form-content {
  flex: 1 1 auto;
  overflow: auto;
  margin-block: -var(--kendo-spacing-4, 1rem);
  margin-inline: -var(--kendo-spacing-4, 1rem);
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-ct-popup {
  box-sizing: border-box;
  width: 190px;
  padding: 5px;
}
.k-ct-popup .k-button {
  width: 100%;
}
.k-ct-popup .k-ct-cell {
  margin: 1px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 1px solid;
  border-color: inherit;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  opacity: 0.7;
  pointer-events: all;
}

.k-editor .k-resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 13px 13px;
  border-color: transparent;
  border-bottom-color: inherit;
  cursor: se-resize;
}
.k-editor .k-resize-handle .k-i-arrow-45-down-right,
.k-editor .k-resize-handle .k-i-caret-br {
  display: none;
}

.k-editor-find-replace .k-content {
  position: relative;
}
.k-editor-find-replace .k-actions, .k-editor-find-replace .k-edit-buttons,
.k-editor-find-replace .k-action-buttons,
.k-editor-find-replace .k-columnmenu-actions, .k-editor-find-replace .k-form-buttons {
  padding: 0;
}
.k-editor-find-replace .k-search-options {
  display: flex;
  flex-direction: column;
  padding: 10px 0 40px;
}
.k-editor-find-replace .k-matches-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.k-textarea.k-editor-textarea {
  width: 100%;
  height: 100%;
  resize: none;
}

.k-filebrowser {
  max-width: 100%;
}
.k-filebrowser .k-floatwrap {
  display: flex;
}
.k-filebrowser .k-floatwrap::after {
  display: none;
}
.k-filebrowser .k-breadcrumbs {
  flex: 1;
}
.k-filebrowser .k-search-wrap {
  margin: 0 0 0 var(--kendo-spacing-4, 1rem);
  width: 150px;
  display: flex;
  align-items: center;
}
.k-filebrowser .k-search-wrap .k-input {
  flex: 1;
  width: 100px;
}
.k-filebrowser .k-search-wrap .k-icon {
  margin: 0;
  position: static;
}
.k-filebrowser .k-filebrowser-toolbar {
  margin: var(--kendo-spacing-4, 1rem) 0 0 0;
}
.k-filebrowser .k-upload {
  padding: 0;
  border-width: 0;
  background: none;
}
.k-filebrowser .k-upload .k-upload-button {
  margin: 0;
}
.k-filebrowser .k-upload .k-upload-status {
  display: none;
}
.k-filebrowser .k-upload-files {
  display: none;
}
.k-filebrowser .k-tiles {
  display: flex;
  flex-flow: row wrap;
  height: 390px;
  max-height: 50vh;
  margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: 0.5rem;
  border-top-width: 0;
  overflow: auto;
}
.k-filebrowser .k-tile {
  width: 33%;
  height: 90px;
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: 0.5rem;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  border-radius: var(--kendo-border-radius-md, 3px);
}
.k-filebrowser .k-tile .k-i-file,
.k-filebrowser .k-tile .k-i-folder {
  font-size: 4em;
}
.k-filebrowser .k-tile input {
  width: 100px;
}
.k-filebrowser .k-tile strong {
  display: block;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-filebrowser .k-tile-empty {
  display: block;
  margin: auto;
}
.k-filebrowser .k-tile-empty.k-selected {
  color: inherit;
  border-width: 0;
  background-image: none;
  background-color: transparent;
}
.k-filebrowser .k-tile-empty strong {
  opacity: 0.5;
  font-size: 3em;
  font-weight: 400;
}
.k-filebrowser .k-thumb {
  float: left;
  margin-right: var(--kendo-spacing-2, 0.5rem);
}
.k-filebrowser .k-breadcrumbs-wrap {
  position: absolute;
  left: 0.75rem;
  top: 0.375rem;
}
.k-filebrowser .k-breadcrumbs-wrap .k-icon {
  position: static;
  margin-top: 0;
}

.k-editor-table-wizard-window .k-tabstrip-wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
}
.k-editor-table-wizard-window .k-tabstrip-wrapper .k-tabstrip.k-root-tabs {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.k-editor-table-wizard-window .k-edit-field > .k-checkbox {
  position: relative;
}

.k-editor-inline .k-table {
  width: 100%;
  border-spacing: 0;
  margin: 0 0 1em;
}
.k-editor-inline .k-table, .k-editor-inline .k-table td {
  outline: 0;
  border-width: 1px;
  border-style: dotted;
}
.k-editor-inline .k-table td {
  min-width: 1px;
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-editor-inline .k-element-resize-handle-wrapper {
  position: absolute;
  width: 5px;
  height: 5px;
  border-width: 1px;
  border-style: solid;
  z-index: 100;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle {
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-east {
  cursor: e-resize;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-north {
  cursor: n-resize;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-northeast {
  cursor: ne-resize;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-northwest {
  cursor: nw-resize;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-south {
  cursor: s-resize;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-southeast {
  cursor: se-resize;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-southwest {
  cursor: sw-resize;
}
.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-west {
  cursor: w-resize;
}
.k-editor-inline .k-column-resize-handle-wrapper {
  position: absolute;
  height: 10px;
  width: 10px;
  cursor: col-resize;
  z-index: 2;
}
.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle {
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle .k-column-resize-marker {
  width: 2px;
  height: 100%;
  margin-block: 0;
  margin-inline: auto;
  display: none;
  opacity: 0.8;
}
.k-editor-inline .k-row-resize-handle-wrapper {
  position: absolute;
  z-index: 2;
  cursor: row-resize;
  width: 10px;
  height: 10px;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-handle {
  display: table;
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker {
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2px;
  opacity: 0.8;
}

.k-editor {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-editor.k-readonly .k-editor-content.k-focus {
  outline-color: var(--kendo-color-on-app-surface, #212529);
}

.k-editor-inline .k-table, .k-editor-inline .k-table td {
  border-color: #cccccc;
}
.k-editor-inline .k-element-resize-handle-wrapper {
  background-color: #ffffff;
  border-color: #000000;
}
.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle .k-column-resize-marker {
  background-color: #00b0ff;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker {
  background-color: #00b0ff;
}
.k-editor-inline:hover, .k-editor-inline.k-hover, .k-editor-inline.k-active {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-editor-resize-handle {
  background-color: #ffffff;
  border-color: #000000;
}

.ProseMirror-selectednode {
  outline-color: #88ccff;
}

.k-ct-popup .k-selected {
  border-color: var(--kendo-color-primary, #0d6efd);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
  background-image: none;
}

.k-editor-content .k-text-selected, .k-editor-content::selection {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-editor-content .k-text-highlighted {
  background-color: var(--kendo-color-primary-subtle, #cfe2ff);
}
.k-editor-content td,
.k-editor-content th {
  border-color: #dddddd;
}
.k-editor-content .selectedCell::after {
  background-color: rgba(200, 200, 255, 0.4);
}

.k-scheduler {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-scheduler table,
.k-scheduler thead,
.k-scheduler tfoot,
.k-scheduler tbody,
.k-scheduler tr,
.k-scheduler th,
.k-scheduler td,
.k-scheduler div,
.k-scheduler .k-scheduler-edit-dialog,
.k-scheduler > * {
  border-color: inherit;
}

kendo-scheduler.k-scheduler {
  overflow: hidden;
}
kendo-scheduler.k-scheduler.k-readonly-scheduler .k-event-delete {
  display: none;
}

.k-scheduler-table {
  width: 100%;
  max-width: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.k-scheduler-table td,
.k-scheduler-table th {
  padding-block: 2px;
  padding-inline: var(--kendo-spacing-3, 0.75rem);
  height: calc(1.4285714286 * 1em);
  overflow: hidden;
  white-space: nowrap;
  border-style: solid;
  border-width: 0 0 1px 1px;
  vertical-align: top;
  box-sizing: content-box;
}
.k-scheduler-table td:first-child,
.k-scheduler-table th:first-child {
  border-left-width: 0;
}
.k-scheduler-table .k-middle-row td {
  border-bottom-style: dotted;
}
.k-scheduler-table .k-link {
  cursor: pointer;
}

.k-scheduler-layout-flex {
  overflow: auto;
}
.k-scheduler-layout-flex .k-scheduler-head {
  position: sticky;
  top: 0;
  z-index: 3;
}
.k-scheduler-layout-flex .k-scheduler-body {
  position: relative;
}
.k-scheduler-layout-flex .k-scheduler-head,
.k-scheduler-layout-flex .k-scheduler-body {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
}
.k-scheduler-layout-flex .k-scheduler-row {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  min-width: 0;
}
.k-scheduler-layout-flex .k-scheduler-group {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  min-width: 0;
  flex-wrap: wrap;
}
.k-scheduler-layout-flex .k-scheduler-group .k-group-cell {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: auto;
}
.k-scheduler-layout-flex .k-scheduler-group .k-group-content {
  padding: 0;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  border-width: 0;
  flex-wrap: wrap;
}
.k-scheduler-layout-flex .k-scheduler-group.k-group-horizontal .k-group-cell {
  flex-basis: 100%;
  border-width: 0;
  padding: 0;
}
.k-scheduler-layout-flex.k-scheduler-timeline-view .k-scheduler-body .k-scheduler-cell {
  flex-basis: auto;
}
.k-scheduler-layout-flex .k-more-events {
  bottom: 2px;
  left: 0;
  width: 100%;
}
.k-scheduler-layout-flex .k-scheduler-cell {
  display: flex;
  flex: 1 1 100%;
  padding-block: 2px;
  padding-inline: 2px;
  min-height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  border-style: solid;
  border-width: 0 1px 1px 0;
  vertical-align: top;
  box-sizing: content-box;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-slot-cell {
  position: relative;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-heading-cell {
  justify-content: center;
  font-weight: bold;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-side-cell {
  justify-content: flex-end;
  flex-grow: 0;
  flex-basis: auto;
  overflow: visible;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-major-cell {
  border-bottom-width: 0;
}
.k-scheduler-layout-flex .k-middle-row .k-scheduler-cell {
  border-bottom-style: dotted;
}
.k-scheduler-layout-flex .k-resource-cell {
  flex: none;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
}
.k-scheduler-layout-flex .k-resource-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border-width: 0;
}
.k-scheduler-layout-flex .k-sticky-cell {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 0;
  position: sticky;
  left: 0;
  z-index: 3;
}

.k-scheduler-toolbar {
  border-width: 0;
  border-bottom-width: 1px;
  white-space: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}
.k-scheduler-toolbar .k-widget {
  font-size: inherit;
}
.k-scheduler-toolbar .k-nav-current {
  color: inherit;
  outline: 0;
  text-decoration: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  cursor: pointer;
}
.k-scheduler-toolbar .k-scheduler-search {
  display: inline-flex;
  flex-flow: row nowrap;
}

.k-scheduler-toolbar .k-views-dropdown {
  width: auto;
  font: inherit;
}

.k-scheduler-footer {
  box-shadow: none;
  border-width: 0;
  border-top-width: 1px;
  white-space: nowrap;
  flex-shrink: 0;
  position: relative;
}

.k-scheduler-layout {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
}
.k-scheduler-layout > tbody > tr > td {
  padding: 0;
  vertical-align: top;
}
.k-scheduler-layout td.k-selected,
.k-scheduler-layout .k-scheduler-cell.k-selected {
  outline: none;
}
.k-scheduler-layout tr + tr .k-scheduler-times tr th,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr th {
  border-bottom-color: transparent;
}
.k-scheduler-layout tr + tr .k-scheduler-times tr .k-slot-cell,
.k-scheduler-layout tr + tr .k-scheduler-times tr .k-scheduler-times-all-day, .k-scheduler-layout tr + tr .k-scheduler-times tr:last-child th,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr .k-slot-cell,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr .k-scheduler-times-all-day,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr:last-child th {
  border-bottom-color: inherit;
}

.k-scheduler-layout.k-scheduler-flex-layout {
  display: flex;
  flex-direction: column;
}
.k-scheduler-layout.k-scheduler-flex-layout.k-scheduler-weekview .k-scheduler-pane:first-child .k-scheduler-table, .k-scheduler-layout.k-scheduler-flex-layout.k-scheduler-dayview .k-scheduler-pane:first-child .k-scheduler-table {
  table-layout: fixed;
}

.k-scheduler-header,
.k-scheduler-view-header {
  padding-inline-end: var(--kendo-scrollbar-width, 17px);
}

.k-scheduler-header th {
  text-align: center;
}

.k-scheduler-header,
.k-scheduler-header-wrap,
.k-scheduler-header-wrap > div {
  border-color: inherit;
  overflow: hidden;
}

.k-scheduler-header-wrap {
  border-width: 0;
  border-style: solid;
  position: relative;
}

.k-scheduler-times {
  border-color: inherit;
  position: relative;
  overflow: hidden;
}
.k-scheduler-times .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-times th {
  border-width: 0 1px 1px 0;
  text-align: end;
}
.k-scheduler-times .k-slot-cell,
.k-scheduler-times .k-scheduler-times-all-day {
  border-bottom-color: inherit;
}
.k-scheduler-times .k-slot-cell.k-last-resource {
  border-right: 0;
}
.k-scheduler-times .k-slot-cell.k-empty-slot {
  padding-left: 0;
  padding-right: 0;
}

.k-scheduler-datecolumn {
  width: 12em;
}

.k-scheduler-timecolumn {
  width: 11em;
  white-space: nowrap;
}

.k-scheduler-content {
  border-color: inherit;
  position: relative;
  overflow: auto;
}
.k-scheduler-content:focus {
  outline: none;
}

kendo-scheduler .k-event,
.k-event {
  border-radius: var(--kendo-border-radius-md, 3px);
  min-height: 25px;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-radius: var(--kendo-border-radius-md, 3px);
  text-align: start;
  outline: 0;
  cursor: default;
  position: absolute;
  overflow: hidden;
  padding-right: 0.5rem;
}
kendo-scheduler .k-event > div,
.k-event > div {
  position: relative;
  z-index: 2;
}
kendo-scheduler .k-event .k-event-template,
.k-event .k-event-template {
  line-height: calc(25px - 2 * var(--kendo-spacing-1, 0.25rem));
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: 0.5rem;
}
kendo-scheduler .k-event .k-event-time,
.k-event .k-event-time {
  padding-bottom: 0;
  font-size: 0.875em;
  white-space: nowrap;
  display: none;
}
kendo-scheduler .k-event .k-event-actions,
.k-event .k-event-actions {
  white-space: nowrap;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  z-index: 2;
}
kendo-scheduler .k-event .k-event-actions .k-event-delete,
.k-event .k-event-actions .k-event-delete {
  opacity: 0.5;
  visibility: hidden;
}
kendo-scheduler .k-event .k-event-actions:hover .k-event-delete, kendo-scheduler .k-event .k-event-actions.k-hover .k-event-delete,
.k-event .k-event-actions:hover .k-event-delete,
.k-event .k-event-actions.k-hover .k-event-delete {
  opacity: 1;
}
kendo-scheduler .k-event .k-event-actions a,
.k-event .k-event-actions a {
  color: inherit;
}
kendo-scheduler .k-event .k-event-actions:first-child,
.k-event .k-event-actions:first-child {
  margin: var(--kendo-spacing-1, 0.25rem) 0.4ex 0 var(--kendo-spacing-2, 0.5rem);
  top: 0;
  right: 0;
  float: left;
  position: relative;
  opacity: 1;
  visibility: visible;
  line-height: normal;
}
kendo-scheduler .k-event .k-resize-handle,
.k-event .k-resize-handle {
  z-index: 4;
  opacity: 0.5;
  visibility: hidden;
}
kendo-scheduler .k-event .k-resize-handle::before,
.k-event .k-resize-handle::before {
  border-color: currentColor;
}
kendo-scheduler .k-event .k-resize-n,
.k-event .k-resize-n {
  height: 0.5em;
  top: 0;
}
kendo-scheduler .k-event .k-resize-s,
.k-event .k-resize-s {
  height: 0.5em;
  bottom: 0;
}
kendo-scheduler .k-event .k-resize-n::before,
kendo-scheduler .k-event .k-resize-s::before,
.k-event .k-resize-n::before,
.k-event .k-resize-s::before {
  width: var(--kendo-spacing-8, 2rem);
  border-bottom-width: 1px;
}
kendo-scheduler .k-event .k-resize-w,
.k-event .k-resize-w {
  width: 0.5em;
  left: 0;
}
kendo-scheduler .k-event .k-resize-e,
.k-event .k-resize-e {
  width: 0.5em;
  right: 0;
}
kendo-scheduler .k-event .k-resize-w::before,
kendo-scheduler .k-event .k-resize-e::before,
.k-event .k-resize-w::before,
.k-event .k-resize-e::before {
  height: var(--kendo-spacing-8, 2rem);
  border-left-width: 1px;
}
kendo-scheduler .k-event:hover .k-event-actions .k-event-delete,
kendo-scheduler .k-event:hover .k-resize-handle, kendo-scheduler .k-event.k-hover .k-event-actions .k-event-delete,
kendo-scheduler .k-event.k-hover .k-resize-handle,
.k-event:hover .k-event-actions .k-event-delete,
.k-event:hover .k-resize-handle,
.k-event.k-hover .k-event-actions .k-event-delete,
.k-event.k-hover .k-resize-handle {
  visibility: visible;
}
kendo-scheduler .k-event.k-event-drag-hint .k-event-time,
.k-event.k-event-drag-hint .k-event-time {
  display: block;
}
kendo-scheduler .k-event .k-event-top-actions,
kendo-scheduler .k-event .k-event-bottom-actions,
.k-event .k-event-top-actions,
.k-event .k-event-bottom-actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
kendo-scheduler .k-event .k-event-bottom-actions,
.k-event .k-event-bottom-actions {
  top: auto;
  bottom: 0;
}

.k-scheduler-mark {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}

.k-more-events {
  padding: 0;
  height: 13px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  position: absolute;
  justify-content: center;
}
.k-more-events > .k-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-event-drag-hint {
  opacity: 0.5;
  z-index: 3;
}
.k-event-drag-hint .k-event-actions,
.k-event-drag-hint .k-event-top-actions,
.k-event-drag-hint .k-event-bottom-actions,
.k-event-drag-hint .k-resize-handle {
  display: none;
}
.k-event-drag-hint .k-event-time {
  display: block;
}

.k-scheduler-marquee {
  border-width: 0;
  border-style: solid;
}
.k-scheduler-marquee .k-label-top,
.k-scheduler-marquee .k-label-bottom {
  font-size: 0.75em;
  position: absolute;
}
.k-scheduler-marquee .k-label-top {
  left: var(--kendo-spacing-2, 0.5rem);
  top: var(--kendo-spacing-1, 0.25rem);
}
.k-scheduler-marquee .k-label-bottom {
  right: var(--kendo-spacing-2, 0.5rem);
  bottom: var(--kendo-spacing-1, 0.25rem);
}
.k-scheduler-marquee.k-first::before, .k-scheduler-marquee.k-last::after {
  content: "";
  border-width: 3px;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
}
.k-scheduler-marquee.k-first::before {
  top: 0;
  left: 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.k-scheduler-marquee.k-last::after {
  bottom: 0;
  right: 0;
  border-left-color: transparent;
  border-top-color: transparent;
}

.k-pdf-export-shadow .k-scheduler,
.k-scheduler-pdf-export .k-scheduler-content,
.k-scheduler-pdf-export .k-scheduler-times {
  height: auto !important;
  overflow: visible !important;
}

.k-scheduler-pdf-export {
  overflow: hidden;
}
.k-scheduler-pdf-export .k-scheduler-header {
  padding: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-header-wrap {
  border-width: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-header .k-scheduler-table,
.k-scheduler-pdf-export .k-scheduler-content .k-scheduler-table {
  width: 100% !important;
}

.k-recurrence-editor {
  display: flex;
  flex-direction: column;
}

kendo-scheduler .k-recurrence-editor {
  display: block;
}

.k-scheduler-monthview .k-scheduler-table {
  height: 100%;
}
.k-scheduler-monthview .k-scheduler-table td {
  height: 80px;
  text-align: end;
}
.k-scheduler-monthview .k-scheduler-body .k-scheduler-cell {
  min-height: 80px;
}
.k-scheduler-monthview .k-hidden {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-right-width: 0 !important;
}
.k-scheduler-monthview .k-scheduler-table-auto,
.k-scheduler-monthview .k-scheduler-table-auto td,
.k-scheduler-monthview .k-scheduler-content .k-scheduler-table-auto {
  height: auto;
}
.k-scheduler-monthview .k-scheduler-content {
  overflow-y: scroll;
}
.k-scheduler-monthview.k-scheduler-flex-layout .k-scheduler-content {
  overflow-y: auto;
}

.k-scheduler-agendaview .k-scheduler-mark {
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}
.k-scheduler-agendaview .k-scheduler-table th:first-child,
.k-scheduler-agendaview .k-scheduler-table td:first-child {
  border-left-width: 1px;
}
.k-scheduler-agendaview .k-scheduler-table td.k-first {
  border-left-width: 0;
}
.k-scheduler-agendaview .k-task > .k-event-delete {
  color: inherit;
  position: absolute;
  top: var(--kendo-spacing-1, 0.25rem);
  right: var(--kendo-spacing-1, 0.25rem);
  opacity: 0.5;
  visibility: hidden;
}
.k-scheduler-agendaview .k-hover .k-task > .k-event-delete,
.k-scheduler-agendaview .k-scheduler-content tr:hover .k-event-delete,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row:hover .k-event-delete,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row.k-hover .k-event-delete {
  visibility: visible;
}

.k-scheduler-agendaday {
  margin: 0 0.2em 0 0;
  font-size: 3em;
  line-height: 1;
  font-weight: 400;
  float: left;
}

.k-scheduler-agendaweek {
  display: block;
  margin: 0.4em 0 0;
  line-height: 1;
  font-style: normal;
}

.k-scheduler-agendadate {
  font-size: 0.75em;
}

.k-scheduler-timelineview .k-slot-cell {
  overflow: hidden;
}
.k-scheduler-timelineview .k-scheduler-content {
  overflow: auto;
}

.k-scheduler-pane {
  display: flex;
  flex-direction: row;
}

.k-scheduler-pane .k-scheduler-times {
  flex: 0 0 auto;
}

.k-scheduler-pane .k-scheduler-times .k-scheduler-table {
  height: 100%;
}

.k-scheduler-pane .k-scheduler-header,
.k-scheduler-pane .k-scheduler-content {
  flex: 1 1 auto;
}

.k-scheduler-yearview .k-scheduler-body {
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  justify-content: center;
}
.k-scheduler-yearview .k-calendar {
  width: 100%;
  border-width: 0;
}
.k-scheduler-yearview .k-calendar .k-calendar-view {
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--kendo-spacing-4, 1rem);
}
.k-scheduler-yearview .k-calendar .k-content {
  flex: 0;
}
.k-scheduler-yearview .k-calendar .k-link {
  position: relative;
}
.k-scheduler-yearview .k-calendar td.k-selected {
  background-color: inherit;
}
.k-scheduler-yearview .k-day-indicator {
  margin-top: calc(32px - var(--kendo-spacing-1, 0.25rem) * 2);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 1.5px);
}

.k-scheduler-tooltip {
  padding-block: var(--kendo-spacing-3, 0.75rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
  border-width: 0;
  color: inherit;
}
.k-scheduler-tooltip .k-tooltip-title {
  margin-bottom: var(--kendo-spacing-3, 0.75rem);
}
.k-scheduler-tooltip .k-tooltip-title .k-month {
  font-size: var(--kendo-font-size-sm, inherit);
  text-transform: uppercase;
}
.k-scheduler-tooltip .k-tooltip-title .k-day {
  font-size: calc(var(--kendo-font-size-sm, 0.765625rem) * 2);
}
.k-scheduler-tooltip .k-tooltip-events-container {
  overflow: auto;
}
.k-scheduler-tooltip .k-tooltip-events {
  max-height: 250px;
  display: flex;
  flex-direction: column;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-scheduler-tooltip .k-tooltip-event {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-radius: var(--kendo-border-radius-md, 3px);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-scheduler-tooltip .k-tooltip-event .k-event-time {
  display: flex;
  flex-shrink: 0;
  font-size: inherit;
}
.k-scheduler-tooltip .k-no-data, .k-scheduler-tooltip .k-nodata {
  height: auto;
  min-height: auto;
  color: inherit;
}

.k-scheduler-edit-dialog .k-dialog {
  max-height: 100vh;
}

.k-scheduler-edit-form .k-edit-form-container {
  width: 100%;
}
.k-scheduler-edit-form .k-edit-label {
  width: 17%;
}
.k-scheduler-edit-form .k-edit-field {
  width: 77%;
}
.k-scheduler-edit-form .k-edit-field > ul > li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.k-scheduler-edit-form .k-recurrence-editor .k-radio-list .k-radio-wrap,
.k-scheduler-edit-form .k-recurrence-editor .k-checkbox-list .k-checkbox-wrap {
  align-self: center;
}
.k-scheduler-edit-form .k-recur-interval,
.k-scheduler-edit-form .k-recur-count,
.k-scheduler-edit-form .k-recur-monthday {
  width: 5em;
}
.k-scheduler-edit-form .k-recur-until,
.k-scheduler-edit-form .k-recur-month,
.k-scheduler-edit-form .k-recur-weekday,
.k-scheduler-edit-form .k-recur-weekday-offset {
  width: 10em;
}
.k-scheduler-edit-form .k-scheduler-datetime-picker {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--kendo-spacing-2, 0.5rem);
}

.k-rtl .k-scheduler-header th,
.k-rtl .k-scheduler-table td {
  border-width: 0 1px 1px 0;
}
.k-rtl .k-scheduler-table td:first-child,
.k-rtl .k-scheduler-table th:first-child {
  border-right-width: 0;
}
.k-rtl .k-scheduler-times th {
  border-width: 0 0 1px 1px;
}
.k-rtl .k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
  border-right-width: 0;
  border-left-width: 1px;
}
.k-rtl .k-event {
  padding-right: 0;
  padding-left: 0.5rem;
}
.k-rtl .k-event .k-resize-w {
  left: auto;
  right: 0;
}
.k-rtl .k-event .k-resize-e {
  right: auto;
  left: 0;
}
.k-rtl .k-event .k-event-actions {
  right: auto;
  left: 2px;
}
.k-rtl .k-event .k-event-actions:first-child {
  margin: var(--kendo-spacing-1, 0.25rem) var(--kendo-spacing-2, 0.5rem) 0 0.4ex;
  float: right;
}
.k-rtl .k-scheduler-agendaview .k-task > .k-event-delete {
  left: 2px;
  right: auto;
}
.k-rtl .k-scheduler-agendaview .k-task .k-scheduler-mark {
  margin-left: 0.5em;
  margin-right: 0;
}
.k-rtl .k-scheduler-marquee .k-label-top {
  left: auto;
  right: 4px;
}
.k-rtl .k-scheduler-marquee .k-label-bottom {
  left: 4px;
  right: auto;
}
.k-rtl .k-scheduler-edit-form .k-scheduler-delete {
  float: right;
}
.k-rtl .k-scheduler .k-scheduler-header-wrap, .k-rtl.k-scheduler .k-scheduler-header-wrap {
  border-right-width: 0;
}
.k-rtl .k-scheduler .k-scheduler-agendaday, .k-rtl.k-scheduler .k-scheduler-agendaday {
  float: right;
  margin: 0 0 0 0.2em;
}
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table td:first-child,
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table th:first-child, .k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table td:first-child,
.k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table th:first-child {
  border-left-width: 0;
}
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr td:first-child,
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr th:first-child, .k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr td:first-child,
.k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr th:first-child {
  border-right-width: 1px;
}

.k-safari .k-scheduler-tooltip .k-tooltip-event:not(:last-child) {
  margin-bottom: var(--kendo-spacing-2, 0.5rem);
}
.k-safari .k-scheduler-yearview .k-calendar-view .k-month {
  margin-block: 0;
  margin-inline: var(--kendo-spacing-4, 1rem)/2;
}

kendo-scheduler .k-event::before,
.k-event::before {
  display: none;
  left: 0;
}
.k-rtl kendo-scheduler .k-event::before,
.k-rtl .k-event::before {
  right: 0;
}
kendo-scheduler .k-event .k-event-template,
.k-event .k-event-template {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  font-size: var(--kendo-font-size-sm, inherit);
}
kendo-scheduler .k-event .k-event-actions,
.k-event .k-event-actions {
  right: var(--kendo-spacing-2, 0.5rem);
}
kendo-scheduler .k-event .k-event-actions:first-child,
.k-event .k-event-actions:first-child {
  margin: 0.1ex 0.4ex 0 var(--kendo-spacing-2, 0.5rem);
}

.k-scheduler {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #ffffff;
}

.k-scheduler-toolbar {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #ffffff;
}

.k-scheduler-footer {
  border-color: #c2c2c2;
  color: #333333;
  background-color: #f8f8f8;
}

.k-scheduler-header {
  background-color: var(--kendo-color-surface, #f8f9fa);
}

.k-scheduler-header-wrap {
  background-color: #ffffff;
}

.k-scheduler-nonwork,
.k-scheduler .k-nonwork-hour {
  background-color: #ffffff;
}

.k-scheduler-other-month,
.k-scheduler .k-other-month {
  background-color: transparent;
}

.k-scheduler-layout td.k-selected,
.k-scheduler-layout .k-scheduler-cell.k-selected {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}

.k-scheduler-layout-flex .k-scheduler-head,
.k-scheduler-layout-flex .k-sticky-cell {
  background-color: #ffffff;
}

.k-event {
  color: #333333;
  background-color: transparent;
}
.k-event.k-selected {
  color: #333333;
  background-color: transparent;
  box-shadow: none;
}
.k-event.k-event-inverse {
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-event.k-event-ongoing {
  box-shadow: inset 0px 0px 0px 1px #ff0000;
}

.k-scheduler-marquee::before,
.k-scheduler-marquee::after {
  border-color: var(--kendo-color-primary, #0d6efd);
}

.k-scheduler-edit-dialog .k-dialog-actions {
  border-color: #e6e6e6;
}

.k-scheduler-agendaview .k-scheduler-content tr:hover,
.k-scheduler-agendaview .k-scheduler-content tr.k-hover,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row:hover,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row.k-hover {
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-base-subtle-hover, #dee2e6);
}
.k-scheduler-agendaview .k-scheduler-content tr:hover .k-scheduler-datecolumn,
.k-scheduler-agendaview .k-scheduler-content tr:hover .k-scheduler-groupcolumn,
.k-scheduler-agendaview .k-scheduler-content tr.k-hover .k-scheduler-datecolumn,
.k-scheduler-agendaview .k-scheduler-content tr.k-hover .k-scheduler-groupcolumn {
  border-color: #e6e6e6;
  color: #333333;
  background-color: #ffffff;
}
.k-scheduler-agendaview .k-scheduler-content tr.k-selected {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}
.k-scheduler-agendaview .k-scheduler-content tr.k-selected .k-scheduler-datecolumn,
.k-scheduler-agendaview .k-scheduler-content tr.k-selected .k-scheduler-groupcolumn {
  background-color: #ffffff;
}

.k-scheduler-yearview .k-day-indicator {
  background-color: var(--kendo-color-primary, #0d6efd);
}
.k-scheduler-yearview .k-selected .k-day-indicator {
  background-color: var(--kendo-color-on-primary, #ffffff);
}

.k-tooltip.k-scheduler-tooltip {
  color: var(--kendo-color-on-app-surface, #212529);
  background-color: var(--kendo-color-on-primary, #ffffff);
  box-shadow: var(--kendo-elevation-2, 0px 2px 7px rgba(0, 0, 0, 0.075));
}
.k-tooltip.k-scheduler-tooltip .k-callout {
  color: var(--kendo-color-on-primary, #ffffff);
}

.k-loader {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  line-height: 0;
}

.k-loader-canvas {
  display: flex;
  position: relative;
}

.k-loader-segment {
  border-radius: 50%;
  background-color: currentColor;
  position: absolute;
}

.k-loader-sm {
  padding: calc(var(--kendo-spacing-1, 0.25rem) / 2);
}
.k-loader-sm .k-loader-segment {
  width: var(--kendo-spacing-1, 0.25rem);
  height: var(--kendo-spacing-1, 0.25rem);
}

.k-loader-md {
  padding: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}
.k-loader-md .k-loader-segment {
  width: var(--kendo-spacing-2, 0.5rem);
  height: var(--kendo-spacing-2, 0.5rem);
}

.k-loader-lg {
  padding: calc(var(--kendo-spacing-4, 1rem) / 2);
}
.k-loader-lg .k-loader-segment {
  width: var(--kendo-spacing-4, 1rem);
  height: var(--kendo-spacing-4, 1rem);
}

@keyframes pulsing-2-segment {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.k-loader-pulsing-2 .k-loader-canvas {
  align-items: center;
}
.k-loader-pulsing-2 .k-loader-segment:nth-child(1) {
  left: 0;
  transform: scale(1.5);
  transform-origin: 0 50%;
  animation: pulsing-2-segment 1s ease infinite;
  animation-delay: -0.5s;
}
.k-loader-pulsing-2 .k-loader-segment:nth-child(2) {
  right: 0;
  transform-origin: 100% 50%;
  animation: pulsing-2-segment 1s ease infinite;
}
.k-loader-pulsing-2.k-loader-sm .k-loader-canvas {
  width: calc(var(--kendo-spacing-1, 0.25rem) * 3);
  height: calc(var(--kendo-spacing-1, 0.25rem) * 1.5);
}
.k-loader-pulsing-2.k-loader-md .k-loader-canvas {
  width: calc(var(--kendo-spacing-2, 0.5rem) * 3);
  height: calc(var(--kendo-spacing-2, 0.5rem) * 1.5);
}
.k-loader-pulsing-2.k-loader-lg .k-loader-canvas {
  width: calc(var(--kendo-spacing-4, 1rem) * 3);
  height: calc(var(--kendo-spacing-4, 1rem) * 1.5);
}

@keyframes spinner-3-segment {
  0% {
    top: 0;
    left: 50%;
  }
  33.3333% {
    top: 100%;
    left: 0;
  }
  66.6666% {
    top: 100%;
    left: 100%;
  }
  100% {
    top: 0;
    left: 50%;
  }
}
.k-loader-spinner-3 .k-loader-segment {
  transform: translate(-50%, -50%);
}
.k-loader-spinner-3 .k-loader-segment:nth-child(1) {
  top: 0;
  left: 50%;
  animation: spinner-3-segment 2s ease infinite;
}
.k-loader-spinner-3 .k-loader-segment:nth-child(2) {
  top: 100%;
  left: 0;
  animation: spinner-3-segment 2s ease infinite;
  animation-delay: -0.6666666667s;
}
.k-loader-spinner-3 .k-loader-segment:nth-child(3) {
  top: 100%;
  left: 100%;
  animation: spinner-3-segment 2s ease infinite;
  animation-delay: -1.3333333333s;
}
.k-loader-spinner-3.k-loader-sm .k-loader-canvas {
  margin: calc(var(--kendo-spacing-1, 0.25rem) / 2);
  width: calc(calc(var(--kendo-spacing-1, 0.25rem) * 4) - var(--kendo-spacing-1, 0.25rem));
  height: calc(calc(calc(var(--kendo-spacing-1, 0.25rem) * 4) * 0.8660254038) - var(--kendo-spacing-1, 0.25rem));
}
.k-loader-spinner-3.k-loader-md .k-loader-canvas {
  margin: calc(var(--kendo-spacing-2, 0.5rem) / 2);
  width: calc(calc(var(--kendo-spacing-2, 0.5rem) * 4) - var(--kendo-spacing-2, 0.5rem));
  height: calc(calc(calc(var(--kendo-spacing-2, 0.5rem) * 4) * 0.8660254038) - var(--kendo-spacing-2, 0.5rem));
}
.k-loader-spinner-3.k-loader-lg .k-loader-canvas {
  margin: calc(var(--kendo-spacing-4, 1rem) / 2);
  width: calc(calc(var(--kendo-spacing-4, 1rem) * 4) - var(--kendo-spacing-4, 1rem));
  height: calc(calc(calc(var(--kendo-spacing-4, 1rem) * 4) * 0.8660254038) - var(--kendo-spacing-4, 1rem));
}

@keyframes spinner-4-segment-1 {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  33.3% {
    top: 0;
    left: 0;
    transform: translateX(0) translateY(0);
  }
  66.6% {
    top: 0;
    left: 0;
    transform: translateX(0) translateY(0);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
@keyframes spinner-4-segment-2 {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  33.3% {
    top: 0;
    left: 100%;
    transform: translateX(-100%) translateY(0);
  }
  66.6% {
    top: 0;
    left: 100%;
    transform: translateX(-100%) translateY(0);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
@keyframes spinner-4-segment-3 {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  33.3% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%) translateY(-100%);
  }
  66.6% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%) translateY(-100%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
@keyframes spinner-4-segment-4 {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  33.3% {
    top: 100%;
    left: 0;
    transform: translateX(0) translateY(-100%);
  }
  66.6% {
    top: 100%;
    left: 0;
    transform: translateX(0) translateY(-100%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
@keyframes spinner-4 {
  0% {
    transform: rotate(0);
  }
  33.3% {
    transform: rotate(0);
  }
  66.6% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.k-loader-spinner-4 .k-loader-canvas {
  animation: spinner-4 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.k-loader-spinner-4 .k-loader-segment:nth-child(1) {
  animation: spinner-4-segment-1 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment:nth-child(2) {
  animation: spinner-4-segment-2 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment:nth-child(3) {
  animation: spinner-4-segment-3 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment:nth-child(4) {
  animation: spinner-4-segment-4 1.833s ease infinite;
}
.k-loader-spinner-4.k-loader-sm .k-loader-canvas {
  width: calc(var(--kendo-spacing-1, 0.25rem) * 4);
  height: calc(var(--kendo-spacing-1, 0.25rem) * 4);
}
.k-loader-spinner-4.k-loader-md .k-loader-canvas {
  width: calc(var(--kendo-spacing-2, 0.5rem) * 4);
  height: calc(var(--kendo-spacing-2, 0.5rem) * 4);
}
.k-loader-spinner-4.k-loader-lg .k-loader-canvas {
  width: calc(var(--kendo-spacing-4, 1rem) * 4);
  height: calc(var(--kendo-spacing-4, 1rem) * 4);
}

.k-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20001;
}
.k-loader-container .k-loader {
  padding: 0;
}

.k-loader-container,
.k-loader-container-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.k-loader-container-overlay {
  opacity: 0.5;
}

.k-loader-container-inner {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.k-loader-container-panel {
  border-width: 1px;
  border-style: solid;
  border-radius: var(--kendo-border-radius-md, 3px);
}

.k-loader-container-sm .k-loader-container-inner {
  padding: var(--kendo-spacing-4, 1rem);
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-loader-container-sm .k-loader-container-label {
  font-size: var(--kendo-font-size-sm, inherit);
}

.k-loader-container-md .k-loader-container-inner {
  padding: var(--kendo-spacing-5, 1.25rem);
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-loader-container-md .k-loader-container-label {
  font-size: var(--kendo-font-size, inherit);
}

.k-loader-container-lg .k-loader-container-inner {
  padding: var(--kendo-spacing-6, 1.5rem);
  gap: var(--kendo-spacing-3, 0.75rem);
}
.k-loader-container-lg .k-loader-container-label {
  font-size: var(--kendo-font-size-lg, inherit);
}

.k-loader-top .k-loader-container-inner {
  flex-direction: column;
  text-align: center;
}

.k-loader-end .k-loader-container-label {
  order: -1;
}

.k-loading-mask,
.k-loading-image,
.k-loading-color {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}
.k-loading-mask *,
.k-loading-mask *::before,
.k-loading-mask *::after, .k-loading-mask::before, .k-loading-mask::after,
.k-loading-image *,
.k-loading-image *::before,
.k-loading-image *::after,
.k-loading-image::before,
.k-loading-image::after,
.k-loading-color *,
.k-loading-color *::before,
.k-loading-color *::after,
.k-loading-color::before,
.k-loading-color::after {
  box-sizing: border-box;
}

.k-loading-mask {
  z-index: 100;
}
.k-loading-mask.k-opaque .k-loading-color {
  opacity: 1;
}

.k-loading-text {
  text-indent: -4000px;
  text-align: center;
  position: absolute;
  color: currentColor;
}

.k-loading-image {
  z-index: 2;
  color: currentColor;
}

.k-loading-color {
  background-color: var(--kendo-color-surface-alt, #ffffff);
  opacity: 0.3;
}

.k-i-loading {
  width: 1em;
  height: 1em;
  line-height: 1;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  box-sizing: border-box;
  color: currentColor;
}
.k-i-loading::before, .k-i-loading::after {
  box-sizing: border-box;
}

.k-i-loading::before,
.k-i-loading::after,
.k-loading-image::before,
.k-loading-image::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: 0.05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}

.k-icon.k-i-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading::before,
.k-loading-image::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation 0.7s linear infinite;
}

.k-i-loading::after,
.k-loading-image::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 0.5em;
  height: 0.5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.k-loading-image::before,
.k-loading-image::after {
  content: "";
  border-width: 1px;
  border-width: clamp(0.015em, 1px, 1px);
  font-size: 4em;
}

@keyframes k-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.k-i-loading::before,
.k-loading-image::before {
  border-color: currentColor;
  border-right-color: transparent !important;
}
.k-i-loading::after,
.k-loading-image::after {
  display: none !important;
}

.k-loading-image::before {
  border-width: var(--kendo-spacing-1, 0.25rem);
}

.k-loader-primary {
  color: var(--kendo-color-primary, #0d6efd);
}

.k-loader-secondary {
  color: #212529;
}

.k-loader-tertiary {
  color: var(--kendo-color-tertiary, #6f42c1);
}

.k-loader-info {
  color: var(--kendo-color-info, #0dcaf0);
}

.k-loader-success {
  color: var(--kendo-color-success, #198754);
}

.k-loader-warning {
  color: var(--kendo-color-warning, #ffc107);
}

.k-loader-error {
  color: var(--kendo-color-error, #dc3545);
}

.k-loader-dark {
  color: var(--kendo-color-dark, #212529);
}

.k-loader-light {
  color: var(--kendo-color-light, #f8f9fa);
}

.k-loader-inverse {
  color: var(--kendo-color-dark, #212529);
}

.k-loader-container-panel {
  background-color: #ffffff;
  border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.k-loader-container-inner .k-text-secondary,
.k-loader-container-inner .\!k-text-secondary {
  color: #212529 !important;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.bg-primary {
  background-color: #005d75 !important;
  fill: #005d75 !important;
}

.border-primary {
  border-color: #005d75 !important;
  stroke: #005d75 !important;
}

.text-primary {
  color: #005d75 !important;
  fill: #005d75 !important;
}

.text-primary-hover:hover {
  color: #005d75 !important;
  fill: #005d75 !important;
}

.bg-primary-hover:hover {
  background-color: #005d75 !important;
}

.border-primary-hover:hover {
  border-color: #005d75 !important;
}

.bg-neutral {
  background-color: #333333 !important;
  fill: #333333 !important;
}

.border-neutral {
  border-color: #333333 !important;
  stroke: #333333 !important;
}

.text-neutral {
  color: #333333 !important;
  fill: #333333 !important;
}

.text-neutral-hover:hover {
  color: #333333 !important;
  fill: #333333 !important;
}

.bg-neutral-hover:hover {
  background-color: #333333 !important;
}

.border-neutral-hover:hover {
  border-color: #333333 !important;
}

.bg-success {
  background-color: #3c9d4d !important;
  fill: #3c9d4d !important;
}

.border-success {
  border-color: #3c9d4d !important;
  stroke: #3c9d4d !important;
}

.text-success {
  color: #3c9d4d !important;
  fill: #3c9d4d !important;
}

.text-success-hover:hover {
  color: #3c9d4d !important;
  fill: #3c9d4d !important;
}

.bg-success-hover:hover {
  background-color: #3c9d4d !important;
}

.border-success-hover:hover {
  border-color: #3c9d4d !important;
}

.bg-warning {
  background-color: #e9b350 !important;
  fill: #e9b350 !important;
}

.border-warning {
  border-color: #e9b350 !important;
  stroke: #e9b350 !important;
}

.text-warning {
  color: #e9b350 !important;
  fill: #e9b350 !important;
}

.text-warning-hover:hover {
  color: #e9b350 !important;
  fill: #e9b350 !important;
}

.bg-warning-hover:hover {
  background-color: #e9b350 !important;
}

.border-warning-hover:hover {
  border-color: #e9b350 !important;
}

.bg-danger {
  background-color: #b20040 !important;
  fill: #b20040 !important;
}

.border-danger {
  border-color: #b20040 !important;
  stroke: #b20040 !important;
}

.text-danger {
  color: #b20040 !important;
  fill: #b20040 !important;
}

.text-danger-hover:hover {
  color: #b20040 !important;
  fill: #b20040 !important;
}

.bg-danger-hover:hover {
  background-color: #b20040 !important;
}

.border-danger-hover:hover {
  border-color: #b20040 !important;
}

.bg-default {
  background-color: #5c5c5c !important;
  fill: #5c5c5c !important;
}

.border-default {
  border-color: #5c5c5c !important;
  stroke: #5c5c5c !important;
}

.text-default {
  color: #5c5c5c !important;
  fill: #5c5c5c !important;
}

.text-default-hover:hover {
  color: #5c5c5c !important;
  fill: #5c5c5c !important;
}

.bg-default-hover:hover {
  background-color: #5c5c5c !important;
}

.border-default-hover:hover {
  border-color: #5c5c5c !important;
}

.bg-accent-1 {
  background-color: #a31a46 !important;
  fill: #a31a46 !important;
}

.border-accent-1 {
  border-color: #a31a46 !important;
  stroke: #a31a46 !important;
}

.text-accent-1 {
  color: #a31a46 !important;
  fill: #a31a46 !important;
}

.text-accent-1-hover:hover {
  color: #a31a46 !important;
  fill: #a31a46 !important;
}

.bg-accent-1-hover:hover {
  background-color: #a31a46 !important;
}

.border-accent-1-hover:hover {
  border-color: #a31a46 !important;
}

.bg-accent-2 {
  background-color: #73287a !important;
  fill: #73287a !important;
}

.border-accent-2 {
  border-color: #73287a !important;
  stroke: #73287a !important;
}

.text-accent-2 {
  color: #73287a !important;
  fill: #73287a !important;
}

.text-accent-2-hover:hover {
  color: #73287a !important;
  fill: #73287a !important;
}

.bg-accent-2-hover:hover {
  background-color: #73287a !important;
}

.border-accent-2-hover:hover {
  border-color: #73287a !important;
}

.bg-accent-3 {
  background-color: #416a82 !important;
  fill: #416a82 !important;
}

.border-accent-3 {
  border-color: #416a82 !important;
  stroke: #416a82 !important;
}

.text-accent-3 {
  color: #416a82 !important;
  fill: #416a82 !important;
}

.text-accent-3-hover:hover {
  color: #416a82 !important;
  fill: #416a82 !important;
}

.bg-accent-3-hover:hover {
  background-color: #416a82 !important;
}

.border-accent-3-hover:hover {
  border-color: #416a82 !important;
}

.bg-accent-4 {
  background-color: #c56c00 !important;
  fill: #c56c00 !important;
}

.border-accent-4 {
  border-color: #c56c00 !important;
  stroke: #c56c00 !important;
}

.text-accent-4 {
  color: #c56c00 !important;
  fill: #c56c00 !important;
}

.text-accent-4-hover:hover {
  color: #c56c00 !important;
  fill: #c56c00 !important;
}

.bg-accent-4-hover:hover {
  background-color: #c56c00 !important;
}

.border-accent-4-hover:hover {
  border-color: #c56c00 !important;
}

.bg-accent-5 {
  background-color: #13725a !important;
  fill: #13725a !important;
}

.border-accent-5 {
  border-color: #13725a !important;
  stroke: #13725a !important;
}

.text-accent-5 {
  color: #13725a !important;
  fill: #13725a !important;
}

.text-accent-5-hover:hover {
  color: #13725a !important;
  fill: #13725a !important;
}

.bg-accent-5-hover:hover {
  background-color: #13725a !important;
}

.border-accent-5-hover:hover {
  border-color: #13725a !important;
}

.bg-accent-6 {
  background-color: #3e51ad !important;
  fill: #3e51ad !important;
}

.border-accent-6 {
  border-color: #3e51ad !important;
  stroke: #3e51ad !important;
}

.text-accent-6 {
  color: #3e51ad !important;
  fill: #3e51ad !important;
}

.text-accent-6-hover:hover {
  color: #3e51ad !important;
  fill: #3e51ad !important;
}

.bg-accent-6-hover:hover {
  background-color: #3e51ad !important;
}

.border-accent-6-hover:hover {
  border-color: #3e51ad !important;
}

.bg-base {
  background-color: #ffffff !important;
  fill: #ffffff !important;
}

.border-base {
  border-color: #ffffff !important;
  stroke: #ffffff !important;
}

.text-base {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.text-base-hover:hover {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.bg-base-hover:hover {
  background-color: #ffffff !important;
}

.border-base-hover:hover {
  border-color: #ffffff !important;
}

.bg-brand {
  background-color: #d6002a !important;
  fill: #d6002a !important;
}

.border-brand {
  border-color: #d6002a !important;
  stroke: #d6002a !important;
}

.text-brand {
  color: #d6002a !important;
  fill: #d6002a !important;
}

.text-brand-hover:hover {
  color: #d6002a !important;
  fill: #d6002a !important;
}

.bg-brand-hover:hover {
  background-color: #d6002a !important;
}

.border-brand-hover:hover {
  border-color: #d6002a !important;
}

.bg-transparent {
  fill: transparent !important;
}

.text-primary-50 {
  color: #f6fdff !important;
  fill: #f6fdff !important;
}

.text-primary-50-hover:hover {
  color: #f6fdff !important;
  fill: #f6fdff !important;
}

.bg-primary-50 {
  background-color: #f6fdff !important;
  fill: #f6fdff !important;
}

.bg-primary-50-hover:hover {
  background-color: #f6fdff !important;
}

.border-primary-50 {
  border-color: #f6fdff !important;
  stroke: #f6fdff !important;
}

.border-primary-50-hover:hover {
  border-color: #f6fdff !important;
}

.text-primary-100 {
  color: #ebf9fc !important;
  fill: #ebf9fc !important;
}

.text-primary-100-hover:hover {
  color: #ebf9fc !important;
  fill: #ebf9fc !important;
}

.bg-primary-100 {
  background-color: #ebf9fc !important;
  fill: #ebf9fc !important;
}

.bg-primary-100-hover:hover {
  background-color: #ebf9fc !important;
}

.border-primary-100 {
  border-color: #ebf9fc !important;
  stroke: #ebf9fc !important;
}

.border-primary-100-hover:hover {
  border-color: #ebf9fc !important;
}

.text-primary-200 {
  color: #d3eef5 !important;
  fill: #d3eef5 !important;
}

.text-primary-200-hover:hover {
  color: #d3eef5 !important;
  fill: #d3eef5 !important;
}

.bg-primary-200 {
  background-color: #d3eef5 !important;
  fill: #d3eef5 !important;
}

.bg-primary-200-hover:hover {
  background-color: #d3eef5 !important;
}

.border-primary-200 {
  border-color: #d3eef5 !important;
  stroke: #d3eef5 !important;
}

.border-primary-200-hover:hover {
  border-color: #d3eef5 !important;
}

.text-primary-300 {
  color: #aedae5 !important;
  fill: #aedae5 !important;
}

.text-primary-300-hover:hover {
  color: #aedae5 !important;
  fill: #aedae5 !important;
}

.bg-primary-300 {
  background-color: #aedae5 !important;
  fill: #aedae5 !important;
}

.bg-primary-300-hover:hover {
  background-color: #aedae5 !important;
}

.border-primary-300 {
  border-color: #aedae5 !important;
  stroke: #aedae5 !important;
}

.border-primary-300-hover:hover {
  border-color: #aedae5 !important;
}

.text-primary-400 {
  color: #66a7b8 !important;
  fill: #66a7b8 !important;
}

.text-primary-400-hover:hover {
  color: #66a7b8 !important;
  fill: #66a7b8 !important;
}

.bg-primary-400 {
  background-color: #66a7b8 !important;
  fill: #66a7b8 !important;
}

.bg-primary-400-hover:hover {
  background-color: #66a7b8 !important;
}

.border-primary-400 {
  border-color: #66a7b8 !important;
  stroke: #66a7b8 !important;
}

.border-primary-400-hover:hover {
  border-color: #66a7b8 !important;
}

.text-primary-500 {
  color: #338ba1 !important;
  fill: #338ba1 !important;
}

.text-primary-500-hover:hover {
  color: #338ba1 !important;
  fill: #338ba1 !important;
}

.bg-primary-500 {
  background-color: #338ba1 !important;
  fill: #338ba1 !important;
}

.bg-primary-500-hover:hover {
  background-color: #338ba1 !important;
}

.border-primary-500 {
  border-color: #338ba1 !important;
  stroke: #338ba1 !important;
}

.border-primary-500-hover:hover {
  border-color: #338ba1 !important;
}

.text-primary-600 {
  color: #006d89 !important;
  fill: #006d89 !important;
}

.text-primary-600-hover:hover {
  color: #006d89 !important;
  fill: #006d89 !important;
}

.bg-primary-600 {
  background-color: #006d89 !important;
  fill: #006d89 !important;
}

.bg-primary-600-hover:hover {
  background-color: #006d89 !important;
}

.border-primary-600 {
  border-color: #006d89 !important;
  stroke: #006d89 !important;
}

.border-primary-600-hover:hover {
  border-color: #006d89 !important;
}

.text-primary-700 {
  color: #005d75 !important;
  fill: #005d75 !important;
}

.text-primary-700-hover:hover {
  color: #005d75 !important;
  fill: #005d75 !important;
}

.bg-primary-700 {
  background-color: #005d75 !important;
  fill: #005d75 !important;
}

.bg-primary-700-hover:hover {
  background-color: #005d75 !important;
}

.border-primary-700 {
  border-color: #005d75 !important;
  stroke: #005d75 !important;
}

.border-primary-700-hover:hover {
  border-color: #005d75 !important;
}

.text-primary-800 {
  color: #005063 !important;
  fill: #005063 !important;
}

.text-primary-800-hover:hover {
  color: #005063 !important;
  fill: #005063 !important;
}

.bg-primary-800 {
  background-color: #005063 !important;
  fill: #005063 !important;
}

.bg-primary-800-hover:hover {
  background-color: #005063 !important;
}

.border-primary-800 {
  border-color: #005063 !important;
  stroke: #005063 !important;
}

.border-primary-800-hover:hover {
  border-color: #005063 !important;
}

.text-primary-900 {
  color: #004759 !important;
  fill: #004759 !important;
}

.text-primary-900-hover:hover {
  color: #004759 !important;
  fill: #004759 !important;
}

.bg-primary-900 {
  background-color: #004759 !important;
  fill: #004759 !important;
}

.bg-primary-900-hover:hover {
  background-color: #004759 !important;
}

.border-primary-900 {
  border-color: #004759 !important;
  stroke: #004759 !important;
}

.border-primary-900-hover:hover {
  border-color: #004759 !important;
}

.text-primary-950 {
  color: #004152 !important;
  fill: #004152 !important;
}

.text-primary-950-hover:hover {
  color: #004152 !important;
  fill: #004152 !important;
}

.bg-primary-950 {
  background-color: #004152 !important;
  fill: #004152 !important;
}

.bg-primary-950-hover:hover {
  background-color: #004152 !important;
}

.border-primary-950 {
  border-color: #004152 !important;
  stroke: #004152 !important;
}

.border-primary-950-hover:hover {
  border-color: #004152 !important;
}

.text-neutral-50 {
  color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}

.text-neutral-50-hover:hover {
  color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}

.bg-neutral-50 {
  background-color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}

.bg-neutral-50-hover:hover {
  background-color: #f5f5f5 !important;
}

.border-neutral-50 {
  border-color: #f5f5f5 !important;
  stroke: #f5f5f5 !important;
}

.border-neutral-50-hover:hover {
  border-color: #f5f5f5 !important;
}

.text-neutral-100 {
  color: #e6e6e6 !important;
  fill: #e6e6e6 !important;
}

.text-neutral-100-hover:hover {
  color: #e6e6e6 !important;
  fill: #e6e6e6 !important;
}

.bg-neutral-100 {
  background-color: #e6e6e6 !important;
  fill: #e6e6e6 !important;
}

.bg-neutral-100-hover:hover {
  background-color: #e6e6e6 !important;
}

.border-neutral-100 {
  border-color: #e6e6e6 !important;
  stroke: #e6e6e6 !important;
}

.border-neutral-100-hover:hover {
  border-color: #e6e6e6 !important;
}

.text-neutral-200 {
  color: #cececf !important;
  fill: #cececf !important;
}

.text-neutral-200-hover:hover {
  color: #cececf !important;
  fill: #cececf !important;
}

.bg-neutral-200 {
  background-color: #cececf !important;
  fill: #cececf !important;
}

.bg-neutral-200-hover:hover {
  background-color: #cececf !important;
}

.border-neutral-200 {
  border-color: #cececf !important;
  stroke: #cececf !important;
}

.border-neutral-200-hover:hover {
  border-color: #cececf !important;
}

.text-neutral-300 {
  color: #adadad !important;
  fill: #adadad !important;
}

.text-neutral-300-hover:hover {
  color: #adadad !important;
  fill: #adadad !important;
}

.bg-neutral-300 {
  background-color: #adadad !important;
  fill: #adadad !important;
}

.bg-neutral-300-hover:hover {
  background-color: #adadad !important;
}

.border-neutral-300 {
  border-color: #adadad !important;
  stroke: #adadad !important;
}

.border-neutral-300-hover:hover {
  border-color: #adadad !important;
}

.text-neutral-400 {
  color: #999999 !important;
  fill: #999999 !important;
}

.text-neutral-400-hover:hover {
  color: #999999 !important;
  fill: #999999 !important;
}

.bg-neutral-400 {
  background-color: #999999 !important;
  fill: #999999 !important;
}

.bg-neutral-400-hover:hover {
  background-color: #999999 !important;
}

.border-neutral-400 {
  border-color: #999999 !important;
  stroke: #999999 !important;
}

.border-neutral-400-hover:hover {
  border-color: #999999 !important;
}

.text-neutral-500 {
  color: #6d6d6d !important;
  fill: #6d6d6d !important;
}

.text-neutral-500-hover:hover {
  color: #6d6d6d !important;
  fill: #6d6d6d !important;
}

.bg-neutral-500 {
  background-color: #6d6d6d !important;
  fill: #6d6d6d !important;
}

.bg-neutral-500-hover:hover {
  background-color: #6d6d6d !important;
}

.border-neutral-500 {
  border-color: #6d6d6d !important;
  stroke: #6d6d6d !important;
}

.border-neutral-500-hover:hover {
  border-color: #6d6d6d !important;
}

.text-neutral-600 {
  color: #383838 !important;
  fill: #383838 !important;
}

.text-neutral-600-hover:hover {
  color: #383838 !important;
  fill: #383838 !important;
}

.bg-neutral-600 {
  background-color: #383838 !important;
  fill: #383838 !important;
}

.bg-neutral-600-hover:hover {
  background-color: #383838 !important;
}

.border-neutral-600 {
  border-color: #383838 !important;
  stroke: #383838 !important;
}

.border-neutral-600-hover:hover {
  border-color: #383838 !important;
}

.text-neutral-700 {
  color: #333333 !important;
  fill: #333333 !important;
}

.text-neutral-700-hover:hover {
  color: #333333 !important;
  fill: #333333 !important;
}

.bg-neutral-700 {
  background-color: #333333 !important;
  fill: #333333 !important;
}

.bg-neutral-700-hover:hover {
  background-color: #333333 !important;
}

.border-neutral-700 {
  border-color: #333333 !important;
  stroke: #333333 !important;
}

.border-neutral-700-hover:hover {
  border-color: #333333 !important;
}

.text-neutral-800 {
  color: #292929 !important;
  fill: #292929 !important;
}

.text-neutral-800-hover:hover {
  color: #292929 !important;
  fill: #292929 !important;
}

.bg-neutral-800 {
  background-color: #292929 !important;
  fill: #292929 !important;
}

.bg-neutral-800-hover:hover {
  background-color: #292929 !important;
}

.border-neutral-800 {
  border-color: #292929 !important;
  stroke: #292929 !important;
}

.border-neutral-800-hover:hover {
  border-color: #292929 !important;
}

.text-neutral-900 {
  color: #1a1a1a !important;
  fill: #1a1a1a !important;
}

.text-neutral-900-hover:hover {
  color: #1a1a1a !important;
  fill: #1a1a1a !important;
}

.bg-neutral-900 {
  background-color: #1a1a1a !important;
  fill: #1a1a1a !important;
}

.bg-neutral-900-hover:hover {
  background-color: #1a1a1a !important;
}

.border-neutral-900 {
  border-color: #1a1a1a !important;
  stroke: #1a1a1a !important;
}

.border-neutral-900-hover:hover {
  border-color: #1a1a1a !important;
}

.text-success-50 {
  color: #f7faf7 !important;
  fill: #f7faf7 !important;
}

.text-success-50-hover:hover {
  color: #f7faf7 !important;
  fill: #f7faf7 !important;
}

.bg-success-50 {
  background-color: #f7faf7 !important;
  fill: #f7faf7 !important;
}

.bg-success-50-hover:hover {
  background-color: #f7faf7 !important;
}

.border-success-50 {
  border-color: #f7faf7 !important;
  stroke: #f7faf7 !important;
}

.border-success-50-hover:hover {
  border-color: #f7faf7 !important;
}

.text-success-100 {
  color: #eef5f0 !important;
  fill: #eef5f0 !important;
}

.text-success-100-hover:hover {
  color: #eef5f0 !important;
  fill: #eef5f0 !important;
}

.bg-success-100 {
  background-color: #eef5f0 !important;
  fill: #eef5f0 !important;
}

.bg-success-100-hover:hover {
  background-color: #eef5f0 !important;
}

.border-success-100 {
  border-color: #eef5f0 !important;
  stroke: #eef5f0 !important;
}

.border-success-100-hover:hover {
  border-color: #eef5f0 !important;
}

.text-success-200 {
  color: #deefe1 !important;
  fill: #deefe1 !important;
}

.text-success-200-hover:hover {
  color: #deefe1 !important;
  fill: #deefe1 !important;
}

.bg-success-200 {
  background-color: #deefe1 !important;
  fill: #deefe1 !important;
}

.bg-success-200-hover:hover {
  background-color: #deefe1 !important;
}

.border-success-200 {
  border-color: #deefe1 !important;
  stroke: #deefe1 !important;
}

.border-success-200-hover:hover {
  border-color: #deefe1 !important;
}

.text-success-300 {
  color: #bedec4 !important;
  fill: #bedec4 !important;
}

.text-success-300-hover:hover {
  color: #bedec4 !important;
  fill: #bedec4 !important;
}

.bg-success-300 {
  background-color: #bedec4 !important;
  fill: #bedec4 !important;
}

.bg-success-300-hover:hover {
  background-color: #bedec4 !important;
}

.border-success-300 {
  border-color: #bedec4 !important;
  stroke: #bedec4 !important;
}

.border-success-300-hover:hover {
  border-color: #bedec4 !important;
}

.text-success-400 {
  color: #9dcea6 !important;
  fill: #9dcea6 !important;
}

.text-success-400-hover:hover {
  color: #9dcea6 !important;
  fill: #9dcea6 !important;
}

.bg-success-400 {
  background-color: #9dcea6 !important;
  fill: #9dcea6 !important;
}

.bg-success-400-hover:hover {
  background-color: #9dcea6 !important;
}

.border-success-400 {
  border-color: #9dcea6 !important;
  stroke: #9dcea6 !important;
}

.border-success-400-hover:hover {
  border-color: #9dcea6 !important;
}

.text-success-500 {
  color: #7dbe88 !important;
  fill: #7dbe88 !important;
}

.text-success-500-hover:hover {
  color: #7dbe88 !important;
  fill: #7dbe88 !important;
}

.bg-success-500 {
  background-color: #7dbe88 !important;
  fill: #7dbe88 !important;
}

.bg-success-500-hover:hover {
  background-color: #7dbe88 !important;
}

.border-success-500 {
  border-color: #7dbe88 !important;
  stroke: #7dbe88 !important;
}

.border-success-500-hover:hover {
  border-color: #7dbe88 !important;
}

.text-success-600 {
  color: #5cae6a !important;
  fill: #5cae6a !important;
}

.text-success-600-hover:hover {
  color: #5cae6a !important;
  fill: #5cae6a !important;
}

.bg-success-600 {
  background-color: #5cae6a !important;
  fill: #5cae6a !important;
}

.bg-success-600-hover:hover {
  background-color: #5cae6a !important;
}

.border-success-600 {
  border-color: #5cae6a !important;
  stroke: #5cae6a !important;
}

.border-success-600-hover:hover {
  border-color: #5cae6a !important;
}

.text-success-700 {
  color: #3c9d4d !important;
  fill: #3c9d4d !important;
}

.text-success-700-hover:hover {
  color: #3c9d4d !important;
  fill: #3c9d4d !important;
}

.bg-success-700 {
  background-color: #3c9d4d !important;
  fill: #3c9d4d !important;
}

.bg-success-700-hover:hover {
  background-color: #3c9d4d !important;
}

.border-success-700 {
  border-color: #3c9d4d !important;
  stroke: #3c9d4d !important;
}

.border-success-700-hover:hover {
  border-color: #3c9d4d !important;
}

.text-success-800 {
  color: #167527 !important;
  fill: #167527 !important;
}

.text-success-800-hover:hover {
  color: #167527 !important;
  fill: #167527 !important;
}

.bg-success-800 {
  background-color: #167527 !important;
  fill: #167527 !important;
}

.bg-success-800-hover:hover {
  background-color: #167527 !important;
}

.border-success-800 {
  border-color: #167527 !important;
  stroke: #167527 !important;
}

.border-success-800-hover:hover {
  border-color: #167527 !important;
}

.text-success-900 {
  color: #125e1f !important;
  fill: #125e1f !important;
}

.text-success-900-hover:hover {
  color: #125e1f !important;
  fill: #125e1f !important;
}

.bg-success-900 {
  background-color: #125e1f !important;
  fill: #125e1f !important;
}

.bg-success-900-hover:hover {
  background-color: #125e1f !important;
}

.border-success-900 {
  border-color: #125e1f !important;
  stroke: #125e1f !important;
}

.border-success-900-hover:hover {
  border-color: #125e1f !important;
}

.text-warning-50 {
  color: #fffaeb !important;
  fill: #fffaeb !important;
}

.text-warning-50-hover:hover {
  color: #fffaeb !important;
  fill: #fffaeb !important;
}

.bg-warning-50 {
  background-color: #fffaeb !important;
  fill: #fffaeb !important;
}

.bg-warning-50-hover:hover {
  background-color: #fffaeb !important;
}

.border-warning-50 {
  border-color: #fffaeb !important;
  stroke: #fffaeb !important;
}

.border-warning-50-hover:hover {
  border-color: #fffaeb !important;
}

.text-warning-100 {
  color: #fff7e3 !important;
  fill: #fff7e3 !important;
}

.text-warning-100-hover:hover {
  color: #fff7e3 !important;
  fill: #fff7e3 !important;
}

.bg-warning-100 {
  background-color: #fff7e3 !important;
  fill: #fff7e3 !important;
}

.bg-warning-100-hover:hover {
  background-color: #fff7e3 !important;
}

.border-warning-100 {
  border-color: #fff7e3 !important;
  stroke: #fff7e3 !important;
}

.border-warning-100-hover:hover {
  border-color: #fff7e3 !important;
}

.text-warning-200 {
  color: #fff3d4 !important;
  fill: #fff3d4 !important;
}

.text-warning-200-hover:hover {
  color: #fff3d4 !important;
  fill: #fff3d4 !important;
}

.bg-warning-200 {
  background-color: #fff3d4 !important;
  fill: #fff3d4 !important;
}

.bg-warning-200-hover:hover {
  background-color: #fff3d4 !important;
}

.border-warning-200 {
  border-color: #fff3d4 !important;
  stroke: #fff3d4 !important;
}

.border-warning-200-hover:hover {
  border-color: #fff3d4 !important;
}

.text-warning-300 {
  color: #fcecc0 !important;
  fill: #fcecc0 !important;
}

.text-warning-300-hover:hover {
  color: #fcecc0 !important;
  fill: #fcecc0 !important;
}

.bg-warning-300 {
  background-color: #fcecc0 !important;
  fill: #fcecc0 !important;
}

.bg-warning-300-hover:hover {
  background-color: #fcecc0 !important;
}

.border-warning-300 {
  border-color: #fcecc0 !important;
  stroke: #fcecc0 !important;
}

.border-warning-300-hover:hover {
  border-color: #fcecc0 !important;
}

.text-warning-400 {
  color: #fadf96 !important;
  fill: #fadf96 !important;
}

.text-warning-400-hover:hover {
  color: #fadf96 !important;
  fill: #fadf96 !important;
}

.bg-warning-400 {
  background-color: #fadf96 !important;
  fill: #fadf96 !important;
}

.bg-warning-400-hover:hover {
  background-color: #fadf96 !important;
}

.border-warning-400 {
  border-color: #fadf96 !important;
  stroke: #fadf96 !important;
}

.border-warning-400-hover:hover {
  border-color: #fadf96 !important;
}

.text-warning-500 {
  color: #f2cb6f !important;
  fill: #f2cb6f !important;
}

.text-warning-500-hover:hover {
  color: #f2cb6f !important;
  fill: #f2cb6f !important;
}

.bg-warning-500 {
  background-color: #f2cb6f !important;
  fill: #f2cb6f !important;
}

.bg-warning-500-hover:hover {
  background-color: #f2cb6f !important;
}

.border-warning-500 {
  border-color: #f2cb6f !important;
  stroke: #f2cb6f !important;
}

.border-warning-500-hover:hover {
  border-color: #f2cb6f !important;
}

.text-warning-600 {
  color: #f0c15d !important;
  fill: #f0c15d !important;
}

.text-warning-600-hover:hover {
  color: #f0c15d !important;
  fill: #f0c15d !important;
}

.bg-warning-600 {
  background-color: #f0c15d !important;
  fill: #f0c15d !important;
}

.bg-warning-600-hover:hover {
  background-color: #f0c15d !important;
}

.border-warning-600 {
  border-color: #f0c15d !important;
  stroke: #f0c15d !important;
}

.border-warning-600-hover:hover {
  border-color: #f0c15d !important;
}

.text-warning-700 {
  color: #e9b350 !important;
  fill: #e9b350 !important;
}

.text-warning-700-hover:hover {
  color: #e9b350 !important;
  fill: #e9b350 !important;
}

.bg-warning-700 {
  background-color: #e9b350 !important;
  fill: #e9b350 !important;
}

.bg-warning-700-hover:hover {
  background-color: #e9b350 !important;
}

.border-warning-700 {
  border-color: #e9b350 !important;
  stroke: #e9b350 !important;
}

.border-warning-700-hover:hover {
  border-color: #e9b350 !important;
}

.text-warning-800 {
  color: #d59f3c !important;
  fill: #d59f3c !important;
}

.text-warning-800-hover:hover {
  color: #d59f3c !important;
  fill: #d59f3c !important;
}

.bg-warning-800 {
  background-color: #d59f3c !important;
  fill: #d59f3c !important;
}

.bg-warning-800-hover:hover {
  background-color: #d59f3c !important;
}

.border-warning-800 {
  border-color: #d59f3c !important;
  stroke: #d59f3c !important;
}

.border-warning-800-hover:hover {
  border-color: #d59f3c !important;
}

.text-warning-900 {
  color: #c18b28 !important;
  fill: #c18b28 !important;
}

.text-warning-900-hover:hover {
  color: #c18b28 !important;
  fill: #c18b28 !important;
}

.bg-warning-900 {
  background-color: #c18b28 !important;
  fill: #c18b28 !important;
}

.bg-warning-900-hover:hover {
  background-color: #c18b28 !important;
}

.border-warning-900 {
  border-color: #c18b28 !important;
  stroke: #c18b28 !important;
}

.border-warning-900-hover:hover {
  border-color: #c18b28 !important;
}

.text-warning-950 {
  color: #9d5700 !important;
  fill: #9d5700 !important;
}

.text-warning-950-hover:hover {
  color: #9d5700 !important;
  fill: #9d5700 !important;
}

.bg-warning-950 {
  background-color: #9d5700 !important;
  fill: #9d5700 !important;
}

.bg-warning-950-hover:hover {
  background-color: #9d5700 !important;
}

.border-warning-950 {
  border-color: #9d5700 !important;
  stroke: #9d5700 !important;
}

.border-warning-950-hover:hover {
  border-color: #9d5700 !important;
}

.text-danger-50 {
  color: #fff6f9 !important;
  fill: #fff6f9 !important;
}

.text-danger-50-hover:hover {
  color: #fff6f9 !important;
  fill: #fff6f9 !important;
}

.bg-danger-50 {
  background-color: #fff6f9 !important;
  fill: #fff6f9 !important;
}

.bg-danger-50-hover:hover {
  background-color: #fff6f9 !important;
}

.border-danger-50 {
  border-color: #fff6f9 !important;
  stroke: #fff6f9 !important;
}

.border-danger-50-hover:hover {
  border-color: #fff6f9 !important;
}

.text-danger-100 {
  color: #ffeff3 !important;
  fill: #ffeff3 !important;
}

.text-danger-100-hover:hover {
  color: #ffeff3 !important;
  fill: #ffeff3 !important;
}

.bg-danger-100 {
  background-color: #ffeff3 !important;
  fill: #ffeff3 !important;
}

.bg-danger-100-hover:hover {
  background-color: #ffeff3 !important;
}

.border-danger-100 {
  border-color: #ffeff3 !important;
  stroke: #ffeff3 !important;
}

.border-danger-100-hover:hover {
  border-color: #ffeff3 !important;
}

.text-danger-200 {
  color: #fadde6 !important;
  fill: #fadde6 !important;
}

.text-danger-200-hover:hover {
  color: #fadde6 !important;
  fill: #fadde6 !important;
}

.bg-danger-200 {
  background-color: #fadde6 !important;
  fill: #fadde6 !important;
}

.bg-danger-200-hover:hover {
  background-color: #fadde6 !important;
}

.border-danger-200 {
  border-color: #fadde6 !important;
  stroke: #fadde6 !important;
}

.border-danger-200-hover:hover {
  border-color: #fadde6 !important;
}

.text-danger-300 {
  color: #f3a7c3 !important;
  fill: #f3a7c3 !important;
}

.text-danger-300-hover:hover {
  color: #f3a7c3 !important;
  fill: #f3a7c3 !important;
}

.bg-danger-300 {
  background-color: #f3a7c3 !important;
  fill: #f3a7c3 !important;
}

.bg-danger-300-hover:hover {
  background-color: #f3a7c3 !important;
}

.border-danger-300 {
  border-color: #f3a7c3 !important;
  stroke: #f3a7c3 !important;
}

.border-danger-300-hover:hover {
  border-color: #f3a7c3 !important;
}

.text-danger-400 {
  color: #df6b95 !important;
  fill: #df6b95 !important;
}

.text-danger-400-hover:hover {
  color: #df6b95 !important;
  fill: #df6b95 !important;
}

.bg-danger-400 {
  background-color: #df6b95 !important;
  fill: #df6b95 !important;
}

.bg-danger-400-hover:hover {
  background-color: #df6b95 !important;
}

.border-danger-400 {
  border-color: #df6b95 !important;
  stroke: #df6b95 !important;
}

.border-danger-400-hover:hover {
  border-color: #df6b95 !important;
}

.text-danger-500 {
  color: #d83159 !important;
  fill: #d83159 !important;
}

.text-danger-500-hover:hover {
  color: #d83159 !important;
  fill: #d83159 !important;
}

.bg-danger-500 {
  background-color: #d83159 !important;
  fill: #d83159 !important;
}

.bg-danger-500-hover:hover {
  background-color: #d83159 !important;
}

.border-danger-500 {
  border-color: #d83159 !important;
  stroke: #d83159 !important;
}

.border-danger-500-hover:hover {
  border-color: #d83159 !important;
}

.text-danger-600 {
  color: #d10f49 !important;
  fill: #d10f49 !important;
}

.text-danger-600-hover:hover {
  color: #d10f49 !important;
  fill: #d10f49 !important;
}

.bg-danger-600 {
  background-color: #d10f49 !important;
  fill: #d10f49 !important;
}

.bg-danger-600-hover:hover {
  background-color: #d10f49 !important;
}

.border-danger-600 {
  border-color: #d10f49 !important;
  stroke: #d10f49 !important;
}

.border-danger-600-hover:hover {
  border-color: #d10f49 !important;
}

.text-danger-700 {
  color: #b20040 !important;
  fill: #b20040 !important;
}

.text-danger-700-hover:hover {
  color: #b20040 !important;
  fill: #b20040 !important;
}

.bg-danger-700 {
  background-color: #b20040 !important;
  fill: #b20040 !important;
}

.bg-danger-700-hover:hover {
  background-color: #b20040 !important;
}

.border-danger-700 {
  border-color: #b20040 !important;
  stroke: #b20040 !important;
}

.border-danger-700-hover:hover {
  border-color: #b20040 !important;
}

.text-danger-800 {
  color: #960036 !important;
  fill: #960036 !important;
}

.text-danger-800-hover:hover {
  color: #960036 !important;
  fill: #960036 !important;
}

.bg-danger-800 {
  background-color: #960036 !important;
  fill: #960036 !important;
}

.bg-danger-800-hover:hover {
  background-color: #960036 !important;
}

.border-danger-800 {
  border-color: #960036 !important;
  stroke: #960036 !important;
}

.border-danger-800-hover:hover {
  border-color: #960036 !important;
}

.text-danger-900 {
  color: #880129 !important;
  fill: #880129 !important;
}

.text-danger-900-hover:hover {
  color: #880129 !important;
  fill: #880129 !important;
}

.bg-danger-900 {
  background-color: #880129 !important;
  fill: #880129 !important;
}

.bg-danger-900-hover:hover {
  background-color: #880129 !important;
}

.border-danger-900 {
  border-color: #880129 !important;
  stroke: #880129 !important;
}

.border-danger-900-hover:hover {
  border-color: #880129 !important;
}

.text-default-50 {
  color: #f8f8f8 !important;
  fill: #f8f8f8 !important;
}

.text-default-50-hover:hover {
  color: #f8f8f8 !important;
  fill: #f8f8f8 !important;
}

.bg-default-50 {
  background-color: #f8f8f8 !important;
  fill: #f8f8f8 !important;
}

.bg-default-50-hover:hover {
  background-color: #f8f8f8 !important;
}

.border-default-50 {
  border-color: #f8f8f8 !important;
  stroke: #f8f8f8 !important;
}

.border-default-50-hover:hover {
  border-color: #f8f8f8 !important;
}

.text-default-100 {
  color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}

.text-default-100-hover:hover {
  color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}

.bg-default-100 {
  background-color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}

.bg-default-100-hover:hover {
  background-color: #f5f5f5 !important;
}

.border-default-100 {
  border-color: #f5f5f5 !important;
  stroke: #f5f5f5 !important;
}

.border-default-100-hover:hover {
  border-color: #f5f5f5 !important;
}

.text-default-200 {
  color: #eeeeee !important;
  fill: #eeeeee !important;
}

.text-default-200-hover:hover {
  color: #eeeeee !important;
  fill: #eeeeee !important;
}

.bg-default-200 {
  background-color: #eeeeee !important;
  fill: #eeeeee !important;
}

.bg-default-200-hover:hover {
  background-color: #eeeeee !important;
}

.border-default-200 {
  border-color: #eeeeee !important;
  stroke: #eeeeee !important;
}

.border-default-200-hover:hover {
  border-color: #eeeeee !important;
}

.text-default-300 {
  color: #e2e2e2 !important;
  fill: #e2e2e2 !important;
}

.text-default-300-hover:hover {
  color: #e2e2e2 !important;
  fill: #e2e2e2 !important;
}

.bg-default-300 {
  background-color: #e2e2e2 !important;
  fill: #e2e2e2 !important;
}

.bg-default-300-hover:hover {
  background-color: #e2e2e2 !important;
}

.border-default-300 {
  border-color: #e2e2e2 !important;
  stroke: #e2e2e2 !important;
}

.border-default-300-hover:hover {
  border-color: #e2e2e2 !important;
}

.text-default-400 {
  color: #c2c2c2 !important;
  fill: #c2c2c2 !important;
}

.text-default-400-hover:hover {
  color: #c2c2c2 !important;
  fill: #c2c2c2 !important;
}

.bg-default-400 {
  background-color: #c2c2c2 !important;
  fill: #c2c2c2 !important;
}

.bg-default-400-hover:hover {
  background-color: #c2c2c2 !important;
}

.border-default-400 {
  border-color: #c2c2c2 !important;
  stroke: #c2c2c2 !important;
}

.border-default-400-hover:hover {
  border-color: #c2c2c2 !important;
}

.text-default-500 {
  color: #888888 !important;
  fill: #888888 !important;
}

.text-default-500-hover:hover {
  color: #888888 !important;
  fill: #888888 !important;
}

.bg-default-500 {
  background-color: #888888 !important;
  fill: #888888 !important;
}

.bg-default-500-hover:hover {
  background-color: #888888 !important;
}

.border-default-500 {
  border-color: #888888 !important;
  stroke: #888888 !important;
}

.border-default-500-hover:hover {
  border-color: #888888 !important;
}

.text-default-600 {
  color: #6b6b6b !important;
  fill: #6b6b6b !important;
}

.text-default-600-hover:hover {
  color: #6b6b6b !important;
  fill: #6b6b6b !important;
}

.bg-default-600 {
  background-color: #6b6b6b !important;
  fill: #6b6b6b !important;
}

.bg-default-600-hover:hover {
  background-color: #6b6b6b !important;
}

.border-default-600 {
  border-color: #6b6b6b !important;
  stroke: #6b6b6b !important;
}

.border-default-600-hover:hover {
  border-color: #6b6b6b !important;
}

.text-default-700 {
  color: #5c5c5c !important;
  fill: #5c5c5c !important;
}

.text-default-700-hover:hover {
  color: #5c5c5c !important;
  fill: #5c5c5c !important;
}

.bg-default-700 {
  background-color: #5c5c5c !important;
  fill: #5c5c5c !important;
}

.bg-default-700-hover:hover {
  background-color: #5c5c5c !important;
}

.border-default-700 {
  border-color: #5c5c5c !important;
  stroke: #5c5c5c !important;
}

.border-default-700-hover:hover {
  border-color: #5c5c5c !important;
}

.text-default-800 {
  color: #494844 !important;
  fill: #494844 !important;
}

.text-default-800-hover:hover {
  color: #494844 !important;
  fill: #494844 !important;
}

.bg-default-800 {
  background-color: #494844 !important;
  fill: #494844 !important;
}

.bg-default-800-hover:hover {
  background-color: #494844 !important;
}

.border-default-800 {
  border-color: #494844 !important;
  stroke: #494844 !important;
}

.border-default-800-hover:hover {
  border-color: #494844 !important;
}

.text-default-900 {
  color: #1f1f1f !important;
  fill: #1f1f1f !important;
}

.text-default-900-hover:hover {
  color: #1f1f1f !important;
  fill: #1f1f1f !important;
}

.bg-default-900 {
  background-color: #1f1f1f !important;
  fill: #1f1f1f !important;
}

.bg-default-900-hover:hover {
  background-color: #1f1f1f !important;
}

.border-default-900 {
  border-color: #1f1f1f !important;
  stroke: #1f1f1f !important;
}

.border-default-900-hover:hover {
  border-color: #1f1f1f !important;
}

.text-accent-1-50 {
  color: #fcf4f6 !important;
  fill: #fcf4f6 !important;
}

.text-accent-1-50-hover:hover {
  color: #fcf4f6 !important;
  fill: #fcf4f6 !important;
}

.bg-accent-1-50 {
  background-color: #fcf4f6 !important;
  fill: #fcf4f6 !important;
}

.bg-accent-1-50-hover:hover {
  background-color: #fcf4f6 !important;
}

.border-accent-1-50 {
  border-color: #fcf4f6 !important;
  stroke: #fcf4f6 !important;
}

.border-accent-1-50-hover:hover {
  border-color: #fcf4f6 !important;
}

.text-accent-1-100 {
  color: #fbeff2 !important;
  fill: #fbeff2 !important;
}

.text-accent-1-100-hover:hover {
  color: #fbeff2 !important;
  fill: #fbeff2 !important;
}

.bg-accent-1-100 {
  background-color: #fbeff2 !important;
  fill: #fbeff2 !important;
}

.bg-accent-1-100-hover:hover {
  background-color: #fbeff2 !important;
}

.border-accent-1-100 {
  border-color: #fbeff2 !important;
  stroke: #fbeff2 !important;
}

.border-accent-1-100-hover:hover {
  border-color: #fbeff2 !important;
}

.text-accent-1-200 {
  color: #f5dfe6 !important;
  fill: #f5dfe6 !important;
}

.text-accent-1-200-hover:hover {
  color: #f5dfe6 !important;
  fill: #f5dfe6 !important;
}

.bg-accent-1-200 {
  background-color: #f5dfe6 !important;
  fill: #f5dfe6 !important;
}

.bg-accent-1-200-hover:hover {
  background-color: #f5dfe6 !important;
}

.border-accent-1-200 {
  border-color: #f5dfe6 !important;
  stroke: #f5dfe6 !important;
}

.border-accent-1-200-hover:hover {
  border-color: #f5dfe6 !important;
}

.text-accent-1-300 {
  color: #ebbfcd !important;
  fill: #ebbfcd !important;
}

.text-accent-1-300-hover:hover {
  color: #ebbfcd !important;
  fill: #ebbfcd !important;
}

.bg-accent-1-300 {
  background-color: #ebbfcd !important;
  fill: #ebbfcd !important;
}

.bg-accent-1-300-hover:hover {
  background-color: #ebbfcd !important;
}

.border-accent-1-300 {
  border-color: #ebbfcd !important;
  stroke: #ebbfcd !important;
}

.border-accent-1-300-hover:hover {
  border-color: #ebbfcd !important;
}

.text-accent-1-400 {
  color: #e19fb4 !important;
  fill: #e19fb4 !important;
}

.text-accent-1-400-hover:hover {
  color: #e19fb4 !important;
  fill: #e19fb4 !important;
}

.bg-accent-1-400 {
  background-color: #e19fb4 !important;
  fill: #e19fb4 !important;
}

.bg-accent-1-400-hover:hover {
  background-color: #e19fb4 !important;
}

.border-accent-1-400 {
  border-color: #e19fb4 !important;
  stroke: #e19fb4 !important;
}

.border-accent-1-400-hover:hover {
  border-color: #e19fb4 !important;
}

.text-accent-1-500 {
  color: #cd6083 !important;
  fill: #cd6083 !important;
}

.text-accent-1-500-hover:hover {
  color: #cd6083 !important;
  fill: #cd6083 !important;
}

.bg-accent-1-500 {
  background-color: #cd6083 !important;
  fill: #cd6083 !important;
}

.bg-accent-1-500-hover:hover {
  background-color: #cd6083 !important;
}

.border-accent-1-500 {
  border-color: #cd6083 !important;
  stroke: #cd6083 !important;
}

.border-accent-1-500-hover:hover {
  border-color: #cd6083 !important;
}

.text-accent-1-600 {
  color: #c3406a !important;
  fill: #c3406a !important;
}

.text-accent-1-600-hover:hover {
  color: #c3406a !important;
  fill: #c3406a !important;
}

.bg-accent-1-600 {
  background-color: #c3406a !important;
  fill: #c3406a !important;
}

.bg-accent-1-600-hover:hover {
  background-color: #c3406a !important;
}

.border-accent-1-600 {
  border-color: #c3406a !important;
  stroke: #c3406a !important;
}

.border-accent-1-600-hover:hover {
  border-color: #c3406a !important;
}

.text-accent-1-700 {
  color: #a31a46 !important;
  fill: #a31a46 !important;
}

.text-accent-1-700-hover:hover {
  color: #a31a46 !important;
  fill: #a31a46 !important;
}

.bg-accent-1-700 {
  background-color: #a31a46 !important;
  fill: #a31a46 !important;
}

.bg-accent-1-700-hover:hover {
  background-color: #a31a46 !important;
}

.border-accent-1-700 {
  border-color: #a31a46 !important;
  stroke: #a31a46 !important;
}

.border-accent-1-700-hover:hover {
  border-color: #a31a46 !important;
}

.text-accent-1-800 {
  color: #8a183c !important;
  fill: #8a183c !important;
}

.text-accent-1-800-hover:hover {
  color: #8a183c !important;
  fill: #8a183c !important;
}

.bg-accent-1-800 {
  background-color: #8a183c !important;
  fill: #8a183c !important;
}

.bg-accent-1-800-hover:hover {
  background-color: #8a183c !important;
}

.border-accent-1-800 {
  border-color: #8a183c !important;
  stroke: #8a183c !important;
}

.border-accent-1-800-hover:hover {
  border-color: #8a183c !important;
}

.text-accent-1-900 {
  color: #7b1536 !important;
  fill: #7b1536 !important;
}

.text-accent-1-900-hover:hover {
  color: #7b1536 !important;
  fill: #7b1536 !important;
}

.bg-accent-1-900 {
  background-color: #7b1536 !important;
  fill: #7b1536 !important;
}

.bg-accent-1-900-hover:hover {
  background-color: #7b1536 !important;
}

.border-accent-1-900 {
  border-color: #7b1536 !important;
  stroke: #7b1536 !important;
}

.border-accent-1-900-hover:hover {
  border-color: #7b1536 !important;
}

.text-accent-2-50 {
  color: #fbf6fc !important;
  fill: #fbf6fc !important;
}

.text-accent-2-50-hover:hover {
  color: #fbf6fc !important;
  fill: #fbf6fc !important;
}

.bg-accent-2-50 {
  background-color: #fbf6fc !important;
  fill: #fbf6fc !important;
}

.bg-accent-2-50-hover:hover {
  background-color: #fbf6fc !important;
}

.border-accent-2-50 {
  border-color: #fbf6fc !important;
  stroke: #fbf6fc !important;
}

.border-accent-2-50-hover:hover {
  border-color: #fbf6fc !important;
}

.text-accent-2-100 {
  color: #f7eef8 !important;
  fill: #f7eef8 !important;
}

.text-accent-2-100-hover:hover {
  color: #f7eef8 !important;
  fill: #f7eef8 !important;
}

.bg-accent-2-100 {
  background-color: #f7eef8 !important;
  fill: #f7eef8 !important;
}

.bg-accent-2-100-hover:hover {
  background-color: #f7eef8 !important;
}

.border-accent-2-100 {
  border-color: #f7eef8 !important;
  stroke: #f7eef8 !important;
}

.border-accent-2-100-hover:hover {
  border-color: #f7eef8 !important;
}

.text-accent-2-200 {
  color: #f1e4f2 !important;
  fill: #f1e4f2 !important;
}

.text-accent-2-200-hover:hover {
  color: #f1e4f2 !important;
  fill: #f1e4f2 !important;
}

.bg-accent-2-200 {
  background-color: #f1e4f2 !important;
  fill: #f1e4f2 !important;
}

.bg-accent-2-200-hover:hover {
  background-color: #f1e4f2 !important;
}

.border-accent-2-200 {
  border-color: #f1e4f2 !important;
  stroke: #f1e4f2 !important;
}

.border-accent-2-200-hover:hover {
  border-color: #f1e4f2 !important;
}

.text-accent-2-300 {
  color: #e6d2e9 !important;
  fill: #e6d2e9 !important;
}

.text-accent-2-300-hover:hover {
  color: #e6d2e9 !important;
  fill: #e6d2e9 !important;
}

.bg-accent-2-300 {
  background-color: #e6d2e9 !important;
  fill: #e6d2e9 !important;
}

.bg-accent-2-300-hover:hover {
  background-color: #e6d2e9 !important;
}

.border-accent-2-300 {
  border-color: #e6d2e9 !important;
  stroke: #e6d2e9 !important;
}

.border-accent-2-300-hover:hover {
  border-color: #e6d2e9 !important;
}

.text-accent-2-400 {
  color: #d7b8da !important;
  fill: #d7b8da !important;
}

.text-accent-2-400-hover:hover {
  color: #d7b8da !important;
  fill: #d7b8da !important;
}

.bg-accent-2-400 {
  background-color: #d7b8da !important;
  fill: #d7b8da !important;
}

.bg-accent-2-400-hover:hover {
  background-color: #d7b8da !important;
}

.border-accent-2-400 {
  border-color: #d7b8da !important;
  stroke: #d7b8da !important;
}

.border-accent-2-400-hover:hover {
  border-color: #d7b8da !important;
}

.text-accent-2-500 {
  color: #b280b6 !important;
  fill: #b280b6 !important;
}

.text-accent-2-500-hover:hover {
  color: #b280b6 !important;
  fill: #b280b6 !important;
}

.bg-accent-2-500 {
  background-color: #b280b6 !important;
  fill: #b280b6 !important;
}

.bg-accent-2-500-hover:hover {
  background-color: #b280b6 !important;
}

.border-accent-2-500 {
  border-color: #b280b6 !important;
  stroke: #b280b6 !important;
}

.border-accent-2-500-hover:hover {
  border-color: #b280b6 !important;
}

.text-accent-2-600 {
  color: #8b4092 !important;
  fill: #8b4092 !important;
}

.text-accent-2-600-hover:hover {
  color: #8b4092 !important;
  fill: #8b4092 !important;
}

.bg-accent-2-600 {
  background-color: #8b4092 !important;
  fill: #8b4092 !important;
}

.bg-accent-2-600-hover:hover {
  background-color: #8b4092 !important;
}

.border-accent-2-600 {
  border-color: #8b4092 !important;
  stroke: #8b4092 !important;
}

.border-accent-2-600-hover:hover {
  border-color: #8b4092 !important;
}

.text-accent-2-700 {
  color: #73287a !important;
  fill: #73287a !important;
}

.text-accent-2-700-hover:hover {
  color: #73287a !important;
  fill: #73287a !important;
}

.bg-accent-2-700 {
  background-color: #73287a !important;
  fill: #73287a !important;
}

.bg-accent-2-700-hover:hover {
  background-color: #73287a !important;
}

.border-accent-2-700 {
  border-color: #73287a !important;
  stroke: #73287a !important;
}

.border-accent-2-700-hover:hover {
  border-color: #73287a !important;
}

.text-accent-2-800 {
  color: #641b6b !important;
  fill: #641b6b !important;
}

.text-accent-2-800-hover:hover {
  color: #641b6b !important;
  fill: #641b6b !important;
}

.bg-accent-2-800 {
  background-color: #641b6b !important;
  fill: #641b6b !important;
}

.bg-accent-2-800-hover:hover {
  background-color: #641b6b !important;
}

.border-accent-2-800 {
  border-color: #641b6b !important;
  stroke: #641b6b !important;
}

.border-accent-2-800-hover:hover {
  border-color: #641b6b !important;
}

.text-accent-2-900 {
  color: #501555 !important;
  fill: #501555 !important;
}

.text-accent-2-900-hover:hover {
  color: #501555 !important;
  fill: #501555 !important;
}

.bg-accent-2-900 {
  background-color: #501555 !important;
  fill: #501555 !important;
}

.bg-accent-2-900-hover:hover {
  background-color: #501555 !important;
}

.border-accent-2-900 {
  border-color: #501555 !important;
  stroke: #501555 !important;
}

.border-accent-2-900-hover:hover {
  border-color: #501555 !important;
}

.text-accent-3-50 {
  color: #f2fbfe !important;
  fill: #f2fbfe !important;
}

.text-accent-3-50-hover:hover {
  color: #f2fbfe !important;
  fill: #f2fbfe !important;
}

.bg-accent-3-50 {
  background-color: #f2fbfe !important;
  fill: #f2fbfe !important;
}

.bg-accent-3-50-hover:hover {
  background-color: #f2fbfe !important;
}

.border-accent-3-50 {
  border-color: #f2fbfe !important;
  stroke: #f2fbfe !important;
}

.border-accent-3-50-hover:hover {
  border-color: #f2fbfe !important;
}

.text-accent-3-100 {
  color: #e9f8fd !important;
  fill: #e9f8fd !important;
}

.text-accent-3-100-hover:hover {
  color: #e9f8fd !important;
  fill: #e9f8fd !important;
}

.bg-accent-3-100 {
  background-color: #e9f8fd !important;
  fill: #e9f8fd !important;
}

.bg-accent-3-100-hover:hover {
  background-color: #e9f8fd !important;
}

.border-accent-3-100 {
  border-color: #e9f8fd !important;
  stroke: #e9f8fd !important;
}

.border-accent-3-100-hover:hover {
  border-color: #e9f8fd !important;
}

.text-accent-3-200 {
  color: #dceff9 !important;
  fill: #dceff9 !important;
}

.text-accent-3-200-hover:hover {
  color: #dceff9 !important;
  fill: #dceff9 !important;
}

.bg-accent-3-200 {
  background-color: #dceff9 !important;
  fill: #dceff9 !important;
}

.bg-accent-3-200-hover:hover {
  background-color: #dceff9 !important;
}

.border-accent-3-200 {
  border-color: #dceff9 !important;
  stroke: #dceff9 !important;
}

.border-accent-3-200-hover:hover {
  border-color: #dceff9 !important;
}

.text-accent-3-300 {
  color: #b4d5e9 !important;
  fill: #b4d5e9 !important;
}

.text-accent-3-300-hover:hover {
  color: #b4d5e9 !important;
  fill: #b4d5e9 !important;
}

.bg-accent-3-300 {
  background-color: #b4d5e9 !important;
  fill: #b4d5e9 !important;
}

.bg-accent-3-300-hover:hover {
  background-color: #b4d5e9 !important;
}

.border-accent-3-300 {
  border-color: #b4d5e9 !important;
  stroke: #b4d5e9 !important;
}

.border-accent-3-300-hover:hover {
  border-color: #b4d5e9 !important;
}

.text-accent-3-400 {
  color: #85b7d4 !important;
  fill: #85b7d4 !important;
}

.text-accent-3-400-hover:hover {
  color: #85b7d4 !important;
  fill: #85b7d4 !important;
}

.bg-accent-3-400 {
  background-color: #85b7d4 !important;
  fill: #85b7d4 !important;
}

.bg-accent-3-400-hover:hover {
  background-color: #85b7d4 !important;
}

.border-accent-3-400 {
  border-color: #85b7d4 !important;
  stroke: #85b7d4 !important;
}

.border-accent-3-400-hover:hover {
  border-color: #85b7d4 !important;
}

.text-accent-3-500 {
  color: #6598b5 !important;
  fill: #6598b5 !important;
}

.text-accent-3-500-hover:hover {
  color: #6598b5 !important;
  fill: #6598b5 !important;
}

.bg-accent-3-500 {
  background-color: #6598b5 !important;
  fill: #6598b5 !important;
}

.bg-accent-3-500-hover:hover {
  background-color: #6598b5 !important;
}

.border-accent-3-500 {
  border-color: #6598b5 !important;
  stroke: #6598b5 !important;
}

.border-accent-3-500-hover:hover {
  border-color: #6598b5 !important;
}

.text-accent-3-600 {
  color: #507e99 !important;
  fill: #507e99 !important;
}

.text-accent-3-600-hover:hover {
  color: #507e99 !important;
  fill: #507e99 !important;
}

.bg-accent-3-600 {
  background-color: #507e99 !important;
  fill: #507e99 !important;
}

.bg-accent-3-600-hover:hover {
  background-color: #507e99 !important;
}

.border-accent-3-600 {
  border-color: #507e99 !important;
  stroke: #507e99 !important;
}

.border-accent-3-600-hover:hover {
  border-color: #507e99 !important;
}

.text-accent-3-700 {
  color: #416a82 !important;
  fill: #416a82 !important;
}

.text-accent-3-700-hover:hover {
  color: #416a82 !important;
  fill: #416a82 !important;
}

.bg-accent-3-700 {
  background-color: #416a82 !important;
  fill: #416a82 !important;
}

.bg-accent-3-700-hover:hover {
  background-color: #416a82 !important;
}

.border-accent-3-700 {
  border-color: #416a82 !important;
  stroke: #416a82 !important;
}

.border-accent-3-700-hover:hover {
  border-color: #416a82 !important;
}

.text-accent-3-800 {
  color: #345569 !important;
  fill: #345569 !important;
}

.text-accent-3-800-hover:hover {
  color: #345569 !important;
  fill: #345569 !important;
}

.bg-accent-3-800 {
  background-color: #345569 !important;
  fill: #345569 !important;
}

.bg-accent-3-800-hover:hover {
  background-color: #345569 !important;
}

.border-accent-3-800 {
  border-color: #345569 !important;
  stroke: #345569 !important;
}

.border-accent-3-800-hover:hover {
  border-color: #345569 !important;
}

.text-accent-3-900 {
  color: #284454 !important;
  fill: #284454 !important;
}

.text-accent-3-900-hover:hover {
  color: #284454 !important;
  fill: #284454 !important;
}

.bg-accent-3-900 {
  background-color: #284454 !important;
  fill: #284454 !important;
}

.bg-accent-3-900-hover:hover {
  background-color: #284454 !important;
}

.border-accent-3-900 {
  border-color: #284454 !important;
  stroke: #284454 !important;
}

.border-accent-3-900-hover:hover {
  border-color: #284454 !important;
}

.text-accent-4-50 {
  color: #fef7ef !important;
  fill: #fef7ef !important;
}

.text-accent-4-50-hover:hover {
  color: #fef7ef !important;
  fill: #fef7ef !important;
}

.bg-accent-4-50 {
  background-color: #fef7ef !important;
  fill: #fef7ef !important;
}

.bg-accent-4-50-hover:hover {
  background-color: #fef7ef !important;
}

.border-accent-4-50 {
  border-color: #fef7ef !important;
  stroke: #fef7ef !important;
}

.border-accent-4-50-hover:hover {
  border-color: #fef7ef !important;
}

.text-accent-4-100 {
  color: #fdefde !important;
  fill: #fdefde !important;
}

.text-accent-4-100-hover:hover {
  color: #fdefde !important;
  fill: #fdefde !important;
}

.bg-accent-4-100 {
  background-color: #fdefde !important;
  fill: #fdefde !important;
}

.bg-accent-4-100-hover:hover {
  background-color: #fdefde !important;
}

.border-accent-4-100 {
  border-color: #fdefde !important;
  stroke: #fdefde !important;
}

.border-accent-4-100-hover:hover {
  border-color: #fdefde !important;
}

.text-accent-4-200 {
  color: #fceddb !important;
  fill: #fceddb !important;
}

.text-accent-4-200-hover:hover {
  color: #fceddb !important;
  fill: #fceddb !important;
}

.bg-accent-4-200 {
  background-color: #fceddb !important;
  fill: #fceddb !important;
}

.bg-accent-4-200-hover:hover {
  background-color: #fceddb !important;
}

.border-accent-4-200 {
  border-color: #fceddb !important;
  stroke: #fceddb !important;
}

.border-accent-4-200-hover:hover {
  border-color: #fceddb !important;
}

.text-accent-4-300 {
  color: #fadbb6 !important;
  fill: #fadbb6 !important;
}

.text-accent-4-300-hover:hover {
  color: #fadbb6 !important;
  fill: #fadbb6 !important;
}

.bg-accent-4-300 {
  background-color: #fadbb6 !important;
  fill: #fadbb6 !important;
}

.bg-accent-4-300-hover:hover {
  background-color: #fadbb6 !important;
}

.border-accent-4-300 {
  border-color: #fadbb6 !important;
  stroke: #fadbb6 !important;
}

.border-accent-4-300-hover:hover {
  border-color: #fadbb6 !important;
}

.text-accent-4-400 {
  color: #f7c992 !important;
  fill: #f7c992 !important;
}

.text-accent-4-400-hover:hover {
  color: #f7c992 !important;
  fill: #f7c992 !important;
}

.bg-accent-4-400 {
  background-color: #f7c992 !important;
  fill: #f7c992 !important;
}

.bg-accent-4-400-hover:hover {
  background-color: #f7c992 !important;
}

.border-accent-4-400 {
  border-color: #f7c992 !important;
  stroke: #f7c992 !important;
}

.border-accent-4-400-hover:hover {
  border-color: #f7c992 !important;
}

.text-accent-4-500 {
  color: #f4b86d !important;
  fill: #f4b86d !important;
}

.text-accent-4-500-hover:hover {
  color: #f4b86d !important;
  fill: #f4b86d !important;
}

.bg-accent-4-500 {
  background-color: #f4b86d !important;
  fill: #f4b86d !important;
}

.bg-accent-4-500-hover:hover {
  background-color: #f4b86d !important;
}

.border-accent-4-500 {
  border-color: #f4b86d !important;
  stroke: #f4b86d !important;
}

.border-accent-4-500-hover:hover {
  border-color: #f4b86d !important;
}

.text-accent-4-600 {
  color: #ec8200 !important;
  fill: #ec8200 !important;
}

.text-accent-4-600-hover:hover {
  color: #ec8200 !important;
  fill: #ec8200 !important;
}

.bg-accent-4-600 {
  background-color: #ec8200 !important;
  fill: #ec8200 !important;
}

.bg-accent-4-600-hover:hover {
  background-color: #ec8200 !important;
}

.border-accent-4-600 {
  border-color: #ec8200 !important;
  stroke: #ec8200 !important;
}

.border-accent-4-600-hover:hover {
  border-color: #ec8200 !important;
}

.text-accent-4-700 {
  color: #c56c00 !important;
  fill: #c56c00 !important;
}

.text-accent-4-700-hover:hover {
  color: #c56c00 !important;
  fill: #c56c00 !important;
}

.bg-accent-4-700 {
  background-color: #c56c00 !important;
  fill: #c56c00 !important;
}

.bg-accent-4-700-hover:hover {
  background-color: #c56c00 !important;
}

.border-accent-4-700 {
  border-color: #c56c00 !important;
  stroke: #c56c00 !important;
}

.border-accent-4-700-hover:hover {
  border-color: #c56c00 !important;
}

.text-accent-4-800 {
  color: #9d5700 !important;
  fill: #9d5700 !important;
}

.text-accent-4-800-hover:hover {
  color: #9d5700 !important;
  fill: #9d5700 !important;
}

.bg-accent-4-800 {
  background-color: #9d5700 !important;
  fill: #9d5700 !important;
}

.bg-accent-4-800-hover:hover {
  background-color: #9d5700 !important;
}

.border-accent-4-800 {
  border-color: #9d5700 !important;
  stroke: #9d5700 !important;
}

.border-accent-4-800-hover:hover {
  border-color: #9d5700 !important;
}

.text-accent-4-900 {
  color: #764100 !important;
  fill: #764100 !important;
}

.text-accent-4-900-hover:hover {
  color: #764100 !important;
  fill: #764100 !important;
}

.bg-accent-4-900 {
  background-color: #764100 !important;
  fill: #764100 !important;
}

.bg-accent-4-900-hover:hover {
  background-color: #764100 !important;
}

.border-accent-4-900 {
  border-color: #764100 !important;
  stroke: #764100 !important;
}

.border-accent-4-900-hover:hover {
  border-color: #764100 !important;
}

.text-accent-5-50 {
  color: #f2fdfb !important;
  fill: #f2fdfb !important;
}

.text-accent-5-50-hover:hover {
  color: #f2fdfb !important;
  fill: #f2fdfb !important;
}

.bg-accent-5-50 {
  background-color: #f2fdfb !important;
  fill: #f2fdfb !important;
}

.bg-accent-5-50-hover:hover {
  background-color: #f2fdfb !important;
}

.border-accent-5-50 {
  border-color: #f2fdfb !important;
  stroke: #f2fdfb !important;
}

.border-accent-5-50-hover:hover {
  border-color: #f2fdfb !important;
}

.text-accent-5-100 {
  color: #f0fcf9 !important;
  fill: #f0fcf9 !important;
}

.text-accent-5-100-hover:hover {
  color: #f0fcf9 !important;
  fill: #f0fcf9 !important;
}

.bg-accent-5-100 {
  background-color: #f0fcf9 !important;
  fill: #f0fcf9 !important;
}

.bg-accent-5-100-hover:hover {
  background-color: #f0fcf9 !important;
}

.border-accent-5-100 {
  border-color: #f0fcf9 !important;
  stroke: #f0fcf9 !important;
}

.border-accent-5-100-hover:hover {
  border-color: #f0fcf9 !important;
}

.text-accent-5-200 {
  color: #e9f5f4 !important;
  fill: #e9f5f4 !important;
}

.text-accent-5-200-hover:hover {
  color: #e9f5f4 !important;
  fill: #e9f5f4 !important;
}

.bg-accent-5-200 {
  background-color: #e9f5f4 !important;
  fill: #e9f5f4 !important;
}

.bg-accent-5-200-hover:hover {
  background-color: #e9f5f4 !important;
}

.border-accent-5-200 {
  border-color: #e9f5f4 !important;
  stroke: #e9f5f4 !important;
}

.border-accent-5-200-hover:hover {
  border-color: #e9f5f4 !important;
}

.text-accent-5-300 {
  color: #c6eae1 !important;
  fill: #c6eae1 !important;
}

.text-accent-5-300-hover:hover {
  color: #c6eae1 !important;
  fill: #c6eae1 !important;
}

.bg-accent-5-300 {
  background-color: #c6eae1 !important;
  fill: #c6eae1 !important;
}

.bg-accent-5-300-hover:hover {
  background-color: #c6eae1 !important;
}

.border-accent-5-300 {
  border-color: #c6eae1 !important;
  stroke: #c6eae1 !important;
}

.border-accent-5-300-hover:hover {
  border-color: #c6eae1 !important;
}

.text-accent-5-400 {
  color: #31ac8d !important;
  fill: #31ac8d !important;
}

.text-accent-5-400-hover:hover {
  color: #31ac8d !important;
  fill: #31ac8d !important;
}

.bg-accent-5-400 {
  background-color: #31ac8d !important;
  fill: #31ac8d !important;
}

.bg-accent-5-400-hover:hover {
  background-color: #31ac8d !important;
}

.border-accent-5-400 {
  border-color: #31ac8d !important;
  stroke: #31ac8d !important;
}

.border-accent-5-400-hover:hover {
  border-color: #31ac8d !important;
}

.text-accent-5-500 {
  color: #0f9e7a !important;
  fill: #0f9e7a !important;
}

.text-accent-5-500-hover:hover {
  color: #0f9e7a !important;
  fill: #0f9e7a !important;
}

.bg-accent-5-500 {
  background-color: #0f9e7a !important;
  fill: #0f9e7a !important;
}

.bg-accent-5-500-hover:hover {
  background-color: #0f9e7a !important;
}

.border-accent-5-500 {
  border-color: #0f9e7a !important;
  stroke: #0f9e7a !important;
}

.border-accent-5-500-hover:hover {
  border-color: #0f9e7a !important;
}

.text-accent-5-600 {
  color: #0c8466 !important;
  fill: #0c8466 !important;
}

.text-accent-5-600-hover:hover {
  color: #0c8466 !important;
  fill: #0c8466 !important;
}

.bg-accent-5-600 {
  background-color: #0c8466 !important;
  fill: #0c8466 !important;
}

.bg-accent-5-600-hover:hover {
  background-color: #0c8466 !important;
}

.border-accent-5-600 {
  border-color: #0c8466 !important;
  stroke: #0c8466 !important;
}

.border-accent-5-600-hover:hover {
  border-color: #0c8466 !important;
}

.text-accent-5-700 {
  color: #13725a !important;
  fill: #13725a !important;
}

.text-accent-5-700-hover:hover {
  color: #13725a !important;
  fill: #13725a !important;
}

.bg-accent-5-700 {
  background-color: #13725a !important;
  fill: #13725a !important;
}

.bg-accent-5-700-hover:hover {
  background-color: #13725a !important;
}

.border-accent-5-700 {
  border-color: #13725a !important;
  stroke: #13725a !important;
}

.border-accent-5-700-hover:hover {
  border-color: #13725a !important;
}

.text-accent-5-800 {
  color: #0a6951 !important;
  fill: #0a6951 !important;
}

.text-accent-5-800-hover:hover {
  color: #0a6951 !important;
  fill: #0a6951 !important;
}

.bg-accent-5-800 {
  background-color: #0a6951 !important;
  fill: #0a6951 !important;
}

.bg-accent-5-800-hover:hover {
  background-color: #0a6951 !important;
}

.border-accent-5-800 {
  border-color: #0a6951 !important;
  stroke: #0a6951 !important;
}

.border-accent-5-800-hover:hover {
  border-color: #0a6951 !important;
}

.text-accent-5-900 {
  color: #084f3d !important;
  fill: #084f3d !important;
}

.text-accent-5-900-hover:hover {
  color: #084f3d !important;
  fill: #084f3d !important;
}

.bg-accent-5-900 {
  background-color: #084f3d !important;
  fill: #084f3d !important;
}

.bg-accent-5-900-hover:hover {
  background-color: #084f3d !important;
}

.border-accent-5-900 {
  border-color: #084f3d !important;
  stroke: #084f3d !important;
}

.border-accent-5-900-hover:hover {
  border-color: #084f3d !important;
}

.text-accent-6-50 {
  color: #eef0f8 !important;
  fill: #eef0f8 !important;
}

.text-accent-6-50-hover:hover {
  color: #eef0f8 !important;
  fill: #eef0f8 !important;
}

.bg-accent-6-50 {
  background-color: #eef0f8 !important;
  fill: #eef0f8 !important;
}

.bg-accent-6-50-hover:hover {
  background-color: #eef0f8 !important;
}

.border-accent-6-50 {
  border-color: #eef0f8 !important;
  stroke: #eef0f8 !important;
}

.border-accent-6-50-hover:hover {
  border-color: #eef0f8 !important;
}

.text-accent-6-100 {
  color: #e3e6f4 !important;
  fill: #e3e6f4 !important;
}

.text-accent-6-100-hover:hover {
  color: #e3e6f4 !important;
  fill: #e3e6f4 !important;
}

.bg-accent-6-100 {
  background-color: #e3e6f4 !important;
  fill: #e3e6f4 !important;
}

.bg-accent-6-100-hover:hover {
  background-color: #e3e6f4 !important;
}

.border-accent-6-100 {
  border-color: #e3e6f4 !important;
  stroke: #e3e6f4 !important;
}

.border-accent-6-100-hover:hover {
  border-color: #e3e6f4 !important;
}

.text-accent-6-200 {
  color: #c0c8e7 !important;
  fill: #c0c8e7 !important;
}

.text-accent-6-200-hover:hover {
  color: #c0c8e7 !important;
  fill: #c0c8e7 !important;
}

.bg-accent-6-200 {
  background-color: #c0c8e7 !important;
  fill: #c0c8e7 !important;
}

.bg-accent-6-200-hover:hover {
  background-color: #c0c8e7 !important;
}

.border-accent-6-200 {
  border-color: #c0c8e7 !important;
  stroke: #c0c8e7 !important;
}

.border-accent-6-200-hover:hover {
  border-color: #c0c8e7 !important;
}

.text-accent-6-300 {
  color: #aab5df !important;
  fill: #aab5df !important;
}

.text-accent-6-300-hover:hover {
  color: #aab5df !important;
  fill: #aab5df !important;
}

.bg-accent-6-300 {
  background-color: #aab5df !important;
  fill: #aab5df !important;
}

.bg-accent-6-300-hover:hover {
  background-color: #aab5df !important;
}

.border-accent-6-300 {
  border-color: #aab5df !important;
  stroke: #aab5df !important;
}

.border-accent-6-300-hover:hover {
  border-color: #aab5df !important;
}

.text-accent-6-400 {
  color: #7c8fce !important;
  fill: #7c8fce !important;
}

.text-accent-6-400-hover:hover {
  color: #7c8fce !important;
  fill: #7c8fce !important;
}

.bg-accent-6-400 {
  background-color: #7c8fce !important;
  fill: #7c8fce !important;
}

.bg-accent-6-400-hover:hover {
  background-color: #7c8fce !important;
}

.border-accent-6-400 {
  border-color: #7c8fce !important;
  stroke: #7c8fce !important;
}

.border-accent-6-400-hover:hover {
  border-color: #7c8fce !important;
}

.text-accent-6-500 {
  color: #566cbf !important;
  fill: #566cbf !important;
}

.text-accent-6-500-hover:hover {
  color: #566cbf !important;
  fill: #566cbf !important;
}

.bg-accent-6-500 {
  background-color: #566cbf !important;
  fill: #566cbf !important;
}

.bg-accent-6-500-hover:hover {
  background-color: #566cbf !important;
}

.border-accent-6-500 {
  border-color: #566cbf !important;
  stroke: #566cbf !important;
}

.border-accent-6-500-hover:hover {
  border-color: #566cbf !important;
}

.text-accent-6-600 {
  color: #495cb2 !important;
  fill: #495cb2 !important;
}

.text-accent-6-600-hover:hover {
  color: #495cb2 !important;
  fill: #495cb2 !important;
}

.bg-accent-6-600 {
  background-color: #495cb2 !important;
  fill: #495cb2 !important;
}

.bg-accent-6-600-hover:hover {
  background-color: #495cb2 !important;
}

.border-accent-6-600 {
  border-color: #495cb2 !important;
  stroke: #495cb2 !important;
}

.border-accent-6-600-hover:hover {
  border-color: #495cb2 !important;
}

.text-accent-6-700 {
  color: #3e51ad !important;
  fill: #3e51ad !important;
}

.text-accent-6-700-hover:hover {
  color: #3e51ad !important;
  fill: #3e51ad !important;
}

.bg-accent-6-700 {
  background-color: #3e51ad !important;
  fill: #3e51ad !important;
}

.bg-accent-6-700-hover:hover {
  background-color: #3e51ad !important;
}

.border-accent-6-700 {
  border-color: #3e51ad !important;
  stroke: #3e51ad !important;
}

.border-accent-6-700-hover:hover {
  border-color: #3e51ad !important;
}

.text-accent-6-800 {
  color: #2c3991 !important;
  fill: #2c3991 !important;
}

.text-accent-6-800-hover:hover {
  color: #2c3991 !important;
  fill: #2c3991 !important;
}

.bg-accent-6-800 {
  background-color: #2c3991 !important;
  fill: #2c3991 !important;
}

.bg-accent-6-800-hover:hover {
  background-color: #2c3991 !important;
}

.border-accent-6-800 {
  border-color: #2c3991 !important;
  stroke: #2c3991 !important;
}

.border-accent-6-800-hover:hover {
  border-color: #2c3991 !important;
}

.text-accent-6-900 {
  color: #01145f !important;
  fill: #01145f !important;
}

.text-accent-6-900-hover:hover {
  color: #01145f !important;
  fill: #01145f !important;
}

.bg-accent-6-900 {
  background-color: #01145f !important;
  fill: #01145f !important;
}

.bg-accent-6-900-hover:hover {
  background-color: #01145f !important;
}

.border-accent-6-900 {
  border-color: #01145f !important;
  stroke: #01145f !important;
}

.border-accent-6-900-hover:hover {
  border-color: #01145f !important;
}

.shadow-neutral-1 {
  box-shadow: 0 1px 5px 0 rgba(26, 26, 26, 0.08) !important;
}

.shadow-neutral-2 {
  box-shadow: 0 1px 3px 0 rgba(26, 26, 26, 0.22) !important;
}

.shadow-neutral-3, .k-drag-clue {
  box-shadow: 0 2px 5px 0 rgba(26, 26, 26, 0.16) !important;
}

.shadow-neutral-4 {
  box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14) !important;
}

.shadow-neutral-5 {
  box-shadow: 0 2px 10px 0 rgba(26, 26, 26, 0.14) !important;
}

.shadow-neutral-6 {
  box-shadow: 0 4px 8px 0 rgba(26, 26, 26, 0.07) !important;
}

.shadow-neutral-7 {
  box-shadow: 0 8px 50px 0 rgba(26, 26, 26, 0.08) !important;
}

.shadow-primary-1 {
  box-shadow: 0 1px 5px 0 rgba(0, 93, 117, 0.08) !important;
}

.shadow-primary-2 {
  box-shadow: 0 1px 3px 0 rgba(0, 93, 117, 0.36) !important;
}

.shadow-primary-3 {
  box-shadow: 0 2px 5px 0 rgba(0, 93, 117, 0.26) !important;
}

.shadow-primary-4 {
  box-shadow: 0 2px 7px 0 rgba(0, 93, 117, 0.2) !important;
}

.shadow-primary-5 {
  box-shadow: 0 2px 10px 0 rgba(0, 93, 117, 0.2) !important;
}

.shadow-primary-6 {
  box-shadow: 0 8px 16px 0 rgba(0, 93, 117, 0.15) !important;
}

.shadow-primary-7 {
  box-shadow: 0 8px 50px 0 rgba(0, 93, 117, 0.08) !important;
}

.inner-shadow-neutral-1 {
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06) !important;
}

.inner-shadow-neutral-2 {
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.22) !important;
}

.shadow-neutral-6-top, .drawer.position-bottom {
  box-shadow: 0 -4px 8px 0 rgba(26, 26, 26, 0.07) !important;
}

.shadow-neutral-6-left, .drawer.position-right {
  box-shadow: -4px 0 8px 0 rgba(26, 26, 26, 0.07) !important;
}

.shadow-neutral-6-right {
  box-shadow: 4px 0 8px 0 rgba(26, 26, 26, 0.07) !important;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.heading-70 {
  font-size: 2rem;
  line-height: 3rem;
  color: #333333;
  font-weight: 600;
}

.heading-50 {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #333333;
  font-weight: 600;
}

.heading-40 {
  font-size: 1.375rem;
  line-height: 2rem;
  color: #333333;
  font-weight: 600;
}

.heading-30, .drawer-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #333333;
  font-weight: 600;
}

.heading-20 {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #6d6d6d;
  font-weight: 600;
  text-transform: uppercase;
}

.subtitle-50 {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
}

.body-70, .card.f-sub-cards-container > .card-body > .card-text .card .card-title-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #333333;
}

.body-50, .k-dropzone-hint, .k-upload .k-upload-status, .k-upload .k-upload-files .k-file-size, .empty-state-note, .dropdown .btn-with-initials {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #333333;
}

.body-40 {
  font-size: 0.6875rem;
  line-height: 0.875rem;
  color: #333333;
}

.body-30 {
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #333333;
}

.link-70 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.link-50, .k-upload-button .k-button-text {
  font-size: 0.75rem;
  line-height: 1rem;
}

@media screen and (max-width: 599px) {
  .increased-baseline-size .heading-70 {
    font-size: 1.375rem;
    line-height: 1.65rem;
  }
}
@media screen and (max-width: 599px) {
  .increased-baseline-size .heading-50 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 599px) {
  .increased-baseline-size .heading-40 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 599px) {
  .increased-baseline-size .heading-30, .increased-baseline-size .drawer-title {
    font-size: 1rem;
    line-height: 1.26315rem;
  }
}
@media screen and (max-width: 599px) {
  .increased-baseline-size .subtitle-50 {
    font-size: 1rem;
    line-height: 1.26315rem;
  }
}
.link-primary, .k-upload-button .k-button-text, .k-grid a.link-primary, .k-grid .breadcrumb-item a, .breadcrumb-item .k-grid a, .k-grid a.inner-error-link, .k-grid a.progress-action, .k-grid a.suggester-add-item-link, .k-grid a.action-item, .action-item, .suggester-add-item-link, .progress-action, .popover .popover-dismiss-link, .inner-error-link, .breadcrumb-item a {
  color: #005d75;
}
.link-primary:hover, .k-upload-button .k-button-text:hover, .k-grid a.link-primary:hover, .action-item:hover, .suggester-add-item-link:hover, .progress-action:hover, .popover .popover-dismiss-link:hover, .inner-error-link:hover, .breadcrumb-item a:hover {
  text-decoration: underline;
}
.link-primary:hover, .k-upload-button .k-button-text:hover, .k-grid a.link-primary:hover, .action-item:hover, .suggester-add-item-link:hover, .progress-action:hover, .popover .popover-dismiss-link:hover, .inner-error-link:hover, .breadcrumb-item a:hover {
  color: #005063;
}
.link-primary:focus-visible, .k-upload-button .k-button-text:focus-visible, .k-grid a.link-primary:focus-visible, .action-item:focus-visible, .suggester-add-item-link:focus-visible, .progress-action:focus-visible, .popover .popover-dismiss-link:focus-visible, .inner-error-link:focus-visible, .breadcrumb-item a:focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
  color: #005d75;
}
.link-primary:active, .k-upload-button .k-button-text:active, .k-grid a.link-primary:active, .action-item:active, .suggester-add-item-link:active, .progress-action:active, .popover .popover-dismiss-link:active, .inner-error-link:active, .breadcrumb-item a:active {
  color: #004759;
}
.link-primary:disabled, .k-upload-button .k-button-text:disabled, .k-grid a.link-primary:disabled, .action-item:disabled, .suggester-add-item-link:disabled, .progress-action:disabled, .popover .popover-dismiss-link:disabled, .inner-error-link:disabled, .breadcrumb-item a:disabled, .link-primary[disabled], .k-upload-button [disabled].k-button-text, .k-grid a[disabled].link-primary, [disabled].action-item, [disabled].suggester-add-item-link, [disabled].progress-action, .popover [disabled].popover-dismiss-link, [disabled].inner-error-link, .breadcrumb-item a[disabled], .link-primary.disabled, .k-upload-button .disabled.k-button-text, .k-grid a.disabled.link-primary, .disabled.action-item, .disabled.suggester-add-item-link, .disabled.progress-action, .popover .disabled.popover-dismiss-link, .disabled.inner-error-link, .breadcrumb-item a.disabled, .link-primary.link-disabled, .k-upload-button .link-disabled.k-button-text, .k-upload.k-disabled .link-primary.k-button-text, .k-upload.k-disabled .k-upload-button .k-button-text, .k-upload-button .k-upload.k-disabled .k-button-text, .k-grid a.link-disabled.link-primary, .link-disabled.action-item, .k-upload.k-disabled .action-item.k-button-text, .link-disabled.suggester-add-item-link, .k-upload.k-disabled .suggester-add-item-link.k-button-text, .link-disabled.progress-action, .k-upload.k-disabled .progress-action.k-button-text, .popover .link-disabled.popover-dismiss-link, .popover .k-upload.k-disabled .popover-dismiss-link.k-button-text, .k-upload.k-disabled .popover .popover-dismiss-link.k-button-text, .link-disabled.inner-error-link, .k-upload.k-disabled .inner-error-link.k-button-text, .breadcrumb-item a.link-disabled, .breadcrumb-item .k-upload.k-disabled a.k-button-text, .k-upload.k-disabled .breadcrumb-item a.k-button-text {
  color: #aedae5;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.link-main, .k-grid a.link-main {
  color: #333333;
}
.link-main:hover, .k-grid a.link-main:hover {
  text-decoration: underline;
}
.link-main:hover, .k-grid a.link-main:hover {
  color: #005d75;
}
.link-main:focus-visible, .k-grid a.link-main:focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
  color: #333333;
}
.link-main:active, .k-grid a.link-main:active {
  color: #005063;
}
.link-main:disabled, .k-grid a.link-main:disabled, .link-main[disabled], .k-grid a[disabled].link-main, .link-main.disabled, .k-grid a.disabled.link-main, .link-main.link-disabled, .k-upload.k-disabled .link-main.k-button-text, .k-grid a.link-disabled.link-main {
  color: #adadad;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.link-secondary, .k-grid a.link-secondary, .k-grid a.step-link, .step-link {
  color: #999999;
}
.link-secondary:hover, .k-grid a.link-secondary:hover, .step-link:hover {
  text-decoration: underline;
}
.link-secondary:hover, .k-grid a.link-secondary:hover, .step-link:hover {
  color: #006d89;
}
.link-secondary:focus-visible, .k-grid a.link-secondary:focus-visible, .step-link:focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
  color: #999999;
}
.link-secondary:active, .k-grid a.link-secondary:active, .step-link:active {
  color: #005d75;
}
.link-secondary:disabled, .k-grid a.link-secondary:disabled, .step-link:disabled, .link-secondary[disabled], .k-grid a[disabled].link-secondary, [disabled].step-link, .link-secondary.disabled, .k-grid a.disabled.link-secondary, .disabled.step-link, .link-secondary.link-disabled, .k-upload.k-disabled .link-secondary.k-button-text, .k-grid a.link-disabled.link-secondary, .link-disabled.step-link, .k-upload.k-disabled .step-link.k-button-text {
  color: #cececf;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.link-success, .k-grid a.link-success {
  color: #3c9d4d;
}
.link-success:hover, .k-grid a.link-success:hover {
  text-decoration: underline;
}
.link-success:hover, .k-grid a.link-success:hover {
  color: #7dbe88;
}
.link-success:focus-visible, .k-grid a.link-success:focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px rgba(92, 174, 106, 0.75);
  color: #3c9d4d;
}
.link-success:active, .k-grid a.link-success:active {
  color: #5cae6a;
}
.link-success:disabled, .k-grid a.link-success:disabled, .link-success[disabled], .k-grid a[disabled].link-success, .link-success.disabled, .k-grid a.disabled.link-success, .link-success.link-disabled, .k-upload.k-disabled .link-success.k-button-text, .k-grid a.link-disabled.link-success {
  color: #bedec4;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.link-warning, .k-grid a.link-warning {
  color: #e9b350;
}
.link-warning:hover, .k-grid a.link-warning:hover {
  text-decoration: underline;
}
.link-warning:hover, .k-grid a.link-warning:hover {
  color: #f2cb6f;
}
.link-warning:focus-visible, .k-grid a.link-warning:focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px rgba(240, 193, 93, 0.75);
  color: #e9b350;
}
.link-warning:active, .k-grid a.link-warning:active {
  color: #f0c15d;
}
.link-warning:disabled, .k-grid a.link-warning:disabled, .link-warning[disabled], .k-grid a[disabled].link-warning, .link-warning.disabled, .k-grid a.disabled.link-warning, .link-warning.link-disabled, .k-upload.k-disabled .link-warning.k-button-text, .k-grid a.link-disabled.link-warning {
  color: #fadf96;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.link-danger, .k-grid a.link-danger {
  color: #b20040;
}
.link-danger:hover, .k-grid a.link-danger:hover {
  text-decoration: underline;
}
.link-danger:hover, .k-grid a.link-danger:hover {
  color: #d83159;
}
.link-danger:focus-visible, .k-grid a.link-danger:focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px rgba(216, 49, 89, 0.75);
  color: #b20040;
}
.link-danger:disabled, .k-grid a.link-danger:disabled, .link-danger[disabled], .k-grid a[disabled].link-danger, .link-danger.disabled, .k-grid a.disabled.link-danger, .link-danger.link-disabled, .k-upload.k-disabled .link-danger.k-button-text, .k-grid a.link-disabled.link-danger {
  color: #f3a7c3;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.link-base {
  color: #ffffff;
}
.link-base:hover {
  text-decoration: underline;
}
.link-base:hover {
  color: #ffffff;
}
.link-base:focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px white;
  color: #ffffff;
}
.link-base:disabled, .link-base[disabled], .link-base.disabled, .link-base.link-disabled, .k-upload.k-disabled .link-base.k-button-text {
  color: rgba(255, 255, 255, 0.7);
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.link, .k-upload-button .k-button-text, .k-grid a, .action-item, .suggester-add-item-link, .progress-action, .popover .popover-dismiss-link, .inner-error-link, .breadcrumb-item a {
  cursor: pointer;
}
.link:focus, .k-upload-button .k-button-text:focus, .k-grid a:focus, .action-item:focus, .suggester-add-item-link:focus, .progress-action:focus, .popover .popover-dismiss-link:focus, .inner-error-link:focus, .breadcrumb-item a:focus {
  outline: none;
}
@media screen and (max-width: 599px) {
  .link:hover, .k-upload-button .k-button-text:hover, .k-grid a:hover, .action-item:hover, .suggester-add-item-link:hover, .progress-action:hover, .popover .popover-dismiss-link:hover, .inner-error-link:hover, .breadcrumb-item a:hover {
    text-decoration: none;
  }
}

a:disabled,
a[disabled],
a.disabled,
a.link-disabled,
.k-upload.k-disabled a.k-button-text {
  color: #888888;
  box-shadow: none;
  text-decoration: none;
  cursor: not-allowed;
}

.text-muted {
  color: #999999 !important;
}

.search-highlight {
  background-color: #fdefde;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.rounded-top-left-1 {
  border-top-left-radius: 2px !important;
}

.rounded-top-left-2 {
  border-top-left-radius: 3px !important;
}

.rounded-top-left-3 {
  border-top-left-radius: 10px !important;
}

.rounded-top-left-4 {
  border-top-left-radius: 5px !important;
}

.rounded-top-right-1 {
  border-top-right-radius: 2px !important;
}

.rounded-top-right-2 {
  border-top-right-radius: 3px !important;
}

.rounded-top-right-3 {
  border-top-right-radius: 10px !important;
}

.rounded-top-right-4 {
  border-top-right-radius: 5px !important;
}

.rounded-bottom-left-1 {
  border-bottom-left-radius: 2px !important;
}

.rounded-bottom-left-2 {
  border-bottom-left-radius: 3px !important;
}

.rounded-bottom-left-3 {
  border-bottom-left-radius: 10px !important;
}

.rounded-bottom-left-4 {
  border-bottom-left-radius: 5px !important;
}

.rounded-bottom-right-1 {
  border-bottom-right-radius: 2px !important;
}

.rounded-bottom-right-2 {
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom-right-3 {
  border-bottom-right-radius: 10px !important;
}

.rounded-bottom-right-4 {
  border-bottom-right-radius: 5px !important;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.border-dashed {
  border: 1px dashed #e6e6e6 !important;
}

.border-top-dashed {
  border-top: 1px dashed #e6e6e6 !important;
}

.border-end-dashed {
  border-right: 1px dashed #e6e6e6 !important;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #e6e6e6 !important;
}

.border-start-dashed {
  border-left: 1px dashed #e6e6e6 !important;
}

html {
  background-color: #f8f8f8;
}

.btn.btn-primary {
  background-color: #006d89;
  color: #ffffff;
}
@media (hover: hover) {
  .btn.btn-primary:hover, .btn.btn-primary:hover:focus-visible, .btn.btn-primary:hover:focus {
    background-color: #005d75;
    color: #ffffff;
  }
}
.btn.btn-primary:active, .btn.btn-primary:active:focus {
  background-color: #005063;
  box-shadow: none;
  color: #ffffff;
}
.btn.btn-primary:focus {
  background-color: #006d89;
  color: #ffffff;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-primary:focus-visible {
    background-color: #006d89;
    box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
  }
}
.btn.btn-primary:disabled {
  background-color: #66a7b8;
  color: rgba(255, 255, 255, 0.6);
}
.btn.btn-primary:disabled:hover {
  color: rgba(255, 255, 255, 0.6);
}
.btn.btn-secondary {
  background-color: #eeeeee;
  color: #6d6d6d;
}
@media (hover: hover) {
  .btn.btn-secondary:hover, .btn.btn-secondary:hover:focus-visible, .btn.btn-secondary:hover:focus {
    background-color: #e2e2e2;
    color: #6d6d6d;
  }
}
.btn.btn-secondary:active, .btn.btn-secondary:active:focus {
  background-color: #c2c2c2;
  box-shadow: none;
  color: #6d6d6d;
}
.btn.btn-secondary:focus {
  background-color: #eeeeee;
  color: #6d6d6d;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-secondary:focus-visible {
    background-color: #eeeeee;
    box-shadow: 0 0 0 2px rgba(0, 93, 117, 0.75);
  }
}
.btn.btn-secondary:disabled {
  background-color: #f5f5f5;
  color: rgba(109, 109, 109, 0.3);
}
.btn.btn-secondary:disabled:hover {
  color: rgba(109, 109, 109, 0.3);
}
.btn.btn-warning {
  background-color: #e9b350;
  color: #ffffff;
}
@media (hover: hover) {
  .btn.btn-warning:hover, .btn.btn-warning:hover:focus-visible, .btn.btn-warning:hover:focus {
    background-color: #d59f3c;
    color: #ffffff;
  }
}
.btn.btn-warning:active, .btn.btn-warning:active:focus {
  background-color: #c18b28;
  box-shadow: none;
  color: #ffffff;
}
.btn.btn-warning:focus {
  background-color: #e9b350;
  color: #ffffff;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-warning:focus-visible {
    background-color: #e9b350;
    box-shadow: 0 0 0 2px rgba(242, 203, 111, 0.75);
  }
}
.btn.btn-warning:disabled {
  background-color: #fcecc0;
  color: rgba(255, 255, 255, 0.7);
}
.btn.btn-warning:disabled:hover {
  color: rgba(255, 255, 255, 0.7);
}
.btn.btn-danger {
  background-color: #b20040;
  color: #ffffff;
}
@media (hover: hover) {
  .btn.btn-danger:hover, .btn.btn-danger:hover:focus-visible, .btn.btn-danger:hover:focus {
    background-color: #960036;
    color: #ffffff;
  }
}
.btn.btn-danger:active, .btn.btn-danger:active:focus {
  background-color: #880129;
  box-shadow: none;
  color: #ffffff;
}
.btn.btn-danger:focus {
  background-color: #b20040;
  color: #ffffff;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-danger:focus-visible {
    background-color: #b20040;
    box-shadow: 0 0 0 2px rgba(223, 107, 149, 0.75);
  }
}
.btn.btn-danger:disabled {
  background-color: #fadde6;
  color: rgba(255, 255, 255, 0.8);
}
.btn.btn-danger:disabled:hover {
  color: rgba(255, 255, 255, 0.8);
}
.btn.btn-outline {
  border: 1px solid;
  background-color: transparent;
  color: #999999;
  border-color: #cececf;
}
@media (hover: hover) {
  .btn.btn-outline:hover, .btn.btn-outline:hover:focus-visible, .btn.btn-outline:hover:focus {
    color: #999999;
    border-color: #adadad;
  }
}
.btn.btn-outline:active, .btn.btn-outline:active:focus {
  box-shadow: none;
  color: #333333;
  border-color: #999999;
}
.btn.btn-outline:focus {
  background-color: transparent;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-outline:focus-visible {
    box-shadow: 0 0 0 2px rgba(0, 93, 117, 0.75);
  }
}
.btn.btn-outline:disabled {
  background-color: transparent;
  color: rgba(153, 153, 153, 0.3);
  border-color: #e6e6e6;
}
.btn.btn-outline:disabled:hover {
  color: rgba(153, 153, 153, 0.3);
  border-color: #e6e6e6;
}
.btn.btn-link:hover, .btn.btn-link:hover:focus-visible {
  background-color: #f6fdff;
}
.btn.btn-link:focus-visible {
  background-color: transparent;
  box-shadow: 0 0 0 2px rgba(0, 93, 117, 0.75);
}
.btn.btn-link:active:focus {
  background-color: #ebf9fc;
}
.btn.btn-link:disabled {
  background-color: transparent;
  color: #d3eef5;
}
.btn.btn-icon.btn-primary:focus-visible {
  box-shadow: 0 0 0 2px rgba(0, 109, 137, 0.75), 0 2px 5px 0 rgba(0, 93, 117, 0.26);
}
.btn.btn-icon.btn-danger {
  background-color: #eeeeee;
  color: #6d6d6d;
}
@media (hover: hover) {
  .btn.btn-icon.btn-danger:hover, .btn.btn-icon.btn-danger:hover:focus-visible, .btn.btn-icon.btn-danger:hover:focus {
    background-color: #fff6f9;
    color: #d10f49;
  }
}
.btn.btn-icon.btn-danger:active, .btn.btn-icon.btn-danger:active:focus {
  background-color: #ffeff3;
  box-shadow: none;
}
.btn.btn-icon.btn-danger:focus {
  background-color: #eeeeee;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-icon.btn-danger:focus-visible {
    background-color: #d10f49;
    box-shadow: 0 0 0 2px rgba(223, 107, 149, 0.75);
  }
}
.btn.btn-icon.btn-danger:disabled {
  background-color: rgba(238, 238, 238, 0.5);
  color: rgba(109, 109, 109, 0.3);
}
.btn.btn-icon.btn-danger:disabled:hover {
  color: rgba(109, 109, 109, 0.3);
}
.btn.btn-icon.btn-danger:active:focus {
  color: #d10f49;
}
.btn.btn-icon.btn-danger:focus-visible {
  background-color: #fff6f9;
  color: #d10f49;
}
.btn.btn-simple-icon.btn-primary {
  background-color: transparent;
  color: #999999;
}
@media (hover: hover) {
  .btn.btn-simple-icon.btn-primary:hover, .btn.btn-simple-icon.btn-primary:hover:focus-visible, .btn.btn-simple-icon.btn-primary:hover:focus {
    color: #005d75;
  }
}
.btn.btn-simple-icon.btn-primary:active, .btn.btn-simple-icon.btn-primary:active:focus {
  background-color: transparent;
  box-shadow: none;
  color: #005063;
}
.btn.btn-simple-icon.btn-primary:focus {
  background-color: transparent;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-simple-icon.btn-primary:focus-visible {
    background-color: #ebf9fc;
    color: #005d75;
  }
}
.btn.btn-simple-icon.btn-primary:disabled {
  color: #999999;
}
.btn.btn-simple-icon.btn-primary:disabled:hover {
  color: #999999;
}
.btn.btn-simple-icon.btn-secondary {
  background-color: transparent;
  color: #999999;
}
@media (hover: hover) {
  .btn.btn-simple-icon.btn-secondary:hover, .btn.btn-simple-icon.btn-secondary:hover:focus-visible, .btn.btn-simple-icon.btn-secondary:hover:focus {
    color: #333333;
  }
}
.btn.btn-simple-icon.btn-secondary:active, .btn.btn-simple-icon.btn-secondary:active:focus {
  background-color: transparent;
  box-shadow: none;
  border-color: #292929;
}
.btn.btn-simple-icon.btn-secondary:focus {
  background-color: transparent;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-simple-icon.btn-secondary:focus-visible {
    background-color: #eeeeee;
    box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
    color: #333333;
  }
}
.btn.btn-simple-icon.btn-secondary:disabled {
  color: #999999;
}
.btn.btn-simple-icon.btn-secondary:disabled:hover {
  color: #999999;
}
.btn.btn-simple-icon.btn-danger {
  background-color: transparent;
  color: #999999;
}
@media (hover: hover) {
  .btn.btn-simple-icon.btn-danger:hover, .btn.btn-simple-icon.btn-danger:hover:focus-visible, .btn.btn-simple-icon.btn-danger:hover:focus {
    color: #d10f49;
  }
}
.btn.btn-simple-icon.btn-danger:active, .btn.btn-simple-icon.btn-danger:active:focus {
  background-color: transparent;
  box-shadow: none;
  color: #b20040;
}
.btn.btn-simple-icon.btn-danger:focus {
  background-color: transparent;
}
@media (hover: hover) and (min-width: 600px) {
  .btn.btn-simple-icon.btn-danger:focus-visible {
    background-color: #ffeff3;
  }
}
.btn.btn-simple-icon.btn-danger:disabled {
  color: #999999;
}
.btn.btn-simple-icon.btn-danger:disabled:hover {
  color: #999999;
}
.btn.btn-simple-icon:disabled {
  opacity: 0.3;
}
.btn-rounded {
  border-radius: 50%;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.show > .btn-primary.dropdown-toggle {
  background: #005063;
}
.show > .btn-primary.dropdown-toggle:focus-visible {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.show > .btn-secondary.dropdown-toggle {
  background-color: #c2c2c2;
  color: #6d6d6d;
}
.show > .btn-secondary.dropdown-toggle:focus-visible {
  box-shadow: 0 0 0 2px rgba(0, 93, 117, 0.75);
}

.show > .btn-outline.dropdown-toggle {
  border-color: #999999;
  color: #333333;
}

.show > .btn-simple-icon.dropdown-toggle {
  background-color: #eeeeee;
  color: #005d75;
}
.show > .btn-simple-icon.dropdown-toggle:focus {
  background-color: #eeeeee;
}

.badge-primary {
  color: #005063;
  background-color: #ebf9fc;
}

.badge-neutral {
  color: #6d6d6d;
  background-color: #f5f5f5;
}

.badge-neutral-secondary {
  color: #333333;
  background-color: #f8f8f8;
  box-shadow: 0 0 0 1px #cececf;
}

.badge-success {
  color: #125e1f;
  background-color: #eef5f0;
}

.badge-success-secondary {
  color: #ffffff;
  background-color: #7dbe88;
}

.badge-warning {
  color: #9d5700;
  background-color: #fff7e3;
}

.badge-danger {
  color: #880129;
  background-color: #ffeff3;
}

.badge-accent-1 {
  color: #7b1536;
  background-color: #fbeff2;
}

.badge-accent-2 {
  color: #641b6b;
  background-color: #f7eef8;
}

.badge-accent-3 {
  color: #345569;
  background-color: #dceff9;
}

.badge-accent-4 {
  color: #764100;
  background-color: #fdefde;
}

.badge-accent-5 {
  color: #13725a;
  background-color: #e9f5f4;
}

.form-control:hover, .input-group-right-icon:hover .form-control, .input-group-left-icon:hover .form-control, .input-group-side-icons:hover .form-control {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.form-control-outline:hover, .input-group-right-icon:hover .form-control-outline, .input-group-left-icon:hover .form-control-outline, .input-group-side-icons:hover .form-control-outline {
  border-color: #888888;
  background: transparent;
}

.multiple-suggester .form-control.input-focus, .form-control:focus, .form-control:focus:hover, .input-group-right-icon .form-control:focus, .input-group-right-icon .form-control:focus:hover, .input-group-side-icons .form-control:focus, .input-group-side-icons .form-control:focus:hover {
  background-color: #f8f8f8;
  border-color: #338ba1;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.form-control:disabled, .form-control.disabled, .input-group-right-icon:hover .form-control.disabled, .input-group-right-icon:hover .form-control:disabled, .input-group-left-icon:hover .form-control.disabled, .input-group-left-icon:hover .form-control:disabled, .input-group-side-icons:hover .form-control.disabled, .input-group-side-icons:hover .form-control:disabled {
  background-color: rgba(238, 238, 238, 0.5);
  border-color: rgba(238, 238, 238, 0.5);
  color: rgba(51, 51, 51, 0.5);
  box-shadow: none;
}

.form-control-outline:disabled, .form-control-outline.disabled, .input-group-right-icon:hover .form-control-outline.disabled, .input-group-right-icon:hover .form-control-outline:disabled, .input-group-left-icon:hover .form-control-outline.disabled, .input-group-left-icon:hover .form-control-outline:disabled, .input-group-side-icons:hover .form-control-outline.disabled, .input-group-side-icons:hover .form-control-outline:disabled {
  border-color: #e2e2e2;
  background: transparent;
  color: rgba(51, 51, 51, 0.5);
}

.form-control.is-valid:hover,
.form-select.is-valid:hover, .input-group-right-icon:hover .form-control.is-valid, .input-group-left-icon:hover .form-control.is-valid, .input-group-side-icons:hover .form-control.is-valid {
  background-color: rgba(238, 245, 240, 0.3);
  border-color: #9dcea6;
}

.form-control.is-valid:focus, .form-control.is-valid:focus:hover,
.form-select.is-valid:focus,
.form-select.is-valid:focus:hover {
  background-color: rgba(238, 245, 240, 0.3);
  border-color: #3c9d4d;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.k-daterangepicker.f-is-collapsed.is-warning.k-focus .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-focus.k-dateinput .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning.k-focus:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-dateinput:hover .k-dateinput, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput.k-focus, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-focus:hover,
.k-datepicker.is-warning.k-focus,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-focus.k-dateinput,
.k-datepicker.is-warning.k-focus:hover,
.k-timepicker.is-warning.k-focus,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-focus.k-dateinput,
.k-timepicker.is-warning.k-focus:hover, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput:hover,
.k-datepicker.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-dateinput:hover,
.k-timepicker.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-dateinput:hover, .form-control.is-warning:hover, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput:hover,
.form-select.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput:hover, .input-group-right-icon:hover .form-control.is-warning, .input-group-right-icon:hover .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .input-group-right-icon:hover .form-control.k-dateinput, .input-group-left-icon:hover .form-control.is-warning, .input-group-left-icon:hover .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .input-group-left-icon:hover .form-control.k-dateinput, .input-group-side-icons:hover .form-control.is-warning, .input-group-side-icons:hover .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .input-group-side-icons:hover .form-control.k-dateinput {
  background-color: rgba(255, 250, 235, 0.3);
  border-color: #f2cb6f;
}

.form-control.is-warning:focus, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput:focus, .form-control.is-warning:focus:hover,
.form-select.is-warning:focus,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput:focus,
.form-select.is-warning:focus:hover {
  background-color: rgba(255, 250, 235, 0.3);
  border-color: #e9b350;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.k-numerictextbox.is-invalid:hover, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-invalid.k-focus .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-focus.k-dateinput .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid.k-focus:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-dateinput:hover .k-dateinput, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput.k-focus, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-focus:hover,
.k-datepicker.is-invalid.k-focus,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-focus.k-dateinput,
.k-datepicker.is-invalid.k-focus:hover,
.k-timepicker.is-invalid.k-focus,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-focus.k-dateinput,
.k-timepicker.is-invalid.k-focus:hover, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput:hover,
.k-datepicker.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-dateinput:hover,
.k-timepicker.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-dateinput:hover, .form-control.is-invalid:hover, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput:hover,
.form-select.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput:hover, .input-group-right-icon:hover .form-control.is-invalid, .input-group-right-icon:hover .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .input-group-right-icon:hover .form-control.k-dateinput, .input-group-left-icon:hover .form-control.is-invalid, .input-group-left-icon:hover .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .input-group-left-icon:hover .form-control.k-dateinput, .input-group-side-icons:hover .form-control.is-invalid, .input-group-side-icons:hover .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .input-group-side-icons:hover .form-control.k-dateinput {
  background-color: rgba(255, 246, 249, 0.2);
  border-color: #d83159;
}

.k-numerictextbox.is-invalid.k-focus, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-focus.k-dateinput, .k-numerictextbox.is-invalid.k-focus:hover, .form-control.is-invalid:focus, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput:focus, .form-control.is-invalid:focus:hover,
.form-select.is-invalid:focus,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput:focus,
.form-select.is-invalid:focus:hover {
  background-color: rgba(255, 246, 249, 0.2);
  border-color: #b20040;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.input-group-right-icon .input-wrapper .f-i:hover,
.input-group-right-icon > .f-i:last-of-type:hover, .input-group-side-icons .input-wrapper .f-i:hover,
.input-group-side-icons > .f-i:last-of-type:hover {
  color: #005d75;
}
.input-group-right-icon .input-wrapper .f-i.disabled:hover,
.input-group-right-icon > .f-i:last-of-type.disabled:hover, .input-group-side-icons .input-wrapper .f-i.disabled:hover,
.input-group-side-icons > .f-i:last-of-type.disabled:hover {
  opacity: 0.3;
}
.input-group-right-icon, .input-group-left-icon, .input-group-side-icons {
  position: relative;
}
.input-group-right-icon .input-wrapper .f-i,
.input-group-right-icon > .f-i, .input-group-left-icon .input-wrapper .f-i,
.input-group-left-icon > .f-i, .input-group-side-icons .input-wrapper .f-i,
.input-group-side-icons > .f-i {
  color: #999999;
}
.input-group-right-icon .input-wrapper .f-i.f-i-close:hover,
.input-group-right-icon > .f-i.f-i-close:hover, .input-group-left-icon .input-wrapper .f-i.f-i-close:hover,
.input-group-left-icon > .f-i.f-i-close:hover, .input-group-side-icons .input-wrapper .f-i.f-i-close:hover,
.input-group-side-icons > .f-i.f-i-close:hover {
  color: #333333;
}
.input-group-right-icon .form-control-outline:focus, .input-group-right-icon .form-control-outline:focus:hover, .input-group-side-icons .form-control-outline:focus, .input-group-side-icons .form-control-outline:focus:hover {
  background: transparent;
  border-color: #338ba1;
}

.form-control:disabled::placeholder, .form-control.disabled::placeholder {
  color: rgba(51, 51, 51, 0.3);
}
.form-control:disabled ~ .f-i, .form-control.disabled ~ .f-i {
  color: rgba(153, 153, 153, 0.3);
}
.form-control-outline {
  background: transparent;
  border-color: #c2c2c2;
}
.form-control-outline:focus, .form-control-outline:focus:hover {
  background: transparent;
  border-color: #338ba1;
}
.form-control-outline:disabled::placeholder, .form-control-outline.disabled::placeholder {
  color: rgba(51, 51, 51, 0.3);
}
.form-control-rounded {
  border-radius: 2px;
}

.form-label-top {
  color: #6d6d6d;
}
.form-label-left {
  color: #6d6d6d;
}

.form-select:hover {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.form-select:focus {
  background-color: #f8f8f8;
  border-color: #338ba1;
}
.form-select:disabled, .form-select.disabled {
  background-color: rgba(238, 238, 238, 0.5);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1000pt" height="1000pt" viewBox="0 0 1000 1000" version="1.1"%3e%3cpath style=" stroke:none;fill-rule:evenodd;fill:%23999999;fill-opacity:0.3;" d="M 514.730469 610.269531 L 672.769531 452.230469 C 680.90625 444.09375 680.90625 430.90625 672.769531 422.769531 C 668.863281 418.863281 663.5625 416.667969 658.039062 416.667969 L 341.960938 416.667969 C 330.457031 416.667969 321.128906 425.992188 321.128906 437.5 C 321.128906 443.023438 323.324219 448.324219 327.230469 452.230469 L 485.269531 610.269531 C 493.40625 618.40625 506.59375 618.40625 514.730469 610.269531 Z M 514.730469 610.269531 "/%3e%3c/svg%3e');
  border-color: rgba(238, 238, 238, 0.5);
  color: rgba(51, 51, 51, 0.5);
}
.form-select:disabled ~ .form-icon, .form-select.disabled ~ .form-icon {
  color: rgba(153, 153, 153, 0.3);
}
.form-select option {
  background-color: #ffffff;
}

.form-select-outline {
  border-color: #c2c2c2;
  background-color: transparent;
}
.form-select-outline:hover {
  border-color: #888888;
  background-color: transparent;
}
.form-select-outline:focus {
  border-color: #338ba1;
  background-color: transparent;
}
.form-select-outline:disabled, .form-select-outline.disabled {
  border-color: #e2e2e2;
  background-color: transparent;
  color: rgba(51, 51, 51, 0.5);
}

.form-select-with-icon .form-icon {
  color: #999999;
}

.form-control.is-valid:hover,
.form-select.is-valid:hover, .input-group-right-icon:hover .form-control.is-valid, .input-group-left-icon:hover .form-control.is-valid, .input-group-side-icons:hover .form-control.is-valid {
  background-color: rgba(238, 245, 240, 0.3);
  border-color: #9dcea6;
}

.form-control.is-valid:focus, .form-control.is-valid:focus:hover,
.form-select.is-valid:focus,
.form-select.is-valid:focus:hover {
  background-color: rgba(238, 245, 240, 0.3);
  border-color: #3c9d4d;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.k-daterangepicker.f-is-collapsed.is-warning.k-focus .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-focus.k-dateinput .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning.k-focus:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-dateinput:hover .k-dateinput, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput.k-focus, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-focus:hover,
.k-datepicker.is-warning.k-focus,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-focus.k-dateinput,
.k-datepicker.is-warning.k-focus:hover,
.k-timepicker.is-warning.k-focus,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-focus.k-dateinput,
.k-timepicker.is-warning.k-focus:hover, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput:hover,
.k-datepicker.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-dateinput:hover,
.k-timepicker.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-dateinput:hover, .form-control.is-warning:hover, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput:hover,
.form-select.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput:hover, .input-group-right-icon:hover .form-control.is-warning, .input-group-right-icon:hover .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .input-group-right-icon:hover .form-control.k-dateinput, .input-group-left-icon:hover .form-control.is-warning, .input-group-left-icon:hover .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .input-group-left-icon:hover .form-control.k-dateinput, .input-group-side-icons:hover .form-control.is-warning, .input-group-side-icons:hover .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .input-group-side-icons:hover .form-control.k-dateinput {
  background-color: rgba(255, 250, 235, 0.3);
  border-color: #f2cb6f;
}

.form-control.is-warning:focus, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput:focus, .form-control.is-warning:focus:hover,
.form-select.is-warning:focus,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput:focus,
.form-select.is-warning:focus:hover {
  background-color: rgba(255, 250, 235, 0.3);
  border-color: #e9b350;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.k-numerictextbox.is-invalid:hover, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-invalid.k-focus .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-focus.k-dateinput .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid.k-focus:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-dateinput:hover .k-dateinput, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput.k-focus, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-focus:hover,
.k-datepicker.is-invalid.k-focus,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-focus.k-dateinput,
.k-datepicker.is-invalid.k-focus:hover,
.k-timepicker.is-invalid.k-focus,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-focus.k-dateinput,
.k-timepicker.is-invalid.k-focus:hover, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput:hover,
.k-datepicker.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-dateinput:hover,
.k-timepicker.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-dateinput:hover, .form-control.is-invalid:hover, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput:hover,
.form-select.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput:hover, .input-group-right-icon:hover .form-control.is-invalid, .input-group-right-icon:hover .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .input-group-right-icon:hover .form-control.k-dateinput, .input-group-left-icon:hover .form-control.is-invalid, .input-group-left-icon:hover .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .input-group-left-icon:hover .form-control.k-dateinput, .input-group-side-icons:hover .form-control.is-invalid, .input-group-side-icons:hover .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .input-group-side-icons:hover .form-control.k-dateinput {
  background-color: rgba(255, 246, 249, 0.2);
  border-color: #d83159;
}

.k-numerictextbox.is-invalid.k-focus, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-focus.k-dateinput, .k-numerictextbox.is-invalid.k-focus:hover, .form-control.is-invalid:focus, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput:focus, .form-control.is-invalid:focus:hover,
.form-select.is-invalid:focus,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput:focus,
.form-select.is-invalid:focus:hover {
  background-color: rgba(255, 246, 249, 0.2);
  border-color: #b20040;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}

.form-control.is-valid,
.form-select.is-valid {
  background-color: #eef5f0;
  border-color: #3c9d4d;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.form-control.is-valid ~ .f-i:first-of-type,
.form-select.is-valid ~ .f-i:first-of-type {
  color: #3c9d4d;
}
.form-control.is-warning, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput,
.form-select.is-warning,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput {
  background-color: #fffaeb;
  border-color: #e9b350;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.form-control.is-warning ~ .f-i:first-of-type, .k-daterangepicker.f-is-collapsed.is-warning .form-control.k-dateinput ~ .f-i:first-of-type,
.form-select.is-warning ~ .f-i:first-of-type,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.k-dateinput ~ .f-i:first-of-type {
  color: #e9b350;
}
.form-control.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput,
.form-select.is-invalid,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput {
  background-color: #fff6f9;
  border-color: #b20040;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.form-control.is-invalid ~ .f-i:first-of-type, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.k-dateinput ~ .f-i:first-of-type,
.form-select.is-invalid ~ .f-i:first-of-type,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.k-dateinput ~ .f-i:first-of-type {
  color: #b20040;
}

.form-control.form-control-outline.is-valid,
.form-select.form-select-outline.is-valid {
  background-color: #f7faf7;
}
.form-control.form-control-outline.is-warning, .k-daterangepicker.f-is-collapsed.is-warning .form-control.form-control-outline.k-dateinput,
.form-select.form-select-outline.is-warning,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.form-select-outline.k-dateinput {
  background-color: #fffaeb;
}
.form-control.form-control-outline.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.form-control-outline.k-dateinput,
.form-select.form-select-outline.is-invalid,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.form-select-outline.k-dateinput {
  background-color: #fff6f9;
}
.form-control.form-control-outline.is-valid:hover, .form-control.form-control-outline.is-warning:hover, .k-daterangepicker.f-is-collapsed.is-warning .form-control.form-control-outline.k-dateinput:hover, .form-control.form-control-outline.is-invalid:hover, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.form-control-outline.k-dateinput:hover,
.form-select.form-select-outline.is-valid:hover,
.form-select.form-select-outline.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.form-select-outline.k-dateinput:hover,
.form-select.form-select-outline.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.form-select-outline.k-dateinput:hover {
  background-color: transparent;
}
.form-control.form-control-outline.is-valid:focus, .form-control.form-control-outline.is-valid:focus:hover, .form-control.form-control-outline.is-warning:focus, .k-daterangepicker.f-is-collapsed.is-warning .form-control.form-control-outline.k-dateinput:focus, .form-control.form-control-outline.is-warning:focus:hover, .form-control.form-control-outline.is-invalid:focus, .k-daterangepicker.f-is-collapsed.is-invalid .form-control.form-control-outline.k-dateinput:focus, .form-control.form-control-outline.is-invalid:focus:hover,
.form-select.form-select-outline.is-valid:focus,
.form-select.form-select-outline.is-valid:focus:hover,
.form-select.form-select-outline.is-warning:focus,
.k-daterangepicker.f-is-collapsed.is-warning .form-select.form-select-outline.k-dateinput:focus,
.form-select.form-select-outline.is-warning:focus:hover,
.form-select.form-select-outline.is-invalid:focus,
.k-daterangepicker.f-is-collapsed.is-invalid .form-select.form-select-outline.k-dateinput:focus,
.form-select.form-select-outline.is-invalid:focus:hover {
  background-color: transparent;
}

.valid-feedback {
  color: #3c9d4d;
}

.warning-feedback {
  color: #d59f3c;
}

.invalid-feedback {
  color: #b20040;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.f-brand-logo {
  height: 24px;
  width: 42px;
  background-image: url("./../../../../../../assets/brandings/snp-light/logo.svg");
  top: -2px;
}

@media (min-width: 1024px) {
  .f-brand-logo:not(.f-brand-logo-static) {
    margin-right: 0.5rem;
  }
  .f-brand-logo:not(.f-brand-logo-static)::after {
    content: "";
    width: 1px;
    height: 26px;
    background-color: #e6e6e6;
    position: absolute;
    top: 50%;
    right: -0.5rem;
    transform: translateY(-50%);
  }
}
.f-brand-logo-static {
  margin-right: 0.5rem;
}
.f-brand-logo-static::after {
  content: "";
  width: 1px;
  height: 26px;
  background-color: #e6e6e6;
  position: absolute;
  top: 50%;
  right: -0.5rem;
  transform: translateY(-50%);
}

.form-check .form-check-input {
  box-shadow: none;
}
.form-check .form-check-input:focus-visible {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.form-check .form-check-input:disabled ~ .form-check-label {
  color: #333333;
}

.form-check-input[type=radio]:hover {
  border-color: #5c5c5c;
}
.form-check-input[type=radio]:active:focus {
  border-color: #494844;
}
.form-check-input[type=radio]:disabled {
  border-color: #e2e2e2;
}
.form-check-input[type=radio]:checked {
  border-color: #888888;
}
.form-check-input[type=radio]:checked::before {
  content: "";
  background: #006d89;
  border-radius: 50%;
}
.form-check-input[type=radio]:checked:hover::before {
  background: #005d75;
}
.form-check-input[type=radio]:checked:active:focus::before {
  background: #005063;
}
.form-check-input[type=radio]:disabled::before, .form-check-input[type=radio]:disabled:checked::before {
  background: #66a7b8;
}
.form-check-input[type=radio]:disabled ~ .form-check-label {
  color: #333333;
}
.form-check-input[type=checkbox]:hover {
  border-color: #5c5c5c;
}
.form-check-input[type=checkbox]:active:focus {
  border-color: #494844;
}
.form-check-input[type=checkbox]:disabled {
  border-color: #e2e2e2;
  opacity: 1;
}
.form-check-input[type=checkbox]:disabled::before {
  opacity: 0.4;
}
.form-check-input[type=checkbox]:indeterminate, .form-check-input[type=checkbox].indeterminate {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:nonzero;fill:white;fill-opacity:1;" d="M 5.332031 14.667969 L 26.667969 14.667969 L 26.667969 16.800781 L 5.332031 16.800781 Z M 5.332031 14.667969"/></svg>'), #006d89;
}
.form-check-input[type=checkbox]:indeterminate:hover, .form-check-input[type=checkbox].indeterminate:hover {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:nonzero;fill:white;fill-opacity:1;" d="M 5.332031 14.667969 L 26.667969 14.667969 L 26.667969 16.800781 L 5.332031 16.800781 Z M 5.332031 14.667969"/></svg>'), #005d75;
}
.form-check-input[type=checkbox]:indeterminate:active, .form-check-input[type=checkbox].indeterminate:active {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:nonzero;fill:white;fill-opacity:1;" d="M 5.332031 14.667969 L 26.667969 14.667969 L 26.667969 16.800781 L 5.332031 16.800781 Z M 5.332031 14.667969"/></svg>'), #006d89;
}
.form-check-input[type=checkbox]:indeterminate:focus-visible, .form-check-input[type=checkbox].indeterminate:focus-visible {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.form-check-input[type=checkbox]:indeterminate:disabled, .form-check-input[type=checkbox].indeterminate:disabled {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:nonzero;fill:white;fill-opacity:1;" d="M 5.332031 14.667969 L 26.667969 14.667969 L 26.667969 16.800781 L 5.332031 16.800781 Z M 5.332031 14.667969"/></svg>'), #66a7b8;
  opacity: 1;
}
.form-check-input[type=checkbox]:checked {
  background: #006d89;
}
.form-check-input[type=checkbox]:checked::before {
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:evenodd;fill:white;fill-opacity:1;" d="M26.244 9.548l-13.813 14.274-8.007-8.274 1.82-1.762 6.187 6.393 11.993-12.393 1.821 1.762z"/></svg>');
}
@media (hover: hover) {
  .form-check-input[type=checkbox]:checked:hover {
    background: #005d75;
  }
}
.form-check-input[type=checkbox]:checked:focus-visible {
  background-color: #006d89;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.form-check-input[type=checkbox]:checked:active:focus {
  background: #005063;
}
.form-check-input[type=checkbox]:checked:disabled, .form-check-input[type=checkbox]:checked:disabled:hover {
  background-color: #66a7b8;
  opacity: 1;
}
.form-check-input[type=checkbox]:checked:disabled::before, .form-check-input[type=checkbox]:checked:disabled:hover::before {
  opacity: 0.6;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" version="1.1"><path style="stroke:none;fill-rule:evenodd;fill:white;fill-opacity:1;" d="M26.244 9.548l-13.813 14.274-8.007-8.274 1.82-1.762 6.187 6.393 11.993-12.393 1.821 1.762z"/></svg>');
}

.form-switch .form-check-input {
  background-color: #e2e2e2;
}
.form-switch .form-check-input:hover {
  background-color: rgba(107, 107, 107, 0.5);
}
.form-switch .form-check-input:active:focus {
  background-color: rgba(107, 107, 107, 0.8);
}
.form-switch .form-check-input:disabled {
  background-color: rgba(238, 238, 238, 0.7);
}
.form-switch .form-check-input[type=checkbox]::before {
  background: #ffffff;
  border-radius: 50%;
}
.form-switch .form-check-input:checked {
  background: #006d89;
}
.form-switch .form-check-input:checked:hover {
  background: #005d75;
}
.form-switch .form-check-input:checked:disabled {
  background: #66a7b8;
}
.form-switch .form-check-input:checked:disabled::before {
  opacity: 0.6;
}
.form-switch .form-check-input:disabled {
  background-color: rgba(238, 238, 238, 0.7);
}
.form-switch .form-check-input:disabled::before {
  opacity: 0.8;
}

.form-check-label {
  color: #333333;
}

.notification-marker::after, .mobile-search-inactive:not(.search-animation-running) .search-notification::after,
.card-row-search-container .multiple-suggester-with-items::after, .increased-baseline-size .card-row-filters-container f-editable-chip-group.has-chips + .btn.btn-secondary.btn-icon::after {
  background-color: #005d75;
  border-radius: 50%;
}

.accordion-item {
  border-bottom-color: #e6e6e6;
}
.accordion-button {
  color: #6d6d6d;
  border-radius: 2px;
}
.accordion-button::before {
  color: #999999;
  font-family: common;
}
.accordion-button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.alert {
  color: #333333;
}
.alert-dismissible .btn-close {
  color: #999999;
}
.alert-dismissible .btn-close:hover {
  color: #333333;
}
.alert-dismissible .btn-close:focus {
  color: #333333;
}
.alert-info, .alert-caution {
  background-color: #ebf9fc;
}
.alert-warning {
  background-color: #fff7e3;
}
.alert-success {
  background-color: #eef5f0;
}
.alert-danger {
  background-color: #ffeff3;
}
.alert .f-i:not(.f-i-close).f-i-info, .alert .f-i:not(.f-i-close).f-i-caution {
  color: #338ba1;
}
.alert .f-i:not(.f-i-close).f-i-warning {
  color: #f2cb6f;
}
.alert .f-i:not(.f-i-close).f-i-success {
  color: #7dbe88;
}
.alert .f-i:not(.f-i-close).f-i-danger {
  color: #d10f49;
}

.card {
  box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12);
}
.card.f-sub-cards-container .f-sub-card {
  box-shadow: -1px 0 0 #e6e6e6;
  position: relative;
}
.card.f-sub-cards-container > .card-body > .card-text .card {
  box-shadow: none;
}
.card-large {
  border-top-left-radius: 2px;
}
.card-large .card-footer {
  box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14);
}

.card-link-show-more::before {
  background-image: linear-gradient(180deg, transparent 0%, #ffffff 60%);
}

.f-grid-line,
.f-axis-domain {
  stroke: #e6e6e6;
}

.f-tick,
.f-charts-axis-label {
  fill: #999999;
}

.f-tick-line {
  stroke: #e6e6e6;
}

.f-axis-zero-line {
  stroke: #999999;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.f-bg-bubble {
  fill: #b4d5e9;
  background-color: #b4d5e9;
}
@media screen and (max-width: 599px) {
  .f-bg-bubble {
    stroke: #ffffff;
  }
}

.f-text-bubble {
  fill: #345569;
  color: #345569;
}

.f-rect-bar {
  cursor: pointer;
  fill: #6598b5;
}
.f-rect-bar.f-rect-bar-empty {
  fill: transparent !important;
  stroke: transparent !important;
}

.f-bar-halo,
.f-bar-group-popover {
  cursor: pointer;
  fill: transparent;
}

[f-bar-series] .interactive .f-bar-halo,
[f-grouped-bar-series] .interactive .f-bar-halo,
[f-stacked-bar-series] .interactive .f-bar-halo,
[f-positive-negative-bar-series] .interactive .f-bar-halo,
[f-positive-negative-grouped-bar-series] .interactive .f-bar-halo {
  fill: rgba(233, 248, 253, 0.7);
}

[f-bar-series] .selected .f-rect-bar,
[f-grouped-bar-series] .selected .f-rect-bar {
  stroke: #333333;
}

.f-chart-rect-border {
  stroke: #e6e6e6;
}

[f-bar-series].has-selected-bar .interactive:not(.selected) .f-bubble-wrapper,
[f-bar-series].has-selected-bar .interactive:not(.selected) .f-rect-bar {
  filter: saturate(0.5) brightness(1.1) opacity(1);
}
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  [f-bar-series].has-selected-bar .interactive:not(.selected) .f-bubble-wrapper,
  [f-bar-series].has-selected-bar .interactive:not(.selected) .f-rect-bar {
    opacity: 0.75;
  }
}
@media (hover: hover) and (pointer: fine) and (min-width: 600px) {
  [f-bar-series]:hover .interactive:not(.selected) .f-bubble-wrapper,
  [f-bar-series]:hover .interactive:not(.selected) .f-rect-bar {
    filter: saturate(0.5) brightness(1.1) opacity(1);
  }
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    [f-bar-series]:hover .interactive:not(.selected) .f-bubble-wrapper,
    [f-bar-series]:hover .interactive:not(.selected) .f-rect-bar {
      opacity: 0.75;
    }
  }
}

[f-stacked-bar-series].has-selected-bar .interactive:not(.selected) .f-bubble-wrapper,
[f-stacked-bar-series].has-selected-bar .interactive:not(.selected) .f-rect-bar,
[f-positive-negative-bar-series].has-selected-bar .interactive:not(.selected) .f-bubble-wrapper,
[f-positive-negative-bar-series].has-selected-bar .interactive:not(.selected) .f-rect-bar {
  filter: saturate(0.5) brightness(1.1) opacity(1);
}
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  [f-stacked-bar-series].has-selected-bar .interactive:not(.selected) .f-bubble-wrapper,
  [f-stacked-bar-series].has-selected-bar .interactive:not(.selected) .f-rect-bar,
  [f-positive-negative-bar-series].has-selected-bar .interactive:not(.selected) .f-bubble-wrapper,
  [f-positive-negative-bar-series].has-selected-bar .interactive:not(.selected) .f-rect-bar {
    opacity: 0.75;
  }
}
@media (hover: hover) and (pointer: fine) and (min-width: 600px) {
  [f-stacked-bar-series]:hover .interactive:not(.selected) .f-bubble-wrapper,
  [f-stacked-bar-series]:hover .interactive:not(.selected) .f-rect-bar,
  [f-positive-negative-bar-series]:hover .interactive:not(.selected) .f-bubble-wrapper,
  [f-positive-negative-bar-series]:hover .interactive:not(.selected) .f-rect-bar {
    filter: saturate(0.5) brightness(1.1) opacity(1);
  }
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    [f-stacked-bar-series]:hover .interactive:not(.selected) .f-bubble-wrapper,
    [f-stacked-bar-series]:hover .interactive:not(.selected) .f-rect-bar,
    [f-positive-negative-bar-series]:hover .interactive:not(.selected) .f-bubble-wrapper,
    [f-positive-negative-bar-series]:hover .interactive:not(.selected) .f-rect-bar {
      opacity: 0.75;
    }
  }
}

[f-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-bar-series],
[f-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-positive-negative-bar-series],
[f-positive-negative-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-bar-series],
[f-positive-negative-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-positive-negative-bar-series] {
  filter: saturate(0.5) brightness(1.1) opacity(1);
}
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  [f-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-bar-series],
  [f-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-positive-negative-bar-series],
  [f-positive-negative-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-bar-series],
  [f-positive-negative-grouped-bar-series].has-selected-bar .interactive:not(.selected) [f-positive-negative-bar-series] {
    opacity: 0.75;
  }
}
@media (hover: hover) and (pointer: fine) and (min-width: 600px) {
  [f-grouped-bar-series]:hover .interactive:not(.selected) [f-bar-series],
  [f-grouped-bar-series]:hover .interactive:not(.selected) [f-positive-negative-bar-series],
  [f-positive-negative-grouped-bar-series]:hover .interactive:not(.selected) [f-bar-series],
  [f-positive-negative-grouped-bar-series]:hover .interactive:not(.selected) [f-positive-negative-bar-series] {
    filter: saturate(0.5) brightness(1.1) opacity(1);
  }
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    [f-grouped-bar-series]:hover .interactive:not(.selected) [f-bar-series],
    [f-grouped-bar-series]:hover .interactive:not(.selected) [f-positive-negative-bar-series],
    [f-positive-negative-grouped-bar-series]:hover .interactive:not(.selected) [f-bar-series],
    [f-positive-negative-grouped-bar-series]:hover .interactive:not(.selected) [f-positive-negative-bar-series] {
      opacity: 0.75;
    }
  }
}

.f-line,
.f-circle {
  stroke: #1a1a1a;
}

.f-area {
  fill: #e9f8fd;
}
.f-area ~ .f-line, .f-area ~ .f-circle {
  stroke: #416a82;
}

.f-area-pointer-line {
  stroke: #416a82;
}
.f-area-pointer-line.selected {
  stroke: #333333;
}

.f-grouped-bar-1 {
  background-color: #b4d5e9;
  fill: #b4d5e9;
}

.f-grouped-bar-2 {
  background-color: #85b7d4;
  fill: #85b7d4;
}

.f-grouped-bar-3 {
  background-color: #6598b5;
  fill: #6598b5;
}

.f-grouped-bar-4 {
  background-color: #507e99;
  fill: #507e99;
}

.f-grouped-bar-5 {
  background-color: #416a82;
  fill: #416a82;
}

.f-grouped-bar-6 {
  background-color: #345569;
  fill: #345569;
}

.f-stacked-bar-container .bar-segment {
  border-left-color: #ffffff;
}

[f-stacked-bar-series] .selected .f-stacked-bar-wrapper,
[f-positive-negative-bar-series] .selected .f-stacked-bar-wrapper,
[f-positive-negative-grouped-bar-series] .selected .f-stacked-bar-wrapper {
  stroke: #333333;
}

.f-stacked-bar-basis {
  fill: #ffffff;
}

.f-svg-donut-chart g.f-arc:nth-child(3n+1) {
  fill: #6598b5;
}
.f-svg-donut-chart g.f-arc:nth-child(3n+2) {
  fill: #01145f;
}
.f-svg-donut-chart g.f-arc:nth-child(3n+3) {
  fill: #f0c15d;
}

.f-vertical-stacked-bar-container .bar-segment {
  border-bottom-color: 1px solid #ffffff;
}

.stripes-pattern-bg-color-1 {
  fill: #b4d5e9;
}

.stripes-pattern-bg-color-2 {
  fill: #6598b5;
}

.bg-stripes-pattern {
  background: repeating-linear-gradient(-45deg, #b4d5e9, #b4d5e9 2px, #6598b5 2px, #6598b5 4px);
}

.bg-stripes-mask {
  background: repeating-linear-gradient(-45deg, #ffffff, #ffffff 2px, transparent 2px, transparent 4px);
}

.f-part-donut-empty-arc {
  fill: #e6e6e6;
}

.f-bar-arrow-pointer {
  color: #5c5c5c;
}

.scale-chart-bar:nth-child(1) {
  background-color: #5cae6a;
}
.scale-chart-bar:nth-child(2) {
  background-color: #9dcea6;
}
.scale-chart-bar:nth-child(3) {
  background-color: #fadf96;
}
.scale-chart-bar:nth-child(4) {
  background-color: #f4b86d;
}
.scale-chart-bar:nth-child(5) {
  background-color: #d10f49;
}

.f-positive-value-bar {
  fill: #3c9d4d !important;
  background-color: #3c9d4d !important;
}

.f-negative-value-bar {
  fill: #c3406a !important;
  background-color: #c3406a !important;
}

.ca-highlighted-decile {
  fill: #6d6d6d;
  background-color: #6d6d6d !important;
}

.ca-selected-decile-bubble {
  fill: #d83159;
  background-color: #d83159 !important;
}

.concentration-scheme-0-level-1 {
  fill: rgba(180, 213, 233, 0.6);
  background-color: rgba(180, 213, 233, 0.6);
}

.concentration-scheme-0-level-2 {
  fill: #b4d5e9;
  background-color: #b4d5e9;
}

.concentration-scheme-0-level-3 {
  fill: #85b7d4;
  background-color: #85b7d4;
}

.concentration-scheme-0-level-4 {
  fill: #507e99;
  background-color: #507e99;
}

.concentration-scheme-0-level-5 {
  fill: #284454;
  background-color: #284454;
}

.concentration-scheme-1-level-1 {
  fill: rgba(190, 222, 196, 0.6);
  background-color: rgba(190, 222, 196, 0.6);
}

.concentration-scheme-1-level-2 {
  fill: rgba(157, 206, 166, 0.8);
  background-color: rgba(157, 206, 166, 0.8);
}

.concentration-scheme-1-level-3 {
  fill: #7dbe88;
  background-color: #7dbe88;
}

.concentration-scheme-1-level-4 {
  fill: #3c9d4d;
  background-color: #3c9d4d;
}

.concentration-scheme-1-level-5 {
  fill: #125e1f;
  background-color: #125e1f;
}

.concentration-scheme-0-gradient {
  background: linear-gradient(to right, rgba(180, 213, 233, 0.6) 20%, #b4d5e9 20% 40%, #85b7d4 40% 60%, #507e99 60% 80%, #284454 80%);
}

.concentration-scheme-1-gradient {
  background: linear-gradient(to right, rgba(190, 222, 196, 0.6) 20%, rgba(157, 206, 166, 0.8) 20% 40%, #7dbe88 40% 60%, #3c9d4d 60% 80%, #125e1f 80%);
}

.chip, .nav-tabs .nav-item {
  border-color: transparent;
  border-radius: 2px;
  color: #999999;
  background-color: #f5f5f5;
}
.chip:hover, .nav-tabs .nav-item:hover {
  color: #333333;
  background-color: #eeeeee;
  border-color: transparent;
}
.chip:focus, .nav-tabs .nav-item:focus {
  border-color: transparent;
}
.chip:focus-visible, .nav-tabs .nav-item:focus-visible, .chip.focus, .nav-tabs .focus.nav-item {
  border-color: transparent;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.chip:active, .nav-tabs .nav-item:active, .chip:hover:active {
  color: #333333;
  background-color: #c2c2c2;
  box-shadow: none;
}
.chip[disabled], .nav-tabs [disabled].nav-item, .chip.disabled, .nav-tabs .disabled.nav-item {
  color: rgba(51, 51, 51, 0.3);
  background-color: rgba(245, 245, 245, 0.3);
}
.chip[disabled]:hover, .nav-tabs [disabled].nav-item:hover, .chip[disabled]:active, .nav-tabs [disabled].nav-item:active, .chip[disabled]:hover:active, .chip.disabled:hover, .nav-tabs .disabled.nav-item:hover, .chip.disabled:active, .nav-tabs .disabled.nav-item:active, .chip.disabled:hover:active {
  color: rgba(51, 51, 51, 0.3);
  background-color: rgba(245, 245, 245, 0.3);
  box-shadow: none;
}
.chip[disabled] .f-i, .nav-tabs [disabled].nav-item .f-i, .chip.disabled .f-i, .nav-tabs .disabled.nav-item .f-i {
  opacity: 0.3;
}
.chip-choice, .nav-tabs.nav-tabs-outline .nav-item.active, .chip-choice:hover, .chip-choice:active, .chip-choice:hover:active, .chip-filter, .chip-input {
  color: #333333;
}
.chip-choice, .nav-tabs.nav-tabs-outline .nav-item.active, .chip-choice:hover, .chip-choice:active, .chip-choice:hover:active, .chip-filter {
  background-color: #e2e2e2;
}
.chip-choice[disabled], .nav-tabs.nav-tabs-outline [disabled].nav-item.active, .chip-choice.disabled, .nav-tabs.nav-tabs-outline .disabled.nav-item.active {
  color: rgba(51, 51, 51, 0.3);
  background-color: #f5f5f5;
}
.chip-choice[disabled]:hover, .nav-tabs.nav-tabs-outline [disabled].nav-item.active:hover, .chip-choice[disabled]:active, .nav-tabs.nav-tabs-outline [disabled].nav-item.active:active, .chip-choice[disabled]:hover:active, .chip-choice.disabled:hover, .nav-tabs.nav-tabs-outline .disabled.nav-item.active:hover, .chip-choice.disabled:active, .nav-tabs.nav-tabs-outline .disabled.nav-item.active:active, .chip-choice.disabled:hover:active {
  background-color: #f5f5f5;
}
.chip .f-i-info, .nav-tabs .nav-item .f-i-info {
  color: #999999;
}
.chip.chip-outline, .nav-tabs .chip-outline.nav-item {
  background-color: transparent;
  border-color: #cececf;
  color: #999999;
}
.chip.chip-outline:hover, .nav-tabs .chip-outline.nav-item:hover {
  background-color: transparent;
  color: #999999;
  border-color: #adadad;
}
.chip.chip-outline:active, .nav-tabs .chip-outline.nav-item:active, .chip.chip-outline:hover:active {
  color: #333333;
  background-color: transparent;
  border-color: #999999;
}
.chip.chip-outline[disabled], .nav-tabs .chip-outline[disabled].nav-item, .chip.chip-outline.disabled, .nav-tabs .chip-outline.disabled.nav-item {
  background-color: transparent;
  color: rgba(153, 153, 153, 0.3);
  border-color: #e6e6e6;
}
.chip.chip-outline[disabled] .f-i, .nav-tabs .chip-outline[disabled].nav-item .f-i, .chip.chip-outline.disabled .f-i, .nav-tabs .chip-outline.disabled.nav-item .f-i {
  color: #999999;
}
.chip.chip-outline[disabled]:hover, .nav-tabs .chip-outline[disabled].nav-item:hover, .chip.chip-outline[disabled]:active, .nav-tabs .chip-outline[disabled].nav-item:active, .chip.chip-outline[disabled]:hover:active, .chip.chip-outline.disabled:hover, .nav-tabs .chip-outline.disabled.nav-item:hover, .chip.chip-outline.disabled:active, .nav-tabs .chip-outline.disabled.nav-item:active, .chip.chip-outline.disabled:hover:active {
  background-color: transparent;
  color: rgba(153, 153, 153, 0.3);
  border-color: #e6e6e6;
}
.chip.chip-outline.chip-choice, .nav-tabs .chip-outline.chip-choice.nav-item, .nav-tabs.nav-tabs-outline .chip-outline.nav-item.active {
  border-color: #6d6d6d;
  color: #333333;
}
.chip.chip-outline.chip-choice:active, .nav-tabs .chip-outline.chip-choice.nav-item:active, .nav-tabs.nav-tabs-outline .chip-outline.nav-item.active:active, .chip.chip-outline.chip-choice:hover:active {
  color: #333333;
  background-color: transparent;
  border-color: #999999;
}
.chip.chip-outline.chip-choice[disabled], .nav-tabs .chip-outline.chip-choice[disabled].nav-item, .nav-tabs.nav-tabs-outline .chip-outline[disabled].nav-item.active, .chip.chip-outline.chip-choice.disabled, .nav-tabs .chip-outline.chip-choice.disabled.nav-item, .nav-tabs.nav-tabs-outline .chip-outline.disabled.nav-item.active {
  color: rgba(51, 51, 51, 0.3);
  border-color: rgba(153, 153, 153, 0.3);
}
.chip.chip-outline.chip-choice[disabled]:hover, .nav-tabs .chip-outline.chip-choice[disabled].nav-item:hover, .nav-tabs.nav-tabs-outline .chip-outline[disabled].nav-item.active:hover, .chip.chip-outline.chip-choice.disabled:hover, .nav-tabs .chip-outline.chip-choice.disabled.nav-item:hover, .nav-tabs.nav-tabs-outline .chip-outline.disabled.nav-item.active:hover {
  color: rgba(51, 51, 51, 0.3);
  border-color: rgba(153, 153, 153, 0.3);
}
.chip-input {
  background-color: #f5f5f5;
  border-color: #6b6b6b;
}
.chip-input:hover, .chip-input:hover:focus-visible {
  background-color: #f8f8f8;
  border-color: #888888;
}
.chip-input:active, .chip-input:hover:active {
  background-color: #e2e2e2;
  border-color: #6b6b6b;
}
.chip-input:focus {
  background-color: #eeeeee;
  border-color: #6b6b6b;
}
.chip-input:active:focus {
  background-color: #e2e2e2;
  border-color: #6b6b6b;
}
.chip-input:focus-visible {
  background-color: #f5f5f5;
  border-color: #6b6b6b;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.chip-input.disabled, .chip-input.disabled:hover, .chip-input.disabled:active:focus {
  color: rgba(51, 51, 51, 0.3);
  background-color: #f8f8f8;
  border-color: #e2e2e2;
}
.chip-input.disabled .f-i, .chip-input.disabled:hover .f-i, .chip-input.disabled:active:focus .f-i {
  opacity: 0.3;
}
.chip-input .f-i {
  color: #6d6d6d;
}
.chip-input .f-i:hover {
  color: #333333;
}
.chip-input-secondary:focus-visible {
  background-color: #1f1f1f;
}
.chip-input-secondary[disabled], .chip-input-secondary.disabled, .chip-input-secondary.disabled:hover, .chip-input-secondary.disabled:active:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: #6b6b6b;
}
.chip-input-secondary[disabled] .f-i, .chip-input-secondary.disabled .f-i, .chip-input-secondary.disabled:hover .f-i, .chip-input-secondary.disabled:active:focus .f-i {
  color: #f8f8f8;
  opacity: 0.7;
}
.chip-input-secondary .f-i {
  color: #f5f5f5;
}
.chip-input-secondary .f-i:hover {
  color: #ffffff;
}
.chip-input-danger {
  color: #ffffff;
  background-color: #df6b95;
}
.chip-input-danger:hover, .chip-input-danger:hover:focus-visible {
  color: #ffffff;
  background-color: #f3a7c3;
}
.chip-input-danger:active:focus, .chip-input-danger:hover:active {
  color: #ffffff;
  background-color: #d10f49;
  box-shadow: none;
}
.chip-input-danger:focus-visible, .chip-input-danger.focus {
  background-color: #df6b95;
  box-shadow: 0 0 0 2px rgba(223, 107, 149, 0.75);
}
.chip-input-danger .f-i {
  color: #ffffff;
}
.chip-input-danger .f-i:hover {
  color: #ffffff;
}
.chip-input-danger[disabled], .chip-input-danger.disabled {
  color: rgba(51, 51, 51, 0.3);
  background-color: #fadde6;
}
.chip-input-danger[disabled]:hover, .chip-input-danger[disabled]:active, .chip-input-danger[disabled]:hover:active, .chip-input-danger.disabled:hover, .chip-input-danger.disabled:active, .chip-input-danger.disabled:hover:active {
  color: rgba(51, 51, 51, 0.3);
  background-color: #fadde6;
}
.chip-input-danger[disabled] .f-i, .chip-input-danger.disabled .f-i {
  opacity: 0.3;
}

.active .chip-input {
  background-color: #eeeeee;
  border-color: #6b6b6b;
}
.active .chip-input-secondary {
  background-color: #494844;
}

.multiple-suggester .chip-input-secondary.disabled:focus-visible {
  background-color: #1f1f1f;
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.chip-input-secondary, .multiple-suggester .chip-input-secondary.disabled {
  color: #ffffff;
  background-color: #1f1f1f;
}
.chip-input-secondary:hover, .multiple-suggester .chip-input-secondary.disabled:hover {
  color: #ffffff;
  background-color: #5c5c5c;
}
.chip-input-secondary:hover:focus-visible {
  color: #ffffff;
  background-color: #5c5c5c;
}
.chip-input-secondary:active, .multiple-suggester .chip-input-secondary.disabled:active {
  color: #ffffff;
  background-color: #1a1a1a;
}
.chip-input-secondary:hover:active {
  color: #ffffff;
  background-color: #1a1a1a;
}
.chip-input-secondary:focus, .multiple-suggester .chip-input-secondary.disabled:focus {
  background-color: #494844;
}
.chip-input-secondary:active:focus {
  color: #ffffff;
  background-color: #1a1a1a;
}

.drawer {
  background-color: #ffffff;
}
.drawer-title .f-i {
  color: #005063;
}
.drawer-footer {
  box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14);
}

.dropdown-menu .nested-dropdown.dropdown-item-checkbox.show {
  background-color: #eeeeee;
}
.dropdown-menu .nested-dropdown.dropdown-item-checkbox.show:hover {
  background-color: #eeeeee;
}
.dropdown-item:focus-visible, .dropdown-item.list-group-item:focus-visible {
  border-color: rgba(102, 167, 184, 0.75);
  border-radius: 2px;
}
.list-group:not(.list-group-borderless) .dropdown-item:focus-visible {
  border-color: rgba(102, 167, 184, 0.75);
}

.dropdown .sub-menu-open {
  color: #333333;
}
.dropdown .cascade-menu-close-icon:hover {
  color: #333333;
}
.dropdown .btn-with-initials {
  color: #6d6d6d;
}

@media (hover: hover) {
  .dropdown-item:not(.disabled):hover {
    background-color: #f5f5f5;
  }
}
.empty-state-note {
  color: #999999;
}

.onboarding-empty-state-caption {
  color: #333333;
}
.onboarding-empty-state-img.meet {
  background-image: url("./../../../../../../assets/brandings/common/light/meet.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.onboarding-empty-state-img.measure {
  background-image: url("./../../../../../../assets/brandings/common/light/measure.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.onboarding-empty-state-img.merge {
  background-image: url("./../../../../../../assets/brandings/common/light/merge.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.onboarding-empty-state-img.onboarding-mail {
  background-image: url("./../../../../../../assets/brandings/common/light/onboarding-mail.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.onboarding-empty-state-img.reporting {
  background-image: url("./../../../../../../assets/brandings/common/light/reporting.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}

.simple-actionable-empty-state-caption {
  color: #333333;
}
.simple-actionable-empty-state-info {
  color: #999999;
}
.simple-actionable-empty-state-img.calendar {
  background-image: url("./../../../../../../assets/brandings/common/light/calendar.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.crm {
  background-image: url("./../../../../../../assets/brandings/common/light/crm.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.edit {
  background-image: url("./../../../../../../assets/brandings/common/light/edit.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.contact {
  background-image: url("./../../../../../../assets/brandings/common/light/contact.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.toggles {
  background-image: url("./../../../../../../assets/brandings/common/light/toggles.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.teams {
  background-image: url("./../../../../../../assets/brandings/common/light/teams.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.institution {
  background-image: url("./../../../../../../assets/brandings/common/light/institution.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.esg {
  background-image: url("./../../../../../../assets/brandings/common/light/esg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.custom-fields {
  background-image: url("./../../../../../../assets/brandings/common/light/custom-fields.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.primary-contact {
  background-image: url("./../../../../../../assets/brandings/common/light/primary-contact.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-actionable-empty-state-img.import {
  background-image: url("./../../../../../../assets/brandings/common/light/import.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}

.simple-empty-state-caption {
  color: #999999;
}
.simple-empty-state-img.contact {
  background-image: url("./../../../../../../assets/brandings/common/light/contact.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.crm {
  background-image: url("./../../../../../../assets/brandings/common/light/crm.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.fund {
  background-image: url("./../../../../../../assets/brandings/common/light/fund.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.institution {
  background-image: url("./../../../../../../assets/brandings/common/light/institution.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.securities {
  background-image: url("./../../../../../../assets/brandings/common/light/securities.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.targeting {
  background-image: url("./../../../../../../assets/brandings/common/light/targeting.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.calendar {
  background-image: url("./../../../../../../assets/brandings/common/light/calendar.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.merge-simple {
  background-image: url("./../../../../../../assets/brandings/common/light/merge-simple.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.merge {
  background-image: url("./../../../../../../assets/brandings/common/light/merge-simple.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.edit-history {
  background-image: url("./../../../../../../assets/brandings/common/light/edit-history.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.esg {
  background-image: url("./../../../../../../assets/brandings/common/light/esg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.custom-fields {
  background-image: url("./../../../../../../assets/brandings/common/light/custom-fields.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.simple-empty-state-img.mail {
  background-image: url("./../../../../../../assets/brandings/common/light/mail.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}

.no-results-empty-state-img {
  background-image: url("./../../../../../../assets/brandings/common/light/no-results-match-criteria.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.no-results-empty-state-info {
  color: #333333;
}

.inner-error-img {
  background-image: url("./../../../../../../assets/brandings/common/light/data-unavailable.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.legend-price {
  background-color: #1a1a1a;
}
.legend-shares {
  background-color: #6598b5;
}
.legend-info {
  color: #999999;
}

.f-grouped-bar-chart-legend .legend-item:nth-child(1) .legend-marker {
  background-color: #b4d5e9;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(2) .legend-marker {
  background-color: #85b7d4;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(3) .legend-marker {
  background-color: #6598b5;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(4) .legend-marker {
  background-color: #507e99;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(5) .legend-marker {
  background-color: #416a82;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(6) .legend-marker {
  background-color: #345569;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(7) .legend-marker {
  background-color: #005063;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(8) .legend-marker {
  background-color: #005d75;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(9) .legend-marker {
  background-color: #006d89;
}
.f-grouped-bar-chart-legend .legend-item:nth-child(10) .legend-marker {
  background-color: #338ba1;
}

.f-grouped-bar-line-chart-legend .legend-item:nth-child(1) .legend-marker {
  background-color: #1a1a1a;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(2) .legend-marker {
  background-color: #b4d5e9;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(3) .legend-marker {
  background-color: #85b7d4;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(4) .legend-marker {
  background-color: #6598b5;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(5) .legend-marker {
  background-color: #507e99;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(6) .legend-marker {
  background-color: #416a82;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(7) .legend-marker {
  background-color: #345569;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(8) .legend-marker {
  background-color: #005063;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(9) .legend-marker {
  background-color: #005d75;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(10) .legend-marker {
  background-color: #006d89;
}
.f-grouped-bar-line-chart-legend .legend-item:nth-child(11) .legend-marker {
  background-color: #338ba1;
}

.f-stacked-bar-chart-legend .legend-item:nth-child(1) .legend-marker {
  background-color: #ebbfcd;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(2) .legend-marker {
  background-color: #d7b8da;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(3) .legend-marker {
  background-color: #7c8fce;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(4) .legend-marker {
  background-color: #495cb2;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(5) .legend-marker {
  background-color: #b4d5e9;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(6) .legend-marker {
  background-color: #aab5df;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(7) .legend-marker {
  background-color: #006d89;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(8) .legend-marker {
  background-color: #c6eae1;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(9) .legend-marker {
  background-color: #6598b5;
}
.f-stacked-bar-chart-legend .legend-item:nth-child(10) .legend-marker {
  background-color: #416a82;
}

.f-stacked-bar-line-chart-legend .legend-item:nth-child(1) .legend-marker {
  background-color: #1a1a1a;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(2) .legend-marker {
  background-color: #ebbfcd;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(3) .legend-marker {
  background-color: #d7b8da;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(4) .legend-marker {
  background-color: #7c8fce;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(5) .legend-marker {
  background-color: #495cb2;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(6) .legend-marker {
  background-color: #b4d5e9;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(7) .legend-marker {
  background-color: #aab5df;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(8) .legend-marker {
  background-color: #006d89;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(9) .legend-marker {
  background-color: #c6eae1;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(10) .legend-marker {
  background-color: #6598b5;
}
.f-stacked-bar-line-chart-legend .legend-item:nth-child(11) .legend-marker {
  background-color: #416a82;
}

.list-group .drag-target.dragging {
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(26, 26, 26, 0.07);
}
.list-group .drag-target.dragging .f-i.f-i-drag-drop {
  color: #333333;
}
.list-group .drag-target .f-i.f-i-drag-drop.f-draggable-list-item-drag-icon-disabled {
  color: rgba(153, 153, 153, 0.3);
}
.list-group.draggable.active .drop-target.dragging-start-place,
.list-group .active .drop-target.dragging-start-place {
  border-color: #cececf;
}
.list-group.draggable.active .drop-target.new-drop-place,
.list-group .active .drop-target.new-drop-place {
  border-color: #005d75;
  background: #ebf9fc;
}
.list-group.draggable .list-group-item.active.disabled {
  color: inherit;
}
.list-group .form-check .form-check-input.active.disabled {
  color: #adadad;
}
@media (hover: hover) {
  .list-group-item-hover:hover:not(.disabled) {
    background-color: #f5f5f5;
  }
}
.list-group-item.active.disabled {
  background-color: #eeeeee;
  color: #adadad;
}
.list-group-item:focus-visible {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.list-group-item.active {
  background-color: #eeeeee;
}
.list-group-item-concised:focus-visible {
  border-radius: 2px;
}
.list-group-item-concised.disabled .f-i {
  opacity: 0.3;
}
.list-group-item-concised .f-i:focus-visible {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
  border-radius: 2px;
}
.list-group-item-danger, .list-group-item-danger:hover {
  color: #b20040;
  background-color: inherit;
}
.list-group-item-danger.disabled, .list-group-item-danger:hover.disabled {
  color: #f3a7c3;
}
.list-group-secondary-text {
  color: #999999;
}

.list-item-removable-on-hover:hover:not(.disabled) {
  background-color: #f5f5f5;
}

.list-group-with-background .list-group-item {
  background: #f5f5f5;
  border-radius: 3px;
}
.list-group-with-background .list-group-item:focus-visible {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.list-group-item > .f-i {
  color: #999999;
}
.list-group-item .remove:hover {
  color: #333333;
}

.f-list-item-checkbox .list-group-item.active:not(.list-group-item-hover) {
  background-color: transparent;
}
.f-list-item-checkbox .list-group-item-hover:has(.form-check-input:focus-visible) {
  background-color: #f5f5f5;
  border-color: rgba(102, 167, 184, 0.75);
  border-radius: 2px;
}

.list-group-selectable > .list-group-item {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5;
  border-radius: 3px;
}
@media (hover: hover) {
  .list-group-selectable > .list-group-item:hover {
    background-color: #f8f8f8 !important;
    border-color: #c2c2c2;
  }
}
.list-group-selectable > .list-group-item.selected {
  background-color: #e2e2e2 !important;
  border-color: #e2e2e2;
}
.list-group-selectable > .list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.list-group-selectable > .list-group-item:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.toggle-list-item:hover {
  background-color: #f5f5f5;
}

.toggle-list-item-checked {
  background-color: #eef5f0;
}

.toggle-list-item-icon-check {
  color: #5cae6a;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.modal.confirmation .modal-header::before {
  background: #ebf9fc;
}
.modal.confirmation .modal-header::after {
  background: url("./../../../../../../assets/brandings/common/light/confirmation-modal-icon.svg") no-repeat;
}
.modal.warning .modal-header::before {
  background: #fff7e3;
}
.modal.warning .modal-header::after {
  background: url("./../../../../../../assets/brandings/common/light/warning-modal-icon.svg") no-repeat;
}
.modal.warning-merge .modal-header::before {
  background: #fff7e3;
}
.modal.warning-merge .modal-header::after {
  background: url("./../../../../../../assets/brandings/common/light/warning-merge-modal-icon.svg") no-repeat;
}
.modal.error .modal-header::before {
  background: #ffeff3;
}
.modal.error .modal-header::after {
  background: url("./../../../../../../assets/brandings/common/light/error-modal-icon.svg") no-repeat;
}

@media screen and (max-width: 599px) {
  .modal.confirmation .modal-header::after {
    background: url("./../../../../../../assets/brandings/common/light/confirmation-modal-icon-mobile.svg") no-repeat;
  }
  .modal.warning .modal-header::after {
    background: url("./../../../../../../assets/brandings/common/light/warning-modal-icon-mobile.svg") no-repeat;
  }
  .modal.warning-merge .modal-header::after {
    background: url("./../../../../../../assets/brandings/common/light/warning-merge-modal-icon-mobile.svg") no-repeat;
  }
  .modal.error .modal-header::after {
    background: url("./../../../../../../assets/brandings/common/light/error-modal-icon-mobile.svg") no-repeat;
  }
  .modal .modal-content {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}
.nav-tabs .nav-item .f-i {
  color: #999999;
}
.nav-tabs .nav-item[disabled] .f-i, .nav-tabs .nav-item.disabled .f-i {
  color: #999999;
}
.nav-tabs .nav-item.active:hover {
  color: #333333;
  background-color: #e2e2e2;
  border-color: transparent;
}
.nav-tabs .nav-item.active[disabled]:hover, .nav-tabs .nav-item.active.disabled:hover {
  color: rgba(51, 51, 51, 0.3);
  background-color: #f5f5f5;
}
.nav-tabs .nav-link:not(.active)[disabled], .nav-tabs .nav-link:not(.active).disabled {
  pointer-events: all;
}
.nav-tabs .nav-link:not(.active)[disabled] .f-i, .nav-tabs .nav-link:not(.active).disabled .f-i {
  color: #999999;
}

.nav-tabs.nav-tabs-outline .nav-item {
  background-color: transparent;
  border-color: #cececf;
  color: #999999;
}
.nav-tabs.nav-tabs-outline .nav-item:hover {
  background-color: transparent;
  color: #999999;
  border-color: #adadad;
}
.nav-tabs.nav-tabs-outline .nav-item:active, .nav-tabs.nav-tabs-outline .nav-item:hover:active {
  background-color: transparent;
  color: #333333;
  border-color: #999999;
}
.nav-tabs.nav-tabs-outline .nav-item[disabled], .nav-tabs.nav-tabs-outline .nav-item.disabled {
  background-color: transparent;
  color: rgba(153, 153, 153, 0.3);
  border-color: #e6e6e6;
}
.nav-tabs.nav-tabs-outline .nav-item[disabled]:hover, .nav-tabs.nav-tabs-outline .nav-item[disabled]:active, .nav-tabs.nav-tabs-outline .nav-item[disabled]:hover:active, .nav-tabs.nav-tabs-outline .nav-item.disabled:hover, .nav-tabs.nav-tabs-outline .nav-item.disabled:active, .nav-tabs.nav-tabs-outline .nav-item.disabled:hover:active {
  background-color: transparent;
  color: rgba(153, 153, 153, 0.3);
  border-color: #e6e6e6;
}
.nav-tabs.nav-tabs-outline .nav-item[disabled].active, .nav-tabs.nav-tabs-outline .nav-item.disabled.active {
  background-color: transparent;
  color: rgba(51, 51, 51, 0.3);
  border-color: rgba(153, 153, 153, 0.3);
}
.nav-tabs.nav-tabs-outline .nav-item.chip-choice, .nav-tabs.nav-tabs-outline .nav-item.active {
  background-color: transparent;
  border-color: #6d6d6d;
}
.nav-tabs.nav-tabs-outline .nav-item.active:hover {
  color: #333333;
}
.nav-tabs.nav-tabs-outline .nav-item.active[disabled]:hover, .nav-tabs.nav-tabs-outline .nav-item.active.disabled:hover {
  color: rgba(51, 51, 51, 0.3);
  border-color: rgba(153, 153, 153, 0.3);
}

.navbar {
  background-color: #ffffff;
}
.navbar::after {
  background-color: #e6e6e6;
}

.popover > .popover-arrow {
  position: absolute;
}
.popover > .popover-arrow::before, .popover > .popover-arrow::after {
  background-color: #ffffff;
}
.popover > .popover-arrow::before {
  box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12);
  border-color: #e6e6e6;
}
.popover-success {
  background-color: #deefe1;
  border-color: #3c9d4d;
  box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12);
}
.popover-success > .popover-arrow::before {
  background-color: #deefe1;
  border-color: #3c9d4d;
}
.popover-success > .popover-arrow::after {
  background-color: #deefe1;
}
.popover-warning {
  background-color: #fff3d4;
  border-color: #e9b350;
  box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12);
}
.popover-warning > .popover-arrow::before {
  background-color: #fff3d4;
  border-color: #e9b350;
}
.popover-warning > .popover-arrow::after {
  background-color: #fff3d4;
}
.popover-danger {
  background-color: #ffeff3;
  border-color: #b20040;
  box-shadow: 0 0 2px 0 rgba(26, 26, 26, 0.12);
}
.popover-danger > .popover-arrow::before {
  background-color: #ffeff3;
  border-color: #b20040;
}
.popover-danger > .popover-arrow::after {
  background-color: #ffeff3;
}
.progress-error {
  background-color: #b20040;
}
.progress-error-info {
  color: #b20040;
}
.progress-overlay::before {
  background-color: rgba(255, 255, 255, 0.85);
}

.quarter-range {
  background-color: rgba(51, 139, 161, 0.25);
}

.quarter-range-start .quarter-cell,
.quarter-range-end .quarter-cell {
  background-color: #338ba1;
  color: #ffffff;
}
.quarter-range-start .quarter-cell:hover,
.quarter-range-end .quarter-cell:hover {
  background-color: #005d75;
}

.quarter-cell:hover {
  background-color: #eeeeee;
}

.quarter-cell-year {
  color: #005063;
}

.carousel-control-prev-icon {
  background-image: url("./../../../../../../assets/brandings/common/light/icons/chevron-left.svg");
}

.carousel-control-next-icon {
  background-image: url("./../../../../../../assets/brandings/common/light/icons/chevron-right.svg");
}

.results-total-count {
  background-color: #f8f8f8;
  border-radius: 3px;
}

.spinner-border {
  color: #494844;
}
.spinner-overlay::before {
  background-color: rgba(255, 255, 255, 0.85);
}

.single-suggester .input-group-right-icon.is-valid .f-i-close, .single-suggester .input-group-right-icon.is-warning .f-i-close, .single-suggester .k-daterangepicker.f-is-collapsed.is-warning .input-group-right-icon.k-dateinput .f-i-close, .k-daterangepicker.f-is-collapsed.is-warning .single-suggester .input-group-right-icon.k-dateinput .f-i-close, .single-suggester .input-group-right-icon.is-invalid .f-i-close, .single-suggester .k-daterangepicker.f-is-collapsed.is-invalid .input-group-right-icon.k-dateinput .f-i-close, .k-daterangepicker.f-is-collapsed.is-invalid .single-suggester .input-group-right-icon.k-dateinput .f-i-close {
  color: #999999;
}
.single-suggester .input-group-right-icon.is-valid .f-i-close:hover, .single-suggester .input-group-right-icon.is-warning .f-i-close:hover, .single-suggester .k-daterangepicker.f-is-collapsed.is-warning .input-group-right-icon.k-dateinput .f-i-close:hover, .k-daterangepicker.f-is-collapsed.is-warning .single-suggester .input-group-right-icon.k-dateinput .f-i-close:hover, .single-suggester .input-group-right-icon.is-invalid .f-i-close:hover, .single-suggester .k-daterangepicker.f-is-collapsed.is-invalid .input-group-right-icon.k-dateinput .f-i-close:hover, .k-daterangepicker.f-is-collapsed.is-invalid .single-suggester .input-group-right-icon.k-dateinput .f-i-close:hover {
  color: #333333;
}

.multiple-suggester.action-wrapper.list-group-item {
  border-bottom-color: #e6e6e6;
}
.multiple-suggester .form-control input {
  color: #333333;
}
.multiple-suggester .form-control input::placeholder {
  color: #999999;
}
.multiple-suggester .form-control input[disabled], .multiple-suggester .form-control input.disabled {
  color: rgba(51, 51, 51, 0.5);
}
.multiple-suggester .form-control input[disabled]::placeholder, .multiple-suggester .form-control input.disabled::placeholder {
  color: rgba(51, 51, 51, 0.3);
}
.multiple-suggester .form-control-outline input.disabled {
  color: rgba(51, 51, 51, 0.5);
}
.multiple-suggester .form-control-outline input.disabled::placeholder {
  color: rgba(51, 51, 51, 0.3);
}
.multiple-suggester .form-control-outline.input-focus {
  background-color: transparent;
  border-color: #338ba1;
}
.multiple-suggester .form-control-outline.input-focus.is-valid, .multiple-suggester .form-control-outline.input-focus.is-warning, .multiple-suggester .k-daterangepicker.f-is-collapsed.is-warning .form-control-outline.input-focus.k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .multiple-suggester .form-control-outline.input-focus.k-dateinput, .multiple-suggester .form-control-outline.input-focus.is-invalid, .multiple-suggester .k-daterangepicker.f-is-collapsed.is-invalid .form-control-outline.input-focus.k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .multiple-suggester .form-control-outline.input-focus.k-dateinput {
  background-color: #f7faf7;
}
.multiple-suggester .dropdown-item:focus-visible {
  background-color: #f5f5f5;
}

.single-suggester .f-i-close:focus-visible,
.multiple-suggester .f-i-close:focus-visible {
  border-radius: 2px;
  outline: none;
}

.powered-by-google-logo {
  background-image: url("./../../../../../../assets/brandings/common/light/powered-by-google-logo.png");
}

@keyframes f-new-suggester-item-animation {
  10% {
    background-color: #eef5f0;
  }
  80% {
    background-color: #eef5f0;
  }
}
.f-new-suggester-item.animated {
  animation-name: f-new-suggester-item-animation;
  animation-timing-function: ease-out;
  animation-duration: 1500ms;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.toast {
  box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14);
}
.toast-danger {
  background-color: #ffeff3;
  border-color: #d10f49;
}
.toast-danger .toast-state-icon {
  color: #d10f49;
}
.toast-success {
  background-color: #deefe1;
  border-color: #5cae6a;
}
.toast-success .toast-state-icon {
  color: #5cae6a;
}
.toast-warning {
  background-color: #fff3d4;
  border-color: #e9b350;
}
.toast-warning .toast-state-icon {
  color: #e9b350;
}
.toast-info {
  background-color: #ebf9fc;
  border-color: #338ba1;
}
.toast-info .toast-state-icon {
  color: #338ba1;
}
.toast .close {
  color: #999999;
}
.toast .close:hover {
  color: #333333;
}

.mobile-inner-error-img.mobile-view {
  background: url("./../../../../../../assets/brandings/common/light/mobile-view.svg") no-repeat;
}
.mobile-inner-error-img.landscape-mode {
  background: url("./../../../../../../assets/brandings/common/light/landscape-mode.svg") no-repeat;
}

.f-map .leaflet-control-zoom .leaflet-control-zoom-in,
.f-map .leaflet-control-zoom .leaflet-control-zoom-out {
  -webkit-tap-highlight-color: #c2c2c2;
}
.f-map .leaflet-control-zoom .leaflet-control-zoom-in:hover,
.f-map .leaflet-control-zoom .leaflet-control-zoom-out:hover {
  background-color: #e2e2e2;
}
.f-map .leaflet-control-zoom .leaflet-control-zoom-in.leaflet-disabled,
.f-map .leaflet-control-zoom .leaflet-control-zoom-out.leaflet-disabled {
  color: rgba(109, 109, 109, 0.3);
  background-color: #f5f5f5;
}
.f-map .leaflet-control-attribution {
  background: #eeeeee;
}
.f-map .leaflet-control-attribution,
.f-map .leaflet-control-attribution a {
  color: #999999;
}
.f-map .leaflet-tooltip,
.f-map .leaflet-popup-tip,
.f-map .leaflet-popup-content-wrapper {
  color: #333333;
  background: #ffffff;
}
.f-map .leaflet-tooltip-left:before {
  border-left-color: #ffffff;
}
.f-map .leaflet-tooltip-right:before {
  border-right-color: #ffffff;
}
.f-map .leaflet-tooltip-top:before {
  border-top-color: #ffffff;
}
.f-map .leaflet-tooltip-bottom:before {
  border-bottom-color: #ffffff;
}
.f-map.dark {
  background-color: #333333;
}
.f-map.dark .leaflet-tile-pane .leaflet-layer {
  filter: brightness(1.4) contrast(1.005);
}
.f-map .f-marker-cluster-area {
  stroke: #66a7b8;
  fill: #66a7b8;
}
.f-map .leaflet-marker-icon .cluster-marker {
  background: #338ba1;
}
.f-map .leaflet-marker-icon .cluster-marker-center {
  background: #338ba1;
}
.f-map .f-location-bubble,
.f-map .f-bubble-center {
  fill: #338ba1;
}
.f-map .f-bubble-tooltip {
  background: #338ba1;
  color: #ffffff;
}

.loading-message-first-circle {
  fill: #6d6d6d;
}

.loading-message-second-circle {
  fill: #999999;
}

.loading-message-third-circle {
  fill: #cececf;
}

.chat-assistant-message {
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f8f8f8;
}

.chat-user-message {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #292929;
  color: #f8f8f8;
}

.chat-error-message {
  background-color: #ffeff3;
}

.chat-error-icon {
  color: #d10f49;
}

.chat-message-label {
  color: #999999;
}

.step-link-active:hover, .step-link-active:active:focus {
  color: #333333;
}

.step-text-disabled {
  color: #adadad;
}

f-layout-width .f-layout-width {
  border-color: #999999 !important;
}
f-layout-width .f-layout-width-item + .f-layout-width-item {
  border-left: 1px solid #ffffff;
}
f-layout-width .f-layout-width-item, f-layout-width .f-layout-width-item:hover ~ .f-layout-width-item:not(.disabled) {
  background-color: rgba(0, 109, 137, 0.2);
}
f-layout-width .f-layout-width-item:hover:not(.disabled) {
  background-color: #005d75;
}
f-layout-width .f-layout-width-item:hover:active:not(.disabled) {
  background-color: #005063;
}
f-layout-width .f-layout-width-item.selected, f-layout-width .f-layout-width-item:has(~ .f-layout-width-item:hover):not(.disabled) {
  background-color: #006d89;
}
f-layout-width .f-layout-width-item.selected.disabled {
  background: linear-gradient(to bottom left, #006d89 calc(50% - 1.5px), #cececf 50%, #006d89 calc(50% + 1.5px)) no-repeat 0px 0px/100% 100%;
}
f-layout-width .f-layout-width-item.disabled {
  background: linear-gradient(to bottom left, rgba(0, 109, 137, 0.2) calc(50% - 1.5px), #adadad 50%, rgba(0, 109, 137, 0.2) calc(50% + 1.5px)) no-repeat 0px 0px/100% 100%;
}

.k-input .k-button-solid-base {
  background-color: #ffffff;
  border-left-width: 0;
}

.k-input,
.k-picker {
  border-radius: 2px;
}

.k-picker {
  border-color: #c2c2c2;
}

.k-input-outline {
  background-color: transparent !important;
}
.k-input-outline:hover {
  background-color: transparent !important;
}
.k-input-outline.k-focus, .k-input-outline.k-focus:hover, .k-input-outline:focus, .k-input-outline:focus:hover {
  background-color: transparent !important;
}

.k-picker-solid:focus:hover {
  background-color: #e2e2e2;
}

.k-loading-mask .k-loading-image::before {
  border-top-color: #494844;
  border-bottom-color: #494844;
  border-left-color: #494844;
  border-right-color: transparent;
}
.k-loading-mask .k-loading-color {
  background-color: #ffffff;
  opacity: 0.85;
}

.k-toolbar .k-button-solid-base.k-selected:focus {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.k-toolbar .k-toolbar-overflow-button {
  background-color: #eeeeee !important;
  color: #6d6d6d;
}
.k-toolbar .k-toolbar-overflow-button[aria-expanded=true] {
  background-color: #006d89 !important;
  color: #ffffff !important;
}
.k-toolbar .k-toolbar-overflow-button[aria-expanded=true]:hover {
  background-color: #006d89 !important;
  color: #ffffff !important;
}
.k-toolbar .k-toolbar-overflow-button::after {
  transition: none;
}
.k-toolbar .k-toolbar-overflow-button:hover {
  background-color: #e2e2e2 !important;
  color: #6d6d6d;
}
.k-toolbar .k-toolbar-overflow-button:active, .k-toolbar .k-toolbar-overflow-button:active:focus {
  background-color: #c2c2c2 !important;
  color: #6d6d6d;
}
.k-toolbar .k-toolbar-overflow-button.k-focus::after, .k-toolbar .k-toolbar-overflow-button:focus::after {
  box-shadow: 0 0 0 2px rgba(0, 93, 117, 0.75);
  opacity: 1;
}

.k-button-group .k-button:focus {
  z-index: 3;
}
.k-button-group .k-button:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.k-button-group .k-button:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.k-toolbar .k-overflow-anchor {
  border-radius: 3px;
}

.k-i-arrow-60-left,
.k-i-arrow-60-right,
.k-i-calendar,
.k-i-clock,
.k-i-caret-alt-up,
.k-i-caret-alt-down,
.k-i-sort-asc-small,
.k-i-sort-desc-small,
.k-i-more-vertical {
  font-family: common;
}

.k-i-arrow-60-left,
.k-i-arrow-60-right {
  font-size: 20px;
}

.k-i-calendar {
  font-size: 24px;
}

.k-i-clock {
  font-size: 24px;
}

.k-i-caret-alt-up {
  transform: rotate(-90deg);
}

.k-i-caret-alt-down {
  transform: rotate(90deg);
}

.k-i-more-vertical {
  font-size: 20px;
}

.k-popup .k-calendar {
  border-color: #e6e6e6;
  box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14);
}

.k-calendar {
  border-radius: 3px;
  color: #333333;
}
.k-calendar .k-icon {
  color: #999999;
}
.k-calendar .k-icon:hover {
  color: #005d75;
}
.k-calendar .k-nav-today:active {
  color: #004759;
}
.k-calendar .k-nav-today:focus-visible {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
  color: #005d75;
  border-radius: 2px;
}
.k-calendar .k-nav-today:disabled, .k-calendar .k-nav-today[disabled], .k-calendar .k-nav-today.disabled {
  color: #e2e2e2;
}
.k-calendar .k-other-month .k-link {
  color: #999999;
}
@media (hover: hover) {
  .k-calendar-nav .k-button-flat:focus {
    box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75) !important;
  }
}

.k-calendar .k-calendar-td.k-selected:hover .k-link {
  background-color: #005d75;
}

.k-calendar-header {
  color: #005d75;
}
.k-calendar-header .k-calendar-title {
  color: inherit;
}
.k-calendar-header .k-title {
  color: #005d75;
}
.k-calendar-header .k-title:hover {
  background-color: #f7faf7 !important;
}
.k-calendar-header .k-title.k-state-disabled:hover {
  background-color: transparent !important;
}
.k-calendar-header .k-button::after {
  box-shadow: none;
}
.k-calendar-header .k-button:disabled {
  opacity: 0.65;
}

.k-state-disabled .k-calendar-tbody,
.k-calendar .k-calendar-table .k-state-disabled {
  opacity: 0.3;
}

div:not(.k-popup):not(.dropdown-menu) > .k-calendar,
div:not(.k-popup):not(.dropdown-menu) > .f-mobile-calendar,
div:not(.k-popup):not(.dropdown-menu) > .f-mobile-calendar .k-calendar-table {
  background-color: #ffffff;
}
div:not(.k-popup):not(.dropdown-menu) > .k-calendar .k-calendar-view .k-today.k-state-focused .k-link {
  box-shadow: inset 0 0 0 1px #338ba1;
}
div:not(.k-popup):not(.dropdown-menu) > .k-calendar .k-calendar-view .k-today.k-state-focused:hover .k-link {
  box-shadow: inset 0 0 0 1px #005d75;
}

.k-calendar .k-range-mid {
  background-color: rgba(51, 139, 161, 0.25);
}
.k-calendar .k-range-start {
  background-image: linear-gradient(90deg, transparent 0%, transparent 45%, rgba(51, 139, 161, 0.25) 45%, rgba(51, 139, 161, 0.25) 100%);
}
.k-calendar .k-range-end {
  background-image: linear-gradient(90deg, rgba(51, 139, 161, 0.25) 0%, rgba(51, 139, 161, 0.25) 45%, transparent 45%, transparent 100%);
}

.k-calendar-nav-today {
  color: #005d75;
}
.k-calendar-nav-today:hover {
  color: #005063;
}

.f-mobile-calendar .k-calendar-title {
  color: #333333;
}
.f-mobile-calendar .k-calendar-td:hover .k-link {
  border-color: transparent;
  background-color: transparent;
}
.f-mobile-calendar .k-calendar-tr .k-calendar-td.k-selected .k-link {
  background-color: #338ba1;
}
.f-mobile-calendar ~ .f-expand-mobile-calendar-btn {
  border-radius: 5px;
}
.f-mobile-calendar ~ .f-expand-mobile-calendar-btn, .f-mobile-calendar ~ .f-expand-mobile-calendar-btn:hover, .f-mobile-calendar ~ .f-expand-mobile-calendar-btn:active {
  background-color: #ffffff;
  border-bottom-color: #eeeeee;
}
.f-mobile-calendar ~ .f-expand-mobile-calendar-btn .f-i {
  color: #eeeeee;
}

.k-colorpicker .k-picker-wrap {
  border-radius: 3px;
}
.k-colorpicker .k-select,
.k-colorpicker .k-tool-icon {
  background-color: #eeeeee;
}
.k-colorpicker .k-select:hover, .k-colorpicker .k-select:active,
.k-colorpicker .k-tool-icon:hover,
.k-colorpicker .k-tool-icon:active {
  background-color: #e2e2e2;
}

/**
* @tokens Assets
*/
/**
* @tokens Borders
* @presenter Border
*/
/**
* @tokens Border-radii
*/
/**
* @tokens Grid-breakpoints
*/
/**
* @tokens Container-max-widths
*/
/**
* @tokens Fonts
*/
/**
* @tokens Zindices
*/
.k-dateinput .f-i-calendar, .k-dateinput .k-i-calendar {
  color: #999999;
}
.k-dateinput.is-invalid .f-i-calendar, .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput .f-i-calendar, .k-dateinput.is-invalid .k-i-calendar, .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput .k-i-calendar {
  color: #b20040;
}
.k-dateinput.is-warning .f-i-calendar, .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput .f-i-calendar, .k-dateinput.is-warning .k-i-calendar, .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput .k-i-calendar {
  color: #e9b350;
}

kendo-daterange .k-dateinput,
kendo-datepicker[fDatePicker] .k-dateinput,
kendo-timepicker[fTimePicker] .k-dateinput {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
kendo-daterange .k-dateinput::selection,
kendo-datepicker[fDatePicker] .k-dateinput::selection,
kendo-timepicker[fTimePicker] .k-dateinput::selection {
  background-color: #dceff9;
  color: #333333;
}

.k-datepicker .f-i-close:focus-visible,
.k-timepicker .f-i-close:focus-visible {
  box-shadow: 0 0 0 2px rgba(0, 109, 137, 0.75);
  border-radius: 2px;
}

.k-datepicker .k-button,
.k-timepicker .k-button {
  color: #999999;
}
.k-datepicker .k-picker-wrap,
.k-datepicker .k-dateinput-wrap,
.k-timepicker .k-picker-wrap,
.k-timepicker .k-dateinput-wrap {
  background-color: #ffffff;
  border-color: #c2c2c2;
}
.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-dateinput-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-dateinput-wrap.k-state-focused {
  background-color: #ffffff;
  border-color: #6b6b6b;
}
.k-datepicker .k-select,
.k-datepicker .k-select.k-state-hover,
.k-datepicker .k-select:hover,
.k-timepicker .k-select,
.k-timepicker .k-select.k-state-hover,
.k-timepicker .k-select:hover {
  color: #999999;
}

.k-reset-button > .f-i {
  color: #999999;
}
.k-reset-button > .f-i:hover {
  color: #333333;
}
.k-reset-button > .f-i:focus-visible {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
  border-radius: 2px;
  outline: none;
}

.k-cell-disabled {
  color: #999999;
}
.k-cell-disabled:hover {
  background-color: #ffffff;
}

.k-time-list-wrapper.k-focus::before {
  background: linear-gradient(180deg, rgba(238, 238, 238, 0.65) 0%, rgba(238, 238, 238, 0.65) 100%);
}
@media screen and (max-width: 599px) {
  .k-time-list-wrapper.k-focus::before {
    background: linear-gradient(180deg, rgba(238, 238, 238, 0.65) 0%, rgba(238, 238, 238, 0.65) 100%);
  }
}
.k-time-list-wrapper.k-focus::after {
  background: linear-gradient(180deg, rgba(238, 238, 238, 0.65) 0%, rgba(238, 238, 238, 0.65) 100%);
}
@media screen and (max-width: 599px) {
  .k-time-list-wrapper.k-focus::after {
    background: linear-gradient(180deg, rgba(238, 238, 238, 0.65) 0%, rgba(238, 238, 238, 0.65) 100%);
  }
}

.k-time-list::before, .k-time-list::after {
  box-shadow: 0 0 3em 3em #ffffff;
}
@media screen and (max-width: 599px) {
  .k-time-list::before, .k-time-list::after {
    box-shadow: 0 0 3em 3em #ffffff;
  }
}
.k-time-list .k-item:hover {
  color: #005d75;
}

.k-timeselector {
  border-radius: 3px;
}
.k-timeselector .k-time-accept {
  background-color: #006d89;
}
.k-timeselector .k-time-accept:hover {
  background-color: #005d75;
}
.k-timeselector .k-time-cancel {
  color: #6d6d6d;
}
.k-timeselector .k-time-cancel:hover {
  color: #6d6d6d;
}

@media screen and (max-width: 599px) {
  .k-daterangepicker.k-daterangepicker-mobile-sm[fDateRangePicker].k-collapsed.with-reset-btn .empty.k-dateinput:nth-of-type(1)::after {
    content: "";
    color: var(--kendo-color-subtle, #596169);
  }
  .k-daterangepicker.k-daterangepicker-mobile-sm[fDateRangePicker].k-collapsed.with-reset-btn .empty.k-dateinput:nth-of-type(1) .k-input-inner {
    padding-right: 13%;
  }
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput:hover,
.k-datepicker.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-dateinput:hover,
.k-timepicker.is-invalid:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-dateinput:hover {
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-input-outline, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput.k-input-outline, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-input-outline,
.k-datepicker.is-invalid.k-input-outline,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-input-outline.k-dateinput,
.k-timepicker.is-invalid.k-input-outline,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-input-outline.k-dateinput {
  background-color: #fff6f9 !important;
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-input-outline:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput.k-input-outline:hover, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-input-outline:hover,
.k-datepicker.is-invalid.k-input-outline:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-input-outline.k-dateinput:hover,
.k-timepicker.is-invalid.k-input-outline:hover,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-input-outline.k-dateinput:hover {
  background-color: #ffffff !important;
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-input-outline.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput.k-input-outline.k-focus, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-input-outline.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-invalid.k-input-outline.k-focus:hover,
.k-datepicker.is-invalid.k-input-outline.k-focus,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-input-outline.k-focus.k-dateinput,
.k-datepicker.is-invalid.k-input-outline.k-focus:hover,
.k-timepicker.is-invalid.k-input-outline.k-focus,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-input-outline.k-focus.k-dateinput,
.k-timepicker.is-invalid.k-input-outline.k-focus:hover {
  background-color: #ffffff !important;
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput:hover, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput:hover,
.k-datepicker.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-dateinput:hover,
.k-timepicker.is-warning:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-dateinput:hover {
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-input-outline, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput.k-input-outline, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-input-outline,
.k-datepicker.is-warning.k-input-outline,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-input-outline.k-dateinput,
.k-timepicker.is-warning.k-input-outline,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-input-outline.k-dateinput {
  background-color: #fffaeb !important;
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-input-outline:hover, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput.k-input-outline:hover, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-input-outline:hover,
.k-datepicker.is-warning.k-input-outline:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-input-outline.k-dateinput:hover,
.k-timepicker.is-warning.k-input-outline:hover,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-input-outline.k-dateinput:hover {
  background-color: #ffffff !important;
}
.k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-input-outline.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput.k-input-outline.k-focus, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.k-input-outline.k-focus, .k-daterangepicker:not(.f-is-collapsed) .k-dateinput.is-warning.k-input-outline.k-focus:hover,
.k-datepicker.is-warning.k-input-outline.k-focus,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-input-outline.k-focus.k-dateinput,
.k-datepicker.is-warning.k-input-outline.k-focus:hover,
.k-timepicker.is-warning.k-input-outline.k-focus,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-input-outline.k-focus.k-dateinput,
.k-timepicker.is-warning.k-input-outline.k-focus:hover {
  background-color: #ffffff !important;
}

.k-daterangepicker.f-is-collapsed.is-invalid:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-dateinput:hover .k-dateinput {
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-daterangepicker.f-is-collapsed.is-invalid.f-is-outlined .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.f-is-outlined.k-dateinput .k-dateinput {
  background-color: #fff6f9 !important;
}
.k-daterangepicker.f-is-collapsed.is-invalid.f-is-outlined:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.f-is-outlined.k-dateinput:hover .k-dateinput {
  background-color: #ffffff !important;
}
.k-daterangepicker.f-is-collapsed.is-invalid.f-is-outlined.k-focus .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.f-is-outlined.k-focus.k-dateinput .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid.f-is-outlined.k-focus:hover .k-dateinput {
  background-color: #ffffff !important;
}
.k-daterangepicker.f-is-collapsed.is-warning:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-dateinput:hover .k-dateinput {
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-daterangepicker.f-is-collapsed.is-warning.f-is-outlined .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.f-is-outlined.k-dateinput .k-dateinput {
  background-color: #fffaeb !important;
}
.k-daterangepicker.f-is-collapsed.is-warning.f-is-outlined:hover .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.f-is-outlined.k-dateinput:hover .k-dateinput {
  background-color: #ffffff !important;
}
.k-daterangepicker.f-is-collapsed.is-warning.f-is-outlined.k-focus .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.f-is-outlined.k-focus.k-dateinput .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning.f-is-outlined.k-focus:hover .k-dateinput {
  background-color: #ffffff !important;
}
.k-dateinput.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput, .k-daterangepicker.f-is-collapsed.is-invalid .k-daterangepicker.f-is-collapsed.k-dateinput .k-dateinput,
.k-datepicker.is-invalid,
.k-daterangepicker.f-is-collapsed.is-invalid .k-datepicker.k-dateinput,
.k-timepicker.is-invalid,
.k-daterangepicker.f-is-collapsed.is-invalid .k-timepicker.k-dateinput {
  background-color: #fff6f9;
  border-color: #b20040;
}
.k-dateinput.is-invalid .k-input-button, .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput .k-input-button,
.k-datepicker.is-invalid .k-input-button,
.k-timepicker.is-invalid .k-input-button {
  color: #b20040;
}
.k-dateinput.is-warning, .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput, .k-daterangepicker.f-is-collapsed.is-warning .k-daterangepicker.f-is-collapsed.k-dateinput .k-dateinput,
.k-datepicker.is-warning,
.k-daterangepicker.f-is-collapsed.is-warning .k-datepicker.k-dateinput,
.k-timepicker.is-warning,
.k-daterangepicker.f-is-collapsed.is-warning .k-timepicker.k-dateinput {
  background-color: #fffaeb;
  border-color: #e9b350;
}
.k-dateinput.is-warning .k-input-button, .k-daterangepicker.f-is-collapsed.is-warning .k-dateinput .k-input-button,
.k-datepicker.is-warning .k-input-button,
.k-timepicker.is-warning .k-input-button {
  color: #e9b350;
}

.k-editor {
  background-color: #ffffff;
}

.k-expanded-editor .k-editor {
  background-color: #ffffff;
}

.k-editor-content .ProseMirror {
  border-color: #c2c2c2;
  color: var(--kendo-color-on-app-surface, #212529);
}
.k-editor-content .ProseMirror-focused {
  border-color: #338ba1;
}

.editor-clean-formatting-button {
  border-radius: 2px;
}
.editor-clean-formatting-button.k-disabled {
  background-color: #eeeeee;
}
.editor-clean-formatting-button.k-disabled .k-svg-i-clear-css {
  color: #999999;
}

.k-editor-validation {
  background-color: #fff6f9;
}
.k-editor-validation .ProseMirror {
  border-color: #b20040;
}
.k-editor-validation .ProseMirror-focused {
  background-color: #ffffff;
}

.k-editor-validation-message {
  color: #b20040;
}

.editor-resize-button:active:focus {
  background-color: #eeeeee;
  box-shadow: none;
}

.k-list .k-item:focus.k-state-selected,
.k-list .k-item.k-state-focused.k-state-selected {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-list .k-list-item.k-selected, .k-list .k-selected.k-list-optionlabel {
  border-color: rgba(0, 109, 137, 0.5);
  border-radius: 2px;
}
.k-list .k-list-item.k-selected:hover, .k-list .k-selected.k-list-optionlabel:hover {
  background-color: transparent;
}

.k-list-optionlabel.k-selected {
  border-color: rgba(0, 109, 137, 0.5);
  border-radius: 2px;
}

.k-list-optionlabel.k-state-focused.k-state-selected {
  background-color: #ffffff;
}

.k-popup .k-overflow-container .k-button:disabled {
  color: #999999;
}
.k-popup .k-item.k-focus, .k-popup .k-item.k-selected {
  border-color: rgba(0, 109, 137, 0.5);
  background-color: transparent;
  border-radius: 2px;
}
.k-popup .k-link.k-menu-link.k-selected {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
  border-radius: 2px;
}

.k-animation-container .k-popup {
  background-color: #ffffff;
  border-color: #e6e6e6;
  box-shadow: 0 2px 7px 0 rgba(26, 26, 26, 0.14);
}

.k-scheduler-header-wrap > .k-scheduler-table {
  border-bottom-color: #e6e6e6;
}

.k-scheduler-times-all-day,
.k-scheduler-table th {
  color: #999999;
}

.k-scheduler-header {
  background-color: #f8f8f8;
}

.k-scheduler-header {
  border-color: #e6e6e6;
}

.k-scheduler ::selection {
  background-color: #dceff9;
  color: #333333;
}

kendo-scheduler .k-marquee-color {
  border-color: #005d75;
  background-color: #f5f5f5;
}

.k-scheduler-header-cell {
  color: #333333;
}

.k-scheduler-monthview th {
  color: #333333;
}
@media screen and (min-width: 600px) {
  .k-scheduler-monthview .k-event {
    border-right-color: #ffffff;
  }
}
@media screen and (min-width: 1280px) {
  .k-scheduler-monthview .k-event {
    border-right-color: #ffffff;
  }
}

.k-scheduler-month-day-pointer {
  border-radius: 50%;
}
.k-scheduler-month-day-pointer:hover {
  background-color: #eeeeee;
}

.k-scheduler-today {
  border-color: #338ba1;
}

.k-scheduler-month-today {
  border-color: #338ba1;
}

.k-scheduler-month-day-slot .f-i-plus {
  color: #005d75;
}

.k-scheduler-other-month {
  color: #999999;
}

.k-scheduler-agendaview {
  border-color: #ffffff;
}

.k-scheduler-agendaview .k-scheduler-content .k-scheduler-table tr:hover {
  background-color: initial;
  color: initial;
  border-color: inherit;
}
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-table tr:hover .k-scheduler-datecolumn {
  background-color: #f5f5f5;
}
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-table .k-scheduler-cell.k-selected {
  background-color: transparent;
}
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-table .k-scheduler-datecolumn {
  background-color: #f5f5f5;
}

.k-scheduler .ca-agenda-view {
  background-color: #ffffff;
}

.k-skeleton-light {
  background-color: #f5f5f5;
}

.k-skeleton-dark {
  background-color: #e2e2e2;
}

.k-skeleton-wave.k-skeleton::after, .k-skeleton-wave.k-placeholder-line::after,
.k-skeleton-wave .k-skeleton::after,
.k-skeleton-wave .k-placeholder-line::after {
  background-image: linear-gradient(125deg, transparent 0%, transparent 30%, rgba(255, 255, 255, 0.5) 50%, transparent 70%, transparent 100%);
}

.k-skeleton-light.k-skeleton-wave.k-skeleton::after, .k-skeleton-light.k-skeleton-wave.k-placeholder-line::after,
.k-skeleton-light.k-skeleton-wave .k-skeleton::after,
.k-skeleton-light.k-skeleton-wave .k-placeholder-line::after {
  background-image: linear-gradient(125deg, transparent 0%, transparent 30%, rgba(255, 255, 255, 0.7) 50%, transparent 70%, transparent 100%);
}

f-skeleton-table-loader .k-grid .k-checkbox kendo-skeleton {
  border-radius: 2px;
}

.k-grid tbody tr {
  background-color: #ffffff;
}
.k-grid tbody tr.k-grouping-table-row {
  background-color: #f8f8f8;
}
.k-grid tbody tr.k-grouping-table-row:hover {
  background-color: #eeeeee;
}
.k-grid .k-detail-cell:hover {
  background-color: #ffffff;
}
.k-grid .k-detail-cell .k-grid-header.k-table-thead:has(.k-grid-header-sticky):has(.k-grid-detail-header) + tbody tr:first-child td:not(.k-checkbox-column) {
  border-top-color: #e6e6e6;
}
.k-grid .k-detail-cell .k-grid-header,
.k-grid .k-detail-cell .k-grid-header-sticky {
  background-color: #ffffff;
  color: #999999;
}
.k-grid .k-detail-cell .k-grid-header .k-table-row:hover,
.k-grid .k-detail-cell .k-grid-header .k-header:hover,
.k-grid .k-detail-cell .k-grid-header-sticky .k-table-row:hover,
.k-grid .k-detail-cell .k-grid-header-sticky .k-header:hover {
  background-color: transparent;
}
.k-grid .k-detail-cell .k-grid-header .k-header,
.k-grid .k-detail-cell .k-grid-header-sticky .k-header {
  border-bottom-color: #e6e6e6;
}
.k-grid .k-detail-cell .k-grid-aria-root tr td:first-of-type.k-table-td.k-grid-content-sticky {
  border-right-color: #e6e6e6;
}
.k-grid .k-detail-cell .k-footer-template:hover {
  background-color: #f5f5f5;
}
.k-grid tr:only-child td {
  border-bottom-color: #e6e6e6;
}
.k-grid.k-selectable-grid:not(.k-ignored-cell-clicked) td:hover {
  box-shadow: inset 0 0 0 1px #888888;
  background-color: #f5f5f5;
  border-radius: 2px;
}
.k-grid.k-selectable-grid:not(.k-ignored-cell-clicked) td.k-focus {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
  border-radius: 2px;
}
.k-grid.k-selectable-grid td:hover:active {
  box-shadow: inset 0 0 0 1px #6b6b6b;
}
.k-grid.k-selectable-grid td.k-selected {
  box-shadow: inset 0 0 0 1px #006d89;
  border-radius: 2px;
}
.k-grid.k-selectable-grid td.k-selected:hover {
  box-shadow: inset 0 0 0 1px #005063;
  background-color: #f5f5f5;
}
.k-grid.k-selectable-grid td.k-selected:hover:active {
  box-shadow: inset 0 0 0 1px #006d89;
}
.k-grid.k-selectable-grid td.k-selected.k-focus:active {
  box-shadow: inset 0 0 0 1px #005063, inset 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-grid.k-selectable-grid.k-context-menu-is-open .k-selectable-cell.k-selected,
.k-grid.k-selectable-grid.k-context-menu-is-open .k-selected:hover {
  box-shadow: inset 0 0 0 1px #006d89;
}
.k-grid.k-selectable-grid.k-context-menu-is-open .k-selectable-cell.k-selected.ca-cell-with-note::after, .k-grid.k-selectable-grid.k-context-menu-is-open .k-selectable-cell.k-selected.ca-activity-cell-auto-mark::after, .k-grid.k-selectable-grid.k-context-menu-is-open .k-selectable-cell.k-selected.ca-activity-cell-with-note::after,
.k-grid.k-selectable-grid.k-context-menu-is-open .k-selected:hover.ca-cell-with-note::after,
.k-grid.k-selectable-grid.k-context-menu-is-open .k-selected:hover.ca-activity-cell-auto-mark::after,
.k-grid.k-selectable-grid.k-context-menu-is-open .k-selected:hover.ca-activity-cell-with-note::after {
  border-top-color: #006d89;
}
.k-grid .k-grid-content-locked,
.k-grid .k-grid-content-locked td {
  border-bottom-color: #e6e6e6;
}
.k-grid .k-grid-content-locked {
  box-shadow: 2px 0px 7px 0px rgba(0, 93, 117, 0.2);
}
.k-grid .k-grid-content-locked .k-state-hover td,
.k-grid .k-grid-content-locked tr:hover td {
  background-color: #f5f5f5;
}
.k-grid .k-header:hover {
  background-color: #eeeeee;
}
@media screen and (max-width: 599px) {
  .k-grid .k-header:hover {
    background-color: transparent;
  }
}
.k-grid .k-header:hover .k-column-resizer {
  border-left-color: #eeeeee;
}

.k-grid-with-summary-row .k-grid-footer {
  border-color: #e6e6e6;
}

.k-grid.k-selectable-grid.k-context-menu-is-open .k-master-row .k-table-td.k-selectable-cell.k-selected:not(.k-grid-content-sticky) {
  background-color: #ebf9fc;
}
.k-grid.k-selectable-grid.k-context-menu-is-open .k-master-row .k-table-td.k-selectable-cell.k-selected.k-grid-content-sticky {
  background-color: #ebf9fc;
}

.k-grid.k-selectable-grid .k-master-row > .k-table-td.k-focus {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.k-grid.k-selectable-grid .k-master-row td.k-selected.k-focus {
  box-shadow: inset 0 0 0 1px #338ba1, inset 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.k-grid-header:has(f-mobile-table-header) {
  padding-right: 0 !important;
}

.k-grid-header .k-header .k-column-resizer:hover::before {
  background-color: #006d89;
}

.k-drag-clue {
  background-color: #f8f8f8;
  border-color: #e2e2e2;
  color: #333333;
}

.k-grouping-dropclue::before {
  border-right-color: #338ba1;
}

.k-grid-footer td {
  border-top-color: #e6e6e6;
}

.k-grid-with-summary-row.k-selectable-grid .k-footer-template td:hover,
.k-grid-with-summary-row.k-selectable-grid .k-footer-template td:hover:active {
  background-color: #f8f8f8;
}
.k-grid-with-summary-row.k-selectable-grid .k-footer-template td.k-footer-highlighted-cell,
.k-grid-with-summary-row.k-selectable-grid .k-footer-template td.k-footer-highlighted-cell:hover,
.k-grid-with-summary-row.k-selectable-grid .k-footer-template td.k-footer-highlighted-cell:hover:active {
  background-color: #eeeeee;
}
.k-grid-with-summary-row .k-grid-footer {
  background-color: #f8f8f8;
}
.k-grid-with-summary-row .k-grid-footer-wrap {
  color: #333333;
}
.k-grid-with-summary-row .k-grid-footer-wrap td:not(:first-child) .k-footer-cell-value:after {
  border-bottom-color: #e6e6e6;
}
.k-grid-with-summary-row .k-footer-highlighted-cell {
  background-color: #eeeeee;
}

.k-menu-popup .k-context-menu .k-menu-item > .k-menu-link {
  color: #333333;
}
.k-menu-popup .k-context-menu .k-menu-item > .k-menu-link:hover {
  background-color: #f5f5f5;
}
.k-menu-popup .k-context-menu .k-item:focus {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
  background-color: #f5f5f5;
  border-radius: 2px;
}
.k-menu-popup .k-context-menu .k-item.k-state-disabled:focus {
  background-color: unset;
}

.k-grid.k-selectable-grid tr:hover .ca-cell-with-note,
.k-grid.k-selectable-grid tr:hover .k-selected .ca-cell-with-note {
  background-color: #e2e2e2;
}
.k-grid.k-selectable-grid tr:hover .ca-activity-cell,
.k-grid.k-selectable-grid tr:hover .ca-activity-cell-with-note,
.k-grid.k-selectable-grid tr:hover .ca-activity-cell-auto-mark,
.k-grid.k-selectable-grid tr:hover .k-selected .ca-activity-cell,
.k-grid.k-selectable-grid tr:hover .k-selected .ca-activity-cell-with-note,
.k-grid.k-selectable-grid tr:hover .k-selected .ca-activity-cell-auto-mark {
  background-color: #fcecc0;
}

.k-grid td.ca-cell-with-note {
  background-color: #f5f5f5;
}
.k-grid td.ca-cell-with-note::after {
  border-top-color: #888888;
}
.k-grid td.ca-activity-cell {
  background-color: #fff7e3;
}
.k-grid td.ca-activity-cell-auto-mark {
  background-color: #fffaeb;
}
.k-grid td.ca-activity-cell-auto-mark::after {
  border-top-color: #f2cb6f;
}
.k-grid td.ca-activity-cell-with-note {
  background-color: #fff7e3;
}
.k-grid td.ca-activity-cell-with-note::after {
  border-top-color: #888888;
}
.k-grid td.k-selected.k-selectable-cell.ca-cell-with-note {
  background-color: #f5f5f5;
}
.k-grid td.k-selected.k-selectable-cell.ca-cell-with-note:hover {
  background-color: #e2e2e2;
}
.k-grid td.k-selected.k-selectable-cell.ca-activity-cell, .k-grid td.k-selected.k-selectable-cell.ca-activity-cell-with-note {
  background-color: #fff7e3;
}
.k-grid td.k-selected.k-selectable-cell.ca-activity-cell:hover, .k-grid td.k-selected.k-selectable-cell.ca-activity-cell-with-note:hover {
  background-color: #fcecc0;
}
.k-grid td.k-selected.k-selectable-cell.ca-activity-cell-auto-mark {
  background-color: #fffaeb;
}
.k-grid td.k-selected.k-selectable-cell.ca-activity-cell-auto-mark:hover {
  background-color: #fcecc0;
}

.k-grid tr:hover > .k-highlighted-column {
  background-color: #eeeeee;
}

.k-highlighted-column {
  background-color: #f8f8f8;
}

.k-grid .k-table-row.k-highlighted-row:not(.k-selected) .k-table-td:not(.k-highlighted-column), .k-grid .k-table-row.k-highlighted-collapsed-row:not(.k-selected) .k-table-td:not(.k-highlighted-column) {
  background-color: #fffaeb;
}
.k-grid .k-grid .k-table-row.k-highlighted-row:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty), .k-grid .k-detail-row:hover .k-grid .k-table-row.k-highlighted-row:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty), .k-grid .k-grid .k-table-row.k-highlighted-collapsed-row:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty), .k-grid .k-detail-row:hover .k-grid .k-table-row.k-highlighted-collapsed-row:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty) {
  background-color: #fffaeb;
}
f-mobile-expanded-row .k-grid .k-table-row.k-highlighted-row > div:not(.k-grid-detail-header), f-mobile-expanded-row .k-grid .k-table-row.k-highlighted-collapsed-row > div:not(.k-grid-detail-header) {
  background-color: #fffaeb;
}
.k-grid .k-table-row.k-highlighted-collapsed-row:not(.k-selected) .k-table-td:not(.k-highlighted-column) {
  background-color: #f8f8f8;
}
.k-grid .k-grid .k-table-row.k-highlighted-collapsed-row:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty), .k-grid .k-detail-row:hover .k-grid .k-table-row.k-highlighted-collapsed-row:not(:hover) .k-grid-content-sticky:not(.k-checkbox-column:empty) {
  background-color: #f8f8f8;
}
f-mobile-expanded-row .k-grid .k-table-row.k-highlighted-collapsed-row > div:not(.k-grid-detail-header) {
  background-color: #f8f8f8;
}
@media (hover: hover) and (pointer: fine) and (min-width: 600px) and (min-width: 600px) {
  .k-grid .k-table-row.k-highlighted-row:not(.k-selected):hover .k-table-td:not(.k-highlighted-column),
  .k-grid .k-table-row.k-highlighted-collapsed-row:not(.k-selected):hover .k-table-td:not(.k-highlighted-column) {
    background-color: #f5f5f5;
  }
}

.k-interactive-cell:hover {
  background-color: #eeeeee !important;
}
@media screen and (max-width: 599px) {
  .k-interactive-cell:hover {
    background-color: transparent !important;
  }
}
.k-interactive-cell.k-interactive-cell-selected {
  background-color: #e2e2e2 !important;
}
@media screen and (max-width: 599px) {
  .k-interactive-cell.k-interactive-cell-selected {
    background-color: transparent !important;
  }
}

f-mobile-expanded-row .k-interactive-cell-marker::after,
.k-interactive .k-interactive-cell-marker::after,
tr:hover .k-interactive-cell-marker::after,
.k-interactive-cell-selected.k-interactive-cell-marker::after {
  border-top-color: #338ba1;
}

kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky {
  background-color: #ffffff;
}
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky.k-selected {
  background-color: #f5f5f5;
}
kendo-grid.k-grid .k-table-row:hover > .k-grid-content-sticky {
  background-color: #f5f5f5;
}
@media screen and (max-width: 599px) {
  kendo-grid.k-grid .k-table-row:hover > .k-grid-content-sticky {
    background-color: transparent;
  }
}

kendo-grid.k-grid .k-table-row.k-grid-row-sticky .k-table-td {
  background-color: #f8f8f8;
}
@media (hover: hover) and (pointer: fine) and (min-width: 600px) and (min-width: 600px) {
  kendo-grid.k-grid .k-table-row.k-grid-row-sticky:hover .k-table-td {
    background-color: #f5f5f5;
  }
}
kendo-grid.k-grid .k-table-row.k-grid-row-sticky.k-selected .k-table-td, kendo-grid.k-grid .k-table-row.k-grid-row-sticky.k-selected:hover .k-table-td {
  background-color: #f6fdff;
}

@media screen and (max-width: 599px) {
  .k-grid-detail-header {
    color: #999999;
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
.k-numerictextbox .k-button-solid-base {
  background-color: #e2e2e2;
  color: #6d6d6d;
}
.k-numerictextbox .k-button-solid-base:hover {
  background-color: #c2c2c2;
}

.k-numerictextbox.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-dateinput {
  background-color: #fff6f9;
  border-color: #b20040;
  box-shadow: inset 0 2px 3px 0 rgba(26, 26, 26, 0.06);
}
.k-numerictextbox.k-input-outline.is-invalid, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-input-outline.k-dateinput {
  background-color: #fff6f9 !important;
}
.k-numerictextbox.k-input-outline.is-invalid:hover, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-input-outline.k-dateinput:hover {
  background-color: transparent !important;
}
.k-numerictextbox.k-input-outline.is-invalid.k-focus, .k-daterangepicker.f-is-collapsed.is-invalid .k-numerictextbox.k-input-outline.k-focus.k-dateinput, .k-numerictextbox.k-input-outline.is-invalid.k-focus:hover {
  background-color: transparent !important;
}

.k-menu-group .k-item > .k-link {
  border-radius: 2px;
}
.k-menu-group .k-item > .k-link.k-selected {
  background-color: #eeeeee;
}
.k-menu-group .k-item:not(.k-disabled):focus > .k-link {
  box-shadow: inset 0 0 0 2px rgba(102, 167, 184, 0.75);
}
.k-menu-group .k-item.k-disabled {
  color: #adadad;
}

.k-toolbar .k-toolbar-overflow-button:focus::after {
  box-shadow: 0 0 0 2px rgba(102, 167, 184, 0.75);
}

.k-animation-container.k-list .k-list-item.k-selected, .k-animation-container.k-list .k-selected.k-list-optionlabel {
  background-color: #eeeeee;
}

.k-upload.k-disabled::after {
  height: 4rem;
}
.k-upload .k-upload-dropzone::before {
  background-image: url("./../../../../../../assets/brandings/common/light/icons/export.svg");
}
.k-upload .k-upload-files .k-file-summary {
  line-height: 1rem;
}
.k-upload .k-progressbar {
  border-radius: 2px;
}
.k-upload .k-icon-button .k-svg-icon:hover {
  color: #005d75;
}

kendo-fileselect.k-upload .k-upload-files .k-file-size {
  color: #999999;
}

.k-upload-actions .k-svg-icon {
  color: #999999;
}

.k-file-error .k-file-summary {
  color: #b20040;
}

.is-invalid .k-upload-dropzone, .k-daterangepicker.f-is-collapsed.is-invalid .k-dateinput .k-upload-dropzone {
  border-color: #b20040;
}

@media screen and (max-width: 599px) {
  .card-row-search-container .multiple-suggester .dropdown-toggle, .card-row-search-container .single-suggester input {
    background-image: url("./../../../../../assets/brandings/common/light/icons/search.svg");
  }
}
@media screen and (max-width: 599px) {
  .search-container {
    border-radius: 2px;
  }
}
:root {
  color-scheme: light;
}

.f-i {
  font-family: "common";
}

@font-face {
  font-family: "common";
  src: url("../fonts/common.eot");
  src: url("../fonts/common.eot?#iefix") format("eot"), url("../fonts/common.woff") format("woff"), url("../fonts/common.ttf") format("truetype"), url("../fonts/common.svg#common") format("svg");
}
.f-i-a-to-z::before {
  content: "\e001";
}

.f-i-addressbook-add::before {
  content: "\e002";
}

.f-i-arrow-down::before {
  content: "\e003";
}

.f-i-arrow-invited::before {
  content: "\e004";
}

.f-i-arrow-up::before {
  content: "\e005";
}

.f-i-bank::before {
  content: "\e006";
}

.f-i-bell::before {
  content: "\e007";
}

.f-i-bento-menu::before {
  content: "\e008";
}

.f-i-blocks::before {
  content: "\e009";
}

.f-i-book::before {
  content: "\e00a";
}

.f-i-building::before {
  content: "\e00b";
}

.f-i-calendar-add::before {
  content: "\e00c";
}

.f-i-calendar::before, .k-i-calendar::before {
  content: "\e00d";
}

.f-i-camera::before {
  content: "\e00e";
}

.f-i-caret-double::before {
  content: "\e00f";
}

.f-i-caret-down::before, .accordion-button::before, .k-i-sort-desc-small::before {
  content: "\e010";
}

.f-i-caret-left::before {
  content: "\e011";
}

.f-i-caret-right::before, .accordion-button.collapsed::before {
  content: "\e012";
}

.f-i-caret-up::before, .k-i-sort-asc-small::before {
  content: "\e013";
}

.f-i-caution::before {
  content: "\e014";
}

.f-i-check::before {
  content: "\e015";
}

.f-i-chevron-left::before, .k-i-arrow-60-left::before {
  content: "\e016";
}

.f-i-chevron-right::before, .k-i-arrow-60-right::before, .k-i-caret-alt-up::before, .k-i-caret-alt-down::before {
  content: "\e017";
}

.f-i-clip::before {
  content: "\e018";
}

.f-i-clock::before, .k-i-clock::before {
  content: "\e019";
}

.f-i-close::before {
  content: "\e01a";
}

.f-i-collapse::before {
  content: "\e01b";
}

.f-i-compass::before {
  content: "\e01c";
}

.f-i-contact-add::before {
  content: "\e01d";
}

.f-i-contact::before {
  content: "\e01e";
}

.f-i-copy::before {
  content: "\e01f";
}

.f-i-crm-primary::before {
  content: "\e020";
}

.f-i-crm::before {
  content: "\e021";
}

.f-i-danger::before {
  content: "\e022";
}

.f-i-dashboard::before {
  content: "\e023";
}

.f-i-dots-x::before {
  content: "\e024";
}

.f-i-dots-y::before, .k-i-more-vertical::before {
  content: "\e025";
}

.f-i-download::before {
  content: "\e026";
}

.f-i-drag-drop::before {
  content: "\e027";
}

.f-i-edit::before {
  content: "\e028";
}

.f-i-email::before {
  content: "\e029";
}

.f-i-enlarge::before {
  content: "\e02a";
}

.f-i-ESG::before {
  content: "\e02b";
}

.f-i-event-add::before {
  content: "\e02c";
}

.f-i-event::before {
  content: "\e02d";
}

.f-i-excel::before {
  content: "\e02e";
}

.f-i-excluded-from-mail::before {
  content: "\e02f";
}

.f-i-expand::before {
  content: "\e030";
}

.f-i-export::before {
  content: "\e031";
}

.f-i-external-link::before {
  content: "\e032";
}

.f-i-field-add::before {
  content: "\e033";
}

.f-i-field::before {
  content: "\e034";
}

.f-i-filter::before {
  content: "\e035";
}

.f-i-flag::before {
  content: "\e036";
}

.f-i-globe::before {
  content: "\e037";
}

.f-i-graph::before {
  content: "\e038";
}

.f-i-group::before {
  content: "\e039";
}

.f-i-help::before {
  content: "\e03a";
}

.f-i-home::before {
  content: "\e03b";
}

.f-i-info::before {
  content: "\e03c";
}

.f-i-insight::before {
  content: "\e03d";
}

.f-i-layers::before {
  content: "\e03e";
}

.f-i-line::before {
  content: "\e03f";
}

.f-i-link-redirect::before {
  content: "\e040";
}

.f-i-link::before {
  content: "\e041";
}

.f-i-list::before {
  content: "\e042";
}

.f-i-mailing-address::before {
  content: "\e043";
}

.f-i-management::before {
  content: "\e044";
}

.f-i-menu::before {
  content: "\e045";
}

.f-i-merge::before {
  content: "\e046";
}

.f-i-micro::before {
  content: "\e047";
}

.f-i-migrate::before {
  content: "\e048";
}

.f-i-minus-circle::before {
  content: "\e049";
}

.f-i-mixed-non-primary::before {
  content: "\e04a";
}

.f-i-mixed-primary::before {
  content: "\e04b";
}

.f-i-modified-data::before {
  content: "\e04c";
}

.f-i-new-conversation::before {
  content: "\e04d";
}

.f-i-new-to-old::before {
  content: "\e04e";
}

.f-i-notes::before {
  content: "\e04f";
}

.f-i-old-to-new::before {
  content: "\e050";
}

.f-i-pdf::before {
  content: "\e051";
}

.f-i-phone::before {
  content: "\e052";
}

.f-i-pin::before {
  content: "\e053";
}

.f-i-plane::before {
  content: "\e054";
}

.f-i-plus-circle::before {
  content: "\e055";
}

.f-i-plus::before {
  content: "\e056";
}

.f-i-pointer::before {
  content: "\e057";
}

.f-i-powerpoint::before {
  content: "\e058";
}

.f-i-primary-email::before {
  content: "\e059";
}

.f-i-primary-phone::before {
  content: "\e05a";
}

.f-i-report::before {
  content: "\e05b";
}

.f-i-retry::before {
  content: "\e05c";
}

.f-i-search::before {
  content: "\e05d";
}

.f-i-send-message::before {
  content: "\e05e";
}

.f-i-settings::before {
  content: "\e05f";
}

.f-i-share::before {
  content: "\e060";
}

.f-i-shrink::before {
  content: "\e061";
}

.f-i-star-filled::before {
  content: "\e062";
}

.f-i-star::before {
  content: "\e063";
}

.f-i-stars-outlined::before {
  content: "\e064";
}

.f-i-stars::before {
  content: "\e065";
}

.f-i-success::before {
  content: "\e066";
}

.f-i-suggestion::before {
  content: "\e067";
}

.f-i-suitcase::before {
  content: "\e068";
}

.f-i-sync-deleted::before {
  content: "\e069";
}

.f-i-sync-disabled::before {
  content: "\e06a";
}

.f-i-sync-edited::before {
  content: "\e06b";
}

.f-i-sync-not-synced::before {
  content: "\e06c";
}

.f-i-sync-partially-synced::before {
  content: "\e06d";
}

.f-i-sync-synced::before {
  content: "\e06e";
}

.f-i-sync::before {
  content: "\e06f";
}

.f-i-tag::before {
  content: "\e070";
}

.f-i-target::before {
  content: "\e071";
}

.f-i-thumb-down::before {
  content: "\e072";
}

.f-i-thumb-up::before {
  content: "\e073";
}

.f-i-timezone::before {
  content: "\e074";
}

.f-i-toggles::before {
  content: "\e075";
}

.f-i-tools::before {
  content: "\e076";
}

.f-i-trash-bin::before {
  content: "\e077";
}

.f-i-view-agenda::before {
  content: "\e078";
}

.f-i-view-day::before {
  content: "\e079";
}

.f-i-view-month::before {
  content: "\e07a";
}

.f-i-view-week::before {
  content: "\e07b";
}

.f-i-view-work-week::before {
  content: "\e07c";
}

.f-i-warning::before {
  content: "\e07d";
}

.f-i-word::before {
  content: "\e07e";
}

.f-i-z-to-a::before {
  content: "\e07f";
}