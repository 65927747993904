$f-layout-width-border-color: $color-neutral-400;
$f-layout-width-item-border: $border-width-1 $border-style-solid $color-base;
$f-layout-width-item-bg-color: rgba($color-primary-600, 0.2);
$f-layout-width-item-bg-color-hover: $color-primary-700;
$f-layout-width-item-bg-color-selected: $color-primary-600;
$f-layout-width-item-bg-color-pressed: $color-primary-800;
$f-layout-width-item-bg-color-selected-disabled: linear-gradient(
    to bottom left,
    $color-primary-600 calc(50% - 1.5px),
    $color-neutral-200 50%,
    $color-primary-600 calc(50% + 1.5px)
  )
  no-repeat 0px 0px / 100% 100%;
$f-layout-width-item-bg-color-disabled: linear-gradient(
    to bottom left,
    $f-layout-width-item-bg-color calc(50% - 1.5px),
    $color-neutral-300 50%,
    $f-layout-width-item-bg-color calc(50% + 1.5px)
  )
  no-repeat 0px 0px / 100% 100%;
