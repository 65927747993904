@import './../../../../../default/kendo-styles/components/file-uploader/file-uploader-variables';
@import './../../../bootstrap-styles/basic-elements/buttons/button-variables';

.k-upload {
  &.k-disabled {
    &::after {
      height: calc(
        $font-body-50-line-height + $font-body-70-line-height + $k-upload-status-mb + $kendo-upload-dropzone-padding-y *
          2
      );
    }

    .k-button-text {
      @extend .link-disabled;
    }
  }

  .k-upload-dropzone {
    &::before {
      background-image: url('#{$brand-icon-assets-path}' + 'export.svg');
    }
  }

  .k-upload-files {
    .k-file-size {
      @extend .body-50;
    }

    .k-file-summary {
      line-height: $font-body-50-line-height;
    }
  }

  .k-upload-status {
    @extend .body-50;
  }

  .k-progressbar {
    border-radius: $progress-border-radius;
  }

  .k-icon-button .k-svg-icon {
    &:hover {
      color: $btn-simple-icon-primary-color-hover;
    }
  }
}

kendo-fileselect.k-upload .k-upload-files .k-file-size {
  color: $text-muted-color;
}

.k-dropzone-hint {
  @extend .body-50;
}

.k-upload-button {
  .k-button-text {
    @extend .link, .link-primary, .link-50;
  }
}

.k-upload-actions {
  .k-svg-icon {
    color: $text-muted-color;
  }
}

.k-file-error .k-file-summary {
  color: $invalid-feedback;
}

.is-invalid {
  .k-upload-dropzone {
    border-color: $invalid-feedback;
  }
}
